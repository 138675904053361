import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { getCurrentUserAdapter } from 'main/adapters/current-user/current-user-adapter'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { PermissionsFactory } from 'presentation/contexts/permissions/permissions-factory'
import { ApiRepository } from 'repository/api-repository'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { DoctorRepository } from 'repository/repositories/doctor/doctor-repository'
import { RemoteLoadDoctor } from 'service/usecases/load-doctor/remote-load-doctor'
import { RemoteLoadUserRole } from 'service/usecases/load-user-role/remote-load-user-role'
import { RemoteLoadUserGroups } from 'service/usecases/load-user-groups/remote-load-user-groups'

export default function makePermisions() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const doctorRepository = new DoctorRepository(authApiRepository)

  const loadUserRole = new RemoteLoadUserRole(authenticationRepository)
  const loadUserGroups = new RemoteLoadUserGroups(authenticationRepository)
  const loadDoctor = new RemoteLoadDoctor(doctorRepository)
  const pbacAdapter = new PbacAdapter([])

  const permissionsFactory = new PermissionsFactory(
    pbacAdapter,
    loadUserRole,
    loadUserGroups,
    getCurrentUserAdapter,
    loadDoctor
  )

  const user = getCurrentUserAdapter()?.user

  return permissionsFactory.build(user?.role)
}
