import { makeAutoObservable } from 'mobx'
import { LoadSurgicalSchedules } from 'domain/usecases/surgical-schedules/load-surgical-schedules'
import { LoadSurgicalSchedule } from 'domain/usecases/surgical-schedule/load-surgical-schedule'
import { LoadEquipaments } from 'domain/usecases/equipaments/load-equipaments'
import { LoadHospitalSurgeryCenter } from 'domain/usecases/hospital-surgery-center/load-hospital-surgery-center'
import { LoadSurgicalScheduleAvailableDays } from 'domain/usecases/load-surgical-schedule-available-days/load-surgical-schedule-available-days'
import { LoadSurgeryCenterCalendar } from 'domain/usecases/load-surgery-center-calendar/load-surgery-center-calendar'
import { FinishSurgicalSchedule } from 'domain/usecases/finish-surgical-schedule/finish-surgical-schedule'

type Services = {
  loadSurgicalSchedules: LoadSurgicalSchedules
  loadEquipaments: LoadEquipaments
  loadHospitalSurgeryCenter: LoadHospitalSurgeryCenter
  loadSurgicalSchedule: LoadSurgicalSchedule
  loadSurgicalScheduleAvailableDays: LoadSurgicalScheduleAvailableDays
  loadSurgeryCenterCalendar: LoadSurgeryCenterCalendar
  finishSurgicalSchedule: FinishSurgicalSchedule
}

export class SurgicalScheduleService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSurgicalSchedules(
    params: LoadSurgicalSchedules.Params
  ): Promise<LoadSurgicalSchedules.Model> {
    return this.services.loadSurgicalSchedules.load(params)
  }

  async loadSurgicalSchedule(
    params: LoadSurgicalSchedule.Params
  ): Promise<LoadSurgicalSchedule.Model> {
    return this.services.loadSurgicalSchedule.load(params)
  }

  async loadEquipaments(
    params: LoadEquipaments.Params
  ): Promise<LoadEquipaments.Model> {
    return this.services.loadEquipaments.load(params)
  }

  async loadHospitalSurgeryCenter(
    params: LoadHospitalSurgeryCenter.Params
  ): Promise<LoadHospitalSurgeryCenter.Model> {
    return this.services.loadHospitalSurgeryCenter.load(params)
  }

  async loadSurgicalScheduleAvailableDays(
    params: LoadSurgicalScheduleAvailableDays.Params
  ): Promise<LoadSurgicalScheduleAvailableDays.Model> {
    return this.services.loadSurgicalScheduleAvailableDays.load(params)
  }

  async loadSurgeryCenterCalendar(
    params: LoadSurgeryCenterCalendar.Params
  ): Promise<LoadSurgeryCenterCalendar.Model> {
    return this.services.loadSurgeryCenterCalendar.load(params)
  }

  async finishSurgicalSchedule(
    params: FinishSurgicalSchedule.Params
  ): Promise<FinishSurgicalSchedule.Model> {
    return this.services.finishSurgicalSchedule.add(params)
  }
}

export default SurgicalScheduleService
