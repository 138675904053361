import { AddSurgicalOrderCancellationComment } from 'domain/usecases/surgical-order/add-surgical-order-cancellation-comment'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteAddSurgicalOrderCancellationComment
  implements AddSurgicalOrderCancellationComment
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  post(
    params: AddSurgicalOrderCancellationComment.Params
  ): Promise<AddSurgicalOrderCancellationComment.Model> {
    return this.surgicalOrderRepository.addSurgicalOrderCancellationComment(
      params
    )
  }
}
