import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'
import { TooltipNew } from 'presentation/shared/components/TooltipNew'
import { ReactComponent as InfoIcon } from 'presentation/assets/icons/info.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as PinIcon } from 'presentation/assets/icons/new_pin.svg'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/chevron-right.svg'
import * as S from './styles'
import ProfilePic from 'presentation/components/ProfilePic'
import { SchedulingSchedule } from 'domain/entities/scheduling-schedule-model'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { SchedulingSpecialty } from 'domain/entities/scheduling-specialty-model'
import { SchedulingDoctor } from 'domain/entities/scheduling-doctor-model'
import moment from 'moment'
import 'moment/locale/pt-br'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import HeaderNew from 'presentation/components/HeaderNew'

type LocationType = {
  state: {
    filteredSearchResult: {
      data: {
        getSchedules: SchedulingSchedule[]
      }
    }
    patient: SchedulingPatient
    healthInsurance: PatientHealthInsurance
  }
}

type NextLocationProps = {
  date_initial: string
  schedule_id: number
  specialty: SchedulingSpecialty
  doctor: SchedulingDoctor
  unit_name: string
  unit_id: number
  unit_description?: string
  cd_it_agenda_central: number
}

const SchedulingAvailableDatesLayout = () => {
  const history = useHistory()
  const location: LocationType = useLocation()
  const searchResult =
    location.state && location.state.filteredSearchResult.data.getSchedules
  const patient = location.state && location.state.patient
  const healthInsurance = location.state && location.state.healthInsurance

  const whereToGo = () => {
    if (patient && healthInsurance) {
      history.push('/agendamento/consulta/busca', {
        patient: patient,
        selectedHealthInsurance: healthInsurance
      })
    } else {
      history.push('/agendamento/consulta/convenios')
    }
  }

  const goToCalendar = ({
    schedule_id,
    doctor,
    specialty,
    unit_name,
    unit_id,
    unit_description,
    date_initial,
    cd_it_agenda_central
  }: NextLocationProps) => {
    history.push('/agendamento/consulta/calendario', {
      filteredSearchResult: location.state.filteredSearchResult,
      healthInsurance: {
        name: healthInsurance.healthInsuranceName,
        plan_name: healthInsurance.healthPlanName,
        plan_id: healthInsurance.healthPlanCode,
        health_insurance_id: healthInsurance.healthInsuranceCode
      },
      doctor,
      schedule_id,
      units: [
        {
          name: unit_name,
          description: unit_description,
          unit_id
        }
      ],
      specialty,
      patient,
      date_initial,
      cd_it_agenda_central
    })
  }

  if (!patient || !healthInsurance) {
    return <Redirect to="/agendamento/consulta/convenios" />
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Agendar consulta"
        actualPageOnClick={() => whereToGo()}
      />
      <ContainerNew noPadding noHeader>
        {searchResult && searchResult.length > 0 && (
          <S.CardInfo>
            <h3 className="title">Importante!</h3>
            <p className="description">
              Para saber o valor da sua consulta entre em contato conosco.
              Futuramente você poderá visualizar os valores por aqui.
            </p>
          </S.CardInfo>
        )}

        <S.AvailableDatesWrapper>
          {patient &&
          healthInsurance &&
          searchResult &&
          searchResult.length > 0 ? (
            searchResult.map((item, index) => (
              <S.CardScheduling key={index}>
                <div className="header">
                  <div className="img">
                    <ProfilePic
                      data-testid="profile-pic"
                      outlined
                      size="xmedium"
                      name={item.doctor.name}
                    />
                  </div>
                  <div className="header-infos">
                    <h2 className="title">
                      <span className="prefix">Dr(a)</span>{' '}
                      <span className="text">
                        {item.doctor.name?.toLocaleLowerCase()}
                      </span>
                    </h2>
                    <h4 className="subtitle">
                      <TooltipNew
                        className="icon"
                        text="Este médico atende em
                    diferentes especialidades"
                      >
                        <InfoIcon />
                      </TooltipNew>
                      {item.specialty.name}
                    </h4>
                  </div>
                </div>
                <div className="content">
                  <div className="localization-box">
                    <PinIcon className="icon" />
                    <div className="infos">
                      <h6 className="title">
                        {item.unit_name?.toLocaleLowerCase()}
                      </h6>
                      <p className="description">
                        {item.unit_description?.toLocaleLowerCase()}
                      </p>
                    </div>
                  </div>

                  <div className="scheduling-box">
                    <h4 className="next-date">
                      <CalendarIcon className="icon" /> <b>Próxima data:</b>{' '}
                      {moment(item.date_initial)
                        .locale('pt-br')
                        .format('dddd - DD [de] MMMM')}
                    </h4>
                    <div className="schedules">
                      {item.hours.length > 0 ? (
                        item.hours.slice(0, 3).map((hour, index) => (
                          <ButtonNew
                            key={index}
                            size="small"
                            onClick={() =>
                              history.push('/agendamento/consulta/resumo', {
                                filteredSearchResult:
                                  location.state.filteredSearchResult,
                                selectedSchedule: item,
                                selectedHour: hour,
                                patient,
                                // units,
                                healthInsurance
                              })
                            }
                            outlined
                          >
                            {moment(hour.date_begin)
                              .locale('pt-br')
                              .format('LT')}
                          </ButtonNew>
                        ))
                      ) : (
                        <p>...</p>
                      )}
                      <ButtonNew
                        size="small"
                        icon={<RightArrow className="icon" />}
                        iconPosition="right"
                        onClick={() => goToCalendar(item)}
                      >
                        Mais
                      </ButtonNew>
                    </div>
                  </div>
                </div>
              </S.CardScheduling>
            ))
          ) : (
            <S.ResultMessage>
              <p>Sua busca não retornou resultados</p>
            </S.ResultMessage>
          )}
        </S.AvailableDatesWrapper>
      </ContainerNew>
    </>
  )
}

export default SchedulingAvailableDatesLayout
