import { IntervalType } from 'presentation/utils/interval-type'
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react'
import SupportText from '../../../components/SupportText'

type CountdownProps = {
  onIsDone?: any
  time?: number
  autoReset?: boolean
}

export type CountdownRefProps = {
  reset?: () => void
}

const Countdown: React.ForwardRefRenderFunction<
  CountdownRefProps,
  CountdownProps
> = ({ onIsDone, time }: CountdownProps, forwardedRef) => {
  const [seconds, setSeconds] = useState(time || 60)
  const [done, setDone] = useState(false)
  const foo = useRef<IntervalType>()

  useEffect(() => {
    if (done) {
      onIsDone?.()
    }
  }, [done])

  useEffect(() => {
    runTimer()
  }, [])

  useEffect(() => {
    if (seconds === 0) {
      if (foo.current) {
        clearInterval(foo.current)
      }

      setDone(true)
    }
  }, [seconds])

  const resetFn = () => {
    setDone(false)
    setSeconds(time || 60)
    runTimer()
  }

  useImperativeHandle(forwardedRef, () => ({
    reset: resetFn,
    done: done
  }))

  const runTimer = () => {
    function tick() {
      setSeconds((prevSeconds) => prevSeconds - 1)
    }
    if (foo.current) {
      clearInterval(foo.current)
    }
    foo.current = setInterval(() => tick(), 1000)
  }

  return (
    <>
      {seconds !== 0 && (
        <SupportText style={{ fontSize: '18px' }}>{seconds}</SupportText>
      )}
    </>
  )
}

export default forwardRef(Countdown)
