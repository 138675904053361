import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  padding-bottom: 10px;
  flex: 1 1 0;
  overflow-y: auto;

  .swiper {
    height: 100%;
  }
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: safe center;
  margin-top: 5px;

  button {
    margin-top: 15px;
  }
`

export const Controls = styled.div`
  ${({ theme }) => css`
    top: 10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: ${theme.layers.overlay};
  `}
`

export const ConfirmationModal = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    p {
      margin-top: 12px;
      margin-bottom: 24px;
    }
    strong {
      color: ${theme.colors.primary};
    }
  `}
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
