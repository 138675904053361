import React, { useRef, HTMLAttributes, useState } from 'react'
import ButtonNew, { ButtonProps } from '../ButtonNew'
import * as S from './styles'

type Props = {
  name: string
  accept?: string
  multiple?: boolean
  onInput?: (file: any) => void
} & ButtonProps &
  HTMLAttributes<HTMLDivElement>

const UploadButtonNew = ({
  accept = 'image/jpeg,image/gif,image/png,image/bmp,image/tif,image/tiff,application/pdf,image/x-eps',
  multiple = false,
  children,
  onInput,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLAnchorElement>(null)

  const [inputKey, setInputKey] = useState(1) as any

  function resetFileInput() {
    const randomString = Math.random().toString(36)
    setInputKey(randomString)
  }

  const buttonClick = () => {
    inputRef.current?.click()
  }

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const files = event.target?.files
    onInput && onInput(files)
    resetFileInput()
  }

  return (
    <>
      <ButtonNew onClick={buttonClick} {...props}>
        {children}
      </ButtonNew>
      {/* <S.InputLabel htmlFor={`file-input-${inputRef}`}>{children}</S.InputLabel> */}
      <S.FileInput
        ref={inputRef}
        type="file"
        hidden
        key={inputKey}
        disabled={props.disabled}
        name={props.name}
        id={`file-input-${props.name}`}
        onInput={(event) => {
          onHandleChange(event as React.ChangeEvent<HTMLInputElement>)
        }}
        // onChange={onHandleChange}
        accept={accept}
        multiple={multiple}
      />
    </>
  )
}

export default UploadButtonNew
