import React from 'react'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { RemoteGetPasswordRecoveryCode } from 'service/usecases/get-password-recovery-code/remote-get-password-recovery-code'
import PasswordRecovery from 'presentation/pages/PasswordRecovery'
import { RemoteChangePassword } from 'service/usecases/change-password/remote-change-password'
import { Profile } from 'common/enum/profile'

type Props = {
  client?: Profile
}

export default function makePasswordRecovery({ client }: Props) {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const getCodeUseCase = new RemoteGetPasswordRecoveryCode(
    authenticationRepository
  )
  const changePasswordUseCase = new RemoteChangePassword(
    authenticationRepository
  )
  return (
    <PasswordRecovery
      changePasswordUseCase={changePasswordUseCase}
      getCodeUseCase={getCodeUseCase}
      client={client}
    />
  )
}
