import { CreateTherapeuticPlan } from 'domain/usecases/therapeutic-plan/create-therapeutic-plan'
import { LoadProtocols } from 'domain/usecases/therapeutic-plan/load-protocols'
import { UploadTherapeuticPlanDocument } from 'domain/usecases/therapeutic-plan/upload-therapeutic-plan-document'
import { makeAutoObservable } from 'mobx'

type Services = {
  createInitialTherapeuticPlan: CreateTherapeuticPlan
  uploadTherapeuticPlanDocument: UploadTherapeuticPlanDocument
  loadProtocols: LoadProtocols
}

export class TherapeuticPlanService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async createInitialTherapeuticPlan(
    params: CreateTherapeuticPlan.Params
  ): Promise<CreateTherapeuticPlan.Model> {
    return this.services.createInitialTherapeuticPlan.add(params)
  }

  async uploadTherapeuticPlanDocument(
    params: UploadTherapeuticPlanDocument.Params
  ): Promise<UploadTherapeuticPlanDocument.Model> {
    return this.services.uploadTherapeuticPlanDocument.upload(params)
  }

  async loadProtocols(
    params: LoadProtocols.Params
  ): Promise<LoadProtocols.Model> {
    return this.services.loadProtocols.load(params)
  }
}

export default TherapeuticPlanService
