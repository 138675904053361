import { EmergencyRoomAttendanceModel } from 'domain/entities/emergency-room-attendance.model'
import { Hospital } from 'domain/entities/hospital-model'
import { CreateEmergencyRoom } from 'domain/usecases/emergency-room/create-emergency-room'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/small-check.svg'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ServiceRequestHeader } from '../Header'
import { ArrivalTimes } from '../SelectUnit'
import { EmergencyRoomConclusionModal } from './ConclusionModal'
import * as S from './styles'

type ServiceRequestStepsProps = {
  carousel: CarouselState
  step: number
  form?: SubmitEmergencyAttendance
  createEmergencyRoom: CreateEmergencyRoom
  hospitals: Hospital[]
}

type StatusContent = {
  status?: 'actual' | 'completed' | 'disabled' | undefined
  statusTitle?: string
}

export function EmergencyRoomSteps({
  carousel,
  step,
  form,
  createEmergencyRoom,
  hospitals
}: ServiceRequestStepsProps) {
  const status: {
    info: StatusContent
    unity: StatusContent
    symptoms: StatusContent
  }[] = [
    {
      info: {
        status: 'actual',
        statusTitle: 'Começar'
      },
      unity: {
        status: undefined,
        statusTitle: ''
      },
      symptoms: {
        status: 'disabled',
        statusTitle: ''
      }
    },
    {
      info: {
        status: 'completed',
        statusTitle: ''
      },
      unity: {
        status: 'actual',
        statusTitle: 'Começar'
      },
      symptoms: {
        status: 'disabled',
        statusTitle: ''
      }
    },
    {
      info: {
        status: 'completed',
        statusTitle: ''
      },
      unity: {
        status: 'completed',
        statusTitle: ''
      },
      symptoms: {
        status:
          !form?.symptoms || form?.symptoms === {} ? 'actual' : 'completed',
        statusTitle: 'Começar'
      }
    },
    {
      info: {
        status: 'completed',
        statusTitle: ''
      },
      unity: {
        status: 'completed',
        statusTitle: ''
      },
      symptoms: {
        status: 'completed',
        statusTitle: ''
      }
    }
  ]
  const [token, setToken] = useState<string>()

  const history = useHistory()

  const timeContent: { time: ArrivalTimes; label: string }[] = [
    {
      time: 'HR_1',
      label: 'na próxima hora'
    },
    {
      time: 'HR_1_30',
      label: 'na próxima uma hora e meia'
    },
    {
      time: 'HR_2',
      label: 'nas próximas 2 horas'
    },
    {
      time: 'MIN_30',
      label: 'nos próximos 30 min'
    }
  ]

  const onComplete = async () => {
    if (form) {
      try {
        delete form.useHealthInsurance
        delete form.card
        delete form.healthPlanCode
        const result: any = await createEmergencyRoom.add(
          new EmergencyRoomAttendanceModel(form)
        )
        setToken(result?.emergencyRoomToken?.token)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  }

  return (
    <>
      <ServiceRequestHeader
        title="Solicitar atendimento no pronto socorro"
        color="primary"
        goBack={() => history.goBack()}
      />
      <S.StepsContent>
        <ServiceRequestStepsButton
          title="Dados pessoais e Unidade"
          description="Informe alguns dados para realizar a sua solicitação"
          status={status[step].info.status}
          statusTitle={status[step].info.statusTitle}
          icon={'1'}
          onClick={() => carousel.slideNext()}
        />
        <ServiceRequestStepsButton
          title="Especialidade"
          description="Escolha a especialidade que deseja ser atendido"
          status={status[step].unity.status}
          statusTitle={status[step].unity.statusTitle}
          icon={'2'}
          onClick={() => carousel.slideTo(2)}
        />
        <ServiceRequestStepsButton
          title="Sintomas"
          description="Informe o que está sentindo para prepararmos o seu atendimento"
          statusTitle={status[step].symptoms.statusTitle}
          status={status[step].symptoms.status}
          icon={'3'}
          onClick={() => carousel.slideTo(3)}
        />
        <Button fullWidth disabled={step <= 2} onClick={onComplete}>
          Concluir
        </Button>
      </S.StepsContent>
      <EmergencyRoomConclusionModal
        onConfirm={() => {
          setToken(undefined)
          history.push('/')
        }}
        open={!!token}
        time={
          timeContent.find((time) => time.time === form?.arrivalForecast)?.label
        }
        token={token}
        unity={
          hospitals?.find(
            (hospital) => hospital?.hospital_id === form?.hospital_id
          )?.name || '-'
        }
      />
    </>
  )
}

type ServiceRequestStepsButtonProps = {
  carousel?: CarouselState
  status?: 'completed' | 'actual' | 'disabled'
  title: string
  statusTitle?: string
  description: string
  icon?: string
  onClick?: () => void
}

function ServiceRequestStepsButton({
  status = 'disabled',
  title,
  statusTitle,
  description,
  icon,
  onClick
}: ServiceRequestStepsButtonProps) {
  return (
    <>
      <S.StepsButton
        status={status}
        onClick={status === 'actual' ? onClick : undefined}
      >
        <S.StepsIcon status={status}>
          {status === 'completed' ? <CheckIcon /> : icon}
        </S.StepsIcon>
        <S.StepsHeader>
          <strong>{title}</strong>
          <div>{statusTitle}</div>
        </S.StepsHeader>
        {description}
      </S.StepsButton>
    </>
  )
}
