import { LoadRecommendationByInviteToken } from 'domain/usecases/doctor/load-recommendation-by-invite-token'
import { RecommendationRepository } from 'repository/repositories/recommendation/recommendation-repository'

export class RemoteLoadRecommendationByInviteToken
  implements LoadRecommendationByInviteToken
{
  constructor(
    private readonly recommendationRepository: RecommendationRepository
  ) {}

  load(
    token: string,
    params: LoadRecommendationByInviteToken.Params
  ): Promise<LoadRecommendationByInviteToken.Model> {
    return this.recommendationRepository.loadRecommendationByInviteToken(
      token,
      params
    )
  }
}

export type LoadRecommendationByInviteTokenModel =
  LoadRecommendationByInviteToken.Model
