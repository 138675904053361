import React from 'react'
import Header from 'presentation/components/Header'
import { Container } from 'presentation/components/Container'
import { useHistory } from 'react-router-dom'

import * as S from './styles'
import SharedProcedureResultForm, {
  SharedResultFormProps
} from 'presentation/components/Forms/SharedProcedureResult'

const SharedProcedureResultLayout = ({
  contacts,
  onSubmitForm
}: SharedResultFormProps) => {
  const history = useHistory()

  return (
    <>
      <Header />

      <Container
        actualPageText="Resultado"
        actualPageOnClick={() => history.goBack()}
        title="Enviar meu exame"
      >
        <S.Wrapper>
          <SharedProcedureResultForm
            contacts={contacts}
            onSubmitForm={onSubmitForm}
          />
        </S.Wrapper>
      </Container>
    </>
  )
}

export default SharedProcedureResultLayout
