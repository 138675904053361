import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetHoursByDay implements GetHoursByDay {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: GetHoursByDay.Params): Promise<GetHoursByDay.Model> {
    return this.schedulingRepository.getScheduleHours(params)
  }
}

export type SearchDoctorsModel = GetHoursByDay.Model
