import styled from 'styled-components'

// type WrapperProps = {
//   confirmated: boolean
// }

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 310px;
  height: 530px;
  /* border: 2px solid red; */
`

export const Content = styled.div`
  height: auto;
  /* border: 2px solid black; */
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  /* border: 2px solid green; */

  > .action-btns {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  outline: none;
  box-shadow: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.ds.colors.primary600};
`
