import ActualPage from 'presentation/components/ActualPage'
import Heading from 'presentation/components/Heading'
import { ReactComponent as ServiceRequestIcon } from 'presentation/assets/icons/new-service-request.svg'
import React from 'react'
import theme from 'presentation/styles/theme'
import * as S from './styles'

type ServiceRequestHeaderProps = {
  title?: string
  color?: keyof typeof theme.colors
  goBack?: () => void
}

export function ServiceRequestHeader({
  title,
  color,
  goBack
}: ServiceRequestHeaderProps) {
  return (
    <S.Header>
      <S.ActualPage>
        <ActualPage text={'Voltar'} onClick={goBack} />
      </S.ActualPage>
      <S.Icon>
        <ServiceRequestIcon />
      </S.Icon>
      <S.Title>
        <Heading as="h5" color={color ?? 'primary'}>
          {title}
        </Heading>
      </S.Title>
    </S.Header>
  )
}
