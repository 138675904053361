import styled, { css } from 'styled-components'

import { SlideProps } from '.'
type SlideIntroProp = Pick<SlideProps, 'slideIntro'>

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`

export const Image = styled.img<SlideIntroProp>`
  ${({ slideIntro }) => css`
    display: block;
    width: 100%;
    max-width: 420px;

    @media (max-width: 575px) {
      max-width: 240px;
      max-height: ${slideIntro ? '240px' : '180px'};
    }

    @media (max-width: 360px) and (max-height: 750px) {
      max-width: 180px;
      max-height: ${slideIntro ? '210px' : '150px'};
    }
  `}
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
