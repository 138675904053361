export const positiveReasons = [
  'Praticidade',
  'Facilidade',
  'Boa disponibilidade de horários',
  'Informações ordenadas',
  'Agilidade',
  'Solucionou meu problema',
  'Outro'
]

export const negativeReasons = [
  'Informações confusas',
  'Lentidão',
  'Falta de funcionalidade',
  'Preços',
  'Problemas com convênio / plano',
  'Data muito longe',
  'Outro'
]

export const rateLabels: { [index: string]: string } = {
  1: 'Muito ruim',
  2: 'Ruim',
  3: 'Neutro',
  4: 'Bom',
  5: 'Muito Bom'
}
