import { Hospital } from 'domain/entities/hospital-model'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import SelectField from 'presentation/shared/components/SelectField'
import React, { useState, useEffect } from 'react'
import { AddHealthInsurance } from '../PatientPersonalData/AddHealthInsurance'
import { EditHealthInsurance } from '../PatientPersonalData/EditHealthInsurance'
import { SelectHealthInsurance } from '../PatientPersonalData/SelectHealthInsurance'
import * as S from '../styles'
import { Patient } from 'domain/entities/patient-model'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { useServices } from 'presentation/hooks/use-services'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { toast } from 'react-toastify'

type EmergencyRoomPatientInfoProps = {
  hospitals: Hospital[]
  setForm: (data: SubmitEmergencyAttendance) => void
  form?: SubmitEmergencyAttendance
  patientCarousel: CarouselState
  patient: Patient
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  uploadDocument?: UploadPatientDocument
  editHealthInsurance: () => void
  updatedHealthInsurance: boolean
  setStep: (number: number) => void
  backPatientCarousel: (number: number) => void
}

export type EditedInsurance = {
  card?: string
  healthPlanCode?: number
}

export function PatientSelectUnit({
  hospitals,
  setForm,
  form,
  patientCarousel,
  patient,
  updateHealthInsuranceData,
  uploadDocument,
  editHealthInsurance,
  updatedHealthInsurance,
  setStep,
  backPatientCarousel
}: EmergencyRoomPatientInfoProps) {
  const [openSelectHealthInsurance, setOpenSelectHealthInsurance] =
    useState<boolean>(false)
  const [openEditHealthInsurance, setOpenEditHealthInsurance] =
    useState<boolean>(false)
  const [openAddHealthInsurance, setOpenAddHealthInsurance] =
    useState<boolean>(false)
  const [healthInsurances, setHealthInsurances] = useState(
    [] as HealthInsurancePlans[]
  )
  const [editedInsurance, setEditedInsurance] = useState({} as EditedInsurance)

  const insuranceServices = useServices().healthInsurance

  const loadData = async (id: number) => {
    setForm({
      ...form,
      hospital_id: id
    })

    try {
      const insurances = await insuranceServices.loadHealthInsurancePlans({
        hospital_id: id,
        onlyInOvermind: true,
        params: [
          'code',
          'description',
          'plans {code, description, subPlan{code, description}}',
          'ansRegister'
        ]
      })
      setHealthInsurances(insurances)
    } catch (err) {
      toast.error('Falha ao carregar os convênios.')
    }
  }

  useEffect(() => {
    if (form && form.hospital_id) {
      loadData(form.hospital_id)
    }
    backPatientCarousel(0)
  }, [])

  return (
    <S.PatientInfoContainer style={{ height: '100%' }}>
      <S.PatientInfoWrapper>
        <strong>Em qual unidade deseja realizar o seu atendimento?</strong>
        <div>
          <SelectField
            label="Unidade"
            name="unit"
            items={hospitals
              ?.filter((hospital) => hospital.hasPartnerCode)
              ?.map(({ name, hospital_id, friendly_name }) => ({
                value: hospital_id,
                label: friendly_name || name
              }))}
            onChange={({ target }) => loadData(+target.value)}
            value={form?.hospital_id ?? ''}
          />
        </div>
      </S.PatientInfoWrapper>
      <Button
        fullWidth
        style={{ marginTop: 'auto' }}
        onClick={() => setOpenSelectHealthInsurance(true)}
        disabled={!form?.hospital_id}
      >
        Continuar
      </Button>
      <SelectHealthInsurance
        patient={patient}
        health_insurance_id={form?.health_insurance_id}
        selectedCard={form?.card}
        open={openSelectHealthInsurance}
        onClose={() => setOpenSelectHealthInsurance(false)}
        nextStep={(health_insurance_id?: number) => {
          setForm({ ...form, health_insurance_id })
          patientCarousel.slideNext()
        }}
        onEdit={() => {
          setOpenEditHealthInsurance(true)
          setOpenSelectHealthInsurance(false)
        }}
        onAdd={() => {
          setOpenAddHealthInsurance(true)
          setOpenSelectHealthInsurance(false)
        }}
        hospital_id={form?.hospital_id}
        updatedHealthInsurance={updatedHealthInsurance}
        form={form}
        setForm={setForm}
        editedInsurance={editedInsurance}
        setEditHealthInsurance={editHealthInsurance}
        setStep={setStep}
      />
      <EditHealthInsurance
        patient={patient}
        onClose={() => setOpenEditHealthInsurance(false)}
        open={openEditHealthInsurance}
        onConfirm={() => {
          editHealthInsurance()
          setOpenEditHealthInsurance(false)
          setOpenSelectHealthInsurance(true)
        }}
        updateHealthInsuranceData={updateHealthInsuranceData}
        uploadDocument={uploadDocument}
        nextStep={(
          card: string,
          healthPlanCode: string,
          health_insurance_id: number
        ) => {
          patientCarousel.slideNext()
          setForm({ ...form, card, healthPlanCode, health_insurance_id })
        }}
        healthInsurances={healthInsurances}
        setEditInsurance={(card?: string, healthPlanCode?: number) =>
          setEditedInsurance({ ...editedInsurance, card, healthPlanCode })
        }
      />
      <AddHealthInsurance
        onClose={() => setOpenAddHealthInsurance(false)}
        open={openAddHealthInsurance}
        onConfirm={() => {
          editHealthInsurance()
          setOpenAddHealthInsurance(false)
          setOpenSelectHealthInsurance(true)
        }}
        updateHealthInsuranceData={updateHealthInsuranceData}
        uploadDocument={uploadDocument}
        nextStep={(
          card: string,
          healthPlanCode: string,
          health_insurance_id: number
        ) => {
          patientCarousel.slideNext()
          setForm({ ...form, card, healthPlanCode, health_insurance_id })
        }}
        health_insurance_id={patient.healthInsurance?.health_insurance_id}
        healthInsurances={healthInsurances}
        patient={patient}
        setAddInsurance={(card?: string, healthPlanCode?: number) =>
          setEditedInsurance({ ...editedInsurance, card, healthPlanCode })
        }
      />
    </S.PatientInfoContainer>
  )
}
