import styled from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'

export const StatusCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 24px;

  svg {
    height: 14px;
    width: 14px;
    path {
      fill: ${({ theme }) => theme.ds.colors.secondary700};
    }
  }
`
