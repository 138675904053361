import React from 'react'
import AddAccompanyingLayout from 'presentation/layouts/AddAccompanying'
import { useLocation } from 'react-router'
import { Accompanying } from 'domain/entities/accompanying-model'
import { AddAccompanying as IAddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { UpdateAccompanying } from 'domain/usecases/accompanying/update-accompanying'

type Props = {
  addAccompanying: IAddAccompanying
  updateAccompanying: UpdateAccompanying
}

export default function AddAccompanying({
  addAccompanying,
  updateAccompanying
}: Props) {
  const { state } = useLocation<{ accompanying?: Accompanying }>()
  const accompanying = state?.accompanying
  return (
    <AddAccompanyingLayout
      method={accompanying ? 'update' : 'create'}
      addAccompanying={addAccompanying}
      updateAccompanying={updateAccompanying}
      initialValues={
        accompanying
          ? {
              name: accompanying?.name,
              email: accompanying?.email,
              phone: accompanying?.phone,
              lastName: accompanying?.lastName,
              relationship: accompanying?.relationship,
              sendSMS: accompanying?.sendSMS,
              sendEmail: accompanying?.sendEmail,
              accompanying_id: accompanying?.accompanying_id,
              isMain: accompanying.isMain
            }
          : undefined
      }
    />
  )
}
