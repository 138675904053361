import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import TherapeuticPlanService from 'presentation/contexts/services/therapeutic-plan-service'
import { ApiRepository } from 'repository/api-repository'
import { TherapeuticPlanRepository } from 'repository/repositories/therapeutic-plan/therapeutic-plan-repository'
import { RemoteCreateInitialTherapeuticPlan } from 'service/usecases/create-initial-therapeutic-plan/remote-create-initial-therapeutic-plan'
import { RemoteLoadProtocols } from 'service/usecases/load-protocols/remote-load-protocols'
import { RemoteUploadTherapeuticPlanDocument } from 'service/usecases/upload-therapeutic-plan-document/remote-upload-therapeutic-plan-documen'

export default function makeTherapeuticPlanService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )
  const therapeuticPlanRepository = new TherapeuticPlanRepository(apiRepository)

  const createInitialTherapeuticPlan = new RemoteCreateInitialTherapeuticPlan(
    therapeuticPlanRepository
  )
  const uploadTherapeuticPlanDocument = new RemoteUploadTherapeuticPlanDocument(
    therapeuticPlanRepository
  )
  const loadProtocols = new RemoteLoadProtocols(therapeuticPlanRepository)

  return new TherapeuticPlanService({
    createInitialTherapeuticPlan,
    uploadTherapeuticPlanDocument,
    loadProtocols
  })
}
