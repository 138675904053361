import styled, { css } from 'styled-components'
import { lighten } from 'polished'

export const List = styled.div`
  ${({ theme }) => css`
    background-color: white;
    border-radius: ${theme.border.radius.small};
    margin-top: 35px;
    li {
      flex-direction: row-reverse;
    }

    img.icon {
      width: 28px;
    }
  `}
`

export const AddReport = styled.p`
  ${({ theme }) => css`
    cursor: pointer;
    position: relative;
    letter-spacing: 0.15px;
    margin-top: 15px;
    padding-left: 16px;
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.gray};
    padding-bottom: 16px;
    border-bottom: 1px solid ${lighten(0.2, theme.colors.lightGray)};
  `}
`

export const MoreExamReportButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.small};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => lighten(0.68, theme.colors.gray)};
  }
`
