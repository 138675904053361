import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ReactComponent as EmailIcon } from 'presentation/assets/icons/email-fill.svg'
import * as S from './styled'
import TextField from 'presentation/components/TextField'
import Switch from 'presentation/shared/components/Switch'
import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import { ContactsModel } from 'domain/entities/contacts'
import { IShareExamResult } from 'domain/usecases/exam/share-exam-result'
import { useLocation } from 'react-router-dom'

export type SharedResultFormProps = {
  contacts: ContactsModel[]
  onSubmitForm: (params: IShareExamResult.Params) => void
}

export type LocationType = {
  title: string
  // pdf_url?: string
  // token?: string
  attendance_id?: number
  exam_order_id?: number
  exam_order_item_id?: number
}

const SharedProcedureResultForm = ({
  contacts,
  onSubmitForm
}: SharedResultFormProps) => {
  const { title, attendance_id, exam_order_id, exam_order_item_id } =
    useLocation<LocationType>().state

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      saveContact: false,
      title: '',
      // pdf_url: '',
      // token: '',
      attendance_id: 0,
      exam_order_id: 0,
      exam_order_item_id: 0
    },
    validationSchema,
    onSubmit: ({
      title,
      // pdf_url,
      // token,
      attendance_id,
      exam_order_id,
      exam_order_item_id,
      ...values
    }) => {
      const formattedData = {
        recipient: values,
        title,
        // pdf_url,
        // token,
        attendance_id,
        exam_order_id,
        exam_order_item_id
      }

      return onSubmitForm(formattedData)
    }
  })

  useEffect(() => {
    if (formik.values) {
      formik.setFieldValue('title', title)
      // formik.setFieldValue('pdf_url', pdf_url)
      // formik.setFieldValue('token', token)
      formik.setFieldValue('attendance_id', attendance_id)
      formik.setFieldValue('exam_order_id', exam_order_id)
      formik.setFieldValue('exam_order_item_id', exam_order_item_id)
    }
  }, [])

  const changeSaveContactValue = () =>
    formik.setFieldValue('saveContact', !formik.values.saveContact)

  const selectContact = (contact: ContactsModel) => {
    formik.setFieldValue('email', contact.emailContact)
    formik.setFieldValue('name', contact.name)
  }

  const contactAlreadySaved = React.useMemo(() => {
    const hasEmail = contacts.some(({ emailContact }) => {
      return emailContact === formik.values.email
    })

    return !hasEmail && !!formik.values.email && formik.values.saveContact
  }, [formik.values.email, formik.values.email, formik.values.saveContact])

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <TextField
        placeholder="email@dominio.com"
        label="E-mail"
        name="email"
        onInputChange={formik.handleChange('email')}
        onBlur={formik.handleBlur('email')}
        error={formik.touched.email && formik.errors.email}
        value={formik.values.email}
        required
        icon={<EmailIcon />}
        iconPosition="right"
        iconLocale="inside"
        iconMargin="54px"
      />

      {contactAlreadySaved && (
        <TextField
          label="Informe nome e E-mail"
          placeholder="Nome"
          name="name"
          onInputChange={formik.handleChange('name')}
          onBlur={formik.handleBlur('name')}
          error={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          required
        />
      )}

      <div className="switch-contact">
        <Switch handleSwitch={changeSaveContactValue} />
        <span>Salvar contato</span>
      </div>

      <S.ContactsDiv>
        <Heading as="h3" color="lightGray">
          Contatos
        </Heading>

        <hr />

        <div className="main">
          <S.List>
            {contacts.map((contact) => (
              <li key={contact.id} onClick={() => selectContact(contact)}>
                <strong>{contact.name}</strong>
                <span>{contact.emailContact}</span>
              </li>
            ))}
          </S.List>

          <div className="button-submit">
            <Button fullWidth type="submit" disabled={!formik.isValid}>
              Prosseguir
            </Button>
          </div>
        </div>
      </S.ContactsDiv>
    </S.Wrapper>
  )
}

const validationSchema = yup.object().shape({
  // name: yup.string().when('email', {
  //   is: ('email') => {

  //   },
  // then: yup.string().required()
  // }),

  email: yup.string().email('E-mail inválido').required()
})
export default SharedProcedureResultForm
