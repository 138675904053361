import React from 'react'

import SuggestionsOrCriticismsForm from 'presentation/components/Forms/SuggestionsOrCriticisms'
import { Container } from 'presentation/components/Container'
import Header from 'presentation/components/Header'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'

type Props = {
  createSuggestionOrCriticism?: CreateSuggestionOrCriticism
}

export default function SuggestionsOrCriticismsLayout({
  createSuggestionOrCriticism
}: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <SuggestionsOrCriticismsForm
          createSuggestionOrCriticism={createSuggestionOrCriticism}
        />
      </Container>
    </>
  )
}
