export const getShortName = (name: string) => {
  const splittedName = name.split(' ')
  const firstName = splittedName[0]
  const lastName = splittedName[splittedName.length - 1]
  return `${firstName} ${splittedName.length > 1 ? lastName : ''}`
}

export const getNameInitials = (name: string) => {
  const splittedName = name.split(' ')
  const firstName = splittedName[0]
  const lastName = splittedName[splittedName.length - 1]
  return `${firstName[0]}${
    splittedName.length > 1 ? lastName[0] : firstName[1]
  }`
}

export function convertNameToTitleCase(name: string) {
  const lowercaseWords = ['de', 'da', 'do', 'das', 'dos']
  const words = name?.toLowerCase().split(' ')

  for (let i = 0; i < words?.length; i++) {
    const word = words[i]
    if (!lowercaseWords.includes(word) || i === 0) {
      words[i] = word.charAt(0).toUpperCase() + word.slice(1)
    }
  }

  return words?.join(' ')
}
