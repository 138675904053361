import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import React from 'react'
import * as S from './styles'

type EmergencyRoomConclusionModalProps = {
  onConfirm: () => void
  open: boolean
  token?: string
  unity?: string
  time?: string
}

export function EmergencyRoomConclusionModal({
  onConfirm,
  open,
  token,
  unity,
  time
}: EmergencyRoomConclusionModalProps) {
  return (
    <SheetModal size={448} isOpen={open} onClose={onConfirm}>
      <S.Container>
        <S.HeaderContainer>
          <Heading size="large">Seu token é </Heading>
          <Heading size="large" color="primary">
            {token}
          </Heading>
        </S.HeaderContainer>
        Caso você não compareça no horário estimado, seu pré cadastro irá
        expirar
        <S.LabelContainer>
          <label>
            Unidade: <div>{unity} </div>
          </label>
          <label>
            Previsão de chegada: <div>{time} </div>
          </label>
        </S.LabelContainer>
        <Button
          fullWidth
          style={{ marginTop: '20px' }}
          onClick={() => onConfirm()}
        >
          Confirmar
        </Button>
      </S.Container>
    </SheetModal>
  )
}
