import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { object, number } from 'yup'

import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import ProfilePic from 'presentation/components/ProfilePic'
import SelectField from 'presentation/shared/components/SelectField'
import TextField from 'presentation/components/TextField'
import Modal from 'presentation/shared/components/Modal'
import { activityFrequencies } from 'presentation/utils/default-physical-activity-frequency'
import Chip from 'presentation/shared/components/Chip'
import * as S from './styles'
import { LostWeight } from 'common/enum/weightLost'
import { PhysicalActivityFrequency } from 'common/enum/physical-activity-frequency'
import { UpdateLifeHabits } from 'domain/usecases/patient/update-life-habits'
import { lostWeight } from 'presentation/utils/lost-weight'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import ActualPage from 'presentation/components/ActualPage'
import { ReactComponent as HelpIcon } from 'presentation/assets/icons/question-mark.svg'
import { HelpTooltip } from 'presentation/shared/components/HelpTooltip'

type Props = {
  updateLifeHabits?: UpdateLifeHabits
}

export default function LifeHabitsForm({ updateLifeHabits }: Props) {
  const [successModal, setSuccessModal] = useState(false)
  const [showTip, setShowTip] = useState(false)
  const [initialValues, setInitialValues] = useState<
    Partial<LifeHabitsFormValues>
  >({} as LifeHabitsFormValues)
  const service = useServices().patient
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      const patient = await service.loadPatientInfo([
        'lifeHabits {isAlcoholic',
        'isEatingLess',
        'dietaryRestrictions',
        'isSmoker',
        'physicalActivityFrequency',
        'lostWeight',
        'cigarettesQuantity',
        'alcoholFrequency',
        'hasLostWeight}'
      ])
      setInitialValues({
        ...patient.lifeHabits,
        physicalActivityFrequency: patient.lifeHabits?.physicalActivityFrequency
      })
    })()
  }, [])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        await updateLifeHabits?.update({
          ...values,
          isAlcoholic:
            values.isAlcoholic === 'true' || values.isAlcoholic === true,
          isEatingLess:
            values.isEatingLess === 'true' || values.isEatingLess === true,
          isSmoker: values.isSmoker === 'true' || values.isSmoker === true,
          hasLostWeight:
            values.hasLostWeight === 'true' || values.hasLostWeight === true,
          alcoholFrequency: isNegativeAnswer('isAlcoholic')
            ? 0
            : Number(values.alcoholFrequency),
          cigarettesQuantity: isNegativeAnswer('isSmoker')
            ? 0
            : Number(values.cigarettesQuantity)
        })
        setSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  useEffect(() => {
    if (formik.values.hasLostWeight === 'false') {
      formik.setFieldValue('lostWeight', undefined)
    }
  }, [formik.values.hasLostWeight])

  const user = useStores().currentAccount.getCurrentAccount()

  const isNegativeAnswer = (field: string) => {
    //@ts-ignore
    return !formik.values[field] || formik.values[field] === 'false'
  }

  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <ActualPage text="Voltar" />
      <ProfilePic
        outlined
        size="large"
        style={{
          display: 'block',
          margin: '0 auto',
          marginTop: '36px',
          marginBottom: '4px'
        }}
      />
      <p>{user?.user.name}</p>
      <Heading as="h2" color="primary" style={{ marginBottom: '20px' }}>
        Hábitos de vida
      </Heading>
      <S.Label>Atividade física</S.Label>
      <S.Activities>
        {activityFrequencies.map((frequency) => (
          <Chip
            label={frequency.label}
            value={frequency.value}
            key={frequency.label}
            name="physicalActivityFrequency"
            onCheck={formik.handleChange('physicalActivityFrequency')}
            checked={
              formik.values.physicalActivityFrequency === frequency.value
            }
          />
        ))}
      </S.Activities>
      <TextField
        label="Minhas restrições alimentares"
        onInputChange={formik.handleChange('dietaryRestrictions')}
        defaultValue={formik.values.dietaryRestrictions}
      />
      <S.DropdownContainer>
        <SelectField
          label="Tabagista"
          value={
            formik.values.isSmoker === true || formik.values.isSmoker === 'true'
              ? 'true'
              : 'false'
          }
          items={[
            {
              label: 'Não',
              value: 'false'
            },
            {
              label: 'Sim',
              value: 'true'
            }
          ]}
          onInputChange={formik.handleChange('isSmoker')}
        />
        <TextField
          type="number"
          min={0}
          label="Quantos cigarros por dia?"
          placeholder="00"
          onInputChange={formik.handleChange('cigarettesQuantity')}
          value={
            isNegativeAnswer('isSmoker') ? '' : formik.values.cigarettesQuantity
          }
          disabled={
            !formik.values.isSmoker || formik.values.isSmoker === 'false'
          }
        />
      </S.DropdownContainer>
      <S.DropdownContainer>
        <SelectField
          label="Etilista"
          value={
            formik.values.isAlcoholic === true ||
            formik.values.isAlcoholic === 'true'
              ? 'true'
              : 'false'
          }
          items={[
            {
              label: 'Não',
              value: 'false'
            },
            {
              label: 'Sim',
              value: 'true'
            }
          ]}
          onInputChange={formik.handleChange('isAlcoholic')}
        />
        <TextField
          type="number"
          min={0}
          label="Unidade"
          placeholder="00"
          onInputChange={formik.handleChange('alcoholFrequency')}
          value={
            isNegativeAnswer('isAlcoholic')
              ? ''
              : formik.values.alcoholFrequency
          }
          disabled={
            !formik.values.isAlcoholic || formik.values.isAlcoholic === 'false'
          }
          labelIcon={
            <HelpTooltip
              title="Unidade"
              text={
                <>
                  <strong>Uma</strong> unidade corresponde a uma lata de
                  cerveja, uma taça de vinho ou qualquer outra bebida alcoólica
                  destilada ingerida nos últimos 30 dias. <br /> Para os homens,
                  considera-se consumo abusivo, <strong>cinco</strong> ou mais
                  doses e para as mulheres, <strong>quatro</strong> ou mais
                  doses.
                </>
              }
              onClose={() => setShowTip(false)}
              open={showTip}
            >
              <HelpIcon onClick={() => setShowTip(true)} />
            </HelpTooltip>
          }
        />
      </S.DropdownContainer>
      <S.DropdownContainer fullWidth>
        <SelectField
          style={{ width: '100%' }}
          label="Teve perda de peso não intencional?"
          value={
            formik.values.hasLostWeight === true ||
            formik.values.hasLostWeight === 'true'
              ? 'true'
              : 'false'
          }
          name="hasLostWeight"
          items={[
            {
              label: 'Não',
              value: 'false'
            },
            {
              label: 'Sim',
              value: 'true'
            }
          ]}
          onChange={(e) => {
            formik.handleChange(e)
            formik.setFieldValue('lostWeight', undefined)
          }}
        />
      </S.DropdownContainer>
      <S.Label
        disabled={
          !formik.values.hasLostWeight ||
          formik.values.hasLostWeight === 'false'
        }
      >
        Se sim, quantos quilos foram perdidos?
      </S.Label>
      <S.Activities>
        {lostWeight.map((weight) => (
          <Chip
            label={weight.label}
            value={weight.value}
            key={weight.label}
            name="lostWeight"
            onCheck={formik.handleChange('lostWeight')}
            checked={formik.values.lostWeight === weight.value}
            disabled={
              !formik.values.hasLostWeight ||
              formik.values.hasLostWeight === 'false'
            }
          />
        ))}
      </S.Activities>
      <S.DropdownContainer fullWidth>
        <SelectField
          label="Está comendo menos devido a falta de apetite?"
          value={
            formik.values.isEatingLess === true ||
            formik.values.isEatingLess === 'true'
              ? 'true'
              : 'false'
          }
          items={[
            {
              label: 'Não',
              value: 'false'
            },
            {
              label: 'Sim',
              value: 'true'
            }
          ]}
          onInputChange={formik.handleChange('isEatingLess')}
        />
      </S.DropdownContainer>
      <Button
        type="submit"
        fullWidth
        style={{ marginTop: '37px' }}
        disabled={!formik.isValid}
      >
        Salvar
      </Button>
      <Modal
        title="Dados atualizados com sucesso !"
        show={successModal}
        close={history.goBack}
      />
    </S.Wrapper>
  )
}

type LifeHabitsFormValues = {
  physicalActivityFrequency: PhysicalActivityFrequency
  dietaryRestrictions: string
  isSmoker: boolean | string
  cigarettesQuantity: number
  isAlcoholic: boolean | string
  alcoholFrequency: number
  hasLostWeight: boolean | string
  lostWeight: LostWeight
  isEatingLess: boolean | string
}

const validationSchema = object().shape({
  cigarettesQuantity: number()
    .nullable()
    .when('isSmoker', {
      is: 'true',
      then: number().min(1).required('Obrigatório').positive()
    }),
  alcoholFrequency: number()
    .nullable()
    .when('isAlcoholic', {
      is: 'true',
      then: number().min(1).required('Obrigatório').positive()
    })
})
