import { CardWrapper } from 'presentation/shared/components/Card'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Header = styled.header`
  background-color: white;
  padding: 24px 24px 0 24px;
`

const HeadInfoModifier = media.greaterThan('small')`
  padding: 0px 40px;
`

export const HeadInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    padding-left: 10px;
  }
  ${HeadInfoModifier}
`

export const LocaleInfoBox = styled(CardWrapper)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: rgba(165, 216, 172, 0.2);
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 18px;
  min-height: 81px;
`

export const LocaleInfo = styled.div`
  line-height: 118%;
  label {
    font-weight: 400;
  }

  strong {
    font-weight: 700;
    padding-left: 8px;
  }

  & + & {
    line-height: 21px;
  }
`

export const InfoList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  align-items: center;
  font-size: 16px;
  margin-top: 28px;
  color: ${({ theme }) => theme.colors.primary};

  a {
    cursor: pointer;
    padding: 18px 0px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    position: relative;

    &.selected {
      font-weight: 700;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

export const StatusContainer = styled.div`
  h2 {
    font-weight: ${({ theme }) => theme.font.black};
    margin-bottom: 20px;
  }
  p {
    font-style: italic;
    margin: 24px 0px;
    text-align: center;
  }
`

export const Content = styled.div`
  padding: 0;
`
