import React from 'react'
import CalendarForm from 'presentation/components/Forms/Calendar'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { SchedulingSchedulesByMonth } from 'domain/entities/scheduling-schedule-by-month-model'
import HeaderNew from 'presentation/components/HeaderNew'

type Props = {
  availableDates?: SchedulingSchedulesByMonth[]
  availableHours?: SchedulingHours[]
  dateInitial: string
  getSchedulesByMonth: (date: string) => void
  getHoursByDay: (date: string) => void
  submitSchedule: (time: number) => void
}

export default function CalendarLayout({
  availableDates,
  availableHours,
  dateInitial,
  getSchedulesByMonth,
  getHoursByDay,
  submitSchedule
}: Props) {
  return (
    <>
      <HeaderNew actualPageTitle="Agendar consulta" />
      <CalendarForm
        availableDates={availableDates}
        availableHours={availableHours}
        dateInitial={dateInitial}
        getSchedulesByMonth={getSchedulesByMonth}
        getHoursByDay={getHoursByDay}
        submitSchedule={submitSchedule}
      />
    </>
  )
}
