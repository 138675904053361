import React, { useState, useEffect } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter
} from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

import PrivateRoute from 'presentation/shared/components/PrivateRoute'
import makePatientHome from 'main/factories/pages/home/patient-home-factory'
import makePatientLogin from 'main/factories/pages/login/patient-login-factory'
import makePasswordRecovery from 'main/factories/pages/password-recovery/patient-password-recovery-factory'
import makeAddPainAlert from 'main/factories/pages/add-pain-alert/patient-add-pain-alert-factory'
import makePatientSignup from 'main/factories/pages/signup/patient-signup-factory'
import makePatientProfile from 'main/factories/pages/profile/patient-profile-factory'
import makePatientAllergies from 'main/factories/pages/allergy/patient-allergy-factory'
import makePatientLifeHabits from 'main/factories/pages/life-habits/patient-life-habits-factory'
import makeEmailConfirmation from 'main/factories/pages/email-confirmation/email-confirmation-factory'
import makePatienSurgeryInfo from 'main/factories/pages/surgery-info/patient-surgery-info-factory'
import makePatientSolicitation from 'main/factories/pages/solicitation/patient-solicitation-factory'
import makePatientAccompanyings from 'main/factories/pages/accompanyings/patient-accompanyings-factory'
import makeDoctorAnswerPendency from 'main/factories/pages/pendency/doctor-pendency-factory'
import makePatientCompleteSignup from 'main/factories/pages/complete-signup/patient-complete-signup-factory'
import makePatientSurgeryDetails from 'main/factories/pages/old-surgery-details/patient-surgery-details-factory'
import makePatientHospitalization from 'main/factories/pages/hospitalization/patient-hospitalization-factory'
import makeAddPatientAccompanyings from 'main/factories/pages/add-accompanying/add-accompanying-factory'
import makePatientSaveSurgicalOrder from 'main/factories/pages/save-surgical-order/save-surgical-order'
import makePatientUpdatePersonalInfo from 'main/factories/pages/update-personal-info/patient-update-personal-info-factory'
import makePatientMedicineAndDiseases from 'main/factories/pages/medicines-and-diseases/patient-medicines-and-diseases-factory'
import makePatientEmergencyRoomService from 'main/factories/pages/emergency-room-service/patient-emergency-room-service'
import makePatientSuggestionOrCriticism from 'main/factories/pages/suggestion-or-criticism/patient-suggestion-or-criticism-factory'
import makePatientSaveSurgicalOrderPersonalInfo from 'main/factories/pages/save-surgical-order-personal-info/save-surgical-order-personal-info'
import makePatientSaveSurgicalOrderInsuranceInfo from 'main/factories/pages/save-surgical-order-insurance-info/save-surgical-order-insurance-info'
import makePatientHealthInsurancesList from 'main/factories/pages/patient-health-insurances-list/patient-health-insurances-list'
import makePatientHealthInsuranceUpdate from 'main/factories/pages/patient-health-insurance-update/patient-health-insurance-update'
import makePatientHealthInsuranceAdd from 'main/factories/pages/patient-health-insurance-add/patient-health-insurance-add'
import { makeSharedExamResultPage } from 'main/factories/pages/shared-exam-result/shared-exam-result-factory'
import makeEmergencyRoomAttendance from 'main/factories/pages/emergency-room-attendance/emergency-room-attendance'

import Help from 'presentation/pages/Help'
import Checkin from 'presentation/pages/Checkin'
import Services from 'presentation/pages/Services'
import Attendances from 'presentation/pages/Attendances'
import ExamsResults from 'presentation/pages/ExamsResults'
import AddDocuments from 'presentation/pages/CreateSurgicalOrder/AddDocuments'
import Appointments from 'presentation/pages/Appointments'
import MyDocuments from 'presentation/pages/MyDocuments'
import InfoAndAccess from 'presentation/layouts/InfoAndAccess'
import VaccineDetails from 'presentation/pages/VaccineDetails'
import NewbornPackages from 'presentation/pages/NewbornPackages'
import AddSurgicalOrder from 'presentation/pages/CreateSurgicalOrder/AppendSurgicalOrder'
import RegisterValidate from 'presentation/pages/RegisterValidate'
import SurgeryStatusDetail from 'presentation/pages/SurgeryStatusDetail'
import InvalidRegisterLayout from 'presentation/layouts/InvalidRegister'
import NewbornPackagesDetails from 'presentation/pages/NewbornPackagesDetails'
import CreateSurgicalOrderIntro from 'presentation/pages/CreateSurgicalOrder/Intro'
import PatientCalendar from 'presentation/pages/Calendar'
import { MedicalReports } from 'presentation/pages/MedicalReports'
import { PatientPendencies } from 'presentation/pages/Pendencies'
import { SurgicalJourneyOrientations } from 'presentation/layouts/SurgicalJourney'
import { AppendSurgicalOrderDocument } from 'presentation/pages/AppendSurgicalOrderDocument'
import makeOnboarding from 'main/factories/pages/onboarding/onboarding-factory'
import SchedulingUpdatePersonalInfo from 'presentation/pages/SchedulingUpdatePersonalInfo'
import SchedulingSearch from 'presentation/pages/SchedulingSearch'
import SchedulingHealthInsurance from 'presentation/pages/SchedulingHealthInsurance'
import SchedulingAvailableDates from 'presentation/pages/SchedulingAvailableDates'
import SchedulingConfirm from 'presentation/pages/SchedulingConfirm'
import SchedulingConfirmed from 'presentation/pages/SchedulingConfirmed'
import SchedulingDetails from 'presentation/pages/SchedulingDetails'
import SchedulingCancel from 'presentation/pages/SchedulingCancel'
import SchedulingNps from 'presentation/pages/SchedulingNps'

import TutorTutorual from 'presentation/pages/TutorTutorial'
import UserTerms from 'presentation/pages/UserTerms'

import makeSurgicalOrderPage from 'main/factories/pages/surgical-order/surgical-order-factory'
import SchedulingExams from 'presentation/pages/SchedulingExams'
import ExamsPreparations from 'presentation/pages/ExamsPreparations'
import ExamPreparationInstructions from 'presentation/pages/ExamPreparationInstructions'
import PreAnesthesicScreen from 'presentation/pages/PreAnesthesicScreen'
import ReasonNotSurgery from 'presentation/pages/ReasonNotSurgery'
import NewDateSurgeryHelpLayout from 'presentation/layouts/NewDateSurgeryHelpLayout'
import { ExamResultList } from 'presentation/pages/ExamResultList'
import ViwerExamResultPage from 'presentation/pages/PdfViwer'

export const ScrollToTop = withRouter(() => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
})

const SwitchRouter = withRouter(() => {
  const { pathname } = useLocation()
  const stores = useStores()
  const currentUser = stores.currentAccount
  const [pendingTerms, setPendingTerms] = useState<boolean>(false)

  const isPendingTerms = currentUser
    .getCurrentAccount()
    ?.user?.terms?.some((term) => term.signed === false)

  const pendingTermsOfUse = () =>
    currentUser.getCurrentAccount()?.accessToken && isPendingTerms

  useEffect(() => {
    setPendingTerms(!!pendingTermsOfUse())
  }, [pathname])

  return (
    <>
      {pendingTerms ? (
        <Switch>
          <PrivateRoute
            path="/termos-de-uso"
            exact
            component={UserTerms}
            profile={[Profile.PATIENT]}
          />

          <Route
            path="/*"
            component={() => {
              return (
                <>
                  <Redirect to="/termos-de-uso" />
                </>
              )
            }}
          />
        </Switch>
      ) : (
        <Switch>
          <Route
            path="/"
            exact
            component={() => {
              return (
                <>
                  <Redirect
                    to={
                      currentUser.getCurrentAccount()?.accessToken
                        ? '/home'
                        : '/login'
                    }
                  />
                </>
              )
            }}
          />
          <Route path="/cadastro" exact component={makePatientSignup} />
          <Route
            path="/cadastro/:hash"
            exact
            component={makePatientCompleteSignup}
          />
          <Route
            path="/login"
            exact
            component={() => makePatientLogin(Profile.PATIENT)}
          />
          <Route
            path="/recuperar-senha/:type"
            exact
            component={() => makePasswordRecovery({ client: Profile.PATIENT })}
          />
          <PrivateRoute
            path="/pedido-cirurgico"
            exact
            component={CreateSurgicalOrderIntro}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pendencias"
            exact
            component={PatientPendencies}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pendencia"
            exact
            component={makeDoctorAnswerPendency}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save"
            exact
            component={makePatientSaveSurgicalOrder}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/dados-pessoais"
            exact
            component={makePatientSaveSurgicalOrderPersonalInfo}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/dados-convenio"
            exact
            component={makePatientSaveSurgicalOrderInsuranceInfo}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/documentos"
            exact
            component={AddDocuments}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pedido-cirurgico/save/documentos/anexo"
            exact
            component={AddSurgicalOrder}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/home"
            exact
            component={makePatientHome}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/onboarding"
            exact
            component={makeOnboarding}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/solicitacao-de-servico/status"
            exact
            component={makeEmergencyRoomAttendance}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/confirmar-dados"
            exact
            component={SchedulingUpdatePersonalInfo}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/convenios"
            exact
            component={SchedulingHealthInsurance}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/busca"
            exact
            component={SchedulingSearch}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/datas"
            exact
            component={SchedulingAvailableDates}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/calendario"
            component={PatientCalendar}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/resumo"
            exact
            component={SchedulingConfirm}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/confirmado"
            exact
            component={SchedulingConfirmed}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/agendamento/consulta/avalie"
            component={SchedulingNps}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/consultas"
            component={Appointments}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/meus-documentos"
            component={MyDocuments}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/consulta/detalhes"
            exact
            component={SchedulingDetails}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/consulta/cancelamento"
            exact
            component={SchedulingCancel}
            profile={[Profile.PATIENT]}
          />

          {/* Reason Not Attend Surgery */}
          <PrivateRoute
            path="/cirurgias-nao-relizadas/motivo"
            component={ReasonNotSurgery}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/confirmacao-ajuda-cirurgia"
            component={NewDateSurgeryHelpLayout}
            profile={[Profile.PATIENT]}
          />

          {/*---------------------------*/}

          {/* Old */}
          <PrivateRoute
            path="/cirurgia"
            component={makePatientSurgeryDetails}
            profile={[Profile.PATIENT]}
          />
          {/* /Old */}
          <PrivateRoute
            path="/pedido-cirurgico/detalhes"
            component={makeSurgicalOrderPage}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/convenios"
            component={makePatientHealthInsurancesList}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/convenio/editar"
            component={makePatientHealthInsuranceUpdate}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/convenio/novo"
            component={makePatientHealthInsuranceAdd}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/orientacoes/cirurgicas"
            component={makePatienSurgeryInfo}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/ajuda"
            component={Help}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/servicos"
            component={Services}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/atendimentos"
            component={Attendances}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/agendamento/exames/avalie"
            // component={<SchedulingNps campaignFeature="exam-schedule" />}
            render={() => <SchedulingNps campaignFeature="exam-schedule" />}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/agendamento/exames/pre-anestesica"
            component={PreAnesthesicScreen}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/agendamento/exames"
            component={SchedulingExams}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exames/preparos/instrucoes"
            exact
            component={ExamPreparationInstructions}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exames/preparos"
            component={ExamsPreparations}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/internacao"
            component={makePatientHospitalization}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/upload/documentos/:type"
            exact
            component={AppendSurgicalOrderDocument}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/upload/laudos"
            exact
            component={MedicalReports}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/detalhes-status"
            exact
            component={SurgeryStatusDetail}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/acompanhantes"
            exact
            component={makePatientAccompanyings}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/acompanhantes/save"
            exact
            component={makeAddPatientAccompanyings}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/checkin"
            exact
            component={Checkin}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/como-estou"
            exact
            component={makeAddPainAlert}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/solicitacao"
            exact
            component={makePatientSolicitation}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/habitos"
            exact
            component={makePatientLifeHabits}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/sugestoes-criticas"
            exact
            component={makePatientSuggestionOrCriticism}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/medicamentos-doencas"
            exact
            component={makePatientMedicineAndDiseases}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/informacoes-pessoais"
            exact
            component={makePatientUpdatePersonalInfo}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/alergia"
            exact
            component={makePatientAllergies}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/solicitar-atendimento"
            exact
            component={makePatientEmergencyRoomService}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/pacotes-recem-nascido"
            exact
            component={NewbornPackages}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/detalhes-do-pacote"
            exact
            component={NewbornPackagesDetails}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/detalhes-da-vacina"
            exact
            component={VaccineDetails}
            profile={[Profile.PATIENT]}
          />
          <PrivateRoute
            path="/minha-jornada"
            exact
            component={SurgicalJourneyOrientations}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/acessos"
            exact
            component={InfoAndAccess}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/perfil"
            exact
            component={makePatientProfile}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/confirmar-email"
            exact
            component={makeEmailConfirmation}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exames"
            exact
            component={ExamsResults}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exame/resultado"
            exact
            component={ExamResultList}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exame/resultado/pdf"
            exact
            component={ViwerExamResultPage}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/exame/resultado/compartilhar"
            exact
            component={makeSharedExamResultPage}
            profile={[Profile.PATIENT]}
          />

          <PrivateRoute
            path="/tutorial/tutor"
            exact
            component={TutorTutorual}
            profile={[Profile.PATIENT]}
          />

          <Route
            path="/cadastro-validacao"
            exact
            component={RegisterValidate}
          />

          <Route
            path="/invalid-register"
            exact
            component={InvalidRegisterLayout}
          />

          <Route path="/*/redirect-vuemotion" />

          {/* Test only */}
          {/* <PrivateRoute
            path="/datagrid-examples"
            exact
            component={DataGridExamples}
            profile={[Profile.PATIENT]}
          /> */}

          <Route path="/*" component={() => <Redirect to="/" />} />
        </Switch>
      )}
    </>
  )
})

const PatientRouter = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SwitchRouter />
    </BrowserRouter>
  )
}

export default PatientRouter
