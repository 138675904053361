import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteCompletePatientSignup } from 'service/usecases/complete-patient-signup/remote-complete-patient-signup'
import { CompletePatientSignup } from 'presentation/pages/CompletePatientSignup'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { RemoteLoadUserByInviteToken } from 'service/usecases/load-user-by-invite-token/remote-load-user-by-invite-token'

export default function makePatientCompleteSignup() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const patientRepository = new PatientRepository(authApiRepository)
  const authRepository = new AuthenticationRepository(authApiRepository)
  const patientCompleteSignup = new RemoteCompletePatientSignup(
    patientRepository
  )
  const loadUserByInviteToken = new RemoteLoadUserByInviteToken(authRepository)
  return (
    <CompletePatientSignup
      patientCompleteSignup={patientCompleteSignup}
      loadUserByInviteToken={loadUserByInviteToken}
    />
  )
}
