import { LoadCurrentRegisterPendency } from 'domain/usecases/register-pendency/load-current-register-pendency'
import { RegisterPendencyRepository } from 'repository/interfaces/register-pendency-repository'

export class RemoteLoadCurrentRegisterPendency
  implements LoadCurrentRegisterPendency
{
  constructor(
    private readonly registrationStatusRepository: RegisterPendencyRepository
  ) {}

  loadCurrentRegisterPendency(
    params: LoadCurrentRegisterPendency.Params
  ): Promise<LoadCurrentRegisterPendency.Model> {
    return this.registrationStatusRepository.loadCurrentRegisterPendency(params)
  }
}
