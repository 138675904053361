import styled from 'styled-components'
import { CardWrapper } from 'presentation/shared/components/Card'

export const CardHeader = styled.div`
  .health-insurance-name {
    color: ${({ theme }) => theme.ds.colors.primary600};
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
  }

  .health-insurance-title {
    margin: 4px 0;
    color: ${({ theme }) => theme.ds.colors.neutral700};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    text-transform: capitalize;
  }
`

export const CardBody = styled.div`
  color: #707683;
  strong {
    font-weight: 500;
  }
`

export const CardFooter = styled.footer`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

export const HealthInsuranceCardWrapper = styled(CardWrapper)`
  position: relative;
  padding: 16px;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  &.-disabled {
    pointer-events: none;
    opacity: 0.8;

    ${CardHeader} {
      .health-insurance-type,
      .health-insurance-title,
      .health-insurance-name {
        color: ${({ theme }) => theme.ds.colors.neutral400};
      }
    }

    ${CardBody} {
      color: ${({ theme }) => theme.ds.colors.neutral400};
    }
  }
`
