import React, { useEffect, useState } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'

import HeaderNew from 'presentation/components/HeaderNew'
import { useHistory } from 'react-router-dom'
import AutoCompleteNew from 'presentation/components/AutoCompleteNew'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search-icon.svg'
import { ReactComponent as RedAlertCircle } from 'presentation/assets/icons/red-alert-circle.svg'
import { ReactComponent as NoExamPreparation } from 'presentation/assets/empty-states/no-exam-preparation.svg'
import ExamPreparationCard from 'presentation/shared/components/ExamPreparationCard'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import { useServices } from 'presentation/hooks/use-services'
import { Patient } from 'domain/entities/patient-model'
import { toast } from 'react-toastify'

export type PreparationType = {
  exam_id: number
  title: string
  description: string
  alias: string
  hospital_id: number
}

const allUnits = [
  {
    value: 0,
    label: 'Selecionar'
  },
  {
    value: 7,
    label: 'Betim-Contagem'
  },
  {
    value: 19,
    label: 'CDI'
  },
  {
    value: 6,
    label: 'Contorno'
  },
  {
    value: 22,
    label: 'EMEC'
  },
  {
    value: 21,
    label: 'Premium'
  },
  {
    value: 8,
    label: 'Salvador/Bahia'
  },
  {
    value: 23,
    label: 'Sta Clara'
  },
  {
    value: 18,
    label: 'Sta Genoveva'
  },
  {
    value: 1,
    label: 'Sto Agostinho'
  }
]

export type ExamPreparationType = {
  title: string
  description: string
  exam_id: number
}

const ExamsPreparationsLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()

    async function handleShowPreparation(selectedPreparation: PreparationType) {
      if (!selectedUnit) {
        return toast.error('Selecione a unidade')
      }
      try {
        setIsLoading(true)

        if (selectedPreparation) {
          history.push('/exames/preparos/instrucoes', {
            selectedExam: [selectedPreparation]
          })
        } else {
          toast.error(
            'Não foi encontrada nenhuma instrução de preparo para a o exame e unidade selecionada'
          )
          return
        }
      } catch (error) {
        toast.error(
          'Não foi encontrada nenhuma instrução de preparo para a o exame e unidade selecionada'
        )
      } finally {
        setIsLoading(false)
      }
    }

    const patientService = useServices().patient

    const [patient, setPatient] = useState<Patient>()

    const [selectedUnit, setSelectedUnit] = useState() as any

    const loadPatientData = async () => {
      try {
        setIsLoading(true)
        const response = await patientService.loadPatientInfo([
          'patient_id',
          'user_id',
          'name',
          'birthday',
          'gender',
          'phone',
          'email',
          'cpf',
          `
          healthInsurances {
            health_insurance_id
            healthCard
            healthInsuranceCode
            healthInsuranceName
            healthPlanCode
            healthPlanName
            healthSubPlanCode
            healthSubPlanName
            ansRegister
            validThru
          }
          `
        ])
        setPatient(response)
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os dados')
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      loadPatientData()
    }, [])

    const examService = useServices().exam

    const examPreparation = false

    const [examQuery, setExamQuery] = useState<string>()
    const [examSearchResult, setExamsSearchResult] = useState() as any
    const [selectedPreparation, setSelectedPreparation] =
      useState<PreparationType>()
    const [noUnitForExamVisible, setNoUnitForExamVisible] = useState(false)

    const handleSelectUnit = (unit: string) => {
      setSelectedUnit(unit)
      resetSelectedExam()
      setNoUnitForExamVisible(false)
    }

    const resetSelectedExam = async () => {
      setExamQuery(undefined)
      setExamsSearchResult([])
      setSelectedPreparation(undefined)
    }

    const searchExams = async (input: string) => {
      setExamsSearchResult(undefined)
      if (patient) {
        const response = await examService.getExamPreparationWithParams({
          exam: input,
          hospital_id: +selectedUnit
        })

        setExamsSearchResult(response)
      }
    }

    function selectExam(preparation: PreparationType) {
      const unitFound = preparation.hospital_id === +selectedUnit

      if (!preparation.hospital_id) {
        return toast.error(
          'Não foi encontrada instrução de preparo para este hospital'
        )
      }

      if (!preparation.description) {
        return toast.error(
          'Não foi encontrada instrução de preparo para este exame no hospital selecionado'
        )
      }

      if (!unitFound) {
        setNoUnitForExamVisible(true)
      } else {
        setSelectedPreparation(preparation)
        setNoUnitForExamVisible(false)
      }
    }
    return (
      <>
        <HeaderNew
          actualPageTitle="Preparos de exames"
          actualPageOnClick={() =>
            history.push('/servicos', {
              anchor: 'exams'
            })
          }
        />

        <ContainerNew
          title="Preparos de exames"
          titleColor="secondary700"
          titleWeight="bold"
          titleSize="medium"
          subtitle="Consulte o preparo necessário para realizar um exame de acordo
          com a unidade."
        >
          {examPreparation ? (
            <S.EmptyStateContainer>
              <NoExamPreparation />
            </S.EmptyStateContainer>
          ) : (
            <S.Wrapper>
              <S.HeaderContainer>
                <SelectFieldNew
                  label="Unidade em que o exame será feito"
                  items={allUnits}
                  onInputChange={(e) => {
                    handleSelectUnit(e)
                  }}
                  required
                  requiredColor="neutral800"
                />

                {selectedUnit && selectedUnit !== '0' && (
                  <AutoCompleteNew
                    label="Nome do exame"
                    placeholder="Digite o nome do exame"
                    icon={<SearchIcon />}
                    value={examQuery || ''}
                    onType={(input) => searchExams(input)}
                    onInputChange={setExamQuery}
                    suggestions={examSearchResult?.map(
                      (preparation: PreparationType) => ({
                        label: preparation.title?.toLocaleLowerCase(),
                        value: preparation
                      })
                    )}
                    onSuggestionClick={(e) => selectExam(e)}
                    clear={() => resetSelectedExam()}
                    required
                    requiredColor="neutral800"
                    data-testid="select-exam-autocomplete"
                    debounceDelay={1000}
                    minCharactersSearch={2}
                    autoFocus={true}
                    style={{ marginTop: '24px' }}
                  />
                )}
              </S.HeaderContainer>

              {noUnitForExamVisible && (
                <S.AlertCard>
                  <div>
                    <RedAlertCircle />
                  </div>
                  <span>
                    Este exame não é realizado na unidade selecionada. Faça uma
                    nova busca.
                  </span>
                </S.AlertCard>
              )}

              {selectedPreparation && (
                <ExamPreparationCard
                  title={selectedPreparation?.title}
                  description={selectedPreparation?.alias}
                  onClick={() => handleShowPreparation(selectedPreparation)}
                />
              )}
            </S.Wrapper>
          )}
        </ContainerNew>
      </>
    )
  }
)

export default ExamsPreparationsLayout
