import { ApiSurgicalScheduleImplementation } from 'infra/api-surgical-schedule/api-surgical-schedule-client'

import { PrepareApiRequestsurgicalScheduleDecorator } from 'main/decorators/prepare-api-request-surgical-schedule-decorator/prepare-api-request-surgical-schedule-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'

import { ApiSurgicalScheduleRepository } from 'repository/rest/api-surgical-schedule/api-surgical-schedule-repository'

import surgicalScheduleService from 'presentation/contexts/services/surgical-schedule-service'
import { SurgicalScheduleRepository } from 'repository/repositories/surgical-schedule/surgical-schedule-repository'
import { RemoteLoadSchedules } from 'service/usecases/load-schedules/remote-load-schedules'
import { RemoteLoadSchedule } from 'service/usecases/load-schedule/remote-load-schedule'
import { RemoteLoadEquipaments } from 'service/usecases/load-equipaments/remote-load-equipaments'
import { RemoteLoadHospitalSurgeryCenter } from 'service/usecases/load-hospital-surgery-center/remote-load-hospital-surgery-center'
import { RemoteLoadSurgicalScheduleAvailableDays } from 'service/usecases/load-surgical-schedule-available-days/remote-load-surgical-schedule-available-days'
import { RemoteLoadSurgeryCenterCalendar } from 'service/usecases/load-surgery-center-calendar/load-surgery-center-calendar'
import { RemoteFinishSurgicalSchedule } from 'service/usecases/finish-surgical-schedule/remote-finish-surgical-schedule'

export default function makeSurgicalScheduleService() {
  const apisurgicalSchedule = new ApiSurgicalScheduleImplementation()

  const apiSurgicalScheduleRepository =
    new PrepareApiRequestsurgicalScheduleDecorator(
      makeLocalStorageAdapter(),
      new ApiSurgicalScheduleRepository(apisurgicalSchedule)
    )

  const surgicalScheduleRepository = new SurgicalScheduleRepository(
    apiSurgicalScheduleRepository
  )

  const loadSurgicalSchedules = new RemoteLoadSchedules(
    surgicalScheduleRepository
  )

  const loadSurgicalSchedule = new RemoteLoadSchedule(
    surgicalScheduleRepository
  )

  const loadEquipaments = new RemoteLoadEquipaments(surgicalScheduleRepository)

  const loadHospitalSurgeryCenter = new RemoteLoadHospitalSurgeryCenter(
    surgicalScheduleRepository
  )

  const loadSurgicalScheduleAvailableDays =
    new RemoteLoadSurgicalScheduleAvailableDays(surgicalScheduleRepository)

  const loadSurgeryCenterCalendar = new RemoteLoadSurgeryCenterCalendar(
    surgicalScheduleRepository
  )

  const finishSurgicalSchedule = new RemoteFinishSurgicalSchedule(
    surgicalScheduleRepository
  )

  return new surgicalScheduleService({
    loadSurgicalSchedules,
    loadEquipaments,
    loadHospitalSurgeryCenter,
    loadSurgicalSchedule,
    loadSurgicalScheduleAvailableDays,
    loadSurgeryCenterCalendar,
    finishSurgicalSchedule
  })
}
