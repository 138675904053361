import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import * as yup from 'yup'
import 'main/config/yup'

import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'

import Carousel, { CarouselState } from 'presentation/components/Carousel'
import { cpfMask, phoneMask, dateMask } from 'presentation/utils/masks'

import * as S from './styles'
import { Gender } from 'common/enum/gender'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import getPhoneWithCountryCode from 'common/utils/getPhoneWithCountryCode'
import { CountryPhoneCode } from 'common/enum/country-phone-codes'
import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import TextFieldNew from 'presentation/components/TextFieldNew'
import ButtonNew from 'presentation/components/ButtonNew'
import SelectFieldNew from '../../SelectFieldNew'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

type CarouselPersonalInfoFormProps = {
  useCase?: UpdatePatientInfo
  initialValues?: PatientPersonalInfoForm
} & WithLoadingProps

const CarouselPersonalInfoForm = WithLoading(
  ({
    useCase,
    initialValues = {} as PatientPersonalInfoForm,
    setIsLoading
  }: CarouselPersonalInfoFormProps) => {
    const [carousel, setCarousel] = useState({} as CarouselState)
    const history = useHistory()

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: CarouselPersonalInfoValidation,
      validateOnBlur: true,
      validateOnMount: true,
      onSubmit: async (values) => {
        const birthday = moment(values.birthday, 'DD/MM/YYYY', true)
          .tz('America/Sao_Paulo')
          .toISOString()
        const sendObject = Object.assign({}, values)
        delete sendObject.cpf
        delete sendObject.user

        try {
          setIsLoading(true)
          await useCase?.update({ ...sendObject, birthday })
          toast.success('Dados alterados com sucesso')
          setIsLoading(false)
          history.push('/pedido-cirurgico/save')
        } catch (err: any) {
          toast.error(err.message)
        } finally {
          setIsLoading(false)
        }
      }
    })

    const handleApplyPhoneCountryCode = () => {
      if (carousel.activeIndex === 2) {
        formik.setFieldValue(
          'phone',
          getPhoneWithCountryCode(formik.values.phone, CountryPhoneCode.BRAZIL)
        )
      }
    }

    useEffect(() => {
      handleApplyPhoneCountryCode()
    }, [carousel.activeIndex])

    return (
      <>
        <HeaderNew
          actualPageTitle="Dados pessoais"
          actualPageOnClick={
            carousel.activeIndex === 0 ? history.goBack : carousel.slidePrev
          }
        />
        <ContainerNew>
          <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
            <Carousel
              state={carousel}
              setState={setCarousel}
              slidesPerView={1}
              touch={false}
            >
              <S.Step>
                <S.Content>
                  <TextFieldNew
                    label="Paciente"
                    name="name"
                    onInputChange={formik.handleChange('name')}
                    value={formik.values.name}
                    onBlur={formik.handleBlur('name')}
                    error={formik.touched.name && formik.errors.name}
                    disabled
                    required
                  />
                  <TextFieldNew
                    style={{ marginTop: '16px' }}
                    label="CPF"
                    defaultValue={formik.initialValues.cpf}
                    mask={cpfMask}
                    disabled={true}
                    required
                  />
                  <S.Buttons>
                    <ButtonNew
                      size="large"
                      disabled={!!formik.errors.name}
                      type="button"
                      fullWidth
                      onClick={carousel.slideNext}
                    >
                      Confirmar
                    </ButtonNew>
                  </S.Buttons>
                </S.Content>
              </S.Step>

              <S.Step>
                <S.Content>
                  <TextFieldNew
                    name="birthday"
                    label="Data de nascimento"
                    placeholder="dd/mm/yyyy"
                    mask={dateMask}
                    onInputChange={formik.handleChange('birthday')}
                    onBlur={formik.handleBlur('birthday')}
                    defaultValue={formik.initialValues.birthday}
                    error={
                      formik.touched.birthday &&
                      formik.errors.birthday &&
                      'Data inválida'
                    }
                    required
                  />
                  <SelectFieldNew
                    style={{ marginTop: '22px' }}
                    name="gender"
                    label="Sexo"
                    items={[
                      {
                        label: 'Masculino',
                        value: 'Masculino'
                      },
                      {
                        label: 'Feminino',
                        value: 'Feminino'
                      }
                    ]}
                    onInputChange={formik.handleChange('gender')}
                    onBlur={formik.handleBlur('gender')}
                    defaultValue={formik.initialValues.gender}
                    error={formik.touched.gender && formik.errors.gender}
                    required
                  />
                  <S.Buttons>
                    <ButtonNew
                      size="large"
                      disabled={
                        !!formik.errors.birthday || !!formik.errors.gender
                      }
                      fullWidth
                      type="button"
                      onClick={carousel.slideNext}
                    >
                      Confirmar
                    </ButtonNew>
                  </S.Buttons>
                </S.Content>
              </S.Step>
              <S.Step>
                <S.Content>
                  <TextFieldNew
                    label="E-mail"
                    name="email"
                    onInputChange={formik.handleChange('email')}
                    onBlur={formik.handleBlur('email')}
                    defaultValue={formik.initialValues.email}
                    error={formik.touched.email && formik.errors.email}
                    required
                  />
                  <TextFieldNew
                    style={{ marginTop: '22px' }}
                    label="Telefone"
                    name="phone"
                    onInputChange={formik.handleChange('phone')}
                    onBlur={formik.handleBlur('phone')}
                    defaultValue={getPhoneWithCountryCode(
                      formik.initialValues.phone,
                      CountryPhoneCode.BRAZIL
                    )}
                    mask={phoneMask}
                    error={formik.touched.phone && formik.errors.phone}
                    required
                  />
                  <S.Buttons>
                    <ButtonNew
                      size="large"
                      disabled={
                        formik.isSubmitting ||
                        !!formik.errors.email ||
                        !!formik.errors.phone
                      }
                      fullWidth
                      type="submit"
                    >
                      Enviar
                    </ButtonNew>
                  </S.Buttons>
                </S.Content>
              </S.Step>
            </Carousel>
          </S.Wrapper>
        </ContainerNew>
      </>
    )
  }
)

export default CarouselPersonalInfoForm

export type PatientPersonalInfoForm = {
  name: string
  birthday: string
  cpf?: string
  gender: Gender
  email: string
  phone: string
  user?: string
  patient_id?: number
}

const CarouselPersonalInfoValidation = yup.object().shape({
  name: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required(),
  gender: yup.string().oneOf(['Masculino', 'Feminino']).required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .test('masked-phone-validation', 'Telefone inválido', function (val) {
      return !!(
        val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
        val?.match(/^[0-9]{13}$/)
      )
    })
    .required()
})
