import { ClassProps } from 'common/utils/getClassProps'
import { Document } from './document'
import { Type } from 'class-transformer'

export class DoctorDocumentValidation {
  doctor_document_validation_id!: number
  title!: string
  doctor_register_id!: number
  document_id!: number
  description!: string

  @Type(() => Document)
  document!: Document

  constructor(props?: Partial<DoctorDocumentValidationProps>) {
    if (props) {
      Object.assign(this, props)
    }
  }
}

type DoctorDocumentValidationProps = ClassProps<
  typeof DoctorDocumentValidation.prototype
>
