import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { NotificationRepository as INotificationRepository } from 'repository/interfaces/notification-repository'
import { ApiStatusCode } from 'service/protocols/api-notification/api-notification-client'
import { IApiNotificationRepository } from 'service/protocols/api-notification/api-notification-repository'
import { LoadNotifications } from 'domain/usecases/notification/load-notifications'
import { ReadNotifications } from 'domain/usecases/notification/read-notifications'
import { LoadNotificationsByPendency } from 'domain/usecases/notification/load-notifications-by-pendency'
// import { DisableNotifications } from 'domain/usecases/notification/disable-notifications'

export class NotificationRepository implements INotificationRepository {
  constructor(
    private readonly apiNotificationRepository: IApiNotificationRepository
  ) {}

  async loadNotifications(
    params: LoadNotifications.Params
  ): Promise<LoadNotifications.Model> {
    const apiNotificationRepository = this
      .apiNotificationRepository as IApiNotificationRepository<LoadNotifications.Model>

    const httpResponse = await apiNotificationRepository.get({
      url: !params.hospital_id
        ? `/notifications/${params.user_id}`
        : `/notifications/${params.user_id}?hospital_id=${params.hospital_id}`
      // body: {
      //   data: params
      // }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadNotifications.Model
    }
  }

  async loadNotificationsByPendency(
    params: LoadNotificationsByPendency.Params
  ): Promise<LoadNotificationsByPendency.Model> {
    const apiNotificationRepository = this
      .apiNotificationRepository as IApiNotificationRepository<LoadNotificationsByPendency.Model>

    const httpResponse = await apiNotificationRepository.get({
      url: `/notifications/by-pendency/${params.pendency_id}`
      // body: {
      //   data: params
      // }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadNotificationsByPendency.Model
    }
  }

  async readNotifications(
    params: ReadNotifications.Params
  ): Promise<ReadNotifications.Model> {
    const apiNotificationRepository = this
      .apiNotificationRepository as IApiNotificationRepository<ReadNotifications.Model>

    const httpResponse = await apiNotificationRepository.put({
      url: `/notifications/read/${params.notification_id}`
      // body: {
      //   data: params
      // }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadNotifications.Model
    }
  }

  // async disableNotifications(
  //   params: DisableNotifications.Params
  // ): Promise<DisableNotifications.Model> {
  //   const apiNotificationRepository = this
  //     .apiNotificationRepository as IApiNotificationRepository<DisableNotifications.Model>

  //   const httpResponse = await apiNotificationRepository.put({
  //     url: `/notifications/disable`,
  //     body: params
  //   })

  //   if (
  //     httpResponse.statusCode &&
  //     httpResponse.statusCode !== ApiStatusCode.ok
  //   ) {
  //     throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
  //   } else {
  //     return httpResponse.body as LoadNotifications.Model
  //   }
  // }
}
