import { GetExamResult } from 'domain/usecases/exam/get-exam-result'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadExamResult implements GetExamResult {
  constructor(private readonly patientRepository: PatientRepository) {}

  async load(data: GetExamResult.Params): Promise<GetExamResult.Model> {
    return this.patientRepository.loadExamResult(data)
  }
}
