import React from 'react'
import * as S from './styles'
import BottomMenu from 'presentation/components/BottomMenu'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { useHistory } from 'react-router-dom'
import { useStores } from 'presentation/hooks/use-stores'
import ButtonNew from 'presentation/components/ButtonNew'
import CardNew from 'presentation/shared/components/CardNew'
import moment from 'moment-timezone'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ExamStatusEnum, ExamStatusEnumLocated } from 'common/enum/exam-status'
import HeaderNew from 'presentation/components/HeaderNew'
import {
  ExamOrderItem,
  ExamResultModel
} from 'domain/entities/exam-result-model'

type Props = {
  exams: ExamResultModel[]
}

const ExamsResultsLayout = ({ exams }: Props) => {
  const history = useHistory()
  const user = useStores().currentAccount.getCurrentAccount().user

  const formatProcedures = (orderItens: ExamOrderItem[]) => {
    return orderItens
      ?.map((item) => {
        const desc = item.exam_description
        return desc?.charAt(0).toUpperCase().concat(desc.slice(1).toLowerCase())
      })
      .join(', ')
  }

  const goToExam = (exam: ExamResultModel) => {
    if (exam.status === ExamStatusEnum.EMPTY) return

    history.push('/exame/resultado', {
      exam: exam,
      examProcedures: formatProcedures(exam.order_items as ExamOrderItem[])
    })
  }

  return (
    <>
      <HeaderNew />
      <ContainerNew
        title="Resultados de exames"
        titleColor="primary600"
        titleSize="large"
        titleWeight="bold"
        subtitle={
          <SupportTextNew capitalize>
            <b>Paciente:</b> {user.name.toLowerCase()}
          </SupportTextNew>
        }
        gap="16px"
      >
        {exams.map((exam) => (
          <CardNew key={exam.exam_order_id} gap="16px">
            <S.CardHeader>
              <S.CardInfo>
                <div className="info-title">Número do pedido</div>
                <div>{exam.exam_order_id}</div>
              </S.CardInfo>

              <S.CardInfo>
                <div className="info-title">Data do atendimento</div>
                <div className="alightRight">
                  {moment(exam.requested_at).format('DD/MM/YYYY')}
                </div>
              </S.CardInfo>
            </S.CardHeader>

            <S.CardInfo>
              <div className="info-title">Procedimentos</div>
              <p>{formatProcedures(exam.order_items as ExamOrderItem[])}</p>
            </S.CardInfo>

            <ButtonNew
              onClick={() => goToExam(exam)}
              disabled={exam.status === ExamStatusEnum.EMPTY}
              outlined={exam.status === ExamStatusEnum.PARTIAL}
              fullWidth
              size="large"
            >
              {exam.status === ExamStatusEnum.FULL
                ? 'Ver Resultado'
                : ExamStatusEnumLocated[
                    exam.status as keyof typeof ExamStatusEnum
                  ]}
            </ButtonNew>
          </CardNew>
        ))}
      </ContainerNew>
      <BottomMenu />
    </>
  )
}

export default ExamsResultsLayout
