import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import ActualPage from 'presentation/components/ActualPage'
import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import Radio from 'presentation/shared/components/RadioButton'
import SupportText from 'presentation/components/SupportText'
import TextArea from 'presentation/shared/components/TextArea'
import { ServiceType } from 'common/enum/service-type'
import { AddHospitalizationService } from 'domain/usecases/hospitalization/add-hospitalization-service'
import * as S from './styles'
import Modal from 'presentation/shared/components/Modal'
import { useStores } from 'presentation/hooks/use-stores'
import TextField from 'presentation/components/TextField'
import * as yup from 'yup'

type Props = {
  addHospitalizationService?: AddHospitalizationService
  surgical_order_id: number
}

export default function ServiceSolicitationForm({
  addHospitalizationService,
  surgical_order_id
}: Props) {
  const history = useHistory()
  const [successModal, setSuccessModal] = useState<boolean>()

  const account = useStores().currentAccount

  const formik = useFormik({
    initialValues: {
      surgical_order_id: surgical_order_id
    } as ServiceSolicitationFormValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        const name = account.getCurrentAccount().user.name
        await addHospitalizationService?.add({ ...values, patientName: name })
        setSuccessModal(true)
        setTimeout(() => {
          history.push('/internacao')
        }, 2500)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  useEffect(() => {
    if (surgical_order_id === 0) {
      history.goBack()
    }
  }, [])

  const onCheckServiceType = (service: ServiceType) => {
    formik.setFieldValue('serviceType', service)
    formik.setFieldValue('description', undefined)
    formik.setFieldValue('apartmentNumber', undefined)
    formik.validateForm({
      serviceType: service
    } as ServiceSolicitationFormValues)
  }

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <ActualPage text="Novo pedido cirúrgico" onClick={history.goBack} />
      <Heading style={{ margin: '10px 0' }}>
        Nova solicitação de serviço
      </Heading>
      <SupportText color="lightGray">
        Caso precise de um serviço adicional, envie a sua solicitação
      </SupportText>
      <S.Content>
        <S.RadiosWrapper>
          <Radio
            name="type"
            labelFor="linen"
            value="linen"
            label="Rouparia"
            onCheck={() => onCheckServiceType(ServiceType.LINEN_ROOM)}
          />
          <Radio
            name="type"
            labelFor="higienization"
            value="higienization"
            label="Higienização"
            onCheck={() => onCheckServiceType(ServiceType.SANITATION)}
          />
          <Radio
            name="type"
            labelFor="maintance"
            value="maintance"
            label="Manutenção"
            onCheck={() => onCheckServiceType(ServiceType.MAINTENANCE)}
          />
          <Radio
            name="type"
            labelFor="nutrition"
            value="nutrition"
            label="Nutrição"
            onCheck={() => onCheckServiceType(ServiceType.NUTRITION)}
          />
          <Radio
            name="type"
            labelFor="sac"
            value="sac"
            label="SAC"
            onCheck={() => onCheckServiceType(ServiceType.SAC)}
          />
        </S.RadiosWrapper>
        <TextField
          label="Número do Apartamento"
          onChange={formik.handleChange('apartmentNumber')}
          value={formik.values.apartmentNumber || ''}
          required
        />
        <TextArea
          placeholder="Descrição"
          rows={8}
          onInputChange={formik.handleChange('description')}
          value={formik.values.description || ''}
        />
      </S.Content>
      <Button
        type="submit"
        fullWidth
        style={{ marginTop: '22px' }}
        disabled={!formik.isValid}
      >
        Enviar
      </Button>
      <Modal
        title="Solicitação de serviço realizada com sucesso"
        close={() => history.push('/home')}
        show={successModal}
      />
    </S.Wrapper>
  )
}

type ServiceSolicitationFormValues = {
  serviceType: ServiceType
  description: string
  surgical_order_id: number
  apartmentNumber?: string
}

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  serviceType: yup.string().required(),
  apartmentNumber: yup.string().required()
})
