import theme from 'presentation/styles/theme'
import styled from 'styled-components'

export const DetailText = styled.div`
  font-weight: ${theme.ds.typography.weight.bold};
  color: ${theme.ds.colors.neutral700};
  text-transform: capitalize;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: -4px;
`

export const CardInfo = styled.div`
  .info-title {
    color: ${theme.ds.colors.primary600};
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 2px;
  }
  .alightRight {
    text-align: right;
  }
  font-size: ${theme.ds.typography.size.xsmall};
`
