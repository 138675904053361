import { AnesthesiaOptions } from 'common/enum/anesthesia-options'
import { GenderNew } from 'common/enum/gender'
import { Hospital } from './hospital-model'
import { SurgeryType } from 'common/enum/surgery-type'

export enum ExamSearchType {
  EXAM = 'EXAM',
  SURGERY = 'SURGERY'
}

export type ExamModelNew = {
  unification_id?: number
  exam_ids: number[] | null
  item_ids: number[] | null
  name: string
  aliases?: string
  anesthesia_option?: AnesthesiaOptions
  gender?: GenderNew
  hospitals?: Hospital[]
  modality?: string
  allow_schedule: boolean
  group_name?: string
  is_mandatory_pre_exam?: boolean
  pre_exam_id?: number
  reason_not_schedule?: string
  doctors?: {
    doctor_id: number
    doctor_name: string
    hospital_ids?: number[]
  }[]
  type: ExamSearchType
  surgery_type: SurgeryType
  surgicalProcedureIndex?: string
  surgicalProcedureSolicited?: boolean
  locatedSurgeryId?: string
}

export type ExamGroup = {
  groupIndex?: string
  group_acronym: string
  exam_ids: number[]
  exams: ExamModelNew[]
  scheduled?: boolean
  selectedExamOffer?: any
  groupExamsScheduled?: any
}
