import { lighten } from 'polished'
import styled, { css, DefaultTheme } from 'styled-components'

type DocInputProps = {
  file: boolean
  disabled: boolean
  error?: boolean
}

export const Wrapper = styled.div<DocInputProps>`
  ${({ theme, file, disabled, error }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    width: 100%;
    max-width: 100%;
    min-height: 120px;
    background-color: ${theme.colors.white};
    border: 1px dashed ${theme.colors.secondary};
    border-radius: ${theme.border.radius.small};

    input {
      display: none;
    }

    & + & {
      margin-top: 22px;
    }

    ${!!file && wrapperModifiers.fileSelected(theme, error)}
    ${!!disabled && wrapperModifiers.disabled(theme)}
  `}
`

export const Label = styled.label`
  position: absolute;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  color: #cdced9;
`

type SubtitleProps = {
  fileExt: string
}

export const Subtitle = styled.span<SubtitleProps>`
  ${({ theme, fileExt }) => css`
    font-weight: 500;
    display: flex;
    margin-top: 0 !important;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};

    small {
      color: ${fileExt === 'pdf' ? theme.colors.lightRed : theme.colors.blue};
    }

    img {
      margin-bottom: 4px;
    }
  `}
`

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 38px;
  padding: 0px 3px;
  background: #f0f6ef;
  border: 0.5px solid #c4c4c4;
  border-radius: 3px;
  margin-bottom: 8px;
`

export const TypeList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: min(180px, 100%);
  height: 100%;
`

export const TypeListItem = styled.label`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    span {
      font-weight: ${theme.font.black};
      color: ${theme.colors.gray};
      font-size: ${theme.font.sizes.xsmall};
    }
    &:hover {
      span {
        color: ${lighten(0.1, theme.colors.gray)};
      }
    }
  `}
`

const wrapperModifiers = {
  fileSelected: (theme: DefaultTheme, error?: boolean) => css`
    border: 1px solid ${error ? theme.colors.lightRed : theme.colors.secondary};
  `,

  disabled: (theme: DefaultTheme) => css`
    cursor: not-allowed;
    color: ${theme.colors.gray};
    &::placeholder {
      color: currentColor;
    }
    background-color: ${lighten(0.3, theme.colors.lightGray)};
  `
}

export const FileName = styled.p`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
`

export const ErrorMessageSpan = styled.span`
  text-align: center;
  margin-bottom: 20px;
  color: #e31a12;
`
