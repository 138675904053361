import { LoadSurgicalOrderPendencies } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteLoadSurgicalOrderPendencies
  implements LoadSurgicalOrderPendencies
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  async load(
    surgical_order_id: LoadSurgicalOrderPendencies.Params
  ): Promise<LoadSurgicalOrderPendencies.Model> {
    return this.surgicalPendencyRepository.loadSurgicalOrderPendencies(
      surgical_order_id
    )
  }
}
