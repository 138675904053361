import styled, { css } from 'styled-components'
import { RadioProps } from '.'

type WrapperProps = {
  isChecked: boolean
  showBackground: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isChecked, showBackground }) => css`
    display: flex;
    /* align-items: center; */
    border: 1px solid
      ${showBackground
        ? isChecked
          ? theme.ds.colors.primary600
          : theme.ds.colors.neutral300
        : theme.ds.colors.transparent};
    border-radius: ${theme.ds.typography.border.radius.small};
    height: ${showBackground ? '48px' : ''};
    padding: ${showBackground ? '16px' : '4px 0'};
    background-color: ${showBackground ? theme.colors.white : ''};
    cursor: pointer;
  `}
`

export const Input = styled.input<Pick<RadioProps, 'color'>>`
  ${({ theme, color }) => css`
    flex-shrink: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    appearance: none;
    margin-top: 1px;
    width: 20px;
    height: 20px;
    border: 2px solid ${theme.ds.colors?.[color!]};
    border-radius: 50%;
    background: transparent;
    transition: background ${theme.transition.fast};
    outline: none;
    cursor: pointer;
    &:before {
      content: '';
      width: 16px;
      height: 16px;
      border: 4px solid ${theme.ds.colors.white};
      box-shadow: 0 0 0 2px ${theme.ds.colors?.[color!]};
      border-radius: 50%;
      background: ${theme.ds.colors?.[color!]};
      transition: opacity ${theme.transition.fast};
      opacity: 0;
      position: absolute;
    }
    &:checked {
      /* & ~ div {
        border-color: ${theme.ds.colors?.[color!]};
      } */
      &:before {
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label<Pick<RadioProps, 'labelColor' | 'labelSize'>>`
  ${({ theme, labelColor, labelSize }) => css`
    display: flex;
    color: ${theme.ds.colors?.[labelColor!]};
    font-size: ${theme.ds.typography.size?.[labelSize!]};
    line-height: ${theme.ds.typography.lineHeight.medium};
    margin-left: 8px;
    cursor: pointer;
  `}
`
