import styled, { css } from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  flex: 1;
`

export const List = styled.div`
  ${({ theme }) => css`
    background-color: white;
    margin-top: 24px;
    border-radius: ${theme.border.radius.small};
    li {
      flex-direction: row-reverse;
    }

    img.icon {
      width: 28px;
    }
  `}
`

export const ItemText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.small};
  `}
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`
