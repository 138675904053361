import React from 'react'

import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import StatusAnalyzingHealthInsuranceImage from 'presentation/assets/banners/surgical-journey/surgical-schedule.svg'
import * as S from './styled'

export const SlideSurgicalSchedule = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Agendamento da cirurgia
        </HeadingNew>
        <S.Image
          src={StatusAnalyzingHealthInsuranceImage}
          alt="Pessoas analisando informações do convênio"
        />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            Esta etapa é direcionada para o seu médico. Assim que o procedimento
            for agendado pelo seu especialista e a data for confirmada você
            conseguirá visualizar pela plataforma e receberá um email de
            notificação.
          </SupportTextNew>
          <SupportTextNew color="neutral700">
            Próximo a data da sua cirurgia, o hospital também entrará em contato
            para confirmação.
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
