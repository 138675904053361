import React, { useState, useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as S from './styles'
import { ReactComponent as ReasonNotSurgeryAnsweredBanner } from 'presentation/assets/banners/reason-not-surgery-answered.svg'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import HeaderNew from 'presentation/components/HeaderNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { ServiceOverviewProps } from 'presentation/shared/components/ServiceOverview'
import ButtonNew from 'presentation/components/ButtonNew'
import { CardInfoSurgery } from 'presentation/components/CardInfoSurgery'
import { NeedHelpForm } from 'presentation/components/Forms/NeedHelpForm'
import { OpenScheduleSurgeryType } from 'common/enum/open-schedule-surgery-type'

type ReasonNotSurgeryType = {
  type: string
  observation?: string
}

type LocationType = {
  state: {
    surgicalOrder: ServiceOverviewProps
    reasonNotSurgery: ReasonNotSurgeryType
    openScheduledSurgeries: OpenScheduleSurgeryType[]
    otherReason: boolean
  }
}

const NewDateSurgeryHelpLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const location: LocationType = useLocation()
    const history = useHistory()
    const {
      surgicalOrder,
      reasonNotSurgery,
      openScheduledSurgeries,
      otherReason
    } = location.state

    const [showReasonAnswered, setShowReasonAnswered] = useState(false)

    useLayoutEffect(() => {
      if (otherReason) {
        setShowReasonAnswered(true)
      }
    }, [])

    function redirectToNextForm() {
      if (openScheduledSurgeries.length > 1) {
        openScheduledSurgeries.shift()
        history.push('/cirurgias-nao-relizadas/motivo', {
          newOpenScheduledSurgeries: openScheduledSurgeries
        })
      }
    }

    return (
      <>
        <HeaderNew />
        <ContainerNew>
          {!showReasonAnswered ? (
            <S.Wrapper>
              <CardInfoSurgery surgicalOrder={openScheduledSurgeries[0]} />
              <NeedHelpForm
                surgicalOrder={surgicalOrder}
                openScheduledSurgeries={openScheduledSurgeries}
                reasonNotSurgery={reasonNotSurgery}
                setShowReasonAnswered={setShowReasonAnswered}
                setIsLoading={setIsLoading}
              />
            </S.Wrapper>
          ) : (
            <S.Wrapper>
              <S.BannerContainer>
                <ReasonNotSurgeryAnsweredBanner />
              </S.BannerContainer>
              <SupportTextNew
                weight="bold"
                color="secondary700"
                size="medium"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                Obrigada pela sua resposta!
              </SupportTextNew>

              <SupportTextNew
                weight="semiBold"
                color="neutral500"
                style={{ marginTop: '10px', marginBottom: '20px' }}
              >
                Entraremos em contato em breve.
              </SupportTextNew>

              <S.ButtonPanel>
                <ButtonNew
                  color="primary"
                  fullWidth
                  onClick={() => history.push('/home')}
                  size="large"
                >
                  Voltar para home
                </ButtonNew>
              </S.ButtonPanel>

              {openScheduledSurgeries.length > 1 && (
                <S.ButtonPanel>
                  <ButtonNew
                    outlined
                    color="primary"
                    fullWidth
                    onClick={redirectToNextForm}
                    size="large"
                  >
                    Próximo
                  </ButtonNew>
                </S.ButtonPanel>
              )}
            </S.Wrapper>
          )}
        </ContainerNew>
      </>
    )
  }
)

export default NewDateSurgeryHelpLayout
