import { GetAmbulatorySchedulePanelOrganized } from 'domain/usecases/ambulatory/get-ambulatory-panel-organized'
import { AmbulatoryRepository } from 'repository/interfaces/ambulatory-repository'

export class RemoteGetAmbulatorySchedulePanelOrganized
  implements GetAmbulatorySchedulePanelOrganized
{
  constructor(private readonly ambulatoryRepository: AmbulatoryRepository) {}

  get(
    params: GetAmbulatorySchedulePanelOrganized.Params
  ): Promise<GetAmbulatorySchedulePanelOrganized.Model> {
    return this.ambulatoryRepository.getAmbulatorySchedulePanelOrganized(params)
  }
}
