import { RepositoryErrors } from 'repository/errors/repository-errors'
import { deleteDoctorDocumentMutation } from 'repository/graphql/mutations'

import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiResponse, ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { DocumentRepository } from 'repository/interfaces/document-repository'
import { DeleteDoctorDocument } from 'domain/usecases/document/delete-doctor-document'
import { GetScheduleDocuments } from 'domain/usecases/document/get-schedule-documents'

export class DocumentRepositoryImpl implements DocumentRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async deleteDoctorDocument(
    params: DeleteDoctorDocument.Params
  ): Promise<void> {
    const apiRepository = this.apiRepository as IApiRepository<void>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: deleteDoctorDocumentMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: deleteDoctorDocumentMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    }
  }

  async getScheduleDocuments(): Promise<GetScheduleDocuments.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetScheduleDocuments.Model>

    const httpResponse = (await apiRepository.get({
      url: `/memed/documents`
    })) as ApiResponse

    if (
      httpResponse?.statusCode &&
      httpResponse?.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as GetScheduleDocuments.Model
    }
  }
}
