import { DoctorRecommendation } from 'domain/entities/doctor-recommendation-model'

import { AddRecommendation } from 'domain/usecases/doctor/add-recommendation'
import { LoadDoctorRecommendations } from 'domain/usecases/doctor/load-doctor-recommendation'
import { LoadRecommendationByInviteToken } from 'domain/usecases/doctor/load-recommendation-by-invite-token'
import { UpdateRecommendation } from 'domain/usecases/doctor/update-recommendation'

import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  addRecommendationMutation,
  updateRecommendationMutation,
  createRecommendationWithoutDoctorMutation
} from 'repository/graphql/mutations'
import {
  loadRecommendationByInviteTokenQuery,
  loadDoctorRecommendations
} from 'repository/graphql/queries'

import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { RecommendationRepository as IRecommendationRepository } from 'repository/interfaces/recommendation-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class RecommendationRepository implements IRecommendationRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addRecommendation(
    params: AddRecommendation.Params
  ): Promise<DoctorRecommendation> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddRecommendation.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addRecommendationMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addRecommendationMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DoctorRecommendation
    }
  }

  async createRecommendationWithoutDoctor(
    params: AddRecommendation.Params
  ): Promise<DoctorRecommendation> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddRecommendation.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: createRecommendationWithoutDoctorMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: createRecommendationWithoutDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DoctorRecommendation
    }
  }

  async loadDoctorRecommendations(
    params: LoadDoctorRecommendations.Params,
    initialDate?: Date
  ): Promise<LoadDoctorRecommendations.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorRecommendations.Model>

    const query = loadDoctorRecommendations(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(initialDate, 'initialDate')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorRecommendations.Model
    }
  }

  async loadRecommendationByInviteToken(
    token: string,
    params: LoadRecommendationByInviteToken.Params
  ): Promise<LoadRecommendationByInviteToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadRecommendationByInviteToken.Model>

    const query = loadRecommendationByInviteTokenQuery(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(token, 'inviteToken')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadRecommendationByInviteToken.Model
    }
  }

  async updateRecommendation(
    params: UpdateRecommendation.Params
  ): Promise<DoctorRecommendation> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateRecommendation.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateRecommendationMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateRecommendationMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DoctorRecommendation
    }
  }
}
