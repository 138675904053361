import styled, { css } from 'styled-components'

import { HealthInsuranceCardWrapper } from 'presentation/shared/components/HealthInsuranceCard/styles'

export const Wrapper = styled.div`
  padding-bottom: 24px;
`
export const Content = styled.div`
  margin-top: 24px;

  ${HealthInsuranceCardWrapper} {
    ${({ theme }) => css`
      cursor: pointer;
      border: 1px solid transparent;
      transition: border ease 0.2s;
      &:hover,
      &:active,
      &:focus {
        border-color: ${theme.ds.colors.primary600};
      }
    `}
  }
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const IpsemgInfo = styled.p`
  ${({ theme }) => css`
    margin-top: -16px;
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 20px;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors.danger300};

    a {
      text-decoration: underline;
      color: ${theme.ds.colors.info400};
      transition: color ease 0.1s;

      &:hover {
        color: ${theme.ds.colors.info300};
      }
    }
  `}
`

export const PurpleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const CardsList = styled.div`
  /* height: 550px;
  overflow-y: scroll; */
`

export const LoadingWrapper = styled.div`
  padding-top: 20px;
  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.primary};
  }
  display: grid;
  justify-content: center;
`

export const LoadingContainer = styled.div`
  margin: auto;
`

export const LoadingText = styled.span`
  color: #4a4a4a;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 32px;
`
