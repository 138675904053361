import { Gender } from 'common/enum/gender'
import { User } from './user-model'
import { Accompanying } from 'domain/entities/accompanying-model'
import { CommunicationParticularity } from 'common/enum/communication-particularity'
import { Allergy } from './allergy-model'
import { SurgicalOrderModel } from './surgical-order-model'
import { EmergencyRoomAttendanceModel } from './emergency-room-attendance.model'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { ExamModel } from './exam-model'
import { ClassProps } from 'common/utils/getClassProps'

export class Patient extends User {
  pendencies?: SurgicalPendency[]
  patient_id!: number
  validThru?: number
  createdAt?: Date
  updatedAt?: Date
  gender!: Gender
  weight?: number
  height?: number
  birthday!: string
  maritalStatus?: string
  children?: number
  schooling?: string
  company?: string
  age?: number
  religion?: string
  religion_id?: number
  eatingHabit?: string
  healthInsurance?: PatientHealthInsurance
  healthInsurances?: PatientHealthInsurance[]
  accompanists?: Accompanying[]
  mainAccompanying?: Accompanying
  communicationParticularity?: CommunicationParticularity[]
  needAttention?: string
  allergies?: Allergy[]
  surgicalOrder?: SurgicalOrderModel
  surgicalOrders?: SurgicalOrderModel[]
  dialitic?: boolean
  specialTreatment?: boolean
  emergencyRoomAttendance?: EmergencyRoomAttendanceModel
  lastHealthInsuranceUsed?: PatientLastHealthInsurance
  lifeHabits?: LifeHabits
  profession?: string
  medicinesDiseases?: MedicinesAndDiseases
  documentHealthInsurance?: DocumentHealthInsurance[]
  getExamOrders?: ExamModel[]
  additionalObservation?: string
  socialName?: string
  user?: User
  rg?: string

  constructor(props?: PatientProps) {
    super(props?.user)
    Object.assign(this, props)
  }
}

export type PatientHealthInsurance = {
  health_insurance_id: number
  healthCard: string
  healthInsuranceCode: number
  healthInsuranceName: string
  healthPlanCode?: number
  healthPlanName?: string
  healthSubPlanCode?: number
  healthSubPlanName?: string
  ansRegister?: string
  company?: string
  validThru: string
  createdAt: string
  updatedAt: string
}

export type PatientLastHealthInsurance = {
  card?: string
  expirationDate?: string
  healthInsuranceCode?: number
  healthInsuranceName?: string
  healthPlanCode?: number
  healthPlanName?: string
  subPlanCode?: number
  subPlanName?: string
}

export type LifeHabits = {
  physicalActivityFrequency: PhysicalActivityFrequency
  dietaryRestrictions: string
  isSmoker: boolean
  cigarettesQuantity: number
  isAlcoholic: boolean
  alcoholFrequency: number
  hasLostWeight: boolean
  lostWeight: LostWeight
  isEatingLess: boolean
}

export type MedicinesAndDiseases = {
  isAllergy: boolean | string
  allergyRemarks?: string
  isMedicines: boolean | string
  medicinesRemarks?: string
  isInsulin: boolean | string
  insulinRemarks?: string
  otherDiseases: string
  illnesses: string
  functionalCapacity: string
}

export type DocumentHealthInsurance = {
  document_id: number
  type: string
  documentUri: string
  name: string
  sendedAt: string
}

enum LostWeight {
  ONE_TO_FIVE = 'ONE_TO_FIVE',
  SIX_TO_TEN = 'SIX_TO_TEN',
  ELEVEN_TO_FIFTEEN = 'ELEVEN_TO_FIFTEEN',
  MORE_THEN_FIFTEEN = 'MORE_THEN_FIFTEEN',
  DONT_KNOW = 'DONT_KNOW'
}

enum PhysicalActivityFrequency {
  SEDENTARY = 'SEDENTARY',
  ACTIVE = 'ACTIVE',
  VERY_ACTIVE = 'VERY_ACTIVE'
}

type PatientProps = ClassProps<typeof Patient.prototype>
