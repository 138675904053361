import styled from 'styled-components'

export const Wrapper = styled.div``

export const Header = styled.header`
  & > div {
    padding: 0px 24px 0px 24px;
  }
`
export const InfoList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  align-items: center;
  font-size: 16px;
  margin-top: 28px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: white;

  a {
    cursor: pointer;
    padding: 18px 0px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    position: relative;

    &.selected {
      font-weight: 700;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

export const Content = styled.div`
  padding: 0 24px;
  display: flex;
  width: 100%;
  justify-content: center;
`
