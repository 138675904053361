import React, { useEffect } from 'react'
import { PatientDocument } from 'common/enum/patient-document'
import 'main/config/yup'
import { observer } from 'mobx-react'
import File from 'presentation/contexts/file/file-context'
import { useStores } from 'presentation/hooks/use-stores'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as S from './styles'
import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'

type Location = {
  type: 'order' | 'medical_report' | 'medical_exams'
}

export const AddSurgicalOrderForm = observer(() => {
  const { state } = useLocation<Location>()
  const type = state?.type
  const history = useHistory()
  const filesContext = useStores().file as File<PatientDocument>
  const getPatientDocumentByType = () => {
    return documentItems[type]?.documentType
  }

  const getInitialFiles = () => {
    return filesContext.listFiles?.filter(
      (listFile) => listFile.identifier === getPatientDocumentByType()
    )
  }

  useEffect(() => {
    if (filesContext.listFiles && !files) {
      onAdd(getInitialFiles() || [])
    }
  }, [filesContext.listFiles])

  const { filesInfo, onAdd, onDelete, onUpdate, onDownload, files } =
    useUploadSection<PatientDocument>({
      maxFileSizeInMB: documentItems[type]?.maxFileSizeInMB,
      maxFiles: documentItems[type]?.maxFiles,
      verifyUniqueFile: true,
      initialFiles: getInitialFiles(),
      extensionsAllowed: ['pdf', 'jpg', 'jpeg']
    })

  const handleSubmit = async () => {
    const filteredFiles =
      filesContext.listFiles?.filter(
        (listFile) => listFile.identifier !== getPatientDocumentByType()
      ) || []
    filesContext.setListFiles([...filteredFiles, ...files])
    toast.success('Documentos Adicionados com sucesso')
    history.goBack()
  }

  const handleAdd = (files: ListFile[]) => {
    onAdd(
      files.map((file) => ({
        ...file,
        identifier: getPatientDocumentByType()
      }))
    )
  }

  const titleProps = {
    title: documentItems[type]?.title,
    titleButtonProps: {
      showTitleButton: false
    },
    subtitle: 'Faça aqui o upload do seu documento'
  }
  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true,
    canPreview: false
  }

  const isRequired = documentItems[type]?.required

  return (
    <>
      <HeaderNew actualPageTitle="Pedido cirúrgico" />
      <ContainerNew
        subtitle="Faça aqui o upload do seu documento:"
        primaryButton={
          <ButtonNew
            size="large"
            disabled={isRequired && files.length < 1}
            fullWidth
            type="submit"
            onClick={() => handleSubmit()}
            data-testid="btn-send"
          >
            Enviar
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew
            size="large"
            type="button"
            fullWidth
            variant="text"
            data-testid="btn-cancel"
            onClick={() => history.goBack()}
          >
            Cancelar
          </ButtonNew>
        }
      >
        <S.Wrapper>
          <UploadSection
            titleProps={titleProps}
            files={files}
            can={canUpload}
            onAdd={handleAdd}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onDownload={onDownload}
            errors={filesInfo.errorMessage}
            acceptFiles={'application/pdf, image/jpeg'}
            showTitle
          />
        </S.Wrapper>
      </ContainerNew>
    </>
  )
})

const documentItems = {
  order: {
    title: 'Pedido Cirúrgico',
    documentType: PatientDocument.SURGICAL_ORDER,
    maxFileSizeInMB: 5,
    maxFiles: 5,
    required: true
  },
  medical_report: {
    title: 'Relatório Médico',
    documentType: PatientDocument.MEDICAL_REPORT,
    maxFileSizeInMB: 10,
    maxFiles: 10,
    required: false
  },
  medical_exams: {
    title: 'Laudos',
    documentType: PatientDocument.EXAM_REPORT,
    maxFileSizeInMB: 19,
    maxFiles: 60,
    required: false
  }
}

export default AddSurgicalOrderForm
