import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteAddPainAlert } from 'service/usecases/add-pain-alert/remote-add-pain-alert'
import AddPainAlert from 'presentation/pages/AddPainAlert'

export default function makeAddPainAlert() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const addPainAlert = new RemoteAddPainAlert(patientRepository)

  return <AddPainAlert addPainAlert={addPainAlert} />
}
