import { makeAutoObservable } from 'mobx'
import { VueMotionCheckForExams } from 'domain/usecases/vue-motion/vue-motion-check-for-exams'

type Services = {
  vueMotionCheckForExams: VueMotionCheckForExams
}

export class VueMotionService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async vueMotionCheckForExams(
    params: VueMotionCheckForExams.Params
  ): Promise<VueMotionCheckForExams.Model> {
    return this.services.vueMotionCheckForExams.load(params)
  }
}

export default VueMotionService
