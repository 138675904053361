import React from 'react'
import * as S from './styled'

type Props = {
  image?: string
  title?: string
  description?: string
}

export default function MyDocumentsOnboardingModal({
  image,
  title,
  description
}: Props) {
  return (
    <S.Wrapper>
      {image && (
        <S.ImageWrapper>
          <img src={image} alt="Ilustração passo a passo" />
        </S.ImageWrapper>
      )}
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description>{description}</S.Description>}
    </S.Wrapper>
  )
}
