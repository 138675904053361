import React from 'react'

import { useHistory } from 'react-router'

import List from 'presentation/shared/components/List'
import ProfilePic from 'presentation/components/ProfilePic'
import ActualPage from 'presentation/components/ActualPage'
import Header from 'presentation/components/Header'
import { useStores } from 'presentation/hooks/use-stores'
import { Container } from 'presentation/components/Container'
import { getProfileList } from './menuItems'
import BottomMenu from 'presentation/components/BottomMenu'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import { ReactComponent as ArrowDown } from 'presentation/assets/icons/open-arrow.svg'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading/index'
import * as S from './styles'
import { toast } from 'react-toastify'

const PatientProfileLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const currentAccount = useStores().currentAccount
    const user = currentAccount.getCurrentAccount().user
    const [anchorEl, setAnchorEl] = React.useState(null)

    const openDependentsList = (event: any) => {
      event.preventDefault()
      if (hasDependents()) {
        setAnchorEl(event.currentTarget)
      }
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleChangeDependent = (user_id: number) => {
      if (user_id !== user.user_id) {
        setIsLoading(true)
        currentAccount
          .setCurrentDependent(user_id)
          .then(() => {
            window.location.reload()
            setAnchorEl(null)
          })
          .catch((e: any) => {
            toast.error(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    const isSelectedDependent = (dependent_id: number) => {
      if (dependent_id === user.user_id) return true
      return false
    }

    const hasDependents = () => {
      const depsList = getFullDependentsList(user)
      return depsList.length > 1
    }

    return (
      <>
        <Header />
        <Container>
          <ActualPage text="Voltar" onClick={() => history.push('/home')} />
          <ProfilePic
            data-testid="profile pic"
            outlined
            size="large"
            upload={() => ''}
            style={{ marginTop: '40px' }}
          />
          <S.UserName
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={openDependentsList}
            color="primary"
            className={hasDependents() ? 'is-clickable' : ''}
          >
            {user.name.split(' ')[0].toLocaleLowerCase()}
            {hasDependents() && (
              <ArrowDown
                style={{ verticalAlign: 'middle', marginLeft: '8px' }}
              />
            )}
          </S.UserName>
          <S.DependentsMenu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {getFullDependentsList(user)?.map(function ({ user_id, name }) {
              return (
                <S.DependentsMenuItem
                  key={user_id}
                  selected={isSelectedDependent(user_id)}
                  className={isSelectedDependent(user_id) ? 'is-active' : ''}
                  onClick={() => handleChangeDependent(user_id)}
                >
                  {name.toLocaleLowerCase()}
                </S.DependentsMenuItem>
              )
            })}
          </S.DependentsMenu>

          <S.List>
            <List
              items={getProfileList(user.role)}
              size="medium"
              spacingIcon
              arrow
            />
          </S.List>
        </Container>
        <BottomMenu />
      </>
    )
  }
)

export default PatientProfileLayout
