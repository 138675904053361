import {
  InternalServerError,
  UserAlreadyExistsError,
  CpfInUseError,
  EmailInUseError,
  UserNotExistsError,
  InvalidCodeError,
  UnauthorizedError,
  DoctorNotExistsError,
  MvError,
  CredentialsNotExistError,
  InvalidCpfFormat,
  FavoriteAlreadyExists,
  NotFoundError,
  FileEncrypted,
  SpecialtyNotExistsError,
  NoResultsForThisAgeError
} from 'common/errors/'
import { AgreementDoesNotCoverExam } from 'common/errors/agreement-does-not-cover-exam'
import { PreparationInstructionsNotFound } from 'common/errors/preparation-instructions-not-found'
import { PatientEmailNotEqualsToInMvError } from 'common/errors/user/patient-email-not-equal-mv'
import { PhoneInUseError } from 'common/errors/user/phone-in-use'

export const RepositoryErrors = {
  ERROR_IN_DB: InternalServerError,
  USER_ALREADY_EXIST: UserAlreadyExistsError,
  EMAIL_IN_USE: EmailInUseError,
  CPF_IN_USE: CpfInUseError,
  USER_NOT_EXIST: UserNotExistsError,
  CODE_NOT_RIGHT: InvalidCodeError,
  UNAUTHORIZED: UnauthorizedError,
  PHONE_IN_USE: PhoneInUseError,
  PATIENT_EMAIL_NOT_EQUALS_IN_MV: PatientEmailNotEqualsToInMvError,
  NOT_FOUND: NotFoundError,
  DOCTOR_NOT_EXIST: DoctorNotExistsError,
  ERROR_IN_MV: MvError,
  CREDENTIALS_NOT_EXIST: CredentialsNotExistError,
  FAVORITE_NAME_ALREADY_EXIST: FavoriteAlreadyExists,
  INVALID_CPF_FORMAT: InvalidCpfFormat,
  FILE_IS_ENCRYPTED: FileEncrypted,
  SPECIALTY_NOT_EXIST: SpecialtyNotExistsError,
  EXAM_NOT_FOUND: AgreementDoesNotCoverExam,
  AGE_NOT_EXIST: NoResultsForThisAgeError,
  PREPARATION_INSTRUCTIONS_NOT_FOUND: PreparationInstructionsNotFound
}

export type RepositoryErrorsKey = keyof typeof RepositoryErrors
