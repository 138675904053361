import React, { useState } from 'react'
import * as S from './styles'
import { ModalLabel } from './styles'
import { useHistory, useLocation } from 'react-router'
import Header from 'presentation/components/Header'
import ActualPage from 'presentation/components/ActualPage'
import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'
import { ReactComponent as PadlockIcon } from 'presentation/assets/icons/padlock.svg'
import { ReactComponent as LocationIcon } from 'presentation/assets/icons/location.svg'
import { ReactComponent as ClockIcon } from 'presentation/assets/icons/clock.svg'
import AccompanyingCard from 'presentation/components/accompanyingCard'
import ServiceSolicitationOverviewModal from 'presentation/components/modals/serviceSolicitationOverviewModal'
import { EmergencyRoomAttendanceModel } from 'domain/entities/emergency-room-attendance.model'
import useTimer from 'presentation/hooks/use-timer'
import { setNewTimeLeft } from 'presentation/utils/timer/set-new-time-left'
import Button from 'presentation/components/Button'
import { CancelEmergencyRoomAttendance } from 'domain/usecases/emergency-room/cancel-emergency-room-attendance'
import { toast } from 'react-toastify'

type ServiceSolicitationOverviewLayoutProps = {
  cancelEmergencyRoomAttendance: CancelEmergencyRoomAttendance
}

export default function ServiceSolicitationOverviewLayout({
  cancelEmergencyRoomAttendance
}: ServiceSolicitationOverviewLayoutProps) {
  const history = useHistory()

  const { state } = useLocation<{
    emergencyRoomAttendance: EmergencyRoomAttendanceModel
  }>()

  const emergencyRoomAttendance = new EmergencyRoomAttendanceModel(
    state.emergencyRoomAttendance
  )

  const emergencyRoomToken = emergencyRoomAttendance?.emergencyRoomToken
  const timer =
    emergencyRoomAttendance?.emergencyRoomToken?.arrivalForecastTimer
  const hospital = emergencyRoomAttendance?.hospital
  const accompany = emergencyRoomAttendance?.firstAccompanying

  const [showModal, setShowModal] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<string>()

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

  const handleOpenModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleOpenSuccessModal = () => setShowSuccessModal(true)
  const handleCloseSuccessModal = () => setShowSuccessModal(false)

  const handleClickOnCancelButton = async () => {
    await cancelEmergencyRoomAttendance
      .cancel({
        emergency_room_attendance_id:
          emergencyRoomAttendance.emergency_room_attendance_id ?? -1
      })
      .then(() => {
        handleCloseModal()
        handleOpenSuccessModal()
      })
      .catch((error: any) => toast.error(error.message))
  }

  useTimer({
    action: () =>
      setNewTimeLeft({
        emergencyRoomToken,
        setTimeLeft,
        timeLeft
      }),
    timer: timer
  })

  return (
    <S.Wrapper>
      <Header />
      <S.WhiteContainer>
        <S.Container>
          <ActualPage text="Voltar" onClick={() => history.goBack()} />
          <S.TitleContainer>
            <S.Title data-testid={'title-heading'}>Clínica Médica</S.Title>
            <Heading as="h5" color={'primary'}>
              PRONTO-SOCORRO
            </Heading>
          </S.TitleContainer>

          <S.TokenContainer>
            <PadlockIcon />
            <span>
              TOKEN: <strong>{emergencyRoomToken?.token}</strong>
            </span>
          </S.TokenContainer>

          <S.TokenContainer>
            <LocationIcon />
            <span>
              Unidade: <strong>{hospital?.name}</strong>
            </span>
          </S.TokenContainer>

          <S.TokenContainer>
            <ClockIcon />
            <span>
              <strong>{timeLeft}</strong> para o seu token expirar
            </span>
          </S.TokenContainer>
        </S.Container>
      </S.WhiteContainer>
      <S.Container>
        <S.Title>Informações</S.Title>
        <Heading as="h5">Acompanhantes</Heading>
        <SupportText>
          Contato da pessoa que vai acompanhar você ao longo do procedimento
        </SupportText>

        {accompany && <AccompanyingCard name={`${accompany?.name}`} />}

        <S.Card>
          <span>
            Ao chegar no Pronto-Socorro da Rede Mater Dei, você deve ter em
            mãos:
          </span>
          <ul>
            <li>Seu documento de identidade</li>
            <li>Carteira do convênio</li>
            <li>Número gerado no pré-cadastro</li>
          </ul>
          <p>
            Importante: o pré-cadastro expira{' '}
            {timer?.toStringDifferenceText?.()}, neste caso, o processo deverá
            ser realizado novamente. Caso você tenha imprevistos e não possa
            comparecer ao Pronto-Socorro, gentileza fazer o cancelamento do
            pré-cadastro.
          </p>
        </S.Card>

        <Button
          fullWidth
          type="button"
          variant="outlined"
          style={{ backgroundColor: 'transparent' }}
          onClick={handleOpenModal}
        >
          Cancelar solicitação
        </Button>
      </S.Container>

      <ServiceSolicitationOverviewModal
        isOpen={showModal}
        onClose={handleCloseModal}
        titleSlot={
          <>
            <ModalLabel>
              Deseja <span>cancelar</span> sua <span>solicitação </span>
              solicitação?
            </ModalLabel>
            <SupportText>Essa ação torna o seu Token inválido</SupportText>
          </>
        }
        firstButtonLabel="Confirmar"
        firstButtonOnClick={handleClickOnCancelButton}
        secondButtonLabel="Cancelar"
        secondButtonOnClick={handleCloseModal}
      />

      <ServiceSolicitationOverviewModal
        isOpen={showSuccessModal}
        onClose={handleCloseSuccessModal}
        titleSlot={
          <ModalLabel>
            Solicitação cancelada com <span>sucesso</span>
          </ModalLabel>
        }
        firstButtonLabel="Voltar"
        firstButtonOnClick={() => history.goBack()}
      />
    </S.Wrapper>
  )
}
