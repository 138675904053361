import styled, { css } from 'styled-components'

export const Row = styled.div<{ cols: number }>`
  ${({ cols }) => css`
    width: 100%;
    display: grid;
    gap: 2%;
    align-items: flex-end;
    grid-template-rows: 1fr;
    grid-template-columns: ${`repeat(${cols}, 1fr)`};

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    .row-box {
      &.-inline {
        display: flex;
        align-items: center;
        gap: 2%;
      }
    }
  `}
`

export const CheckWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`

export const CheckInfo = styled.p<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    text-transform: capitalize;
    margin-top: 6px;
    font-size: ${theme.ds.typography.size.xxsmall};
    font-weight: ${theme.ds.typography.weight.normal};
    line-height: ${theme.ds.typography.lineHeight.small};
    color: ${disabled
      ? theme.ds.colors.neutral300
      : theme.ds.colors.neutral600};
  `}
`

export const BoxAction = styled.div`
  margin-top: auto;
  margin-bottom: 0;
`
export const AlertCard = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 8px;
  padding: 18px;
  background-color: ${({ theme }) => theme.ds.colors.danger25};
  border: 1px solid ${({ theme }) => theme.ds.colors.danger700};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  color: ${({ theme }) => theme.ds.colors.danger700};

  span {
    margin-top: -3px;
  }
`

export const UnitName = styled.strong`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.small};
  margin-top: 10px;
`

export const Description = styled.p`
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  color: ${({ theme }) => theme.ds.colors.neutral600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.small};
`

export const Fone = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  color: ${({ theme }) => theme.ds.colors.primary600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.small};
  text-decoration: underline;
`

export const TelephoneContactContainer = styled.div`
  margin: 18px 0;
`
