import styled from 'styled-components'

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const StatusWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const StatusCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  display: inline-block;
`

export const StatusText = styled.span`
  font-size: 12px;
`

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 22px;
`

export const LineRadio = styled.div`
  display: flex;
  gap: 80px;
`

export const TextAreaWrapper = styled.div`
  margin-top: 16px;
`

export const Wrapper = styled.div`
  margin-bottom: 16px;
`

export const BannerContainer = styled.div`
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
