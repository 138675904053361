import React from 'react'
import HeadingNew, { HeadingProps } from '../../../../components/HeadingNew'
import ArrowDowIcon from 'presentation/assets/icons/arrow-down-green.svg'
import * as S from './styles'
// import SupportText from '../../../../components/SupportText'

type TitleLabelProps = {
  size?: HeadingProps['size']
  color?: HeadingProps['color']
  as?: HeadingProps['as']
}

type TitleButtonProps = {
  titleButtonText?: string
  titleButtonIcon?: string
  showTitleButton?: boolean
  onClickTitleButton?: () => void
  altTitleButton?: string
}

export type UploadTitleProps = {
  title?: string
  subtitle?: string
  titleLabelProps?: TitleLabelProps
  titleButtonProps?: TitleButtonProps
}

export function UploadTitle({
  title,
  // subtitle,
  titleLabelProps,
  titleButtonProps
}: UploadTitleProps) {
  const propsTitleLabel: TitleLabelProps = {
    as: 'h2',
    color: 'neutral700',
    size: 'large',
    ...titleLabelProps
  }
  const propsTitleButton: TitleButtonProps = {
    titleButtonText: 'Fazer download de todos os documentos',
    titleButtonIcon: ArrowDowIcon,
    showTitleButton:
      typeof titleButtonProps?.showTitleButton === 'boolean'
        ? titleButtonProps?.showTitleButton
        : true,
    altTitleButton: 'Ícone de download de arquivos',
    ...titleButtonProps
  }

  const onTitleButtonClick = () => {
    titleButtonProps?.onClickTitleButton?.()
  }

  return (
    <S.UploadTitleContainer>
      <div>
        <HeadingNew {...propsTitleLabel}>{title}</HeadingNew>
        {/* <SupportText>{subtitle}</SupportText> */}
      </div>
      {propsTitleButton.showTitleButton && (
        <S.TitleButton onClick={onTitleButtonClick}>
          <img
            src={propsTitleButton.titleButtonIcon}
            alt={propsTitleButton.altTitleButton}
          />
          <label>{propsTitleButton.titleButtonText}</label>
        </S.TitleButton>
      )}
    </S.UploadTitleContainer>
  )
}
