import { LoadUserGroups } from 'domain/usecases/authentication/load-user-groups'
import { AuthenticationRepository } from 'repository/interfaces/authentication-repository'

export class RemoteLoadUserGroups implements LoadUserGroups {
  constructor(
    private readonly authenticationRepository: AuthenticationRepository
  ) {}

  load(params: LoadUserGroups.Params): Promise<LoadUserGroups.Model> {
    return this.authenticationRepository.loadUserGroups(params)
  }
}

export type LoadUserGroupsModel = LoadUserGroups.Model
