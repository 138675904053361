import { LoadPatientExamDocuments } from 'domain/usecases/patient/load-patient-exam-documents'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientExamDocuments
  implements LoadPatientExamDocuments
{
  constructor(private readonly patientRepository: PatientRepository) {}

  load(patient_id: number): Promise<LoadPatientExamDocuments.Model> {
    return this.patientRepository.loadPatientExamDocuments(patient_id)
  }
}

export type LoadPatientExamDocumentsModel = LoadPatientExamDocuments.Model
