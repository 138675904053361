import React, { useEffect, useState } from 'react'

import { useStores } from 'presentation/hooks/use-stores'
import { Hospital } from 'domain/entities/hospital-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'

export type CreateSurgicalOrderIntroTypes = {
  title: string
  description: string
  orderFn: () => void
  goBack: () => void
} & WithLoadingProps

function CreateSurgicalOrderIntro({
  title,
  description,
  orderFn,
  goBack,
  setIsLoading
}: CreateSurgicalOrderIntroTypes) {
  const [selectedHospital, setSelectedHospital] = useState<number>()
  const [hospitals, setHospitals] = useState<Hospital[]>([])

  const currentHospital = useStores().currentHospital

  const loadHospitals = async () => {
    await currentHospital.load()
    setHospitals(currentHospital.hospitalList)
  }

  const handleSelectHospital = (selectedHospital: number) => {
    orderFn()
    currentHospital.setHospitalSelected(selectedHospital)
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        await loadHospitals()
      } catch (e) {
        toast.error('Erro ao buscar os hospitais')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return (
    <>
      <HeaderNew actualPageTitle={title} />
      <ContainerNew
        title={title}
        titleColor="primary600"
        titleWeight="bold"
        titleSize="large"
        subtitle={description}
        primaryButton={
          <ButtonNew
            onClick={() => handleSelectHospital(selectedHospital ?? 0)}
            size="large"
            fullWidth
            disabled={!selectedHospital}
          >
            Iniciar
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew
            style={{ marginTop: '8px' }}
            onClick={goBack}
            size="medium"
            variant="text"
            outlined
          >
            Cancelar
          </ButtonNew>
        }
        justifyContent="center"
      >
        <SelectFieldNew
          name="hospital"
          label="Hospital"
          items={hospitals?.map(({ name, friendly_name, hospital_id }) => ({
            label: friendly_name || name,
            value: hospital_id
          }))}
          onInputChange={(e) => setSelectedHospital(Number(e))}
          value={selectedHospital || ''}
          required
        />
      </ContainerNew>
    </>
  )
}

export default WithLoading(CreateSurgicalOrderIntro)
