import { LoadSurgicalSchedule } from 'domain/usecases/surgical-schedule/load-surgical-schedule'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadSchedule implements LoadSurgicalSchedule {
  constructor(
    private readonly scheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(
    params: LoadSurgicalSchedule.Params
  ): Promise<LoadSurgicalSchedule.Model> {
    return this.scheduleRepository.loadSurgicalSchedule(params)
  }
}

export type LoadSurgicalScheduleModel = LoadSurgicalSchedule.Model
