export type Vaccine = {
  vaccineName: string
  dosage: string
  contraIndications: string
  indications: string
  adverseReactions: string
  prevention: string
}

export type Package = {
  packageName: string
  alias: string
  price: string
  vaccines: Vaccine[]
}

const FirstPackage: Package = {
  packageName: 'Recém-Nascido 2 a 6 meses',
  alias: 'Pacote Bebês de 2 a 6 meses',
  price: 'R$ 4.230,00',
  vaccines: [
    {
      vaccineName:
        'Hexavalente (difteria, tétano, coqueluche, Haemophilus tipo B, Hepatite B e Poliomielite)',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer componente da sua fórmula; ' +
        'indivíduos que já tenham apresentado sinais de hipersensibilidade após administração anterior de vacinas ' +
        'contra difteria, tétano ou coqueluche; ' +
        'indivíduos que já tenham apresentado encefalopatia de etiologia desconhecida nos sete dias seguintes à ' +
        'utilização de vacina que contém pertussis; ' +
        'indivíduos que tenham apresentado trombocitopenia transitória ou complicações neurológicas após o uso ' +
        'anterior de vacina contra difteria e/ou tétano.',
      indications:
        'Crianças a partir de 4 anos de idade, gestantes, adolescentes e adultos;\n' +
        'Todas as pessoas que convivem com crianças menores de 2 anos, sobretudo bebês com menos de 1 ano, incluindo ' +
        'familiares, babás, cuidadores e profissionais da saúde.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, perda de apetite, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Difteria, tétano e coqueluche e proteção passiva contra coqueluche no início da infância após a imunização ' +
        'materna durante a gravidez.'
    },
    {
      vaccineName: 'Pneumocócica 15',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade aos componentes da fórmula;\n' +
        'Reação anafilática a neomicina;\n' +
        'Doenças que afetem a imunidade.',
      indications:
        'Crianças a partir de 6 semanas de idade, adolescentes, adultos e idosos;\n' +
        'Adolescentes e adultos portadores de certas doenças crônicas e idosos, recomenda-se esquema com as ' +
        'vacinas VPC13 e VPP23.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em cerca de 10% dos pacientes pode ocorrer diminuição do apetite, irritabilidade, sonolência, ' +
        'aumento do sono, sono agitado, diminuição do sono, febre, vermelhidão, endurecimento, inchaço, ' +
        'dor ou sensibilidade local.',
      prevention:
        'Infecções pneumocócicas causadas pelos tipos mais comuns de pneumococos, causadores de doenças, como ' +
        'meningite, sepse, bacteremia, pneumonia e otite média.'
    },
    {
      vaccineName: 'Rotavírus Pentavalente',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Reação alérgica prévia após receber uma dose da vacina;\n' +
        'Se for alérgica a qualquer um dos ingredientes da vacina;\n' +
        'Idade superior à preconizada e se tiver Imunodeficiência Combinada Grave (SCID).',
      indications:
        'Bebês de 6 semanas a 7 meses e 29 dias. A primeira dose deve ser obrigatoriamente aplicada até a idade de 3 ' +
        'meses e 15 dias, e a última dose até os 7 meses e 29 dias.',
      adverseReactions:
        'A vacina é segura e apresenta boa eficácia. ' +
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo geralmente leves e temporários, podendo ocorrer diarreia, vômito e febre.',
      prevention:
        'Infecção por rotavírus, que é uma infecção viral do trato digestivo e causa importante de gastroenterite ' +
        '(inflamação do estômago e dos intestinos que causa diarreia e vômitos).'
    },
    {
      vaccineName: 'Meningocócica ACWY',
      dosage: '2 doses no 3º e 5º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer um dos componentes da fórmula ou que tenham apresentado sinais de ' +
        'hipersensibilidade após a administração prévia dessa vacina.',
      indications:
        'Crianças a partir de 6 semanas de idade;\n' +
        'Adultos e idosos, dependendo da situação epidemiológica;\n' +
        'Pessoas de qualquer idade com doenças que aumentem o risco para a doença meningocócica;\n' +
        'Viajantes com destino às regiões onde há risco aumentado da doença.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Doenças meningocócicas invasivas causadas por Neisseria meningitidis dos grupos A, C, W-135 e Y.'
    },
    {
      vaccineName: 'Meningocócica B',
      dosage: '2 doses no 3º e 5º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer um dos componentes da fórmula ou que tenham apresentado sinais de ' +
        'hipersensibilidade após a administração prévia dessa vacina.',
      indications: 'Indivíduos a partir de 2 meses a 50 anos de idade.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em lactentes e crianças (com menos de 2 anos de idade), as reações adversas locais e sistêmicas mais comuns observadas ' +
        'foram sensibilidade e eritema no local da injeção, febre e irritabilidade e em adolescentes e adultos, as reações adversas ' +
        'mais comuns observadas foram dor no local da injeção, mal-estar e cefaleia.',
      prevention:
        'Doença meningocócica invasiva causada pela bactéria Neisseria meningitidis do grupo B.'
    }
  ]
}

const SecondPackage: Package = {
  packageName: 'Recém-Nascido 2 a 12 meses',
  alias: 'Pacote Bebês de 2 a 12 meses',
  price: 'R$ 6.130,00',
  vaccines: [
    {
      vaccineName:
        'Hexavalente (difteria, tétano, coqueluche, Haemophilus tipo B, Hepatite B e Poliomielite)',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer componente da sua fórmula; ' +
        'indivíduos que já tenham apresentado sinais de hipersensibilidade após administração anterior de vacinas ' +
        'contra difteria, tétano ou coqueluche; ' +
        'indivíduos que já tenham apresentado encefalopatia de etiologia desconhecida nos sete dias seguintes à ' +
        'utilização de vacina que contém pertussis; ' +
        'indivíduos que tenham apresentado trombocitopenia transitória ou complicações neurológicas após o uso ' +
        'anterior de vacina contra difteria e/ou tétano.',
      indications:
        'Crianças a partir de 4 anos de idade, gestantes, adolescentes e adultos;\n' +
        'Todas as pessoas que convivem com crianças menores de 2 anos, sobretudo bebês com menos de 1 ano, incluindo ' +
        'familiares, babás, cuidadores e profissionais da saúde.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, perda de apetite, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Difteria, tétano e coqueluche e proteção passiva contra coqueluche no início da infância após a imunização ' +
        'materna durante a gravidez.'
    },
    {
      vaccineName: 'Pneumocócica 15',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer um dos componentes da vacina.',
      indications:
        'Crianças a partir de 6 semanas de idade, adolescentes, adultos e idosos;\n' +
        'Adolescentes e adultos portadores de certas doenças crônicas e idosos, recomenda-se esquema com as ' +
        'vacinas VPC13 e VPP23.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em cerca de 10% dos pacientes pode ocorrer diminuição do apetite, irritabilidade, sonolência, ' +
        'aumento do sono, sono agitado, diminuição do sono, febre, vermelhidão, endurecimento, inchaço, ' +
        'dor ou sensibilidade local.',
      prevention:
        'Infecções pneumocócicas causadas pelos tipos mais comuns de pneumococos, causadores de doenças, como ' +
        'meningite, sepse, bacteremia, pneumonia e otite média.'
    },
    {
      vaccineName: 'Rotavírus Pentavalente',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Reação alérgica prévia após receber uma dose da vacina;\n' +
        'Se for alérgica a qualquer um dos ingredientes da vacina;\n' +
        'Idade superior à preconizada e se tiver Imunodeficiência Combinada Grave (SCID).',
      indications:
        'Bebês de 6 semanas a 7 meses e 29 dias. A primeira dose deve ser obrigatoriamente aplicada até a idade de 3 ' +
        'meses e 15 dias, e a última dose até os 7 meses e 29 dias.',
      adverseReactions:
        'A vacina é segura e apresenta boa eficácia. ' +
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo geralmente leves e temporários, podendo ocorrer diarreia, vômito e febre.',
      prevention:
        'Infecção por rotavírus, que é uma infecção viral do trato digestivo e causa importante de gastroenterite ' +
        '(inflamação do estômago e dos intestinos que causa diarreia e vômitos).'
    },
    {
      vaccineName: 'Meningocócica ACWY',
      dosage: '3 doses no 3º, 5º e  12º mês de vida',
      contraIndications:
        'Hipersensibilidade às substâncias que compõem a vacina.',
      indications:
        'Crianças a partir de 6 semanas de idade;\n' +
        'Adultos e idosos, dependendo da situação epidemiológica;\n' +
        'Pessoas de qualquer idade com doenças que aumentem o risco para a doença meningocócica;\n' +
        'Viajantes com destino às regiões onde há risco aumentado da doença.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Doenças meningocócicas invasivas causadas por Neisseria meningitidis dos grupos A, C, W-135 e Y.'
    },
    {
      vaccineName: 'Meningocócica B',
      dosage: '3 doses no 3º, 5º e  12º mês de vida',
      contraIndications: 'Hipersensibilidade aos componentes da fórmula.',
      indications: 'Indivíduos a partir de 2 meses a 50 anos de idade.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em lactentes e crianças (com menos de 2 anos de idade), as reações adversas locais e sistêmicas mais comuns observadas ' +
        'foram sensibilidade e eritema no local da injeção, febre e irritabilidade e em adolescentes e adultos, as reações adversas ' +
        'mais comuns observadas foram dor no local da injeção, mal-estar e cefaleia.',
      prevention:
        'Doença meningocócica invasiva causada pela bactéria Neisseria meningitidis do grupo B.'
    },
    {
      vaccineName: 'Febre amarela',
      dosage: '1 dose no 9º mês de vida',
      contraIndications:
        'Menores de 6 meses de idade; \n' +
        'Indivíduos com histórico de reação alérgica grave a ovos, a proteínas de galinha ou a qualquer componente da vacina;\n',
      indications:
        'Adultos e crianças acima de 9 meses de idade;\n' +
        'Pessoas que vivem, viajam ou passam por uma área endêmica; ' +
        'e pessoas não vacinadas que se deslocam de uma área endêmica ' +
        'para uma área não endêmica potencialmente receptiva.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'os quais são geralmente leves e transitórios. Pode haver cefaleia, astenia e dor no local da injeção nos 3 ' +
        'primeiros dias após a aplicação\n',
      prevention:
        'Prevenção da febre amarela que é uma arbovirose causada por um vírus da família Flaviviradae, ' +
        'do gênero Flavivirus, transmitida ao homem por picada do mosquito Aedes aegypti infectado. ' +
        'A forma clássica da doença caracteriza-se por um quadro íctero-hemorrágico, com comprometimento hepático, ' +
        'renal, miocárdico, neurológico e hemorrágico, com elevada letalidade.'
    },
    {
      vaccineName: 'Varicela',
      dosage: '1 dose no 12º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer componente da vacina, incluindo a gelatina; hstórico de reação anafilactóide à ' +
        'neomicina; discrasias sanguíneas, leucemia, linfomas de qualquer tipo ; terapia imunossupressora; ' +
        'imunodeficiência primária ou adquirida, histórico familiar de imunodeficiência congênita ou hereditária, ' +
        'a menos que seja demonstrada a competência imunológica do receptor potencial da vacina; tuberculose ativa ' +
        'não tratada; gestantes; e qualquer doença febril ativa. A gravidez deve ser evitada por um período de três ' +
        'meses após a vacinação de mulheres férteis.',
      indications:
        'Crianças a partir de 12 meses (excepcionalmente, em situações de surto, por exemplo, também para crianças ' +
        'menores, a partir de 9 meses). Todas as crianças, adolescentes e adultos suscetíveis (que não tiveram catapora) ' +
        'devem ser vacinados.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'As reações adversas mais comuns são irritabilidade, vermelhidão, inchaço, calor e endurecimento no local de ' +
        'aplicação da vacina, além de febre. Cerca de 3% dos vacinados podem apresentar algumas vesículas, ' +
        'principalmente próximas ao local de aplicação.',
      prevention: 'Infecção causada pelo vírus da varicela (catapora).'
    },
    {
      vaccineName: 'Hepatite A',
      dosage: '1 dose no 12º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer componente da fórmula ou ao resíduo e indivíduos que apresentaram sinais de ' +
        'hipersensibilidade após receber essa vacina.',
      indications: 'A partir de 12 meses de vida.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as ' +
        'pessoas, sendo mais comum cefaleia, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Doença causada pelo vírus da hepatite A (VHA) em indivíduos com risco de exposição a esse patógeno. ' +
        'A vacina não protege contra infecções causadas pelos vírus das hepatites B, C, D e E nem por outros patógenos ' +
        'capazes de infectar o fígado. O contágio pode ocorrer através da ingestão de alimentos e água contaminados.'
    },
    {
      vaccineName: 'Triplice viral (caxumba, rubéola e sarampo)',
      dosage: '1 dose no 12º mês de vida',
      contraIndications:
        'Gestantes; hipersensibilidade a qualquer componente da vacina, incluindo a gelatina; reações anafiláticas à ' +
        'neomicina; qualquer doença respiratória febril ou outra infecção febril ativa; tuberculose ativa não tratada; ' +
        'pacientes sob terapia imunossupressora (não se aplica a pacientes que estejam recebendo corticosteroides como ' +
        'terapia de substituição); indivíduos com discrasias sanguíneas, leucemia ou linfomas de qualquer tipo; ' +
        'imunodeficiência primária e adquirida; e indivíduos com histórico familiar de imunodeficiência congênita ou ' +
        'hereditária, até que seja demonstrada a competência imunológica do indivíduo que receberá a vacina.',
      indications:
        'Crianças a partir de 12 meses de idade, adolescentes, adultos e idosos.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'As reações mais comuns são queimação e/ou pontadas de curta duração no local da injeção. Febre alta ' +
        '(maior que 39,5⁰C), que surge de cinco a 12 dias após a vacinação, com um a cinco dias de duração, ' +
        'pode ocorrer em 5% a 15% dos vacinados.',
      prevention:
        'Sarampo, caxumba e rubéola que são doenças comuns da infância, que podem estar associadas a graves ' +
        'complicações e/ou morte.'
    }
  ]
}

const ThirdPackage: Package = {
  packageName: 'Recém-Nascido 2 a 18 meses',
  alias: 'Pacote Bebês de 2 a 18 meses',
  price: 'R$ 6.930,00',
  vaccines: [
    {
      vaccineName:
        'Hexavalente (difteria, tétano, coqueluche, Haemophilus tipo B, Hepatite B e Poliomielite)',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer componente da sua fórmula; ' +
        'indivíduos que já tenham apresentado sinais de hipersensibilidade após administração anterior de vacinas ' +
        'contra difteria, tétano ou coqueluche; ' +
        'indivíduos que já tenham apresentado encefalopatia de etiologia desconhecida nos sete dias seguintes à ' +
        'utilização de vacina que contém pertussis; ' +
        'indivíduos que tenham apresentado trombocitopenia transitória ou complicações neurológicas após o uso ' +
        'anterior de vacina contra difteria e/ou tétano.',
      indications:
        'Crianças a partir de 4 anos de idade, gestantes, adolescentes e adultos;\n' +
        'Todas as pessoas que convivem com crianças menores de 2 anos, sobretudo bebês com menos de 1 ano, incluindo ' +
        'familiares, babás, cuidadores e profissionais da saúde.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, perda de apetite, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Difteria, tétano e coqueluche e proteção passiva contra coqueluche no início da infância após a imunização ' +
        'materna durante a gravidez.'
    },
    {
      vaccineName: 'Pneumocócica 15',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer um dos componentes da vacina.',
      indications:
        'Crianças a partir de 6 semanas de idade, adolescentes, adultos e idosos;\n' +
        'Adolescentes e adultos portadores de certas doenças crônicas e idosos, recomenda-se esquema com as ' +
        'vacinas VPC13 e VPP23.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em cerca de 10% dos pacientes pode ocorrer diminuição do apetite, irritabilidade, sonolência, ' +
        'aumento do sono, sono agitado, diminuição do sono, febre, vermelhidão, endurecimento, inchaço, ' +
        'dor ou sensibilidade local.',
      prevention:
        'Infecções pneumocócicas causadas pelos tipos mais comuns de pneumococos, causadores de doenças, como ' +
        'meningite, sepse, bacteremia, pneumonia e otite média.'
    },
    {
      vaccineName: 'Rotavírus Pentavalente',
      dosage: '3 doses no 2º, 4º e 6º mês de vida',
      contraIndications:
        'Reação alérgica prévia após receber uma dose da vacina;\n' +
        'Se for alérgica a qualquer um dos ingredientes da vacina;\n' +
        'Idade superior à preconizada e se tiver Imunodeficiência Combinada Grave (SCID).',
      indications:
        'Bebês de 6 semanas a 7 meses e 29 dias. A primeira dose deve ser obrigatoriamente aplicada até a idade de 3 ' +
        'meses e 15 dias, e a última dose até os 7 meses e 29 dias.',
      adverseReactions:
        'A vacina é segura e apresenta boa eficácia. ' +
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo geralmente leves e temporários, podendo ocorrer diarreia, vômito e febre.',
      prevention:
        'Infecção por rotavírus, que é uma infecção viral do trato digestivo e causa importante de gastroenterite ' +
        '(inflamação do estômago e dos intestinos que causa diarreia e vômitos).'
    },
    {
      vaccineName: 'Meningocócica ACWY',
      dosage: '3 doses no 3º, 5º e  12º mês de vida',
      contraIndications:
        'Hipersensibilidade conhecida a qualquer um dos componentes da fórmula ou que tenham apresentado sinais de ' +
        'hipersensibilidade após a administração prévia dessa vacina.',
      indications:
        'Crianças a partir de 6 semanas de idade;\n' +
        'Adultos e idosos, dependendo da situação epidemiológica;\n' +
        'Pessoas de qualquer idade com doenças que aumentem o risco para a doença meningocócica;\n' +
        'Viajantes com destino às regiões onde há risco aumentado da doença.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'sendo mais comum cefaleia, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Doenças meningocócicas invasivas causadas por Neisseria meningitidis dos grupos A, C, W-135 e Y.'
    },
    {
      vaccineName: 'Meningocócica B',
      dosage: '3 doses no 3º, 5º e  12º mês de vida',
      contraIndications: 'Hipersensibilidade aos componentes da fórmula.',
      indications: 'Indivíduos a partir de 2 meses a 50 anos de idade.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'Em lactentes e crianças (com menos de 2 anos de idade), as reações adversas locais e sistêmicas mais comuns observadas ' +
        'foram sensibilidade e eritema no local da injeção, febre e irritabilidade e em adolescentes e adultos, as reações adversas ' +
        'mais comuns observadas foram dor no local da injeção, mal-estar e cefaleia.',
      prevention:
        'Doença meningocócica invasiva causada pela bactéria Neisseria meningitidis do grupo B.'
    },
    {
      vaccineName: 'Febre amarela',
      dosage: '1 dose no 9º mês de vida',
      contraIndications:
        'Menores de 6 meses de idade; \n' +
        'Indivíduos com histórico de reação alérgica grave a ovos, a proteínas de galinha ou a qualquer componente da vacina;\n',
      indications:
        'Adultos e crianças acima de 9 meses de idade;\n' +
        'Pessoas que vivem, viajam ou passam por uma área endêmica; ' +
        'e pessoas não vacinadas que se deslocam de uma área endêmica ' +
        'para uma área não endêmica potencialmente receptiva.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas, ' +
        'os quais são geralmente leves e transitórios. Pode haver cefaleia, astenia e dor no local da injeção nos 3 ' +
        'primeiros dias após a aplicação\n',
      prevention:
        'Prevenção da febre amarela que é uma arbovirose causada por um vírus da família Flaviviradae, ' +
        'do gênero Flavivirus, transmitida ao homem por picada do mosquito Aedes aegypti infectado. ' +
        'A forma clássica da doença caracteriza-se por um quadro íctero-hemorrágico, com comprometimento hepático, ' +
        'renal, miocárdico, neurológico e hemorrágico, com elevada letalidade.'
    },
    {
      vaccineName: 'Varicela',
      dosage: '2 doses no 12º e 18º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer componente da vacina, incluindo a gelatina; hstórico de reação anafilactóide à ' +
        'neomicina; discrasias sanguíneas, leucemia, linfomas de qualquer tipo ; terapia imunossupressora; ' +
        'imunodeficiência primária ou adquirida, histórico familiar de imunodeficiência congênita ou hereditária, ' +
        'a menos que seja demonstrada a competência imunológica do receptor potencial da vacina; tuberculose ativa ' +
        'não tratada; gestantes; e qualquer doença febril ativa. A gravidez deve ser evitada por um período de três ' +
        'meses após a vacinação de mulheres férteis.',
      indications:
        'Crianças a partir de 12 meses (excepcionalmente, em situações de surto, por exemplo, também para crianças ' +
        'menores, a partir de 9 meses). Todas as crianças, adolescentes e adultos suscetíveis (que não tiveram catapora) ' +
        'devem ser vacinados.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'As reações adversas mais comuns são irritabilidade, vermelhidão, inchaço, calor e endurecimento no local de ' +
        'aplicação da vacina, além de febre. Cerca de 3% dos vacinados podem apresentar algumas vesículas, ' +
        'principalmente próximas ao local de aplicação.',
      prevention: 'Infecção causada pelo vírus da varicela (catapora).'
    },
    {
      vaccineName: 'Hepatite A',
      dosage: '2 doses no 12º e 18º mês de vida',
      contraIndications:
        'Hipersensibilidade a qualquer componente da fórmula ou ao resíduo e indivíduos que apresentaram sinais de ' +
        'hipersensibilidade após receber essa vacina.',
      indications: 'A partir de 12 meses de vida.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as ' +
        'pessoas, sendo mais comum cefaleia, dor, vermelhidão e inchaço no local da injeção.',
      prevention:
        'Doença causada pelo vírus da hepatite A (VHA) em indivíduos com risco de exposição a esse patógeno. ' +
        'A vacina não protege contra infecções causadas pelos vírus das hepatites B, C, D e E nem por outros patógenos ' +
        'capazes de infectar o fígado. O contágio pode ocorrer através da ingestão de alimentos e água contaminados.'
    },
    {
      vaccineName: 'Triplice viral (caxumba, rubéola e sarampo)',
      dosage: '2 doses no 12º e 18º mês de vida',
      contraIndications:
        'Gestantes; hipersensibilidade a qualquer componente da vacina, incluindo a gelatina; reações anafiláticas à ' +
        'neomicina; qualquer doença respiratória febril ou outra infecção febril ativa; tuberculose ativa não tratada; ' +
        'pacientes sob terapia imunossupressora (não se aplica a pacientes que estejam recebendo corticosteroides como ' +
        'terapia de substituição); indivíduos com discrasias sanguíneas, leucemia ou linfomas de qualquer tipo; ' +
        'imunodeficiência primária e adquirida; e indivíduos com histórico familiar de imunodeficiência congênita ou ' +
        'hereditária, até que seja demonstrada a competência imunológica do indivíduo que receberá a vacina.',
      indications:
        'Crianças a partir de 12 meses de idade, adolescentes, adultos e idosos.',
      adverseReactions:
        'Assim como qualquer vacina, pode apresentar eventos adversos, embora isso não ocorra com todas as pessoas. ' +
        'As reações mais comuns são queimação e/ou pontadas de curta duração no local da injeção. Febre alta ' +
        '(maior que 39,5⁰C), que surge de cinco a 12 dias após a vacinação, com um a cinco dias de duração, ' +
        'pode ocorrer em 5% a 15% dos vacinados.',
      prevention:
        'Sarampo, caxumba e rubéola que são doenças comuns da infância, que podem estar associadas a graves ' +
        'complicações e/ou morte.'
    }
  ]
}

export const newbornPackages: Package[] = [
  FirstPackage,
  SecondPackage,
  ThirdPackage
]
