import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { AnswerPendency } from 'presentation/pages/AnswerPendency'
import { SurgicalPendencyRepository } from 'repository/repositories/surgical-pendency/surgical-pendency-repository'
import { RemoteAnswerSurgicalPendency } from 'service/usecases/answer-surgical-pendency/remote-answer-surgical-pendency'
import { RemoteUploadSurgicalPendencyDocument } from 'service/usecases/upload-surgical-pendency-document/remote-upload-surgical-pendency-document'

export default function makeDoctorAnswerPendency() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const surgicalPendencyRepository = new SurgicalPendencyRepository(
    apiRepository
  )

  const answerSurgicalPendency = new RemoteAnswerSurgicalPendency(
    surgicalPendencyRepository
  )
  const uploadSurgicalPendencyDocument =
    new RemoteUploadSurgicalPendencyDocument(surgicalPendencyRepository)

  return (
    <AnswerPendency
      answerSurgicalPendency={answerSurgicalPendency}
      uploadSurgicalPendencyDocument={uploadSurgicalPendencyDocument}
    />
  )
}
