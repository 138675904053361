import React, { useState } from 'react'

import * as S from './styles'

type Props = {
  handleSwitch(checked: boolean): void
  isChecked?: boolean
}

const Switch = ({ handleSwitch, isChecked = false }: Props) => {
  const [checked, setChecked] = useState(isChecked)

  const handleClick = () => {
    setChecked(!checked)
    handleSwitch(!checked)
  }

  return <S.Wrapper onClick={handleClick} checked={checked} />
}

export default Switch
