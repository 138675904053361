import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermConfidentiality() {
  return {
    name: TermsTypeEnum.TERM_CONFIDENTIALITY,
    title: 'Termo de confidencialidade',
    content: (
      <>
        {/* <iframe
          src="https://production-exp-paciente-public-assets.s3.amazonaws.com/terms/Termo+de+confidencialidade.pdf"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            paddingBottom: '0'
          }}
        /> */}
        <div>
          <p>
            Declaro, para os fins do disposto na Lei nº 13.709/2018 - Lei Geral
            de Proteção de Dados Pessoais, que, ao acessar o sítio eletrônico
            MEU MATER DEI, comprometo-me, quando do tratamento dos dados do
            TITULAR, a não divulgar as referidas informações, não utilizá-las
            para gerar benefício próprio exclusivo e/ou unilateral, presente ou
            futuro, ou para o uso de terceiros, não efetuar nenhuma gravação ou
            cópia de qualquer documentação confidencial a que tiver acesso, não
            me apropriar de material confidencial e/ou sigiloso da tecnologia
            que venha a ser disponível, não repassar o conhecimento das
            informações confidenciais bem como a respeitar a inviolabilidade dos
            dados do TITULAR, obrigando-me, assim, a ressarcir a ocorrência de
            qualquer dano e/ou prejuízo oriundo de eventual quebra de sigilo das
            informações por mim causados, e estando ainda sujeito à
            responsabilização cível e/ou criminal.
          </p>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de confidencialidade`
  }
}
