import { IShareExamResult } from 'domain/usecases/exam/share-exam-result'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteSharedProcedureResult implements IShareExamResult {
  constructor(private readonly patientRepository: PatientRepository) {}

  async share(
    params: IShareExamResult.Params
  ): Promise<IShareExamResult.Model> {
    return this.patientRepository.shareProcedureResult(params)
  }
}
