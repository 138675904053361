import styled, { css } from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  scrollbar-width: none;
`

export const Row = styled.div<{ cols: number }>`
  ${({ cols }) => css`
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-rows: 1fr;
    grid-template-columns: ${`repeat(${cols}, 1fr)`};
    margin-bottom: 24px;
  `}
`

export const BodyInfoLabel = styled.small`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 8px;
  width: 1px;
  display: block;
  margin-right: 10px;
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  /* .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } */
`

export const CardContainer = styled.div`
  margin-top: 20px;
  display: grid;
  border: 1px solid #82cec9;
  border-radius: 8px;
  background: ${({ theme }) => theme.ds.colors.white};
  padding: 15px;

  button {
    margin-top: 15px;
  }
`

export const CardLabel = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const CardTextValue = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 5px;
`

export const PurpleStrong = styled.strong`
  margin: 20px 0px 10px 0px;
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const Description = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 20px;
`
