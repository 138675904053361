import type { DoctorRegisterRepository } from 'repository/interfaces/doctor-register-repository'
import type { CreateDoctorRegisterReview } from 'domain/usecases/doctor-register-review/create-doctor-register-review'

export class RemoteCreateDoctorRegisterReview
  implements CreateDoctorRegisterReview
{
  constructor(
    private readonly doctorRegisterRepository: DoctorRegisterRepository
  ) {}

  execute(
    params: CreateDoctorRegisterReview.Params
  ): Promise<CreateDoctorRegisterReview.Model> {
    return this.doctorRegisterRepository.createDoctorRegisterReview(params)
  }
}
