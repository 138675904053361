export enum NotificationType {
  UPDATE_PATIENT_SURGICAL_ORDER_STATUS = 'UPDATE_PATIENT_SURGICAL_ORDER_STATUS',
  UPDATE_DOCTOR_SURGICAL_ORDER_STATUS = 'UPDATE_DOCTOR_SURGICAL_ORDER_STATUS',
  UPDATE_SECRETARY_SURGICAL_ORDER_STATUS = 'UPDATE_SECRETARY_SURGICAL_ORDER_STATUS',
  PATIENT_CREATE_PENDENCY = 'PATIENT_CREATE_PENDENCY',
  DOCTOR_CREATE_PENDENCY = 'DOCTOR_CREATE_PENDENCY',
  SECRETARY_CREATE_PENDENCY = 'SECRETARY_CREATE_PENDENCY',
  PATIENT_UPDATE_PENDENCY = 'PATIENT_UPDATE_PENDENCY',
  CRMO_UPDATE_PENDENCY = 'CRMO_UPDATE_PENDENCY',
  CRMO_WEEK_PENDENCIES = 'CRMO_WEEK_PENDENCIES',
  DOCTOR_WEEK_SURGICAL_ORDER_COUNT = 'DOCTOR_WEEK_SURGICAL_ORDER_COUNT',
  SECRETARY_WEEK_SURGICAL_ORDER_COUNT = 'SECRETARY_WEEK_SURGICAL_ORDER_COUNT',
  CREATE_PATIENT_NEW_SURGICAL_ORDER = 'CREATE_PATIENT_NEW_SURGICAL_ORDER',
  PATIENT_WEEK_PENDENCIES = 'PATIENT_WEEK_PENDENCIES',
  DOCTOR_WEEK_PENDENCIES = 'DOCTOR_WEEK_PENDENCIES',
  SECRETARY_WEEK_PENDENCIES = 'SECRETARY_WEEK_PENDENCIES',
  // SCHEDULE TYPES
  CREATE_SCHEDULE_CONSULT = 'CREATE_SCHEDULE_CONSULT',
  UPDATE_SCHEDULE_CONSULT = 'UPDATE_SCHEDULE_CONSULT',
  CANCEL_SCHEDULE_CONSULT = 'CANCEL_SCHEDULE_CONSULT'
}
