import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const PurpleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const StrongHeader = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 8px;
`

export const SubTitleHeader = styled.pre`
  color: ${({ theme }) => theme.ds.colors.neutral500};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  font-family: 'Poppins';
  margin: 16px 0 24px;

  white-space: pre-wrap;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  margin-bottom: 20px;
`

export const EmptyStateContainer = styled.div`
  display: flex;
  margin: auto;
  place-content: center;
`

type AccordionContainerProps = {
  isVisible: boolean
}

export const AccordionContainer = styled.div<AccordionContainerProps>`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  cursor: pointer;

  svg {
    transition: transform 0.3s ease;
    transform: rotate(${({ isVisible }) => (isVisible ? '0' : '180deg')});
  }
`
