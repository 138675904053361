import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteInvitePatient } from 'service/usecases/invite-patient/remote-invite-patient'
import PatientService from 'presentation/contexts/services/patient-service'
import { RemoteLoadPatientByCpf } from 'service/usecases/load-patient-by-cpf/remote-load-patient-by-cpf'
import { RemoteSearchPatient } from 'service/usecases/search-patient/remote-search-patient'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import { RemoteLoadPatientDocuments } from 'service/usecases/load-patient-documents/remote-load-patient-documents'
import { RemoteDownloadPatientDocument } from 'service/usecases/download-patient-document/remote-download-patient-document'
import { RemoteLoadPatientHideContact } from 'service/usecases/load-patient-hide-contact/remote-load-patient-hide-contact'
import { RemoteLoadPatientPossibleMotherName } from 'service/usecases/load-patient-possible-mother-name/remote-load-patient-possible-mother-name'
import { RemoteValidatePatientMotherAndBirthday } from 'service/usecases/validate-patient-mother-and-birthday/remote-validate-patient-mother-and-birthday'
import { RemoteValidateRegisteredPatientInDb } from 'service/usecases/validate-registered-patient-in-db/remote-validate-registered-patient-in-db'
import { RemoteInvitePatientFromMv } from 'service/usecases/invite-patient-from-mv/invite-patient-from-mv'
import { RemoteLoadPatientPendencies } from 'service/usecases/load-patient-pendencies/remote-load-patient-pendencies'
import { RemoteLoadPatientSchedules } from 'service/usecases/load-patient-schedules/remote-load-patient-schedules'
import { RemoteInviteNewBornPatient } from 'service/usecases/invite-newborn-patient/remote-invite-newborn-patient'
import { RemoteGetPatientNextAttendances } from 'service/usecases/get-patient-next-attendances/remote-get-patient-next-attendances'
import { RemoteUpdatePatientById } from 'service/usecases/update-patient-by-id/remote-update-patient-by-id'
import { RemoteCheckPatientByEmail } from 'service/usecases/check-patient-by-email/check-patient-by-email'
import { RemoteCheckPatientByPhone } from 'service/usecases/check-patient-by-phone/check-patient-by-phone'
import { RemoteCheckPatientByCpf } from 'service/usecases/check-patient-by-cpf/check-patient-by-cpf'
import { RemoteUpdatePatientInfo } from 'service/usecases/update-patient-info/remote-update-patient-info'
import { RemoteLoadPatientSurgicalOrders } from 'service/usecases/load-patient-surgical-orders/remote-load-patient-surgical-orders'
import { RemoteLoadPatientExamDocuments } from 'service/usecases/load-patient-exam-documents/remote-load-patient-documents'
import { RemoteDeletePatientExamDocument } from 'service/usecases/delete-patient-exam-document/remote-delete-patient-document'
import { RemoteLoadExamResult } from 'service/usecases/load-exam-result/remote-load-exam-result'
import { RemoteListExamResult } from 'service/usecases/list-exam-result/list-exam-result'
import { RemoteGetPatientPendency } from 'service/usecases/get-patient-pendency/get-patient-pendency'
import { RemoteRequestSurgicalOrder } from 'service/usecases/request-surgical-order/request-surgical-order'

export default function makePatientService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const patientRepository = new PatientRepository(apiRepository)

  const invitePatient = new RemoteInvitePatient(patientRepository)
  const loadPatientByCpf = new RemoteLoadPatientByCpf(patientRepository)
  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const searchPatient = new RemoteSearchPatient(patientRepository)
  const loadPatientDocuments = new RemoteLoadPatientDocuments(patientRepository)
  const loadPatientExamDocuments = new RemoteLoadPatientExamDocuments(
    patientRepository
  )
  const loadPatientHideContact = new RemoteLoadPatientHideContact(
    patientRepository
  )
  const downloadPatientDocument = new RemoteDownloadPatientDocument(
    patientRepository
  )
  const loadPatientPossibleMotherName = new RemoteLoadPatientPossibleMotherName(
    patientRepository
  )
  const validatePatientMotherAndBirthday =
    new RemoteValidatePatientMotherAndBirthday(patientRepository)

  const validateRegisteredPatientInDb = new RemoteValidateRegisteredPatientInDb(
    patientRepository
  )

  const checkPatientByEmail = new RemoteCheckPatientByEmail(patientRepository)

  const checkPatientByPhone = new RemoteCheckPatientByPhone(patientRepository)

  const checkPatientByCpf = new RemoteCheckPatientByCpf(patientRepository)

  const invitePatientFromMv = new RemoteInvitePatientFromMv(patientRepository)
  const loadPatientPendencies = new RemoteLoadPatientPendencies(
    patientRepository
  )
  const loadPatientSchedules = new RemoteLoadPatientSchedules(patientRepository)
  const inviteNewBornPatient = new RemoteInviteNewBornPatient(patientRepository)

  const getPatientNextAttendances = new RemoteGetPatientNextAttendances(
    patientRepository
  )

  const updatePatientInfo = new RemoteUpdatePatientInfo(patientRepository)
  const updatePatientById = new RemoteUpdatePatientById(patientRepository)

  const loadPatientSurgicalOrders = new RemoteLoadPatientSurgicalOrders(
    patientRepository
  )

  const deletePatientExamDocument = new RemoteDeletePatientExamDocument(
    patientRepository
  )

  const loadExamResult = new RemoteLoadExamResult(patientRepository)

  const listExamResult = new RemoteListExamResult(patientRepository)

  const getPatientPendency = new RemoteGetPatientPendency(patientRepository)

  const requestSurgicalOrder = new RemoteRequestSurgicalOrder(patientRepository)

  return new PatientService({
    invitePatient,
    invitePatientFromMv,
    loadPatientByCpf,
    searchPatient,
    loadPatientInfo,
    loadPatientDocuments,
    loadPatientExamDocuments,
    downloadPatientDocument,
    loadPatientHideContact,
    loadPatientPossibleMotherName,
    validatePatientMotherAndBirthday,
    validateRegisteredPatientInDb,
    loadPatientPendencies,
    loadPatientSchedules,
    inviteNewBornPatient,
    getPatientNextAttendances,
    updatePatientById,
    checkPatientByEmail,
    checkPatientByPhone,
    checkPatientByCpf,
    updatePatientInfo,
    loadPatientSurgicalOrders,
    deletePatientExamDocument,
    loadExamResult,
    listExamResult,
    getPatientPendency,
    requestSurgicalOrder
  })
}
