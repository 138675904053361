import React, { useState } from 'react'

import { Container } from 'presentation/components/Container'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import { newbornPackages } from 'presentation/utils/newborn-packages-informations'
import vaccineBabyFirstCard from 'presentation/assets/icons/vaccine-baby-first-card.png'
import vaccineBabySecondCard from 'presentation/assets/icons/vaccine-baby-second-card.png'
import vaccineBabyThirdCard from 'presentation/assets/icons/vaccine-baby-third-card.png'
import VaccinesPackageCard from 'presentation/components/vaccinesPackageCard'
import SupportTextNew from 'presentation/components/SupportTextNew'
import HeaderNew from 'presentation/components/HeaderNew'
import HeadingNew from 'presentation/components/HeadingNew'
import * as S from './styles'

export default function NewbornPackagesLayout() {
  const [carousel, setCarousel] = useState({} as CarouselState)

  return (
    <S.Wrapper>
      <HeaderNew actualPageTitle="Pacotes Recém-Nascido" />
      <Container noHeader style={{ overflow: 'visible' }}>
        <HeadingNew color="primary600" size="xlarge">
          Pacotes Recém-Nascido
        </HeadingNew>
        <SupportTextNew color="neutral900" size="xsmall">
          Condições comerciais disponíveis apenas para os hospitais da região
          metropolitana de Belo Horizonte.
        </SupportTextNew>

        <Carousel
          state={carousel}
          setState={setCarousel}
          slidesPerView={1}
          spaceBetween={30}
          dynamicHeight={true}
          style={{ alignItems: 'center', height: '100%' }}
          containerStyle={{ padding: 20, marginBottom: 150 }}
          bulletBottomPosition={'97%'}
          pagination={{
            clickable: true
          }}
          breakpoints={{
            880: {
              slidesPerView: 2
            },

            1220: {
              slidesPerView: 3
            }
          }}
        >
          <VaccinesPackageCard
            packageInformation={newbornPackages[0]}
            image={vaccineBabyFirstCard}
          />
          <VaccinesPackageCard
            packageInformation={newbornPackages[1]}
            image={vaccineBabySecondCard}
          />
          <VaccinesPackageCard
            packageInformation={newbornPackages[2]}
            image={vaccineBabyThirdCard}
          />
        </Carousel>
      </Container>
    </S.Wrapper>
  )
}
