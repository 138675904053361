import React from 'react'
import * as S from './styles'
import { useHistory, useLocation } from 'react-router'
import Header from 'presentation/components/Header'
import ActualPage from 'presentation/components/ActualPage'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import { Vaccine } from 'presentation/utils/newborn-packages-informations'
import Button from 'presentation/components/Button'
import { openPhoneApp } from 'presentation/utils/mobile-actions'
import { ReactComponent as ShoppingCart } from 'presentation/assets/icons/outlined-shopping-cart.svg'

export default function VaccineDetailsLayout() {
  const history = useHistory()

  const handleRedirect = () =>
    openPhoneApp(
      'whatsApp',
      '5531971507542',
      'Olá! Gostaria de saber mais sobre os pacotes de vacinas do Mater Dei Vacinas'
    )

  const { state } = useLocation<{
    vaccineInformation: Vaccine
  }>()

  const { vaccineInformation } = state

  return (
    <S.Wrapper>
      <Header />
      <Container>
        <ActualPage text="Voltar" onClick={() => history.goBack()} />
        <S.ContentContainer>
          <Heading color={'primary'}>{vaccineInformation.vaccineName}</Heading>

          <S.TextContainer>
            <Heading color={'primary'}>Esquema de Doses</Heading>
            <p>{vaccineInformation.dosage}</p>
          </S.TextContainer>

          <S.TextContainer>
            <Heading color={'primary'}>Indicações</Heading>
            <p>{vaccineInformation.indications}</p>
          </S.TextContainer>

          <S.TextContainer>
            <Heading color={'primary'}>Contra-Indicação</Heading>
            <p>{vaccineInformation.contraIndications}</p>
          </S.TextContainer>

          <S.TextContainer>
            <Heading color={'primary'}>Reações Adversas</Heading>
            <p>{vaccineInformation.adverseReactions}</p>
          </S.TextContainer>

          <S.TextContainer>
            <Heading color={'primary'}>O que previne</Heading>
            <p>{vaccineInformation.prevention}</p>
          </S.TextContainer>
        </S.ContentContainer>

        <Button fullWidth={true} onClick={handleRedirect}>
          <ShoppingCart style={{ marginRight: 5 }} role={'get-packages'} />
          Contratar pacote
        </Button>
      </Container>
    </S.Wrapper>
  )
}
