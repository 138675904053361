import { LoadEmergencyRoomByHospital } from 'domain/usecases/emergency-room/load-emergency-room-by-hospital'
import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'

export class RemoteLoadEmergencyRoomByHospital
  implements LoadEmergencyRoomByHospital
{
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  load(
    fields: LoadEmergencyRoomByHospital.Params
  ): Promise<LoadEmergencyRoomByHospital.Model> {
    return this.emergencyRoomRepository.loadEmergencyRoomByHospital(fields)
  }
}
