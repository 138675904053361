import styled, { css } from 'styled-components'

type WrapperProps = {
  isChecked: boolean
  disabled?: boolean
}

export const Input = styled.input`
  ${({ theme }) => css`
    opacity: 0;
    width: 0;
    &:checked {
      & + ${InputLabel} {
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary};
      }
    }
  `}
`

export const InputLabel = styled.label<WrapperProps>`
  ${({ theme, disabled }) => css`
    padding: 8px 14px;
    border-radius: 34px;
    border: 1px solid ${theme.colors.primary};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.normal};
    color: ${theme.colors.gray};
    background-color: ${!disabled
      ? theme.colors.white
      : theme.colors.lightestGray};
    cursor: pointer;
    z-index: 2;
  `}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.primary};
    transform: translate(-50%, -50%);
    border-radius: 34px;
    transition-property: height, width;
    transition: 200ms;
    opacity: 0;
  }

  :focus-within {
    ::before {
      width: 120%;
      height: 120%;
      opacity: 0.3;
    }
  }

  label:not(:last-child) {
    margin-right: 12px;
  }
`
