import { lighten } from 'polished'
import styled, { css, DefaultTheme } from 'styled-components'

type DocInputProps = {
  file: boolean
  disabled: boolean
  notShowFileName?: boolean
}

export const DocInput = styled.div<DocInputProps>`
  ${({ theme, file, disabled, notShowFileName }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    color: white;
    width: 100%;
    min-height: 120px;
    background-color: ${theme.colors.white};
    border: 1px dashed ${theme.colors.secondary};
    border-radius: ${theme.border.radius.small};
    input {
      display: none;
    }
    & + & {
      margin-top: 8px;
    }
    span {
      margin-top: 8px;
    }
    ${!!file && wrapperModifiers.fileSelected(theme)}
    ${!!disabled && wrapperModifiers.disabled(theme)}
    ${!!notShowFileName && wrapperModifiers.notShowFileName(theme)}
  `}
`

export const Label = styled.label`
  position: absolute;
  height: 100%;
  display: block;
  text-align: center;
  top: 0;
  left: 0;
  right: 0;
  color: #cdced9;
`

type SubtitleProps = {
  fileExt: string
}

export const Subtitle = styled.span<SubtitleProps>`
  ${({ theme, fileExt }) => css`
    font-weight: 500;
    display: flex;
    margin-top: 0 !important;
    flex-direction: column;
    text-align: center;
    align-items: center;
    flex-grow: 0;
    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xsmall};
    font-weight: ${theme.font.semiBold};

    small {
      color: ${fileExt === 'pdf' ? theme.colors.lightRed : theme.colors.blue};
    }

    img {
      margin-bottom: 4px;
    }
  `}
`

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 30px;
  width: fit-content;
  height: 38px;
  padding: 0px 3px;
  background: #f0f6ef;
  border: 0.5px solid #c4c4c4;
  border-radius: 3px;
  margin-bottom: 8px;
`

const wrapperModifiers = {
  fileSelected: (theme: DefaultTheme) => css`
    border: 1px solid ${theme.colors.secondary};
  `,
  disabled: (theme: DefaultTheme) => css`
    cursor: not-allowed;
    color: ${theme.colors.gray};
    &::placeholder {
      color: currentColor;
    }
    background-color: ${lighten(0.3, theme.colors.lightGray)};
  `,
  notShowFileName: (theme: DefaultTheme) => css`
    border: 2px dashed ${theme.colors.secondary};
    background-color: rgba(230, 230, 230, 0.6);

    span {
      color: ${theme.colors.secondary};
      font-size: ${theme.font.sizes.xxsmall};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 4px;

      img {
        width: 18px;
        margin-left: 8px;
      }
    }
  `
}
