import { LoadHelpPendencyHistory } from 'domain/usecases/surgical-order/load-help-pendency-history'
import { SurgicalPendencyRepository } from 'repository/repositories/surgical-pendency/surgical-pendency-repository'

export class RemoteLoadHelpPendencyHistory implements LoadHelpPendencyHistory {
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  load(
    params: LoadHelpPendencyHistory.Params
  ): Promise<LoadHelpPendencyHistory.Model> {
    return this.surgicalPendencyRepository.loadHelpPendencyHistory(params)
  }
}
