import React, { useEffect, useContext } from 'react'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import SurgicalOrderDetailsLayout from 'presentation/layouts/SurgicalOrderDetails'
import HeaderNew from 'presentation/components/HeaderNew'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderContext } from 'main/factories/pages/surgical-order/surgical-order-factory'
import BottomMenu from 'presentation/components/BottomMenu'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { PendencyTypes } from 'presentation/components/PendencyCard'

const SurgicalOrderDetails = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const surgicalOrderService = useServices().surgicalOrder
    const surgicalPendencyService = useServices().surgicalPendency
    const { dispatch } = useContext<any>(SurgicalOrderContext)
    const { state } = useLocation<{ surgical_order_id: number }>()

    const getSurgicalOrder = async () => {
      try {
        const response: any =
          await surgicalOrderService.loadSurgicalOrderNoticeCard({
            surgical_order_id: state.surgical_order_id
          })

        dispatch({
          type: 'SURGICAL_ORDER',
          payload: response.data
        })
      } catch {
        toast.error('Ocorreu um erro ao carregar o pedido cirúrgico')
      }
    }

    const getSurgicalOrderTimeline = async () => {
      try {
        const response = await surgicalOrderService.getSurgicalOrderTimeline({
          surgical_order_id: state.surgical_order_id
        })

        dispatch({
          type: 'TIMELINE_TAB',
          payload: response.data.reverse()
        })
      } catch {
        toast.error('Ocorreu um erro ao carregar os documentos')
      }
    }

    const getPendenciesList = async () => {
      try {
        const response: any =
          await surgicalPendencyService.loadSurgicalOrderPendencies(
            state.surgical_order_id
          )

        dispatch({
          type: 'SURGICAL_PENDENCIES',
          payload: response.data
        })
      } catch {
        toast.error('Ocorreu um erro ao carregar as pendencias')
      }
    }

    const getPendencyHistory = async (
      surgical_pendency_id: number,
      pendency_type?: PendencyTypes
    ) => {
      setIsLoading(true)

      if (pendency_type === PendencyTypes.HELP_CARD) {
        try {
          const response: any =
            await surgicalPendencyService.loadHelpPendencyHistory(
              surgical_pendency_id
            )

          dispatch({
            type: 'CURRENT_SURGICAL_PENDENCY',
            payload: response.data
          })
        } catch {
          toast.error('Ocorreu um erro ao carregar a pendência')
        } finally {
          setIsLoading(false)
        }
      } else
        try {
          const response: any =
            await surgicalPendencyService.loadSurgicalOrderPendenciesHistory(
              surgical_pendency_id
            )

          dispatch({
            type: 'CURRENT_SURGICAL_PENDENCY',
            payload: response.data
          })
        } catch {
          toast.error('Ocorreu um erro ao carregar a pendência')
        } finally {
          setIsLoading(false)
        }
    }

    useEffect(() => {
      if (!state?.surgical_order_id) return history.push('/home')
      setIsLoading(true)
      Promise.all([getSurgicalOrder(), getPendenciesList()]).finally(() =>
        setIsLoading(false)
      )
    }, [])

    if (state?.surgical_order_id) {
      return (
        <>
          <HeaderNew actualPageTitle="Pedido cirúrgico" />
          <SurgicalOrderDetailsLayout
            // surgical_order_id={state?.surgical_order_id}
            getPendencyHistory={getPendencyHistory}
            getSurgicalOrder={getSurgicalOrder}
            getSurgicalOrderTimeline={getSurgicalOrderTimeline}
            setIsLoading={setIsLoading}
          />
          <BottomMenu />
        </>
      )
    } else {
      return <></>
    }
  }
)

export default SurgicalOrderDetails
