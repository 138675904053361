import { RequestSurgicalOrderCancellation } from 'domain/usecases/surgical-order/request-surgical-order-cancellation'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteRequestSurgicalOrderCancellation
  implements RequestSurgicalOrderCancellation
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  post(
    params: RequestSurgicalOrderCancellation.Params
  ): Promise<RequestSurgicalOrderCancellation.Model> {
    return this.surgicalOrderRepository.requestSurgicalOrderCancellation(params)
  }
}
