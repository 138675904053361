import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
import { NavigationProps } from '../utils'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'
import { ContainerNew } from 'presentation/components/ContainerNew'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import { useFormik } from 'formik'
import AutoCompleteNew from 'presentation/components/AutoCompleteNew'
import { SchedulingDoctor } from 'domain/entities/scheduling-doctor-model'
import { useServices } from 'presentation/hooks/use-services'
import ButtonNew from 'presentation/components/ButtonNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import CalendarForm from 'presentation/components/Forms/Calendar'
import { ExamAvailableOfferDetails } from 'domain/usecases/exam/get-exam-available-dates'
import { toast } from 'react-toastify'
import moment from 'moment'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import TextFieldNew from 'presentation/components/TextFieldNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import {
  ExamGroup,
  ExamModelNew,
  ExamSearchType
} from 'domain/entities/exam-model-new'
import { AnesthesiaOptions } from 'common/enum/anesthesia-options'
import { ReactComponent as NoAgenda } from 'presentation/assets/empty-states/no-agenda.svg'
import { generateHash } from 'common/utils/generateHash'
import { useHistory } from 'react-router'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgeryType } from 'common/enum/surgery-type'

export type SchedulingUnitType = {
  name?: string
  id: number
  friendly_name: string
  units: {
    name: string
    unit_id: number
  }[]
}

export type SurgicalProcedureDoctor = {
  doctor_id: number
  doctor_name: string
  hospital_ids?: number[]
}

type Props = {
  showCalendarScreen: boolean
  setShowCalendarScreen: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  isLoading?: boolean
} & NavigationProps

const SearchDoctorAndUnity = ({
  setIsLoading,
  next,
  activeIndex,
  showCalendarScreen,
  setShowCalendarScreen,
  slideTo
}: Props) => {
  const { state } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  const [examType, setExamType] = useState<ExamSearchType>(ExamSearchType.EXAM)
  const examService = useServices().exam
  const hospitalService = useServices().hospital
  const [examName, setExamName] = useState<string>('')
  const [examAvailableDates, setExamAvailableDates] = useState() as any
  const [scheduleByResource, setScheduleByResource] = useState(false)
  const [examGroupsOrdered, setExamGroupsOrdered] = useState<ExamGroup[]>([])
  const [currentSchedulingGroup, setCurrentSchedulingGroup] = useState<
    string | null
  >()

  const [availableUnits, setAvailableUnits] = useState([]) as any

  const [showEmptyStateScreen, setShowEmptyStateScreen] = useState(false)
  const [surgicalProcedureDoctors, setSurgicalProcedureDoctors] =
    useState<SurgicalProcedureDoctor[]>()
  const [selectedSurgicalProcedureDoctor, setSelectedSurgicalProcedureDoctor] =
    useState<SurgicalProcedureDoctor | undefined>()
  const [
    allAvailableUnitsByHealthInsurance,
    setAllAvailableUnitsByHealthInsurance
  ] = useState<Partial<Hospital>[]>()
  const [
    availableUnitsBySurgicalProcedureDoctor,
    setAvailableUnitsBySurgicalProcedureDoctor
  ] = useState<Partial<Hospital>[]>()
  const [selectedSurgicalProcedureUnit, setSelectedSurgicalProcedureUnit] =
    useState<Partial<Hospital>>()

  const handleSetExamName = (exams: ExamModelNew[]) => {
    const examName = exams
      ?.map((exam) => {
        return exam.name
          .charAt(0)
          .toUpperCase()
          .concat(exam.name.slice(1).toLowerCase())
      })
      .toString()
      .replaceAll(',', ' + ')

    setExamName(examName)
  }

  const verifyHasNextSchedule = async () => {
    if (state?.exams?.length === 0 && state?.examsScheduled?.length >= 1) {
      await dispatch({
        type: 'SET_HAS_NEXT_SCHEDULE',
        payload: false
      })

      slideTo && slideTo(7)
      return
    }
  }

  const verifyExamListAndScheduled = () => {
    if (state.exams?.length === 0 && !state.examsScheduled?.length) {
      slideTo && slideTo(4)
      return
    }
  }

  const loadSelectedExamsOrdered = async () => {
    verifyHasNextSchedule()
    verifyExamListAndScheduled()

    setCurrentSchedulingGroup(null)

    setIsLoading(true)
    try {
      const filteredExamsAllowed = state.exams
        .filter((exam: ExamModelNew) => exam.allow_schedule)
        .flat()

      const responseExamsOrdered = await examService.getExamGroupOrdered({
        exams: filteredExamsAllowed
      })

      const groupsOrdered = responseExamsOrdered?.groups?.map(
        (group: ExamGroup) => {
          return {
            ...group,
            groupIndex: generateHash(),
            scheduled: false
          }
        }
      )

      await checkDoctorsOrResource(groupsOrdered)

      // Salva grupos ordenados em um useState para ser usado no calendário (pode melhorar isso no futuro)
      setExamGroupsOrdered(groupsOrdered)

      // Salva retorno do backend num array de exames (ordenados e com as regras aplicadas) no context
      dispatch({
        type: 'UPDATE_SELECTED_EXAMS_ORDERED',
        payload: groupsOrdered
      })

      await updateInitialUnitsOptions(groupsOrdered)

      const filteredIsMandatoryPreExam = state.exams
        .filter((exam: ExamModelNew) => exam.is_mandatory_pre_exam)
        .flat()

      // Salva lista de exames que necessitam de consulta prévia no context
      dispatch({
        type: 'UPDATE_SELECTED_CONSULT_PRE_EXAMS',
        payload: filteredIsMandatoryPreExam
      })
    } catch (error: any) {
      toast.error('Ocorreu um erro ao agrupar exames')
      // slideTo && slideTo(4)
    } finally {
      setIsLoading(false)
    }
  }

  const loadSelectedSurgicalProceduresOrdered = async () => {
    verifyHasNextSchedule()
    verifyExamListAndScheduled()
    setCurrentSchedulingGroup(null)

    setIsLoading(true)
    try {
      const filteredSurgicalProcedures = state.exams
        .filter((exam: ExamModelNew) => exam.type === ExamSearchType.SURGERY)
        .flat()

      const surgicalProceduresFormatted = filteredSurgicalProcedures?.map(
        (exam: ExamModelNew) => {
          return {
            ...exam,
            surgicalProcedureIndex: generateHash(),
            surgicalProcedureSolicited: false
          }
        }
      )

      // Salva lista de procedimentos cirúrgicos que necessitam de autorização no context:
      dispatch({
        type: 'UPDATE_SELECTED_SURGICAL_PROCEDURES',
        payload: surgicalProceduresFormatted
      })

      const filteredIsMandatoryPreExam = state.exams
        .filter((exam: ExamModelNew) => exam.surgery_type === SurgeryType.BIO)
        .flat()

      // Salva lista de exames que necessitam de consulta prévia no context
      dispatch({
        type: 'UPDATE_SELECTED_CONSULT_PRE_EXAMS',
        payload: filteredIsMandatoryPreExam
      })

      handleSetExamName(filteredSurgicalProcedures)

      // Carrega unidades a partir do convênio selecionado
      const responseHospitalsByHealthInsurance =
        await hospitalService.getHospitalsByHealthInsurance({
          health_insurance_code: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        })
      setAllAvailableUnitsByHealthInsurance(responseHospitalsByHealthInsurance)
    } catch (error: any) {
      toast.error(
        'Ocorreu um erro ao carregar a lista de unidades. Tente novamente mais tarde.'
      )
      // slideTo && slideTo(4)
    } finally {
      setIsLoading(false)
    }
  }

  const checkDoctorsOrResource = async (groupsOrdered?: ExamGroup[]) => {
    dispatch({
      type: 'SET_RECOMMENDED_DOCTOR',
      payload: undefined
    })

    const examGroupsOrdered = groupsOrdered?.length
      ? groupsOrdered
      : state.examsOrdered

    if (examGroupsOrdered?.length) {
      // Filtra o próximo grupo não agendado
      const filteredGroupsNotScheduled = examGroupsOrdered.filter(
        (examGroup: ExamGroup) => !examGroup.scheduled
      )?.[0]

      setCurrentSchedulingGroup(filteredGroupsNotScheduled.groupIndex)

      // Filtra exames que podem ser agendados
      const filteredExamsOrderedAllowed =
        filteredGroupsNotScheduled.exams?.filter(
          (exam: ExamModelNew) => exam.allow_schedule
        )

      const payloadCheckDoctorsOrResource = filteredExamsOrderedAllowed?.map(
        (exam: ExamModelNew) => {
          return {
            item_ids: exam.item_ids,
            unification_id: exam.unification_id
          }
        }
      )

      const responseDoctorsOrResources =
        await examService.checkResourceSchedule({
          exams: payloadCheckDoctorsOrResource
        })

      handleSetExamName(filteredExamsOrderedAllowed)

      if (responseDoctorsOrResources?.length === 0) {
        setScheduleByResource(true)
      } else {
        setScheduleByResource(false)

        dispatch({
          type: 'SET_RECOMMENDED_DOCTOR',
          payload: undefined
        })
      }
    }
  }

  const updateInitialUnitsOptions = async (groupsOrdered?: ExamGroup[]) => {
    setIsLoading(true)
    const examGroupsOrdered = groupsOrdered?.length
      ? groupsOrdered
      : state.examsOrdered

    if (examGroupsOrdered?.length) {
      const filteredExamsOrderedAllowed = examGroupsOrdered
        .filter((examGroup: ExamGroup) => !examGroup.scheduled)?.[0] // Filtra o próximo grupo não agendado
        .exams?.filter((exam: ExamModelNew) => exam.allow_schedule) // Filtra exames que podem ser agendados
        .map((exam: ExamModelNew) => {
          return {
            item_ids: exam.item_ids,
            exam_ids: exam.exam_ids,
            unification_id: exam.unification_id
          }
        })

      const response = await examService.getExamAvailableHospitals({
        health_insurance: {
          health_insurance_id: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        },
        doctor_id: undefined,
        exams: filteredExamsOrderedAllowed
      })

      if (response?.length === 0) {
        const updatedSelectedExams = state.exams.filter(
          (exam: ExamModelNew) => {
            return (
              exam.unification_id !==
              filteredExamsOrderedAllowed[0].unification_id
            )
          }
        )
        // Atualiza lista de exames selecionados pelo paciente (remove os exames agendados)
        await dispatch({
          type: 'UPDATE_SELECTED_EXAMS',
          payload: updatedSelectedExams
        })

        setShowEmptyStateScreen(true)
        setIsLoading(false)
        return
      }
      if (response?.length) {
        setAvailableUnits(response)
      }
      setIsLoading(false)
    }
  }

  async function loadExamAvailableUnits(doctor_id?: number) {
    setIsLoading(true)
    try {
      const filteredExamsOrderedAllowed = state.examsOrdered
        .filter((examGroup: ExamGroup) => !examGroup.scheduled)?.[0] // Filtra o próximo grupo não agendado
        .exams?.filter((exam: ExamModelNew) => exam.allow_schedule) // Filtra exames que podem ser agendados
        .map((exam: ExamModelNew) => {
          return {
            item_ids: exam.item_ids,
            exam_ids: exam.exam_ids,
            unification_id: exam.unification_id
          }
        })
        .flat()

      const response = await examService.getExamAvailableHospitals({
        health_insurance: {
          health_insurance_id: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        },
        doctor_id: doctor_id || undefined,
        exams: filteredExamsOrderedAllowed
      })

      if (response?.length) {
        setAvailableUnits(response)
      }
    } catch (error: any) {
      toast.error('Ocorreu um erro ao buscar unidades disponíveis')
    } finally {
      setIsLoading(false)
    }
  }

  function loadAvailableUnitsByDoctor(doctor?: SurgicalProcedureDoctor) {
    const filteredUnitsByDoctor = allAvailableUnitsByHealthInsurance?.filter(
      (unit: Partial<Hospital>) =>
        doctor?.hospital_ids && doctor.hospital_ids.includes(unit.hospital_id!)
    )

    setAvailableUnitsBySurgicalProcedureDoctor(filteredUnitsByDoctor)
  }

  const verifySelectedExams = async () => {
    const filteredExamsAllowed = state.exams
      .filter(
        (exam: ExamModelNew) =>
          exam.type !== ExamSearchType.SURGERY && exam.allow_schedule
      )
      .flat()

    const filteredSurgicalProcedures = state.exams
      .filter((exam: ExamModelNew) => exam.type === ExamSearchType.SURGERY)
      .flat()

    if (filteredExamsAllowed?.length) {
      setExamType(ExamSearchType.EXAM)
      await loadSelectedExamsOrdered()
    } else if (filteredSurgicalProcedures?.length) {
      setExamType(ExamSearchType.SURGERY)
      await loadSelectedSurgicalProceduresOrdered()
    }
  }

  useEffect(() => {
    setShowEmptyStateScreen(false)
    setExamName('')
    if (state.is_reschedule) {
      updateInitialUnitsOptions()
    }

    if (state.has_next_schedule) {
      formik.resetForm()
      setExamAvailableDates([])
      setAvailableUnits([])
      resetSelectedDoctor()
    }

    if (activeIndex && activeIndex === 4) {
      formik.resetForm()
      setExamAvailableDates([])
      setAvailableUnits([])
      setAvailableUnitsBySurgicalProcedureDoctor([])
      resetSelectedDoctor()
      resetSelectedSurgicalProcedureDoctor()
    }

    if (activeIndex === 5 || showEmptyStateScreen === true) {
      verifySelectedExams()

      setCurrentPage(1)

      let sedationOption

      if (state?.exams[0]?.anesthesia_option === AnesthesiaOptions.REQUIRED) {
        sedationOption = true

        dispatch({
          type: 'SET_USE_SEDATION',
          payload: sedationOption
        })
      } else if (
        state?.exams[0]?.anesthesia_option ===
        AnesthesiaOptions.WITHOUT_ANESTHESIA
      ) {
        sedationOption = false

        dispatch({
          type: 'SET_USE_SEDATION',
          payload: sedationOption
        })
      }
    }
  }, [activeIndex])

  const formik = useFormik({
    initialValues: {
      doctor: {
        name: '',
        doctor_id: ''
      },
      units: [],
      recommended_doctor: undefined
    },
    onSubmit: async () => {
      try {
        setIsLoading(true)
        if (
          !!state?.exams?.length &&
          state?.exams[0]?.anesthesia_option === AnesthesiaOptions.OPTIONAL
        ) {
          if (state.use_sedation === undefined) {
            return toast.error('Verifique a seleção de opção de sedação')
          }
        }

        if (scheduleByResource) {
          if (formik.values.recommended_doctor) {
            dispatch({
              type: 'SET_RECOMMENDED_DOCTOR',
              payload: formik.values.recommended_doctor
            })
          } else {
            dispatch({
              type: 'SET_RECOMMENDED_DOCTOR',
              payload: undefined
            })
          }
        }

        const unitsId = formik.values.units

        const filteredExamsOrderedAllowed = state.examsOrdered
          .filter((examGroup: ExamGroup) => !examGroup.scheduled)?.[0] // Filtra o próximo grupo não agendado
          .exams?.filter((exam: ExamModelNew) => exam.allow_schedule) // Filtra exames que podem ser agendados
          .map((exam: ExamModelNew) => {
            return {
              item_ids: exam.item_ids,
              unification_id: exam.unification_id,
              name: exam.name
            }
          })

        const response = await examService.getExamAvailableDates({
          patient: {
            age: state.age,
            patient_id: state.patient_id
          },
          exams: filteredExamsOrderedAllowed,
          doctor_id: +formik?.values.doctor.doctor_id || null,

          health_insurance: {
            health_insurance_id: state.healthInsuranceCode,
            plan_code: state.healthPlanCode
          },
          unit_ids: unitsId,
          has_anesthesia: scheduleByResource ? state.use_sedation : false
        })

        if (response?.length === 0) {
          toast.error(
            'Nenhuma oferta foi encontrada com os filtros selecionados'
          )
          setExamAvailableDates([])

          const updatedSelectedExams = state.exams.filter(
            (exam: ExamModelNew) => {
              return (
                exam.unification_id !==
                filteredExamsOrderedAllowed[0].unification_id
              )
            }
          )
          // Atualiza lista de exames selecionados pelo paciente (remove os exames agendados)
          await dispatch({
            type: 'UPDATE_SELECTED_EXAMS',
            payload: updatedSelectedExams
          })

          setShowEmptyStateScreen(true)
        }
        setCurrentPage(1)
        setExamAvailableDates(response)
      } catch (error: any) {
        toast.error('Falha ao buscar disponibilidade de horários')
        setExamAvailableDates([])
      } finally {
        setIsLoading(false)
      }
    }
  })

  const onCheckUnitsOptions = (unit: SchedulingUnitType) => {
    selectAllUnits(false)

    const unitsFromSelectedHospital = unit.units.map((unit: any) => {
      return unit.unit_id
    })

    const unitsAlreadySelected = formik.values?.units?.some((num) =>
      unitsFromSelectedHospital.includes(num)
    )

    if (!unitsAlreadySelected) {
      formik.setFieldValue('units', [
        ...formik.values?.units,
        ...unitsFromSelectedHospital
      ])
    } else {
      unitsFromSelectedHospital.forEach((unitFromSelectedHospital: number) => {
        formik.setFieldValue(
          'units',
          formik.values?.units?.filter((op) => op !== unitFromSelectedHospital)
        )
      })
    }
  }

  const isCheckedUnitsOptions = (units_id: number[]) => {
    return formik.values?.units?.some((num) => units_id.includes(num))
  }

  const [doctorQuery, setDoctorQuery] = useState<string>()
  const [selectedDoctor, setSelectedDoctor] = useState<SchedulingDoctor | any>()
  const [surgicalProcedureDoctorQuery, setSurgicalProcedureDoctorQuery] =
    useState<string>()

  const [doctorsSearchResult, setDoctorsSearchResult] = useState<
    SchedulingDoctor[] | undefined
  >([]) as any

  const resetSelectedDoctor = async () => {
    await formik.setFieldValue('doctor', '')
    setSelectedDoctor('')
    setDoctorQuery('')

    updateInitialUnitsOptions()
    // setAvailableUnits([])
    selectAllUnits(false)
    setExamAvailableDates([])
    setDoctorsSearchResult([])
  }

  const resetSelectedSurgicalProcedureDoctor = async () => {
    // ToDo 1: Verificar necessidade de manipular campo no formik para médicos de procedimentos cirúrgucos
    // await formik.setFieldValue('doctor', '')
    // ToDo 2: Verificar se é necessário chamar essa função
    // verifySelectedExams()
    setSelectedSurgicalProcedureDoctor(undefined)
    setSurgicalProcedureDoctorQuery('')
    setSurgicalProcedureDoctors([])
    setAvailableUnitsBySurgicalProcedureDoctor([])
  }

  const searchDoctors = async (input: string) => {
    if (input && state.examsOrdered?.length) {
      const filteredExamsOrderedAllowed = state.examsOrdered
        .filter((examGroup: ExamGroup) => !examGroup.scheduled)?.[0] // Filtra o próximo grupo não agendado
        .exams?.filter(
          (exam: ExamModelNew) => exam.allow_schedule // Filtra exames que podem ser agendados
        )
        .map((exam: ExamModelNew) => {
          return {
            item_ids: exam.item_ids,
            unification_id: exam.unification_id
          }
        })

      if (filteredExamsOrderedAllowed?.length) {
        const res = await examService.searchExamDoctors({
          search: input,
          exams: filteredExamsOrderedAllowed,
          hospitals_id: allAvailableUnitsIds
        })
        setDoctorsSearchResult(res)
      }
    }
  }

  const searchSurgicalProcedureDoctor = (input: string) => {
    setAvailableUnitsBySurgicalProcedureDoctor([])
    const searchResult = state.surgicalProcedures[0].doctors?.filter((doctor) =>
      doctor.doctor_name
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
        .includes(
          input
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.toLowerCase()
        )
    )

    setSurgicalProcedureDoctors(searchResult)
  }

  const selectDoctor = async (doctor: SchedulingDoctor) => {
    if (doctor) {
      setDoctorQuery(doctor.name)
      setSelectedDoctor(doctor)
      loadExamAvailableUnits(doctor.doctor_id)
      selectAllUnits(false)
      setExamAvailableDates([])
      await formik.setFieldValue('doctor', doctor)
    } else {
      resetSelectedDoctor()
    }
  }

  const selectSurgicalProcedureDoctor = async (
    doctor: SurgicalProcedureDoctor
  ) => {
    if (doctor) {
      setSurgicalProcedureDoctorQuery(doctor.doctor_name)
      setSelectedSurgicalProcedureDoctor(doctor)
      loadAvailableUnitsByDoctor(doctor)
    } else {
      resetSelectedSurgicalProcedureDoctor()
    }
  }

  function handleSurgicalProcedureSolicitation() {
    const currentSurgicalProcedure = state.surgicalProcedures[0]

    const documents = [
      state.health_card_document_id ?? [],
      state.medical_order_document_ids
    ]

    const selectedSurgicalProcedure = {
      exam_item_title: examName,
      health_insurance: {
        health_insurance_id: state.health_insurance_id,
        health_insurance_name: state.healthInsuranceName,
        health_insurance_code: state.healthInsuranceCode,
        health_card: state.healthCard,
        health_plan_code: state.healthPlanCode,
        health_plan_name: state.healthPlanName,
        valid_thru: state.validThru
      },
      doctor: selectedSurgicalProcedureDoctor,
      hospital: selectedSurgicalProcedureUnit,
      surgery_type: currentSurgicalProcedure?.surgery_type,
      surgicalProcedureIndex: currentSurgicalProcedure.surgicalProcedureIndex,
      documents: documents.flat()
    }

    dispatch({
      type: 'SELECT_SURGICAL_PROCEDURE',
      payload: selectedSurgicalProcedure
    })

    setIsLoading(false)
    next()
  }

  const [selectedExamOption, setSelectedExamOption] = useState() as any
  const [isSelectedAllUnits, setIsSelectedAllUnits] = useState(false)

  function handleOpenCalendar(examOptionDetails: any) {
    setShowCalendarScreen(true)
    setSelectedExamOption(examOptionDetails)
  }

  function selectAllUnits(value: boolean) {
    setIsSelectedAllUnits(value)

    const allAvailableUnits = availableUnits
      ?.map((hospital: SchedulingUnitType) => {
        return hospital.units
      })
      .flat()
      .map((unit: any) => unit.unit_id)

    if (value) {
      formik.setFieldValue('units', allAvailableUnits)
    } else {
      formik.setFieldValue('units', [])
    }
  }

  const { dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  function handleChangeSedation(value: boolean) {
    setExamAvailableDates([])
    let sedationOption
    if (state?.exams[0]?.anesthesia_option === AnesthesiaOptions.OPTIONAL) {
      sedationOption = value
    } else if (
      state?.exams[0]?.anesthesia_option === AnesthesiaOptions.REQUIRED
    ) {
      sedationOption = true
    } else if (
      state?.exams[0]?.anesthesia_option ===
      AnesthesiaOptions.WITHOUT_ANESTHESIA
    ) {
      sedationOption = false
    }

    dispatch({
      type: 'SET_USE_SEDATION',
      payload: sedationOption
    })
  }

  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)
  const endIndex = currentPage * itemsPerPage
  const pageItems = examAvailableDates?.slice(0, endIndex)

  const handleClickNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const allAvailableUnitsIds = availableUnits?.map((unit: any) => {
    return unit.id
  })

  if (showCalendarScreen) {
    return (
      <>
        <CalendarScreen
          selectedExamOption={selectedExamOption}
          setIsLoading={setIsLoading}
          next={next}
          prev={() => setShowCalendarScreen(false)}
          scheduleByResource={scheduleByResource}
          examGroupsOrdered={examGroupsOrdered}
          currentSchedulingGroup={currentSchedulingGroup}
          // doctorsSearchResult={doctorsSearchResult}
        />
      </>
    )
  }

  if (showEmptyStateScreen) {
    return (
      <>
        <EmptyStateScreen
          loadSelectedExamsOrdered={loadSelectedExamsOrdered}
          setShowEmptyStateScreen={setShowEmptyStateScreen}
          slideTo={slideTo}
          examName={examName}
          activeIndex={activeIndex}
        />
      </>
    )
  } else
    return (
      <>
        <ContainerNew noHeader>
          <S.PurpleStrong>{examName && examName}</S.PurpleStrong>
          <S.TitleStrong>Escolher unidade e médico</S.TitleStrong>

          {examType === ExamSearchType.EXAM && (
            <>
              <SupportTextNew color="neutral700">
                Se desejar, escolha um médico e depois selecione a unidade.
              </SupportTextNew>
              {scheduleByResource ? (
                <>
                  <div style={{ marginTop: '10px' }}>
                    <TextFieldNew
                      label="Gostaria de indicar um médico?"
                      name="recommended_doctor"
                      placeholder="Digite o nome do médico"
                      value={formik.values.recommended_doctor}
                      onInputChange={formik.handleChange('recommended_doctor')}
                      maxLength={40}
                    />
                    <S.SubTitleGreen>
                      A indicação do médico está sujeita a disponibilidade do
                      mesmo.
                    </S.SubTitleGreen>
                  </div>
                </>
              ) : (
                <AutoCompleteNew
                  style={{ marginTop: '25px' }}
                  name="doctor"
                  placeholder="Digite o nome do médico"
                  label="Nome do médico"
                  labelWeight="semiBold"
                  labelSize="small"
                  value={doctorQuery || selectedDoctor?.name || ''}
                  onType={(input) => searchDoctors(input)}
                  onInputChange={setDoctorQuery}
                  suggestions={doctorsSearchResult?.map(
                    (doctor: SchedulingDoctor) => ({
                      label: doctor.name?.toLocaleLowerCase(),
                      value: doctor
                    })
                  )}
                  onSuggestionClick={(value) => selectDoctor(value)}
                  clear={() => resetSelectedDoctor()}
                  disabled={selectedDoctor}
                  data-testid="select-doctor-autocomplete"
                  debounceDelay={1000}
                />
              )}

              {!!availableUnits?.length && (
                <>
                  <S.SubTitleHeader style={{ marginTop: '24px' }}>
                    Unidade de atendimento *
                  </S.SubTitleHeader>
                  {availableUnits?.length > 1 && (
                    <CheckboxNew
                      // name="units"
                      labelFor={'Selecionar todas'}
                      label={'Selecionar todas'}
                      // value={'all'}
                      onCheck={() => selectAllUnits(!isSelectedAllUnits)}
                      checked={!!isSelectedAllUnits}
                    />
                  )}

                  {availableUnits?.map((unit: SchedulingUnitType) => {
                    return (
                      <CheckboxNew
                        key={unit.id}
                        name="units"
                        labelFor={`unidade-${unit.friendly_name}`}
                        label={unit.friendly_name}
                        value={unit.id}
                        onCheck={() => onCheckUnitsOptions(unit)}
                        checked={isCheckedUnitsOptions(
                          unit.units.map((unit: any) => {
                            return unit.unit_id
                          })
                        )}
                      />
                    )
                  })}
                </>
              )}

              {!!state?.exams?.length &&
                state?.exams[0]?.anesthesia_option ===
                  AnesthesiaOptions.REQUIRED && (
                  <S.AnesthesiaSubTitle>
                    Este exame demanda obrigatoriamente o uso de
                    sedação/anestesia
                  </S.AnesthesiaSubTitle>
                )}

              {!!state?.exams?.length &&
                state?.exams[0]?.anesthesia_option ===
                  AnesthesiaOptions.OPTIONAL && (
                  <>
                    <S.AnesthesiaOption>
                      Exame será realizado com sedação/anestesia? *
                    </S.AnesthesiaOption>

                    <S.HorizontalRadioPanel>
                      <RadioButtonNew
                        showBackground={false}
                        name="sedation"
                        labelFor="Sim"
                        label="Sim"
                        onCheck={() => handleChangeSedation(true)}
                        checked={state.use_sedation === true}
                      />

                      <RadioButtonNew
                        showBackground={false}
                        name="sedation"
                        labelFor="Não"
                        label="Não"
                        onCheck={() => handleChangeSedation(false)}
                        checked={state.use_sedation === false}
                      />
                    </S.HorizontalRadioPanel>
                    <S.SubTitleGreenCheckBox>
                      Esta informação deve constar no pedido médico.
                    </S.SubTitleGreenCheckBox>
                  </>
                )}

              <ButtonNew
                color="primary"
                fullWidth
                type="submit"
                size="large"
                style={{ marginTop: '25px', marginBottom: '20px' }}
                onClick={() => formik.handleSubmit()}
                disabled={!formik.values.units.length}
              >
                Escolher horário
              </ButtonNew>
              {!!examAvailableDates?.length && (
                <>
                  <DividerNew />
                  <S.TitleStrong>Escolher horário</S.TitleStrong>

                  <S.SubTitleHeader style={{ marginBottom: '20px' }}>
                    Selecione um dos horários sugeridos ou clique em “mais” para
                    escolher um horário na agenda.
                  </S.SubTitleHeader>

                  {!!pageItems?.length &&
                    pageItems?.map(
                      (examAvailableDates: ExamAvailableOfferDetails) => {
                        return (
                          <ExamScheduleOptionCard
                            scheduleByResource={scheduleByResource}
                            key={examAvailableDates.schedule_id}
                            examOptionDetails={examAvailableDates}
                            currentSchedulingGroup={currentSchedulingGroup}
                            handleOpenCalendar={() =>
                              handleOpenCalendar(examAvailableDates)
                            }
                            examName={examName}
                            next={next}
                          />
                        )
                      }
                    )}
                </>
              )}
              {pageItems?.length < examAvailableDates?.length && (
                <ButtonNew
                  style={{ marginBottom: '20px' }}
                  onClick={handleClickNextPage}
                >
                  Ver mais
                </ButtonNew>
              )}
            </>
          )}

          {examType === ExamSearchType.SURGERY && (
            <>
              <AutoCompleteNew
                style={{ marginTop: '24px' }}
                name="surgical_procedure_doctor"
                placeholder="Digite o nome do médico"
                label="Nome do médico"
                labelWeight="semiBold"
                labelSize="small"
                value={
                  surgicalProcedureDoctorQuery ||
                  selectedSurgicalProcedureDoctor?.doctor_name ||
                  ''
                }
                onType={(input) => searchSurgicalProcedureDoctor(input)}
                onInputChange={setSurgicalProcedureDoctorQuery}
                suggestions={surgicalProcedureDoctors?.map(
                  (doctor: SurgicalProcedureDoctor) => ({
                    label: doctor.doctor_name?.toLocaleLowerCase(),
                    value: doctor
                  })
                )}
                onSuggestionClick={(value) =>
                  selectSurgicalProcedureDoctor(value)
                }
                clear={() => resetSelectedSurgicalProcedureDoctor()}
                disabled={!!selectedSurgicalProcedureDoctor}
                data-testid="select-surgical-doctor-autocomplete"
                debounceDelay={500}
              />

              {!!availableUnitsBySurgicalProcedureDoctor?.length && (
                <>
                  <S.SubTitleHeader style={{ marginTop: '24px' }}>
                    Unidade de atendimento *
                  </S.SubTitleHeader>

                  {availableUnitsBySurgicalProcedureDoctor?.map(
                    (unit: Partial<Hospital>) => {
                      return (
                        <RadioButtonNew
                          key={unit.hospital_id}
                          showBackground={false}
                          name="surgical_procedure_unit"
                          labelFor={`unidade-cirurgica-${unit.name}`}
                          label={unit.name}
                          value={unit.hospital_id}
                          onCheck={() => setSelectedSurgicalProcedureUnit(unit)}
                          // checked={}
                        />
                      )
                    }
                  )}
                </>
              )}

              <ButtonNew
                color="primary"
                fullWidth
                type="button"
                size="large"
                style={{ marginTop: '24px', marginBottom: '24px' }}
                onClick={() => handleSurgicalProcedureSolicitation()}
                disabled={
                  !selectedSurgicalProcedureDoctor ||
                  !selectedSurgicalProcedureUnit
                }
              >
                Enviar solicitação
              </ButtonNew>
            </>
          )}
        </ContainerNew>
      </>
    )
}

export default SearchDoctorAndUnity

type ExamScheduleOptionCard = {
  examOptionDetails: ExamAvailableOfferDetails
  handleOpenCalendar: (examOptionDetails: any) => void
  next: () => void
  scheduleByResource: boolean
  examName: string
  currentSchedulingGroup?: string | null
}

const ExamScheduleOptionCard = ({
  examOptionDetails,
  handleOpenCalendar,
  scheduleByResource,
  examName,
  next,
  currentSchedulingGroup
}: ExamScheduleOptionCard) => {
  const { dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  type AvailableHour = {
    cd_it_agenda_central: number
    cd_agenda_central: number
    date_begin: string
    date_finish: string
    cd_it_agenda_ini: number
    cd_it_agenda_fim: number
  }

  function handleSelectExamOffer(available_hour: AvailableHour) {
    const selectedExamOffer = {
      exam_item_id: examOptionDetails.exam_item_id,
      exam_id: examOptionDetails.exam_id,
      exam_item_title: examName
        ?.charAt(0)
        .toUpperCase()
        .concat(examName.slice(1).toLowerCase()),
      date_initial: examOptionDetails.date_initial,
      doctor: examOptionDetails.doctor,
      unit: examOptionDetails.unit,
      selected_hour: available_hour,
      hospital_id: examOptionDetails.hospital_id,
      currentSchedulingGroup: currentSchedulingGroup
    }

    dispatch({
      type: 'SELECT_EXAM_OFFER',
      payload: selectedExamOffer
    })

    next()
  }

  return (
    <>
      <S.CardContainer>
        <S.CardExamTitle>
          {examName
            ?.charAt(0)
            .toUpperCase()
            .concat(examName.slice(1).toLowerCase())}{' '}
        </S.CardExamTitle>
        <S.PurpleStrong>
          {moment(examOptionDetails.date_initial).format('dddd | DD/MM/YYYY')}
        </S.PurpleStrong>

        {!scheduleByResource && (
          <>
            <S.CardLabel>Médico</S.CardLabel>
            <S.CardTextValue>{examOptionDetails?.doctor?.name}</S.CardTextValue>
          </>
        )}

        <S.CardLabel>Unidade</S.CardLabel>
        <S.CardTextValue>{examOptionDetails.unit.name}</S.CardTextValue>

        <S.CardLabel>Escolha um horário</S.CardLabel>

        <S.HorizontalButtonPanel>
          {examOptionDetails.available_hours.map((available_hour, index) => {
            if (index <= 2) {
              return (
                <ButtonNew
                  size="small"
                  color="primary"
                  style={{ width: '65px' }}
                  key={available_hour.date_begin}
                  onClick={() => handleSelectExamOffer(available_hour)}
                >
                  {' '}
                  {moment.utc(available_hour.date_begin).format('HH:mm')}
                </ButtonNew>
              )
            }
          })}
          <ButtonNew
            color="primary"
            size="small"
            icon={<CalendarIcon />}
            iconPosition="right"
            onClick={() => handleOpenCalendar(examOptionDetails)}
            style={{ width: '70px' }}
          >
            {' '}
            mais
          </ButtonNew>
        </S.HorizontalButtonPanel>
      </S.CardContainer>
    </>
  )
}

type CalendarScreenProps = {
  selectedExamOption: any
  setIsLoading: (value: boolean) => void
  next: () => void
  prev?: () => void
  scheduleByResource?: boolean
  // doctorsSearchResult?: SchedulingDoctor
  examGroupsOrdered: ExamGroup[]
  currentSchedulingGroup?: string | null
}
const CalendarScreen = ({
  selectedExamOption,
  setIsLoading,
  next,
  prev,
  scheduleByResource,
  examGroupsOrdered,
  currentSchedulingGroup
}: // doctorsSearchResult
CalendarScreenProps) => {
  const { state, dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  const examService = useServices().exam

  const [availableDates, setAvailableDates] = useState([]) as any

  const [availableHours, setAvailableHours] = useState() as any

  const filteredExamsOrderedAllowed = examGroupsOrdered
    .filter((examGroup: ExamGroup) => !examGroup.scheduled)?.[0] // Filtra o próximo grupo não agendado
    .exams?.filter((exam: ExamModelNew) => exam.allow_schedule) // Filtra exames que podem ser agendados
    .map((exam: ExamModelNew) => {
      return {
        item_ids: exam.item_ids,
        unification_id: exam.unification_id,
        name: exam.name
      }
    })

  const getSchedulesByMonth = async (date: string) => {
    try {
      setIsLoading(true)
      const response = await examService.getExamScheduleOffersByMonth({
        patient: {
          age: state.age,
          patient_id: state.patient_id
        },
        exams: filteredExamsOrderedAllowed,
        health_insurance: {
          health_insurance_id: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        },
        doctor_id: selectedExamOption?.doctor?.doctor_id || null,
        unit_ids: [selectedExamOption.unit.unit_id],
        month: date, //'2023-12-10'
        has_anesthesia: scheduleByResource ? state.use_sedation : false
      })

      setAvailableDates(response)
      setAvailableHours([])
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar ofertas no mês')
    } finally {
      setIsLoading(false)
    }
  }

  ///////////////////////

  const selectedDate = (date: string) => {
    return availableDates.find((items: any) => {
      const currentDate = moment(items.date_initial).format('YYYY-MM-DD')
      return currentDate === moment(date).format('YYYY-MM-DD')
    })
  }

  const getHoursByDay = async (date: string) => {
    try {
      setIsLoading(true)

      const selectedDateItem = selectedDate(date)

      const response = await examService.getExamScheduleHours({
        patient: {
          age: state.age,
          patient_id: state.patient_id
        },
        exams: filteredExamsOrderedAllowed,
        doctor_id: selectedExamOption?.doctor?.doctor_id || null,
        unit_ids: [selectedExamOption.unit.unit_id],
        health_insurance: {
          health_insurance_id: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        },
        schedule_id: selectedDateItem?.schedule_id || 0,
        date: date,
        has_anesthesia: scheduleByResource ? state.use_sedation : false
      })

      setAvailableHours(response[0]?.available_hours)
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar ofertas do dia')
    } finally {
      setIsLoading(false)
    }
  }

  const submitSchedule = (time: number) => {
    const selectedHour = availableHours?.find((availableHour: any) => {
      return availableHour.cd_it_agenda_central === time
    })

    const selectedExamOffer = {
      exam_item_id: selectedExamOption.exam_item_id,
      exam_id: selectedExamOption.exam_id,
      exam_item_title: selectedExamOption.exam_item_title,
      date_initial: selectedExamOption.time,
      doctor: selectedExamOption.doctor || null,
      unit: selectedExamOption.unit,
      selected_hour: selectedHour,
      hospital_id: selectedExamOption.hospital_id,
      currentSchedulingGroup: currentSchedulingGroup
    }

    dispatch({
      type: 'SELECT_EXAM_OFFER',
      payload: selectedExamOffer
    })

    next()
  }

  useEffect(() => {
    getSchedulesByMonth(moment(new Date()).format('YYYY-MM-DD'))
  }, [])

  const examTitle = selectedExamOption.exam_item_title
  const unit = selectedExamOption?.unit?.name
  const arrUnit = unit.split(' ')

  return (
    <>
      <ContainerNew>
        <S.PurpleStrong>
          {examTitle
            ?.charAt(0)
            .toUpperCase()
            .concat(examTitle?.slice(1).toLowerCase())}
        </S.PurpleStrong>

        <S.TitleStrong>
          {arrUnit
            .map((word: string) => {
              return word
                ?.charAt(0)
                .toUpperCase()
                .concat(word?.slice(1).toLowerCase())
            })
            .join(' ')}
        </S.TitleStrong>
        {/* <a href="">Trocar unidade</a> */}
      </ContainerNew>
      <div style={{ padding: '24px' }}>
        <CalendarForm
          availableDates={availableDates}
          availableHours={availableHours}
          dateInitial={String(new Date())}
          getSchedulesByMonth={getSchedulesByMonth}
          getHoursByDay={getHoursByDay}
          submitSchedule={submitSchedule}
          schedulingType="exams"
          prev={prev}
        />
      </div>
    </>
  )
}

type EmptyStateScreenProps = {
  setShowEmptyStateScreen: (value: boolean) => void
  loadSelectedExamsOrdered: () => void
  slideTo?: (value: number) => void
  activeIndex?: number
  examName: string
}

const EmptyStateScreen = ({
  setShowEmptyStateScreen,
  loadSelectedExamsOrdered,
  examName,
  activeIndex,
  slideTo
}: EmptyStateScreenProps) => {
  function handleNew() {
    setShowEmptyStateScreen(false)
    loadSelectedExamsOrdered()

    if (activeIndex && activeIndex === 5) {
      slideTo && slideTo(4)
    }
  }

  const history = useHistory()

  const { state } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  function handleReturn() {
    if (state.exams.length >= 1) {
      slideTo && slideTo(4)
    } else {
      history.push('/home')
    }
  }

  let titleButton

  titleButton = 'Agendar outro exame'

  if (state?.exams?.length > 1) {
    titleButton = 'Próximo'
  }

  if (state?.exams?.length === 0 && state?.examsScheduled?.length >= 1) {
    titleButton = 'Próximo'
  }

  return (
    <ContainerNew
      title={examName}
      titleColor="secondary700"
      titleWeight="bold"
      titleSize="large"
    >
      <S.EmptyStateContainer>
        <NoAgenda />
        Este exame não possui agenda aberta para os próximos 6 meses.
      </S.EmptyStateContainer>

      <S.ButtonPanel>
        <ButtonNew onClick={handleNew}>{titleButton}</ButtonNew>

        <ButtonNew onClick={handleReturn} outlined>
          {' '}
          {state.exams.length >= 1 ? 'Anterior' : 'Voltar para a home'}
        </ButtonNew>
      </S.ButtonPanel>
    </ContainerNew>
  )
}
