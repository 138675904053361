import React, { useContext } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import * as S from './styles'

import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar-icon.svg'
import { ReactComponent as GrayPinIcon } from 'presentation/assets/icons/gray-pin-icon.svg'
import { ReactComponent as SyringeIcon } from 'presentation/assets/icons/syringe-icon.svg'
import { ReactComponent as StethoscopeIconOutline } from 'presentation/assets/icons/stethoscope-outline.svg'
import { ReactComponent as AlertOutlineIcon } from 'presentation/assets/icons/alert-triangle.svg'

import {
  SchedulingExamContext,
  SchedulingExamModelContext,
  SchedulingExamState
} from '../context'

import { NavigationProps } from '../utils'
import moment from 'moment'
import ButtonNew from 'presentation/components/ButtonNew'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import SupportTextNew from 'presentation/components/SupportTextNew'
import {
  ExamGroup,
  ExamModelNew,
  ExamSearchType
} from 'domain/entities/exam-model-new'
import HeadingNew from 'presentation/components/HeadingNew'

type ExamOfferProps = Pick<
  SchedulingExamState,
  'selectedExamOffer' | 'use_sedation'
>

type SurgicalProcedureProps = Pick<
  SchedulingExamState,
  'selectedSurgicalProcedure'
>

type Props = {
  setIsLoading: (value: boolean) => void
  setShowCalendarScreen?: (value: boolean) => void
} & NavigationProps

const ConfirmationExamSchedule = ({
  setIsLoading,
  next,
  slideTo,
  prev,
  setShowCalendarScreen
}: Props) => {
  const { state, dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  const {
    exams,
    examsOrdered,
    selectedExamOffer,
    surgicalProcedures,
    selectedSurgicalProcedure
  } = state

  const examService = useServices().exam
  const patientService = useServices().patient

  const handleConfirmAppointment = async () => {
    setIsLoading(true)
    try {
      let objectConfirmAppointment = {
        recommended_doctor: state?.recommended_doctor,
        use_sedation: state.use_sedation,
        documents: state.is_reschedule ? [] : state.medical_order_document_ids,
        date: selectedExamOffer.selected_hour?.date_begin,
        health_insurance: {
          health_insurance_id: state.healthInsuranceCode,
          plan_code: state.healthPlanCode
        },
        patient: {
          patient_id: state.patient_id,
          name: state.name,
          email: state.email
        },
        exams: [
          {
            name: selectedExamOffer.exam_item_title,
            is_sedation: !!state.exams[0].anesthesia_option,
            unit: {
              name: selectedExamOffer.unit.name,
              address: selectedExamOffer.unit.address
            }
          }
        ],
        appointments:
          selectedExamOffer.selected_hour.available_appointments.map(
            (appointment) => ({
              item_id: appointment.item_id,
              cd_agenda_central: appointment.cd_agenda_central,
              cd_it_agenda_ini: appointment.cd_it_agenda_ini,
              cd_it_agenda_fim: appointment.cd_it_agenda_fim,
              sn_encaixe: appointment.sn_encaixe
            })
          )
      } as any

      if (state.recommended_doctor === 'undefined') {
        objectConfirmAppointment = {
          ...objectConfirmAppointment,
          doctor: {
            doctor_id: selectedExamOffer?.doctor?.doctor_id,
            name: selectedExamOffer?.doctor?.name
          }
        }
      }

      const examListsurgicalProcedures = exams
        ?.filter((examItem) => examItem.type === ExamSearchType.SURGERY)
        .flat()

      // Cria agendamento
      await examService.createPatientExamAppointment(objectConfirmAppointment)
      toast.success('Agendamento de exame criado com sucesso!')

      // Filtra grupo de exames agendados
      const examGroupScheduled = examsOrdered
        .filter(
          (group: ExamGroup) =>
            group.groupIndex === selectedExamOffer?.currentSchedulingGroup
        )
        .map((examGroup: ExamGroup) => {
          return {
            ...examGroup,
            groupExamsScheduled: objectConfirmAppointment,
            selectedExamOffer: selectedExamOffer,
            groupIndex: null,
            scheduled: true
          }
        })

      // Filtra grupos de exames não agendados
      const examGroupsNotScheduled = examsOrdered?.filter(
        (group: ExamGroup) =>
          group.groupIndex !== selectedExamOffer?.currentSchedulingGroup
      )

      // Cria a lista atualizada de exames selecionados pelo paciente (removendo os agendados)
      const updatedSelectedExams: ExamModelNew[] = []
      examGroupsNotScheduled?.forEach((examGroup: ExamGroup) =>
        examGroup.exams.forEach((exam: ExamModelNew) =>
          updatedSelectedExams.push(exam)
        )
      )

      // Adiciona grupo (exames) agendados no objeto examGroupsScheduled no context para ser usado na tela de confirmação de agendamento:
      let updateExamsScheduled = []

      if (state.examsScheduled) {
        updateExamsScheduled = [
          ...state.examsScheduled,
          examGroupScheduled
        ].flat()
      } else {
        updateExamsScheduled = examGroupScheduled
      }

      // Atualiza lista de exames agendados
      await dispatch({
        type: 'UPDATE_EXAMS_SCHEDULED',
        payload: updateExamsScheduled
      })

      // Atualiza lista de exames selecionados pelo paciente (remove os exames agendados)
      await dispatch({
        type: 'UPDATE_SELECTED_EXAMS',
        payload: [
          ...examListsurgicalProcedures,
          updatedSelectedExams && updatedSelectedExams
        ]
      })

      // Limpa listagem de exames ordenados
      await dispatch({
        type: 'UPDATE_SELECTED_EXAMS_ORDERED',
        payload: []
      })

      // Limpa selectedExamOffer
      dispatch({
        type: 'SELECT_EXAM_OFFER',
        payload: undefined
      })

      // Esconde calendário
      setShowCalendarScreen && setShowCalendarScreen(false)

      // Agenda próximo grupo de exames ou redireciona para a tela de confirmação de agendamento
      if (
        examGroupsNotScheduled?.length > 0 ||
        examListsurgicalProcedures?.length > 0
      ) {
        await dispatch({
          type: 'SET_HAS_NEXT_SCHEDULE',
          payload: true
        })
        setIsLoading(false)
        setTimeout(() => {
          slideTo && slideTo(5)
        }, 1000)
      } else {
        await dispatch({
          type: 'SET_HAS_NEXT_SCHEDULE',
          payload: false
        })
        next()
      }
      setIsLoading(false)
    } catch (error: any) {
      toast.error('Falha ao agendar exame, tente novamente.')
      slideTo && slideTo(4) // Redireciona para a listagem de exames selecionados pelo paciente
    } finally {
      setIsLoading(false)
    }
  }

  const handleSurgicalProcedureSolicitation = async () => {
    setIsLoading(true)

    try {
      const payload = {
        health_insurance: selectedSurgicalProcedure.health_insurance,
        hospital_id: selectedSurgicalProcedure.hospital.hospital_id,
        doctor: {
          doctor_id: selectedSurgicalProcedure.doctor.doctor_id,
          doctor_name: selectedSurgicalProcedure.doctor.doctor_name
        },
        surgery_type: selectedSurgicalProcedure?.surgery_type,
        documents: selectedSurgicalProcedure.documents
      }

      // Cria solicitação
      await patientService.requestSurgicalOrder(payload)
      toast.success('Solicitação de pedido cirúrgico criada com sucesso!')

      const updatedListSurgicalProcedures = surgicalProcedures
        ?.filter(
          (examItem) =>
            examItem.surgicalProcedureIndex !==
            selectedSurgicalProcedure.surgicalProcedureIndex
        )
        .flat()

      // Atualiza lista de procedimentos cirúrgicos selecionados pelo paciente (remove o agendado)
      await dispatch({
        type: 'UPDATE_SELECTED_SURGICAL_PROCEDURES',
        payload: updatedListSurgicalProcedures
      })

      // Limpa procedimento cirúrgico selecionado
      await dispatch({
        type: 'SELECT_SURGICAL_PROCEDURE',
        payload: {}
      })

      // Vai para o próximo procedimento cirúrgico ou segue adiante
      // Tratar caso de Biópsia aqui (caso necessário):
      if (updatedListSurgicalProcedures?.length > 0) {
        await dispatch({
          type: 'SET_HAS_NEXT_SCHEDULE',
          payload: true
        })
        setTimeout(() => {
          slideTo && slideTo(5)
        }, 1000)
      } else {
        await dispatch({
          type: 'SET_HAS_NEXT_SCHEDULE',
          payload: false
        })

        if (state.consultPreExams?.length) {
          dispatch({
            type: 'SET_SHOW_PRE_CONSULT',
            payload: true
          })

          slideTo && slideTo(4)
        } else {
          setTimeout(() => {
            slideTo && slideTo(0)
          }, 1000)
        }
      }
    } catch (error: any) {
      toast.error(
        'Falha ao solicitar procedimento cirúrgico, tente novamente mais tarde.'
      )
      slideTo && slideTo(4) // Redireciona para a listagem de exames selecionados pelo paciente
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ContainerNew
      title="Confirmar informações"
      titleColor="secondary700"
      titleSize="medium"
      titleWeight="bold"
      subtitle={
        selectedExamOffer
          ? 'Confirme as informações para realizar o agendamento'
          : 'Confirme as informações para enviar sua solicitação ao hospital e iniciar o processo de autorização do procedimento cirúrgico'
      }
    >
      {selectedExamOffer ? (
        <>
          <ConfirmExamCard
            selectedExamOffer={selectedExamOffer}
            use_sedation={state.use_sedation}
          />

          <S.ButtonPanel>
            <ButtonNew
              color="primary"
              type="button"
              fullWidth
              size="large"
              onClick={handleConfirmAppointment}
            >
              Confirmar
            </ButtonNew>
            <ButtonNew
              type="button"
              outlined
              onClick={prev}
              fullWidth
              size="large"
            >
              Anterior
            </ButtonNew>
          </S.ButtonPanel>
        </>
      ) : (
        <>
          <ConfirmSurgicalProcedureCard
            selectedSurgicalProcedure={selectedSurgicalProcedure}
          />

          <S.CardAttention style={{ marginTop: '24px' }}>
            <AlertOutlineIcon className="icon" width={21} />
            <HeadingNew as="h4" color="terciary900" weight="semiBold">
              Atenção!
            </HeadingNew>
            <SupportTextNew
              color="terciary900"
              size="xsmall"
              style={{ marginTop: '8px' }}
            >
              Este exame, por ser um procedimento cirúrgico passará pelo
              processo de autorização de cirurgias.
            </SupportTextNew>
            <SupportTextNew
              color="terciary900"
              size="xsmall"
              style={{ marginTop: '16px' }}
            >
              Você pode acompanhar o status do procedimento na aba Cirurgias.
            </SupportTextNew>
          </S.CardAttention>

          <S.ButtonPanel>
            <ButtonNew
              color="primary"
              type="button"
              fullWidth
              size="large"
              onClick={handleSurgicalProcedureSolicitation}
            >
              Confirmar solicitação
            </ButtonNew>
            <ButtonNew
              type="button"
              outlined
              onClick={prev}
              fullWidth
              size="large"
            >
              Anterior
            </ButtonNew>
          </S.ButtonPanel>
        </>
      )}
    </ContainerNew>
  )
}

export default ConfirmationExamSchedule

const ConfirmExamCard = ({
  selectedExamOffer,
  use_sedation
}: ExamOfferProps) => {
  return (
    <S.CardContainer>
      <div>
        <SupportTextNew color="neutral800" size="medium" weight="bold">
          {selectedExamOffer?.exam_item_title}
        </SupportTextNew>

        <S.HorizontalLine />
      </div>

      <S.ContainerInfo>
        <S.Label>
          <CalendarIcon />
          Data agendada
        </S.Label>
        <S.PurpleDescription>
          {moment(selectedExamOffer.selected_hour.date_begin).format(
            `dddd [|] DD/MM/YYYY [às] HH[h]mm`
          )}
        </S.PurpleDescription>
      </S.ContainerInfo>

      {selectedExamOffer?.doctor?.name && (
        <S.ContainerInfo>
          <S.Label>
            <StethoscopeIconOutline width={15} />
            Médico
          </S.Label>
          <S.SubDescription>
            Dr(a) {selectedExamOffer?.doctor?.name}{' '}
          </S.SubDescription>
        </S.ContainerInfo>
      )}

      <S.ContainerInfo>
        <S.Label>
          <GrayPinIcon />
          Unidade de atendimento
        </S.Label>
        <S.SubDescription> {selectedExamOffer.unit.name} </S.SubDescription>
        <S.AddressDescription>
          {' '}
          {selectedExamOffer.unit.address}
        </S.AddressDescription>
      </S.ContainerInfo>

      <S.ContainerInfo>
        <S.Label>
          <SyringeIcon />
          Vai realizar sedação?
        </S.Label>
        <S.SubDescription>{use_sedation ? 'Sim' : 'Não'} </S.SubDescription>
      </S.ContainerInfo>
    </S.CardContainer>
  )
}

const ConfirmSurgicalProcedureCard = ({
  selectedSurgicalProcedure
}: SurgicalProcedureProps) => {
  return (
    <S.CardContainer>
      <div>
        <SupportTextNew color="neutral800" size="medium" weight="bold">
          {selectedSurgicalProcedure?.exam_item_title}
        </SupportTextNew>

        <S.HorizontalLine />
      </div>

      {selectedSurgicalProcedure?.doctor?.doctor_name && (
        <S.ContainerInfo>
          <S.Label>
            <StethoscopeIconOutline width={15} />
            Médico
          </S.Label>
          <S.SubDescription>
            Dr(a) {selectedSurgicalProcedure?.doctor?.doctor_name}
          </S.SubDescription>
        </S.ContainerInfo>
      )}

      {selectedSurgicalProcedure?.hospital?.name && (
        <S.ContainerInfo>
          <S.Label>
            <GrayPinIcon />
            Unidade de atendimento
          </S.Label>
          <S.SubDescription>
            {' '}
            {selectedSurgicalProcedure?.hospital?.name}{' '}
          </S.SubDescription>
          {/* <S.AddressDescription>
          {' '}
          {selectedSurgicalProcedure?.hospital?.name}
        </S.AddressDescription> */}
        </S.ContainerInfo>
      )}
    </S.CardContainer>
  )
}
