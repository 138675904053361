import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { VueMotionRepository } from 'repository/repositories/vue-motion/vue-motion-repository'
import { RemoteVueMotionCheckForExams } from 'service/usecases/vue-motion-check-for-exams/vue-motion-check-for-exams'
import VueMotionService from 'presentation/contexts/services/vue-motion-service'

export default function makeVueMotionService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const vueMotionRepository = new VueMotionRepository(apiRepository)

  const vueMotionCheckForExams = new RemoteVueMotionCheckForExams(
    vueMotionRepository
  )

  return new VueMotionService({
    vueMotionCheckForExams
  })
}
