import React from 'react'

import { useLocation } from 'react-router-dom'

import BottomMenu from 'presentation/components/BottomMenu'
import { Container } from 'presentation/components/Container'
import { Header } from 'presentation/components/Container/styles'
import InternationDetails from 'presentation/shared/components/InternationDetails'
import LabelBox from 'presentation/shared/components/LabelBox'
import getTelephoneExtensions from 'presentation/utils/telephoneExtensions'
import * as S from './styles'
import getWifiNetworks from 'presentation/utils/wifiNetworks'

type Location = {
  id: string
  data?: {
    hospital: string
  }
}

export default function InfoAndAccess() {
  const { state } = useLocation<Location>()

  const { data } = state || {}

  const telephoneExtensions = getTelephoneExtensions(data?.hospital || '')
  const wifiNetworks = getWifiNetworks()
  return (
    <>
      <Header />
      <Container actualPageText="Informações e acessos">
        <S.Wrapper>
          <S.Header>
            <InternationDetails />
          </S.Header>
          <LabelBox
            columns={1}
            text={
              <>
                <S.HeaderText>Redes</S.HeaderText>
              </>
            }
          >
            <>
              {wifiNetworks && (
                <>
                  <S.ContentText>
                    <span>{wifiNetworks.name}</span>
                  </S.ContentText>
                </>
              )}
            </>
          </LabelBox>
          <LabelBox
            columns={2}
            text={
              <>
                <S.HeaderText>Ramais</S.HeaderText>
                <S.HeaderText>Areas</S.HeaderText>
              </>
            }
          >
            <>
              {telephoneExtensions &&
                telephoneExtensions.map((item) => (
                  <>
                    <S.ContentText>
                      <span>{item.phoneExtension}</span>
                    </S.ContentText>
                    <S.ContentText>
                      <span>{item.area}</span>
                    </S.ContentText>
                  </>
                ))}
            </>
          </LabelBox>
        </S.Wrapper>
      </Container>
      <BottomMenu />
    </>
  )
}
