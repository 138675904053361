import React from 'react'
import * as S from './styles'
import ActualPage from 'presentation/components/ActualPage'
import Header from 'presentation/components/Header'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import { Switch, Route, useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import SurgeryDocumentList from 'presentation/shared/components/SurgeryDocumentList'
import OrderDetails from 'presentation/shared/components/OrderDetails'
import { ListItem } from 'presentation/shared/components/List'
import { TimelineStatusItem } from 'presentation/shared/components/Timeline'
import SupportText from 'presentation/components/SupportText'
import { MedicalTeam } from 'domain/entities/surgery-model'
import BottomMenu from 'presentation/components/BottomMenu'
import { Accompanying } from 'domain/entities/accompanying-model'
import SurgicalOrderTimeline from 'presentation/shared/components/Timeline/surgical-order-timeline'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

export type Procedure = {
  isMain: boolean
  procedure_code: string
  code: string
  description: string
  quantity: number
  doctor_name: string
}

export type SurgeryDetailsLayoutProps = {
  date?: string
  expectedDate?: string
  title?: string
  hospital?: string
  surgery_id?: number
  documents?: Array<ListItem & { document_id: number; type: string }>
  statusList?: TimelineStatusItem[]
  medicalTeam?: MedicalTeam[]
  mainAccompanying?: Accompanying
  isSolicitation?: boolean
  isUrgent?: boolean

  passwordToFinish?: string
  passwordMaxDateValidation?: string
  procedureDetails?: string
  observation?: string
  opmeDetails?: string
  opme?: {
    solicitations: {
      description: string
      quantity: number
      observation: string
    }[]
    providers: string[]
  }
  procedures?: Procedure[]
}

export default function SurgeryDetailsLayout({
  documents,
  statusList,
  date,
  hospital,
  title,
  expectedDate,
  surgery_id,
  isSolicitation,
  isUrgent,
  passwordToFinish,
  passwordMaxDateValidation,
  procedures,
  procedureDetails,
  observation,
  opmeDetails,
  opme
}: SurgeryDetailsLayoutProps) {
  const history = useHistory()
  const stores = useStores()
  const currentUser = stores.currentAccount
  const role = currentUser.getCurrentAccount()?.user?.role

  return (
    <>
      <Header />
      <Container noPadding>
        <S.Header>
          <ActualPage onClick={() => history.push('/home')} text="Cirurgia" />
          <S.HeadInfo>
            <Heading color="primary" as="h1" size="large">
              {title}
            </Heading>
            <S.LocaleInfoBox>
              <S.LocaleInfo>
                {date && (
                  <>
                    <label>Data da cirurgia:</label>
                    <strong>{date}</strong>
                  </>
                )}
                {!date && (
                  <>
                    <label>
                      {isUrgent
                        ? 'Data de Urgência:'
                        : 'Data prevista para cirurgia:'}
                    </label>
                    <strong>
                      {role !== Profile.PATIENT && expectedDate
                        ? expectedDate
                        : 'Aguardando agendamento'}
                    </strong>
                  </>
                )}
              </S.LocaleInfo>
              <S.LocaleInfo>
                <label>Unidade: {hospital && ' ' + hospital}</label>
              </S.LocaleInfo>
              <S.LocaleInfo>
                <label>
                  Número do aviso:{' '}
                  {surgery_id && !isSolicitation ? ' ' + surgery_id : ''}
                </label>
              </S.LocaleInfo>
            </S.LocaleInfoBox>
          </S.HeadInfo>
          <S.InfoList>
            <NavLink
              exact
              to={{ pathname: '/cirurgia', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Informações
            </NavLink>
            <NavLink
              exact
              to={{ pathname: '/cirurgia/status', state: { id: surgery_id } }}
              activeClassName="selected"
            >
              Status
            </NavLink>
            <NavLink
              exact
              to={{
                pathname: '/cirurgia/documentos',
                state: { id: surgery_id }
              }}
              activeClassName="selected"
            >
              Documentos
            </NavLink>
          </S.InfoList>
        </S.Header>
        <S.Content>
          <Switch>
            <Route
              exact
              path={`/cirurgia`}
              render={() => {
                return (
                  <OrderDetails
                    passwordToFinish={passwordToFinish}
                    passwordMaxDateValidation={passwordMaxDateValidation}
                    procedures={procedures}
                    procedureDetails={procedureDetails}
                    observation={observation}
                    opmeDetails={opmeDetails}
                    opme={opme}
                    statusList={statusList}
                  />
                )
              }}
            />
            <Route
              exact
              path={`/cirurgia/status`}
              render={(props) => (
                <S.StatusContainer>
                  <SupportText color="lightGray">
                    Acompanhe o andamento de liberação e marcação do seu
                    procedimento.
                  </SupportText>
                  <Heading as="h2">Status da cirurgia</Heading>
                  <SurgicalOrderTimeline items={statusList} {...props} />
                </S.StatusContainer>
              )}
            />
            <Route
              exact
              path={`/cirurgia/documentos`}
              render={(props) => (
                <SurgeryDocumentList {...props} documents={documents ?? []} />
              )}
            />
          </Switch>
        </S.Content>
      </Container>
      <BottomMenu />
    </>
  )
}
