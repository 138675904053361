import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { RemoteAddAccompanying } from 'service/usecases/add-accompanying/remote-add-accompanying'
import { RemoteUpdateAccompanying } from 'service/usecases/update-accompanying/remote-update-accompanying'
import AddAccompanyings from 'presentation/pages/AddAccompanyings'
import { AccompanyingRepository } from 'repository/repositories/accompanying/accompanying-repository'

export default function makeAddPatientAccompanyings() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const accompanyingRepository = new AccompanyingRepository(apiRepository)
  const addAccompanying = new RemoteAddAccompanying(accompanyingRepository)
  const updateAccompanying = new RemoteUpdateAccompanying(
    accompanyingRepository
  )

  return (
    <AddAccompanyings
      addAccompanying={addAccompanying}
      updateAccompanying={updateAccompanying}
    />
  )
}
