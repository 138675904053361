import styled from 'styled-components'

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 25px;
`
export const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
`
export const Label = styled.div`
  color: ${({ theme }) => theme.ds.colors.primary600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
`
export const Description = styled.div`
  color: ${({ theme }) => theme.ds.colors.neutral600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  margin-bottom: 15px;
`
export const TextArea = styled.div`
  padding: 15px;
  margin-top: 10px;
  background: ${({ theme }) => theme.ds.colors.neutral25};
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
  border-radius: 5px;

  & > Description {
    color: ${({ theme }) => theme.ds.colors.primary600};
    font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  }
`
