export enum Profile {
  PATIENT = 'patient',
  DOCTOR = 'doctor',
  HOSPITAL = 'admin',
  SECRETARY = 'secretary',
  CRMO = 'crmo',
  HOSPITALIZATION = 'hospitalization',
  AMBULATORY = 'ambulatory',
  ONCOLOGY = 'oncology',
  DOCTOR_REGISTER = 'doctor_register',
  DIRECTOR_DOCTOR_REGISTER = 'director_doctor_register',
  ADMIN_SECTORIAL_CRMO = 'admin_sectorial_crmo',
  EMERGENCY_ROOM = 'emergency_room',
  SETOR_DE_MARCACAO = 'setor_de_marcacao'
}

export enum ProfileLocated {
  patient = 'Paciente',
  doctor = 'Médico(a)',
  admin = 'Admin',
  secretary = 'Secretária',
  crmo = 'CRMO',
  hospitalization = 'Hospitalização',
  ambulatory = 'Ambulatório',
  oncology = 'Oncologia',
  doctor_register = 'Cadastro médico',
  director_doctor_register = 'Diretor médico',
  admin_sectorial_crmo = 'Admin Setorial CRMO',
  setor_de_marcacao = 'setor_de_marcacao'
}
