import { CreateRegisterPendency } from 'domain/usecases/register-pendency/create-register-pendency'
import { LoadCurrentRegisterPendency } from 'domain/usecases/register-pendency/load-current-register-pendency'
import { makeAutoObservable } from 'mobx'

type Services = {
  create: CreateRegisterPendency
  loadCurrent: LoadCurrentRegisterPendency
}

export class RegisterPendencyService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async createRegisterPendency(
    data: CreateRegisterPendency.Params
  ): Promise<CreateRegisterPendency.Model> {
    return this.services.create.createRegisterPendency(data)
  }

  async loadCurrentRegisterPendency(
    params: LoadCurrentRegisterPendency.Params
  ): Promise<LoadCurrentRegisterPendency.Model> {
    return this.services.loadCurrent.loadCurrentRegisterPendency(params)
  }
}
