import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import Carousel, { CarouselState } from 'presentation/components/Carousel'
import Heading from 'presentation/components/Heading'
import Radio from 'presentation/shared/components/RadioButton'
import Button from 'presentation/components/Button'
import SelectField from 'presentation/shared/components/SelectField'
import TextArea from 'presentation/shared/components/TextArea'
import Checkbox from 'presentation/shared/components/Checkbox'
import ActualPage from 'presentation/components/ActualPage'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'
import { RatingType } from 'common/enum/rating-type'
import Modal from 'presentation/shared/components/Modal'
import { ServiceType } from 'common/enum/service-type'
import { Hospital } from 'domain/entities/hospital-model'
import ProfilePic from 'presentation/components/ProfilePic'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import getDateFormattedByLocale from 'common/utils/getDateFormmatedByLocale'
import { DateLocales } from 'common/enum/date-locales'
import { useStores } from 'presentation/hooks/use-stores'

import * as S from './styles'

type Props = {
  createSuggestionOrCriticism?: CreateSuggestionOrCriticism
}

type Location = {
  data: SurgicalOrderModel
}

export default function SuggestionsOrCriticismsForm({
  createSuggestionOrCriticism
}: Props) {
  const [successModal, setSuccessModal] = useState<boolean>()
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [surgicalOrder, setSurgicalOrder] = useState<SurgicalOrderModel>({})
  const [hospitals, setHospitals] = useState<Hospital[]>([])
  const history = useHistory()
  const currentHospital = useStores().currentHospital

  const { state } = useLocation<Location>()

  const formik = useFormik({
    initialValues: {} as SuggestionsOrCriticismsFormValues,
    validateOnChange: false,
    onSubmit: async (values) => {
      await createSuggestionOrCriticism?.add({
        ...values,
        hospital_id: Number(values.hospital_id),
        patientName: surgicalOrder.patient?.name ?? ''
      })
      setSuccessModal(true)
    }
  })

  const getSendDateMessage = (): string => {
    const nowDate = moment(new Date(), 'DD-MM-YYYY')
    return `Enviado às ${nowDate.format('HH:mm')} - dia ${nowDate.format(
      'DD/MM/YYYY'
    )}`
  }

  useEffect(() => {
    async function handleLoadHospitals() {
      await currentHospital.load()
      setHospitals(currentHospital.hospitalList ?? [])
    }
    handleLoadHospitals()
  }, [])

  useEffect(() => {
    setSurgicalOrder(state.data)
  }, [state])
  return (
    <>
      <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
        <Carousel state={carousel} setState={setCarousel} spaceBetween={4}>
          <S.FormWrapper>
            <ActualPage
              text="Elogios, sugestões ou reclamações"
              onClick={() => history.push('/internacao')}
            />
            <S.PatientInfoContainer>
              <S.Patient>
                <ProfilePic outlined size="medium" />
                <p>{surgicalOrder.patient?.name}</p>
              </S.Patient>
              <div>
                <S.PatientInfo>
                  <div>
                    <strong>Nascimento: </strong>
                    <label>
                      {getDateFormattedByLocale(
                        surgicalOrder.patient?.birthday,
                        DateLocales.ptBr
                      )}
                    </label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Atendimento: </strong>
                    <label>-</label>
                  </div>
                  <div>
                    <strong>Dieta: </strong>
                    <label>-</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Local: </strong>
                    <label>-</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Convênio: </strong>
                    <label>
                      {surgicalOrder.healthInsurance?.healthInsuranceName}
                    </label>
                  </div>
                  <div>
                    <strong>Dias de internação: </strong>
                    <label>{surgicalOrder.requestedDailies ?? 0}</label>
                  </div>
                </S.PatientInfo>
              </div>
            </S.PatientInfoContainer>
            <Heading color="lightGray">
              A sua colaboração é muito importante.
            </Heading>
            <S.Content>
              <S.RadioWrapper>
                <Radio
                  name="type"
                  label="Elogio"
                  labelFor={RatingType.COMPLIMENT}
                  value={RatingType.COMPLIMENT}
                  onCheck={formik.handleChange('type')}
                />
                <Radio
                  name="type"
                  label="Sugestão"
                  labelFor={RatingType.SUGGESTION}
                  value={RatingType.SUGGESTION}
                  onCheck={formik.handleChange('type')}
                />
                <Radio
                  name="type"
                  label="Reclamação"
                  labelFor={RatingType.CRITICISM}
                  value={RatingType.CRITICISM}
                  onCheck={formik.handleChange('type')}
                />
              </S.RadioWrapper>
            </S.Content>
            <Button
              fullWidth
              type="button"
              onClick={carousel.slideNext}
              disabled={!formik.values.type}
            >
              Próximo
            </Button>
          </S.FormWrapper>
          <S.FormWrapper>
            <ActualPage
              text="Elogios, sugestões ou reclamações"
              onClick={() => carousel.slidePrev()}
            />
            <S.PatientInfoContainer>
              <S.Patient>
                <ProfilePic outlined size="medium" />
                <p>{surgicalOrder.patient?.name}</p>
              </S.Patient>
              <div>
                <S.PatientInfo>
                  <div>
                    <strong>Nascimento: </strong>
                    <label>
                      {getDateFormattedByLocale(
                        surgicalOrder.patient?.birthday,
                        DateLocales.ptBr
                      )}
                    </label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Atendimento: </strong>
                    <label>-</label>
                  </div>
                  <div>
                    <strong>Dieta: </strong>
                    <label>-</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Local: </strong>
                    <label>-</label>
                  </div>
                </S.PatientInfo>
                <S.PatientInfo>
                  <div>
                    <strong>Convênio: </strong>
                    <label>
                      {surgicalOrder.healthInsurance?.healthInsuranceName}
                    </label>
                  </div>
                  <div>
                    <strong>Dias de internação: </strong>
                    <label>{surgicalOrder.requestedDailies ?? 0}</label>
                  </div>
                </S.PatientInfo>
              </div>
            </S.PatientInfoContainer>
            <Heading color="lightGray">
              Para qual unidade e área gostaria de enviar sua sugestão?
            </Heading>
            <S.Content>
              <SelectField
                label="Unidade"
                required
                onInputChange={formik.handleChange('hospital_id')}
                items={hospitals.map(
                  ({ name, hospital_id, friendly_name }) => ({
                    label: friendly_name || name,
                    value: hospital_id
                  })
                )}
              />
              <SelectField
                label="Área"
                onInputChange={formik.handleChange('area')}
                items={[
                  {
                    label: 'Rouparia',
                    value: ServiceType.LINEN_ROOM
                  },
                  {
                    label: 'Higienização',
                    value: ServiceType.SANITATION
                  },
                  {
                    label: 'Manutenção',
                    value: ServiceType.MAINTENANCE
                  },
                  {
                    label: 'Nutrição',
                    value: ServiceType.NUTRITION
                  },
                  {
                    label: 'Outros',
                    value: ServiceType.OTHERS
                  }
                ]}
                style={{ marginTop: '8px' }}
              />
              <TextArea
                label="Descrição"
                rows={5}
                style={{ marginBottom: '35px', marginTop: '8px' }}
                onInputChange={formik.handleChange('description')}
                required
              />
              <Checkbox
                label="Aceito que a Rede Mater Dei entre em contato para conversar e entender mais sobre a minha experiência."
                style={{ minWidth: '20px' }}
              />
            </S.Content>
            <Button
              fullWidth
              type="submit"
              disabled={
                !formik.values.hospital_id || !formik.values.description
              }
            >
              Enviar
            </Button>
          </S.FormWrapper>
        </Carousel>
      </S.Wrapper>
      <Modal
        title="Agradecemos o seu retorno."
        description={getSendDateMessage()}
        show={successModal}
        close={history.goBack}
      />
    </>
  )
}

type SuggestionsOrCriticismsFormValues = {
  description: string
  area: string
  type: RatingType
  hospital_id: number
}
