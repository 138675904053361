import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { SurgicalScheduleRepository as ISurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'
import { IApiSurgicalScheduleRepository } from 'service/protocols/api-surgical-schedule/api-surgical-schedule-repository'
import { LoadSurgicalSchedules } from 'domain/usecases/surgical-schedules/load-surgical-schedules'
import { LoadSurgicalSchedule } from 'domain/usecases/surgical-schedule/load-surgical-schedule'
import { ApiStatusCode } from 'service/protocols/api-surgical-schedule/api-surgical-schedule-client'
import {
  EquipamentsModel,
  HospitalSurgeryCenterModel
} from 'domain/entities/surgical-schedule-model'
import { LoadEquipaments } from 'domain/usecases/equipaments/load-equipaments'
import { LoadHospitalSurgeryCenter } from 'domain/usecases/hospital-surgery-center/load-hospital-surgery-center'
import { LoadSurgicalScheduleAvailableDays } from 'domain/usecases/load-surgical-schedule-available-days/load-surgical-schedule-available-days'
import { LoadSurgeryCenterCalendar } from 'domain/usecases/load-surgery-center-calendar/load-surgery-center-calendar'
import { FinishSurgicalSchedule } from 'domain/usecases/finish-surgical-schedule/finish-surgical-schedule'

export class SurgicalScheduleRepository implements ISurgicalScheduleRepository {
  constructor(
    private readonly apiSurgicalScheduleRepository: IApiSurgicalScheduleRepository
  ) {}

  async loadSurgicalSchedules(
    params: LoadSurgicalSchedules.Params
  ): Promise<LoadSurgicalSchedules.Model> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadSurgicalSchedules.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: '/schedulings',
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadSurgicalSchedules.Model
    }
  }

  async loadEquipaments(
    params: LoadEquipaments.Params
  ): Promise<EquipamentsModel> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadEquipaments.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: '/equipaments/search',
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadEquipaments.Model
    }
  }

  async loadHospitalSurgeryCenter(
    params: LoadHospitalSurgeryCenter.Params
  ): Promise<HospitalSurgeryCenterModel> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadHospitalSurgeryCenter.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: `/hospitals/${params.hospital_id}/surgery-center`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadHospitalSurgeryCenter.Model
    }
  }

  async loadSurgicalSchedule(
    params: LoadSurgicalSchedule.Params
  ): Promise<LoadSurgicalSchedule.Model> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadSurgicalSchedule.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: `/schedulings/details`,
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadSurgicalSchedule.Model
    }
  }

  async loadSurgicalScheduleAvailableDays(
    params: LoadSurgicalScheduleAvailableDays.Params
  ): Promise<LoadSurgicalScheduleAvailableDays.Model> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadSurgicalScheduleAvailableDays.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: '/calendar/available-days',
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadSurgicalScheduleAvailableDays.Model
    }
  }

  async loadSurgeryCenterCalendar(
    params: LoadSurgeryCenterCalendar.Params
  ): Promise<LoadSurgeryCenterCalendar.Model> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<LoadSurgeryCenterCalendar.Model>

    const httpResponse = await apiSurgicalScheduleRepository.get({
      url: `/surgery-centers/${params.code}/calendar`,
      params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as LoadSurgeryCenterCalendar.Model
    }
  }

  async finishSurgicalSchedule(
    params: FinishSurgicalSchedule.Params
  ): Promise<FinishSurgicalSchedule.Model> {
    const apiSurgicalScheduleRepository = this
      .apiSurgicalScheduleRepository as IApiSurgicalScheduleRepository<FinishSurgicalSchedule.Model>

    const httpResponse = await apiSurgicalScheduleRepository.post({
      url: `/schedulings/finish`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw httpResponse.body as any
      // throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as unknown as FinishSurgicalSchedule.Model
    }
  }
}
