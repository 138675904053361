import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import ButtonNew from 'presentation/components/ButtonNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { useServices } from 'presentation/hooks/use-services'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { ServiceOverviewProps } from 'presentation/shared/components/ServiceOverview'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import * as S from './styles'
import { OpenScheduleSurgeryType } from 'common/enum/open-schedule-surgery-type'

type ReasonsFormProps = {
  surgicalOrder: ServiceOverviewProps
  reasonNotSurgery: ReasonNotSurgeryType
  setShowReasonAnswered: (value: boolean) => void
  setIsLoading: (value: boolean) => void
  openScheduledSurgeries: OpenScheduleSurgeryType[]
}

type ReasonNotSurgeryType = {
  type: string
  observation?: string
}

export const NeedHelpForm = ({
  reasonNotSurgery,
  setShowReasonAnswered,
  surgicalOrder,
  setIsLoading
}: ReasonsFormProps) => {
  const [showTextArea, setShowTextArea] = useState<boolean>(false)

  useEffect(() => {
    if (showTextArea) {
      window.scrollTo(0, 1000)
    }
  }, [showTextArea])

  const validationSchema = yup.object().shape({
    aligned_date: yup.boolean().optional(),
    need_help: yup.boolean().optional(),
    observation_to_need_help: yup
      .string()
      .max(800, 'Limite de caracteres excedido')
      .when('need_help', {
        is: true,
        then: yup.string().required()
      })
  })

  const surgicalOrderService = useServices().surgicalOrder

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      need_help: undefined,
      aligned_date: undefined,
      observation_to_need_help: undefined
    },
    onSubmit: async () => {
      try {
        setIsLoading(true)
        if (
          surgicalOrder.surgical_order_id &&
          typeof formik.values.aligned_date === 'boolean' &&
          typeof formik.values.need_help === 'boolean'
        ) {
          await surgicalOrderService.sendReasonNotSurgery({
            aligned_date: formik.values.aligned_date,
            need_help: formik.values.need_help,
            surgical_order_id: surgicalOrder.surgical_order_id,
            type: reasonNotSurgery.type,
            observation_to_need_help: formik.values.observation_to_need_help
              ? formik.values.observation_to_need_help
              : null
          })

          setShowReasonAnswered(true)
        }
      } catch (error) {
        toast.error('Falha ao enviar motivo')
      } finally {
        setIsLoading(false)
      }
    }
  })

  const handleChangeAlignDate = (value: boolean) => {
    formik.setFieldValue('aligned_date', value)
  }

  const handleChangeNeedHelp = (value: boolean) => {
    formik.setFieldValue('need_help', value)
    if (value) {
      setShowTextArea(true)
    } else {
      setShowTextArea(false)
    }
  }

  return (
    <>
      <ContainerNew
        form
        noPadding
        onSubmit={formik.handleSubmit}
        primaryButton={
          <ButtonNew
            color="primary"
            fullWidth
            type="submit"
            size="large"
            disabled={
              (!formik.values.observation_to_need_help &&
                formik.values.need_help === true) ||
              formik.values.aligned_date === undefined ||
              formik.values.need_help === undefined
            }
          >
            Próximo
          </ButtonNew>
        }
      >
        <S.BoxContent>
          <SupportTextNew weight="semiBold" color="secondary700" size="medium">
            Você já alinhou uma data com o médico? *
          </SupportTextNew>

          <S.LineRadio>
            <RadioButtonNew
              showBackground={false}
              name="aligned_date"
              labelFor={'Sim'}
              label={'Sim'}
              onCheck={() => handleChangeAlignDate(true)}
            />

            <RadioButtonNew
              showBackground={false}
              name="aligned_date"
              labelFor={'Não'}
              label={'Não'}
              onCheck={() => handleChangeAlignDate(true)}
            />
          </S.LineRadio>
        </S.BoxContent>

        <S.BoxContent>
          <SupportTextNew weight="semiBold" color="secondary700" size="medium">
            Você precisa de ajuda com o processo da cirurgia? *
          </SupportTextNew>

          <S.LineRadio>
            <RadioButtonNew
              showBackground={false}
              name="need_help"
              labelFor={'Sim '}
              label={'Sim '}
              onCheck={() => handleChangeNeedHelp(true)}
            />

            <RadioButtonNew
              showBackground={false}
              name="need_help"
              labelFor={'Não '}
              label={'Não '}
              onCheck={() => handleChangeNeedHelp(false)}
            />
          </S.LineRadio>

          {showTextArea && (
            <S.TextAreaWrapper>
              <TextAreaNew
                label="Descreva o problema aqui"
                required
                rows={4}
                name="observation_to_need_help"
                onInputChange={formik.handleChange('observation_to_need_help')}
                error={
                  formik.touched.observation_to_need_help
                    ? formik.errors.observation_to_need_help
                    : undefined
                }
                value={formik.values.observation_to_need_help}
                onBlur={formik.handleBlur('observation_to_need_help')}
              />
            </S.TextAreaWrapper>
          )}
        </S.BoxContent>
      </ContainerNew>
    </>
  )
}
