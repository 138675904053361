import React from 'react'
import * as S from './styles'

import ActualPage from 'presentation/components/ActualPage'
import { Container } from 'presentation/components/Container'
import Header from 'presentation/components/Header'
import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'
import { ReactComponent as LeftArrow } from 'presentation/assets/icons/left-arrow.svg'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/right-arrow.svg'
import { useHistory } from 'react-router'
import { getStatusInfo } from 'presentation/utils/service-status'
import normalizeText from 'common/utils/getNormalizedText'

export type SurgeryStatusDetailLayoutProps = {
  status: string
}

export default function SurgeryStatusDetailLayout({
  status
}: SurgeryStatusDetailLayoutProps) {
  const history = useHistory()
  const statusName = normalizeText(status.toLocaleLowerCase())

  return (
    <>
      <Header />
      <Container hasHeader style={{ overflow: 'hidden' }}>
        <ActualPage onClick={() => history.goBack()} text="Status" />
        <S.Marker>
          <S.Arrows>
            <LeftArrow />
            <RightArrow />
          </S.Arrows>
          <S.Dot color={getStatusInfo(status)?.color} />
          <hr />
        </S.Marker>
        <Heading
          size="medium"
          as="h1"
          style={{
            marginBottom: '31px',
            marginTop: '20px',
            textAlign: 'center'
          }}
        >
          {status}
        </Heading>
        <SupportText
          dangerouslySetInnerHTML={{ __html: texts[statusName] || loremIpsum }}
        />
      </Container>
    </>
  )
}

const texts: Record<string, string> = {
  'pedido em revisao':
    'A sua cirurgia começa a partir daqui! O pedido cirúrgico, também chamado de planejamento cirúrgico,' +
    'é fundamental e obrigatório para o sucesso de todo o processo de autorização e agendamento de cirurgias na Rede Mater Dei de Saúde.' +
    '<br/><br/>' +
    'O cadastro será realizado aqui, pelo seu médico, de forma simples e rápida.' +
    '<br/><br/>' +
    'Caso tenha alguma dúvida, gentileza utilizar o chat para comunicação direta com o seu médico.',
  'pedido cadastrado':
    'A sua cirurgia começa a partir daqui! O pedido cirúrgico, também chamado de planejamento cirúrgico,' +
    'é fundamental e obrigatório para o sucesso de todo o processo de autorização e agendamento de cirurgias na Rede Mater Dei de Saúde.' +
    '<br/><br/>' +
    'O cadastro será realizado aqui, pelo seu médico, de forma simples e rápida.' +
    '<br/><br/>' +
    'Caso tenha alguma dúvida, gentileza utilizar o chat para comunicação direta com o seu médico.',
  'em cotacao de opme':
    'Nesta etapa é realizada a cotação de valores de Órteses, Próteses e Materiais Especiais (OPME) que são indicadas no pedido ' +
    'cirúrgico. <br/> <br/> A necessidade de OPME é definida pelo médico e justificada no pedido cirúrgico.',
  'em revisao':
    'Nesta fase do processo de autorização da cirurgia onde o Hospital reúne toda a ' +
    'documentação do paciente para envio ao Convênio, como: orçamento correto, seguindo a orientação de três cotações, ' +
    'como indicado pela  Agência Nacional de Saúde Suplementar(ANS); pedido completo e justificativa do médico para a ' +
    'indicação da cirurgia e utilização de materiais, órteses e próteses.',
  'em analise na crmo':
    'Nesta etapa de autorização da cirurgia, a Mater Dei reúne toda a documentação enviada para repassar ao convênio, sendo ela: ' +
    'orçamento correto - seguindo a orientação de três cotações, como ' +
    'indicado pela Agência Nacional de Saúde Suplementar (ANS), ' +
    'pedido completo e justificativa do médico para a indicação da ' +
    'cirurgia e da utilização de Materiais, Órteses e Próteses (OPME).',
  'em analise no convenio':
    'Agora a solicitação de procedimento cirúrgico está em análise pelo seu convênio, que é responsável por autorizar a realização da cirurgia.' +
    '<br/><br/>' +
    'De acordo com a Agência Nacional de Saúde Suplementar, no caso de cirurgias eletivas, o convênio pode dar retorno a solicitação em até 21 dias úteis.' +
    '<br/><br/>' +
    'Assim que Rede Mater Dei de Saúde receber a resposta sobre a cirurgia, você receberá um aviso com os próximos passos para a realização do procedimento.',
  'cirurgia autorizada':
    'As mensagens sobre autorização ou negativa de cirurgia, bem como relatórios complementares, ' +
    'são solicitados pelo seu e-mail e também pelo do médico, seguindo o cadastro interno da Rede Mater Dei de Saúde. ' +
    '<br/><br/>' +
    'Por esse motivo, é importante que os seus dados cadastrais estejam corretos e sempre atualizados.' +
    '<br/><br/>' +
    'Após o convênio autorizar a realização do procedimento cirúrgico, está na hora de passar para a próxima etapa do processo: o agendamento da cirurgia.',
  'cirurgia agendada':
    'Essa etapa é realizada pelo seu médico, que deverá fazer o cadastro da sua cirurgia no Meu Mater Dei.' +
    '<br/><br/>' +
    'O procedimento cirúrgico será agendado rapidamente pelo profissional e você receberá uma notificação assim que a data da cirurgia for confirmada. ',
  'check-in':
    'O Check-in pode ser realizado pelo aplicativo ou na portaria principal do hospital, e é importante para avisar a equipe assistencial sobre a sua chegada para a cirurgia.' +
    '<br/><br/>' +
    'Esta é a maneira mais efetiva da Rede Mater Dei saber que você já chegou e que está preparado para iniciar o procedimento agendado. ' +
    '<br/><br/>' +
    'Importante: para realizar o Check-in, é necessário ter preenchido e enviado, em até 48 horas antes da internação, toda a documentação solicitada.',
  fastpass:
    'Para realizar o FastPass, é necessário ter preenchido e enviado, em até 48 horas antes da internação, toda a documentação solicitada.' +
    '<br/><br/>' +
    'Assim como o Check-in, o FastPass tem o objetivo de garantir que o seu procedimento será realizado no horário agendado, sem atrasos.',
  'paciente em cirurgia':
    'Neste momento você estará em procedimento cirúrgico.' +
    '<br/><br/>' +
    'O seu acompanhante poderá acompanhar, via Meu Mater Dei, toda a sua jornada cirúrgica e solicitar atualizações, em tempo real, diretamente ao anestesista que estará acompanhando o seu procedimento.',
  'paciente em recuperacao anestesica':
    'Após a finalização do procedimento, você estará em observação de pós-operatório. Assim que possível, será encaminhado ao apartamento para acompanhamento e posterior alta.' +
    '<br/><br/>' +
    'Nesta etapa o seu acompanhante também pode solicitar informações e notícias sobre o seu estado geral.',
  'alta do centro cirurgico':
    'Após o período de recuperação anestésica e observação, você será transferido para o quarto de' +
    ' internação ou receberá alta hospitalar, de acordo com o caso e o procedimento realizado.',
  'avaliacao dos servicos':
    'A sua percepção é muito importante para a Rede Mater Dei!' +
    '<br/><br/>' +
    'Nesta etapa você poderá avaliar como foi a sua jornada na Rede Mater Dei de Saúde, em cada passo para a realização do seu procedimento cirúrgico.' +
    '<br/><br/>' +
    'Neste momento, você poderá registrar suas observações sobre os serviços oferecidos e o atendimento realizado. A ideia é aprimorar, cada vez mais, as experiências dos pacientes na Mater Dei.'
}

const loremIpsum =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porttitor ac quam non ultrices. Quisque sed eros mollis, commodo lorem a, malesuada eros. Sed lacinia sem quis risus ullamcorper, non laoreet nisi laoreet. Ut vulputate faucibus enim vitae vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus eget sem non orci scelerisque lacinia sed at mauris. Quisque at nibh risus. Aliquam erat volutpat. Donec sit amet massa quis arcu accumsan vehicula eget vel arcu. Etiam rutrum consequat urna, in congue ex hendrerit at. Phasellus nec fringilla purus. Pellentesque fermentum fringilla libero sit amet pharetra.'
