import { Patient } from 'domain/entities/patient-model'
import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import Radio from 'presentation/shared/components/RadioButton'
import SheetModal from 'presentation/components/SheetModal'
import theme from 'presentation/styles/theme'
import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { useServices } from 'presentation/hooks/use-services'
import { EligibilityRequestOrigin } from 'common/enum/eligibility-request-origin'
import { toast } from 'react-toastify'
import { SuccessModal } from './SuccessModal'
import { ConfirmedAttendanceModal } from './ConfirmedAttendanceModal'
import { FailedModal } from './FailedModal'
import { NoticeModal } from './NoticeModal'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import { EditedInsurance } from '../../PatientSelectUnit'

type SelectHealthInsuranceProps = {
  patient: Patient
  onClose: () => void
  nextStep: (health_insurance_id?: number) => void
  open: boolean
  health_insurance_id?: number
  selectedCard?: string
  selectedHealthPlanCode?: string
  onEdit: () => void
  onAdd: () => void
  hospital_id?: number
  updatedHealthInsurance: boolean
  form?: SubmitEmergencyAttendance
  setForm: (data: SubmitEmergencyAttendance) => void
  editedInsurance: EditedInsurance
  setEditHealthInsurance: () => void
  setStep: (number: number) => void
}

export function SelectHealthInsurance({
  patient,
  onClose,
  open,
  nextStep,
  onEdit,
  onAdd,
  hospital_id,
  updatedHealthInsurance,
  form,
  setForm,
  editedInsurance,
  setEditHealthInsurance,
  setStep
}: SelectHealthInsuranceProps) {
  const [selectedHealthInsurance, setSelectedHealthInsurance] = useState<
    number | undefined
  >(patient?.healthInsurance?.healthInsuranceCode)
  const [card, setCard] = useState<string | undefined>(
    patient.lastHealthInsuranceUsed?.card ??
      patient?.healthInsurance?.healthCard
  )
  const [healthPlanCode, setHealthPlanCode] = useState<number | undefined>(
    patient.lastHealthInsuranceUsed?.healthPlanCode ??
      patient.healthInsurance?.healthPlanCode
  )
  const [handleSuccessModal, setHandleSuccessModal] = useState<boolean>(false)
  const [handleSelectModal, setHandleSelectModal] = useState<boolean>(false)
  const [handleAttendance, setHandleAttendance] = useState<boolean>(false)
  const [handleFailedAttendance, setHandleFailedAttendance] =
    useState<boolean>(false)
  const [handleNoticeModal, setHandleNoticeModal] = useState<boolean>(false)

  const eligibilityService = useServices().eligibility
  const authorizationService = useServices().authorization

  const hasLastHealthInsurance =
    patient?.lastHealthInsuranceUsed &&
    patient?.lastHealthInsuranceUsed?.card !==
      patient?.healthInsurance?.healthCard &&
    patient?.lastHealthInsuranceUsed?.card &&
    patient?.lastHealthInsuranceUsed?.healthPlanCode

  const hasHealthInsurance =
    patient.healthInsurance &&
    patient.healthInsurance.healthCard &&
    patient.healthInsurance.healthPlanCode

  useEffect(() => {
    updatedHealthInsurance === true
      ? setHandleSelectModal(updatedHealthInsurance)
      : setHandleSelectModal(
          hasLastHealthInsurance ?? hasHealthInsurance ? true : false
        )
  }, [updatedHealthInsurance])

  const checkInsuranceEligibility = async () => {
    if (card && healthPlanCode && hospital_id) {
      try {
        const eligible = await eligibilityService.eligibilityCheck(
          {
            eligibilityRequest: {
              card: card
            },
            requestHeader: {
              hospital_id: hospital_id,
              operatorCode: String(healthPlanCode),
              requestOrigin: EligibilityRequestOrigin.PS
            }
          },
          ['eligible', 'card']
        )
        if (eligible.eligible) {
          const authorized = await authorizationService.authorizationCheck(
            {
              authorizationRequest: {
                beneficiary: {
                  cart: card,
                  name: patient.name
                }
              },
              requestHeader: {
                hospital_id: hospital_id,
                operatorCode: String(healthPlanCode),
                requestOrigin: EligibilityRequestOrigin.PS
              }
            },
            [
              'status',
              'order_overmind_id',
              'operatorGuideNumber',
              'password',
              'passwordValidationDate'
            ]
          )

          if (authorized.status === 'AUTORIZADO') {
            setForm({
              ...form,
              authorization: {
                guideNumber: authorized.operatorGuideNumber,
                password: authorized.password,
                expirationDate: authorized.passwordValidationDate
              }
            })
            setHandleAttendance(true)
          } else {
            setHandleFailedAttendance(true)
          }
        } else {
          setHandleFailedAttendance(true)
        }
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  }

  const checkNewInsuranceEligibility = async () => {
    if (
      hospital_id &&
      updatedHealthInsurance &&
      editedInsurance.card &&
      editedInsurance.healthPlanCode
    ) {
      try {
        const eligible = await eligibilityService.eligibilityCheck(
          {
            eligibilityRequest: {
              card: editedInsurance.card
            },
            requestHeader: {
              hospital_id: hospital_id,
              operatorCode: String(editedInsurance.healthPlanCode),
              requestOrigin: EligibilityRequestOrigin.PS
            }
          },
          ['eligible', 'card']
        )
        if (eligible.eligible) {
          const authorized = await authorizationService.authorizationCheck(
            {
              authorizationRequest: {
                beneficiary: {
                  cart: editedInsurance.card,
                  name: patient.name
                }
              },
              requestHeader: {
                hospital_id: hospital_id,
                operatorCode: String(editedInsurance.healthPlanCode),
                requestOrigin: EligibilityRequestOrigin.PS
              }
            },
            [
              'status',
              'order_overmind_id',
              'operatorGuideNumber',
              'password',
              'passwordValidationDate'
            ]
          )

          if (authorized.status === 'AUTORIZADO') {
            setForm({
              ...form,
              authorization: {
                guideNumber: authorized.operatorGuideNumber,
                password: authorized.password,
                expirationDate: authorized.passwordValidationDate
              }
            })
            setHandleAttendance(true)
          } else {
            setHandleFailedAttendance(true)
          }
        } else {
          setHandleFailedAttendance(true)
        }
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  }

  const onConfirm = async () => {
    if (updatedHealthInsurance) {
      await checkNewInsuranceEligibility()
    } else if (selectedHealthInsurance) {
      await checkInsuranceEligibility()
    } else {
      nextStep()
    }
  }

  return hasLastHealthInsurance ?? hasHealthInsurance ? (
    <>
      {handleSelectModal && (
        <SheetModal size={550} isOpen={open} onClose={onClose} backdrop="true">
          <div style={{ marginBottom: '20px' }}>
            <Heading size="large">
              Com qual <strong>convênio</strong> deseja continuar?
            </Heading>
          </div>
          <Container
            noPadding
            primaryButton={
              <Button
                fullWidth
                color={theme.colors.primaryDarker}
                style={{ marginTop: '20px' }}
                disabled={false}
                onClick={() => {
                  onConfirm()
                  setHandleSelectModal(false)
                }}
              >
                Utilizar convênio
              </Button>
            }
            secondaryButton={
              <Button
                fullWidth
                style={{ marginTop: '20px' }}
                onClick={() => onEdit()}
                variant="outlined"
              >
                Editar convênio
              </Button>
            }
          >
            <S.EditPatientInfoWrapper>
              {hasLastHealthInsurance && (
                <S.RadioContent>
                  <Radio
                    role="radio"
                    label={
                      <div>
                        <strong>
                          {
                            patient?.lastHealthInsuranceUsed
                              ?.healthInsuranceName
                          }
                        </strong>
                        <label>
                          {patient?.lastHealthInsuranceUsed?.healthPlanName}
                        </label>
                        <label>{patient?.lastHealthInsuranceUsed?.card}</label>
                      </div>
                    }
                    value={
                      patient?.lastHealthInsuranceUsed?.healthInsuranceCode
                    }
                    onCheck={() => {
                      setSelectedHealthInsurance(
                        patient?.lastHealthInsuranceUsed?.healthInsuranceCode
                      )
                      setCard(patient?.lastHealthInsuranceUsed?.card)
                      setHealthPlanCode(
                        patient?.lastHealthInsuranceUsed?.healthPlanCode
                      )
                    }}
                    checked={
                      selectedHealthInsurance ===
                      patient?.lastHealthInsuranceUsed?.healthInsuranceCode
                    }
                  />
                </S.RadioContent>
              )}
              {hasHealthInsurance && (
                <S.RadioContent>
                  <Radio
                    role="radio"
                    label={
                      <div>
                        <strong>
                          {patient?.healthInsurance?.healthInsuranceName}
                        </strong>
                        <label>
                          {patient?.healthInsurance?.healthPlanName}
                        </label>
                        <label>{patient?.healthInsurance?.healthCard}</label>
                      </div>
                    }
                    value={patient?.healthInsurance?.healthInsuranceCode}
                    onCheck={() => {
                      setSelectedHealthInsurance(
                        patient?.healthInsurance?.healthInsuranceCode
                      )
                      setCard(patient?.healthInsurance?.healthCard)
                      setHealthPlanCode(
                        patient?.healthInsurance?.healthPlanCode
                      )
                    }}
                    checked={!!patient?.healthInsurance?.healthInsuranceCode}
                  />
                </S.RadioContent>
              )}
            </S.EditPatientInfoWrapper>
          </Container>
        </SheetModal>
      )}
      {handleSuccessModal && (
        <SuccessModal
          onClose={() => {
            setHandleSuccessModal(false)
            setHandleSelectModal(true)
          }}
          onConfirm={() => onConfirm()}
          open={handleSuccessModal}
        />
      )}
      {handleAttendance && (
        <ConfirmedAttendanceModal
          onClose={() => {
            setHandleSelectModal(true)
            setHandleAttendance(false)
          }}
          onConfirm={() => {
            if (selectedHealthInsurance) {
              nextStep(selectedHealthInsurance)
            }
            if (updatedHealthInsurance) {
              nextStep(patient?.healthInsurance?.healthInsuranceCode)
              setEditHealthInsurance()
              setStep(2)
            }
          }}
          open={handleAttendance}
          hospital_id={hospital_id}
        />
      )}
      {handleFailedAttendance && (
        <FailedModal
          onClose={() => {
            setHandleFailedAttendance(false)
            setHandleSelectModal(true)
            setHandleSuccessModal(false)
          }}
          onConfirm={() => {
            setHandleFailedAttendance(false)
            setHandleSelectModal(true)
            setHandleSuccessModal(false)
          }}
          open={handleFailedAttendance}
        />
      )}
      {handleNoticeModal && (
        <NoticeModal
          onClose={() => {
            setHandleAttendance(true)
            setHandleNoticeModal(false)
          }}
          onConfirm={() => {
            if (selectedHealthInsurance) {
              nextStep(selectedHealthInsurance)
            }
            if (updatedHealthInsurance) {
              nextStep(patient?.healthInsurance?.healthInsuranceCode)
              setEditHealthInsurance()
              setStep(2)
            }
          }}
          open={handleNoticeModal}
          hospital_id={hospital_id}
        />
      )}
    </>
  ) : (
    <SheetModal
      style={{ padding: 0 }}
      size={300}
      isOpen={open}
      onClose={onClose}
      backdrop="true"
    >
      <Heading size="large">
        Convênio não encontrado. Deseja adicionar um
        <strong> convênio?</strong>
      </Heading>
      <Button
        color={theme.colors.primaryDarker}
        style={{ marginTop: '20px' }}
        onClick={() => {
          onAdd()
        }}
      >
        Adicionar convênio
      </Button>
    </SheetModal>
  )
}
