import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.ds.colors.white};

  .form-wrapper {
    position: absolute;
    display: block;
    inset: 0;
    width: 100%;
    max-width: 600px;
    background-color: ${({ theme }) => theme.ds.colors.white};

    @media (min-width: 600px) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
  }
`
