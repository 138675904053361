import React from 'react'
import ButtonNew from 'presentation/components/ButtonNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import CardNew from 'presentation/shared/components/CardNew'
import CalendarNew from 'presentation/shared/components/CalendarNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { SchedulingSchedulesByMonth } from 'domain/entities/scheduling-schedule-by-month-model'
import moment from 'moment-timezone'
import { useFormik } from 'formik'
// import * as yup from 'yup'
import * as S from './styles'
import 'main/config/yup'

type Props = {
  availableDates?: SchedulingSchedulesByMonth[]
  availableHours?: SchedulingHours[] | any[]
  prev?: () => void
  dateInitial: string
  getSchedulesByMonth: (date: string) => void
  getHoursByDay: (date: string) => void
  submitSchedule: (time: any) => void
  schedulingType?: 'consult' | 'exams'
}

export default function CalendarForm({
  availableHours,
  availableDates,
  dateInitial,
  prev,
  getSchedulesByMonth,
  getHoursByDay,
  submitSchedule,
  schedulingType = 'consult'
}: Props) {
  const formik = useFormik({
    initialValues: {
      time: ''
    },
    validateOnMount: true,
    // validationSchema: validationSchema,
    onSubmit: ({ time }) => submitSchedule(Number(time))
  })

  const resetForm = () => {
    formik.resetForm()
    formik.validateForm()
  }

  const changeDay = (date: Date) => {
    const formatedDate = moment(date).format('YYYY-MM-DD')
    getHoursByDay(formatedDate)
    resetForm()
  }

  const changeMonth = (date: Date) => {
    const formatedDate = moment(date).format('YYYY-MM-DD')
    getSchedulesByMonth(formatedDate)
    resetForm()
  }

  const prevNextMonth = ({ view, action, activeStartDate }: any) => {
    if (view === 'month' && ['prev', 'next'].includes(action)) {
      const formatedDate = moment(activeStartDate).format('YYYY-MM-DD')
      getSchedulesByMonth(formatedDate)
      resetForm()
    }
  }

  const availableHoursItems = (schedulingType: string) => {
    let availableHoursArray: any[] | undefined

    if (schedulingType === 'consult') {
      availableHoursArray = availableHours?.map((time: SchedulingHours) => ({
        label: moment(time.date_begin).format('HH:mm'),
        value: time.hour_finish_id
      }))

      availableHoursArray?.unshift({
        label: 'Selecionar',
        value: 0
      })
    } else {
      availableHoursArray = availableHours?.map((item: any) => ({
        label: moment(item.date_begin).format('HH:mm'),
        value: item.cd_it_agenda_central
      }))

      availableHoursArray?.unshift({
        label: 'Selecionar',
        value: 0
      })
    }

    return availableHoursArray
  }

  const isDisabledDate = ({ date, view }: any) => {
    if (view === 'month') {
      const formatedDate = moment(date).format('YYYY-MM-DD')
      const mappedDates = availableDates?.map((date) =>
        moment(date.date_initial).format('YYYY-MM-DD')
      )

      return !mappedDates?.includes(formatedDate)
    }
    return false
  }

  return (
    <ContainerNew
      title="Escolher data"
      onSubmit={formik.handleSubmit}
      gap="20px"
      form
      noPadding
    >
      <CardNew background="neutral25">
        <CalendarNew
          onClickDay={changeDay}
          onClickMonth={changeMonth}
          onActiveStartDateChange={prevNextMonth}
          defaultActiveStartDate={new Date(dateInitial)}
          tileDisabled={isDisabledDate}
          minDate={new Date()}
        />
      </CardNew>
      <SelectFieldNew
        placeholder="Selecione o horário"
        label="Escolha o horário"
        items={availableHoursItems(schedulingType)}
        value={formik.values.time}
        onBlur={formik.handleBlur('time')}
        onInputChange={formik.handleChange('time')}
        disabled={
          !availableHoursItems(schedulingType)?.length ||
          availableHoursItems(schedulingType)?.length === 1
        }
        required
      />

      <S.ButtonPanel>
        <ButtonNew
          type="submit"
          size="large"
          // disabled={!formik.isValid}
          disabled={!formik.values.time}
          fullWidth
        >
          Próximo
        </ButtonNew>

        <ButtonNew type="button" onClick={prev} size="large" outlined fullWidth>
          Anterior
        </ButtonNew>
      </S.ButtonPanel>
    </ContainerNew>
  )
}

// const validationSchema = yup.object().shape({
//   time: yup.number().required() || yup.string().required()
// })
