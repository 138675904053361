import SharedProcedureResult from 'presentation/pages/SharedProcedureResult'
import React from 'react'
import { RemoteSharedProcedureResult } from 'service/usecases/shared-procedure-result/remote-shared-procedure-result'
import { makePatientRepository } from '../make-patient-repository-factory'

export const makeSharedExamResultPage = () => {
  const shareExamRepository = new RemoteSharedProcedureResult(
    makePatientRepository()
  )

  return <SharedProcedureResult shareExamResult={shareExamRepository} />
}
