import React from 'react'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import HealthInsuranceForm from 'presentation/components/Forms/HealthInsuranceForm'
import HeaderNew from 'presentation/components/HeaderNew'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  pageTitle: string
  primaryButtonText: string
  healthInsurance: PatientHealthInsurance
  availableHealthInsurances: HealthInsurancePlans[]
  addOrUpdateHealthInsurance: UpdatePatientHealthInsurance
  showHeader?: { showHeader: boolean }
  showAddHealthInsurance?: boolean
  setShowAddHealthInsurance?: (visible: boolean) => void
  noPadding?: boolean
}

type LocationType = {
  state: {
    prevPage?: string
  }
}

export default function HealthInsuranceLayout({
  pageTitle,
  primaryButtonText,
  healthInsurance,
  availableHealthInsurances,
  addOrUpdateHealthInsurance,
  showHeader,
  showAddHealthInsurance,
  setShowAddHealthInsurance,
  noPadding
}: Props) {
  const history = useHistory()
  const location: LocationType = useLocation()
  const prevPage = location.state && location.state.prevPage

  const backToPreviewsPage = () => {
    if (!!showAddHealthInsurance && setShowAddHealthInsurance) {
      setShowAddHealthInsurance(false)
      return
    } else {
      history.push(prevPage || '/convenios', {
        updateHealthInsurances: true
        // activeIndex: prevPage === '/agendamento/exames' ? 2 : null
      })
    }
  }

  return (
    <>
      {!!showHeader && (
        <HeaderNew
          actualPageTitle={pageTitle}
          actualPageOnClick={() => backToPreviewsPage()}
        />
      )}

      <HealthInsuranceForm
        // pageTitle={pageTitle}
        healthInsurance={healthInsurance}
        primaryButtonText={primaryButtonText}
        availableHealthInsurances={availableHealthInsurances}
        addOrUpdateHealthInsurance={addOrUpdateHealthInsurance}
        backToPreviewsPage={backToPreviewsPage}
        noPadding={noPadding}
      />
    </>
  )
}
