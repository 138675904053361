import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { LoadUserGroups } from 'domain/usecases/authentication/load-user-groups'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import { LoadHealthInsurance } from 'domain/usecases/health-insurance/load-health-insurance'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadHospitalsByDoctorCpf } from 'domain/usecases/hospital/load-hospitals-by-doctor-cpf'
import { GetAllHospitals } from 'domain/usecases/hospital/get-all-hospitals'
import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { LoadPatientSchedules } from 'domain/usecases/patient/load-patient-schedules'
import { LoadPatientSurgery } from 'domain/usecases/patient/load-patient-surgery'
import { LoadPatients } from 'domain/usecases/patient/load-patients'
import { LoadSchedules } from 'domain/usecases/schedule/load-schedules'
import { LoadSecretary } from 'domain/usecases/secretary/load-secretary'
import { LoadSecretaryRequestCount } from 'domain/usecases/secretary/load-secretary-request-count'
import { LoadSecretarySurgicalOrders } from 'domain/usecases/secretary/load-secretary-surgical-order'
import { LoadSurgeryCenter } from 'domain/usecases/surgery-center/load-surgery-center'
import { LoadPainAlerts } from 'domain/usecases/surgery/load-pain-alerts'
import { LoadSurgeryDetails } from 'domain/usecases/surgery/load-surgery-details'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { SearchSurgicalOrders } from 'domain/usecases/surgical-order/search-surgical-orders'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { LoadTuss } from 'domain/usecases/surgical-order/load-tuss'
import { LoadHealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { SearchHealthInsurances } from 'domain/usecases/health-insurance/search-health-insurances'
import { LoadAllHealthInsurancePlans } from 'domain/usecases/health-insurance/load-all-health-insurance'
import { RenewEmergencyToken } from 'domain/usecases/renew-emergency-token/renew-emergency-token'
import { CountSurgicalOrdersByStatus } from 'domain/usecases/surgical-order/count-surgical-orders-by-status'
import { LoadUser } from 'domain/usecases/user/load-user'
import { LoadCollaborator } from 'domain/usecases/collaborator/load-collaborator'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { LoadAllSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-all-surgical-order'
import { LoadDoctorRecommendations } from 'domain/usecases/doctor/load-doctor-recommendation'
import { LoadSpecialties } from 'domain/usecases/specialties/load-specialties'
import { EligibilityCheck } from 'domain/usecases/eligibility/eligibility-check'
import { LoadRecommendationByInviteToken } from 'domain/usecases/doctor/load-recommendation-by-invite-token'
import { SearchCollaborators } from 'domain/usecases/collaborator/search-collaborators'
import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { LoadPatientHideContact } from 'domain/usecases/patient/load-patient-hide-contact'
import { LoadExams } from 'domain/usecases/patient/load-exams'
import { LoadExamById } from 'domain/usecases/patient/loadExamById'
import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { LoadPatientPendencies } from 'domain/usecases/patient/load-patient-pendencies'
import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { LoadDoctorWithRecommendation } from 'domain/usecases/doctor/load-doctor-with-recommendation'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { LoadCurrentRegisterPendency } from 'domain/usecases/register-pendency/load-current-register-pendency'
import { ILoadUserContacts } from 'domain/usecases/user/load-contacts'
import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import { LoadSecretaryPendencies } from 'domain/usecases/secretary/load-secretary-pendencies'
import { LoadSecretarySurgicalPendenciesCount } from 'domain/usecases/secretary/load-secretary-surgical-pendencies-count'
import { LoadSecretaryRegisteredOrdersCount } from 'domain/usecases/secretary/load-secretary-registered-orders-count'
import { LoadMedicalTeams } from 'domain/usecases/medical-team/load-medical-teams'
import { LoadAllDoctorRegisters } from 'domain/usecases/doctor/load-all-doctor-registers'
import { LoadDoctorRegisterPendenciesGroupedDate } from '../../../domain/usecases/doctor/load-doctor-register-pendencies-grouped-date'
import { LoadEmergencyRoomByHospital } from 'domain/usecases/emergency-room/load-emergency-room-by-hospital'
import { GetEmergencyRoomByToken } from 'domain/usecases/emergency-room/get-emergency-room-by-token'
import { GetSecretaryByCpf } from 'domain/usecases/secretary/get-secretary-by-cpf'
import { GetAmbulatorySchedulePanelOrganized } from 'domain/usecases/ambulatory/get-ambulatory-panel-organized'
import { AuthorizationCheck } from 'domain/usecases/authorization/authorization'
import { CountScheduledAndUnscheduledSurgicalOrders } from 'domain/usecases/surgical-order/scheduled-and-unscheduled-counter'

export const loadPatientInfoQuery = (params: LoadPatientInfo.Params) => ({
  query: `query Patient($patient_id:Float){
    patient(patient_id:$patient_id) {${params.join(',')}}
  }
  `,
  name: 'patient'
})

export const loadPatientsQuery = (params: LoadPatients.Params) => ({
  query: `query Patients($name:String!, $pagination: PaginationArgs){
    patients(name:$name, pagination:  $pagination) {
      data {
        ${params?.params?.join(',')}
      }
      pageInfo {
        currentPage
        totalPages
        itemsPerPage,
        totalItems
      }
    }
  }
  `,
  name: 'patients'
})

export const searchPatientsQuery = (params: LoadPatients.Params) => ({
  query: `query PatientNameBirthday($name:String!, $bday:DateTime!, $pagination: PaginationArgs){
    patientNameBirthday(name:$name, bday: $bday, pagination: $pagination) {${params?.params?.join(
      ','
    )}}
  }
  `,
  name: 'patientNameBirthday'
})

export const loadPatientHideContactQuery = (
  params: LoadPatientHideContact.Params
) => ({
  query: `query GetPatientHidedData($cpf:String!){
    getPatientHidedData(patientCpf:$cpf) {${params?.params?.join(',')}}
  }
  `,
  name: 'getPatientHidedData'
})

export const loadPatientPossibleMotherNameQuery = () => ({
  query: `query GetPossiblesPatientMotherName($cpf:String!){
    getPossiblesPatientMotherName(patientCpf:$cpf)
  }
  `,
  name: 'getPossiblesPatientMotherName'
})

export const validateRegisteredPatientInDbQuery = {
  query: `
    query VerifyPatientRegisteredInDB($cpf: String!) {
      verifyPatientRegisteredInDB(patientCpf: $cpf)
    }
  `,
  name: 'verifyPatientRegisteredInDB'
}

export const checkPatientByEmailQuery = {
  query: `query HasUniqueEmail($email:String!) {
    hasUniqueEmail(email:$email) {
      unique
    }
  }`,
  name: 'hasUniqueEmail'
}

export const checkPatientByPhoneQuery = {
  query: `query HasUniquePhone($phone:String!) {
    hasUniquePhone(phone:$phone) {
      unique
    }
  }`,
  name: 'hasUniquePhone'
}

export const checkPatientByCpfQuery = {
  query: `query HasUniqueCpf($cpf:String!) {
    hasUniqueCpf(cpf:$cpf) {
      unique
    }
  }`,
  name: 'hasUniqueCpf'
}

export const loadHealthInsuranceQuery = (
  params: LoadHealthInsurance.Params
) => ({
  query: `query GetHealthInsurancePlans($search:SearchHealthInsurancePlan, $pagination: PaginationArgs){
    getHealthInsurancePlans(search:$search, pagination:$pagination) {${params.params?.join(
      ','
    )}}
  }
  `,
  name: 'getHealthInsurancePlans'
})

export const loadHealthInsurancePlansQuery = (
  params: LoadHealthInsurancePlans.Params
) => ({
  query: `query GetHealthInsurances($hospital_id: Float!){
    getHealthInsurances(hospital_id: $hospital_id) {${params.params?.join(',')}}
  }
  `,
  name: 'getHealthInsurances'
})

export const searchHealthInsurances = (
  params: SearchHealthInsurances.Params
) => ({
  query: `query SearchHealthInsurances($hospital_id: Float!) {
    searchHealthInsurances(hospital_id: $hospital_id) {
      ${params.params?.join(',')}
    }
  }`,
  name: 'searchHealthInsurances'
})

export const loadAllHealthInsurancePlansQuery = (
  params: LoadAllHealthInsurancePlans.Params
) => ({
  query: `query GetAllHealthInsurances {
    getAllHealthInsurances {${params.params?.join(',')}}
  }
  `,
  name: 'getAllHealthInsurances'
})

export const loadUserRoleQuery = (params: LoadUserRole.Params) => ({
  query: `{
    getRole {${params.join(',')}}
  }
  `,
  name: 'getRole'
})

export const loadUserGroupsQuery = (params: LoadUserGroups.Params) => ({
  query: `{
    getPermissions {${params.join(',')}}
  }
  `,
  name: 'getPermissions'
})

export const getDoctorByInviteTokenQuery = (
  token: string,
  params?: LoadDoctorByInviteToken.Params
) => ({
  query: `
    query GetDoctorByInviteToken($inviteToken:String!) {
      getDoctorByInviteToken(inviteToken:$inviteToken) {${params?.join(',')}}
    }
  `,
  name: 'getDoctorByInviteToken'
})

export const getDoctorQuery = (params?: LoadDoctor.Params) => ({
  query: `
    query Doctor {
      doctor {${params?.join(',')}}
    }
  `,
  name: 'doctor'
})

export const loadMedicalTeamsQuery = (params?: LoadMedicalTeams.Params) => ({
  query: `
    query GetDoctorMedicalTeam {
      getDoctorMedicalTeam {${params?.params?.join(',')}}
    }
  `,
  name: 'getDoctorMedicalTeam'
})

export const loadAllDoctorRegistersQuery = (
  params?: LoadAllDoctorRegisters.Params
) => ({
  query: `
    query GetAllDoctorRegisters($query: GetAllDoctorRegistersQueryInput, $pagination: PaginationArgs) {
      getAllDoctorRegisters(query: $query, pagination: $pagination) {
        ${params?.params?.join(',')}
      }
    }
  `,
  name: 'getAllDoctorRegisters'
})

export const getDoctorPendenciesQuery = (
  params?: LoadDoctorPendencies.Params['params']
) => ({
  query: `
    query Doctor($query: QuerySurgicalPendency, $pagination: PaginationArgs) {
      doctor {
        pendencies(query:$query, pagination: $pagination) {
          ${params?.join(',')}
        }
      }
    }
  `,
  name: 'doctor'
})

export const getPatientPendenciesQuery = (
  params?: LoadPatientPendencies.Params['params']
) => ({
  query: `
    query Patient($query: QuerySurgicalPendency, $pagination: PaginationArgs) {
      patient {
        pendencies(query:$query, pagination: $pagination) {
          ${params?.join(',')}
        }
      }
    }
  `,
  name: 'patient'
})

export const searchDoctorsQuery = (params?: SearchDoctors.Params) => ({
  query: `
    query loadDoctors($query:String, $pagination: PaginationArgs) {
      loadDoctors(query:$query, pagination: $pagination) {${params?.params?.join(
        ','
      )}}
    }
  `,
  name: 'loadDoctors'
})

export const getUserByInviteToken = (
  token: string,
  params?: LoadUserByInviteToken.Params
) => ({
  query: `
    query GetUserByInviteToken($inviteToken:String!) {
      getUserByInviteToken(inviteToken:$inviteToken) {${params?.join(',')}}
    }
  `,
  name: 'getUserByInviteToken'
})

export const verifyLoginClientQuery = () => ({
  query: `
    query VerifyClient($data:VerifyClient!){
      verifyClient(data:$data)
    }
  `,
  name: 'verifyClient'
})

export const loadHospitalsQuery = (params: LoadHospitals.Params) => ({
  query: `
    query LoadHospitals {
      loadHospitals {${params.join(',')}}
    }
  `,
  name: 'loadHospitals'
})

export const getAllHospitalsQuery = (params: GetAllHospitals.Params) => ({
  query: `
    query getAllHospitals {
      getAllHospitals {${params.join(',')}}
    }
  `,
  name: 'getAllHospitals'
})

export const loadHospitalsByDoctorCpfQuery = (
  params?: LoadHospitalsByDoctorCpf.Params
) => ({
  query: `
    query LoadHospitalsByDoctorCPF($cpf:String!) {
      loadHospitalsByDoctorCPF(cpf: $cpf) {${params?.join(',')}}
    }
  `,
  name: 'loadHospitalsByDoctorCPF'
})

export const loadExamsQuery = (params: LoadExams.Params) => ({
  query: `query loadExams{
    loadExams {
      ${params.join(',')}
    }
  }
  `,
  name: 'loadExams'
})

export const LoadExamQuery = (params?: LoadExamById.Params) => ({
  query: `query loadExam($data: ILoadExam!) {
    loadExam(data: $data) {
      ${params?.fields.join(',')}
    },
  }`,
  name: 'loadExam'
})

export const loadPatientDocumentsQuery = (
  params: LoadPatientDocuments.Params
) => ({
  query: `
    query GetAllPatientDocuments {
      getAllPatientDocuments {${params.join(',')}}
    }
  `,
  name: 'getAllPatientDocuments'
})

export const loadSurgeryDetailsQuery = (params: LoadSurgeryDetails.Params) => ({
  query: `
    query GetSurgeryById($surgeryId:Float!) {
      getSurgeryById(surgeryId: $surgeryId) {${params.fields.join(',')}}
    }
  `,
  name: 'getSurgeryById'
})

export const loadPainAlerts = (params?: LoadPainAlerts.Params) => ({
  query: `
    query PainAlerts {
      painAlerts {${params?.join(',')}}
    }
  `,
  name: 'painAlerts'
})

export const loadSurgeryCenter = (params: LoadSurgeryCenter.Params) => ({
  query: `
    query SurgeryCenter($hospital_id: Float!) {
      surgeryCenter(hospital_id: $hospital_id) {${params.fields.join(',')}}
    }
  `,
  name: 'surgeryCenter'
})

export const shareExamResultQuery = () => ({
  query: `query sendResultMail($data: SharePatientExam!) {
    sendResultMail(data: $data)
  }`,
  name: 'sendResultMail'
})

export const loadPatientSurgeryQuery = (params: LoadPatientSurgery.Params) => ({
  query: `
  query GetPatientSurgery {
    getPatientSurgery {${params.join(',')}}
  }`,
  name: 'getPatientSurgery'
})

export const getDoctorSurgeryRequestsCountQuery = () => ({
  query: `
    query DashboardDoctorSurgicalOrderCounts {
      dashboardDoctorSurgicalOrderCounts {
       scheduled { authorized, unauthorized, total },
       unscheduled { authorized, unauthorized, total },
       unfinished
      }
    }
  `,
  name: 'dashboardDoctorSurgicalOrderCounts'
})

export const getSecretarySurgeryRequestsCountQuery = (
  params?: LoadSecretaryRequestCount.Params
) => ({
  query: `
    query GetSecretarySurgeryRequestsCount {
      getSecretarySurgeryRequestsCount {${params?.join(',')}}
    }
  `,
  name: 'getSecretarySurgeryRequestsCount'
})

export const getSecretaryRegisteredOrdersCountQuery = (
  params?: LoadSecretaryRegisteredOrdersCount.Params
) => ({
  query: `
    query getSecretaryDoctorsSurgicalOrdersCount {
      getSecretaryDoctorsSurgicalOrdersCount {${params?.join(',')}}
    }
  `,
  name: 'getSecretaryDoctorsSurgicalOrdersCount'
})

export const getSecretarySurgicalOrders = (
  params: LoadSecretarySurgicalOrders.Params
) => ({
  query: `
    query ListSurgeryRequests (
      $pagination: PaginationArgs,
      $search: SearchSurgicalOrder
    ){
      secretary {
        surgicalOrders (
          pagination: $pagination
          search: $search
        ) {
          ${params.fields.join(',')}
        }
      }
    }
  `,
  name: 'secretary'
})

export const getSecretaryScheduleSurgicalOrders = (
  params: LoadSecretarySurgicalOrders.Params
) => ({
  query: `
    query ListScheduleSurgicalOrders (
      $pagination: PaginationArgs,
      $search: SearchSurgicalOrder,
      $orderStatus: LoadSecretaryScheduleSurgicalOrdersInput!
    ){
      secretary {
        scheduleSurgicalOrders (
          pagination: $pagination
          search: $search
          orderStatus: $orderStatus
        ) {
          ${params.fields.join(',')}
        }
      }
    }
  `,
  name: 'secretary'
})

export const getSecretary = (params?: LoadSecretary.Params) => ({
  query: `
    query Secretary {
      secretary {${params?.join(',')}}
    }
  `,
  name: 'secretary'
})

export const getPatientByCpf = (params?: LoadSecretary.Params) => ({
  query: `
    query GetPatientByCpf($cpf:String!) {
      getPatientByCpf(cpf: $cpf) {${params?.join(',')}}
    }
  `,
  name: 'getPatientByCpf'
})

export const getCidQuery = (params?: LoadCid.Params) => ({
  query: `
    query GetCids($pagination: PaginationArgs, $query: String) {
      getCids(pagination: $pagination, query:$query) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'getCids'
})

export const loadSurgeriesQuery = (params?: LoadCid.Params) => ({
  query: `
    query GetSurgeriesInfo($pagination: PaginationArgs, $name: String) {
      getSurgeriesInfo(pagination: $pagination, name:$name) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'getSurgeriesInfo'
})

export const getTussQuery = (params?: LoadTuss.Params) => ({
  query: `
    query GetTuss($pagination: PaginationArgs, $name: String,
      $code: String) {
      getTuss(pagination: $pagination, name:$name,
      code: $code) {${params?.fields.join(',')}}
    }
  `,
  name: 'getTuss'
})

export const loadSurgicalOrderQuery = (params?: LoadSurgicalOrder.Params) => ({
  query: `
  query SurgicalOrder($surgical_order_id: Float!) {
	  surgicalOrder(surgical_order_id:$surgical_order_id) {${params?.join(',')}}
  }
`,
  name: 'surgicalOrder'
})

export const loadSurgicalOrderStatusQuery = (
  params?: LoadSurgicalOrderStatus.Params
) => ({
  query: `
  query GetSurgeryStatus($surgical_order_id: Float!) {
	  getSurgeryStatus(surgical_order_id:$surgical_order_id) {${params?.fields?.join(
      ','
    )}}
  }
`,
  name: 'getSurgeryStatus'
})

export const searchSurgicalOrdersQuery = (
  params?: SearchSurgicalOrders.Params
) => ({
  query: `
  query SearchSurgicalOrder($pagination: PaginationArgs,$search: SearchSurgicalOrder!) {
	  searchSurgicalOrder(search:$search, pagination: $pagination) {${params?.params?.join(
      ','
    )}}
  }
`,
  name: 'searchSurgicalOrder'
})

export const getPatientSchedules = (params?: LoadPatientSchedules.Params) => ({
  query: `
    query GetPatientSchedules($pagination: PaginationArgs, $interval: IntervalArgs!) {
      ambulatorySchedulesCodePatient(pagination: $pagination, interval: $interval) {
        data {
          ${params?.join(',')}
        }
      }
    }
  `,
  name: 'ambulatorySchedulesCodePatient'
})

export const getSchedules = (params?: LoadSchedules.Params) => ({
  query: `
    query GetSchedules($interval: IntervalArgs!, $hospital_id: Float!, $pagination: PaginationArgs) {
      ambulatorySchedulesDate(interval: $interval, hospital_id: $hospital_id,pagination: $pagination) {
        data {
          ${params?.join(',')}
        }
      }
    }
  `,
  name: 'ambulatorySchedulesDate'
})

export const renewEmergencyTokenQuery = (
  params: RenewEmergencyToken.Params
) => ({
  query: `query RenewEmergencyRoomToken($emergency_room_token_id:Float!){
    renewEmergencyRoomToken(emergency_room_token_id:$emergency_room_token_id) {${params.join(
      ','
    )}}
  }
  `,
  name: 'renewEmergencyRoomToken'
})

export const countSurgicalOrdersByStatus = (
  params: CountSurgicalOrdersByStatus.Params
) => ({
  query: `
    query DashboardSurgicalOrderCounts($period: DateSelection) {
      dashboardSurgicalOrderCounts(period: $period) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'dashboardSurgicalOrderCounts'
})

export const getAllSurgicalOrdersByDoctor = (
  params: LoadAllSurgicalOrdersByDoctor.Params
) => ({
  query: `
    query getAllSurgicalOrdersByDoctor {
      getAllSurgicalOrdersByDoctor {
        ${params.fields.join(',')}
      }
    }
  `,
  name: 'getAllSurgicalOrdersByDoctor'
})

export const getSurgicalOrdersByDoctorAndStatus = (
  params: LoadSurgicalOrdersByDoctor.Params
) => ({
  query: `query LoadSurgicalOrdersByDoctor(
    $order_status: LoadSurgicalOrderByDoctor!
    $cancelled: Boolean
    $unfinished: Boolean
    $pagination: PaginationArgs
  ) {
    loadSurgicalOrdersByDoctorByScheduleAuthorized(
      order_status: $order_status
      cancelled: $cancelled
      unfinished: $unfinished
      pagination: $pagination
    ) {
      ${params.fields.join(',')}
    }
  }`,
  name: 'loadSurgicalOrdersByDoctorByScheduleAuthorized'
})

export const loadUserQuery = (params?: LoadUser.Params) => ({
  query: `
    query User {
      user {${params?.join(',')}}
    }
  `,
  name: 'user'
})

export const loadUserContactsQuery = (params?: ILoadUserContacts.Params) => ({
  query: `
  query loadAllContactsFromUser {
    loadAllContactsFromUser  {${params?.join(',')}}
  }
  `,
  name: 'loadAllContactsFromUser'
})

export const loadCollaboratorQuery = (params?: LoadCollaborator.Params) => ({
  query: `
    query GetCollaboratorByUserId {
      getCollaboratorByUserId {${params?.join(',')}}
    }
  `,
  name: 'getCollaboratorByUserId'
})

export const checkEligibility = (params?: EligibilityCheck.Params) => ({
  query: `
    query EligibilityCheck($data: EligibilityData!) {
        eligibility(data: $data) {
          ${params?.join(',')}
      }
    }
  `,
  name: 'eligibility'
})

export const loadDoctorRecommendations = (
  params: LoadDoctorRecommendations.Params
) => ({
  query: `query LoadDoctorRecommendations($initialDate: DateTime) {
    loadDoctorRecommendations(initialDate: $initialDate) {
        ${params.join(',')}
    }
  }`,
  name: 'loadDoctorRecommendations'
})

export const loadSpecialties = (params: LoadSpecialties.Params) => ({
  query: `
    query loadSpecialties($query:String!, $pagination: PaginationArgs) {
      loadSpecialties(query:$query, pagination: $pagination) {
        data {
          ${params?.fields?.join(',')}
        }
        pageInfo {
          currentPage
          itemsPerPage
          totalItems
          totalPages
        }
      }
    }
  `,
  name: 'loadSpecialties'
})

export const loadRecommendationByInviteTokenQuery = (
  params: LoadRecommendationByInviteToken.Params
) => ({
  query: `
    query loadRecommendationByInviteToken($inviteToken:String!) {
      loadRecommendationByInviteToken(inviteToken:$inviteToken) {${params?.join(
        ','
      )}}
    }
  `,
  name: 'loadRecommendationByInviteToken'
})

export const searchCollaboratorsQuery = (
  params?: SearchCollaborators.Params
) => ({
  query: `
    query GetAllCollaborators($name:String, $pagination: PaginationArgs) {
      getAllCollaborators(name:$name, pagination: $pagination) {${params?.params?.join(
        ','
      )}
      }
    }
  `,
  name: 'getAllCollaborators'
})

export const getDistrictsByDescription = () => ({
  query: `query getDistrictsByDescription($description: String!) {
    getDistrictsByDescription(description: $description) {
      name,
      district_id,
      uf
    }
  }`,
  name: 'getDistrictsByDescription'
})

export const getCitiesFromDistrict = () => ({
  query: `query getCitiesFromDistrict($uf: String!, $pagination: PaginationArgs) {
    getCitiesFromDistrict(uf: $uf, pagination:  $pagination) {
      data {
        city_id,
        name,
      }
      pageInfo {
          currentPage
          totalPages
          itemsPerPage,
          totalItems
        }
    }
  }`,
  name: 'getCitiesFromDistrict'
})

export const loadUserAddress = () => ({
  query: `query loadUserAddress {
    loadUserAddress  {
      address_id,
      zipCode,
      district,
      city,
      neighborhood,
      street,
      number,
      complement
    }
  }`,
  name: 'loadUserAddress'
})

export const getExternalCertifications = (
  params?: LoadDocumentExternalCertifications.Params
) => ({
  query: `
    query getExternalCertifications($doctor_id: Float!) {
      getExternalCertifications(doctor_id: $doctor_id) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'getExternalCertifications'
})

export const loadRecommendedDoctorsQuery = (
  params: LoadRecommendedDoctors.Params
) => ({
  query: `
    query getRecommendedDoctors($query: loadRecommendedDoctorsQuery!, $pagination: PaginationArgs) {
      getRecommendedDoctors(query: $query, pagination: $pagination) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'getRecommendedDoctors'
})

export const getDoctorWithRecommendationQuery = (
  params: LoadDoctorWithRecommendation.Params
) => ({
  query: `
    query loadDoctorWithRecommendation($doctor_id: Float!, $loadDocuments: Boolean) {
      loadDoctorWithRecommendation(doctor_id: $doctor_id, loadDocuments: $loadDocuments) {${params.fields.join(
        ','
      )}}
    }
  `,
  name: 'loadDoctorWithRecommendation'
})

export const loadFavoriteSurgicalOrdersQuery = (
  params: LoadFavoriteSurgicalOrders.Params
) => ({
  query: `
    query ListFavoriteSurgicalOrder {
	    listFavoriteSurgicalOrder {${params.join(',')}}
  }
`,
  name: 'listFavoriteSurgicalOrder'
})

export const loadFavoriteDoctorOrdersQuery = (
  params: LoadFavoriteSurgicalOrders.Params
) => ({
  query: `
    query doctorFavoriteSurgicalOrders {
      doctorFavoriteSurgicalOrders {${params.join(',')}}
    }
  `,
  name: 'doctorFavoriteSurgicalOrders'
})

export const loadDoctorInfoQuery = (params: LoadDoctorInfo.Params) => ({
  query: `query GetDoctorById($doctor_id:Float!){
    getDoctorByID(doctor_id:$doctor_id) {${params.join(',')}}
  }
  `,
  name: 'getDoctorByID'
})

export const getProtocolsQuery = (params?: LoadCid.Params) => ({
  query: `
    query GetProtocols($pagination: PaginationArgs, $query: String) {
      loadProtocols(pagination: $pagination, query:$query) {${params?.fields.join(
        ','
      )}}
    }
  `,
  name: 'loadProtocols'
})

export const loadDoctorOverallStatusForReview = (
  params: LoadDoctorWithRecommendation.Params
) => ({
  query: `
    query FindDoctorOverallStatusForResume($doctor_id: Float!) {
      findDoctorOverallStatusForResume(doctor_id: $doctor_id) {${params.fields.join(
        ','
      )}}
    }
  `,
  name: 'findDoctorOverallStatusForResume'
})

export const loadDoctorCurrentPendencyQuery = (
  params: LoadCurrentRegisterPendency.Params
) => ({
  query: `
    query LoadDoctorCurrentPendency($doctor_id: Float!) {
      loadDoctorCurrentPendency(doctor_id: $doctor_id) {${params.fields.join(
        ','
      )}}
    }
  `,
  name: 'loadDoctorCurrentPendency'
})

export const getPatientNextAttendancesQuery = (
  params: GetPatientNextAttendances.Params
) => ({
  query: `
    query GetPatientNextAttendances($limit: Float) {
      getPatientNextAttendances(limit: $limit) {${params.fields.join(',')}}
    }
  `,
  name: 'getPatientNextAttendances'
})

export const loadSecretaryPendenciesQuery = (
  fields?: LoadSecretaryPendencies.Params['fields']
) => ({
  query: `
    query LoadSecretaryPendencies(
      $pagination: PaginationArgs,
      $doctorName: String,
      $patientName: String,
      $surgicalOrderId: Float,
      $type: PendencyType
    ) {
      getTotalPendency(data: {
        surgicalOrderId: $surgicalOrderId
        doctor_name: $doctorName
        patient_name: $patientName
        type: $type
        paginatedArgs: $pagination
      }) {
        ${fields?.join(',')}
      }
    }
  `,
  name: 'getTotalPendency'
})

export const getSecretarySurgicalPendenciesCountQuery = (
  params?: LoadSecretarySurgicalPendenciesCount.Params
) => ({
  query: `
    query GetSecretarySurgicalPendenciesCount {
      getTotalPendencyPerType {${params?.join(',')}}
    }
  `,
  name: 'getTotalPendencyPerType'
})

export const getDoctorRegisterPendenciesGroupedByDataQuery = (
  params?: LoadDoctorRegisterPendenciesGroupedDate.Params['params']
) => ({
  query: `
    query GetDoctorRegisterById($doctor_register_id: Float!, $isSolved: Boolean!) {
      getDoctorRegisterById(doctor_register_id:$doctor_register_id) {
        pendenciesGroupedDate(isSolved:$isSolved) {
          ${params?.join(',')}
        }
      }
    }
  `,
  name: 'getDoctorRegisterById'
})

export const loadEmergencyRoomByHospitalQuery = (
  fields: LoadEmergencyRoomByHospital.Params
) => ({
  query: `{
    getEmergencyRoomAttendanceByHospital {${fields.join(',')}}
  }
  `,
  name: 'getEmergencyRoomAttendanceByHospital'
})

export const getEmergencyRoomByTokenQuery = (
  fields: GetEmergencyRoomByToken.Params['fields']
) => ({
  query: `
    query GetEmergencyRoomAttendanceByToken($token: String!) {
      getEmergencyRoomAttendanceByToken (token: $token) {${fields.join(',')}}
    }
  `,
  name: 'getEmergencyRoomAttendanceByToken'
})

export const loadReligionsQuery = {
  query: `
    query LoadReligions {
      getReligions {
        religion_id
        name
      }
    }
  `,
  name: 'getReligions'
}

export const vueMotionCheckForExamsQuery = () => ({
  query: `
    query VueMotionCheckForExams($accession_number: Float!) {
      vueMotionCheckForExams(accession_number: $accession_number)
    }
  `,
  name: 'vueMotionCheckForExams'
})

export const userAlreadyRegistered = () => ({
  query: `
    query UserAlreadyRegistered($cpf: String!, $role: String!) {
      userAlreadyRegistered(cpf: $cpf, role: $role)
    }
  `,
  name: 'userAlreadyRegistered'
})

export const getSecretaryByCpf = (
  fields: GetSecretaryByCpf.Params['fields']
) => ({
  query: `
    query GetSecretaryByCpf($cpf: String!) {
      getSecretaryByCpf(cpf: $cpf){
        ${fields.join(',')}
      }
    }
  `,
  name: 'getSecretaryByCpf'
})

export const getAmbulatorySchedulePanelOrganizedQuery = (
  fields: GetAmbulatorySchedulePanelOrganized.Params
) => ({
  query: `
    query GetAmbulatorySchedulePanelOrganized {
      getAmbulatorySchedulePanelOrganized {${fields.join(',')}}
    }
  `,
  name: 'getAmbulatorySchedulePanelOrganized'
})

export const checkAuthorization = (params?: AuthorizationCheck.Params) => ({
  query: `
    query authorization($data: AuthorizationData!) {
      authorization(data: $data) {
        ${params?.join(',')}
      }
    }
  `,
  name: 'authorization'
})

export const countScheduledAndUnscheduledSurgicalOrders = (
  params: CountScheduledAndUnscheduledSurgicalOrders.Params
) => ({
  query: `
    query ScheduledAndUnscheduledSurgicalOrderCounts($period: DateSelection) {
      scheduledAndUnscheduledSurgicalOrderCount(period: $period) {
        ${params?.fields.join(',')}
      }
    }
  `,
  name: 'scheduledAndUnscheduledSurgicalOrderCount'
})
