import React from 'react'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

import { Gender } from 'common/enum/gender'
import TextFieldNew from 'presentation/components/TextFieldNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import { dateMask, phoneMask, cpfMask } from 'presentation/utils/masks'
import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import Modal from 'presentation/shared/components/Modal'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useStores } from 'presentation/hooks/use-stores'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import * as S from './styles'
import ButtonNew from 'presentation/components/ButtonNew'
import { useServices } from 'presentation/hooks/use-services'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import { ContainerNew } from 'presentation/components/ContainerNew'

type Props = {
  patient?: Patient
} & WithLoadingProps

const SchedulingUpdatePersonalInfoForm = WithLoading(
  ({ patient, setIsLoading }: Props) => {
    const patientService = useServices().patient
    const [successModal, setSuccessModal] = useState<boolean>(false)
    const history = useHistory()
    const currentAccount = useStores().currentAccount
    const user = currentAccount.getCurrentAccount().user
    const dependents = getFullDependentsList(user)
    const [personalData, setPersonalData] = useState<SchedulingPatient>()

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        ...patient,
        birthday: patient?.birthday
          ? moment(patient?.birthday).utc().format('DD/MM/YYYY')
          : ''
      } as PatientInfoFormValues,
      onSubmit: async (values) => {
        const updatePatientData = {
          ...values,
          phone: values.phone?.replace(/\D+/g, ''),
          birthday: moment(values.birthday, 'DD/MM/YYYY').utc().toISOString()
        } as PatientInfoFormValues

        const schedulingPatientData = {
          patient_id: patient?.patient_id,
          user_id: patient?.user_id,
          name: patient?.name,
          age:
            Number(
              getAgeByBirthdayDate(
                moment(patient?.birthday).format('DD/MM/YYYY')
              )
            ) || 0,
          gender: patient?.gender,
          email: patient?.email,
          phone: patient?.phone,
          healthInsurances: patient?.healthInsurances
        } as SchedulingPatient

        try {
          setIsLoading(true)
          delete updatePatientData.cpf
          delete updatePatientData.healthInsurances
          delete updatePatientData.patient_id
          delete updatePatientData.user_id
          await patientService.updatePatientInfo(updatePatientData)
          setPersonalData(schedulingPatientData)
          setSuccessModal(true)
        } catch (error: any) {
          toast.error(error.message)
        } finally {
          setIsLoading(false)
        }
      }
    })

    const hasDependents = () => {
      return dependents.length > 0
    }

    const handleChangeDependent = (user_id: number) => {
      if (user_id !== user.user_id) {
        setIsLoading(true)
        currentAccount
          .setCurrentDependent(user_id)
          .then(() => {
            window.location.reload()
          })
          .catch((e: any) => {
            console.log(e.message)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }

    return (
      <>
        <ContainerNew
          title="Confirme os dados cadastrais"
          primaryButton={
            <ButtonNew
              color="primary"
              fullWidth
              type="submit"
              size="large"
              disabled={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Confirmar cadastro
            </ButtonNew>
          }
        >
          <S.FormWrapper onSubmit={formik.handleSubmit}>
            {hasDependents() && (
              <S.Row cols={1} style={{ marginTop: '8px' }}>
                <SelectFieldNew
                  label="Para quem é a consulta?"
                  name="current_user"
                  items={dependents?.map(({ name, user_id }) => ({
                    label: name,
                    value: user_id
                  }))}
                  value={user.user_id}
                  onInputChange={(e) => handleChangeDependent(Number(e))}
                />
              </S.Row>
            )}
            <S.Row
              cols={1}
              style={{ marginTop: hasDependents() ? '24px' : '8px' }}
            >
              <TextFieldNew
                label="Nome completo"
                name="name"
                value={formik.values.name}
                onInputChange={formik.handleChange('name')}
                disabled={true}
              />
            </S.Row>
            <S.Row cols={2}>
              <TextFieldNew
                label="Nascimento"
                name="birthday"
                mask={dateMask}
                value={formik.values.birthday}
                onInputChange={formik.handleChange('birthday')}
                disabled={true}
              />
              <SelectFieldNew
                label="Sexo"
                name="gender"
                value={formik.values.gender}
                items={[
                  { label: 'Masculino', value: Gender.MALE },
                  { label: 'Feminino', value: Gender.FEMALE }
                ]}
                onInputChange={formik.handleChange('gender')}
              />
            </S.Row>
            <S.Row cols={1}>
              <TextFieldNew
                label="CPF"
                name="cpf"
                mask={cpfMask}
                value={formik.values.cpf}
                disabled={true}
              />
            </S.Row>
            <S.Row cols={1}>
              <TextFieldNew
                label="Telefone"
                name="phone"
                mask={phoneMask}
                value={formik.values.phone}
                onInputChange={formik.handleChange('phone')}
              />
            </S.Row>
            <S.Row cols={1}>
              <TextFieldNew
                label="E-mail"
                name="email"
                value={formik.values.email}
                onInputChange={formik.handleChange('email')}
              />
            </S.Row>
          </S.FormWrapper>
        </ContainerNew>
        <Modal
          title="Dados atualizados com sucesso"
          show={successModal}
          closeTimeout={700}
          close={() =>
            history.push('/agendamento/consulta/convenios', {
              patient: personalData
            })
          }
        />
      </>
    )
  }
)

type PatientInfoFormValues = {
  patient_id?: number | undefined
  user_id?: number | undefined
  name: string
  birthday?: string
  age: number | undefined
  gender: string
  email: string
  cpf?: string
  phone: string
  healthInsurances?: PatientHealthInsurance[]
}

export default SchedulingUpdatePersonalInfoForm
