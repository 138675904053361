import React, { HTMLAttributes } from 'react'

import * as S from './styles'

export type LogoProps = {
  size?: 'normal' | 'large' | 'huge'
} & HTMLAttributes<HTMLDivElement>

const Logo = ({ size = 'normal', ...props }: LogoProps) => (
  <S.Wrapper size={size} {...props}>
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1784.000000 676.000000"
      preserveAspectRatio="xMidYMid meet"
      role="img"
    >
      <g
        transform="translate(0.000000,676.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          className="cls-1"
          d="M3319 6735 c-193 -31 -337 -78 -520 -172 -469 -239 -807 -709 -883
-1226 -14 -89 -16 -244 -16 -1016 l0 -911 3373 0 c2240 0 3402 4 3462 10 428
49 828 259 1091 572 194 232 312 473 375 768 31 141 34 474 6 615 -46 235
-154 491 -285 675 -86 121 -270 306 -392 393 -175 124 -408 226 -637 279 l-98
22 -2695 2 c-2215 2 -2710 0 -2781 -11z m5523 -273 c405 -93 728 -326 932
-672 316 -534 237 -1221 -192 -1671 -209 -220 -471 -363 -782 -426 -79 -16
-310 -17 -3362 -20 l-3278 -3 0 757 c0 807 3 857 51 1033 40 150 136 341 234
471 27 35 89 105 139 154 218 216 504 354 816 394 36 5 1253 8 2705 7 l2640
-2 97 -22z"
        />
        <path
          className="cls-1"
          d="M6284 5840 c-231 -25 -420 -148 -537 -350 -37 -65 -84 -211 -98 -309
-16 -112 -6 -313 20 -411 11 -41 41 -117 66 -168 78 -156 218 -280 382 -337
100 -35 203 -48 323 -42 265 14 483 149 601 370 17 32 27 62 23 67 -5 4 -63
25 -130 47 l-121 40 -31 -52 c-36 -60 -117 -137 -174 -166 -159 -81 -390 -60
-518 48 -74 62 -138 194 -155 321 l-7 52 581 0 581 0 6 25 c10 39 -13 261 -36
349 -95 361 -390 557 -776 516z m261 -265 c136 -45 234 -179 260 -357 l7 -48
-436 0 -436 0 0 23 c0 36 29 126 60 187 53 102 163 185 282 210 60 13 203 5
263 -15z"
        />
        <path
          className="cls-1"
          d="M3816 5824 c-73 -18 -163 -65 -221 -116 l-45 -40 0 66 0 66 -125 0
-125 0 0 -770 0 -770 135 0 135 0 0 488 c0 268 5 518 10 555 19 129 85 223
189 270 64 29 188 29 251 0 89 -40 145 -105 174 -203 14 -46 16 -129 16 -582
l0 -528 135 0 135 0 0 498 c0 299 4 523 11 562 27 170 151 275 325 274 88 0
165 -34 223 -100 77 -88 75 -71 79 -686 l3 -548 134 0 135 0 0 568 c0 536 -1
573 -20 647 -24 93 -56 151 -122 221 -90 96 -216 144 -373 144 -168 0 -282
-45 -395 -158 -47 -47 -76 -69 -79 -61 -2 8 -29 39 -58 70 -61 64 -142 111
-228 134 -73 19 -219 18 -299 -1z"
        />
        <path
          className="cls-1"
          d="M7362 5288 c4 -492 5 -516 26 -593 89 -323 309 -490 623 -472 165 10
295 60 399 154 l60 55 0 -86 0 -86 120 0 120 0 0 770 0 770 -135 0 -135 0 0
-452 c0 -497 -4 -536 -59 -654 -83 -178 -292 -258 -494 -188 -69 23 -150 102
-187 180 -59 124 -63 155 -67 657 l-5 457 -134 0 -135 0 3 -512z"
        />
        <path
          className="cls-1"
          d="M17510 2545 l0 -165 160 0 160 0 0 165 0 165 -160 0 -160 0 0 -165z"
        />
        <path
          className="cls-1"
          d="M830 2275 l0 -405 -410 0 -410 0 0 -495 0 -495 410 0 410 0 0 -405 0
-405 510 0 510 0 0 405 0 405 410 0 410 0 0 495 0 495 -410 0 -410 0 0 405 0
405 -510 0 -510 0 0 -405z m720 -300 l0 -405 410 0 410 0 0 -195 0 -195 -410
0 -410 0 0 -405 0 -405 -205 0 -205 0 0 405 0 405 -415 0 -415 0 0 195 0 195
415 0 415 0 0 405 0 405 205 0 205 0 0 -405z"
        />
        <path
          className="cls-1"
          d="M3310 1375 l0 -1305 155 0 155 0 2 947 3 947 441 -944 441 -945 88 0
89 0 440 945 441 945 3 -948 2 -947 155 0 155 0 0 1305 0 1306 -151 -3 -151
-3 -469 -995 c-259 -547 -479 -1015 -490 -1040 -15 -35 -22 -41 -29 -30 -5 8
-229 477 -497 1043 l-487 1027 -148 0 -148 0 0 -1305z"
        />
        <path
          className="cls-1"
          d="M13000 1375 l0 -1305 513 0 c312 0 547 4 602 11 235 29 426 119 580
274 189 191 305 464 346 810 15 124 6 408 -15 530 -55 305 -170 540 -353 716
-125 121 -242 186 -408 228 -143 35 -245 41 -762 41 l-503 0 0 -1305z m1119
980 c222 -49 368 -167 471 -380 84 -174 120 -351 120 -600 0 -517 -190 -866
-525 -964 -105 -31 -246 -41 -566 -41 l-289 0 0 1006 0 1007 358 -6 c280 -4
373 -9 431 -22z"
        />
        <path
          className="cls-1"
          d="M8402 1513 l3 -1058 27 -73 c52 -142 121 -222 242 -277 109 -50 193
-65 358 -64 79 1 172 6 208 13 l65 11 3 139 3 138 -24 -6 c-57 -14 -246 -18
-311 -7 -87 15 -132 38 -180 91 -74 83 -71 45 -74 728 l-3 612 296 0 295 0 0
135 0 135 -295 0 -295 0 0 270 0 270 -160 0 -161 0 3 -1057z"
        />
        <path
          className="cls-1"
          d="M6958 2066 c-198 -35 -351 -120 -465 -257 -47 -57 -118 -193 -129
-246 l-6 -31 88 -27 c49 -15 116 -35 149 -46 32 -10 60 -17 60 -16 1 1 9 21
18 45 55 140 174 240 328 277 82 19 255 19 337 0 116 -28 202 -88 247 -175 23
-45 55 -176 55 -226 0 -52 21 -45 -235 -79 -633 -85 -830 -147 -984 -310 -149
-157 -183 -421 -83 -630 93 -191 262 -300 509 -327 194 -21 426 23 572 109 75
45 175 134 215 192 16 23 33 41 37 41 5 0 9 -65 9 -145 l0 -145 141 0 140 0
-3 723 c-4 703 -5 724 -26 800 -66 239 -222 387 -480 454 -121 31 -370 41
-494 19z m679 -1128 c-14 -268 -96 -439 -265 -552 -170 -113 -445 -141 -602
-61 -106 54 -170 158 -170 278 0 165 134 281 394 342 61 15 230 44 376 65 146
21 267 38 269 39 2 1 1 -50 -2 -111z"
        />
        <path
          className="cls-1"
          d="M10225 2070 c-393 -61 -674 -357 -751 -790 -23 -126 -23 -364 0 -481
77 -407 335 -683 711 -765 121 -26 344 -24 458 4 181 45 325 126 447 252 63
65 155 196 175 250 4 12 -27 26 -141 65 -80 28 -150 51 -154 53 -4 2 -22 -23
-40 -55 -70 -123 -219 -234 -361 -268 -85 -20 -264 -20 -343 1 -156 40 -276
141 -348 290 -38 79 -78 233 -78 302 l0 32 751 0 752 0 -6 163 c-7 179 -26
282 -76 413 -103 266 -300 445 -566 515 -92 24 -329 34 -430 19z m335 -292
c197 -36 341 -198 390 -438 11 -52 20 -103 20 -112 0 -17 -34 -18 -579 -18
-319 0 -582 4 -585 8 -11 18 37 191 74 269 119 244 365 350 680 291z"
        />
        <path
          className="cls-1"
          d="M16065 2070 c-369 -58 -641 -322 -735 -713 -74 -309 -32 -664 107
-901 115 -197 310 -348 523 -405 357 -96 724 -9 961 228 65 65 147 179 174
243 l13 33 -151 52 c-84 29 -153 53 -154 53 0 0 -15 -25 -32 -56 -51 -89 -146
-176 -243 -222 -104 -50 -161 -62 -296 -62 -182 0 -294 42 -404 151 -51 51
-78 87 -108 151 -40 82 -80 235 -80 306 l0 32 750 0 750 0 0 116 c0 505 -240
868 -645 975 -92 24 -329 34 -430 19z m335 -292 c217 -40 364 -229 406 -521
l6 -47 -583 2 -584 3 3 35 c2 19 12 69 23 110 88 329 363 487 729 418z"
        />
        <path
          className="cls-1"
          d="M12361 2025 c-35 -8 -85 -21 -112 -30 -100 -33 -233 -139 -295 -236
l-29 -44 -3 158 -3 157 -139 0 -140 0 0 -980 0 -980 159 0 160 0 4 582 c4 648
5 654 72 796 66 139 170 232 307 273 57 18 88 21 188 18 l120 -4 0 146 0 146
-37 7 c-62 10 -184 5 -252 -9z"
        />
        <path
          className="cls-1"
          d="M17510 1050 l0 -980 160 0 160 0 0 980 0 980 -160 0 -160 0 0 -980z"
        />
      </g>
    </svg>
  </S.Wrapper>
)

export default Logo
