import React from 'react'

import ServiceSolicitationForm from 'presentation/components/Forms/ServiceSolicitation'
import { Header } from 'presentation/components/Header'
import { Container } from 'presentation/components/Container'
import { AddHospitalizationService } from 'domain/usecases/hospitalization/add-hospitalization-service'

type Props = {
  addHospitalizationService?: AddHospitalizationService
  surgical_order_id: number
}

export default function ServiceSolicitationLayout({
  addHospitalizationService,
  surgical_order_id
}: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <ServiceSolicitationForm
          addHospitalizationService={addHospitalizationService}
          surgical_order_id={surgical_order_id}
        />
      </Container>
    </>
  )
}
