import { ClassProps } from 'common/utils/getClassProps'
import { Type } from 'class-transformer'
import { DoctorRegisterStep } from './doctor-register'

export class DoctorRegisterPendency {
  doctor_register_pendency_id!: number
  text!: string
  isSolved!: boolean
  created_by_id?: number
  doctor_register_step_id!: number

  @Type(() => DoctorRegisterStep)
  getDoctorRegisterStep?: DoctorRegisterStep

  constructor(props: ClassProps<typeof DoctorRegisterPendency['prototype']>) {
    Object.assign(this, props)
  }
}
