import { DeletePatientExamDocument } from 'domain/usecases/patient/delete-patient-exam-document'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteDeletePatientExamDocument
  implements DeletePatientExamDocument
{
  constructor(private readonly patientRepository: PatientRepository) {}

  delete(
    params: DeletePatientExamDocument.Params
  ): Promise<DeletePatientExamDocument.Model> {
    return this.patientRepository.deletePatientExamDocument(params)
  }
}

export type DeletePatientExamDocumentModel = DeletePatientExamDocument.Model
