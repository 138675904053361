import { UploadTherapeuticPlanDocument } from 'domain/usecases/therapeutic-plan/upload-therapeutic-plan-document'
import { TherapeuticPlanRepository } from 'repository/interfaces/therapeutic-plan-repository'

export class RemoteUploadTherapeuticPlanDocument
  implements UploadTherapeuticPlanDocument
{
  constructor(
    private readonly therapeuticPlanRepository: TherapeuticPlanRepository
  ) {}

  upload(
    params: UploadTherapeuticPlanDocument.Params
  ): Promise<UploadTherapeuticPlanDocument.Model> {
    return this.therapeuticPlanRepository.uploadTherapeuticPlanDocument(params)
  }
}

export type UploadTherapeuticPlanDocumentModel =
  UploadTherapeuticPlanDocument.Model
