import React, { useState } from 'react'
import ButtonNew from 'presentation/components/ButtonNew'
import HealthInsuranceCard from 'presentation/shared/components/HealthInsuranceCard'
import { useHistory } from 'react-router'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import MenuActionDrawer from 'presentation/shared/components/MenuActionDrawer'
import { ContainerNew } from 'presentation/components/ContainerNew'
import HeaderNew from 'presentation/components/HeaderNew'

type Props = {
  healthInsurances?: PatientHealthInsurance[]
}

export default function HealthInsurancesLayout({ healthInsurances }: Props) {
  const history = useHistory()
  const [confirmationDeleteMenuIsOpen, setConfirmationDeleteMenuIsOpen] =
    useState<number | boolean>(false)

  const toggleConfirmationDeleteMenuIsOpenIsOpen = (
    healthInsuranceId: number | boolean
  ) => {
    setConfirmationDeleteMenuIsOpen(healthInsuranceId)
  }

  const HealthInsuranceList = () => {
    return healthInsurances?.map(
      (healthInsurance: PatientHealthInsurance, index: number) => (
        <div key={`${healthInsurance.health_insurance_id}+${index}`}>
          <HealthInsuranceCard
            style={{ marginBottom: '24px' }}
            healthInsurance={healthInsurance}
            openConfirmationDeleteMenu={
              toggleConfirmationDeleteMenuIsOpenIsOpen
            }
          />
        </div>
      )
    )
  }

  const primaryButton = () => {
    return (
      <ButtonNew
        size="large"
        fullWidth
        onClick={() => goToNewHealthInsurance()}
      >
        {!healthInsurances?.length
          ? 'Cadastrar convênio'
          : 'Adicionar convênio'}
      </ButtonNew>
    )
  }

  const deleteHealthInsurance = () => {
    console.log('deleted', confirmationDeleteMenuIsOpen)
  }

  const goToNewHealthInsurance = () => {
    history.push('/convenio/novo')
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Convênios"
        actualPageOnClick={() => history.push('/perfil')}
      />
      <ContainerNew primaryButton={primaryButton()}>
        {!healthInsurances?.length ? (
          <span>Você ainda não possui nenhum convênio cadastrado.</span>
        ) : (
          HealthInsuranceList()
        )}

        <MenuActionDrawer
          title={'Excluir convênio'}
          isOpen={confirmationDeleteMenuIsOpen}
          toggleIsOpen={toggleConfirmationDeleteMenuIsOpenIsOpen}
          primaryButtonText="Sim, desejo excluir"
          primaryButtonOnClick={() => deleteHealthInsurance()}
          secondaryButtonText="Cancelar"
          secondaryButtonOnClick={() =>
            toggleConfirmationDeleteMenuIsOpenIsOpen(false)
          }
        >
          Você tem certeza que deseja excluir as informações do convênio?
        </MenuActionDrawer>
      </ContainerNew>
    </>
  )
}
