import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import 'moment/locale/pt-br'
import BottomMenu from 'presentation/components/BottomMenu'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/new_close.svg'
import { ReactComponent as EmptyStateDocuments } from 'presentation/assets/empty-states/no-documents.svg'
import { ReactComponent as StethoscopeIconOutline } from 'presentation/assets/icons/stethoscope-outline.svg'
import ButtonNew from 'presentation/components/ButtonNew'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import HeaderNew from 'presentation/components/HeaderNew'
import CardNew from 'presentation/shared/components/CardNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import { DocumentsType } from 'domain/usecases/document/get-schedule-documents'
import moment from 'moment'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import LoadingModal from 'presentation/shared/components/LoadingModal'

type DocumentProps = {
  date: string
  doctorName: string
  specialty: string
  code: string
  link: string
}
const MyDocumentsLayout = WithLoading(
  ({ isLoading, setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const documentService = useServices().document

    const [documents, setDocuments] = useState([]) as any
    async function getDocuments() {
      try {
        setIsLoading(true)
        const response = await documentService.getScheduleDocuments()
        setDocuments(response.data)
      } catch (error) {
        toast.error('Falha ao buscar documentos')
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      getDocuments()
    }, [])

    setIsLoading(false)

    return (
      <S.Wrapper>
        <HeaderNew
          actualPageTitle="Meus Documentos"
          actualPageOnClick={() =>
            history.push('/servicos', {
              anchor: 'appointments'
            })
          }
        />
        <S.Container>
          {documents === null && !isLoading ? (
            <>
              <S.EmptyStateContainer>
                <EmptyStateDocuments />
              </S.EmptyStateContainer>
            </>
          ) : (
            <>
              <S.ResultMessage>
                <p>
                  Visualize seus atestados, pedidos de exames, receitas e
                  relatórios médicos
                </p>
              </S.ResultMessage>
              <S.CardsWrapper>
                {documents?.map((document: DocumentsType, index: number) => {
                  return (
                    <DocumentCard
                      date={document.created_at}
                      doctorName={document.doctor_name}
                      specialty={document.specialty}
                      code={document.code}
                      key={index}
                      link={document.link}
                    />
                  )
                })}
              </S.CardsWrapper>
            </>
          )}
        </S.Container>
        <BottomMenu />
        <LoadingModal show={!!isLoading} />
      </S.Wrapper>
    )
  }
)

export default MyDocumentsLayout

function DocumentCard({
  date,
  doctorName,
  specialty,
  code,
  link
}: DocumentProps) {
  const [modalOpen, setModalOpen] = useState(false)

  function redirectToMemed() {
    window.open(link, '_blank')
  }

  return (
    <>
      <CardNew>
        <S.HeaderCard>
          <S.PurpleStrong>
            <CalendarIcon />
            {moment(new Date(date)).format('DD/MM/YYYY - HH:mm')}
          </S.PurpleStrong>
        </S.HeaderCard>
        <DividerNew />

        <S.BlackStrong>
          <StethoscopeIconOutline width={17} />
          Dr(a) {doctorName}
        </S.BlackStrong>
        <S.TextDescription>{specialty}</S.TextDescription>
        <ButtonNew size="medium" onClick={() => setModalOpen(true)}>
          Ver documentos
        </ButtonNew>
      </CardNew>
      <SheetModalNew
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        isDraggable={false}
        size={580}
        style={{ overflow: 'hidden' }}
      >
        <S.SheetWrapper>
          <S.SheetHeader>
            <S.Title>Ver Documentos</S.Title>
            <CloseIcon onClick={() => setModalOpen(false)} />
          </S.SheetHeader>
          <DividerNew />

          <S.TextSheet>
            Você será direcionado para o site da Memed para visualizar os
            documentos prescritos pelo seu médico durante a consulta.
          </S.TextSheet>

          <S.TextSheet>
            Anote o código abaixo, você precisará inserí-lo para baixar sua
            receita.
          </S.TextSheet>

          <S.SplitedCodeContainer>
            {code.split('').map((element, index) => {
              return <S.BigText key={index}>{element}</S.BigText>
            })}
          </S.SplitedCodeContainer>
          <S.ButtonContainer>
            <ButtonNew size="large" onClick={redirectToMemed}>
              Prosseguir
            </ButtonNew>
          </S.ButtonContainer>
        </S.SheetWrapper>
      </SheetModalNew>
    </>
  )
}
