import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import ButtonNew from 'presentation/components/ButtonNew'
import { FormattedSchedule } from 'domain/entities/schedule-model'
import { SchedulingReasonCancelFormatted } from 'domain/entities/scheduling-reason-cancel'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { schedulingCancelValidationSchema } from './scheduling-cancel-validation-schema'
import { useStores } from 'presentation/hooks/use-stores'
import moment from 'moment-timezone'
import { ContainerNew } from 'presentation/components/ContainerNew'

type Props = {
  schedule: FormattedSchedule
  scheduleReasons: SchedulingReasonCancelFormatted[]
} & WithLoadingProps

const SchedulingCancelForm = WithLoading(
  ({ schedule, scheduleReasons, setIsLoading }: Props) => {
    const user = useStores().currentAccount?.getCurrentAccount()?.user
    const history = useHistory()
    const schedulingService = useServices().scheduling
    const [showTextArea, setShowTextArea] = useState<boolean>(false)

    const formik = useFormik({
      initialValues: {
        validate_reason_other: false,
        cd_it_agenda_central: schedule.cd_it_agenda_central,
        cd_mot_cancel: undefined,
        text: ''
      } as ScheduleCancelFormValues,
      validationSchema: schedulingCancelValidationSchema,
      validateOnMount: true,
      validateOnChange: true,
      validateOnBlur: true,
      // enableReinitialize: true,
      onSubmit: async (values) => {
        delete values.validate_reason_other

        const payload = {
          healthInsurance: {
            plan_id: schedule?.healthInsurance?.plan_id,
            health_insurance_id: schedule?.healthInsurance?.health_insurance_id,
            name: schedule?.healthInsurance?.name,
            plan_name: schedule?.healthInsurance?.plan_name
          },
          specialty: {
            specialty_id: schedule?.specialty?.specialty_id,
            name: schedule?.specialty?.name
          },
          patient: {
            user_id: schedule?.patient?.user_id ?? user.user_id,
            patient_id: schedule?.patient?.patient_id,
            name: schedule?.patient?.name,
            email: schedule?.patient?.email
            // age: schedule?.patient?.age,
            // gender: schedule?.patient?.gender
          },
          schedule: {
            schedule_id: schedule?.schedule_id,
            cd_it_agenda_central: schedule?.cd_it_agenda_central,
            date: `${moment(schedule?.date)
              .locale('pt-br')
              .format('dddd - DD [de] MMMM')} às ${moment(schedule?.date)
              .locale('pt-br')
              .format('LT')}`,
            cd_mot_cancel: values.cd_mot_cancel,
            text: values.text
          },
          doctor: {
            doctor_id: schedule?.doctor?.doctor_id,
            name: schedule?.doctor?.name
          },
          unit: {
            unit_id: schedule?.unit?.unit_id,
            name: schedule?.unit?.name,
            description: schedule?.unit?.description
          }
        }

        try {
          setIsLoading(true)
          const res = await schedulingService.deleteSchedule({
            data: payload
          })

          if (res.data.deleteSchedule) {
            toast('Sua consulta foi cancelada', {
              hideProgressBar: true,
              className: '-default',
              position: 'bottom-center'
            })
            history.push('/consultas')
          }
        } catch (error: any) {
          toast.error(
            'Ocorreu um erro ao cancelar o agendamento, tente novamente mais tarde.'
          )
        } finally {
          setIsLoading(false)
        }
      }
    })

    const handleChangeReason = (
      scheduleReasonCancel: SchedulingReasonCancelFormatted
    ) => {
      formik.setFieldValue(
        'cd_mot_cancel',
        scheduleReasonCancel.reason_cancel_id
      )

      if (scheduleReasonCancel.name?.toLocaleUpperCase() === 'OUTRO') {
        formik.setFieldValue('validate_reason_other', true)
        setShowTextArea(true)
      } else {
        formik.setFieldValue('validate_reason_other', false)
        formik.setFieldValue('text', '')
        setShowTextArea(false)
      }
    }

    useEffect(() => {
      formik.validateForm()
    }, [formik.values])

    return (
      <ContainerNew
        title="Qual o motivo do cancelamento?*"
        form
        onSubmit={formik.handleSubmit}
        primaryButton={
          <ButtonNew
            color="primary"
            fullWidth
            type="submit"
            size="large"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar consulta
          </ButtonNew>
        }
      >
        <S.Wrapper>
          <S.BoxContent>
            {scheduleReasons &&
              scheduleReasons?.map((item, index) => (
                <RadioButtonNew
                  key={item.reason_cancel_id}
                  showBackground={false}
                  name="cd_mot_cancel"
                  labelFor={`reason_to_cancel_${index}`}
                  value={item.reason_cancel_id}
                  label={item.name}
                  // onCheck={formik.handleChange('cd_mot_cancel')}
                  onCheck={() => handleChangeReason(item)}
                  // checked={}
                />
              ))}
          </S.BoxContent>

          {showTextArea && (
            <S.TextAreaWrapper>
              <TextAreaNew
                label="Nos conte o que ocorreu:"
                required
                rows={4}
                name="text"
                onInputChange={formik.handleChange('text')}
                error={formik.touched.text ? formik.errors.text : undefined}
                value={formik.values.text}
                onBlur={formik.handleBlur('text')}
              />
            </S.TextAreaWrapper>
          )}
        </S.Wrapper>
      </ContainerNew>
    )
  }
)

type ScheduleCancelFormValues = {
  validate_reason_other?: false
  cd_it_agenda_central: number
  cd_mot_cancel: number | undefined
  text: string
}

export default SchedulingCancelForm
