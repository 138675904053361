import { CreateTherapeuticPlan } from 'domain/usecases/therapeutic-plan/create-therapeutic-plan'
import { TherapeuticPlanRepository } from 'repository/interfaces/therapeutic-plan-repository'

export class RemoteCreateInitialTherapeuticPlan
  implements CreateTherapeuticPlan
{
  constructor(
    private readonly therapeuticPlanRepository: TherapeuticPlanRepository
  ) {}

  add(
    params: CreateTherapeuticPlan.Params
  ): Promise<CreateTherapeuticPlan.Model> {
    return this.therapeuticPlanRepository.createTherapeuticPlan(params)
  }
}

export type CreateTherapeuticPlanModel = CreateTherapeuticPlan.Model
