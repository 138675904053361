import { CreateDoctorRegisterPendency } from 'domain/usecases/doctor-register/enable-doctor-register-pendency'
import { DoctorRegisterRepository } from 'repository/interfaces/doctor-register-repository'

export class RemoteCreateDoctorRegisterPendency
  implements CreateDoctorRegisterPendency
{
  constructor(
    private readonly doctorRegisterRepository: DoctorRegisterRepository
  ) {}

  execute(
    params: CreateDoctorRegisterPendency.Params
  ): Promise<CreateDoctorRegisterPendency.Model> {
    return this.doctorRegisterRepository.createDoctorRegisterPendency(params)
  }
}
