import styled, { css } from 'styled-components'

export const CardInfo = styled.div`
  ${({ theme }) => css`
  margin-top: 8px;
    background-color: ${theme.ds.colors.white};
    padding: 24px;

    > .title {
      color: ${theme.ds.colors.neutral900};
      font-weight: ${theme.ds.typography.weight.semiBold};
      font-size: ${theme.ds.typography.size.small};
      line-height: ${theme.ds.typography.lineHeight.medium};
      margin-bottom: 8px;
    }

    > .description {
      color: ${theme.ds.colors.neutral500};
      font-weight: ${theme.ds.typography.weight.normal};
      font-size: ${theme.ds.typography.size.xsmall};
      line-height: ${theme.ds.typography.lineHeight.medium};

      > .link {
        color ${theme.ds.colors.primary600};
        font-weight: ${theme.ds.typography.weight.bold};
        text-decoration: none;
      }
    }
  `}
`

export const AvailableDatesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardScheduling = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.ds.typography.fontFamily};
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${theme.ds.colors.white};
    padding: 16px 24px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06);

    > .header {
      display: flex;
      align-items: center;

      .img {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .header-infos {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .title {
          font-size: ${theme.ds.typography.size.small};
          color: ${theme.ds.colors.neutral900};
          line-height: ${theme.ds.typography.lineHeight.medium};

          .prefix {
            font-weight: ${theme.ds.typography.weight.semiBold};
            font-size: ${theme.ds.typography.size.xsmall};
          }

          .text {
            text-transform: capitalize;
            font-weight: ${theme.ds.typography.weight.bold};
            font-size: ${theme.ds.typography.size.small};
          }
        }

        > .subtitle {
          display: flex;
          align-items: center;
          font-size: ${theme.ds.typography.size.xsmall};
          color: ${theme.ds.colors.neutral600};
          font-weight: ${theme.ds.typography.weight.normal};
          line-height: ${theme.ds.typography.lineHeight.medium};
          text-transform: capitalize;

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > .localization-box {
        display: flex;
        align-items: flex-start;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        > .infos {
          > .title {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
            line-height: ${theme.ds.typography.lineHeight.medium};
            text-transform: capitalize;
          }

          > .description {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral600};
            font-weight: ${theme.ds.typography.weight.normal};
            line-height: ${theme.ds.typography.lineHeight.medium};
            text-transform: capitalize;
          }
        }
      }

      > .scheduling-box {
        > .next-date {
          display: flex;
          align-items: center;
          font-size: ${theme.ds.typography.size.xsmall};
          color: ${theme.ds.colors.neutral600};
          font-weight: ${theme.ds.typography.weight.normal};
          line-height: ${theme.ds.typography.lineHeight.medium};
          /* text-transform: capitalize; */

          b {
            margin-right: 8px;
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }

        > .schedules {
          display: flex;
          gap: 8px;
          margin-top: 16px;

          /* Button "+" icon */
          button {
            .icon {
              margin-left: -4px;
              svg,
              path {
                color: transparent;
                fill: transparent;
                stroke: ${theme.ds.colors.white};
              }
            }

            &:disabled {
              .icon {
                svg,
                path {
                  stroke: ${theme.ds.colors.neutral400};
                }
              }
            }
          }
        }
      }
    }
  `}
`

export const ResultMessage = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    margin-bottom: 32px;
  }
`
