import styled, { css } from 'styled-components'

type CanvaType = {
  sWidth: string
  sHeight: string
}

type OptionsProps = {
  position: 'top' | 'bottom'
}

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
`

export const Content = styled.div<CanvaType>`
  margin-top: 32px;
  overflow: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  object {
    width: ${({ sWidth }) => `${sWidth}`};
    height: ${({ sHeight }) => `${sHeight}`};
  }

  .mobile-visualization-option {
    margin-top: 24px;
    padding: 24px;
  }
`

export const Options = styled.div<OptionsProps>`
  width: 100%;
  height: 32px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
  z-index: 100;
  color: white;
  background: #38383d;
  border-bottom: 1px solid #0d0d0d;
  border-top: 1px solid #0d0d0d;
  ${({ position }) => {
    return position === 'bottom'
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `
  }}

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  input {
    padding: 8px;
    max-width: 60px;
    border-radius: 6px;
    border: 1px solid white;
    margin-right: 8px;
    text-align: right;
    font-size: 16px;
    color: white;
  }

  .navigation {
    //TODO[6]: descomentar quando o TODO[1] for resolvido
    /* width: 100px; */
    display: flex;
    align-items: center;
    justify-content: space-between;

    .file {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 10px;
      }
    }

    .separate {
      display: inline-block;
      height: 15px;
      border-left: 1px solid #27272b;
      transform: scale(1.4);
    }

    .page button:last-child {
      margin-left: 5px;
    }

    button:nth-last-child(2) {
      transform: scaleX(-1);
    }
  }

  @media only screen and (min-width: 690px) {
    .navigation {
      justify-content: end;
    }
    .page {
      display: none;
    }
  }
`
