import { Type } from 'class-transformer'
import { Doctor } from 'domain/entities/doctor-model'
import { DoctorRegistrationSteps } from 'common/enum/doctor-overall-registration-steps'
import { DoctorRegisterPendency } from 'domain/entities/doctor-register-step-pendency'
import { DoctorDocumentValidation } from 'domain/entities/doctor-document-validation-model'
import { DoctorRegisterReview } from 'domain/entities/doctor-register-review-model'

export class DoctorRegister {
  approvedAt?: Date
  doctor_id?: number
  isComplete!: boolean
  approved_by_id?: number
  doctor_register_id?: number
  isEmergency?: boolean
  status!: DoctorRegisterStatus

  @Type(() => DoctorRegisterStep)
  steps?: DoctorRegisterStep[]

  @Type(() => Doctor)
  doctor?: Doctor

  @Type(() => Date)
  sentToDirectorAt?: Date

  @Type(() => Date)
  sentToHospitalAt?: Date

  @Type(() => PendenciesGroupedByDate)
  pendenciesGroupedDate?: PendenciesGroupedByDate[]

  @Type(() => DoctorDocumentValidation)
  documentValidations?: DoctorDocumentValidation[]

  get translatedStatus(): string {
    return translateStatus(this.status)
  }

  constructor(props?: DoctorRegisterProps) {
    if (props) {
      Object.assign(this, props)
    }
  }
}

export class DoctorRegisterStep {
  approved_at?: Date
  doctor_register_id?: number
  name?: DoctorRegistrationSteps
  status?: DoctorRegisterStepStatus
  doctor_register_step_id?: number
  @Type(() => DoctorRegisterPendency)
  getActivePendency?: DoctorRegisterPendency

  @Type(() => DoctorRegisterReview)
  getActiveReview?: DoctorRegisterReview

  constructor(props?: DoctorRegisterStepProps) {
    if (props) {
      Object.assign(this, props)
    }
  }
}

type DoctorRegisterProps = {
  approvedAt?: Date
  doctor_id?: number
  isComplete: boolean
  approved_by_id?: number
  doctor_register_id?: number
  steps?: DoctorRegisterStep[]
  status?: DoctorRegisterStatus
}

export type DoctorRegisterStepProps = {
  doctor_register_step_id?: number
  status: DoctorRegisterStepStatus
  name: DoctorRegistrationSteps
  doctor_register_id?: number
  approved_at?: Date
}

export enum DoctorRegisterStatus {
  UNREVIEWED_REGISTER = 'UNREVIEWED_REGISTER',
  PENDENT = 'PENDENT',
  REVIEWED_BY_DIRECTOR = 'REVIEWED_BY_DIRECTOR',
  APPROVED_BY_DIRECTOR_WITH_PENDENCIES = 'APPROVED_BY_DIRECTOR_WITH_PENDENCIES',
  PENDENCY_ANSWERED = 'PENDENCY_ANSWERED',
  REGISTER_SEND_TO_DIRECTOR = 'REGISTER_SEND_TO_DIRECTOR',
  REGISTER_RESEND_TO_DIRECTOR = 'REGISTER_RESEND_TO_DIRECTOR',
  REGISTER_CONCLUDED = 'REGISTER_CONCLUDED',
  REGISTER_REPROVED_BY_DIRECTOR = 'REGISTER_REPROVED_BY_DIRECTOR',
  INCOMPLETE_REGISTER = 'INCOMPLETE_REGISTER'
}

export enum DoctorRegisterStepStatus {
  EMPTY = 'EMPTY',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  PENDENT = 'PENDENT',
  APPROVED_DOCTOR_REGISTER = 'APPROVED_DOCTOR_REGISTER',
  APPROVED_DIRECTOR = 'APPROVED_DIRECTOR',
  REVIEW = 'REVIEW',
  CHANGED = 'CHANGED'
}

function translateStatus(status: DoctorRegisterStatus): string {
  switch (status) {
    case DoctorRegisterStatus.UNREVIEWED_REGISTER:
      return 'Não revisado'
    case DoctorRegisterStatus.PENDENT:
      return 'Pendente'
    case DoctorRegisterStatus.REVIEWED_BY_DIRECTOR:
      return 'Revisado pelo diretor'
    case DoctorRegisterStatus.APPROVED_BY_DIRECTOR_WITH_PENDENCIES:
      return 'Aprovado pelo diretor com pendências'
    case DoctorRegisterStatus.PENDENCY_ANSWERED:
      return 'Pendência respondida'
    case DoctorRegisterStatus.REGISTER_SEND_TO_DIRECTOR:
      return 'Enviado para o diretor'
    case DoctorRegisterStatus.REGISTER_CONCLUDED:
      return 'Cadastro concluído'
    case DoctorRegisterStatus.REGISTER_REPROVED_BY_DIRECTOR:
      return 'Reprovado pelo diretor'
    case DoctorRegisterStatus.INCOMPLETE_REGISTER:
      return 'Cadastro incompleto'
    case DoctorRegisterStatus.REGISTER_RESEND_TO_DIRECTOR:
      return 'Reenviado para o diretor'
  }
}
export function translateStepName(name: DoctorRegistrationSteps): string {
  switch (name) {
    case DoctorRegistrationSteps.ACADEMIC_EDUCATION:
      return 'Formação acadêmica'
    case DoctorRegistrationSteps.ADDRESS:
      return 'Endereços'
    case DoctorRegistrationSteps.ATTENDANCES:
      return 'Atendimentos'
    case DoctorRegistrationSteps.CNES:
      return 'CNES'
    case DoctorRegistrationSteps.CONTACTS:
      return 'Contatos'
    case DoctorRegistrationSteps.DOCUMENTS_AND_ATTACHMENTS:
      return 'Documentos e anexos'
    case DoctorRegistrationSteps.IMUNNIZATION:
      return 'Imunização'
    case DoctorRegistrationSteps.MEDICAL_PRIVILEGES:
      return 'Privilegios médicos'
    case DoctorRegistrationSteps.PERSONAL_DATA:
      return 'Dados pessoais'
    case DoctorRegistrationSteps.PROFESSIONAL_DATA:
      return 'Dados profissionais'
    case DoctorRegistrationSteps.SECTORS_OF_ACTIVITY:
      return 'Setor de atuação'
    case DoctorRegistrationSteps.SOURCE_VALIDATION:
      return 'Validação da fonte'
    case DoctorRegistrationSteps.TEAMS:
      return 'Equipes'
    case DoctorRegistrationSteps.TERMS:
      return 'Termos'
    default:
      return ''
  }
}

export class PendenciesGroupedByDate {
  date!: Date
  @Type(() => DoctorRegisterPendency)
  data!: DoctorRegisterPendency[]

  constructor(props?: PendenciesGroupedByDateProps) {
    if (props) {
      Object.assign(this, props)
    }
  }
}

export type PendenciesGroupedByDateProps = {
  data: DoctorRegisterPendency[]
  date: Date
}
