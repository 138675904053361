import React from 'react'
import {
  GetPasswordCodeFormValues,
  SetPasswordFormValues
} from 'presentation/pages/PasswordRecovery'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import PasswordRecoveryForm from 'presentation/components/Forms/PasswordRecovery'
import { Profile } from 'common/enum/profile'

type PasswordRecoveryLayoutProps = {
  getPasswordCodeInitialValues?: GetPasswordCodeFormValues
  setPasswordInitialValues?: SetPasswordFormValues
  getCodeUseCase?: GetPasswordRecoveryCode
  setPasswordUseCase?: ChangePassword
  type: 'phone' | 'email'
  client?: Profile
}

export default function PasswordRecoveryLayout({
  getCodeUseCase,
  type,
  getPasswordCodeInitialValues,
  setPasswordInitialValues,
  setPasswordUseCase,
  client
}: PasswordRecoveryLayoutProps) {
  return (
    <PasswordRecoveryForm
      getCodeValues={getPasswordCodeInitialValues}
      setPasswordValues={setPasswordInitialValues}
      getCodeUseCase={getCodeUseCase}
      changePasswordUseCase={setPasswordUseCase}
      type={type}
      client={client}
    />
  )
}
