import { LoadPatientSurgicalOrders } from 'domain/usecases/patient/load-patient-surgical-orders'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadPatientSurgicalOrders
  implements LoadPatientSurgicalOrders
{
  constructor(private readonly patientRepository: PatientRepository) {}

  async load(
    params: LoadPatientSurgicalOrders.Params
  ): Promise<LoadPatientSurgicalOrders.Model> {
    return this.patientRepository.loadPatientSurgicalOrders(params)
  }
}
