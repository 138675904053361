import styled, { css } from 'styled-components'

export const CardSchedulingInfos = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.ds.typography.fontFamily};
    display: flex;
    flex-direction: column;
    gap: 16px;

    > .header {
      display: flex;
      align-items: center;

      .img {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 16px;

        .icon {
          width: 48px;
          height: 48px;

          svg,
          path {
            max-width: 48px;
            max-height: 48px;
            fill: ${({ theme }) => theme.ds.colors.success500};
          }
        }
      }

      .header-infos {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .title {
          font-size: ${theme.ds.typography.size.medium};
          color: ${theme.ds.colors.neutral900};
          font-weight: ${theme.ds.typography.weight.semiBold};
          line-height: ${theme.ds.typography.lineHeight.medium};
        }
      }
    }

    > .content {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      > .box {
        display: flex;
        align-items: flex-start;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        > .infos {
          > .title {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
            line-height: ${theme.ds.typography.lineHeight.medium};
            text-transform: capitalize;

            span {
              text-transform: none;
            }

            .text {
              text-transform: capitalize;
              font-weight: ${theme.ds.typography.weight.bold}; */
            }
          }

          > .description {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral600};
            font-weight: ${theme.ds.typography.weight.normal};
            line-height: ${theme.ds.typography.lineHeight.medium};

            &.-localization,
            &.-plan-name {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  `}
`

export const CardPatientInfos = styled.div`
  ${({ theme }) => css`
    margin-top: 2px;
    font-family: ${theme.ds.typography.fontFamily};
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${theme.ds.colors.white};
    padding: 32px 24px 16px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06);

    > .header {
      > .title {
        font-size: ${theme.ds.typography.size.small};
        color: ${theme.ds.colors.primary800};
        font-weight: ${theme.ds.typography.weight.bold};
        line-height: ${theme.ds.typography.lineHeight.medium};
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > .box {
        display: flex;
        align-items: flex-start;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        > .infos {
          > .title {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
            line-height: ${theme.ds.typography.lineHeight.medium};
          }

          > .description {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral600};
            font-weight: ${theme.ds.typography.weight.normal};
            line-height: ${theme.ds.typography.lineHeight.medium};
            text-transform: capitalize;

            &.-no-transform {
              text-transform: none;
            }
          }
        }
      }
    }
  `}
`
