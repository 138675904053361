import React from 'react'
import ReactDOM from 'react-dom'
import 'reflect-metadata/Reflect.js'
import PatientRouter from 'main/routes'
import { ThemeProvider } from 'styled-components'
import theme from 'presentation/styles/theme'
import GlobalStyles from 'presentation/styles/global'
import { ToastContainer, Slide } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import ServiceWorkerWrapper from 'presentation/shared/components/PWAContainer'
import TagManager from 'react-gtm-module'

let tagManagerArgs
if (process.env.NODE_ENV === 'production') {
  tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID_PRODUCAO || '',
    auth: process.env.REACT_APP_GTM_AUTH_PRODUCAO || '', // used to set environments.
    preview: process.env.REACT_APP_GTM_PREVIEW_PRODUCAO || '' //used to set environments
    // dataLayerName: 'PageDataLayer' // Multiple dataLayer example
    // dataLayer: {
    //   userId: '001',
    //   userProject: 'project'
    // },
  }
} else {
  tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID_DEVELOP || '',
    auth: process.env.REACT_APP_GTM_AUTH_DEVELOP || '', // used to set environments.
    preview: process.env.REACT_APP_GTM_PREVIEW_DEVELOP || '' //used to set environments
    // dataLayerName: 'PageDataLayer' // Multiple dataLayer example
    // dataLayer: {
    //   userId: '001',
    //   userProject: 'project'
    // },
  }
}

TagManager.initialize(tagManagerArgs) // Initialize GTM module

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <PatientRouter />
        <ToastContainer
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          transition={Slide}
        />
      </ThemeProvider>
    </ServiceWorkerWrapper>
  </React.StrictMode>,
  document.getElementById('root')
)
