import styled from 'styled-components'

export const EditPatientInfoWrapper = styled.div`
  text-align: start !important;

  input {
    background: #f0f6ef;
  }
`

export const RadioContent = styled.div`
  margin-bottom: 10px;
  div {
    height: auto;
  }
  div div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5px;
  }

  div label {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`
