export enum ExamStatusEnum {
  FULL = 'FULL',
  WAIT = 'WAIT',
  PARTIAL = 'PARTIAL',
  EMPTY = 'EMPTY'
}

export enum ExamStatusEnumLocated {
  FULL = 'Resultado liberado',
  WAIT = 'Aguardando laudo',
  PARTIAL = 'Resultado parcialmente liberado',
  EMPTY = 'Resultado não disponível'
}
