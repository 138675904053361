import styled from 'styled-components'

export const Wrapper = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h6`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.font.semiBold};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

export const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  color: ${({ theme }) => theme.colors.lightGray};
  line-height: 30px;

  svg {
    margin-right: 5.5px;
  }

  strong {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const Container = styled.div`
  padding: 20px;
`

export const TitleContainer = styled.div`
  margin: 10px 0;
`

export const WhiteContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
`

export const Card = styled.div`
  width: 100%;
  margin: 30px 0;
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius.large};

  ul {
    list-style-type: disc;
    padding: 10px 40px;
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const ModalLabel = styled.h3`
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`
