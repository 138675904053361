import styled from 'styled-components'
import Chip from 'presentation/shared/components/Chip'
import Rating from '@material-ui/lab/Rating'
import theme from 'presentation/styles/theme'

export const StyledRate = styled(Rating)`
  label {
    padding: 0 16px !important;
  }
`

export const ChipsContainer = styled.div`
  p {
    margin-bottom: 16px;
    text-align: center;
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    max-width: 700px;
    margin: auto;
  }
`

export const ChipNew = styled(Chip)`
  & + label {
    border: none !important;
    color: ${theme.ds.colors.neutral600};
    background-color: ${theme.ds.colors.neutral100};
    user-select: none;
  }

  &:checked + label {
    background-color: ${theme.ds.colors.primary300} !important;
  }
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
