import React from 'react'
import { useHistory } from 'react-router-dom'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

import theme from 'presentation/styles/theme'
import { ReactComponent as BackArrow } from 'presentation/assets/icons/full-back-arrow.svg'
import * as S from './styles'

export type ActualPageProps = {
  text: string
  color?: keyof typeof theme.colors
  onClick?: () => void
  style?: CSSProperties
}

export default function ActualPage({
  text,
  onClick,
  style,
  color
}: ActualPageProps) {
  const history = useHistory()

  return (
    <S.Wrapper
      style={style}
      onClick={onClick ? () => onClick() : () => history.goBack()}
      data-testid="back-arrow-icon"
    >
      <BackArrow />
      <label style={{ color: color && theme.colors[color] }}>{text}</label>
    </S.Wrapper>
  )
}
