import styled from 'styled-components'

export const Wrapper = styled.div`
  height: auto;
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const TitleContainer = styled.div`
  margin: 24px 0;
`

export const TitleInfo = styled.div`
  display: grid;
  justify-content: flex-start;
`

export const TitleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const SubTitle = styled.span`
  color: ${({ theme }) => theme.ds.colors.neutral500};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
`

export const SubTitleRed = styled.p`
  color: ${({ theme }) => theme.ds.colors.danger900};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
`

export const CardContainer = styled.div`
  display: grid;
  border: 1px solid #52dbcc;
  border-radius: 8px;
  background: ${({ theme }) => theme.ds.colors.white};
  padding: 15px;
  margin-bottom: 20px;

  button {
    margin-top: 20px;
  }
`

export const CardTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 10px;
`

export const CardLabel = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral600};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const CardTextValue = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 5px;
`

export const AlertContainer = styled.div`
  display: flex;
  align-items: baseline;

  svg {
    width: 25px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BackgroundWrapper = styled.div`
  /* background: ${({ theme }) => theme.ds.colors.white}; */
  background: blue;
`
