import React, { useEffect, useMemo, useState } from 'react'

import CheckinLayout from 'presentation/layouts/Checkin'
import moment, { Moment } from 'moment-timezone'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

function Checkin({ setIsLoading }: WithLoadingProps) {
  const [surgicalOrdersToCheckin, setSurgicalOrdersToCheckin] = useState<
    SurgicalOrderModel[]
  >([])
  const service = useServices().patient
  const surgicalOrderService = useServices().surgicalOrder

  const validateCheckinHour = (
    now?: Moment,
    surgeryDateTime?: Moment,
    status?: SurgicalOrderStatus
  ) => {
    if (status && invalidStatus.includes(status)) {
      return false
    }
    const surgeryDate = moment.utc(surgeryDateTime)
    surgeryDate.set('hours', 4)
    surgeryDate.set('minutes', 0)
    surgeryDate.set('seconds', 0)

    return (
      surgeryDate.isSame(now, 'date') &&
      surgeryDate.isSame(now, 'months') &&
      surgeryDate.isSame(now, 'year') &&
      !!now?.isSameOrAfter(surgeryDate) &&
      !!now?.isSameOrBefore(surgeryDateTime)
    )
  }

  const showCheckin = useMemo((): boolean => {
    return surgicalOrdersToCheckin.length > 0
  }, [surgicalOrdersToCheckin])

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true)
        const now = await getNow()
        const patientInfo = await service.loadPatientInfo([
          'surgicalOrders {',
          'surgeryDate',
          'surgical_order_id',
          'status {status}',
          '}'
        ])

        const allowedSurgicalOrders = patientInfo.surgicalOrders?.filter(
          (surgicalOrder) => {
            return validateCheckinHour(
              now,
              moment.utc(surgicalOrder.surgeryDate),
              surgicalOrder.status?.slice(-1)?.[0].status
            )
          }
        )

        if (allowedSurgicalOrders) {
          setSurgicalOrdersToCheckin(allowedSurgicalOrders)
        }
      } catch (err) {
        return err
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
  }, [])

  const getNow = () => {
    return fetch(
      process.env.REACT_APP_API_URL
        ? process.env.REACT_APP_API_URL
        : '/fetch' + '/now'
    )
      .then((res) => res.json())
      .then((res) => {
        return moment().utc(res)
      })
  }

  const handleSubmit = async () => {
    const newDate = await getNow()

    for (const surgicalOrder of surgicalOrdersToCheckin) {
      if (
        surgicalOrder.surgeryDate &&
        validateCheckinHour(
          newDate,
          moment(surgicalOrder.surgeryDate),
          surgicalOrder.status?.slice(-1)?.[0].status
        )
      ) {
        await surgicalOrderService.checkin({
          surgical_order_id: Number(surgicalOrder.surgical_order_id)
        })
      }
    }
  }

  return <CheckinLayout disabled={!showCheckin} handleSubmit={handleSubmit} />
}

export default WithLoading(Checkin)

const invalidStatus = [
  SurgicalOrderStatus.CHECK_IN,
  SurgicalOrderStatus.PATIENT_IN_SURGERY,
  SurgicalOrderStatus.PATIENT_RECOVERY,
  SurgicalOrderStatus.PATIENT_DISCHARGE,
  SurgicalOrderStatus.SERVICE_EVALUATION,
  SurgicalOrderStatus.CANCELLED
]
