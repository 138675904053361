import { LoadSurgicalOrderNoticeCard } from 'domain/usecases/surgical-order/load-surgical-orders-notice-card'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteLoadSurgicalOrderNoticeCard
  implements LoadSurgicalOrderNoticeCard
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  async load(
    params: LoadSurgicalOrderNoticeCard.Params
  ): Promise<LoadSurgicalOrderNoticeCard.Model> {
    return this.surgicalOrderRepository.loadSurgicalOrderNoticeCard(params)
  }
}

export type RemoteLoadSurgicalOrderNoticeCardModel =
  LoadSurgicalOrderNoticeCard.Model
