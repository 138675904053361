import React from 'react'
import { Authentication } from 'domain/usecases/authentication/authentication'
import LoginLayout from 'presentation/layouts/Login'
import { VerifyLoginClient } from 'domain/usecases/authentication/verify-client'
import { Profile } from 'common/enum/profile'

type LoginProps = {
  useCase: Authentication
  verifyClient: VerifyLoginClient
  client: Profile
}

export default function Login({ useCase, verifyClient, client }: LoginProps) {
  return (
    <LoginLayout
      client={client}
      verifyClient={verifyClient}
      useCase={useCase}
    />
  )
}

export type LoginFormValues = {
  cpf: string
  password: string
  remember: boolean
}
