import styled from 'styled-components'
import { motion } from 'framer-motion'
import styleTheme from 'presentation/styles/theme'

import { ModalProps } from '.'

export const bg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  /* z-index: ${({ theme }) => theme.layers.alwaysOnTop}; */
  z-index: 99999999999;
`

export const Wrapper = styled(motion.div)<Pick<ModalProps, 'preventPadding'>>`
  transform-origin: center;
  width: 288px;
  min-height: 256px;
  box-shadow: 0 4px 9px 2px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ preventPadding }) => (preventPadding ? 0 : '50px 25px')};
  z-index: 99999999999;
`

export const Title = styled.h3<Pick<ModalProps, 'description'>>`
  padding-top: 10px;
  padding-bottom: ${({ description }) => (description ? '10px' : 0)};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.bold};
  color: ${({ theme }) => theme.colors.gray};
`

export type SubtitleProps = {
  colorFont?: keyof typeof styleTheme.colors
}

export const Subtitle = styled.span<SubtitleProps>`
  color: ${({ theme, colorFont }) =>
    colorFont ? theme.colors[colorFont] : theme.colors.lightGray};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  line-height: 21px;
  letter-spacing: 0.5px;
`

export const Buttons = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
`
export const Input = styled.input`
  width: 90%;
  margin-top: 20px;
  height: 45px;
  border-radius: 5px;
  border: none;
  background-color: #e7e7e7;
  color: #9b9b9b;
  outline: none;
  padding: 10px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`
