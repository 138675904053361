export const capitalizeEachWordText = (text: string) => {
  const notValid = !text || text?.length === 0 || !text?.trim()
  if (notValid) return ''
  const normalize = text?.toLowerCase()?.trim()
  const arr = normalize.split(' ')
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  const textCapitalized = arr.join(' ')

  return textCapitalized
}
