import React, { useEffect, useState } from 'react'

import AttendancesLayout from 'presentation/layouts/Attendances'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { Hospital } from 'domain/entities/hospital-model'
import { getStatusInfo } from 'presentation/utils/service-status'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import moment from 'moment-timezone'
import { Schedule } from 'domain/entities/schedule-model'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'

const Attendances = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const [surgicalOrders, setSurgicalOrders] = useState<SurgicalOrderModel[]>([])
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const patientService = useServices().patient

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)
      await patientService
        .loadPatientInfo([
          'surgicalOrders (filterSolicitations: true) {',
          'surgical_order_id',
          'procedure {description}',
          'hospital {name, hospital_id}',
          'surgeryDate',
          'scheduled',
          'status{status}',
          'doctor{name, role}',
          '}'
        ])
        .then(async (patientData) => {
          const surgicalOrders = patientData.surgicalOrders

          if (surgicalOrders) {
            const newSurgicalOrders =
              await patientService.loadPatientSurgicalOrders()

            surgicalOrders.map((surgicalOrder) => {
              surgicalOrder.active_status = newSurgicalOrders.filter(
                (newSurgicalOrder) =>
                  newSurgicalOrder.surgical_order_id ===
                  surgicalOrder.surgical_order_id
              )[0].active_status
            })

            setSurgicalOrders(surgicalOrders)
          }
        })
      patientService

        .loadPatientSchedules(
          [
            'scheduleItemDescription',
            'sector',
            'scheduleTime',
            'status',
            'hospitalName'
          ],
          {
            pageSize: 50,
            pageNumber: 1
          },
          {
            startDate: moment(new Date()).subtract({ years: 4 }).toISOString(),
            endDate: moment(new Date())
              .add({
                years: 5
              })
              .toISOString()
          }
        )
        .then((patientSchedules) => setSchedules(patientSchedules))
      setIsLoading(false)
    }

    try {
      loadData()
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar os atendimentos')
    }
  }, [])

  return (
    <AttendancesLayout
      surgicalOrders={surgicalOrders?.map((surgicalOrder) => {
        const statusInfo = getStatusInfo(surgicalOrder?.active_status ?? '')
        return {
          doctorName: surgicalOrder?.doctor?.name ?? '-',
          doctorRole: 'Médico responsável',
          unit: new Hospital(
            surgicalOrder?.hospital?.hospital_id ?? 0,
            surgicalOrder?.hospital?.name ?? '',
            surgicalOrder?.hospital?.name ?? ''
          ).name,
          status: statusInfo?.label ?? '',
          statusColor: handleStatusColor(surgicalOrder) ?? '',
          color: handleStatusColor(surgicalOrder) ?? '',
          surgical_order_id: surgicalOrder?.surgical_order_id ?? 1,
          scheduling_status: surgicalOrder?.scheduling_status,
          active_status: surgicalOrder.active_status,
          date: surgicalOrder?.surgeryDate ?? '',
          title:
            surgicalOrder?.procedure?.[0].description ??
            'Procedimento cirúrgico'
        }
      })}
      schedules={schedules?.map((patientScheduled) => ({
        title: patientScheduled.scheduleItemDescription,
        status: patientScheduled.status,
        type: patientScheduled.sector,
        statusColor: handleScheduleStatusColor(patientScheduled),
        color: handleScheduleStatusColor(patientScheduled),
        unit: new Hospital(
          0,
          patientScheduled.hospitalName || '',
          patientScheduled.hospitalName || ''
        ).name,
        date: patientScheduled.scheduleTime
          ? moment(new Date(patientScheduled.scheduleTime))
              .utc()
              .format('DD/MM/YYYY - HH:mm')
          : 'Aguardando agendamento'
      }))}
    />
  )
})

export default Attendances

function handleStatusColor(surgicalOrder: SurgicalOrderModel) {
  let color = '#888888'
  surgicalOrder.status?.forEach((status) => {
    if (status.status === SurgicalOrderStatus.CANCELLED) {
      return (color = '#F48989')
    } else if (status.status === SurgicalOrderStatus.SCHEDULED) {
      return (color = '#1BD15D')
    }
  })
  return color
}

function handleScheduleStatusColor(schedule: Schedule) {
  let color = '#1BD15D'
  schedule.status === 'C' && (color = '#F48989')
  schedule.status === 'A' && (color = '#888888')
  return color
}
