import { UpdateDoctorRegisterStep } from 'domain/usecases/doctor-register-step/update-doctor-register-step'
import { IDoctorRegisterStepRepository } from 'repository/interfaces/doctor-register-step-repository'

export class RemoteUpdateDoctorRegisterStep
  implements UpdateDoctorRegisterStep
{
  constructor(
    private readonly doctorRegisterStepRepository: IDoctorRegisterStepRepository
  ) {}

  update(
    params: UpdateDoctorRegisterStep.Params
  ): Promise<UpdateDoctorRegisterStep.Model> {
    return this.doctorRegisterStepRepository.updateDoctorRegisterStep(params)
  }
}
