import { changeSelectedUserDependentMutation } from 'repository/graphql/mutations'
import { DependentsRepository as IDependentRepository } from 'repository/interfaces/dependents-repositoriy'
import { ChangeDependent } from 'domain/usecases/dependents/change-denpendent'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'

export class DependentRepository implements IDependentRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async changeDependent(
    params: ChangeDependent.Params
  ): Promise<ChangeDependent.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangeDependent.Model>

    const query = changeSelectedUserDependentMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.user_id, 'user_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ChangeDependent.Model
    }
  }
}
