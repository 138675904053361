import React from 'react'

import { SlideProps } from '.'
import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import MySurgeryImage from 'presentation/assets/banners/surgical-journey/my-surgery.svg'
import * as S from './styled'

export const SlideIntro = ({ slideIntro }: SlideProps) => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Minha cirurgia
        </HeadingNew>
        <S.Image
          slideIntro={slideIntro}
          src={MySurgeryImage}
          alt="Mulher passando informações"
        />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            Conheça as etapas e acompanhe as orientações para a sua cirurgia
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
