import { Patient } from 'domain/entities/patient-model'
import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import MultimediaFileInput from 'presentation/shared/components/MultimediaFileInput'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import TextField from 'presentation/components/TextField'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { InsuranceFormValues } from '../AddHealthInsurance'
import {
  HealthInsurancePlans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import { toast } from 'react-toastify'
import theme from 'presentation/styles/theme'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientDocument } from 'common/enum/patient-document'
import AutoComplete from 'presentation/components/AutoComplete'
import { Plans } from 'domain/usecases/health-insurance/load-all-health-insurance'

type EditHealthInsuranceProps = {
  patient: Patient
  onClose: () => void
  open: boolean
  onConfirm: () => void
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  initialValues?: InsuranceFormValues
  uploadDocument?: UploadPatientDocument
  nextStep: (
    card: string,
    healthPlanCode: string,
    health_insurance_id: number
  ) => void
  healthInsurances: HealthInsurancePlans[]
  setEditInsurance: (card?: string, healthPlanCode?: number) => void
}

type DefaultInsuranceValues = {
  healthInsuranceCode?: number
  healthInsuranceName?: string
  ansRegister?: string
  healthPlanName?: string
  healthPlanCode?: number
  subplanName?: string
  subplanCode?: number
  health_insurance_id?: number
  company?: string
  healthCard?: string
  frontCard?: File
  backCard?: File
}

export function EditHealthInsurance({
  patient,
  onClose,
  open,
  onConfirm,
  updateHealthInsuranceData,
  uploadDocument,
  healthInsurances,
  setEditInsurance
}: EditHealthInsuranceProps) {
  const [plans, setPlans] = useState([] as Plans[])
  const [filteredPlans, setFilteredPlans] = useState([] as Plans[])
  const [subplans, setSubplans] = useState([] as SubPlan[])
  const [filteredSubplans, setFilteredSubplans] = useState([] as SubPlan[])
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)
  const [insurances, setInsurances] = useState([] as HealthInsurancePlans[])
  const [insuranceSearchQuery, setInsuranceSearchQuery] = useState<string>()
  const [planSearchQuery, setPlanSearchQuery] = useState<string>()
  const [subplanSearchQuery, setSubplanSearchQuery] = useState<string>()
  const [healthCardQuery, setHealthCardQuery] = useState<string>()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  const { isValid, values, errors, submitForm, setFieldValue, handleChange } =
    useFormik<DefaultInsuranceValues>({
      initialValues: {
        healthInsuranceCode: patient.healthInsurance?.healthInsuranceCode,
        healthInsuranceName: patient.healthInsurance?.healthInsuranceName,
        healthPlanCode: patient.healthInsurance?.healthPlanCode,
        healthPlanName: patient.healthInsurance?.healthPlanName,
        health_insurance_id: patient.healthInsurance?.health_insurance_id,
        healthCard: patient.healthInsurance?.healthCard,
        company: patient.healthInsurance?.company,
        ansRegister: ''
      },
      validateOnMount: true,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async (values) => {
        try {
          setEditInsurance(values.healthCard, values.healthPlanCode)

          await updateHealthInsuranceData.update({
            healthInsuranceCode: values.healthInsuranceCode,
            healthInsuranceName: values.healthInsuranceName,
            healthPlanCode: values.healthPlanCode,
            healthPlanName: values.healthPlanName,
            company: values.company,
            health_insurance_id: values.healthInsuranceCode,
            healthCard: values.healthCard,
            healthSubPlanCode: values.subplanCode,
            healthSubPlanName: values.subplanName,
            ansRegister: values.ansRegister ?? ''
          })

          if (values.frontCard) {
            const formFront = new FormData()
            formFront.append('file', values.frontCard)
            formFront.append('type', PatientDocument.HEALTH_CARD_FRONT)
            await uploadDocument?.upload({
              file: values.frontCard,
              form: formFront,
              type: PatientDocument.HEALTH_CARD_FRONT
            })
          }

          if (values.backCard) {
            const formFront = new FormData()
            formFront.append('file', values.backCard)
            formFront.append('type', PatientDocument.HEALTH_CARD_BACK)
            await uploadDocument?.upload({
              file: values.backCard,
              form: formFront,
              type: PatientDocument.HEALTH_CARD_BACK
            })
          }
          setSuccessModalOpen(true)
        } catch (error: any) {
          toast.error(error)
        }
      }
    })

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: 'frontCard' | 'backCard'
  ) => {
    setFieldValue(name, e.target.files![0])
  }

  function handleLoadPlans(insuranceCode: string, planCode?: number) {
    const selectedHealthInsurance = healthInsurances.find(
      (insurance) => insurance.code === +insuranceCode
    )
    if (selectedHealthInsurance?.plans) {
      setPlans(selectedHealthInsurance.plans)
      setFilteredPlans(selectedHealthInsurance.plans)

      const selectedSubplans = selectedHealthInsurance.plans.find(
        (plan) => plan.code === planCode
      )?.subPlan

      if (selectedSubplans) {
        setSubplans(selectedSubplans)
        setFilteredSubplans(selectedSubplans)
      }
    }
  }

  useEffect(() => {
    if (patient.healthInsurance) {
      handleLoadPlans(
        patient.healthInsurance.healthInsuranceCode.toString(),
        patient.healthInsurance.healthPlanCode
      )
    }
  }, [healthInsurances])

  const handleInsuranceChange = (value: string) => {
    setIsSearching(true)
    const filteredInsurances = healthInsurances.filter((insurance) =>
      insurance.description.toLowerCase().includes(value.toLowerCase())
    )
    setInsurances(filteredInsurances)
    setInsuranceSearchQuery(value)
    setFieldValue('healthInsuranceName', value)
    if (value) {
      setFieldValue('healthPlanName', undefined)
    }
  }

  const handleHealthInsurance = (value: string) => {
    setFieldValue('healthInsuranceCode', value)
    setFieldValue(
      'healthInsuranceName',
      healthInsurances.find((insurance) => insurance.code === +value)
        ?.description
    )
    setFieldValue(
      'ansRegister',
      healthInsurances.find((insurance) => insurance.code === +value)
        ?.ansRegister ?? ''
    )
    setFieldValue('healthPlanCode', 0)
    setPlanSearchQuery('')
    setSubplanSearchQuery('')
    setFieldValue('subplan', '')
    handleLoadPlans(value)
    setInsuranceSearchQuery(
      healthInsurances.find((insurance) => insurance.code === +value)
        ?.description
    )
    setIsSearching(false)
  }

  const handlePlanChange = (value: string) => {
    setIsSearching(true)

    const filteredPlans = plans.filter((plan) =>
      plan.description.toLowerCase().includes(value.toLowerCase())
    )

    filteredPlans.length > 0
      ? setFilteredPlans(filteredPlans)
      : setFilteredPlans([])

    setFieldValue('healthPlanName', value)

    if (value) {
      setFieldValue('subplan', undefined)
    }
    setPlanSearchQuery(value)
  }

  const handlePlans = (value: string) => {
    setFieldValue('healthPlanCode', +value)
    const selectedPlan = filteredPlans.find((plan) => plan.code === +value)
    if (selectedPlan?.subPlan) {
      setFilteredSubplans(selectedPlan?.subPlan)
      setSubplans(selectedPlan?.subPlan)
    }
    setFieldValue('healthPlanName', selectedPlan?.description)
    setPlanSearchQuery(selectedPlan?.description)
    setSubplanSearchQuery('')
    setIsSearching(false)
  }

  const handleSubplanChange = (value: string) => {
    setIsSearching(true)

    const selectedSubplans = subplans.filter((subplan) =>
      subplan.description.toLowerCase().includes(value.toLowerCase())
    )
    selectedSubplans.length > 0
      ? setFilteredSubplans(selectedSubplans)
      : setFilteredSubplans([])

    setSubplanSearchQuery(value)
    if (!value) {
      setIsSearching(false)
    }
  }

  const handleSubplans = (value: string) => {
    setFieldValue('subplanCode', +value)
    const selectedSubplan = subplans.find((subplan) => subplan.code === +value)
    setFieldValue('subplanName', selectedSubplan?.description)
    setSubplanSearchQuery(selectedSubplan?.description)

    setIsSearching(false)
  }

  const handleHealthCard = (value: string) => {
    setFieldValue('healthCard', value)
    setHealthCardQuery(value)
  }

  const handleHealthCardSuggestions = () => {
    if (healthCardQuery) {
      if (
        patient.healthInsurance &&
        patient.healthInsurance.healthCard.indexOf(healthCardQuery) == 0
      ) {
        return [
          {
            label: patient.healthInsurance?.healthCard,
            value: patient.healthInsurance?.healthCard
          }
        ]
      }
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <SheetModal
      size={windowDimensions.width < 550 ? 650 : 550}
      isOpen={open}
      onClose={onClose}
      backdrop="false"
    >
      <div style={{ marginBottom: '20px' }} role="edit-health-insurance">
        <Heading size="large">Editar convênio</Heading>
      </div>
      <Container
        noPadding
        primaryButton={
          <Button
            fullWidth
            style={{ marginTop: '20px' }}
            disabled={!isSearching ? !isValid : isSearching}
            onClick={() => {
              submitForm()
            }}
          >
            Confirmar
          </Button>
        }
      >
        <div style={{ textAlign: 'start' }}>
          <AutoComplete
            label="Convênio"
            bgColor="mainBg"
            suggestions={insurances.map((insurance) => ({
              label: insurance.description,
              value: insurance.code
            }))}
            name="health-insurance"
            value={insuranceSearchQuery}
            onSuggestionClick={(value) => handleHealthInsurance(value)}
            onType={(value) => handleInsuranceChange(value)}
            onInputChange={(value) => handleInsuranceChange(value)}
            required
            data-testid="health-insurance-autocomplete"
            error={errors.healthInsuranceName}
            defaultValue={patient?.healthInsurance?.healthInsuranceName}
          />
          <AutoComplete
            label="Nº da carteirinha"
            bgColor="mainBg"
            suggestions={handleHealthCardSuggestions()}
            name="health-card"
            value={healthCardQuery}
            onSuggestionClick={(value) => handleHealthCard(value)}
            onInputChange={(value) => handleHealthCard(value)}
            required
            data-testid="health-insurance-autocomplete"
            error={errors.healthCard}
            style={{ marginTop: 20 }}
            defaultValue={patient?.healthInsurance?.healthCard}
            maxLength={30}
            type="number"
          />
          <AutoComplete
            label="Plano"
            bgColor="mainBg"
            suggestions={filteredPlans.map((plan) => ({
              label: plan.description,
              value: plan.code
            }))}
            name="health-plan"
            value={planSearchQuery}
            onSuggestionClick={(value) => handlePlans(value)}
            onType={(value) => handlePlanChange(value)}
            onInputChange={(value) => handlePlanChange(value)}
            required
            data-testid="health-plan-autocomplete"
            error={errors.healthPlanName}
            defaultValue={patient?.healthInsurance?.healthPlanName}
            style={{ marginTop: 20 }}
          />
          <AutoComplete
            label="Subplano"
            bgColor="mainBg"
            suggestions={filteredSubplans.map((subplan) => ({
              label: subplan.description,
              value: subplan.code
            }))}
            name="health-subplan"
            value={subplanSearchQuery}
            onSuggestionClick={(value) => handleSubplans(value)}
            onType={(value) => handleSubplanChange(value)}
            onInputChange={(value) => handleSubplanChange(value)}
            data-testid="health-subplan-autocomplete"
            error={errors.subplanName}
            style={{ marginTop: 20 }}
            defaultValue={patient?.healthInsurance?.healthSubPlanName}
          />
          <TextField
            label="Empresa"
            bgColor="mainBg"
            style={{ marginTop: 20 }}
            defaultValue={patient?.healthInsurance?.company}
            value={values.company}
            onChange={handleChange('company')}
            required
            error={errors.company}
            maxLength={60}
          />
          <div style={{ marginTop: '10px' }}>
            <SupportText style={{ color: theme.colors.primary, marginTop: 20 }}>
              Anexe abaixo as imagens da sua carteirinha do convênio
            </SupportText>
            <MultimediaFileInput
              name="frontCard"
              id="frontCard"
              text="Frente"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFileChange(event, 'frontCard')
              }}
              maxFileSizeMB={6}
            />
            <MultimediaFileInput
              name="backCard"
              id="backCard"
              text="Verso"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFileChange(event, 'backCard')
              }}
              maxFileSizeMB={6}
            />
          </div>
        </div>
      </Container>
      <SheetModal
        style={{ padding: 0 }}
        size={300}
        isOpen={successModalOpen}
        onClose={onClose}
        backdrop="true"
      >
        <Heading size="large">
          Convênio editado com
          <p style={{ color: theme.colors.primary }}>sucesso</p>
        </Heading>
        <p>Seus dados do convênio foram alterados com sucesso</p>
        <Button
          color={theme.colors.primary}
          style={{ marginTop: '20px' }}
          onClick={() => {
            setSuccessModalOpen(false)
            onConfirm()
          }}
        >
          Confirmar
        </Button>
      </SheetModal>
    </SheetModal>
  )
}

const validationSchema = yup.object().shape({
  healthInsuranceCode: yup.number().optional(),
  healthInsuranceName: yup.string().required(),
  ansRegister: yup.string().optional(),
  healthPlanCode: yup.number().optional(),
  healthPlanName: yup.string().required(),
  subplan: yup.string().optional(),
  company: yup.string().required(),
  healthCard: yup.string().required()
})
