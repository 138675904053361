import { GetExamPreparation } from 'domain/usecases/exam/get-exam-preparation'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamPreparation implements GetExamPreparation {
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: GetExamPreparation.Params
  ): Promise<GetExamPreparation.Model> {
    return this.examRepository.getExamPreparation(params)
  }
}

export type GetExamPreparationModel = GetExamPreparation.Model
