import React from 'react'
import * as S from './styles'
import SupportTextNew from '../SupportTextNew'
import theme from 'presentation/styles/theme'

export type Props = {
  status:
    | 'scheduled'
    | 'SCHEDULED'
    | 'unscheduled'
    | 'UNSCHEDULED'
    | 'PENDENT'
    | 'pendent'
}

const BadgeStatusNew = ({ status }: Props) => {
  const getStatus = () => {
    switch (status) {
      case 'scheduled':
      case 'SCHEDULED':
        return {
          label: 'Agendado',
          color: theme.ds.colors.primary600
        }
      case 'unscheduled':
      case 'UNSCHEDULED':
        return {
          label: 'Não agendado',
          color: theme.ds.colors.terciary500
        }
      case 'PENDENT':
        return {
          label: 'À resolver',
          color: theme.ds.colors.terciary700
        }
      default:
        return {
          label: 'Não agendado',
          color: theme.ds.colors.terciary500
        }
    }
  }

  return (
    <S.StatusWrapper>
      <S.StatusCircle color={getStatus().color} />
      <SupportTextNew>{getStatus().label}</SupportTextNew>
    </S.StatusWrapper>
  )
}

export default BadgeStatusNew
