import React, { useState } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { ReactComponent as AccordionArrow } from 'presentation/assets/icons/accordion-arrow.svg'
import { WithLoading } from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'

import HeaderNew from 'presentation/components/HeaderNew'
import { useHistory, useLocation } from 'react-router-dom'
import DividerNew from 'presentation/shared/components/DividerNew'

const ExamPreparationInstructionsLayout = WithLoading(() => {
  const history = useHistory()
  const { selectedExam, hasParamToken } = useLocation().state as any

  type LocationType = {
    state: {
      prevPage?: string
      activeIndex?: number
    }
  }

  function getActiveIndex() {
    if (prevPage === '/agendamento/exames') {
      return 0
    }
    return null
  }

  const backToPreviewsPage = () => {
    history.push(prevPage || '/exames/preparos', {
      updateHealthInsurances: true,
      activeIndex: getActiveIndex()
    })
  }

  const location: LocationType = useLocation()
  const prevPage = location.state && location.state.prevPage

  return (
    <>
      {!hasParamToken && <HeaderNew actualPageOnClick={backToPreviewsPage} />}

      <ContainerNew
        title="Instruções de preparo"
        titleColor="neutral700"
        titleWeight="bold"
        titleSize="large"
        actualPageText={hasParamToken ? 'Voltar' : ''}
        actualPageOnClick={() => hasParamToken && history.goBack()}
      >
        <S.Wrapper>
          {selectedExam?.length > 0 &&
            selectedExam.map((examPreparation: any) => {
              return (
                <PreparationAccordion
                  key={examPreparation.exam_id}
                  examPreparation={examPreparation}
                />
              )
            })}
        </S.Wrapper>
      </ContainerNew>
    </>
  )
})

export default ExamPreparationInstructionsLayout

const PreparationAccordion = ({ examPreparation }: any) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <S.AccordionContainer
        isVisible={isVisible}
        onClick={() => setIsVisible(!isVisible)}
      >
        <S.PurpleStrong className="capitalize-fl">
          {examPreparation.title}
        </S.PurpleStrong>

        <div>
          <AccordionArrow />
        </div>
      </S.AccordionContainer>
      {!isVisible && (
        <div onClick={() => setIsVisible(!isVisible)}>
          <S.SubTitleHeader>{examPreparation.description}</S.SubTitleHeader>
        </div>
      )}
      <DividerNew />
    </>
  )
}
