import { useLocation } from 'react-router-dom'
import isBase64 from 'presentation/utils/is-base-64'

function useSearchParams() {
  const params = useLocation().search?.replace('?', '')
  const decodedParams = isBase64(params) ? atob(params) : params

  return new URLSearchParams(decodedParams)
}

export { useSearchParams }
