import { UpdateExam } from 'domain/usecases/patient/update-exam'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteExamUpdate implements UpdateExam {
  constructor(private patientRepository: PatientRepository) {}

  async update(params: UpdateExam.Params): Promise<UpdateExam.Model> {
    return this.patientRepository.updateExam(params)
  }
}
