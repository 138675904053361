import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 10px 0;
  padding: 20px;
  border-radius: ${({ theme }) => theme.border.radius.large};
  background-color: ${({ theme }) => theme.colors.white};
`

export const TextContainer = styled.div`
  margin: 10px 0;

  p {
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
  }
`
export const Image = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius.large};
`
