import { ApiSchedulingImplementation } from 'infra/api-scheduling/api-scheduling-client'
import { PrepareApiRequestSchedulingDecorator } from 'main/decorators/prepare-api-request-scheduling-decorator/prepare-api-request-scheduling-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiSchedulingRepository } from 'repository/api-scheduling-repository'
import { SchedulingRepository } from 'repository/repositories/scheduling/scheduling-repository'
import { RemoteSearchScheduling } from 'service/usecases/scheduling/remote-search-schedules/remote-search-scheduling'
import { RemoteGetSchedules } from 'service/usecases/scheduling/remote-get-schedules/remote-get-schedules'
import { RemoteCreateSchedule } from 'service/usecases/scheduling/remote-create-schedule/remote-create-schedule'

import { RemoteGetSchedulesByMonth } from 'service/usecases/scheduling/remote-get-appointments-by-month/remote-get-appointments-by-month'
import { RemoteGetHoursByDay } from 'service/usecases/scheduling/remote-get-hours-by-day/remote-get-hours-by-day'
import { SchedulingService } from 'presentation/contexts/services/scheduling-service'
import { RemoteGetReasonCancel } from 'service/usecases/scheduling/remote-get-reason-cancel/remote-get-reason-cancel'
import { RemoteDeleteSchedule } from 'service/usecases/scheduling/remote-delete-schedule/remote-delete-schedule'
import { RemoteListSchedules } from 'service/usecases/scheduling/remote-list-schedules/remote-list-schedules'

export default function makeSchedulingService() {
  const apiScheduling = new ApiSchedulingImplementation()
  const apiSchedulingRepository = new PrepareApiRequestSchedulingDecorator(
    makeLocalStorageAdapter(),
    new ApiSchedulingRepository(apiScheduling)
  )
  const schedulingRepository = new SchedulingRepository(apiSchedulingRepository)

  const searchFiltersSchedules = new RemoteSearchScheduling(
    schedulingRepository
  )
  const getSchedules = new RemoteGetSchedules(schedulingRepository)
  const getSchedulesByMonth = new RemoteGetSchedulesByMonth(
    schedulingRepository
  )
  const createSchedule = new RemoteCreateSchedule(schedulingRepository)
  const getHoursByDay = new RemoteGetHoursByDay(schedulingRepository)
  const getReasonCancel = new RemoteGetReasonCancel(schedulingRepository)
  const deleteSchedule = new RemoteDeleteSchedule(schedulingRepository)
  const listSchedules = new RemoteListSchedules(schedulingRepository)

  return new SchedulingService({
    searchFiltersSchedules,
    getSchedules,
    getSchedulesByMonth,
    getHoursByDay,
    createSchedule,
    listSchedules,
    getReasonCancel,
    deleteSchedule
  })
}
