import { CompleteDoctorRegister } from 'domain/usecases/doctor/complete-doctor-register'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteCompleteDoctorRegister implements CompleteDoctorRegister {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  execute(
    params: CompleteDoctorRegister.Params
  ): Promise<CompleteDoctorRegister.Model> {
    return this.doctorRepository.completeDoctorRegister(params)
  }
}
