import React, { useState, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'

import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import HealthInsuranceCard from 'presentation/shared/components/HealthInsuranceCard'
import ButtonNew from 'presentation/components/ButtonNew'
import { ReactComponent as PlusCircleIcon } from 'presentation/assets/icons/plus-circle.svg'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

type LocationType = {
  state: {
    patient: SchedulingPatient
    updateHealthInsurances?: boolean
  }
  pathname?: string
}

const SchedulingHealthInsuranceLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const location: LocationType = useLocation()
    const patientService = useServices().patient
    const patientState = location.state && location.state.patient
    const updateHealthInsurances =
      location.state && location.state.updateHealthInsurances
    const [patientInfo, setPatientInfo] = useState<SchedulingPatient>(
      {} as SchedulingPatient
    )
    const [healthInsurances, setHealthInsurances] =
      useState<PatientHealthInsurance[]>()

    const particularHealthInsurance = {
      healthInsuranceName: 'Consulta particular',
      healthPlanName: 'Particular',
      healthInsuranceCode: 304,
      healthPlanCode: 1
    }

    const HealthInsuranceList = () => {
      return healthInsurances?.map(
        (healthInsurance: PatientHealthInsurance, index) => (
          <div key={index}>
            <HealthInsuranceCard
              style={{ marginBottom: '24px' }}
              healthInsurance={healthInsurance}
              showOnly={true}
              disabled={healthInsurance.healthInsuranceCode === 396} // Block ipsemg health insurance
              onClick={
                healthInsurance.healthInsuranceCode === 396
                  ? () => undefined // Block ipsemg health insurance
                  : () =>
                      history.push('/agendamento/consulta/busca', {
                        patient: patientInfo,
                        selectedHealthInsurance: healthInsurance
                      })
              }
            />
            {/* Ipsemg health insurance message */}
            {healthInsurance?.healthInsuranceCode === 396 && (
              <S.IpsemgInfo>
                Agendamentos pelo IPSEMG são exclusivos por telefone:{' '}
                <a
                  href="tel:3133399000"
                  target="_blank"
                  rel="noreferrer"
                  title="Ligue para (31) 3339-9000"
                >
                  Ligue para (31) 3339-9000
                </a>
              </S.IpsemgInfo>
            )}
          </div>
        )
      )
    }

    useEffect(() => {
      async function loadPatientData() {
        setIsLoading(true)
        try {
          const response = await patientService.loadPatientInfo([
            'patient_id',
            'user_id',
            'name',
            'birthday',
            'gender',
            'phone',
            'email',
            'cpf',
            `
              healthInsurances {
                health_insurance_id
                healthCard
                healthInsuranceCode
                healthInsuranceName
                healthPlanCode
                healthPlanName
                healthSubPlanCode
                healthSubPlanName
                ansRegister
                validThru
              }
            `
          ])

          const schedulingPatientData = {
            patient_id: response?.patient_id,
            user_id: response?.user_id,
            name: response?.name,
            age: Number(
              getAgeByBirthdayDate(
                moment(response?.birthday).format('DD/MM/YYYY')
              )
            ),
            gender: response?.gender,
            email: response?.email,
            phone: response?.phone,
            healthInsurances: response?.healthInsurances
          } as SchedulingPatient

          setPatientInfo(schedulingPatientData)
          setHealthInsurances(response.healthInsurances)
        } catch (error: any) {
          toast.error('Ocorreu um erro ao carregar os dados')
        } finally {
          setIsLoading(false)
        }
      }

      if (
        patientState &&
        !!patientState.healthInsurances &&
        !updateHealthInsurances
      ) {
        setPatientInfo(patientState)
        setHealthInsurances(patientState.healthInsurances)
      } else {
        loadPatientData()
      }
    }, [])

    return (
      <>
        <HeaderNew
          actualPageTitle="Agendar consulta"
          actualPageOnClick={() =>
            history.push('/agendamento/consulta/confirmar-dados')
          }
        />
        <ContainerNew
          title="Particular ou convênio?"
          subtitle="Selecione uma opção para continuar."
        >
          <S.Wrapper>
            <S.Content>
              <HealthInsuranceCard
                particular={true}
                showOnly={true}
                title="Com pagamento no local."
                description="Aceitamos cartões de débito e crédito, Pix e dinheiro."
                onClick={() =>
                  history.push('/agendamento/consulta/busca', {
                    patient: patientInfo,
                    selectedHealthInsurance: particularHealthInsurance
                  })
                }
              />
              <S.Divider />
              <ButtonNew
                variant="text"
                icon={<PlusCircleIcon />}
                noPadding={true}
                type="button"
                onClick={() =>
                  history.push('/convenio/novo', {
                    prevPage: location.pathname
                  })
                }
                style={{ marginBottom: '24px' }}
              >
                Cadastrar convênio
              </ButtonNew>

              {!!healthInsurances && HealthInsuranceList()}
            </S.Content>
          </S.Wrapper>
        </ContainerNew>
      </>
    )
  }
)

export default SchedulingHealthInsuranceLayout
