import { ContactsModel } from 'domain/entities/contacts'
import { IShareExamResult } from 'domain/usecases/exam/share-exam-result'
import { useServices } from 'presentation/hooks/use-services'
import SharedProcedureResultLayout from 'presentation/layouts/SharedProcedureResult'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import Modal from 'presentation/shared/components/Modal'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
  shareExamResult: IShareExamResult
} & WithLoadingProps

const SharedProcedureResult = WithLoading(
  ({ setIsLoading, shareExamResult }: Props) => {
    const [contacts, setContacts] = React.useState<ContactsModel[]>(
      [] as ContactsModel[]
    )

    const [successModal, setSuccesModal] = React.useState(false)
    const [errorModal, setErrorModal] = React.useState(false)

    const userService = useServices().user
    const history = useHistory()

    React.useEffect(() => {
      ;(async () => {
        try {
          setIsLoading(true)
          const result = await userService.loadContacts([
            'id',
            'emailContact',
            'name'
          ])

          setContacts(result)
        } catch (error) {
          toast.error('Não foi possível carregar os contatos')
        } finally {
          setIsLoading(false)
        }
      })()
    }, [])

    const submitForm = async (params: IShareExamResult.Params) => {
      try {
        setIsLoading(true)
        const result = await shareExamResult.share(params)
        if (result) {
          setSuccesModal(true)
          setTimeout(() => {
            setSuccesModal(false)
            history.goBack()
          }, 2000)
        } else {
          setErrorModal(true)
        }
      } catch (error) {
        setErrorModal(true)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <>
        <Modal
          title="E-mail enviado com sucesso!"
          type="check"
          show={successModal}
        />

        <Modal
          title="Erro ao enviar email. Tente novamente"
          type="error"
          show={errorModal}
          close={() => setErrorModal(false)}
        />

        <SharedProcedureResultLayout
          contacts={contacts}
          onSubmitForm={submitForm}
        />
      </>
    )
  }
)

export default SharedProcedureResult
