import styled, { css } from 'styled-components'
import NewExamBg from 'presentation/assets/banners/new-exam.svg'

type WrapperProps = {
  confirmated: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  display: flex;
  border-radius: 8px;
  background-image: url(${NewExamBg});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 2rem 0.9rem;

  .options-div {
    margin-top: 10.13px;
  }

  ${({ confirmated }) =>
    !confirmated &&
    css`
      min-height: 500px;
    `}
`

export const Content = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .text-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 10px;
  }
`
export const Text = styled.p`
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 600;

  /* color: ${({ theme }) => theme.colors.gray}; */
  color: #4a4a4a;
  margin-bottom: 12px;
`
