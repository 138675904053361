// import { Notification } from 'domain/entities/notification-model'
import { LoadNotificationsByPendency } from 'domain/usecases/notification/load-notifications-by-pendency'
import { NotificationRepository } from 'repository/interfaces/notification-repository'

export class RemoteLoadNotificationsByPendency
  implements LoadNotificationsByPendency
{
  constructor(
    private readonly notificationRepository: NotificationRepository
  ) {}

  async load(
    params: LoadNotificationsByPendency.Params
  ): Promise<LoadNotificationsByPendency.Model> {
    return this.notificationRepository.loadNotificationsByPendency(params)
  }
}

export type LoadNotificationsByPendencyModel = LoadNotificationsByPendency.Model
