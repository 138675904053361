import React from 'react'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import HealthInsurance from 'presentation/pages/HealthInsuranceUpdate'
import { RemoteUpdatePatientHealthInsuranceInMv } from 'service/usecases/patient-update-health-insurance-in-mv/remote-patient-update-health-insurance-in-mv'
import { RemoteSearchHealthInsurances } from 'service/usecases/search-health-insurances/remote-search-health-insurances'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'

export default function makePatientHealthInsurancesInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)

  const searchHealthInsurances = new RemoteSearchHealthInsurances(
    healthInsuranceRepository
  )
  const updateHealthInsurance = new RemoteUpdatePatientHealthInsuranceInMv(
    patientRepository
  )

  return (
    <HealthInsurance
      pageTitle="Editar convênio"
      primaryButtonText="Salvar"
      searchHealthInsurances={searchHealthInsurances}
      addOrUpdateHealthInsurance={updateHealthInsurance}
    />
  )
}
