import { Hospital } from 'domain/entities/hospital-model'
import { Patient } from 'domain/entities/patient-model'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import ActualPage from 'presentation/components/ActualPage'
import { CarouselState } from 'presentation/components/Carousel'
import ConditionalCarousel from 'presentation/components/Carousel/ConditionalCarousel'
import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'
import theme from 'presentation/styles/theme'
import React, { useState } from 'react'
import { PatientAccompanying } from './PatientAccompanying'
import { PatientPersonalData } from './PatientPersonalData'
import { PatientSelectUnit } from './PatientSelectUnit'
import * as S from './styles'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'

type EmergencyRoomPatientInfoProps = {
  patient: Patient
  setPatient: (patient: Patient) => void
  carousel: CarouselState
  setStep: (number: number) => void
  updatePatientPersonalData: UpdatePatientInfoById
  addAccompanying: AddAccompanying
  setForm: (data: SubmitEmergencyAttendance) => void
  form?: SubmitEmergencyAttendance
  hospitals: Hospital[]
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  uploadDocument?: UploadPatientDocument
  editPatient: (patient: Patient) => void
  editHealthInsurance: () => void
  updatedHealthInsurance: boolean
  updateAccompanies: () => void
  backPatientCarousel: (number: number) => void
  patientBackStep?: number
}

export function EmergencyRoomPatientInfo({
  carousel,
  patient,
  setStep,
  updatePatientPersonalData,
  addAccompanying,
  form,
  setForm,
  hospitals,
  setPatient,
  updateHealthInsuranceData,
  uploadDocument,
  editPatient,
  editHealthInsurance,
  updatedHealthInsurance,
  updateAccompanies,
  patientBackStep,
  backPatientCarousel
}: EmergencyRoomPatientInfoProps) {
  const [patientCarousel, setPatientCarousel] = useState({} as CarouselState)

  const subTitles = [
    'Confira seus dados para realizar sua solicitação.',
    'Informe alguns dados para realizar a sua solicitação.'
  ]

  return (
    <>
      <S.PatientInfoHeader>
        <ActualPage
          onClick={() => {
            if (patientCarousel.activeIndex === 0) carousel.slidePrev()
            else patientCarousel.slidePrev()
          }}
          text="Voltar"
          color="primary"
        />
        <Heading size="medium" color="primary" style={{ marginBottom: '10px' }}>
          Dados Pessoais
        </Heading>
        <SupportText
          style={{ fontSize: theme.font.sizes.medium }}
          color="lightGray"
        >
          {subTitles[patientCarousel.activeIndex]}
        </SupportText>
      </S.PatientInfoHeader>
      <ConditionalCarousel setState={setPatientCarousel}>
        <PatientPersonalData
          patient={patient}
          patientCarousel={patientCarousel}
          updatePatientPersonalData={updatePatientPersonalData}
          setPatient={setPatient}
          editPatient={(patient) => editPatient(patient)}
          patientBackStep={patientBackStep}
        />
        <PatientSelectUnit
          patientCarousel={patientCarousel}
          setForm={setForm}
          setStep={setStep}
          form={form}
          hospitals={hospitals}
          patient={patient}
          updateHealthInsuranceData={updateHealthInsuranceData}
          uploadDocument={uploadDocument}
          editHealthInsurance={() => editHealthInsurance()}
          updatedHealthInsurance={updatedHealthInsurance}
          backPatientCarousel={backPatientCarousel}
        />
        <PatientAccompanying
          carousel={carousel}
          patient={patient}
          setStep={setStep}
          addAccompanying={addAccompanying}
          setForm={setForm}
          form={form}
          updateAccompanies={updateAccompanies}
        />
      </ConditionalCarousel>
    </>
  )
}
