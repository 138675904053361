import React from 'react'

import { UpdateMedicineAndDiseases } from 'domain/usecases/patient/update-medicine-and-diseases'
import MedicinesAndDiseasesLayout from 'presentation/layouts/MedicinesAndDiseases'

type Props = {
  updateMedicineAndDiseases: UpdateMedicineAndDiseases
}

export default function MedicineAndDiseases({
  updateMedicineAndDiseases
}: Props) {
  return (
    <MedicinesAndDiseasesLayout
      updateMedicineAndDiseases={updateMedicineAndDiseases}
    />
  )
}
