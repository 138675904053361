import styled from 'styled-components'

// type WrapperProps = {
//
// }

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 16px;
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  /* border: 1px solid red; */

  img {
    width: 240px;
    height: 300px;
    position: relative;
    object-position: center;
    object-fit: contain;
  }
`

export const Title = styled.h4`
  margin-top: 24px;
  color: ${({ theme }) => theme.ds.colors.primary600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  /* border: 2px solid blue; */
`

export const Description = styled.p`
  margin-top: 8px;
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  line-height: 1.3;
  /* border: 2px solid green; */
`
