import { ApiClientImplementation } from 'infra/api-client/api-client'
import {
  getCurrentUserAdapter,
  removeCurrentUserAdapter,
  setCurrentUserAdapter
} from 'main/adapters/current-user/current-user-adapter'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import CurrentUser from 'presentation/contexts/user/current-user-context'
import { ApiRepository } from 'repository/api-repository'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { RemoteLogout } from 'service/usecases/logout/remote-logout'
import { DependentRepository } from 'repository/repositories/dependent/dependent-repository'
import { RemoteChangeDependent } from 'service/usecases/change-dependent/remote-change-dependent'

export default function makeCurrentUser() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )

  const dependentRepository = new DependentRepository(authApiRepository)

  const changeDependent = new RemoteChangeDependent(dependentRepository)

  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )

  const logout = new RemoteLogout(authenticationRepository)

  return new CurrentUser(
    setCurrentUserAdapter,
    getCurrentUserAdapter,
    removeCurrentUserAdapter,
    changeDependent,
    logout
  )
}
