import { AnswerNps } from 'domain/usecases/tracksale/answer-nps'
import { TracksaleRepository } from 'repository/interfaces/tracksale-repository'

export class RemoteAnswerNps implements AnswerNps {
  constructor(private readonly tracksaleRepository: TracksaleRepository) {}

  post(params: AnswerNps.Params): Promise<AnswerNps.Model> {
    return this.tracksaleRepository.answerNps(params)
  }
}

export type SearchDoctorsModel = AnswerNps.Model
