import { AmbulatoryContact } from 'domain/usecases/ambulatory/ambulatory-contact'
import { AmbulatoryRepository } from 'repository/interfaces/ambulatory-repository'

export class RemoteAmbulatoryContact implements AmbulatoryContact {
  constructor(private readonly ambulatoryRepository: AmbulatoryRepository) {}

  async contact(
    params: AmbulatoryContact.Params,
    patient_id: number,
    ambulatory_schedule_id: number
  ): Promise<AmbulatoryContact.Model> {
    return await this.ambulatoryRepository.ambulatoryContact(
      params,
      patient_id,
      ambulatory_schedule_id
    )
  }
}

export type RemoteAmbulatoryContactModel = AmbulatoryContact.Model
