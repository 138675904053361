import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import theme from 'presentation/styles/theme'
import SupportCard from '../ConfirmedAttendanceModal/SupportCard'
import { handleUnityPhone } from 'common/utils/handleUnityPhone'
import React, { useState, useEffect } from 'react'

type FailedModalProps = {
  onConfirm: () => void
  onClose: () => void
  open: boolean
  hospital_id?: number
}

export function FailedModal({
  onConfirm,
  onClose,
  open,
  hospital_id
}: FailedModalProps) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <SheetModal
      size={windowDimensions.width < 450 ? 700 : 450}
      isOpen={open}
      onClose={onClose}
    >
      <Heading size="large">
        Atendimento{' '}
        <strong style={{ color: theme.colors.red }}>não autorizado</strong>
      </Heading>
      <SupportText style={{ marginTop: 10 }}>
        Prezado(a) cliente,
        <p style={{ fontWeight: theme.font.bold }}>
          O seu atendimento não foi autorizado
        </p>
        pela sua Operadora de saúde.
      </SupportText>
      <SupportCard>
        Pedimos para conferir se os dados da carteirinha foram digitados
        corretamente. Caso
        <p style={{ fontWeight: theme.font.bold }}>
          as informações estejam corretas,
        </p>
        gentileza entrar em contato com a Operadora para verificar o motivo da
        não elegibilidade/autorização
        <strong style={{ fontWeight: theme.font.light }}>
          {' '}
          {handleUnityPhone(hospital_id)}.{' '}
        </strong>
        A nossa equipe está à disposição se necessitar de algum auxílio sobre o
        assunto ou para ter informações sobre atendimentos particulares.
      </SupportCard>
      <Button fullWidth style={{ marginTop: '20px' }} onClick={onConfirm}>
        Continuar
      </Button>
    </SheetModal>
  )
}
