import { makeAutoObservable } from 'mobx'
import { CreateDoctorRegisterPendency } from 'domain/usecases/doctor-register/enable-doctor-register-pendency'
import { SendDoctorRegisterToDirector } from 'domain/usecases/doctor-register/send-doctor-register-to-director'
import { CreateDoctorRegisterReview } from 'domain/usecases/doctor-register-review/create-doctor-register-review'

type Services = {
  createDoctorRegisterPendency: CreateDoctorRegisterPendency
  sendDoctorRegisterToDirector: SendDoctorRegisterToDirector
  createDoctorRegisterReview: CreateDoctorRegisterReview
}

export class DoctorRegisterService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async createDoctorRegisterPendency(
    params: CreateDoctorRegisterPendency.Params
  ): Promise<CreateDoctorRegisterPendency.Model> {
    return this.services.createDoctorRegisterPendency.execute(params)
  }

  async sendDoctorRegisterToDirector(
    params: SendDoctorRegisterToDirector.Params
  ): Promise<SendDoctorRegisterToDirector.Model> {
    return this.services.sendDoctorRegisterToDirector.execute(params)
  }

  async createDoctorRegisterReview(
    params: CreateDoctorRegisterReview.Params
  ): Promise<CreateDoctorRegisterReview.Model> {
    return this.services.createDoctorRegisterReview.execute(params)
  }
}
