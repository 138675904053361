import { CreateSchedule } from 'domain/usecases/scheduling/create-schedule'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteCreateSchedule implements CreateSchedule {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: CreateSchedule.Params): Promise<CreateSchedule.Model> {
    return this.schedulingRepository.createSchedule(params)
  }
}

export type FinishScheduleModel = CreateSchedule.Model
