import React from 'react'
import * as S from './styles'
import CardNew from 'presentation/shared/components/CardNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import BadgeStatusNew from 'presentation/components/BadgeStatusNew'
import StatusTag from 'presentation/components/StatusTag'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendency-icon.svg'
import theme from 'presentation/styles/theme'
import moment from 'moment'
import {
  SurgicalOrdersStatusTranslatedSingular,
  SurgicalOrderStatus
} from 'common/enum/surgical-orders-status'
import { ReactComponent as AlertIcon } from 'presentation/assets/icons/alert-triangle-filled.svg'
import { TooltipNew } from 'presentation/shared/components/TooltipNew'
import { PasswordMaxDateValidationStatus } from 'common/enum/password-max-date-validation-status'
import { SurgeryType, SurgeryTypeLocated } from 'common/enum/surgery-type'

type Props = {
  surgicalOrder: any
  footerColor?: keyof typeof theme.ds.colors
  onClick?: (surgical_order_id: number) => void
  hideFooter?: boolean
  hideStatusTag?: boolean
  flatten?: boolean
}

export default function SurgicalOrderCard({
  surgicalOrder,
  footerColor = 'terciary400',
  onClick,
  hideFooter = false,
  hideStatusTag = false,
  flatten = false
}: Props) {
  const getDateLabel = () => {
    switch (surgicalOrder.scheduling_status) {
      case 'scheduled':
      case 'SCHEDULED':
        return {
          label: 'Data prevista para a cirurgia',
          key: 'scheduled_date'
        }
      default:
        return {
          label: 'Data prevista para a cirurgia',
          key: 'default_unscheduled'
        }
    }
  }

  const statusIsSolicitation = (status: SurgicalOrderStatus) =>
    status === SurgicalOrderStatus.ORDER_REGISTERED

  const mainProcedure = () => {
    if (surgicalOrder?.surgery_type) {
      return SurgeryTypeLocated[surgicalOrder.surgery_type as SurgeryType]
    }

    return surgicalOrder.procedures
      ?.find((procedure: any) => procedure.isMain)
      ?.description?.toLowerCase()
  }

  const shouldShowExpirationDateAlert = () =>
    surgicalOrder.finalize_surgical_order.password_max_date_validation_status &&
    surgicalOrder.finalize_surgical_order
      .password_max_date_validation_status !==
      PasswordMaxDateValidationStatus.VALID

  return (
    <CardNew
      background={onClick || flatten ? 'neutral25' : 'white'}
      shadow={onClick && !flatten ? 'satin' : 'none'}
      border={onClick || flatten ? 'none' : 'primary300'}
      onClick={
        onClick ? () => onClick(surgicalOrder.surgical_order_id) : undefined
      }
    >
      <S.CardHeader>
        <SupportTextNew weight="bold" color="neutral900">
          {statusIsSolicitation(surgicalOrder.active_status) ? (
            <>
              {
                SurgicalOrdersStatusTranslatedSingular[
                  surgicalOrder.active_status as SurgicalOrderStatus
                ]
              }
            </>
          ) : (
            <>Aviso {surgicalOrder.surgical_order_id}</>
          )}
        </SupportTextNew>

        <BadgeStatusNew status={surgicalOrder.scheduling_status} />
      </S.CardHeader>

      <DividerNew />

      <S.CardBody>
        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Paciente
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.patient?.name.toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Procedimento
          </SupportTextNew>
          <SupportTextNew capitalize>{mainProcedure() || '-'}</SupportTextNew>
        </div>

        {surgicalOrder?.doctor?.name && (
          <div>
            <SupportTextNew weight="bold" color="neutral900" size="xsmall">
              Médico
            </SupportTextNew>
            <SupportTextNew capitalize>
              {surgicalOrder.doctor.name.toLowerCase()}
            </SupportTextNew>
          </div>
        )}

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Convênio
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.health_insurance?.name.toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            {getDateLabel().label}
          </SupportTextNew>
          <SupportTextNew>
            {surgicalOrder.scheduling_date.map((date: any) =>
              date[getDateLabel().key]
                ? moment(date[getDateLabel().key])
                    .utc()
                    .format('DD [de] MMM[.] [de] YYYY')
                : 'Não agendada'
            )}
          </SupportTextNew>
        </div>

        <div>
          <SupportTextNew weight="bold" color="neutral900" size="xsmall">
            Unidade
          </SupportTextNew>
          <SupportTextNew capitalize>
            {surgicalOrder.hospital?.name
              ?.replace(/^\d+\s*-\s*/, '')
              .toLowerCase() || '-'}
          </SupportTextNew>
        </div>

        {shouldShowExpirationDateAlert() ? (
          <TooltipNew
            text={
              surgicalOrder.finalize_surgical_order
                .password_max_date_validation_status ==
              PasswordMaxDateValidationStatus.EXPIRED
                ? 'A senha de autorização do convênio está vencida e será necessário revalidação para realização da cirurgia'
                : 'A senha de autorização do convênio está próxima ao vencimento. Entre em contato com seu médico e agende sua cirurgia dentro do prazo de validade.'
            }
          >
            <div>
              <SupportTextNew weight="bold" color="neutral900" size="xsmall">
                Senha de autorização
              </SupportTextNew>
              <SupportTextNew>
                <S.AlertIconWrapper
                  status={
                    surgicalOrder.finalize_surgical_order
                      .password_max_date_validation_status
                  }
                >
                  <AlertIcon />
                  {`Válida até ${moment(
                    surgicalOrder.finalize_surgical_order
                      .password_max_date_validation
                  )
                    .utc()
                    .format('DD/MM/YYYY')}`}
                </S.AlertIconWrapper>
              </SupportTextNew>
            </div>
          </TooltipNew>
        ) : (
          ''
        )}

        {!hideStatusTag && surgicalOrder.active_status && (
          <StatusTag status={surgicalOrder.active_status} />
        )}
      </S.CardBody>

      {surgicalOrder.pendencies && !hideFooter && (
        <S.CardFooter color={footerColor}>
          <SupportTextNew
            weight="bold"
            color="neutral900"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <PendencyIcon style={{ marginRight: '8px' }} />
            Pendências
          </SupportTextNew>
          <S.BadgeCounter color={footerColor}>
            {surgicalOrder.pendencies.total}
          </S.BadgeCounter>
        </S.CardFooter>
      )}
    </CardNew>
  )
}
