const isBase64 = (params: string) => {
  try {
    const replacedParams = params.replace('?', '')
    return btoa(atob(replacedParams)) === replacedParams
  } catch (err) {
    return false
  }
}

export default isBase64
