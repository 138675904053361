import { CreateTherapeuticPlan } from 'domain/usecases/therapeutic-plan/create-therapeutic-plan'
import { LoadProtocols } from 'domain/usecases/therapeutic-plan/load-protocols'
import { UploadTherapeuticPlanDocument } from 'domain/usecases/therapeutic-plan/upload-therapeutic-plan-document'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { createInitialTherapeuticPlanMutation } from 'repository/graphql/mutations'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { TherapeuticPlanRepository as ITherapeuticPlanRepository } from 'repository/interfaces/therapeutic-plan-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class TherapeuticPlanRepository implements ITherapeuticPlanRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async createTherapeuticPlan(
    params: CreateTherapeuticPlan.Params
  ): Promise<CreateTherapeuticPlan.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateTherapeuticPlan.Model>

    const query = createInitialTherapeuticPlanMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateTherapeuticPlan.Model
    }
  }

  async uploadTherapeuticPlanDocument(
    params: UploadTherapeuticPlanDocument.Params
  ): Promise<UploadTherapeuticPlanDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadTherapeuticPlanDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/therapeutic-plan/upload-document',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadTherapeuticPlanDocument.Model
    }
  }

  async loadProtocols(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    params: LoadProtocols.Params
  ): Promise<LoadProtocols.Model> {
    return {
      data: [],
      pageInfo: {
        currentPage: 0,
        itemsPerPage: 0,
        totalItems: 0,
        totalPages: 0
      }
    }
    // TO-DO: Uncomment when route is finished
    // const apiRepository = this.apiRepository as IApiRepository<LoadProtocols.Model>
    // const query = getProtocolsQuery(params)
    // const httpResponse = await apiRepository.post({
    //   url: '/graphql',
    //   body: {
    //     query: query.query,
    //     variables: {
    //       ...makeGraphQLVariable(params.pagination, 'pagination'),
    //       ...makeGraphQLVariable(params.query, 'query')
    //     }
    //   },
    //   query: query.name
    // })
    // if (
    //   httpResponse.statusCode &&
    //   httpResponse.statusCode !== ApiStatusCode.ok
    // ) {
    //   throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    // } else {
    //   return httpResponse.body as LoadProtocols.Model
    // }
  }
}
