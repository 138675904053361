import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  align-items: center;
`

export const ActualPage = styled.div`
  width: 100%;
  padding: 20px 0px 20px 24px;
`

export const Title = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 75%;
  text-align: center;
`

export const Icon = styled.div`
  height: 58px;
`
