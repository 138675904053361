import React, { useState, useEffect } from 'react'
import ButtonNew from 'presentation/components/ButtonNew'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import TextFieldNew from 'presentation/components/TextFieldNew'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import {
  HealthInsurancePlans,
  Plans
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { dateMask } from 'presentation/utils/masks'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { useFormik } from 'formik'
import * as yup from 'yup'
import 'main/config/yup'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

type Props = {
  primaryButtonText: string
  healthInsurance: PatientHealthInsurance
  availableHealthInsurances: HealthInsurancePlans[]
  addOrUpdateHealthInsurance: UpdatePatientHealthInsurance
  backToPreviewsPage: () => void
  noPadding?: boolean
} & WithLoadingProps

const HealthInsuranceForm = WithLoading(
  ({
    primaryButtonText,
    healthInsurance = {} as PatientHealthInsurance,
    availableHealthInsurances,
    addOrUpdateHealthInsurance,
    backToPreviewsPage,
    setIsLoading,
    noPadding
  }: Props) => {
    const [selectedHealthInsurancePlans, setSelectedHealthInsurancePlans] =
      useState<Plans[]>([])

    const initialValues = {
      ...healthInsurance,
      healthCard: healthInsurance.healthCard || '',
      validThru: healthInsurance?.validThru
        ? moment(healthInsurance?.validThru).format('DD/MM/YYYY')
        : ''
    }

    const submitForm = async (values: PatientHealthInsurance) => {
      setIsLoading(true)
      try {
        if (formik.isValid) {
          const selectedHealthInsurance = availableHealthInsurances?.find(
            (i) => i.code === values.healthInsuranceCode
          )

          const selectedHealthPlan = selectedHealthInsurancePlans?.find(
            (i) => i.code === values.healthPlanCode
          )

          const payload = {
            ...values,
            healthInsuranceName: selectedHealthInsurance?.description,
            healthPlanName: selectedHealthPlan?.description,
            validThru: moment(values.validThru, 'DD/MM/YYYY')
              .utc()
              .toISOString()
          }

          await addOrUpdateHealthInsurance.update(payload)
          backToPreviewsPage()
        }
      } catch (error: any) {
        toast.error('Houve um erro ao salvar o convênio')
      } finally {
        setIsLoading(false)
      }
    }

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      validateOnBlur: true,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: async (values) => submitForm(values)
    })

    const healthInsurancesList = () => {
      return [
        {
          label: 'Selecione o convênio',
          value: 0
        },
        ...availableHealthInsurances?.map((i) => ({
          label: i?.description,
          value: Number(i?.code)
        }))
      ]
    }

    const healthInsurancesPlansList = () => {
      return [
        {
          label: 'Selecione o plano',
          value: 0
        },
        ...selectedHealthInsurancePlans?.map((p) => ({
          label: p.description,
          value: Number(p.code)
        }))
      ]
    }

    const handleChangeSelectedHealthInsurance = (insuranceId: number) => {
      formik.setFieldValue('healthInsuranceCode', insuranceId)

      const selectedHealthInsurance = availableHealthInsurances?.find(
        (i) => i.code === insuranceId
      )

      setSelectedHealthInsurancePlans(selectedHealthInsurance?.plans ?? [])
      formik.setFieldValue(
        'healthPlanCode',
        healthInsurance.healthPlanCode || healthInsurancesPlansList()[0].value
      )
    }

    const handleChangeSelectedHealthInsurancePlan = (planId: number) => {
      formik.setFieldValue('healthPlanCode', planId)
      // formik.handleChange('healthPlanCode')
    }

    const isHealthCardDisabled = () => {
      return !(
        Boolean(formik.values.healthCard) ||
        Boolean(formik.values.healthPlanCode)
      )
    }

    const isValidThruDisabled = () => {
      return !(
        Boolean(formik.values.validThru) || Boolean(formik.values.healthCard)
      )
    }

    const primaryFooterButton = () => (
      <ButtonNew
        disabled={!formik.isValid}
        onClick={() => formik.handleSubmit}
        size="large"
        fullWidth
        type="submit"
        style={{ marginTop: '24px' }}
      >
        {primaryButtonText}
      </ButtonNew>
    )

    useEffect(() => {
      formik.setValues(initialValues)
      handleChangeSelectedHealthInsurance(healthInsurance.healthInsuranceCode)
    }, [availableHealthInsurances])

    return (
      <ContainerNew
        form
        primaryButton={primaryFooterButton()}
        onSubmit={formik.handleSubmit}
        gap="22px"
        style={{ paddingTop: '0px' }}
        noPadding={noPadding}
      >
        <SelectFieldNew
          name="healthInsurance"
          label="Convênio"
          placeholder="Digite o nome do convênio"
          items={healthInsurancesList()}
          value={formik.values.healthInsuranceCode}
          onBlur={formik.handleBlur('healthInsuranceCode')}
          onInputChange={(e) => handleChangeSelectedHealthInsurance(Number(e))}
          error={
            formik.touched.healthInsuranceCode &&
            formik.errors.healthInsuranceCode
          }
          required
        />
        <SelectFieldNew
          name="healthInsurancePlan"
          label="Plano"
          placeholder="Selecione o plano"
          items={healthInsurancesPlansList()}
          value={formik.values.healthPlanCode}
          onBlur={formik.handleBlur('healthPlanCode')}
          onInputChange={(e) =>
            handleChangeSelectedHealthInsurancePlan(Number(e))
          }
          disabled={!healthInsurancesPlansList().length}
          error={formik.touched.healthPlanCode && formik.errors.healthPlanCode}
          required
        />
        <TextFieldNew
          name="healthInsuranceCard"
          placeholder="Digite o número da carteirinha"
          label="N° da carteirinha"
          value={formik.values.healthCard}
          onBlur={formik.handleBlur('healthCard')}
          onInputChange={formik.handleChange('healthCard')}
          disabled={isHealthCardDisabled()}
          error={formik.touched.healthCard && formik.errors.healthCard}
          required
          maxLength={25}
        />
        <TextFieldNew
          name="validThru"
          placeholder="Insira a data de validade"
          label="Validade da Carteirinha"
          value={formik.values.validThru}
          onBlur={formik.handleBlur('validThru')}
          onInputChange={formik.handleChange('validThru')}
          disabled={isValidThruDisabled()}
          error={formik.touched.validThru && formik.errors.validThru}
          // required
          mask={dateMask}
        />
      </ContainerNew>
    )
  }
)

export default HealthInsuranceForm

const validationSchema = yup.object().shape({
  healthInsuranceCode: yup.string().required(),
  healthPlanCode: yup.number().required(),
  healthCard: yup.number().required(),
  validThru: yup
    .date()
    .typeError('Data Inválida')
    .min(moment().utc(), 'A data precisa ser maior que a data atual')
    .format('DD/MM/YYYY', true)
  // .required()
})
