import React, { useState, useEffect, useContext } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { useServices } from 'presentation/hooks/use-services'
import * as S from './styles'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import HealthInsuranceCardNew from 'presentation/shared/components/HealthInsuranceCardNew'
import ButtonNew from 'presentation/components/ButtonNew'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'
import { toast } from 'react-toastify'
import makePatientHealthInsuranceAdd from 'main/factories/pages/patient-health-insurance-add/patient-health-insurance-add'

export type NavigationProps = {
  next: () => void
  showAddHealthInsurance: boolean
  setShowAddHealthInsurance: (show: boolean) => void
  setIsLoading: (value: boolean) => void
}

const ExamsHealthInsurance = ({
  showAddHealthInsurance,
  setShowAddHealthInsurance,
  setIsLoading,
  next
}: NavigationProps) => {
  const patientService = useServices().patient

  const [healthInsurances, setHealthInsurances] =
    useState<PatientHealthInsurance[]>()

  const particularHealthInsurance = {
    healthInsuranceName: 'Consulta particular',
    healthPlanName: 'Particular',
    healthInsuranceCode: 304,
    health_insurance_id: 304,
    healthPlanCode: 1
  }

  const { state, dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  async function loadPatient() {
    return await patientService.loadPatientInfo(['patient_id'])
  }

  const deleteDocument = async (document_id: number) => {
    const { patient_id } = await loadPatient()

    try {
      setIsLoading(true)
      const response = await patientService.deletePatientExamDocuments({
        patient_id,
        document_id
      })
      return response
    } catch {
      toast.error('Ocorreu um erro ao excluir o documento')
    } finally {
      setIsLoading(false)
    }
  }

  async function handleSelectHealthInsurance(healthInsurance: any) {
    setIsLoading(true)

    dispatch({
      type: 'UPDATE_SELECTED_EXAMS',
      payload: null
    })

    if (healthInsurance.healthInsuranceCode === 396) {
      setIsLoading(false)
      return
    }

    if (state.health_card_document_id) {
      setIsLoading(true)
      const resp = await deleteDocument(state.health_card_document_id)
      if (resp?.ok === 'ok') {
        dispatch({
          type: 'SELECT_HEALTH_INSURANCE',
          payload: healthInsurance
        }),
          setTimeout(() => {
            next()
          }, 1000)
        setIsLoading(false)
      }
    } else {
      dispatch({
        type: 'SELECT_HEALTH_INSURANCE',
        payload: healthInsurance
      })
      next()
    }
  }

  const HealthInsuranceList = (showMore: any) => {
    return healthInsurances?.map(
      (healthInsurance: PatientHealthInsurance, index) => {
        if (!showMore) {
          if (index < 2) {
            return (
              <div key={index}>
                <HealthInsuranceCardNew
                  style={{ marginBottom: '24px' }}
                  healthInsurance={healthInsurance}
                  showOnly={true}
                  disabled={
                    healthInsurance.healthInsuranceCode === 396 ||
                    healthInsurance.healthInsuranceCode === 448
                  } // Block ipsemg and planserv health insurance
                  onClick={() => handleSelectHealthInsurance(healthInsurance)}
                />
                {/* Ipsemg health insurance message */}
                {healthInsurance?.healthInsuranceCode === 396 && (
                  <S.IpsemgInfo>
                    Agendamentos pelo IPSEMG são exclusivos por telefone:{' '}
                    <a
                      href="tel:3135122500"
                      target="_blank"
                      rel="noreferrer"
                      title="Ligue para (31) 3512-2500"
                    >
                      Ligue (31) 3512-2500
                    </a>
                  </S.IpsemgInfo>
                )}
              </div>
            )
          }
        } else {
          return (
            <div key={index}>
              <HealthInsuranceCardNew
                style={{ marginBottom: '24px' }}
                healthInsurance={healthInsurance}
                showOnly={true}
                disabled={
                  healthInsurance.healthInsuranceCode === 396 ||
                  healthInsurance.healthInsuranceCode === 448
                } // Block ipsemg and planserv health insurance
                onClick={() => handleSelectHealthInsurance(healthInsurance)}
              />
              {/* Ipsemg health insurance message */}
              {healthInsurance?.healthInsuranceCode === 396 && (
                <S.IpsemgInfo>
                  Agendamentos pelo IPSEMG são exclusivos por telefone:{' '}
                  <a
                    href="tel:3135122500"
                    target="_blank"
                    rel="noreferrer"
                    title="Ligue para (31) 3512-2500"
                  >
                    Ligue (31) 3512-2500
                  </a>
                </S.IpsemgInfo>
              )}
            </div>
          )
        }
      }
    )
  }

  useEffect(() => {
    async function loadPatientData() {
      // setIsLoading(true)
      try {
        const response = await patientService.loadPatientInfo([
          `
              healthInsurances {
                health_insurance_id
                healthCard
                healthInsuranceCode
                healthInsuranceName
                healthPlanCode
                healthPlanName
                healthSubPlanCode
                healthSubPlanName
                ansRegister
                validThru
                createdAt
                updatedAt
              }
            `
        ])

        setHealthInsurances(response.healthInsurances)
      } catch (error: any) {
        toast.error('Ocorreu um erro ao carregar os dados')
      } finally {
        // setIsLoading(false)
      }
    }

    loadPatientData()
  }, [showAddHealthInsurance])

  const [showMore, setShowMore] = useState(false)

  return (
    <ContainerNew
      title={
        showAddHealthInsurance
          ? 'Adicionar convênio'
          : 'Agendar pelo convênio ou particular?'
      }
      titleColor="secondary700"
      titleSize="medium"
      titleWeight="bold"
      subtitle={
        showAddHealthInsurance
          ? 'Insira os dados do convênio'
          : 'Clique em uma das opções abaixo para continuar.'
      }
    >
      {showAddHealthInsurance ? (
        <S.Wrapper>
          {makePatientHealthInsuranceAdd({
            showHeader: false,
            noPadding: true,
            showAddHealthInsurance,
            setShowAddHealthInsurance
          })}
        </S.Wrapper>
      ) : (
        <>
          <S.Wrapper>
            {!!healthInsurances && (
              <>
                <ButtonNew
                  fullWidth
                  type="button"
                  onClick={() => setShowAddHealthInsurance(true)}
                  style={{ margin: '16px 0 24px' }}
                >
                  Adicionar convênio
                </ButtonNew>
                <S.CardsList>
                  <HealthInsuranceCardNew
                    particular={true}
                    showOnly={true}
                    title="Com pagamento no local."
                    description="Aceitamos cartões de débito e crédito, Pix e dinheiro."
                    onClick={() =>
                      handleSelectHealthInsurance(particularHealthInsurance)
                    }
                  />
                  {HealthInsuranceList(showMore)}
                </S.CardsList>
                {healthInsurances?.length > 1 && (
                  <ButtonNew
                    style={{
                      marginBottom: '24px',
                      display: 'flex',
                      border: 'none'
                    }}
                    fullWidth
                    outlined
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? 'Ver menos' : 'Ver mais'}
                  </ButtonNew>
                )}
              </>
            )}
          </S.Wrapper>
        </>
      )}
    </ContainerNew>
  )
}

export default ExamsHealthInsurance
