import { CreateHelpPendencyMessage } from 'domain/usecases/surgical-order/create-help-pendency-message'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteCreateHelpPendencyMessage
  implements CreateHelpPendencyMessage
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  create(
    params: CreateHelpPendencyMessage.Params
  ): Promise<CreateHelpPendencyMessage.Model> {
    return this.surgicalPendencyRepository.createHelpPendencyMessage(params)
  }
}
