import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    height: 72px;
    width: 100%;
    z-index: ${theme.layers.base};
    background-color: white;
    border-bottom: 1px solid #c4c4c4;
  `}
`

export const LogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HamburguerIconWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 20px;
`
