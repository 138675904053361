export enum Gender {
  MALE = 'Masculino',
  FEMALE = 'Feminino'
}

export const gender = [
  {
    label: 'Masculino',
    value: Gender.MALE
  },
  {
    label: 'Feminino',
    value: Gender.FEMALE
  }
]

// Gender New
export enum GenderNew {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  BOTH = 'BOTH'
}

export enum GenderNewTranslated {
  MALE = 'Masculino',
  FEMALE = 'Feminino'
}

export const genderNewList = [
  {
    label: 'Masculino',
    value: GenderNew.MALE
  },
  {
    label: 'Feminino',
    value: GenderNew.FEMALE
  }
]
