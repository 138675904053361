import { Switch, Theme, withStyles, createStyles } from '@material-ui/core'

export const Wrapper = withStyles((muiTheme: Theme) =>
  createStyles({
    root: {
      width: 30,
      height: 16,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: '#fff',
      '&$checked': {
        transform: 'translateX(12px)',
        color: muiTheme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#5278f2',
          borderColor: '#5278f2'
        }
      }
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none'
    },
    track: {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#d4d8d9'
    },
    checked: {}
  })
)(Switch)
