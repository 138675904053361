// import { AxiosResponse } from 'axios'
import {
  ApiSurgicalScheduleClient,
  ApiRequest,
  ApiResponse,
  ApiMethod
} from 'service/protocols/api-surgical-schedule/api-surgical-schedule-client'
import {
  IApiSurgicalScheduleRepository,
  RepositoryRequest
} from 'service/protocols/api-surgical-schedule/api-surgical-schedule-repository'

export class ApiSurgicalScheduleRepository<Model = any>
  implements IApiSurgicalScheduleRepository<Model>
{
  constructor(
    private readonly apiSurgicalScheduleClient: ApiSurgicalScheduleClient<Model>
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiSurgicalScheduleClient.request({
      ...data,
      method: ApiMethod.GET
    } as ApiRequest)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    const request = await this.apiSurgicalScheduleClient.request({
      ...data,
      method: ApiMethod.POST
    } as ApiRequest)

    // if (request.body) {
    //   request.body = (request.body as any).data
    //     ? (request.body as any).data[data.query]
    //     : request.body
    // }

    return request
  }

  async put(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiSurgicalScheduleClient.request({
      ...data,
      method: ApiMethod.PUT
    } as ApiRequest)
  }

  delete(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return this.apiSurgicalScheduleClient.request({
      ...data,
      method: ApiMethod.DELETE
    } as ApiRequest)
  }
}
