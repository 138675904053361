import React, {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState
} from 'react'
import * as S from './styles'
import UploadIcon from 'presentation/assets/icons/upload.png'
import AddIcon from 'presentation/assets/icons/plus-green.svg'

export type DocInputProps = {
  fileName?: string
  name: string
  text: string
  span?: ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  notShowFileName?: boolean
  multiple?: boolean
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  ignoreOnInput?: boolean
  acceptFiles?: string
  notUpdateFileName?: boolean
  dataTestId?: string | number
} & InputHTMLAttributes<HTMLInputElement>

export default function DocInput({
  text = 'Carregar Documento',
  span,
  inputProps,
  ignoreOnInput,
  acceptFiles,
  notUpdateFileName = false,
  dataTestId,
  ...props
}: DocInputProps) {
  const [fileName, setFileName] = useState(props.fileName ?? '')
  const [fileExt, setFileExt] = useState('')

  const updateFileExt = (fileName: string) => {
    const pattern = /\.([0-9a-z]+)(?:[?#]|$)/i
    const match = fileName.match(pattern)
    if (match) {
      setFileExt(match[1])
    }
  }

  useEffect(() => {
    setFileName(props.fileName ?? '')
    updateFileExt(props.fileName ?? '')
  }, [props.fileName])

  function onHandleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation()
    event.preventDefault()
    const files = event.target?.files
    const fileLength = files?.length || 0
    if (fileLength > 0 && files?.[0]) {
      const { name } = files[0]
      if (!notUpdateFileName) {
        setFileName(name)
        updateFileExt(name)
      }
      props.onChange(event)
    }
  }

  const showIcon = (): boolean => {
    return Boolean(!fileName || props.notShowFileName)
  }

  const getIcon = (): string => {
    return props.notShowFileName ? AddIcon : UploadIcon
  }

  const getValidFileTypes = (): string => {
    if (acceptFiles) return acceptFiles
    return 'image/jpeg,image/gif,image/png,image/bmp,image/tif,image/tiff,application/pdf,image/x-eps'
  }

  return (
    <S.DocInput
      {...props}
      disabled={!!props.disabled}
      file={!!fileName}
      notShowFileName={props.notShowFileName}
    >
      <S.Label
        htmlFor={props.name}
        data-testid={`file-input-${dataTestId}`}
      ></S.Label>
      <S.Subtitle fileExt={fileExt}>
        {!props.notShowFileName && fileName && (
          <S.Preview>
            <small>{fileExt}</small>
          </S.Preview>
        )}
        {showIcon() && <img src={getIcon()} alt="Upload icon" />}
        {!props.notShowFileName && fileName ? fileName : text}
        {!!span && !fileName && span}
      </S.Subtitle>
      <input
        type="file"
        disabled={props.disabled}
        name={props.name}
        id={props.name}
        onInput={(event) => {
          if (!ignoreOnInput) {
            onHandleChange(event as React.ChangeEvent<HTMLInputElement>)
          }
        }}
        onChange={onHandleChange}
        accept={getValidFileTypes()}
        multiple={props.multiple}
        {...inputProps}
      />
    </S.DocInput>
  )
}
