import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import React from 'react'
import * as S from './styles'

type RenewEmergencyTokenModalProps = {
  renewToken: () => void
  showRenew: boolean
  onClose: () => void
  onCancel: () => void
}

export default function RenewEmergencyTokenModal({
  renewToken,
  showRenew,
  onClose,
  onCancel
}: RenewEmergencyTokenModalProps) {
  return (
    <SheetModal size={280} isOpen={showRenew} onClose={onClose}>
      <S.Container>
        <S.HeaderContainer>
          <Heading size="large">Seu token irá </Heading>
          <Heading size="large" color="primaryDarker">
            expirar
          </Heading>
        </S.HeaderContainer>
        Deseja prorrogar o seu token por mais 30 minutos?
        <Button
          fullWidth
          style={{ marginTop: '20px' }}
          role="renewButton"
          onClick={() => renewToken()}
          backgroundColor={'primaryDarker'}
        >
          Sim
        </Button>
        <SupportText
          role="cancelButton"
          onClick={onCancel}
          color="primaryDarker"
        >
          Não
        </SupportText>
      </S.Container>
    </SheetModal>
  )
}
