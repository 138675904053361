import { DoctorRepository } from 'repository/interfaces/doctor-repository'
import { LoadDoctorWithRecommendation } from 'domain/usecases/doctor/load-doctor-with-recommendation'

export class RemoteLoadDoctorWithRecommendation
  implements LoadDoctorWithRecommendation
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadDoctorWithRecommendation.Params
  ): Promise<LoadDoctorWithRecommendation.Model> {
    return this.doctorRepository.loadDoctorWithRecommendation(params)
  }
}
