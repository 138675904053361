import { ApiTracksaleImplementation } from 'infra/api-tracksale/api-tracksale-client'
import { PrepareApiRequestTracksaleDecorator } from 'main/decorators/prepare-api-request-tracksale-decorator/prepare-api-request-tracksale-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiTracksaleRepository } from 'repository/api-tracksale-repository'
import { TracksaleRepository } from 'repository/repositories/tracksale/tracksale-repository'
import { TracksaleService } from 'presentation/contexts/services/tracksale-service'
import { RemoteAnswerNps } from 'service/usecases/tracksale/answerNps/answer-nps'

export default function makeTracksaleService() {
  const apiTracksale = new ApiTracksaleImplementation()
  const apiTracksaleRepository = new PrepareApiRequestTracksaleDecorator(
    makeLocalStorageAdapter(),
    new ApiTracksaleRepository(apiTracksale)
  )
  const tracksaleRepository = new TracksaleRepository(apiTracksaleRepository)

  const answerNps = new RemoteAnswerNps(tracksaleRepository)

  return new TracksaleService({ answerNps })
}
