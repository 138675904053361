import styled, { css } from 'styled-components'
import theme from 'presentation/styles/theme'
import SupportTextNew from '../SupportTextNew'

export const StatusTag = styled(SupportTextNew)<{
  bgColor: keyof typeof theme.ds.colors
}>`
  ${({ bgColor }) => css`
    width: fit-content;
    padding: 4px 8px;
    background-color: ${theme.ds.colors[bgColor]};
    border-radius: 16px;
    font-size: 14px;
  `}
`
