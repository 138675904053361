import { IApiRepository } from 'service/protocols/api/api-repository'
import { ExamRepository as IExamRepository } from 'repository/interfaces/exam-repository'
import { SearchExamDoctors } from 'domain/usecases/exam/search-exam-doctors'
import { GetPatientExamAppointments } from 'domain/usecases/patient/get-exam-appointments'
import { SearchExams } from 'domain/usecases/exam/search-exams'
import { GetExamAvailableDates } from 'domain/usecases/exam/get-exam-available-dates'
import { CreatePatientExamAppointments } from 'domain/usecases/patient/create-patient-exam-appointment'
import { GetExamScheduleOffersByMonth } from 'domain/usecases/exam/get-exam-schedule-offers-by-month'
import { GetExamScheduleHours } from 'domain/usecases/exam/get-exam-schedule-hours'
import { GetExamReasonCancel } from 'domain/usecases/exam/get-exam-reason-cancel'
import { CancelExamSchedule } from 'domain/usecases/exam/cancel-exam-schedule'
import { CheckResourceSchedule } from 'domain/usecases/exam/check-resource-schedule'
import { GetExamPreparation } from 'domain/usecases/exam/get-exam-preparation'
import { GetExamByItem } from 'domain/usecases/exam/get-exam-by-item'
import { GetExamAvailableUnits } from 'domain/usecases/exam/get-exam-available-units'
import { GetExamAvailableHospitals } from 'domain/usecases/exam/get-exam-available-hospitals'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { GetExamPreparationWithParams } from 'domain/usecases/exam/get-exam-preparation-with-params'
import { GetExamGroupOrdered } from 'domain/usecases/exam/get-exam-group-ordered'

export class ExamRepository implements IExamRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async getPatientExamAppointments(
    params: GetPatientExamAppointments.Params
  ): Promise<GetPatientExamAppointments.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.get({
      url: `/api/v1/appointments?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&patient_id=${params.patient_id}`
    })

    return httpResponse.body.data as GetPatientExamAppointments.Model
  }

  async searchExamDoctors(
    data: SearchExamDoctors.Params
  ): Promise<SearchExamDoctors.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/doctors`,
      body: data
    })

    return httpResponse.body.data as SearchExamDoctors.Model
  }

  async createPatientExamAppointment(
    params: CreatePatientExamAppointments.Params
  ): Promise<CreatePatientExamAppointments.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/appointments`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body as CreatePatientExamAppointments.Model
    }
  }

  async searchExams(data: SearchExams.Params): Promise<SearchExams.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/search`,
      body: data
    })
    return httpResponse.body.data as SearchExams.Model
  }

  async getExamAvailableDates(
    data: GetExamAvailableDates.Params
  ): Promise<GetExamAvailableDates.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-recent-schedules`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as GetExamAvailableDates.Model
    }
  }

  async getExamScheduleOffersByMonth(
    data: GetExamScheduleOffersByMonth.Params
  ): Promise<GetExamScheduleOffersByMonth.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-schedules-by-month`,
      body: data
    })

    return httpResponse.body.data as GetExamScheduleOffersByMonth.Model
  }

  async getExamScheduleHours(
    data: GetExamScheduleHours.Params
  ): Promise<GetExamScheduleHours.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-schedule-hours`,
      body: data
    })

    return httpResponse.body.data as GetExamScheduleHours.Model
  }

  async getExamReasonCancel(): Promise<GetExamReasonCancel.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.get({
      url: `/api/v1/reason-cancel`
    })

    return httpResponse.body.data as GetExamReasonCancel.Model
  }

  async cancelExamSchedule(
    data: CancelExamSchedule.Params
  ): Promise<CancelExamSchedule.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.delete({
      url: `/api/v1/appointments`,
      body: data
    })

    return httpResponse.body as CancelExamSchedule.Model
  }

  async checkResourceSchedule(
    data: CheckResourceSchedule.Params
  ): Promise<CheckResourceSchedule.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/doctors-or-resources`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as CheckResourceSchedule.Model
    }
  }

  async getExamPreparation(
    data: GetExamPreparation.Params
  ): Promise<GetExamPreparation.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/preparation`,
      body: data
    })

    return httpResponse.body as GetExamPreparation.Model
  }

  async getExamByItem(
    params: GetExamByItem.Params
  ): Promise<GetExamByItem.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams`,
      body: params
    })

    return httpResponse.body as GetExamByItem.Model
  }

  async getExamAvailableUnits(
    data: GetExamAvailableUnits.Params
  ): Promise<GetExamAvailableUnits.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/availability`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      if (httpResponse.statusCode === ApiStatusCode.notFound) {
        throw handleGraphQLError(RepositoryErrors.EXAM_NOT_FOUND)
      } else {
        throw handleGraphQLError(
          RepositoryErrors[
            httpResponse.body?.code as keyof typeof RepositoryErrors
          ]
        )
      }
    } else {
      return httpResponse.body as GetExamAvailableUnits.Model
    }
  }

  async getExamAvailableHospitals(
    data: GetExamAvailableHospitals.Params
  ): Promise<GetExamAvailableHospitals.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/hospitals`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as GetExamAvailableHospitals.Model
    }
  }

  async getExamPreparationWithParams(
    params: GetExamPreparationWithParams.Params
  ): Promise<GetExamPreparationWithParams.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.get({
      url: `/api/v1/preparation?exam=${params.exam}&hospital_id=${params.hospital_id}`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      if (httpResponse.statusCode === ApiStatusCode.notFound) {
        throw handleGraphQLError(
          RepositoryErrors.PREPARATION_INSTRUCTIONS_NOT_FOUND
        )
      } else {
        throw handleGraphQLError(
          RepositoryErrors[
            httpResponse.body?.code as keyof typeof RepositoryErrors
          ]
        )
      }
    } else {
      return httpResponse.body.data as GetExamPreparationWithParams.Model
    }
  }

  async getExamGroupOrdered(
    params: GetExamGroupOrdered.Params
  ): Promise<GetExamGroupOrdered.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/groups`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as GetExamGroupOrdered.Model
    }
  }
}
