import React, { useState } from 'react'
import { useHistory } from 'react-router'

import Carousel, { CarouselState } from 'presentation/components/Carousel'
import BottomMenu from 'presentation/components/BottomMenu'
import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'

import { SlideIntro } from './components/slide-intro'
import { SlideSurgicalOrder } from './components/slide-surgical-order'
import { SlideHospitalAnalysis } from './components/slide-hospital-analysis'
import { SlideHealthInsuranceAnalysis } from './components/slide-health-insurance-analysis'
import { SlideSurgicalAuthorization } from './components/slide-surgical-authorization'
import { SlideSurgicalSchedule } from './components/slide-surgical-schedule'

import * as S from './styled'
import ButtonNew from 'presentation/components/ButtonNew'

export const SurgicalJourneyOrientations = () => {
  const history = useHistory()
  const [carousel, setCarousel] = useState({} as CarouselState)

  return (
    <>
      <HeaderNew actualPageTitle="Jornada cirúrgica" />
      <ContainerNew
        noHeader
        primaryButton={
          <>
            {carousel.isLast ? (
              <ButtonNew
                onClick={() => carousel.slideTo(0)}
                style={{ margin: 'auto auto 16px' }}
                fullWidth
                size="large"
              >
                Voltar para o início
              </ButtonNew>
            ) : (
              <ButtonNew onClick={carousel.slideNext} fullWidth size="large">
                {carousel.activeIndex === 0 ? 'Iniciar' : 'Próximo'}
              </ButtonNew>
            )}
          </>
        }
        secondaryButton={
          <>
            {carousel.isLast && (
              <ButtonNew
                onClick={() => history.push('/home')}
                style={{ margin: '0 auto 0' }}
                variant="text"
                fullWidth
                size="large"
              >
                Fechar
              </ButtonNew>
            )}
          </>
        }
      >
        <S.Wrapper>
          <Carousel
            state={carousel}
            setState={setCarousel}
            slidesPerView={1}
            // spaceBetween={20}
            dynamicHeight={true}
            fade={{ crossFade: true }}
            effect="fade"
            speed={800}
          >
            <SlideIntro key="0" slideIntro />
            <SlideSurgicalOrder key="1" />
            <SlideHospitalAnalysis key="2" />
            <SlideHealthInsuranceAnalysis key="3" />
            <SlideSurgicalAuthorization key="4" />
            <SlideSurgicalSchedule key="5" />
          </Carousel>
        </S.Wrapper>
      </ContainerNew>
      <BottomMenu />
    </>
  )
}
