import React, { useEffect, useState } from 'react'
import {
  ExamOrderItem,
  ExamResultModel
} from 'domain/entities/exam-result-model'
import { useHistory, useLocation } from 'react-router-dom'
import ExamResultListLayout from 'presentation/layouts/ExamResultList'
import { ExamOrderType } from 'common/enum/exam-order-type'

type LocationType = {
  exam: ExamResultModel
  examProcedures: string
}

export const ExamResultList = () => {
  const history = useHistory()
  const location = useLocation<LocationType>().state
  const exam = location?.exam
  const procedures = location?.examProcedures
  const [formattedExamOrderItems, setFormattedExamOrderItems] = useState<
    ExamOrderItem[]
  >([])

  const handleFormatExamOrderItems = () => {
    let laboratoryExams = [] as ExamOrderItem[]
    let imageExams = [] as ExamOrderItem[]

    if (exam.order_type === ExamOrderType.LABORATORY) {
      laboratoryExams = exam.order_items.map((orderItem: ExamOrderItem) => ({
        ...orderItem
      }))
    }

    if (exam.order_type === ExamOrderType.IMAGE) {
      imageExams = exam.order_items.map((orderItem: ExamOrderItem) => ({
        ...orderItem,
        available_report: false
      }))

      laboratoryExams = exam.order_items.map((orderItem: ExamOrderItem) => ({
        ...orderItem,
        exam_type: ExamOrderType.LABORATORY,
        available_image: false
      }))
    }

    // Sort order items
    const sortedExams = [...laboratoryExams, ...imageExams].sort((a, b) => {
      if (a.exam_description < b.exam_description) return -1
      if (a.exam_description > b.exam_description) return 1
      return 0
    })

    setFormattedExamOrderItems(sortedExams)
  }

  useEffect(() => {
    if (!exam.status) {
      history.push('/exames')
    } else {
      handleFormatExamOrderItems()
    }
  }, [])

  return (
    <ExamResultListLayout
      exam={exam}
      examOrderItems={formattedExamOrderItems}
      procedures={procedures}
    />
  )
}
