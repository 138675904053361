import { Ability } from '@casl/ability'

export class PbacAdapter {
  private ability: Ability
  constructor(private permissions: string[] = []) {
    this.ability = new Ability(
      permissions.map((permission) => ({
        action: permission,
        subject: 'all'
      }))
    )
  }

  can(permission: string) {
    return this.ability.can(permission, 'all')
  }

  // Old
  getAbilities(): string[] {
    return this.ability.rules.map((rule) => rule.action as string)
  }

  // New
  getPermissions(): string[] {
    return this.ability.rules.map((rule) => rule.action as string)
  }
}
