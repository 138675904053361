import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { VueMotionRepository as IVueMotionRepository } from 'repository/interfaces/vue-motion-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { vueMotionCheckForExamsQuery } from 'repository/graphql/queries'
import { VueMotionCheckForExams } from 'domain/usecases/vue-motion/vue-motion-check-for-exams'

export class VueMotionRepository implements IVueMotionRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async vueMotionCheckForExams(
    params: VueMotionCheckForExams.Params
  ): Promise<VueMotionCheckForExams.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<VueMotionCheckForExams.Model>

    const query = vueMotionCheckForExamsQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.accession_number,
          'accession_number'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as VueMotionCheckForExams.Model
    }
  }
}
