import React, { useEffect, useState } from 'react'
// import TagManager from 'react-gtm-module'
import { ExamModel } from 'domain/entities/exam-model'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { LoadExams } from 'domain/usecases/patient/load-exams'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { LoadPatientSurgery } from 'domain/usecases/patient/load-patient-surgery'
import { RenewEmergencyToken } from 'domain/usecases/renew-emergency-token/renew-emergency-token'
import { LoadSurgicalOrderStatus } from 'domain/usecases/surgical-order/get-surgical-order-status'
import moment from 'moment-timezone'
import { useStores } from 'presentation/hooks/use-stores'
import ConfirmNewExamModal from 'presentation/components/modals/confirmNewExamModal'
import PatientHomeLayout from 'presentation/layouts/Home'
import { ServiceOverviewProps } from 'presentation/shared/components/ServiceOverview'
import { getStatusInfo } from 'presentation/utils/service-status'
import { useHistory, useLocation } from 'react-router'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'
import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import {
  Attendance,
  AttendanceType,
  AttendanceTypeTranslation
} from 'domain/entities/attendance-model'
import { EmergencyRoomAttendanceModel } from 'domain/entities/emergency-room-attendance.model'
import { EmergencyRoomTokenModel } from 'domain/entities/emergency-room-token.model'
import { Patient } from 'domain/entities/patient-model'
import { IntervalType } from 'presentation/utils/interval-type'
import {
  LoadPatientSurgicalOrders,
  SurgicalScheduleStatus
} from 'domain/usecases/patient/load-patient-surgical-orders'
import MyDocumentsOnboardingModal from 'presentation/components/modals/MyDocumentsOnboardingModal'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import { UserFlagType, UserFlagTypeLocated } from 'common/enum/user-flags-types'
import { SurgeryType, SurgeryTypeLocated } from 'common/enum/surgery-type'
import { capitalize } from '@material-ui/core'

// const tagManagerArgs = {
//   dataLayer: {
//     userId: '001',
//     userProject: 'project',
//     page: 'home'
//   },
//   dataLayerName: 'PageDataLayer'
// }

type PatientHomeProps = {
  loadPatientSurgery: LoadPatientSurgery
  loadPatientInfo: LoadPatientInfo
  loadExams: LoadExams
  loadSurgicalOrderStatus: LoadSurgicalOrderStatus
  renewEmergencyToken: RenewEmergencyToken
  getPatientNextAttendances: GetPatientNextAttendances
  loadPatientSurgicalOrders: LoadPatientSurgicalOrders
}

type SurgeryProps = {
  unit: string
  status: string
  statusColor: string
  statusLabel: string
  doctorName: string
  doctorRole: string
  scheduleDate: string
  surgical_order_id: number
  title: string
}

type ILocation = {
  exam_id?: number
  confirmated?: boolean
}

const MINIMAL_TIME_TO_RENEW = 900000 // 15 minutes
const ATTENDANCES_PER_PAGE = 5
const MAX_TIMES_TO_RENEW = 3

const PatientHome = ({
  loadPatientInfo,
  loadExams,
  renewEmergencyToken,
  getPatientNextAttendances,
  loadPatientSurgicalOrders
}: PatientHomeProps) => {
  // TagManager.dataLayer(tagManagerArgs)
  const [patientSurgicalOrders, setPatientSurgicalOrders] = useState<
    SurgicalOrderModel[]
  >([])
  const [patientAttendances, setPatientAttendances] = useState<Attendance[]>([])
  const [patientSurgeries, setPatientSurgeries] = useState<SurgeryProps[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pendencies, setPendencies] = useState<number>()
  const [emergencyRoomAttendance, setEmergencyRoomAttendance] =
    useState<EmergencyRoomAttendanceModel>()
  const [showRenewToken, setShowRenewToken] = useState<boolean>(false)
  const [canRenew, setCanRenew] = useState<boolean>(false)
  const [endTokenTimeout, setEndTokenTimeout] = useState<IntervalType>()
  const [nextExamNotVerified, setNextExamNotVerified] = useState<
    ExamModel | undefined
  >()
  const [showDocumentsOnboardingModal, setShowDocumentsOnboardingModal] =
    useState<boolean>(false)

  const [openSurgeriesModalVisible, setOpenSurgeriesModalVisible] =
    useState<boolean>(false)

  const [openScheduledSurgeries, setOpenScheduledSurgeries] = useState<any>([])

  const history = useHistory()

  const { currentAccount, examsStore } = useStores()
  const user = currentAccount.getCurrentAccount().user

  const location = useLocation<ILocation>()
  const patientService = useServices().patient
  const userService = useServices().user
  const surgicalOrderService = useServices().surgicalOrder
  const localStorageAdapter = new LocalStorageAdapter()

  const [
    surgicalOrderCancellationNotification,
    setSurgicalOrderCancellationNotification
  ] = useState({})

  const getCancellationNotifications = async () => {
    try {
      const response: any =
        await surgicalOrderService.getSurgicalOrderCancellationNotifications()

      setSurgicalOrderCancellationNotification(response?.surgical_orders[0])
    } catch {
      toast.error('Ocorreu um erro ao buscar solicitações de cancelamento.')
    }
  }

  const handleLoadUserFlags = async () => {
    const currentUserFlags = localStorageAdapter.get('user-flags')
    if (currentUserFlags?.[UserFlagTypeLocated[UserFlagType.INTEGRATION_MEMED]])
      return

    try {
      const response = await userService.loadUserFlags({
        types: [UserFlagType.INTEGRATION_MEMED]
      })
      const filteredFlag = response?.data?.find(
        (flag) => flag.type === UserFlagType.INTEGRATION_MEMED
      )

      if (filteredFlag) {
        localStorageAdapter.set('user-flags', {
          ...currentUserFlags,
          [UserFlagTypeLocated[filteredFlag.type]]: filteredFlag.is_concluded
        })
        setShowDocumentsOnboardingModal(!filteredFlag.is_concluded)
      }
    } catch (error: any) {
      console.error(error.message)
    }
  }

  const setEmergencyTokenTimeout = (
    emergencyToken: EmergencyRoomTokenModel
  ) => {
    const diff =
      emergencyToken?.arrivalForecastTimer?.getDifferenceByNow() || -1
    if (diff < 0) return
    const qtRenewed = emergencyToken.qtRenewed ?? MAX_TIMES_TO_RENEW + 1
    if (qtRenewed < MAX_TIMES_TO_RENEW) {
      setCanRenew(true)
    } else {
      setCanRenew(false)
    }
    if (diff < MINIMAL_TIME_TO_RENEW) {
      setShowRenewToken(true)
    } else {
      const timeToMinimal = diff - MINIMAL_TIME_TO_RENEW
      setTimeout(() => {
        setShowRenewToken(true)
      }, timeToMinimal)
    }
    const endTimeout = setTimeout(() => {
      setShowRenewToken(false)
    }, diff)
    setEndTokenTimeout(endTimeout)
  }

  const setPatientEmergencyRoom = (patientInfo: Patient) => {
    if (!patientInfo?.emergencyRoomAttendance) return
    const patientEmergencyRoomAttendance = new EmergencyRoomAttendanceModel(
      patientInfo?.emergencyRoomAttendance
    )
    if (!patientEmergencyRoomAttendance.emergencyRoomToken) return
    setEmergencyTokenTimeout(patientEmergencyRoomAttendance.emergencyRoomToken)
    setEmergencyRoomAttendance(patientEmergencyRoomAttendance)
  }

  const renewToken = async () => {
    if (endTokenTimeout) {
      clearTimeout(endTokenTimeout)
    }
    const id =
      emergencyRoomAttendance?.emergencyRoomToken?.emergency_room_token_id
    if (id) {
      try {
        await renewEmergencyToken.renew(['emergency_room_token_id'], id)
        const patientInfo = await loadPatientInfo.load([
          'emergencyRoomAttendance {',
          ...emergencyRoomAttendanceFields,
          '}'
        ])
        setPatientEmergencyRoom(patientInfo)
        setShowRenewToken(false)
      } catch (error: any) {
        toast.error(error?.message)
      }
    }
  }

  const handleLoadExams = async () => {
    try {
      const exams = await loadExams.load([
        'exam_id',
        'exam_mv_id',
        'visualized',
        'createdAt',
        'scheduleDate',
        'hospital',
        'examDescription'
      ])
      examsStore.setPatientExam(exams)
      if (!hasConfirmation && Array.isArray(exams)) {
        setNextExamNotVerified(exams.find((exam) => !exam.visualized))
      }
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handlePatientInfo = async () => {
    const patientInfo = await loadPatientInfo.load([
      'surgicalOrders (filterSolicitations: true) {',
      'surgical_order_id',
      'procedure {description}',
      'hospital {name, hospital_id}',
      'surgeryDate',
      'scheduled',
      'status{status}',
      'doctor{name, role}',
      '}',
      'emergencyRoomAttendance {',
      ...emergencyRoomAttendanceFields,
      '}'
    ])
    setPatientSurgeries(
      mapSurgicalOrderToSurgeryProps(patientInfo?.surgicalOrders || [])
    )
    setPatientEmergencyRoom(patientInfo)
  }

  const handlePatientPendencies = async () => {
    try {
      const totalPendencies = await patientService.getPatientPendency({
        pageNumber: 1,
        pageSize: 2
      })
      setPendencies(
        totalPendencies?.pagination?.total_items +
          totalPendencies?.pagination?.total_help_card
      )
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  const handlePatientNextAttendance = async () => {
    const attendances = await getPatientNextAttendances.load({
      fields: [
        'identifier',
        'attendanceDate',
        'description',
        'hospitalName',
        'status',
        'type'
      ],
      limit: ATTENDANCES_PER_PAGE + 1
    })
    if (attendances) setPatientAttendances(attendances)
  }

  const handlePatientSurgicalOrders = async () => {
    const surgicalOrders = await loadPatientSurgicalOrders.load()
    if (surgicalOrders) setPatientSurgicalOrders(surgicalOrders)
  }

  const checkPendentScheduledSurgeries = async () => {
    const pendentSurgeriesBanner = localStorageAdapter.get(
      'pendent-surgeries-banner'
    )

    if (!pendentSurgeriesBanner?.bannerPresented) {
      const response = await loadPatientSurgicalOrders.load({
        statusStartDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
        statusEndDate: moment().subtract(2, 'months').format('YYYY-MM-DD'),
        status: SurgicalScheduleStatus.AUTHORIZED,
        activeSearch: false
      })
      if (response.length > 0) {
        setOpenScheduledSurgeries(response)
        setOpenSurgeriesModalVisible(true)

        localStorageAdapter.set('pendent-surgeries-banner', {
          bannerPresented: true
        })
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        await checkPendentScheduledSurgeries()
        handleLoadUserFlags()
        handleLoadExams()
        await getCancellationNotifications()
        await handlePatientInfo()
        handlePatientPendencies()
        await handlePatientNextAttendance()
        await handlePatientSurgicalOrders()
      } catch (err: any) {
        toast.error(err.message)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  const [hasConfirmation, setHasConfirmation] = useState<boolean>(
    location.state?.confirmated || false
  )

  const closeExamModal = () => {
    if (hasConfirmation) {
      setHasConfirmation(false)
      history.replace({ ...location, state: undefined })
    } else {
      setNextExamNotVerified(undefined)
    }
  }

  const surgicalRequestName = (title?: string) => {
    switch (title) {
      case SurgeryType.BIO:
        return SurgeryTypeLocated.BIO

      case SurgeryType.EMB:
        return SurgeryTypeLocated.EMB

      case SurgeryType.CAT:
        return SurgeryTypeLocated.CAT

      default:
        return ''
    }
  }

  const adaptAttendanceToProp = (attendance: Attendance) => {
    const prop = {
      title:
        attendance.description &&
        (surgicalRequestName(attendance?.description) ||
          capitalize(attendance.description.toLowerCase())),
      status: getStatusInfo(
        patientSurgicalOrders.filter((surgicalOrder) => {
          if (surgicalOrder.surgical_order_id === attendance.identifier)
            return surgicalOrder
        })[0]?.active_status
      )?.label,
      type: AttendanceTypeTranslation[attendance.type],
      unit: new Hospital(0, attendance.hospitalName, attendance.hospitalName)
        .name,
      date: attendance.attendanceDate
        ? moment(new Date(attendance.attendanceDate)).utc().format('DD/MM/YYYY')
        : 'Aguardando agendamento'
    }
    const attendanceFunction: Record<AttendanceType, () => void> = {
      [AttendanceType.SURGICAL]: () =>
        history.push('/pedido-cirurgico/detalhes', {
          surgical_order_id: attendance.identifier
        }),
      [AttendanceType.AMBULATORY]: () =>
        history.push('/agendamento/status', {
          schedule: prop
        })
    }
    return {
      ...prop,
      onClick: attendanceFunction[attendance.type]
    }
  }

  const adaptedAttendances = patientAttendances
    .slice(0, ATTENDANCES_PER_PAGE)
    .map((attendance) => adaptAttendanceToProp(attendance))
  const hasMoreAttendances =
    patientAttendances.length === ATTENDANCES_PER_PAGE + 1

  return (
    <>
      {(hasConfirmation || !!nextExamNotVerified) && (
        <ConfirmNewExamModal
          confirmated={!!location.state?.confirmated}
          show={hasConfirmation || !!nextExamNotVerified || false}
          exam_id={location.state?.exam_id || nextExamNotVerified?.exam_id}
          close={() => closeExamModal()}
        />
      )}

      <MyDocumentsOnboardingModal
        show={showDocumentsOnboardingModal}
        close={() => setShowDocumentsOnboardingModal(false)}
        preventAutomateClose
        preventOutsideClickClose
      />

      <PatientHomeLayout
        openScheduledSurgeries={openScheduledSurgeries}
        openSurgeriesModalVisible={openSurgeriesModalVisible}
        setOpenSurgeriesModalVisible={setOpenSurgeriesModalVisible}
        user={user}
        surgeries={
          patientSurgeries
            ? mapSurgeryPropsToServiceOverviewProps(patientSurgeries)
            : undefined
        }
        isLoading={isLoading}
        redirect={history.push}
        pendencies={pendencies}
        hasMoreAttendances={hasMoreAttendances}
        attendances={adaptedAttendances}
        renewEmergencyTokenProps={{
          canRenew,
          renewToken,
          showRenewToken,
          emergencyRoomAttendance
        }}
        surgicalOrderCancellationNotification={
          surgicalOrderCancellationNotification
        }
      />
    </>
  )
}

export default PatientHome

function handleStatusColor(surgicalOrder: SurgicalOrderModel) {
  let color = '#888888'
  surgicalOrder.status?.forEach((status) => {
    if (status.status === SurgicalOrderStatus.CANCELLED) {
      return (color = '#F48989')
    } else if (status.status === SurgicalOrderStatus.SCHEDULED) {
      return (color = '#1BD15D')
    }
  })
  return color
}

function adaptSurgicalOrderToSurgeryProps(
  surgicalOrder: SurgicalOrderModel
): SurgeryProps {
  const statusInfo = getStatusInfo(
    surgicalOrder?.status?.slice(-1)?.[0]?.status ?? ''
  )
  return {
    doctorName: surgicalOrder?.doctor?.name ?? '-',
    doctorRole: 'Médico responsável',
    unit: new Hospital(
      surgicalOrder?.hospital?.hospital_id ?? 0,
      surgicalOrder?.hospital?.name ?? '',
      surgicalOrder?.hospital?.name ?? ''
    ).name,
    status: surgicalOrder?.status?.slice(-1)?.[0]?.status ?? '',
    statusColor: handleStatusColor(surgicalOrder) ?? '',
    statusLabel: statusInfo?.label ?? '',
    surgical_order_id: surgicalOrder?.surgical_order_id ?? 1,
    scheduleDate: surgicalOrder?.surgeryDate ?? '',
    title: surgicalOrder?.procedure?.[0].description ?? 'Procedimento cirúrgico'
  }
}

function adaptSurgeryPropsToServiceOverviewProps(
  surgeryProps: SurgeryProps
): ServiceOverviewProps {
  return {
    title: surgeryProps?.title ?? '',
    type: 'Cirurgia',
    unit: surgeryProps?.unit,
    color: surgeryProps?.statusColor,
    status: surgeryProps?.statusLabel ?? '',
    date: surgeryProps?.scheduleDate
      ? moment(new Date(surgeryProps?.scheduleDate))
          .utc()
          .format('DD/MM/YYYY - HH:mm')
      : '-',
    surgicalId: surgeryProps?.surgical_order_id
  }
}

function mapSurgicalOrderToSurgeryProps(
  surgicalOrders: SurgicalOrderModel[]
): SurgeryProps[] {
  return surgicalOrders.map((surgicalOrder) =>
    adaptSurgicalOrderToSurgeryProps(surgicalOrder)
  )
}

function mapSurgeryPropsToServiceOverviewProps(
  surgeryProps: SurgeryProps[]
): ServiceOverviewProps[] {
  return surgeryProps.map((surgeryProp) =>
    adaptSurgeryPropsToServiceOverviewProps(surgeryProp)
  )
}

const emergencyRoomAttendanceFields = [
  ' hospital {',
  '  name',
  ' }',
  ' specialty',
  ' emergency_room_attendance_id',
  ' accompanies {',
  '  accompanying_id',
  '  name',
  '  lastName',
  ' }',
  ' emergencyRoomToken {',
  '  emergency_room_token_id',
  '  qtRenewed',
  '  arrivalForecast',
  '  token',
  ' }'
]
