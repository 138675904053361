import { Doctor } from 'domain/entities/doctor-model'
import { AddDoctor } from 'domain/usecases/doctor/add-doctor'
import { AddDoctorSignature } from 'domain/usecases/doctor/add-signature'
import { FinishDoctorSignup } from 'domain/usecases/doctor/finish-doctor-signup'
import { LoadDoctor } from 'domain/usecases/doctor/load-doctor'
import { LoadDoctorByInviteToken } from 'domain/usecases/doctor/load-doctor-by-invite-token'
import { SearchDoctors } from 'domain/usecases/doctor/search-doctors'
import {
  RepositoryErrors,
  RepositoryErrorsKey
} from 'repository/errors/repository-errors'
import {
  addDoctorMutation,
  addDoctorSurgicalOrderMutation,
  addFavoriteDoctorMutation,
  createDoctorRegisterStepPendencyMutation,
  completeDoctorRegisterMutation,
  finishDoctorRecommendationMutation,
  finishDoctorSignupMutation,
  finishDoctorSurgicalOrder,
  resendDoctorInviteMutation,
  updateDoctorMutation,
  SendDoctorRegisterChangesToAnalysisMutation,
  UpdateDoctorRegisterStatusMutation,
  LinkSecretaryMutation
} from 'repository/graphql/mutations'
import {
  getDoctorByInviteTokenQuery,
  getDoctorPendenciesQuery,
  getDoctorQuery,
  getDoctorRegisterPendenciesGroupedByDataQuery,
  getDoctorSurgeryRequestsCountQuery,
  getDoctorWithRecommendationQuery,
  getExternalCertifications,
  getAllSurgicalOrdersByDoctor,
  getSurgicalOrdersByDoctorAndStatus,
  loadAllDoctorRegistersQuery,
  loadDoctorInfoQuery,
  loadMedicalTeamsQuery,
  loadRecommendedDoctorsQuery,
  searchDoctorsQuery
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { DoctorRepository as IDoctorRepository } from 'repository/interfaces/doctor-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { AddDoctorModel } from 'service/usecases/add-doctor/remote-add-doctor'
import { FinishDoctorSignupModel } from 'service/usecases/finish-doctor-signup/remote-finish-doctor-signup'
import { AddDoctorSurgicalOrder } from 'domain/usecases/doctor/add-doctor-surgical-order'
import { LoadDoctorSchedule } from 'domain/usecases/doctor/load-doctor-schedule'
import { LoadDoctorSurgeryRequestCount } from 'domain/usecases/doctor/load-doctor-surgery-request-count'
import { LoadSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-surgical-order'
import { LoadAllSurgicalOrdersByDoctor } from 'domain/usecases/doctor/load-all-surgical-order'
import { FinishDoctorSurgicalOrder } from 'domain/usecases/doctor/finish-doctor-surgical-order'
import { LoadDoctorInfo } from 'domain/usecases/doctor/load-doctor-info'
import { UpdateDoctor } from 'domain/usecases/doctor/update-doctor'
import { LoadDocumentExternalCertifications } from 'domain/usecases/doctor/load-documents-external-certifications'
import { UploadDoctorDocument } from 'domain/usecases/doctor/upload-doctor-document'
import { LoadDoctorPendencies } from 'domain/usecases/doctor/load-doctor-pendencies'
import { LoadRecommendedDoctors } from 'domain/usecases/doctor/load-recommended-doctors'
import { LoadDoctorWithRecommendation } from 'domain/usecases/doctor/load-doctor-with-recommendation'
import { ResendDoctorInvite } from 'domain/usecases/doctor/resendDoctorInvite'
import { LoadDoctorDocument } from 'domain/usecases/doctor/load-doctor-document'
import { AddFavoriteSurgicalOrder } from 'domain/usecases/doctor/add-favorite-surgical-order'
import { FinishDoctorRecommendation } from 'domain/usecases/doctor/finish-doctor-recommendation'
import { LoadMedicalTeams } from 'domain/usecases/medical-team/load-medical-teams'
import { LoadAllDoctorRegisters } from 'domain/usecases/doctor/load-all-doctor-registers'
import { plainToClass } from 'class-transformer'
import { DoctorRegister } from 'domain/entities/doctor-register'
import { CompleteDoctorRegister } from '../../../domain/usecases/doctor/complete-doctor-register'
import { CreateDoctorRegisterStepPendency } from 'domain/usecases/doctor-register-step-pendency/create-doctor-register-step-pendency'
import { DoctorRegisterPendency } from 'domain/entities/doctor-register-step-pendency'
import { SendDoctorRegisterChangesToAnalysis } from '../../../domain/usecases/doctor/send-doctor-register-changes-to-analysis'
import { LoadDoctorRegisterPendenciesGroupedDate } from '../../../domain/usecases/doctor/load-doctor-register-pendencies-grouped-date'
import { InternalServerError } from 'common/errors'
import { UpdateDoctorRegisterStatus } from '../../../domain/usecases/doctor-register/update-doctor-register-status'
import { LinkSecretary } from 'domain/usecases/doctor/link-secretary'

export class DoctorRepository implements IDoctorRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async loadAllSurgicalOrdersByDoctor(
    params: LoadAllSurgicalOrdersByDoctor.Params
  ): Promise<LoadAllSurgicalOrdersByDoctor.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAllSurgicalOrdersByDoctor.Model>

    const query = getAllSurgicalOrdersByDoctor(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadAllSurgicalOrdersByDoctor.Model
    }
  }

  async loadSurgicalOrdersByDoctor(
    params: LoadSurgicalOrdersByDoctor.Params
  ): Promise<LoadSurgicalOrdersByDoctor.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadSurgicalOrdersByDoctor.Model>

    const query = getSurgicalOrdersByDoctorAndStatus(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.order_status, 'order_status'),
          ...makeGraphQLVariable(params.cancelled, 'cancelled'),
          ...makeGraphQLVariable(params.unfinished, 'unfinished'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadSurgicalOrdersByDoctor.Model
    }
  }

  async addDoctor(params: AddDoctor.Params): Promise<Doctor> {
    const apiRepository = this.apiRepository as IApiRepository<AddDoctor.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addDoctorMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddDoctorModel
    }
  }

  async loadDoctorByInviteToken(
    token: string,
    params?: LoadDoctorByInviteToken.Params
  ): Promise<LoadDoctorByInviteToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorByInviteToken.Model>

    const query = getDoctorByInviteTokenQuery(token, params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(token, 'inviteToken')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorByInviteToken.Model
    }
  }

  async finishDoctorSignup(params: FinishDoctorSignup.Params): Promise<Doctor> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishDoctorSignup.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: finishDoctorSignupMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: addDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishDoctorSignupModel
    }
  }

  async countSurgeryRequests(): Promise<LoadDoctorSurgeryRequestCount.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorSurgeryRequestCount.Model>

    const query = getDoctorSurgeryRequestsCountQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorSurgeryRequestCount.Model
    }
  }

  async loadDoctor(params?: LoadDoctor.Params): Promise<LoadDoctor.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadDoctor.Model>

    const query = getDoctorQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctor.Model
    }
  }

  async loadDoctorPendencies(
    params: LoadDoctorPendencies.Params
  ): Promise<LoadDoctorPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorPendencies.Model>
    const query = getDoctorPendenciesQuery(params.params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.query, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorPendencies.Model
    }
  }

  async searchDoctors(
    params: SearchDoctors.Params
  ): Promise<SearchDoctors.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchDoctors.Model>

    const query = searchDoctorsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.doctorName, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchDoctors.Model
    }
  }

  async addFavorite(
    params: AddDoctorSignature.Params
  ): Promise<AddDoctorSignature.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddDoctorSignature.Model>
    const httpResponse = await apiRepository.post({
      url: '/doctor/signature',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddDoctorSignature.Model
    }
  }

  async addSurgicalOrder(
    params: AddDoctorSurgicalOrder.Params
  ): Promise<AddDoctorSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddDoctorSurgicalOrder.Model>

    const query = addDoctorSurgicalOrderMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body!
    }
  }

  async loadDoctorSchedule(
    params: LoadDoctorSchedule.Params
  ): Promise<LoadDoctorSchedule.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorSchedule.Model>
    const httpResponse: any = await apiRepository.get(
      {
        url: `/doctor/schedule?date=${params.date}`
      },
      false
    )
    if (httpResponse?.statusCode === ApiStatusCode.serverError) {
      throw handleGraphQLError(
        RepositoryErrors[
          (httpResponse?.body?.code as RepositoryErrorsKey) || 'ERROR_IN_DB'
        ]
      )
    }
    return httpResponse.body as LoadDoctorSchedule.Model
  }

  async finishDoctorSurgicalOrder(
    params: FinishDoctorSurgicalOrder.Params
  ): Promise<FinishDoctorSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishDoctorSurgicalOrder.Model>

    const query = finishDoctorSurgicalOrder

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            params?.surgical_order_id,
            'surgical_order_id'
          ),
          ...makeGraphQLVariable(
            params?.surgical_center_id,
            'surgical_center_id'
          )
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishDoctorSurgicalOrder.Model
    }
  }

  async updateDoctor(params: UpdateDoctor.Params): Promise<Doctor> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateDoctor.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateDoctorMutation.query,
        variables: {
          ...makeGraphQLVariable(params?.data, 'data'),
          ...makeGraphQLVariable(params?.doctor_id, 'doctor_id')
        }
      },
      query: updateDoctorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateDoctor.Model
    }
  }

  async loadDocumentsExternalCertifications(
    params: LoadDocumentExternalCertifications.Params
  ): Promise<LoadDocumentExternalCertifications.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDocumentExternalCertifications.Model>

    const query = getExternalCertifications(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: { ...makeGraphQLVariable(params?.doctor_id, 'doctor_id') }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDocumentExternalCertifications.Model
    }
  }

  async uploadDoctorDocument(
    params: UploadDoctorDocument.Params
  ): Promise<UploadDoctorDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadDoctorDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/doctor/upload-doctor-document',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadDoctorDocument.Model
    }
  }

  async loadRecommendedDoctors(
    params: LoadRecommendedDoctors.Params
  ): Promise<LoadRecommendedDoctors.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadRecommendedDoctors.Model>

    const query = loadRecommendedDoctorsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.query, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadRecommendedDoctors.Model
    }
  }

  async loadDoctorWithRecommendation(
    params: LoadDoctorWithRecommendation.Params
  ): Promise<LoadDoctorWithRecommendation.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorWithRecommendation.Model>

    const query = getDoctorWithRecommendationQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.doctor_id, 'doctor_id'),
          ...makeGraphQLVariable(params?.loadDocuments, 'loadDocuments')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorWithRecommendation.Model
    }
  }

  async resendDoctorInvite(
    params: ResendDoctorInvite.Params
  ): Promise<ResendDoctorInvite.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ResendDoctorInvite.Model>

    const query = resendDoctorInviteMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.phone, 'phone'),
          ...makeGraphQLVariable(params.email, 'email'),
          ...makeGraphQLVariable(params.doctor_id, 'doctor_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ResendDoctorInvite.Model
    }
  }

  async loadDoctorInfo(
    doctor_id: number,
    params?: LoadDoctorInfo.Params
  ): Promise<LoadDoctorInfo.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorInfo.Model>

    const query = loadDoctorInfoQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(doctor_id, 'doctor_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return plainToClass(Doctor, httpResponse.body)
    }
  }

  async loadDoctorDocument(
    params: LoadDoctorDocument.Params
  ): Promise<LoadDoctorDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/doctor/${params.doctor_id}/document/${params.document_id}`
      },
      true
    )
    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    if (httpResponse.status && httpResponse.status !== ApiStatusCode.ok) {
      throw new InternalServerError()
    } else {
      return {
        contentType,
        data
      }
    }
  }

  async addFavoriteSurgicalOrder(
    params: AddFavoriteSurgicalOrder.Params
  ): Promise<AddFavoriteSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddFavoriteSurgicalOrder.Model>

    const query = addFavoriteDoctorMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddFavoriteSurgicalOrder.Model
    }
  }

  async finishDoctorRecommendation(
    params: FinishDoctorRecommendation.Params
  ): Promise<FinishDoctorRecommendation.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<FinishDoctorRecommendation.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: finishDoctorRecommendationMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: finishDoctorRecommendationMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as FinishDoctorRecommendation.Model
    }
  }

  async loadMedicalTeams(
    params: LoadMedicalTeams.Params
  ): Promise<LoadMedicalTeams.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadMedicalTeams.Model>

    const query = loadMedicalTeamsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadMedicalTeams.Model
    }
  }

  async loadAllDoctorRegisters(
    params: LoadAllDoctorRegisters.Params
  ): Promise<LoadAllDoctorRegisters.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadAllDoctorRegisters.Model>

    const query = loadAllDoctorRegistersQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.pagination, 'pagination'),
          ...makeGraphQLVariable(params.query, 'query')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return {
        ...httpResponse.body,
        data:
          httpResponse.body?.data.map((val) =>
            plainToClass(DoctorRegister, val)
          ) || []
      } as LoadAllDoctorRegisters.Model
    }
  }

  async completeDoctorRegister(
    params: CompleteDoctorRegister.Params
  ): Promise<CompleteDoctorRegister.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CompleteDoctorRegister.Model>

    const query = completeDoctorRegisterMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.doctor_register_id,
          'doctor_register_id'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CompleteDoctorRegister.Model
    }
  }

  async createDoctorRegisterStepPendency(
    params: CreateDoctorRegisterStepPendency.Params
  ): Promise<CreateDoctorRegisterStepPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateDoctorRegisterStepPendency.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: createDoctorRegisterStepPendencyMutation.query,
        variables: makeGraphQLVariable(params, 'input')
      },
      query: createDoctorRegisterStepPendencyMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return plainToClass(DoctorRegisterPendency, httpResponse.body)
    }
  }

  async sendDoctorRegisterChangesToAnalysis(
    params: SendDoctorRegisterChangesToAnalysis.Params
  ): Promise<SendDoctorRegisterChangesToAnalysis.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SendDoctorRegisterChangesToAnalysis.Model>

    const query = SendDoctorRegisterChangesToAnalysisMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.doctor_register_id,
          'doctor_register_id'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SendDoctorRegisterChangesToAnalysis.Model
    }
  }

  async loadDoctorRegisterPendenciesGroupedDate(
    params: LoadDoctorRegisterPendenciesGroupedDate.Params
  ): Promise<LoadDoctorRegisterPendenciesGroupedDate.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorRegisterPendenciesGroupedDate.Model>

    const query = getDoctorRegisterPendenciesGroupedByDataQuery(params.params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            params.doctor_register_id,
            'doctor_register_id'
          ),
          ...makeGraphQLVariable(params.isSolved, 'isSolved')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorRegisterPendenciesGroupedDate.Model
    }
  }

  async updateDoctorRegisterStatus(
    params: UpdateDoctorRegisterStatus.Params
  ): Promise<UpdateDoctorRegisterStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateDoctorRegisterStatus.Model>

    const query = UpdateDoctorRegisterStatusMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(
            params.doctor_register_id,
            'doctor_register_id'
          ),
          ...makeGraphQLVariable(params.status, 'status')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateDoctorRegisterStatus.Model
    }
  }

  async linkSecretary(
    params: LinkSecretary.Params
  ): Promise<LinkSecretary.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LinkSecretary.Model>

    const query = LinkSecretaryMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.secretary_id, 'secretary_id')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LinkSecretary.Model
    }
  }
}
