import React from 'react'

import {
  RouteProps,
  Route,
  Redirect,
  useHistory,
  useLocation
} from 'react-router-dom'

import { useStores } from 'presentation/hooks/use-stores'
import { User } from 'domain/entities/user-model'
import { Profile } from 'common/enum/profile'
import { decodeJwt } from 'common/utils/decode-jwt'
// import { DoctorNavPermissions } from 'common/enum/doctor-nav-permissions'
import { useSearchParams } from 'presentation/hooks/use-search-params'
import isBase64 from 'presentation/utils/is-base-64'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'

type Props = {
  profile?: Profile[]
} & RouteProps

const PrivateRoute: React.FC<Props> = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = useSearchParams()
  const searchParamToken = searchParams.get('token')
  const searchParamName = searchParams.get('name')
  const encodedParams = isBase64(searchParams.toString())
    ? searchParams.toString()
    : btoa(searchParams.toString())
  const localStorage = new LocalStorageAdapter()
  const currentAccountStorage = localStorage.get('account')

  if (searchParamToken) {
    localStorage.set('account', {
      accessToken: searchParamToken,
      user: {
        ...currentAccountStorage?.user,
        name: searchParamName,
        role: 'patient'
      }
    })
  }

  const account = useStores().currentAccount.getCurrentAccount()
  let user = {} as User
  if (account?.accessToken) {
    user = decodeJwt<User>(account.accessToken)
  } else {
    history.push({
      pathname: '/login',
      search: encodedParams,
      state: { pathname: location.pathname }
    })
  }

  // Remove code block (not used for patient role):
  // const permissions = useStores().permissions

  // useLayoutEffect(() => {
  //   let isMounted = true

  //   async function loadData() {
  //     try {
  //       await permissions.load()
  //     } catch {
  //       return history.push({
  //         pathname: '/login',
  //         search: encodedParams,
  //         state: { pathname: location.pathname }
  //       })
  //     }

  //     if (props.profile?.includes(Profile.DOCTOR)) {
  //       handleDoctorPushToRoute(isMounted)
  //     }
  //   }

  //   loadData()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [location])

  // const handleDoctorPushToRoute = (isMounted: boolean) => {
  //   if (!isMounted) return
  //   if (
  //     permissions.can(DoctorNavPermissions.GO_TO_RECOMMENDATION_REGISTER) &&
  //     location.pathname !== '/cadastro-por-indicacao'
  //   ) {
  //     history.push('/cadastro-por-indicacao')
  //     return
  //   }

  //   if (
  //     permissions.can(DoctorNavPermissions.GO_TO_REVIEW) &&
  //     !location.pathname.includes('/cadastro-por-indicacao')
  //   ) {
  //     history.push('/cadastro-por-indicacao/resumo')
  //     return
  //   }

  //   if (
  //     permissions.can(DoctorNavPermissions.GO_TO_ADD_SIGNATURE) &&
  //     location.pathname !== '/assinatura/save'
  //   ) {
  //     history.push('/assinatura/save')
  //     return
  //   }
  // }

  return account?.accessToken && props.profile?.includes(user.role) ? (
    <Route {...props} />
  ) : (
    <Route
      {...props}
      component={() => (
        <Redirect
          to={{
            pathname: '/login',
            search: encodedParams,
            state: { pathname: location.pathname }
          }}
        />
      )}
    />
  )
}

export default PrivateRoute
