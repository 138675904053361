import theme from 'presentation/styles/theme'
import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  background-color: ${theme.ds.colors.white};
  display: flex;
  justify-content: center;
  padding: 40px 0 0;
  & > div {
    cursor: pointer;
    width: 140px;
  }
`
