import { toast } from 'react-toastify'
import { AccountModel } from 'domain/entities/account-model'
import { PbacAdapter } from 'infra/pbac-adapter/pbac-adapter'
import { makeAutoObservable } from 'mobx'
import { LoadUserGroups } from 'domain/usecases/authentication/load-user-groups'
import { Permissions } from './permissions'
import { Profile } from 'common/enum/profile'

export class DefaultPermissions implements Permissions {
  constructor(
    private pbacAdapter: PbacAdapter,
    private loadUserGroups: LoadUserGroups,
    private getCurrentUser: () => AccountModel
  ) {
    makeAutoObservable(this)
  }
  set(params: string[]) {
    return (this.pbacAdapter = new PbacAdapter(params))
  }

  can(permission: string) {
    return this.pbacAdapter.can(permission)
  }

  getAbilities() {
    return this.pbacAdapter.getAbilities()
  }

  async load() {
    if (
      this.getCurrentUser()?.accessToken &&
      [
        Profile.CRMO,
        Profile.HOSPITAL,
        Profile.DOCTOR_REGISTER,
        Profile.DIRECTOR_DOCTOR_REGISTER
      ].includes(this.getCurrentUser()?.user.role)
    ) {
      try {
        const groups = await this.loadUserGroups.load([
          'groups',
          'permissions',
          'isMain'
        ])
        this.set(groups.permissions.map((permission) => permission))
      } catch (error) {
        toast.error('Não foi possível carregar as suas permissões')
        return
      }
    }
  }

  clean() {
    return (this.pbacAdapter = new PbacAdapter())
  }
}
