import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { ActiveSearchSurgeryType } from 'common/enum/active-search-surgery-type'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

type Props = {
  surgicalOrder: any
  openScheduledSurgeries: any
  setIsLoading: (value: boolean) => void
}
export const ReasonsNoSurgeryForm = ({
  surgicalOrder,
  openScheduledSurgeries,
  setIsLoading
}: Props) => {
  const [showTextArea, setShowTextArea] = useState<boolean>(false)

  const history = useHistory()

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    validate_reason_other: yup.boolean().optional(),
    observation: yup
      .string()
      .max(800, 'Limite de caracteres excedido')
      .when('validate_reason_other', {
        is: true,
        then: yup.string().required()
      })
  })

  const surgicalOrderService = useServices().surgicalOrder

  async function handleSubmitOtherReason(values: any) {
    if (surgicalOrder.surgical_order_id) {
      await surgicalOrderService.sendReasonNotSurgery({
        aligned_date: false,
        need_help: false,
        surgical_order_id: surgicalOrder.surgical_order_id,
        type: values.type,
        observation: values.observation
        // observation_to_need_help: ''
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      type: undefined,
      validate_reason_other: false,
      observation: undefined
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        setIsLoading(true)
        if (
          formik.values.type !==
          ActiveSearchSurgeryType.I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE
        ) {
          await handleSubmitOtherReason(formik.values)
        }
        history.push('/confirmacao-ajuda-cirurgia', {
          surgicalOrder: surgicalOrder,
          reasonNotSurgery: formik.values,
          openScheduledSurgeries: openScheduledSurgeries,
          otherReason:
            formik.values.type !==
            ActiveSearchSurgeryType.I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE
        })
      } catch (error) {
        toast.error('Ocorreu um erro ao enviar motivo')
      } finally {
        setIsLoading(false)
      }
    }
  })

  const handleChangeReason = (reason: ActiveSearchSurgeryType) => {
    formik.setFieldValue('type', reason)
    if (reason === 'OTHER') {
      formik.setFieldValue('validate_reason_other', true)
      setShowTextArea(true)
    } else {
      formik.setFieldValue('validate_reason_other', false)
      formik.setFieldValue('observation', '')
      setShowTextArea(false)
    }
  }

  return (
    <>
      <ContainerNew
        form
        onSubmit={formik.handleSubmit}
        primaryButton={
          <ButtonNew
            color="primary"
            fullWidth
            type="submit"
            size="large"
            disabled={!formik.values.type}
          >
            Próximo
          </ButtonNew>
        }
      >
        <S.BoxContent>
          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={
              ActiveSearchSurgeryType.I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE
            }
            value={
              ActiveSearchSurgeryType.I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE
            }
            label={
              'Não realizei a cirurgia ainda, mas quero continuar o processo'
            }
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.SURGERY_ALREADY_DONE}
            value={ActiveSearchSurgeryType.SURGERY_ALREADY_DONE}
            label={'Cirurgia já foi realizada em outra unidade da Rede'}
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={
              ActiveSearchSurgeryType.SURGERY_ALREADY_DONE_IN_OTHER_HOSPITAL
            }
            value={
              ActiveSearchSurgeryType.SURGERY_ALREADY_DONE_IN_OTHER_HOSPITAL
            }
            label={
              'Cirurgia já foi realizada em outro hospital e pode ser cancelada'
            }
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.I_NO_LONGER_WISH_TO_CONTINUE}
            value={ActiveSearchSurgeryType.I_NO_LONGER_WISH_TO_CONTINUE}
            label={'Não desejo mais realizar a cirurgia'}
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.OPERATOR_AUTHORIZED}
            value={ActiveSearchSurgeryType.OPERATOR_AUTHORIZED}
            label={'Desisti por problemas de autorização da minha operadora'}
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.HOSPITAL_RETURN}
            value={ActiveSearchSurgeryType.HOSPITAL_RETURN}
            label={'Desisti por problemas de retorno do hospital'}
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.CHANGE_OF_DOCTOR}
            value={ActiveSearchSurgeryType.CHANGE_OF_DOCTOR}
            label={'Troquei de médico dentro da Rede Mater Dei de Saúde'}
            onCheck={(value) => handleChangeReason(value)}
          />
          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.HEALTH_PLAN_ANOTHER_HOSPITAL}
            value={ActiveSearchSurgeryType.HEALTH_PLAN_ANOTHER_HOSPITAL}
            label={'Meu plano de saúde me direcionou para outro hospital'}
            onCheck={(value) => handleChangeReason(value)}
          />
          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.PERSONAL_PROBLEM}
            value={ActiveSearchSurgeryType.PERSONAL_PROBLEM}
            label={'Desisti por problemas pessoais'}
            onCheck={(value) => handleChangeReason(value)}
          />

          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor={ActiveSearchSurgeryType.OTHER}
            value={ActiveSearchSurgeryType.OTHER}
            label={'Outras opções'}
            onCheck={(value) => handleChangeReason(value)}
          />

          {showTextArea && (
            <S.TextAreaWrapper>
              <TextAreaNew
                label="Descreva o problema aqui"
                required
                rows={4}
                name="observation"
                onInputChange={formik.handleChange('observation')}
                error={
                  formik.touched.observation
                    ? formik.errors.observation
                    : undefined
                }
                value={formik.values.observation}
                onBlur={formik.handleBlur('observation')}
              />
            </S.TextAreaWrapper>
          )}
        </S.BoxContent>
      </ContainerNew>
    </>
  )
}
