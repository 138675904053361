import React, { useState, CSSProperties } from 'react'
import * as S from './styles'
import CardNew from '../CardNew'

type Params = {
  value?: number
  showLabel?: boolean
  onClick?: (value: number) => void
  onChange?: (value: number) => void
  className?: string
  style?: CSSProperties
}

export default function NpsScale({
  value = 10,
  showLabel = false,
  onClick,
  onChange,
  className,
  style
}: Params) {
  const [activeValue, setActiveValue] = useState<number>(-1)

  const onClickItem = (key: number) => {
    if (onClick) {
      onClick(key)
    } else {
      onChange && onChange(key)
    }
  }

  const onHoverItem = (key: number) => {
    setActiveValue(key)
  }

  const onLeaveItem = () => {
    setActiveValue(-1)
  }

  const classes = (key: number) => {
    const val = activeValue !== -1 ? activeValue : value
    const active = Number(key) === val ? 'active' : ''
    return `scale-item ${active}`
  }

  return (
    <div className={className} style={style}>
      <S.ScaleContent>
        {Object.keys(defaultScale).map((key) => {
          return (
            <CardNew
              className={classes(Number(key))}
              background="transparent"
              key={key}
              color="white"
              shadow="none"
              onClick={() => onClickItem(Number(key))}
              onMouseOver={() => onHoverItem(Number(key))}
              onMouseLeave={() => onLeaveItem()}
            >
              <div
                style={{
                  background:
                    defaultScale[Number(key) as keyof typeof defaultScale].color
                }}
              >
                {key}
              </div>
            </CardNew>
          )
        })}
      </S.ScaleContent>

      {showLabel && (
        <S.RateLabel className="label" color="neutral700" weight="semiBold">
          {defaultScale[
            Number(
              activeValue !== -1 ? activeValue : value
            ) as keyof typeof defaultScale
          ]?.label || ''}
        </S.RateLabel>
      )}
    </div>
  )
}

const defaultScale = {
  0: {
    color: '#B72025',
    label: 'Péssimo'
  },
  1: {
    color: '#D62027',
    label: 'Múito ruim'
  },
  2: {
    color: '#F05223',
    label: 'Ruim'
  },
  3: {
    color: '#F36F21',
    label: ''
  },
  4: {
    color: '#FAA823',
    label: ''
  },
  5: {
    color: '#FFCA27',
    label: 'Neutro'
  },
  6: {
    color: '#EDD200',
    label: 'Regular'
  },
  7: {
    color: '#E1D93A',
    label: 'Bom'
  },
  8: {
    color: '#C5D92D',
    label: 'Múito bom'
  },
  9: {
    color: '#AFD136',
    label: 'Ótimo'
  },
  10: {
    color: '#64B64D',
    label: 'Excelente'
  }
}
