import { UpdateDoctorRegisterStatus } from 'domain/usecases/doctor-register/update-doctor-register-status'
import { DoctorRepository } from '../../../repository/interfaces/doctor-repository'

export class RemoteUpdateDoctorRegisterStatus
  implements UpdateDoctorRegisterStatus
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  execute(
    params: UpdateDoctorRegisterStatus.Params
  ): Promise<UpdateDoctorRegisterStatus.Model> {
    return this.doctorRepository.updateDoctorRegisterStatus(params)
  }
}
