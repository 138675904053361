import { FinishTutorialTutor } from 'domain/usecases/user/finish-tutorial-tutor'
import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'

export class RemoteFinishTutorialTutor implements FinishTutorialTutor {
  constructor(private readonly userRepository: IUserRepositoryRepository) {}

  finishTutorialTutor(): Promise<FinishTutorialTutor.Model> {
    return this.userRepository.finishTutorialTutor()
  }
}

export type FinishTutorialTutorModel = FinishTutorialTutor.Model
