import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import SuggestionsOrCriticisms from 'presentation/pages/SuggestionsOrCriticisms'
import { RemoteAddSuggestionOrCriticism } from 'service/usecases/suggestion-or-criticism/remote-suggestion-or-criticism'

export default function makePatientSuggestionOrCriticism() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const createSuggestionOrCriticism = new RemoteAddSuggestionOrCriticism(
    patientRepository
  )

  return (
    <SuggestionsOrCriticisms
      createSuggestionOrCriticism={createSuggestionOrCriticism}
    />
  )
}
