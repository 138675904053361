import styled from 'styled-components'
import theme from 'presentation/styles/theme'

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardTitle = styled.div<{
  color?: keyof typeof theme.ds.colors
}>`
  display: flex;
  gap: 8px;
  align-items: center;
  svg {
    path {
      fill: ${({ theme, color = 'terciary400' }) => theme.ds.colors[color]};
    }
  }
`

export const Quote = styled.div<{
  color?: keyof typeof theme.ds.colors
}>`
  border-left: ${({ theme, color = 'terciary400' }) =>
    `1px solid ${theme.ds.colors[color]}`};
  padding: 8px 0px 8px 8px;
  flex: 1;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.5em;
    max-height: 3em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
