import styled, { css } from 'styled-components'

import { HealthInsuranceCardWrapper } from 'presentation/shared/components/HealthInsuranceCard/styles'

export const Wrapper = styled.div`
  padding-bottom: 24px;
`
export const Content = styled.div`
  margin-top: 24px;

  ${HealthInsuranceCardWrapper} {
    ${({ theme }) => css`
      cursor: pointer;
      border: 1px solid transparent;
      transition: border ease 0.2s;
      &:hover,
      &:active,
      &:focus {
        border-color: ${theme.ds.colors.primary600};
      }
    `}
  }
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const IpsemgInfo = styled.p`
  ${({ theme }) => css`
    margin-top: -16px;
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 20px;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors.danger300};

    a {
      text-decoration: underline;
      color: ${theme.ds.colors.info400};
      transition: color ease 0.1s;

      &:hover {
        color: ${theme.ds.colors.info300};
      }
    }
  `}
`

export const PurpleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 20px;
`

export const CardsList = styled.div`
  /* height: 550px;
  overflow-y: scroll; */
`

export const SpacedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 63vh;
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const CardContainer = styled.div`
  display: grid;
  border: 1px solid #52dbcc;
  border-radius: 8px;
  background: ${({ theme }) => theme.ds.colors.white};
  padding: 15px;
  margin-bottom: 20px;

  button {
    margin-top: 20px;
  }
`

export const ExamTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  margin-bottom: 20px;
`

export const CancelReasonTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 20px;
`

export const CardTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const HorizontalButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  place-content: center;
`

export const TitleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};

  margin-bottom: 20px;
`

export const SubTitleHeader = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral500};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 20px;
`

export const HeaderContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const StrongHeader = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 8px;
`

export const ButtonPreparation = styled.div`
  display: flex;
  justify-content: end;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.ds.colors.neutral600};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const PurpleDescription = styled.strong`
  /* padding-left: 23px; */
  color: ${({ theme }) => theme.ds.colors.secondary800};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const AddressDescription = styled.p`
  /* padding-left: 23px; */
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};

  /* margin-bottom: 5px; */
`

export const SubDescription = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral500};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  margin-bottom: 30px;

  /* margin-bottom: 5px; */
`

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 12px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`

export const HorizontalLine = styled.hr`
  ${({ theme }) => css`
    width: 100%;
    margin: 10px 0px;
    color: ${theme.ds.colors.primary50};
    border: 1px solid ${theme.ds.colors.primary50};
    background-color: ${theme.ds.colors.primary50};
  `}
`

export const BlueNote = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    background-color: ${theme.ds.colors.info50};

    strong {
      color: ${theme.ds.colors.info700};
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }

    p {
      color: ${({ theme }) => theme.ds.colors.neutral700};
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }
  `}
`
