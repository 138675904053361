import { LoadDoctorOverallStatusForReview } from 'domain/usecases/overall-registration-status/load-doctor-overall-status-for-review'
import { OverallRegistrationStatusRepository } from 'repository/repositories/overall-registration-status/overall-registration-status-repository'

export class RemoteLoadDoctorOverallStatusForReview
  implements LoadDoctorOverallStatusForReview
{
  constructor(
    private readonly registrationStatusRepository: OverallRegistrationStatusRepository
  ) {}

  loadDoctorOverallStatusForReview(
    params: LoadDoctorOverallStatusForReview.Params
  ): Promise<LoadDoctorOverallStatusForReview.Model> {
    return this.registrationStatusRepository.loadDoctorOverallStatusForReview(
      params
    )
  }
}
