import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import theme from 'presentation/styles/theme'
import { handleUnityPhone } from 'common/utils/handleUnityPhone'
import React from 'react'

type SuccessModalProps = {
  onConfirm: () => void
  onClose: () => void
  open: boolean
  hospital_id?: number
}

export function NoticeModal({
  onConfirm,
  onClose,
  open,
  hospital_id
}: SuccessModalProps) {
  return (
    <SheetModal size={300} isOpen={open} onClose={onClose} backdrop="true">
      <div style={{ marginBottom: '20px' }}>
        <Heading size="large">
          Aviso sobre o <strong>convênio</strong>
        </Heading>
      </div>
      <SupportText>
        Esses dados podem ter sofrido alguma alteração, gentileza verificar com
        nossa equipe de suporte através do telefone
        <strong style={{ fontWeight: theme.font.light }}>
          {' '}
          {handleUnityPhone(hospital_id)}
        </strong>
      </SupportText>
      <Button
        fullWidth
        style={{ marginTop: '20px' }}
        onClick={() => {
          onConfirm()
        }}
      >
        Confirmar
      </Button>
    </SheetModal>
  )
}
