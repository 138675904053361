import { Accompanying } from 'domain/entities/accompanying-model'
import { Patient } from 'domain/entities/patient-model'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import Radio from 'presentation/shared/components/RadioButton'
import SupportText from 'presentation/components/SupportText'
import React, { useEffect, useState } from 'react'
import * as S from '../styles'
import { AddAccompanyingModal } from './AddAccompanyingModal'

type EmergencyRoomPatientInfoProps = {
  patient: Patient
  carousel: CarouselState
  setStep: (number: number) => void
  addAccompanying: AddAccompanying
  setForm: (data: SubmitEmergencyAttendance) => void
  form?: SubmitEmergencyAttendance
  updateAccompanies: () => void
}

export function PatientAccompanying({
  carousel,
  patient,
  addAccompanying,
  setForm,
  form,
  updateAccompanies,
  setStep
}: EmergencyRoomPatientInfoProps) {
  const [accompanists, setAccompanists] = useState<Accompanying[]>()
  const [selectedAccompanying, setSelectedAccompanying] = useState<number>()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (patient) {
      setAccompanists(patient.accompanists)
    }
  }, [patient])

  const onNextStep = () => {
    if (selectedAccompanying) {
      setForm({ ...form, accompanies_id: [selectedAccompanying] })
    }
    carousel.slideNext()
    setStep(1)
  }

  return (
    <>
      <S.PatientInfoContainer style={{ height: '100%' }}>
        <strong style={{ marginBottom: '0px' }}>Acompanhante</strong>
        <SupportText color="lightGray">
          Contato da pessoa que irá acompanhar você ao longo do procedimento
        </SupportText>

        <S.PatientAccompanyingList>
          {accompanists?.map((accompanying) => (
            <Radio
              label={accompanying.name}
              key={accompanying.accompanying_id}
              checked={selectedAccompanying === accompanying.accompanying_id}
              onCheck={() =>
                setSelectedAccompanying(accompanying.accompanying_id)
              }
              onClick={() =>
                setSelectedAccompanying(accompanying.accompanying_id)
              }
            />
          ))}
        </S.PatientAccompanyingList>
        <Button fullWidth onClick={() => onNextStep()}>
          Confirmar
        </Button>
        <Button
          style={{ marginTop: '20px' }}
          variant="outlined"
          fullWidth
          onClick={() => setOpen(true)}
        >
          Add. acompanhante
        </Button>
        <AddAccompanyingModal
          patient={patient}
          onClose={() => setOpen(false)}
          open={open}
          setAccompanists={(accompany) => setAccompanists(accompany)}
          accompanists={accompanists || []}
          addAccompanying={addAccompanying}
          updateAccompanies={updateAccompanies}
        />
      </S.PatientInfoContainer>
    </>
  )
}
