import React from 'react'

import SuggestionsOrCriticismsLayout from 'presentation/layouts/SuggestionsOrCriticisms'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'

type Props = {
  createSuggestionOrCriticism: CreateSuggestionOrCriticism
}

export default function SuggestionsOrCriticisms({
  createSuggestionOrCriticism
}: Props) {
  return (
    <SuggestionsOrCriticismsLayout
      createSuggestionOrCriticism={createSuggestionOrCriticism}
    />
  )
}
