import React, { InputHTMLAttributes } from 'react'
import MaskedInput from 'react-text-mask'

import theme from 'presentation/styles/theme'
import * as S from './styles'

export type TextFieldProps = {
  initialValue?: string | number | undefined
  label?: string
  labelColor?: keyof typeof theme.ds.colors
  labelSize?: keyof typeof theme.ds.typography.size
  labelWeight?: keyof typeof theme.ds.typography.weight
  labelIcon?: React.ReactNode
  onLabelIconClick?: () => void
  icon?: React.ReactNode
  iconPosition?: 'insideRight' | 'outsideRight' | 'insideLeft' | 'outsideLeft'
  iconIsButton?: boolean
  iconColor?: keyof typeof theme.ds.colors
  iconBgColor?: keyof typeof theme.ds.colors
  onIconClick?: (param?: any) => void
  onInputChange?: (value: string) => void
  disabled?: boolean
  error?: string | false
  bgColor?: keyof typeof theme.ds.colors
  required?: boolean
  requiredColor?: keyof typeof theme.ds.colors
  guide?: boolean
  mask?: (string | RegExp)[] | ((value: string) => (string | RegExp)[])
  regex?: RegExp
  onResetTableValue?: () => any
} & InputHTMLAttributes<HTMLInputElement>

const TextFieldNew: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    label,
    labelSize = 'small',
    labelWeight = 'normal',
    name,
    mask,
    error,
    disabled = false,
    onInputChange,
    style,
    bgColor = 'white',
    icon,
    iconPosition = 'insideRight',
    iconIsButton = false,
    iconColor = 'neutral300',
    iconBgColor = 'primary600',
    onIconClick,
    required = false,
    requiredColor = 'danger200',
    labelColor = 'neutral900',
    onResetTableValue,
    labelIcon,
    onLabelIconClick,
    guide,
    regex,
    ...props
  }: TextFieldProps,
  ref
) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    if (onResetTableValue && newValue === '') onResetTableValue()

    if (newValue && regex && !regex.test(newValue)) {
      return
    }

    !!onInputChange && onInputChange(newValue)
    !!props.onChange && props.onChange(e)
  }

  const iconClick = () => {
    !!onIconClick && onIconClick()
  }

  const labelIconClick = () => {
    !!onLabelIconClick && onLabelIconClick()
  }

  return (
    <S.Wrapper style={style} disabled={disabled} error={!!error}>
      {(!!label || !!labelIcon) && (
        <S.LabelWrapper>
          {!!label && (
            <S.Label
              htmlFor={name}
              labelColor={labelColor}
              labelSize={labelSize}
              labelWeight={labelWeight}
            >
              {label}{' '}
              {required && (
                <S.Asterisk requiredColor={requiredColor}>*</S.Asterisk>
              )}
            </S.Label>
          )}
          {!!labelIcon && (
            <S.LabelIcon onClick={labelIconClick}>{labelIcon}</S.LabelIcon>
          )}
        </S.LabelWrapper>
      )}
      <S.InputWrapper>
        {!!icon && (
          <S.Icon
            type="button"
            onClick={iconClick}
            iconColor={iconColor}
            iconBgColor={iconBgColor}
            iconPosition={iconPosition}
            iconIsButton={iconIsButton}
            disabled={disabled || !iconIsButton}
          >
            {icon}
          </S.Icon>
        )}
        {mask ? (
          <MaskedInput
            type="text"
            mask={mask}
            guide={guide ?? true}
            disabled={disabled}
            name={name}
            {...props}
            onChange={onChange}
            render={(ref, props) => (
              <S.Input
                ref={(input) => ref(input!)}
                bgColor={bgColor}
                icon={icon}
                iconPosition={iconPosition}
                {...(label ? { id: name } : {})}
                {...props}
              />
            )}
          />
        ) : (
          <S.Input
            type="text"
            disabled={disabled}
            name={name}
            bgColor={bgColor}
            ref={ref}
            icon={icon}
            iconPosition={iconPosition}
            {...(label ? { id: name } : {})}
            {...props}
            onChange={onChange}
          />
        )}
      </S.InputWrapper>
      {!!error && <S.Error className="error">{error}</S.Error>}
    </S.Wrapper>
  )
}

export default React.forwardRef(TextFieldNew)
