import { makeAutoObservable } from 'mobx'
import { AccountModel } from 'domain/entities/account-model'
import { Logout } from 'domain/usecases/authentication/logout'
import { ChangeDependent } from 'domain/usecases/dependents/change-denpendent'
export class CurrentUser {
  constructor(
    private readonly setCurrentUserImplementation: (
      account: AccountModel
    ) => void,
    private readonly getCurrentUserImplementation: () => AccountModel,
    private readonly removeCurrentUserImplementation: () => void,
    private readonly changeDependent: ChangeDependent,
    private readonly logout: Logout
  ) {
    this.setCurrentUserImplementation = setCurrentUserImplementation
    this.getCurrentUserImplementation = getCurrentUserImplementation
    makeAutoObservable(this)
  }

  setCurrentAccount(account: AccountModel) {
    return this.setCurrentUserImplementation(account)
  }

  async setCurrentDependent(user_id: number | null) {
    try {
      const newDependent = await this.changeDependent.update({ user_id })
      this.setCurrentAccount(
        new AccountModel(newDependent.accessToken, newDependent.user)
      )
    } catch (error: any) {
      console.error(error)
    }
  }

  async removeCurrentAccount() {
    try {
      const account = this.getCurrentAccount()
      await this.logout.logout({ accessToken: account.accessToken })
      return this.removeCurrentUserImplementation()
    } catch (err) {
      return err
    }
  }

  getCurrentAccount(): AccountModel {
    return this.getCurrentUserImplementation()
  }
}

export default CurrentUser
