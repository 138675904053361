import React, { useEffect, useRef } from 'react'
import * as S from './styles'

type EmergencyMenuProps = {
  goBack?: () => void
  onSelectItem: (value: string) => void
  selectedItemMenu: string
}

type MenuItems = {
  title: string
  key: string
}[]

export function EmergencyMenu({
  onSelectItem,
  selectedItemMenu
}: EmergencyMenuProps) {
  const menuItems: MenuItems = [
    { title: 'Consultas', key: 'appointments' },
    { title: 'Exames', key: 'exams' },
    { title: 'Cirurgia', key: 'surgery' },
    // { title: 'PRONTO-SOCORRO', key: 'emergency_room' },
    { title: 'Vacinas', key: 'vaccines' }
  ]

  const scrollRef = useRef<HTMLDivElement>(null)

  const handleSelected = (selectedItem: string) => {
    onSelectItem(selectedItem)
  }

  useEffect(() => {
    if (
      scrollRef.current &&
      (selectedItemMenu === 'emergency_room' || selectedItemMenu === 'vaccines')
    ) {
      scrollRef.current.scrollLeft += 4000
    }
  }, [selectedItemMenu])

  return (
    <>
      <S.Wrapper ref={scrollRef}>
        {menuItems.map((item, index) => (
          <S.MenuItem
            key={index}
            onClick={() => handleSelected(item.key)}
            selected={selectedItemMenu === item.key ?? false}
          >
            {item.title}
          </S.MenuItem>
        ))}
      </S.Wrapper>
    </>
  )
}
