import React from 'react'
import { Property } from 'csstype'

import theme from 'presentation/styles/theme'
import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import ActualPage from 'presentation/components/ActualPage'
import { ProgressBar } from 'presentation/components/ProgressBar'

import * as S from './styles'

export type ContainerTypes = {
  hasHeader?: boolean
  noHeader?: boolean
  noPadding?: boolean
  form?: boolean
  onSubmit?: () => void
}

export type ContainerProps = {
  children: React.ReactNode
  title?: string | React.ReactNode
  titleColor?: keyof typeof theme.ds.colors
  titleSize?: keyof typeof theme.ds.typography.size
  titleWeight?: keyof typeof theme.ds.typography.weight
  subtitle?: string | React.ReactNode
  subtitleColor?: keyof typeof theme.ds.colors
  subtitleSize?: keyof typeof theme.ds.typography.size
  subtitleWeight?: keyof typeof theme.ds.typography.weight
  primaryButton?: React.ReactNode
  secondaryButton?: React.ReactNode
  headingButton?: React.ReactNode
  justifyContent?: Property.JustifyContent
  alignItems?: Property.AlignItems
  style?: React.CSSProperties
  actualPageText?: string
  actualPageOnClick?: () => void
  progressBar?: {
    totalSteps: number
    activeStep: number
  }
  gap?: string
} & ContainerTypes

export const ContainerNew = ({
  children,
  style,
  noPadding,
  form,
  onSubmit,
  noHeader,
  actualPageText,
  actualPageOnClick,
  headingButton,
  title,
  titleColor = 'neutral900',
  titleSize = 'medium',
  titleWeight = 'semiBold',
  subtitle,
  subtitleColor = 'neutral500',
  subtitleSize = 'small',
  subtitleWeight = 'normal',
  justifyContent,
  alignItems,
  progressBar,
  primaryButton,
  secondaryButton,
  gap = '0px'
}: ContainerProps) => (
  <S.Wrapper
    style={style}
    noPadding={noPadding}
    as={form ? 'form' : 'div'}
    onSubmit={onSubmit}
  >
    <S.ContainerStyle noPadding={noPadding}>
      {noHeader ? undefined : (
        <S.Header style={{ padding: noPadding ? '0' : '0px 24px' }}>
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            {actualPageText && (
              <ActualPage onClick={actualPageOnClick} text={actualPageText} />
            )}
            {headingButton && (
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  fontSize: '16px',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {headingButton}
              </div>
            )}
          </div>

          {title && (
            <HeadingNew
              as="h1"
              color={titleColor}
              weight={titleWeight}
              size={titleSize}
            >
              {title}
            </HeadingNew>
          )}
          {subtitle && (
            <SupportTextNew
              color={subtitleColor}
              size={subtitleSize}
              weight={subtitleWeight}
            >
              {subtitle}
            </SupportTextNew>
          )}
        </S.Header>
      )}
      <S.Content
        style={{
          justifyContent: justifyContent,
          alignItems: alignItems,
          padding: noPadding ? '0' : `${gap} 24px`,
          gap: gap
        }}
      >
        {children}
      </S.Content>
      {progressBar && (
        <ProgressBar
          stepAmount={progressBar.totalSteps}
          actualStep={progressBar.activeStep}
        />
      )}
      {(primaryButton || secondaryButton) && (
        <S.Buttons noPadding={noPadding}>
          {primaryButton}
          {secondaryButton}
        </S.Buttons>
      )}
    </S.ContainerStyle>
  </S.Wrapper>
)
