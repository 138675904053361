import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'
import { CancelEmergencyRoomAttendance } from 'domain/usecases/emergency-room/cancel-emergency-room-attendance'

export class RemoteCancelEmergencyRoomAttendance
  implements CancelEmergencyRoomAttendance
{
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  cancel(
    params: CancelEmergencyRoomAttendance.Params
  ): Promise<CancelEmergencyRoomAttendance.Model> {
    return this.emergencyRoomRepository.cancelEmergencyRoomAttendance(params)
  }
}

export type CancelEmergencyRoomAttendanceModel =
  CancelEmergencyRoomAttendance.Model
