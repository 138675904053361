import {
  ApiSurgicalScheduleClient,
  ApiRequest,
  ApiResponse
} from 'service/protocols/api-surgical-schedule/api-surgical-schedule-client'
import axios, { AxiosResponse, AxiosStatic } from 'axios'
import https from 'https'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import handleCustomError from 'repository/graphql/utils/handle-custom-error'
import isBase64 from 'presentation/utils/is-base-64'

const TIMEOUT_IN_MS = 10 * 60 * 1000 // 10 minutes

export class ApiSurgicalScheduleImplementation
  implements ApiSurgicalScheduleClient
{
  async request(data: ApiRequest): Promise<ApiResponse> {
    let axiosResponse: AxiosResponse
    let error = null
    try {
      axiosResponse = await axios
        .request({
          url: data.url,
          method: data.method,
          data: data.body,
          params: data.params,
          headers: data.headers,
          timeout: TIMEOUT_IN_MS,
          httpsAgent: new https.Agent({ rejectUnauthorized: false })
        })
        .then((response) => this.refreshToken(axios, response, data.query))

      if (axiosResponse?.data?.errors && !axiosResponse?.data?.data) {
        axiosResponse.status =
          axiosResponse?.data?.errors?.[0]?.statusCode || 500
        error = axiosResponse?.data?.errors?.[0]?.code
      }
    } catch (err: any) {
      axiosResponse = err.response
    }

    if (axiosResponse?.data) {
      const error = handleCustomError(axiosResponse?.data)
      if (error) {
        throw error
      }
    }

    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data,
      error: error
    }
  }

  async requestFile(data: ApiRequest): Promise<AxiosResponse> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await axios
        .request({
          url: data.url,
          method: data.method,
          data: data.body,
          headers: data.headers,
          timeout: 180000,
          responseType: 'blob'
        })
        .then((response) => this.refreshToken(axios, response, data.query))

      if (axiosResponse.data.errors) {
        axiosResponse.status = axiosResponse.data.errors[0].statusCode || 500
      }
    } catch (err: any) {
      axiosResponse = err.response
    }
    return axiosResponse
  }

  async refreshToken(
    axios: AxiosStatic,
    response: AxiosResponse<any>,
    queryName?: string
  ): Promise<AxiosResponse<any>> {
    const error = response.data?.errors?.[0]
    const localStorageAdapter = new LocalStorageAdapter()
    const account = localStorageAdapter.get('account')
    if (error?.statusCode === 401 && account?.accessToken) {
      const { accessToken } = account
      const uri =
        process.env.NODE_ENV === 'production'
          ? `/fetch/graphql`
          : `${process.env.REACT_APP_API_URL + '/graphql'}`

      return axios
        .post(uri, {
          query:
            'mutation refreshToken($accessToken: String!) {' +
            'refreshToken(accessToken: $accessToken)' +
            '}',
          variables: { accessToken }
        })
        .then((res) => {
          if (res?.data?.errors?.[0].statusCode) {
            this.killSession()
            return Promise.resolve(res)
          } else {
            localStorageAdapter.set('account', {
              ...account,
              accessToken: res.data.data.refreshToken
            })
            response.config.headers = {
              Authorization: 'Bearer ' + res.data.data.refreshToken
            }
            return axios({
              ...response.config,
              data: JSON.parse(response.config.data)
            })
          }
        })
    } else if (!account?.accessToken && queryName !== 'login') {
      this.killSession()
    }
    return response
  }

  private useSearchParams() {
    const params = window.location.search.replace('?', '')
    const decodedParams = isBase64(params) ? atob(params) : params

    return new URLSearchParams(decodedParams)
  }

  private generateQueryParams() {
    const pathname = window.location.pathname.replace('/', '').split('/')
    if (process.env.NODE_ENV === 'development') pathname.shift()
    const newPathname = `/${pathname.join('/')}`

    const searchParams = this.useSearchParams().toString()
    const query = searchParams
      ? '?' + btoa(`${searchParams}&pathname=${newPathname}`)
      : ''

    return query
  }

  killSession() {
    const queryParams = this.generateQueryParams()

    if (process.env.NODE_ENV === 'production') {
      ;(window.location.href as any) = `/login${queryParams}`
    } else if (process.env.NODE_ENV === 'development') {
      const client = window.location.pathname.split('/')[1]
      ;(window.location.href as any) = `/${client}/login${queryParams}`
    }
    const localStorage = new LocalStorageAdapter()
    localStorage.set('account')
  }
}
