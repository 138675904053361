import React from 'react'
import * as S from './styles'
import CardNew from '../CardNew'
import BadgeStatusNew from 'presentation/components/BadgeStatusNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import Divider from '../DividerNew'
import AvatarNew from 'presentation/components/AvatarNew'
import { ReactComponent as PendencyIcon } from 'presentation/assets/icons/pendecy-icon.svg'
import moment from 'moment-timezone'
type PendencyCardProps = {
  onClick?: (surgical_order_id: number) => void
  pendency: any
}

export const PendencyCard = ({ pendency, onClick }: PendencyCardProps) => {
  const formatedDate = moment(pendency.created_at)
    .utc()
    .format('DD/MM/YYYY HH:mm')

  return (
    <CardNew
      style={{ marginTop: '16px' }}
      onClick={onClick ? () => onClick(pendency.surgical_order_id) : undefined}
    >
      <S.CardHeader>
        <SupportTextNew weight="normal" color="neutral600" size="small">
          Nº {pendency?.surgical_order_id || 0}
        </SupportTextNew>

        <BadgeStatusNew status={pendency.status as any} />
      </S.CardHeader>

      <Divider />

      <S.Container>
        <div>
          <SupportTextNew weight="semiBold" color="neutral700" size="xsmall">
            Paciente
          </SupportTextNew>
          <SupportTextNew
            capitalize
            weight="semiBold"
            size="small"
            color="black"
          >
            {pendency?.patient?.name}
          </SupportTextNew>
        </div>
        <div>
          <SupportTextNew weight="semiBold" color="neutral700" size="xsmall">
            Procedimento
          </SupportTextNew>
          <SupportTextNew
            capitalize
            weight="semiBold"
            size="small"
            color="neutral700"
          >
            {pendency?.procedures
              ?.map((procedure: any) => procedure.description)
              .join(' | ')}
          </SupportTextNew>
        </div>
        <div>
          <SupportTextNew weight="semiBold" color="neutral700" size="xsmall">
            Médico
          </SupportTextNew>
          <SupportTextNew
            capitalize
            weight="semiBold"
            size="small"
            color="black"
          >
            {pendency?.doctor?.name}
          </SupportTextNew>
        </div>
        <div>
          <SupportTextNew weight="semiBold" color="terciary600" size="xxsmall">
            Enviado por
          </SupportTextNew>
          <SupportTextNew size="xxsmall" color="black">
            {pendency?.created_by} em {formatedDate}
          </SupportTextNew>
        </div>

        {pendency.help_card.count > 0 || pendency.pendency.count > 0 ? (
          <S.PendencyStatusSection>
            {pendency.pendency.count > 0 && (
              <S.PendencyStatusHeader>
                <PendencyIcon />
                <SupportTextNew
                  color="neutral700"
                  weight="bold"
                  className="capitalize-fl"
                >
                  Pendência
                </SupportTextNew>
                <div style={{ flex: '1' }}></div>
                <AvatarNew
                  color={'terciary700'}
                  text={pendency?.pendency.count.toString()}
                  active
                  size={'xmedium'}
                />
              </S.PendencyStatusHeader>
            )}
            {pendency.pendency.count > 0 && pendency.help_card.count > 0 && (
              <Divider />
            )}
            {pendency.help_card.count > 0 && (
              <S.PendencyStatusHeader>
                <PendencyIcon />
                <SupportTextNew
                  color="neutral700"
                  weight="bold"
                  className="capitalize-fl"
                >
                  Preciso de ajuda
                </SupportTextNew>
                <div style={{ flex: '1' }}></div>
                <AvatarNew
                  color={'terciary700'}
                  text={pendency?.help_card.count.toString()}
                  active
                  size={'xmedium'}
                />
              </S.PendencyStatusHeader>
            )}
          </S.PendencyStatusSection>
        ) : null}
      </S.Container>
    </CardNew>
  )
}
