import React from 'react'
import * as S from './styles'
import Close from 'presentation/assets/icons/close.svg'
import ButtonNew from 'presentation/components/ButtonNew'

type Props = {
  title: string
  isOpen: any
  toggleIsOpen: (isOpen: boolean) => void
  primaryButtonText?: string
  primaryButtonOnClick?: () => void
  secondaryButtonText?: string
  secondaryButtonOnClick?: () => void
  tertiaryButtonText?: string
  teriaryButtonOnClick?: () => void
  children: any
}

export default function MenuActionDrawer({
  title,
  isOpen,
  toggleIsOpen,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  tertiaryButtonText,
  teriaryButtonOnClick,
  children
}: Props) {
  return (
    <S.MenuDrawer
      anchor={'bottom'}
      open={Boolean(isOpen)}
      onClose={() => toggleIsOpen(false)}
    >
      <S.DrawerHeader>
        {title}
        <S.CloseIcon src={Close} onClick={() => toggleIsOpen(false)} />
      </S.DrawerHeader>
      <S.Divider />
      <S.DrawerBody>{children}</S.DrawerBody>
      <S.DrawerFooter>
        {primaryButtonText && primaryButtonOnClick && (
          <ButtonNew onClick={primaryButtonOnClick}>
            {primaryButtonText}
          </ButtonNew>
        )}
        {secondaryButtonText && secondaryButtonOnClick && (
          <ButtonNew variant="text" onClick={secondaryButtonOnClick}>
            {secondaryButtonText}
          </ButtonNew>
        )}
        {tertiaryButtonText && teriaryButtonOnClick && (
          <ButtonNew variant="text" onClick={teriaryButtonOnClick}>
            {tertiaryButtonText}
          </ButtonNew>
        )}
      </S.DrawerFooter>
    </S.MenuDrawer>
  )
}
