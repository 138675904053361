import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import MultimediaFileInput from 'presentation/shared/components/MultimediaFileInput'
import {
  HealthInsurancePlans,
  SubPlan
} from 'domain/usecases/health-insurance/load-health-insurance-plans'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import TextField from 'presentation/components/TextField'
import * as yup from 'yup'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import theme from 'presentation/styles/theme'
import { toast } from 'react-toastify'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientDocument } from 'common/enum/patient-document'
import AutoComplete from 'presentation/components/AutoComplete'
import { Plans } from 'domain/usecases/health-insurance/load-all-health-insurance'
import { Patient } from 'domain/entities/patient-model'

type AddHealthInsuranceProps = {
  onClose: () => void
  open: boolean
  onConfirm: () => void
  initialValues?: InsuranceFormValues
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  uploadDocument?: UploadPatientDocument
  nextStep: (
    card: string,
    healthPlanCode: string,
    health_insurance_id: number
  ) => void
  health_insurance_id?: number
  healthInsurances: HealthInsurancePlans[]
  patient: Patient
  setAddInsurance: (card?: string, healthPlanCode?: number) => void
}

export type InsuranceFormValues = {
  code: number
  description: string
  ansRegister: string
  planName: string
  planCode: number
  subplanName: string
  subplanCode: number
  company: string
  healthCard: string
  frontCard?: File
  backCard?: File
}

export function AddHealthInsurance({
  onClose,
  open,
  onConfirm,
  initialValues = {} as InsuranceFormValues,
  updateHealthInsuranceData,
  uploadDocument,
  health_insurance_id,
  healthInsurances,
  patient,
  setAddInsurance
}: AddHealthInsuranceProps) {
  const [plans, setPlans] = useState([] as Plans[])
  const [filteredPlans, setFilteredPlans] = useState([] as Plans[])
  const [subplans, setSubplans] = useState([] as SubPlan[])
  const [filteredSubplans, setFilteredSubplans] = useState([] as SubPlan[])
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false)
  const [insurances, setInsurances] = useState([] as HealthInsurancePlans[])
  const [insuranceSearchQuery, setInsuranceSearchQuery] = useState<string>()
  const [planSearchQuery, setPlanSearchQuery] = useState<string>()
  const [subplanSearchQuery, setSubplanSearchQuery] = useState<string>()
  const [healthCardQuery, setHealthCardQuery] = useState<string>()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  const { isValid, values, errors, submitForm, setFieldValue, handleChange } =
    useFormik<InsuranceFormValues>({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: async (values) => {
        setAddInsurance(values.healthCard, values.planCode)

        try {
          await updateHealthInsuranceData.update({
            healthInsuranceCode: +values.code,
            healthInsuranceName: values.description,
            healthPlanCode: +values.planCode,
            healthPlanName: values.planName,
            company: values.company,
            health_insurance_id: health_insurance_id,
            healthCard: values.healthCard,
            healthSubPlanCode: values.subplanCode,
            healthSubPlanName: values.subplanName
          })

          if (values.frontCard) {
            const formFront = new FormData()
            formFront.append('file', values.frontCard)
            formFront.append('type', PatientDocument.HEALTH_CARD_FRONT)
            await uploadDocument?.upload({
              file: values.frontCard,
              form: formFront,
              type: PatientDocument.HEALTH_CARD_FRONT
            })
          }
          if (values.backCard) {
            const formBack = new FormData()
            formBack.append('file', values.backCard)
            formBack.append('type', PatientDocument.HEALTH_CARD_BACK)
            await uploadDocument?.upload({
              file: values.backCard,
              form: formBack,
              type: PatientDocument.HEALTH_CARD_BACK
            })
          }
          setSuccessModalOpen(true)
        } catch (error: any) {
          toast.error(error)
        }
      }
    })

  async function handleLoadPlans(insuranceCode: string, planCode?: number) {
    const selectedHealthInsurance = healthInsurances.find(
      (insurance) => insurance.code === +insuranceCode
    )
    if (selectedHealthInsurance?.plans) {
      setPlans(selectedHealthInsurance?.plans)
      setFilteredPlans(selectedHealthInsurance?.plans)

      const selectedSubplans = selectedHealthInsurance.plans.find(
        (plan) => plan.code === planCode
      )?.subPlan

      if (selectedSubplans) {
        setSubplans(selectedSubplans)
        setFilteredSubplans(selectedSubplans)
      }
    }
  }

  const handleInsuranceChange = (value: string) => {
    setIsSearching(true)
    const filteredInsurances = healthInsurances.filter((insurance) =>
      insurance.description.toLowerCase().includes(value.toLowerCase())
    )
    setInsurances(filteredInsurances)
    setInsuranceSearchQuery(value)
    setFieldValue('description', value)
    if (value) {
      setFieldValue('planName', undefined)
    }
  }

  const handleHealthInsurance = (value: string) => {
    setFieldValue('code', value)
    setFieldValue(
      'description',
      healthInsurances.find((insurance) => insurance.code === +value)
        ?.description
    )
    setFieldValue('planCode', 0)
    setPlanSearchQuery('')
    setSubplanSearchQuery('')
    setFieldValue('subplan', '')
    handleLoadPlans(value)
    setInsuranceSearchQuery(
      healthInsurances.find((insurance) => insurance.code === +value)
        ?.description
    )
    setIsSearching(false)
  }

  const handlePlanChange = (value: string) => {
    setIsSearching(true)

    const filteredPlans = plans.filter((plan) =>
      plan.description.toLowerCase().includes(value.toLowerCase())
    )

    filteredPlans.length > 0
      ? setFilteredPlans(filteredPlans)
      : setFilteredPlans([])

    setFieldValue('planName', value)

    if (value) {
      setFieldValue('subplanName', undefined)
    }
    setPlanSearchQuery(value)
  }

  const handlePlans = (value: string) => {
    setFieldValue('planCode', +value)
    const selectedPlan = plans.find((plan) => plan.code === +value)
    if (selectedPlan?.subPlan) {
      setSubplans(selectedPlan?.subPlan)
    }
    setFieldValue('planName', selectedPlan?.description)
    setPlanSearchQuery(selectedPlan?.description)
    setSubplanSearchQuery('')
    setIsSearching(false)
  }

  const handleSubplanChange = (value: string) => {
    setIsSearching(true)

    const selectedSubplans = subplans.filter((subplan) =>
      subplan.description.toLowerCase().includes(value.toLowerCase())
    )
    selectedSubplans.length > 0
      ? setFilteredSubplans(selectedSubplans)
      : setFilteredSubplans([])

    setSubplanSearchQuery(value)
    if (!value) {
      setIsSearching(false)
    }
  }

  const handleSubplans = (value: string) => {
    setFieldValue('subplanCode', +value)
    const selectedSubplan = subplans.find((subplan) => subplan.code === +value)
    setFieldValue('subplanName', selectedSubplan?.description)
    setSubplanSearchQuery(selectedSubplan?.description)

    setIsSearching(false)
  }

  const handleHealthCard = (value: string) => {
    setFieldValue('healthCard', value)
    setHealthCardQuery(value)
  }

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: 'frontCard' | 'backCard'
  ) => {
    setFieldValue(name, e.target.files![0])
  }

  const handleHealthCardSuggestions = () => {
    if (healthCardQuery) {
      if (
        patient.healthInsurance &&
        patient.healthInsurance.healthCard.indexOf(healthCardQuery) == 0
      ) {
        return [
          {
            label: patient.healthInsurance?.healthCard,
            value: patient.healthInsurance?.healthCard
          }
        ]
      }
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <SheetModal
      size={windowDimensions.width < 550 ? 650 : 550}
      isOpen={open}
      onClose={onClose}
      backdrop="false"
    >
      <div style={{ marginBottom: '20px' }}>
        <Heading size="large">Adicionar convênio</Heading>
      </div>
      <Container
        noPadding
        primaryButton={
          <Button
            fullWidth
            style={{ marginTop: '20px' }}
            onClick={() => {
              submitForm()
            }}
            disabled={!isSearching ? !isValid : isSearching}
          >
            Confirmar
          </Button>
        }
      >
        <div style={{ textAlign: 'start' }}>
          <AutoComplete
            label="Convênio"
            bgColor="mainBg"
            suggestions={insurances.map((insurance) => ({
              label: insurance.description,
              value: insurance.code
            }))}
            name="health-insurance"
            value={insuranceSearchQuery}
            onSuggestionClick={(value) => handleHealthInsurance(value)}
            onType={(value) => handleInsuranceChange(value)}
            onInputChange={(value) => handleInsuranceChange(value)}
            required
            data-testid="health-insurance-autocomplete"
            error={errors.description}
          />
          <AutoComplete
            label="Nº da carteirinha"
            bgColor="mainBg"
            suggestions={handleHealthCardSuggestions()}
            name="health-card"
            value={healthCardQuery}
            onSuggestionClick={(value) => handleHealthCard(value)}
            onInputChange={(value) => handleHealthCard(value)}
            required
            data-testid="health-insurance-autocomplete"
            error={errors.healthCard}
            style={{ marginTop: 20 }}
            maxLength={30}
            type="number"
          />
          <AutoComplete
            label="Plano"
            bgColor="mainBg"
            suggestions={filteredPlans.map((plan) => ({
              label: plan.description,
              value: plan.code
            }))}
            name="health-plan"
            value={planSearchQuery}
            onSuggestionClick={(value) => handlePlans(value)}
            onType={(value) => handlePlanChange(value)}
            onInputChange={(value) => handlePlanChange(value)}
            required
            data-testid="health-plan-autocomplete"
            error={errors.planName}
            style={{ marginTop: 20 }}
          />
          <AutoComplete
            label="Subplano"
            bgColor="mainBg"
            suggestions={filteredSubplans.map((subplan) => ({
              label: subplan.description,
              value: subplan.code
            }))}
            name="health-subplan"
            value={subplanSearchQuery}
            onSuggestionClick={(value) => handleSubplans(value)}
            onType={(value) => handleSubplanChange(value)}
            onInputChange={(value) => handleSubplanChange(value)}
            data-testid="health-subplan-autocomplete"
            error={errors.subplanName}
            style={{ marginTop: 20 }}
          />
          <TextField
            bgColor="mainBg"
            label="Empresa"
            defaultValue={values.company}
            style={{ marginTop: 20 }}
            value={values.company || ''}
            onChange={handleChange('company')}
            required
            error={errors.company}
            maxLength={40}
          />
          <div style={{ marginTop: '10px' }}>
            <SupportText style={{ color: theme.colors.primary, marginTop: 20 }}>
              Anexe abaixo as imagens da sua carteirinha do convênio
            </SupportText>
            <MultimediaFileInput
              name="frontCard"
              id="frontCard"
              text="Frente"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFileChange(event, 'frontCard')
              }}
              maxFileSizeMB={6}
            />
            <MultimediaFileInput
              name="backCard"
              id="backCard"
              text="Verso"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFileChange(event, 'backCard')
              }}
              maxFileSizeMB={6}
            />
          </div>
        </div>
      </Container>
      <SheetModal
        style={{ padding: 0 }}
        size={300}
        isOpen={successModalOpen}
        onClose={onClose}
        backdrop="true"
      >
        <Heading size="large">
          Convênio adicionado com
          <p style={{ color: theme.colors.primaryDarker }}>sucesso</p>
        </Heading>
        <Button
          color={theme.colors.primaryDarker}
          style={{ marginTop: '20px' }}
          onClick={() => {
            setSuccessModalOpen(false)
            onConfirm()
          }}
        >
          Confirmar
        </Button>
      </SheetModal>
    </SheetModal>
  )
}

const validationSchema = yup.object().shape({
  code: yup.number().optional(),
  description: yup.string().required(),
  ansRegister: yup.string().optional(),
  planName: yup.string().required(),
  planCode: yup.number().optional(),
  subplan: yup.string().optional(),
  company: yup.string().required(),
  healthCard: yup.string().required()
})
