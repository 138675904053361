import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetSchedules } from 'domain/usecases/scheduling/get-schedules'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { GetSchedulesByMonth } from 'domain/usecases/scheduling/get-schedules-by-month'
import { ListSchedules } from 'domain/usecases/scheduling/list-schedules'

export const searchFiltersSchedulesQuery = (
  params: SearchFiltersSchedules.Params
) => ({
  query: `
    query Search($query: String!, $type: SearchTypeEnum!, $term: Int, $patient: PatientData!, $healthInsurance: HealthInsuranceData!) {
      search(data: {
        query: $query, type: $type, term: $term, patient: $patient, healthInsurance: $healthInsurance
      }) {${params?.fields.join(',')}}
    }
  `,
  name: 'search'
})

export const getSchedulesQuery = (params: GetSchedules.Params) => ({
  query: `
    query GetSchedules(
      $patient: PatientData,
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $units: [UnitData!]!
      ) {
      getSchedules(
        data: {
          patient: $patient,
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          doctor: $doctor,
          units: $units
        }
      ) {${params?.fields.join(',')}}
    }
  `,
  name: 'getSchedules'
})

export const getSchedulesByMonthQuery = (
  params: GetSchedulesByMonth.Params
) => ({
  query: `
    query GetSchedulesByMonth(
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $patient: PatientData,
      $units: [UnitData!]!,
      $schedule_id: Int!,
      $month: String!
      ) {
      getSchedulesByMonth(
        data: {
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          doctor: $doctor,
          patient: $patient,
          units: $units,
          schedule_id: $schedule_id,
          month: $month
        }
      ) {${params?.fields.join(',')}}
    }
  `,
  name: 'getSchedulesByMonth'
})

export const getSchedulesHoursQuery = (params: GetHoursByDay.Params) => ({
  query: `
    query GetSchedulesHours(
      $healthInsurance: HealthInsuranceData!,
      $specialty: SpecialtyData,
      $doctor: DoctorData,
      $patient: PatientData
      $units: [UnitData!]!,
      $schedule_id: Int!,
      $date: String,
    ) {
      getSchedulesHours(
        data: {
          doctor: $doctor,
          healthInsurance: $healthInsurance,
          specialty: $specialty,
          patient: $patient,
          units: $units,
          schedule_id: $schedule_id,
          date: $date
        }
      ) {${params?.fields.join(',')}}
    }
  `,
  name: 'getSchedulesHours'
})

export const getReasonCancelQuery = () => ({
  query: `
    query {
      getReasonCancel {
        reason_cancel_id,
        name
      }
    }
  `,
  name: 'getReasonCancel'
})

export const getPatientSchedulesQuery = (params?: ListSchedules.Params) => ({
  query: `
    query ListSchedules($patient_id: Float!, $interval: IntervalArgs!) {
      listSchedules(patient_id: $patient_id, interval: $interval) {
        ${params?.join(',')}
      }
    }
  `,
  name: 'getPatientSchedules'
})
