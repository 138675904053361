import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import theme from 'presentation/styles/theme'
import SupportCard from './SupportCard'
import { handleUnityPhone } from 'common/utils/handleUnityPhone'
import React from 'react'

type ConfirmedAttendanceModalProps = {
  onClose: () => void
  onConfirm: () => void
  open: boolean
  hospital_id?: number
}

export function ConfirmedAttendanceModal({
  onClose,
  open,
  onConfirm,
  hospital_id
}: ConfirmedAttendanceModalProps) {
  return (
    <SheetModal size={450} isOpen={open} onClose={onClose}>
      <Heading size="large">
        Atendimento autorizado com <strong>sucesso</strong>
      </Heading>
      <SupportText>
        Prezado(a) cliente, o seu
        <p style={{ fontWeight: theme.font.bold }}>
          atendimento foi autorizado
        </p>
        pela sua Operadora de saúde.
      </SupportText>
      <SupportCard>
        Esses dados podem ter sofrido alguma alteração, gentileza verificar com
        nossa equipe de suporte através do telefone
        <strong style={{ fontWeight: theme.font.light }}>
          {' '}
          {handleUnityPhone(hospital_id)}
        </strong>
      </SupportCard>
      <Button fullWidth style={{ marginTop: '20px' }} onClick={onConfirm}>
        Confirmar
      </Button>
    </SheetModal>
  )
}
