import { LoadAllDoctorRegisters } from 'domain/usecases/doctor/load-all-doctor-registers'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadAllDoctorRegisters implements LoadAllDoctorRegisters {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(
    params: LoadAllDoctorRegisters.Params
  ): Promise<LoadAllDoctorRegisters.Model> {
    return this.doctorRepository.loadAllDoctorRegisters(params)
  }
}
