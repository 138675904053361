import React, { HTMLAttributes } from 'react'

import theme from 'presentation/styles/theme'
import * as S from './styles'

export type SupportTextProps = {
  color?: keyof typeof theme.ds.colors
  weight?: keyof typeof theme.ds.typography.weight
  lineHeight?: keyof typeof theme.ds.typography.lineHeight
  size?: keyof typeof theme.ds.typography.size
  capitalize?: boolean
  as?: React.ElementType
  textAlign?: 'center' | 'left' | 'right' | 'justify'
} & HTMLAttributes<HTMLParagraphElement>

const SupportTextNew = ({
  color = 'neutral500',
  weight = 'normal',
  lineHeight,
  size = 'small',
  capitalize = false,
  children,
  as = 'p',
  textAlign = 'left',
  ...props
}: SupportTextProps) => (
  <S.Wrapper
    color={color}
    weight={weight}
    lineHeight={lineHeight}
    size={size}
    capitalize={capitalize}
    as={as}
    textAlign={textAlign}
    {...props}
  >
    {children}
  </S.Wrapper>
)

export default SupportTextNew
