import React from 'react'
import * as S from './styles'
import { useHistory, useLocation } from 'react-router'
import Header from 'presentation/components/Header'
import ActualPage from 'presentation/components/ActualPage'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import {
  Package,
  Vaccine
} from 'presentation/utils/newborn-packages-informations'
import Button from 'presentation/components/Button'
import { openPhoneApp } from 'presentation/utils/mobile-actions'
import { ReactComponent as ShoppingCart } from 'presentation/assets/icons/outlined-shopping-cart.svg'

export default function NewbornPackagesDetailsLayout() {
  const history = useHistory()

  const handleRedirect = () =>
    openPhoneApp(
      'whatsApp',
      '5531971507542',
      'Olá! Gostaria de saber mais sobre os pacotes de vacinas do Mater Dei Vacinas'
    )

  const { state } = useLocation<{
    packageInformation: Package
  }>()

  const { packageInformation } = state

  const handleClickOnVaccineCard = (vaccine: Vaccine) => {
    history.push('/detalhes-da-vacina', {
      vaccineInformation: vaccine
    })
  }

  return (
    <S.Wrapper>
      <Header />
      <Container>
        <ActualPage text="Voltar" onClick={() => history.goBack()} />
        <S.TitleContainer>
          <Heading color={'primary'}>{packageInformation.packageName}</Heading>
        </S.TitleContainer>

        <S.CardsContainer>
          {packageInformation.vaccines.map((vaccine, index) => (
            <S.Card
              key={index}
              onClick={() => handleClickOnVaccineCard(vaccine)}
            >
              <p>{vaccine.vaccineName}</p>
            </S.Card>
          ))}
        </S.CardsContainer>

        <Button
          fullWidth={true}
          onClick={handleRedirect}
          style={{ marginBottom: 20 }}
          role={'get-packages'}
        >
          <ShoppingCart style={{ marginRight: 5 }} />
          Contratar pacote
        </Button>
      </Container>
    </S.Wrapper>
  )
}
