// import { AxiosResponse } from 'axios'
import {
  ApiTracksaleClient,
  ApiRequest,
  ApiResponse,
  ApiMethod
} from 'service/protocols/api-tracksale/api-tracksale-client'
import {
  IApiTracksaleRepository,
  RepositoryRequest
} from 'service/protocols/api-tracksale/api-tracksale-repository'

export class ApiTracksaleRepository<Model = any>
  implements IApiTracksaleRepository<Model>
{
  constructor(private readonly apiTracksaleClient: ApiTracksaleClient<Model>) {}

  // async get(data: RepositoryRequest): Promise<ApiResponse<Model>> {
  //   return await this.apiTracksaleClient.request({
  //     ...data,
  //     method: ApiMethod.GET
  //   } as ApiRequest)
  // }

  async post(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    const request = await this.apiTracksaleClient.request({
      ...data,
      method: ApiMethod.POST
    } as ApiRequest)

    if (request.body && data.query) {
      request.body = (request.body as any).data
        ? (request.body as any).data[data.query]
        : request.body
    }

    return request
  }

  // async put(data: RepositoryRequest): Promise<ApiResponse<Model>> {
  //   return await this.apiTracksaleClient.request({
  //     ...data,
  //     method: ApiMethod.PUT
  //   } as ApiRequest)
  // }

  // delete(data: RepositoryRequest): Promise<ApiResponse<Model>> {
  //   return this.apiTracksaleClient.request({
  //     ...data,
  //     method: ApiMethod.DELETE
  //   } as ApiRequest)
  // }
}
