import React, { useContext, useState } from 'react'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import ButtonNew from 'presentation/components/ButtonNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import DocumentsModal, { DocumentsList } from '../DocumentsModal'
import DocumentSuccessModal from '../DocumentSuccessModal'
import MessageCard from 'presentation/components/MessageCard'
import CardNew from 'presentation/shared/components/CardNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import StatusTag from 'presentation/components/StatusTag'
import { SurgicalOrderContext } from 'main/factories/pages/surgical-order/surgical-order-factory'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'
import DocumentAccordion from 'presentation/components/DocumentAccordeon'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { PendencyTypes } from 'presentation/components/PendencyCard'

type Props = {
  getPendencyHistory: (
    surgical_pendency_id: number,
    pendencyType?: PendencyTypes
  ) => void
  onClose: (value?: boolean) => void
  hidePendency: (update?: boolean) => void
  pendencyOrderId?: number
  isOpen: boolean
} & WithLoadingProps

const PendencyModal = ({
  isOpen,
  onClose,
  setIsLoading,
  getPendencyHistory,
  pendencyOrderId,
  hidePendency
}: Props) => {
  const {
    state: { currentPendency }
  } = useContext<any>(SurgicalOrderContext)
  const [documentModalIsOpen, setDocumentModalIsOpen] = useState(false)
  const [isHistoryExpanded, setIsHitoryExpanded] = useState(false)
  const [expandedDocument, setExpandedDocument] = useState('')
  const [documentSuccessModalIsOpen, setDocumentSuccessModalIsOpen] =
    useState(false)
  const surgicalPendencyService = useServices().surgicalPendency

  const { dispatch } = useContext<any>(SurgicalOrderContext)

  const getPendenciesList = async () => {
    try {
      const response: any =
        await surgicalPendencyService.loadSurgicalOrderPendencies(
          currentPendency.surgical_order_id
        )

      dispatch({
        type: 'SURGICAL_PENDENCIES',
        payload: response.data
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar as pendencias')
    }
  }

  const answerSurgicalPendency = async (values: { answer: string }) => {
    setIsLoading(true)
    try {
      if (currentPendency.type === PendencyTypes.HELP_CARD) {
        setIsLoading(true)
        await surgicalPendencyService.createHelpPendencyMessage({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          message: formik.values.answer
        })

        await getPendenciesList()
      } else {
        setIsLoading(true)
        await surgicalPendencyService.answerSurgicalPendency({
          answer: values.answer,
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          group_id: generateHash()
        })
        await getPendencyHistory(currentPendency.surgical_pendency_id)
      }

      formik.setFieldValue('answer', '')
      hidePendency(true)
    } catch {
      toast.error('Ocorreu um erro ao responder a pendencia')
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      answer: ''
    },
    validationSchema: validationSchema,
    onSubmit: answerSurgicalPendency
  })

  const displayedHistoric = () => {
    return !isHistoryExpanded && currentPendency.historic?.length > 3
      ? [...currentPendency.historic.slice(0, 3)]
      : [...currentPendency.historic]
  }

  const uploadSurgicalPendencyDocument = async (files: any, type: string) => {
    setIsLoading(true)
    try {
      const file = files[0]
      const form = new FormData()
      const newFile = renameFile(file, file.name)
      form.append('file', newFile)
      form.append('type', type)
      form.append('group_id', generateHash())
      form.append(
        'surgical_pendency_id',
        String(currentPendency.surgical_pendency_id)
      )

      await surgicalPendencyService.uploadSurgicalPendencyDocument({
        type: newFile.type,
        form: form,
        file: newFile
      })
      await getPendencyHistory(currentPendency.surgical_pendency_id)
      setDocumentSuccessModalIsOpen(true)
    } catch {
      toast.error('Ocorreu um erro ao envia o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async (document_id: number, group_id: string) => {
    setIsLoading(true)
    try {
      await surgicalPendencyService.removeDocumentFromSurgicalPendency({
        surgical_pendency_id: currentPendency.surgical_pendency_id,
        document_id,
        group_id
      })
      await getPendencyHistory(currentPendency.surgical_pendency_id)
    } catch {
      toast.error('Ocorreu um erro ao excluir o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const downloadDocument = async (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => {
    setIsLoading(true)
    try {
      const document =
        await surgicalPendencyService.loadSurgicalPendencyDocument({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          document_id,
          group_id
        })

      if (document) {
        downloadFileFromBlob(
          document.data,
          document.contentType,
          DocumentsList[type]
        )
      }
    } catch {
      toast.error('Ocorreu um erro ao baixar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const AnswerPendency = () => {
    return (
      <ButtonNew
        size="large"
        disabled={
          !formik.isValid || !formik.values.answer || formik.isSubmitting
        }
        onClick={formik.submitForm}
        fullWidth
      >
        Responder
      </ButtonNew>
    )
  }

  const isResolvedPendency = () => {
    return currentPendency.status !== 'RESOLVED'
  }

  const changeExpandedAccordeon = (
    accordeonLabel: string,
    isExpanded: boolean
  ) => {
    setExpandedDocument(isExpanded ? accordeonLabel : '')
  }

  const title =
    currentPendency?.type === PendencyTypes.HELP_CARD
      ? `Preciso de ajuda ${('000' + pendencyOrderId).slice(-3)}`
      : `Pendência ${('000' + pendencyOrderId).slice(-3)}`

  return (
    <>
      <SheetModalNew
        isDraggable
        isOpen={isOpen}
        onClose={() => onClose()}
        title={title}
        gap="16px"
        primaryButton={isResolvedPendency() && <AnswerPendency />}
        style={{ maxHeight: '98vh' }}
        subHeader={
          <StatusTag status={currentPendency.status} type="pendency" />
        }
      >
        <CardNew border="primary100" shadow="none" padding="0px">
          {displayedHistoric().map(
            (
              { message, createdAt, responsible, profile, description }: any,
              index: number
            ) => {
              return (
                <React.Fragment key={index}>
                  <MessageCard
                    key={index}
                    message={message}
                    createdAt={createdAt}
                    responsible={responsible}
                    profile={profile}
                    description={description}
                  />
                  {(index !== displayedHistoric()?.length - 1 ||
                    currentPendency.historic?.length > 3) && (
                    <DividerNew
                      marginBottom="4px"
                      marginTop="4px"
                      leak="-8px"
                      color="neutral100"
                    />
                  )}
                </React.Fragment>
              )
            }
          )}
          {currentPendency.historic?.length > 3 && (
            <ButtonNew
              variant="text"
              onClick={() => setIsHitoryExpanded(!isHistoryExpanded)}
              style={{ justifyContent: 'flex-start', padding: '24px 16px' }}
            >
              {isHistoryExpanded ? 'Ver menos' : 'Ver mais'}
            </ButtonNew>
          )}
        </CardNew>

        {currentPendency?.type !== PendencyTypes.HELP_CARD && (
          <>
            <DividerNew marginBottom="0px" marginTop="0px" />
            {isResolvedPendency() && (
              <ButtonNew
                size="large"
                outlined
                onClick={() => setDocumentModalIsOpen(true)}
              >
                Incluir documento
              </ButtonNew>
            )}
          </>
        )}

        {currentPendency?.documents?.length > 0 && (
          <div>
            {currentPendency?.documents.map((document: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <DocumentAccordion
                    key={document.key}
                    document={document}
                    padding="0"
                    hideHeaderDivider
                    handleChange={changeExpandedAccordeon}
                    expandedDocument={expandedDocument}
                    onDeleteFile={deleteDocument}
                    onDownloadFile={downloadDocument}
                    onUploadFile={(files: any) =>
                      uploadSurgicalPendencyDocument(files, document.type)
                    }
                  />
                  {currentPendency?.documents?.length - 1 !== index && (
                    <DividerNew marginBottom="8px" marginTop="8px" />
                  )}
                </React.Fragment>
              )
            })}
          </div>
        )}

        {isResolvedPendency() && (
          <>
            <DividerNew marginTop="0px" marginBottom="0px" />
            <div style={{ position: 'relative', marginBottom: '16px' }}>
              <TextAreaNew
                label="Adicionar comentário"
                placeholder="Adicionar um comentário para a CRMO"
                value={formik.values.answer}
                onInputChange={formik.handleChange('answer')}
                rows={3}
              />
              <SupportTextNew
                size="xsmall"
                color={formik.isValid ? 'neutral400' : 'danger300'}
                style={{ position: 'absolute', right: '8px', bottom: '-24px' }}
              >
                {formik.values.answer?.length}/800
              </SupportTextNew>
            </div>
          </>
        )}
      </SheetModalNew>

      <DocumentsModal
        isOpen={documentModalIsOpen}
        onClose={() => setDocumentModalIsOpen(false)}
        onUploadFile={uploadSurgicalPendencyDocument}
        documents={currentPendency?.documents}
        onDownloadFile={downloadDocument}
        onDeleteFile={deleteDocument}
      />

      <DocumentSuccessModal
        isOpen={documentSuccessModalIsOpen}
        onClose={() => setDocumentSuccessModalIsOpen(false)}
      />
    </>
  )
}

export default WithLoading(PendencyModal)

const validationSchema = yup.object().shape({
  answer: yup.string().required().max(800, 'Limite de caracteres excedido')
})
