import styled from 'styled-components'

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
