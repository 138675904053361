import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { AuthenticationRepository } from 'repository/repositories/authentication/authentication-repository'
import { ApiRepository } from 'repository/api-repository'
import PatientLogin from 'presentation/pages/Login'
import { RemoteAuthentication } from 'service/usecases/authentication/remote-authentication'
import { RemoteVerifyLoginClient } from 'service/usecases/verify-login-client/remote-verify-login-client'
import { Profile } from 'common/enum/profile'

export default function makePatientLogin(client: Profile) {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new ApiRepository(apiClient)
  const authApiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    apiRepository
  )
  const authenticationRepository = new AuthenticationRepository(
    authApiRepository
  )
  const signup = new RemoteAuthentication(authenticationRepository)

  const verifyClient = new RemoteVerifyLoginClient(authenticationRepository)
  return (
    <PatientLogin
      client={client}
      verifyClient={verifyClient}
      useCase={signup}
    />
  )
}
