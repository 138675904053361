import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteLoadSurgicalOrderDocument } from 'service/usecases/load-surgical-order-document/load-upload-surgical-order-document'
import SurgicalOrderService from 'presentation/contexts/services/surgical-order-service'
import { RemoteUploadSurgicalOrderDocument } from 'service/usecases/upload-surgical-order-document/remote-upload-surgical-order-document'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { RemoteLoadCid } from 'service/usecases/load-cid/remote-load-cid'
import { RemoteConcludeSurgicalOrder } from 'service/usecases/conclude-surgical-order/remote-conclude-surgical-order'

import { RemoteDeleteSurgicalOrderSolicitation } from 'service/usecases/delete-surgical-order-solicitation/remote-delete-surgical-order-solicitation'
import { RemoteUploadSurgicalOrderDocumentsSameType } from 'service/usecases/upload-surgical-order-documents-same-type/remote-upload-surgical-order-documents-same-type'
import { RemoteLoadFavoriteSurgicalOrders } from 'service/usecases/load-favorite-surgical-orders/remote-load-favorite-surgical-orders'
import { RemoteDeleteFavoritedSurgicalOrder } from 'service/usecases/delete-favorited-surgical-order/remote-delete-favorited-surgical-order'
import { RemoteCheckinSurgicalOrder } from 'service/usecases/checkin-surgical-order/remote-checkin-surgical-order'
import { RemoteLoadFavoriteDoctorSurgicalOrders } from 'service/usecases/load-favorite-doctor-orders/remote-load-favorite-doctor-orders'
import { RemoteUploadToMergeDocument } from 'service/usecases/upload-to-merge-document/remote-upload-to-merge-document'
import { RemoteConfirmMergeDocuments } from 'service/usecases/confirm-merge-documents/remote-confirm-merge-documents'
import { RemoteDeleteUploadedDocuments } from 'service/usecases/delete-uploaded-document-surgical-order/remote-delete-uploaded-document-surgical-order'
import { RemoteLoadSurgicalOrderNoticeCard } from 'service/usecases/load-surgical-orders-notice-card/remote-load-surgical-orders-notice-card'
import { RemoteGetSurgicalOrderTimeline } from 'service/usecases/get-surgical-order-timeline/get-surgical-order-timeline'
import { RemoteRequestSurgicalOrderCancellation } from 'service/usecases/request-surgical-order-cancellation/request-surgical-order-cancellation'
import { RemoteAddSurgicalOrderCancellationComment } from 'service/usecases/add-surgical-order-cancellation-comment/add-surgical-order-cancellation-comment'
import { RemoteGetSurgicalOrderCancellationNotifications } from 'service/usecases/get-surgical-order-cancellation/get-surgical-order-cancellation'
import { RemoteConfirmReadCancellationRequests } from 'service/usecases/read-cancellation-requests/read-cancellation-requests'
import { RemoteSendReasonNotSurgery } from 'service/usecases/send-reason-not-surgery/send-reason-not-surgery'

export default function makeSurgicalOrderService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)

  const loadSurgicalOrderDocument = new RemoteLoadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const uploadSurgicalOrderDocument = new RemoteUploadSurgicalOrderDocument(
    surgicalOrderRepository
  )
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)
  const loadCids = new RemoteLoadCid(surgicalOrderRepository)
  const concludeSurgicalOrder = new RemoteConcludeSurgicalOrder(
    surgicalOrderRepository
  )
  const deleteSurgicalOrderSolicitation =
    new RemoteDeleteSurgicalOrderSolicitation(surgicalOrderRepository)
  const checkin = new RemoteCheckinSurgicalOrder(surgicalOrderRepository)

  const uploadSurgicalOrderDocumentsSameType =
    new RemoteUploadSurgicalOrderDocumentsSameType(surgicalOrderRepository)

  const loadFavoriteSurgicalOrders = new RemoteLoadFavoriteSurgicalOrders(
    surgicalOrderRepository
  )

  const loadFavoriteDoctorSurgicalOrders =
    new RemoteLoadFavoriteDoctorSurgicalOrders(surgicalOrderRepository)

  const deleteFavoritedSurgicalOrder = new RemoteDeleteFavoritedSurgicalOrder(
    surgicalOrderRepository
  )

  const uploadToMergeDocument = new RemoteUploadToMergeDocument(
    surgicalOrderRepository
  )

  const confirmMergeDocuments = new RemoteConfirmMergeDocuments(
    surgicalOrderRepository
  )

  const deleteUploadedDocuments = new RemoteDeleteUploadedDocuments(
    surgicalOrderRepository
  )

  const loadSurgicalOrderNoticeCard = new RemoteLoadSurgicalOrderNoticeCard(
    surgicalOrderRepository
  )

  const getSurgicalOrderTimeline = new RemoteGetSurgicalOrderTimeline(
    surgicalOrderRepository
  )

  const requestSurgicalOrderCancellation =
    new RemoteRequestSurgicalOrderCancellation(surgicalOrderRepository)

  const addSurgicalOrderCancellationComment =
    new RemoteAddSurgicalOrderCancellationComment(surgicalOrderRepository)

  const getSurgicalOrderCancellationNotifications =
    new RemoteGetSurgicalOrderCancellationNotifications(surgicalOrderRepository)

  const confirmReadCancellationRequests =
    new RemoteConfirmReadCancellationRequests(surgicalOrderRepository)

  const sendReasonNotSurgery = new RemoteSendReasonNotSurgery(
    surgicalOrderRepository
  )

  return new SurgicalOrderService({
    loadSurgicalOrderDocument,
    uploadSurgicalOrderDocument,
    loadSurgicalOrder,
    loadCids,
    concludeSurgicalOrder,
    deleteSurgicalOrderSolicitation,
    uploadSurgicalOrderDocumentsSameType,
    loadFavoriteSurgicalOrders,
    loadFavoriteDoctorSurgicalOrders,
    deleteFavoritedSurgicalOrder,
    checkin,
    uploadToMergeDocument,
    confirmMergeDocuments,
    deleteUploadedDocuments,
    loadSurgicalOrderNoticeCard,
    getSurgicalOrderTimeline,
    requestSurgicalOrderCancellation,
    addSurgicalOrderCancellationComment,
    getSurgicalOrderCancellationNotifications,
    confirmReadCancellationRequests,
    sendReasonNotSurgery
  })
}
