import React, { ChangeEvent } from 'react'
import { InputHTMLAttributes } from 'react'

import theme from '../../../styles/theme'
import * as S from './styles'

type RadioValue = any

export type RadioProps = {
  color?: keyof typeof theme.ds.colors
  label?: string | React.ReactElement
  labelColor?: keyof typeof theme.ds.colors
  labelSize?: keyof typeof theme.ds.typography.size
  labelFor?: string
  value?: RadioValue
  onCheck?: (value?: RadioValue) => void
  showBackground?: boolean
  customLabelComponent?: React.ReactElement
} & InputHTMLAttributes<HTMLInputElement>

const RadioButtonNew = ({
  color = 'primary600',
  showBackground = true,
  label,
  labelColor = 'neutral900',
  labelSize = 'small',
  labelFor = '',
  value,
  onCheck,
  customLabelComponent,
  ...props
}: RadioProps) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    !!onCheck && onCheck(e.target.value)
  }

  return (
    <S.Wrapper isChecked={!!props.checked} showBackground={showBackground}>
      <S.Input
        id={labelFor}
        type="radio"
        value={value}
        onChange={onChange}
        color={color}
        {...props}
      />
      {!!label && (
        <S.Label
          htmlFor={labelFor}
          labelColor={labelColor}
          labelSize={labelSize}
        >
          {label}
        </S.Label>
      )}
      {customLabelComponent ? customLabelComponent : null}
    </S.Wrapper>
  )
}

export default RadioButtonNew
