import styled, { css, DefaultTheme } from 'styled-components'

import { PasswordFieldProps } from '.'

type IconPositionProps = Pick<PasswordFieldProps, 'iconPosition'>

type WrapperProps = Pick<PasswordFieldProps, 'disabled'> & { error?: boolean }

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: ${({ theme }) => theme.border.radius.small};
`

export const Input = styled.input<IconPositionProps>`
  color: ${({ theme }) => theme.colors.gray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  line-height: ${({ theme }) => theme.font.sizes.large};
  height: 54px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  border-radius: ${({ theme }) => theme.border.radius.small};
  padding-left: ${({ iconPosition }) =>
    iconPosition === 'left' ? '24px' : '18px'};
  padding-right: ${({ iconPosition }) =>
    iconPosition === 'right' ? '24px' : '18px'};
  width: ${({ iconPosition }) =>
    iconPosition === 'right' ? 'calc(100% - 22px)' : '100%'};
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    outline: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primary};
  }
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 12px;
`

export const Icon = styled.div<IconPositionProps>`
  display: flex;
  cursor: pointer;
  position: absolute;
  color: ${({ theme }) => theme.colors.gray};
  right: 20px;
  order: ${({ iconPosition }) => (iconPosition === 'right' ? 1 : 0)};
  & > svg {
    width: 30px;
    height: 18px;
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.colors.lightRed};
      border-width: 1px;
      border-style: solid;
    }
    ${Icon} {
      color: ${theme.colors.lightRed};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      cursor: not-allowed;
      color: ${theme.colors.gray};
      &::placeholder {
        color: currentColor;
      }
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`
