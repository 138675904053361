import { LoadDoctorRecommendations } from 'domain/usecases/doctor/load-doctor-recommendation'
import { RecommendationRepository } from 'repository/interfaces/recommendation-repository'

export class RemoteLoadDoctorRecommendations
  implements LoadDoctorRecommendations
{
  constructor(
    private readonly recommendationRepository: RecommendationRepository
  ) {}

  load(
    params: LoadDoctorRecommendations.Params,
    initialDate?: Date
  ): Promise<LoadDoctorRecommendations.Model> {
    return this.recommendationRepository.loadDoctorRecommendations(
      params,
      initialDate
    )
  }
}

export type LoadDoctorRecommendationsModel = LoadDoctorRecommendations.Model
