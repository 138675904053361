import { makeAutoObservable } from 'mobx'
import { UpdateDoctorRegisterStep } from 'domain/usecases/doctor-register-step/update-doctor-register-step'

type Services = {
  updateDoctorRegisterStep: UpdateDoctorRegisterStep
}

export class DoctorRegisterStepService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async updateDoctorRegisterStep(
    params: UpdateDoctorRegisterStep.Params
  ): Promise<UpdateDoctorRegisterStep.Model> {
    return this.services.updateDoctorRegisterStep.update(params)
  }
}
