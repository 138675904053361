import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import theme from 'presentation/styles/theme'
import SheetModal from 'presentation/components/SheetModal'
import React from 'react'
import * as S from '../styles'

type SuccessRenewModalProps = {
  show: boolean
  onClose: () => void
}

export default function SuccessRenewModal({
  onClose,
  show
}: SuccessRenewModalProps) {
  return (
    <SheetModal size={220} isOpen={show} onClose={onClose}>
      <S.Container>
        <S.HeaderContainer>
          <Heading size="large">
            Token prorrogado por mais{' '}
            <strong style={{ color: theme.colors.primaryDarker }}>
              30 minutos
            </strong>
          </Heading>
        </S.HeaderContainer>
        <Button
          fullWidth
          style={{ marginTop: '20px' }}
          backgroundColor={'primaryDarker'}
          onClick={onClose}
        >
          Voltar a tela inicial
        </Button>
      </S.Container>
    </SheetModal>
  )
}
