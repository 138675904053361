import React from 'react'

import * as S from './styles'

import { Container } from 'presentation/components/Container'
import BottomMenu from 'presentation/components/BottomMenu'
import Header from 'presentation/components/Header'
import LabelBox from 'presentation/shared/components/LabelBox'
import { Allergy } from 'domain/entities/allergy-model'
import ActualPage from 'presentation/components/ActualPage'
import { useHistory } from 'react-router-dom'
import { dateInputFormat } from 'presentation/utils/date-format'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'
import InternationDetails from 'presentation/shared/components/InternationDetails'

type Props = {
  allergies: Allergy[]
}

export default function AllergiesLayout({ allergies = [] }: Props) {
  const history = useHistory()
  const account = useStores().currentAccount.getCurrentAccount()
  return (
    <>
      <Header />
      <Container>
        <>
          <S.Header>
            <ActualPage text="Alergias" onClick={history.goBack} />
            {account.user.role === Profile.DOCTOR && (
              <p onClick={() => history.push('/alergia/save')}>
                Adicionar alergia
              </p>
            )}
            <InternationDetails />
          </S.Header>
          <LabelBox
            columns={2}
            text={
              <>
                <S.HeaderText>Data de registro</S.HeaderText>
                <S.HeaderText>Alergia</S.HeaderText>
              </>
            }
          >
            <>
              {allergies.map((allergy) => (
                <>
                  <S.ContentText>
                    <span>{dateInputFormat(allergy.createdAt)}</span>
                  </S.ContentText>
                  <S.ContentText>
                    <span>{allergy.description}</span>
                  </S.ContentText>
                </>
              ))}
            </>
          </LabelBox>
        </>
      </Container>
      <BottomMenu />
    </>
  )
}
