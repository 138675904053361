import { Profile } from '../enum/profile'
import { Authentication } from '../../domain/usecases/authentication/authentication'

export const profileBind: Record<Profile, Authentication.Params['role']> = {
  [Profile.DOCTOR]: 'DOCTOR',
  [Profile.PATIENT]: 'PATIENT',
  [Profile.SECRETARY]: 'SECRETARY',
  [Profile.HOSPITAL]: 'ADMIN',
  [Profile.CRMO]: 'CRMO',
  [Profile.HOSPITALIZATION]: 'HOSPITALIZATION',
  [Profile.AMBULATORY]: 'AMBULATORY',
  [Profile.ONCOLOGY]: 'ONCOLOGY',
  [Profile.DOCTOR_REGISTER]: 'DOCTOR_REGISTER',
  [Profile.DIRECTOR_DOCTOR_REGISTER]: 'DIRECTOR_DOCTOR_REGISTER',
  [Profile.ADMIN_SECTORIAL_CRMO]: 'ADMIN_SECTORIAL_CRMO',
  [Profile.EMERGENCY_ROOM]: 'EMERGENCY_ROOM',
  [Profile.SETOR_DE_MARCACAO]: 'SETOR_DE_MARCACAO'
}
