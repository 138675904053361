import { makeAutoObservable } from 'mobx'
import { SearchExamDoctors } from 'domain/usecases/exam/search-exam-doctors'
import { GetPatientExamAppointments } from 'domain/usecases/patient/get-exam-appointments'
import { SearchExams } from 'domain/usecases/exam/search-exams'
import { GetExamScheduleOffersByMonth } from 'domain/usecases/exam/get-exam-schedule-offers-by-month'
import { GetExamScheduleHours } from 'domain/usecases/exam/get-exam-schedule-hours'
import { CreatePatientExamAppointments } from 'domain/usecases/patient/create-patient-exam-appointment'
import { GetExamAvailableDates } from 'domain/usecases/exam/get-exam-available-dates'
import { GetExamReasonCancel } from 'domain/usecases/exam/get-exam-reason-cancel'
import { CancelExamSchedule } from 'domain/usecases/exam/cancel-exam-schedule'
import { CheckResourceSchedule } from 'domain/usecases/exam/check-resource-schedule'
import { GetExamPreparation } from 'domain/usecases/exam/get-exam-preparation'
import { GetExamByItem } from 'domain/usecases/exam/get-exam-by-item'
import { GetExamAvailableUnits } from 'domain/usecases/exam/get-exam-available-units'
import { GetExamAvailableHospitals } from 'domain/usecases/exam/get-exam-available-hospitals'
import { GetExamPreparationWithParams } from 'domain/usecases/exam/get-exam-preparation-with-params'
import { GetExamGroupOrdered } from 'domain/usecases/exam/get-exam-group-ordered'

type Services = {
  searchExamDoctors: SearchExamDoctors
  getPatientExamAppointments: GetPatientExamAppointments
  searchExams: SearchExams
  getExamScheduleOffersByMonth: GetExamScheduleOffersByMonth
  getExamScheduleHours: GetExamScheduleHours
  getExamAvailableDates: GetExamAvailableDates
  createPatientExamAppointments: CreatePatientExamAppointments
  getExamReasonCancel: GetExamReasonCancel
  cancelExamSchedule: CancelExamSchedule
  checkResourceSchedule: CheckResourceSchedule
  getExamPreparation: GetExamPreparation
  getExamByItem: GetExamByItem
  getExamAvailableUnits: GetExamAvailableUnits
  getExamAvailableHospitals: GetExamAvailableHospitals
  getExamPreparationWithParams: GetExamPreparationWithParams
  getExamGroupOrdered: GetExamGroupOrdered
}

export class ExamService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async getPatientExamAppointments(
    params: GetPatientExamAppointments.Params
  ): Promise<GetPatientExamAppointments.Model> {
    return this.services.getPatientExamAppointments.load(params)
  }

  async searchExamDoctors(
    params: SearchExamDoctors.Params
  ): Promise<SearchExamDoctors.Model> {
    return this.services.searchExamDoctors.load(params)
  }

  async searchExams(params: SearchExams.Params): Promise<SearchExams.Model> {
    return this.services.searchExams.load(params)
  }

  async getExamScheduleOffersByMonth(
    params: GetExamScheduleOffersByMonth.Params
  ): Promise<GetExamScheduleOffersByMonth.Model> {
    return this.services.getExamScheduleOffersByMonth.load(params)
  }

  async getExamScheduleHours(
    params: GetExamScheduleHours.Params
  ): Promise<GetExamScheduleHours.Model> {
    return this.services.getExamScheduleHours.load(params)
  }

  async getExamAvailableDates(
    params: GetExamAvailableDates.Params
  ): Promise<GetExamAvailableDates.Model> {
    return this.services.getExamAvailableDates.load(params)
  }

  async createPatientExamAppointment(
    params: CreatePatientExamAppointments.Params
  ): Promise<CreatePatientExamAppointments.Model> {
    return this.services.createPatientExamAppointments.create(params)
  }

  async getExamReasonCancel(): Promise<GetExamReasonCancel.Model> {
    return this.services.getExamReasonCancel.load()
  }

  async cancelExamSchedule(
    params: CancelExamSchedule.Params
  ): Promise<CancelExamSchedule.Model> {
    return this.services.cancelExamSchedule.delete(params)
  }

  async checkResourceSchedule(
    params: CheckResourceSchedule.Params
  ): Promise<CheckResourceSchedule.Model> {
    return this.services.checkResourceSchedule.get(params)
  }

  async getExamPreparation(
    params: GetExamPreparation.Params
  ): Promise<GetExamPreparation.Model> {
    return this.services.getExamPreparation.get(params)
  }

  async getExamByItem(
    params: GetExamByItem.Params
  ): Promise<GetExamByItem.Model> {
    return this.services.getExamByItem.get(params)
  }

  async getExamAvailableUnits(
    params: GetExamAvailableUnits.Params
  ): Promise<GetExamAvailableUnits.Model> {
    return this.services.getExamAvailableUnits.get(params)
  }

  async getExamAvailableHospitals(
    params: GetExamAvailableHospitals.Params
  ): Promise<GetExamAvailableHospitals.Model> {
    return this.services.getExamAvailableHospitals.get(params)
  }

  async getExamPreparationWithParams(
    params: GetExamPreparationWithParams.Params
  ): Promise<GetExamPreparationWithParams.Model> {
    return this.services.getExamPreparationWithParams.get(params)
  }

  async getExamGroupOrdered(
    params: GetExamGroupOrdered.Params
  ): Promise<GetExamGroupOrdered.Model> {
    return this.services.getExamGroupOrdered.get(params)
  }
}

export default ExamService
