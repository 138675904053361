import { LoadSurgicalScheduleAvailableDays } from 'domain/usecases/load-surgical-schedule-available-days/load-surgical-schedule-available-days'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadSurgicalScheduleAvailableDays
  implements LoadSurgicalScheduleAvailableDays
{
  constructor(
    private readonly surgicalScheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(
    params: LoadSurgicalScheduleAvailableDays.Params
  ): Promise<LoadSurgicalScheduleAvailableDays.Model> {
    return this.surgicalScheduleRepository.loadSurgicalScheduleAvailableDays(
      params
    )
  }
}

export type LoadSurgicalScheduleAvailableModel =
  LoadSurgicalScheduleAvailableDays.Model
