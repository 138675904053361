import { makeAutoObservable } from 'mobx'
import { AddRecommendation } from 'domain/usecases/doctor/add-recommendation'
import { LoadDoctorRecommendations } from 'domain/usecases/doctor/load-doctor-recommendation'
import { LoadRecommendationByInviteToken } from 'domain/usecases/doctor/load-recommendation-by-invite-token'
import { UpdateRecommendation } from 'domain/usecases/doctor/update-recommendation'
import { CreateRecommendationWithoutDoctor } from 'domain/usecases/doctor/create-recommendation-without-doctor'

type Services = {
  createRecommendationWithoutDoctor: CreateRecommendationWithoutDoctor
  addRecommendation: AddRecommendation
  loadDoctorRecommendations: LoadDoctorRecommendations
  loadRecommendationByInviteToken: LoadRecommendationByInviteToken
  updateRecommendation: UpdateRecommendation
}

export class RecommendationService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async addRecommendation(
    params: AddRecommendation.Params
  ): Promise<AddRecommendation.Model> {
    return this.services.addRecommendation.add(params)
  }

  async createRecommendationWithoutDoctor(
    params: AddRecommendation.Params
  ): Promise<AddRecommendation.Model> {
    return this.services.createRecommendationWithoutDoctor.add(params)
  }

  async loadDoctorRecommendations(
    params: LoadDoctorRecommendations.Params,
    initialDate?: Date
  ): Promise<LoadDoctorRecommendations.Model> {
    return this.services.loadDoctorRecommendations.load(params, initialDate)
  }

  async loadRecommendationByInviteToken(
    token: string,
    params: LoadRecommendationByInviteToken.Params
  ): Promise<LoadRecommendationByInviteToken.Model> {
    return this.services.loadRecommendationByInviteToken.load(token, params)
  }

  async updateRecommendation(
    params: UpdateRecommendation.Params
  ): Promise<UpdateRecommendation.Model> {
    return this.services.updateRecommendation.update(params)
  }
}

export default RecommendationService
