import { GetExamPreparationWithParams } from 'domain/usecases/exam/get-exam-preparation-with-params'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamPreparationWithParams
  implements GetExamPreparationWithParams
{
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: GetExamPreparationWithParams.Params
  ): Promise<GetExamPreparationWithParams.Model> {
    return this.examRepository.getExamPreparationWithParams(params)
  }
}

export type GetExamPreparationWithParamsModel =
  GetExamPreparationWithParams.Model
