import { LoadHospitalSurgeryCenter } from 'domain/usecases/hospital-surgery-center/load-hospital-surgery-center'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadHospitalSurgeryCenter
  implements LoadHospitalSurgeryCenter
{
  constructor(
    private readonly surgicalScheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(
    params: LoadHospitalSurgeryCenter.Params
  ): Promise<LoadHospitalSurgeryCenter.Model> {
    return this.surgicalScheduleRepository.loadHospitalSurgeryCenter(params)
  }
}

export type LoadHospitalSurgeryCenterModel = LoadHospitalSurgeryCenter.Model
