export enum Roles {
  ADMIN = 'ADMIN',
  DOCTOR = 'DOCTOR',
  PATIENT = 'PATIENT',
  NURSE = 'NURSE',
  SECRETARY = 'SECRETARY',
  CRMO = 'CRMO',
  HOSPITALIZATION = 'HOSPITALIZATION',
  SETOR_DE_MARCACAO = 'SETOR_DE_MARCACAO'
}
