import { EmergencyRoomTokenModel } from 'domain/entities/emergency-room-token.model'

type SetNewTimeLeftProps = {
  emergencyRoomToken?: EmergencyRoomTokenModel
  setTimeLeft: (newTimeLeft: string) => void
  timeLeft?: string
}

export function setNewTimeLeft({
  emergencyRoomToken,
  setTimeLeft,
  timeLeft
}: SetNewTimeLeftProps) {
  if (!emergencyRoomToken) return
  const diff = emergencyRoomToken?.arrivalForecastTimer?.getDifferenceByNow()
  if (diff === -1 && timeLeft === 'Em instantes') return
  setTimeLeft(
    emergencyRoomToken?.arrivalForecastTimer?.toStringDifference() || '-'
  )
}
