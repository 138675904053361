import styled, { css } from 'styled-components'

import { HealthInsuranceCardWrapper } from 'presentation/shared/components/HealthInsuranceCard/styles'

export const Wrapper = styled.div`
  padding-bottom: 24px;
`
export const Content = styled.div`
  margin-top: 24px;

  ${HealthInsuranceCardWrapper} {
    ${({ theme }) => css`
      cursor: pointer;
      border: 1px solid transparent;
      transition: border ease 0.2s;
      &:hover,
      &:active,
      &:focus {
        border-color: ${theme.ds.colors.primary600};
      }
    `}
  }
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const IpsemgInfo = styled.p`
  ${({ theme }) => css`
    margin-top: -16px;
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 20px;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors.danger300};

    a {
      text-decoration: underline;
      color: ${theme.ds.colors.info400};
      transition: color ease 0.1s;

      &:hover {
        color: ${theme.ds.colors.info300};
      }
    }
  `}
`

export const PurpleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 16px;
`

export const CardsList = styled.div`
  /* height: 550px;
  overflow-y: scroll; */
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const CardContainer = styled.div`
  display: grid;
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  background: ${({ theme }) => theme.ds.colors.neutral25};
  border-radius: 8px;
  padding: 18px;
  margin-bottom: 20px;
`

export const CardTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const CardDescription = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-top: 8px;
  /* margin-bottom: 5px; */
`

export const HorizontalButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 4px;
  /* justify-content: space-between; */
  place-content: left;

  button {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const HorizontalRadioPanel = styled.div`
  display: flex;
  gap: 28px;
`

export const TitleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};

  margin-bottom: 10px;
`

export const SubTitleHeader = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 10px;
`

export const SubTitleGreen = styled.p`
  color: ${({ theme }) => theme.ds.colors.primary800};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 10px;
  margin-top: 7px;
  margin-left: 2px;
`

export const SubTitleGreenCheckBox = styled.p`
  color: ${({ theme }) => theme.ds.colors.primary800};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 10px;
  margin-left: 2px;
`

export const AnesthesiaSubTitle = styled.p`
  color: ${({ theme }) => theme.ds.colors.primary800};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  margin-top: 10px;
  margin-bottom: 3px;
`

export const AnesthesiaOption = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-top: 10px;
  margin-bottom: 3px;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.ds.colors.neutral800};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  margin-bottom: 20px;
`

export const HeaderContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const StrongHeader = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 8px;
`

export const ButtonPreparation = styled.div`
  display: flex;
  justify-content: end;
`

export const CardExamTitle = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral700};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 10px;
`

export const CardExamDate = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  margin-bottom: 10px;
`

export const CardLabel = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral600};
  font-size: ${({ theme }) => theme.ds.typography.size.xxsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const CardTextValue = styled.strong`
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  margin-bottom: 5px;
`

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  gap: 20px;
  place-content: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: ${({ theme }) => theme.ds.colors.neutral25};

  color: ${({ theme }) => theme.ds.colors.neutral500};
  text-align: center;
`

export const LoadingContainer = styled.div`
  height: 83vh;
`
