import { CheckPatientByPhone } from 'domain/usecases/patient/check-patient-by-phone'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteCheckPatientByPhone implements CheckPatientByPhone {
  constructor(private readonly patientRepository: PatientRepository) {}

  check(Phone: string): Promise<CheckPatientByPhone.Model> {
    return this.patientRepository.checkPatientByPhone(Phone)
  }
}
