import { SearchExamDoctors } from 'domain/usecases/exam/search-exam-doctors'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteSearchExamDoctors implements SearchExamDoctors {
  constructor(private readonly examRepository: ExamRepository) {}
  async load(
    params: SearchExamDoctors.Params
  ): Promise<SearchExamDoctors.Model> {
    return this.examRepository.searchExamDoctors(params)
  }
}

export type SearchExamDoctorsModel = SearchExamDoctors.Model
