import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;

  min-height: 400px !important;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  div {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.font.bold};
  }
`
