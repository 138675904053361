import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { HospitalizationRepository as IHospitalizationRepository } from 'repository/interfaces/hospitalization-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { AddHospitalizationService } from 'domain/usecases/hospitalization/add-hospitalization-service'
import { createHospitalizationServiceMutation } from 'repository/graphql/mutations'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

export class HospitalizationRepository implements IHospitalizationRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async addHospitalizationService(
    params?: AddHospitalizationService.Params
  ): Promise<AddHospitalizationService.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadHospitals.Model>

    const query = createHospitalizationServiceMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddHospitalizationService.Model
    }
  }
}
