import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { SchedulingSchedule } from 'domain/entities/scheduling-schedule-model'
import HeaderNew from 'presentation/components/HeaderNew'
import ButtonNew from 'presentation/components/ButtonNew'
import { ReactComponent as CreditCardIcon } from 'presentation/assets/icons/credit-card.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as PinIcon } from 'presentation/assets/icons/new_pin.svg'
import { ReactComponent as ConfirmIcon } from 'presentation/assets/icons/confirm-icon.svg'
import { ReactComponent as StethoscopeIconOutline } from 'presentation/assets/icons/stethoscope-outline.svg'
import * as S from './styles'
import BottomMenu from 'presentation/components/BottomMenu'
import CardNew from 'presentation/shared/components/CardNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import NpsScale from 'presentation/shared/components/NpsScale'

type LocationType = {
  state: {
    state: {
      filteredSearchResult: {
        data: {
          getSchedules: SchedulingSchedule[]
        }
      }
      selectedSchedule: SchedulingSchedule
      selectedHour: SchedulingHours
      patient: Patient
      healthInsurance: PatientHealthInsurance
    }
  }
}

const SchedulingConfirmedLayout = () => {
  const history = useHistory()
  const location: LocationType = useLocation()
  const scheduleState = location.state && location.state.state.selectedSchedule
  const scheduleHourState = location.state && location.state.state.selectedHour
  const patientState = location.state && location.state.state.patient
  const healthInsuranceState =
    location.state && location.state.state.healthInsurance

  useEffect(() => {
    if (
      !patientState ||
      !scheduleState ||
      !scheduleHourState ||
      !healthInsuranceState
    ) {
      history.push('/consultas')
    }
  }, [])

  const footerButton = () => {
    return (
      <ButtonNew
        color="primary"
        fullWidth
        type="button"
        size="large"
        onClick={() => history.push('/consultas')}
      >
        Ir para minhas consultas
      </ButtonNew>
    )
  }

  return (
    <>
      <HeaderNew actualPageTitle="Sucesso!" />
      <ContainerNew
        noHeader
        style={{ background: 'white' }}
        primaryButton={footerButton()}
      >
        {patientState &&
          scheduleState &&
          scheduleHourState &&
          healthInsuranceState && (
            <>
              <S.CardSchedulingInfos>
                <div className="header">
                  <div className="img">
                    <ConfirmIcon className="icon" />
                  </div>
                  <div className="header-infos">
                    <h2 className="title">Consulta agendada com sucesso!</h2>
                  </div>
                </div>
                <div className="content">
                  <div className="box">
                    <StethoscopeIconOutline className="icon" />
                    <div className="infos">
                      <h6 className="title">
                        <span>Dr(a)</span>{' '}
                        <span className="text">
                          {scheduleState?.doctor?.name?.toLocaleLowerCase()}
                        </span>
                      </h6>
                      <p className="description -plan-name">
                        {scheduleState?.specialty?.name?.toLocaleLowerCase()}
                      </p>
                    </div>
                  </div>

                  <div className="box">
                    <CalendarIcon className="icon" />
                    <div className="infos">
                      <h4 className="title">
                        {moment(scheduleHourState.date_begin)
                          .locale('pt-br')
                          .format('dddd - DD [de] MMMM')}{' '}
                        <span>às</span>{' '}
                        {moment(scheduleHourState.date_begin)
                          .locale('pt-br')
                          .format('LT')}
                      </h4>
                    </div>
                  </div>

                  <div className="box">
                    <CreditCardIcon className="icon" />
                    <div className="infos">
                      <h6 className="title">
                        {healthInsuranceState.healthInsuranceName?.toLocaleLowerCase()}
                      </h6>
                      <p className="description -plan-name">
                        {healthInsuranceState?.healthPlanName?.toLocaleLowerCase()}
                      </p>
                    </div>
                  </div>

                  <div className="box">
                    <PinIcon className="icon" />
                    <div className="infos">
                      <h6 className="title">
                        {scheduleState.unit_name?.toLocaleLowerCase()}
                      </h6>
                      <p className="description -localization">
                        {scheduleState.unit_description?.toLocaleLowerCase()}
                      </p>
                    </div>
                  </div>
                </div>
              </S.CardSchedulingInfos>
            </>
          )}

        <DividerNew leak="24px" marginBottom="24px" marginTop="24px" />

        <CardNew
          shadow="none"
          background="neutral50"
          style={{ alignItems: 'center', textAlign: 'center' }}
        >
          <SupportTextNew weight="bold" size="large" color="neutral900">
            Avalie sua experiência
          </SupportTextNew>
          <SupportTextNew
            style={{ textAlign: 'center', marginTop: '4px' }}
            color="neutral900"
          >
            De 0 a 10, como foi sua experiência com o agendamento de consultas?
          </SupportTextNew>
          <NpsScale
            style={{ marginTop: '24px', width: '270px' }}
            onClick={(rate) =>
              history.push('/agendamento/consulta/avalie', {
                rate,
                state: location.state
              })
            }
          />
        </CardNew>
      </ContainerNew>
      <BottomMenu />
    </>
  )
}

export default SchedulingConfirmedLayout
