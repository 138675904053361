import React, { useEffect, useState } from 'react'
import HealthInsuranceLayout from 'presentation/layouts/HealthInsurance'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import {
  SearchHealthInsurances,
  HealthInsurancePlans
} from 'domain/usecases/health-insurance/search-health-insurances'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useLocation } from 'react-router'
import { toast } from 'react-toastify'

type Props = {
  pageTitle: string
  primaryButtonText: string
  searchHealthInsurances: SearchHealthInsurances
  addOrUpdateHealthInsurance: UpdatePatientHealthInsurance
  showHeader?: {
    showHeader: boolean
  }
  noPadding?: boolean
  showAddHealthInsurance?: boolean
  setShowAddHealthInsurance?: (visible: boolean) => void
} & WithLoadingProps

type State = {
  state?: {
    healthInsurance: PatientHealthInsurance
  }
}

const HealthInsurance = WithLoading(
  ({
    pageTitle,
    primaryButtonText,
    searchHealthInsurances,
    addOrUpdateHealthInsurance,
    showHeader,
    setIsLoading,
    showAddHealthInsurance,
    setShowAddHealthInsurance,
    noPadding
  }: Props) => {
    const { state: locationState } = useLocation() as State
    const [healthInsurance, setHealthInsurance] =
      useState<PatientHealthInsurance>({} as PatientHealthInsurance)
    const [availableHealthInsurances, setAvailableHealthInsurances] = useState(
      [] as HealthInsurancePlans[]
    )

    useEffect(() => {
      async function loadData() {
        setIsLoading(true)
        try {
          const response = await searchHealthInsurances.load({
            params: [
              `
                code
                description
                plans {
                  code
                  description
                }
                ansRegister
              `
            ],
            hospital_id: 1
          })

          setAvailableHealthInsurances(response)
        } catch (error: any) {
          toast.error('Houve um erro ao carregar os convênios')
        } finally {
          setIsLoading(false)
        }
      }

      loadData()
    }, [])

    useEffect(() => {
      const healthInsurance = locationState?.healthInsurance

      if (healthInsurance?.health_insurance_id) {
        setHealthInsurance(healthInsurance)
      }
    }, [])

    return (
      <HealthInsuranceLayout
        pageTitle={pageTitle}
        primaryButtonText={primaryButtonText}
        healthInsurance={healthInsurance}
        availableHealthInsurances={availableHealthInsurances}
        addOrUpdateHealthInsurance={addOrUpdateHealthInsurance}
        showHeader={showHeader}
        noPadding={noPadding}
        setShowAddHealthInsurance={setShowAddHealthInsurance}
        showAddHealthInsurance={showAddHealthInsurance}
      />
    )
  }
)

export default HealthInsurance
