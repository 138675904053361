export type FeverOptions = 'YES' | 'NO' | 'I_CANT_TELL'
export type BreathingDifficultyOptions =
  | 'COUGH'
  | 'RUNNY_NOSE'
  | 'SORE_THROAT'
  | 'SHORTNESS_OF_BREATH'
export type Locations = 'THORAX' | 'HEAD' | 'ABDOMEN' | 'LOW_BACK'

export type SymptomsValues = {
  fever?: FeverOptions
  isFever?: number
  temperatureFever?: string | number
  breathingDifficulty?: boolean
  details?: string
  otherPainLocation?: string
  breathingOptions?: BreathingDifficultyOptions[]
  isHeartPalpitations?: boolean
  isPain?: boolean
  lossStrength?: boolean
  difficultiesSpeakWalk?: boolean
  confusionMemoryLoss?: boolean
  chestRadiatingArm?: boolean
  abdominalPain?: boolean
  hypertensionDiabetes?: boolean
  painLocation?: {
    location?: Locations
    intensity?: number
  }[]
  symptoms_id?: number
}

type LocationInfo = 'head' | 'thorax' | 'abdomen' | 'low back'

type PainIntensityInfo = {
  [k in LocationInfo]?: {
    label: string
    isAlert: boolean
  }
} & { hasAlert: boolean }

export const DEFAULT_ALERT_INTENSITY = 8

export class EmergencyRoomSymptoms implements SymptomsValues {
  symptoms_id?: number
  fever?: FeverOptions
  temperatureFever?: string | number
  breathingDifficulty?: boolean
  details?: string
  otherPainLocation?: string
  breathingOptions?: BreathingDifficultyOptions[]
  isHeartPalpitations?: boolean
  isPain?: boolean
  lossStrength?: boolean
  difficultiesSpeakWalk?: boolean
  confusionMemoryLoss?: boolean
  chestRadiatingArm?: boolean
  abdominalPain?: boolean
  hypertensionDiabetes?: boolean
  painLocation?: {
    location?: Locations
    intensity?: number
  }[]

  constructor(props: SymptomsValues) {
    if (!props) return
    Object.assign(this, props)
  }

  getPainIntensityInfo(
    alertIntensity = DEFAULT_ALERT_INTENSITY
  ): PainIntensityInfo {
    const painIntensityInfo: PainIntensityInfo = {
      hasAlert: false
    }
    if (this.painLocation) {
      this.painLocation.forEach((painLocation) => {
        if (!painLocation.location || !painLocation.intensity) return
        const hasAlert = painLocation.intensity >= alertIntensity
        painIntensityInfo[painLocation.location as LocationInfo] = {
          label: `Intensidade: ${painLocation.intensity}`,
          isAlert: hasAlert
        }
        if (hasAlert) {
          painIntensityInfo.hasAlert = true
        }
      })
    }
    return painIntensityInfo
  }

  formatFeverOptions(): string {
    switch (this.fever) {
      case 'I_CANT_TELL':
        return 'Não sei dizer'
      case 'YES':
        return 'Sim'
      case 'NO':
        return 'Não'
      default:
        return '-'
    }
  }

  formatBreathingDifficultyOption(
    breathingDifficulty: BreathingDifficultyOptions
  ): string {
    switch (breathingDifficulty) {
      case 'COUGH':
        return 'Tosse'
      case 'RUNNY_NOSE':
        return 'Coriza'
      case 'SORE_THROAT':
        return 'Dor de garganta'
      case 'SHORTNESS_OF_BREATH':
        return 'Falta de ar'

      default:
        return '-'
    }
  }

  formatBreathingDifficultyOptions(): string[] {
    return (
      this.breathingOptions?.map((option) =>
        this.formatBreathingDifficultyOption(option)
      ) || []
    )
  }
}
