import { AddRecommendation } from 'domain/usecases/doctor/add-recommendation'
import { RecommendationRepository } from 'repository/interfaces/recommendation-repository'

export class RemoteAddRecommendation implements AddRecommendation {
  constructor(
    private readonly recommendationRepository: RecommendationRepository
  ) {}

  add(params: AddRecommendation.Params): Promise<AddRecommendation.Model> {
    return this.recommendationRepository.addRecommendation(params)
  }
}

export type AddRecommendationModel = AddRecommendation.Model
