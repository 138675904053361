import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useStores } from 'presentation/hooks/use-stores'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import DependentsImage from 'presentation/assets/images/tutorials/tutor/dependents.svg'
import TutorGif from 'presentation/assets/images/tutorials/tutor/tutor.gif'
import SupportTextNew from 'presentation/components/SupportTextNew'
import Tutorials from 'presentation/shared/components/Tutorials'
import * as S from './styles'
import { FinishTutorialTutor } from 'domain/usecases/user/finish-tutorial-tutor'
import { AccountModel } from 'domain/entities/account-model'

type LocationType = {
  state: {
    pathname?: string
  }
}

type Props = {
  userService: FinishTutorialTutor
}

export default function TutorTutorialLayout({ userService }: Props) {
  const history = useHistory()
  const userStore = useStores().currentAccount
  const location: LocationType = useLocation()
  const [carouselState, setCarouselState] = useState({} as CarouselState)
  const [tutorGif, setTutorGif] = useState(TutorGif)

  const Image = (image: string) => {
    return (
      <S.Image>
        <img src={image} />
      </S.Image>
    )
  }

  const Gif = () => {
    return (
      <S.Image>
        <img src={tutorGif} />
      </S.Image>
    )
  }

  const backToMainPath = () => {
    history.push(location.state?.pathname || '/home')
  }

  const seeLater = () => {
    setAsFinished()
    backToMainPath()
  }

  const restartGif = () => {
    setTutorGif('')
    setTimeout(() => {
      setTutorGif(tutorGif)
    }, 1)
  }

  const goToFinish = () => {
    carouselState.slideNext()

    setTimeout(() => {
      userService.finishTutorialTutor().finally(() => {
        setAsFinished()
      })
    }, 400)
  }

  const setAsFinished = () => {
    const account = userStore.getCurrentAccount()

    userStore.setCurrentAccount(
      new AccountModel(account.accessToken, {
        ...account.user,
        finishTutorialTutor: true
      })
    )
  }

  return (
    <Carousel
      state={carouselState}
      setState={setCarouselState}
      style={{ height: '100%' }}
      containerStyle={{ height: '100%' }}
    >
      <Tutorials
        primaryButtonAction={() => goToFinish()}
        primaryButtonText="Vem ver o que mudou >"
        secondaryButtonAction={() => seeLater()}
        secondaryButtonText="Ver mais tarde"
      >
        <S.TextContent>
          <SupportTextNew color="neutral500" weight="bold" size="xxlarge">
            Olá, temos uma novidade!
          </SupportTextNew>
          <br />
          <SupportTextNew
            color="neutral500"
            weight="bold"
            size="xxlarge"
            as="span"
          >
            Agora você pode{' '}
          </SupportTextNew>
          <SupportTextNew
            color="neutral900"
            weight="bold"
            size="xxlarge"
            as="span"
          >
            acessar o perfil do seu dependente.
          </SupportTextNew>
        </S.TextContent>
        {Image(DependentsImage)}
      </Tutorials>
      <Tutorials
        primaryButtonAction={() => restartGif()}
        primaryButtonText="Assistir novamente"
        secondaryButtonAction={() => backToMainPath()}
        secondaryButtonText="Fechar"
      >
        {Gif()}
      </Tutorials>
    </Carousel>
  )
}
