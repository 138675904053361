import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { SchedulingRepository as ISchedulingRepository } from 'repository/interfaces/scheduling-repository'
import { ApiStatusCode } from 'service/protocols/api-scheduling/api-scheduling-client'
import { IApiSchedulingRepository } from 'service/protocols/api-scheduling/api-scheduling-repository'
import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { GetSchedulesByMonth } from 'domain/usecases/scheduling/get-schedules-by-month'
import { GetSchedules } from 'domain/usecases/scheduling/get-schedules'
import { CreateSchedule } from 'domain/usecases/scheduling/create-schedule'
import { GetReasonCancel } from 'domain/usecases/scheduling/get-reason-cancel'
import { DeleteSchedule } from 'domain/usecases/scheduling/delete-schedule'
import {
  searchFiltersSchedulesQuery,
  getSchedulesQuery,
  getSchedulesByMonthQuery,
  getSchedulesHoursQuery,
  getReasonCancelQuery,
  getPatientSchedulesQuery
} from 'repository/graphql/queries/scheduling'
import {
  createScheduleMutation,
  deleteScheduleMutation
} from 'repository/graphql/mutations/scheduling'
import { ListSchedules } from 'domain/usecases/scheduling/list-schedules'
import { Interval } from 'service/protocols/api/api-client'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { SchedulesList } from 'domain/entities/schedule-model'
// import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

export class SchedulingRepository implements ISchedulingRepository {
  constructor(
    private readonly apiSchedulingRepository: IApiSchedulingRepository
  ) {}

  async searchFiltersSchedules(
    params: SearchFiltersSchedules.Params
  ): Promise<SearchFiltersSchedules.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<SearchFiltersSchedules.Model>

    const query = searchFiltersSchedulesQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchFiltersSchedules.Model
    }
  }

  async getSchedules(params: GetSchedules.Params): Promise<GetSchedules.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetSchedules.Model>

    const query = getSchedulesQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetSchedules.Model
    }
  }

  async getSchedulesByMonth(
    params: GetSchedulesByMonth.Params
  ): Promise<GetSchedulesByMonth.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetSchedulesByMonth.Model>

    const query = getSchedulesByMonthQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetSchedulesByMonth.Model
    }
  }

  async getScheduleHours(
    params: GetHoursByDay.Params
  ): Promise<GetHoursByDay.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetHoursByDay.Model>

    const query = getSchedulesHoursQuery(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetHoursByDay.Model
    }
  }

  async createSchedule(
    params: CreateSchedule.Params
  ): Promise<CreateSchedule.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<CreateSchedule.Model>

    const query = createScheduleMutation(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateSchedule.Model
    }
  }

  async getReasonCancel(): Promise<GetReasonCancel.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<GetReasonCancel.Model>

    const query = getReasonCancelQuery()

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetReasonCancel.Model
    }
  }

  async deleteSchedule(
    params: DeleteSchedule.Params
  ): Promise<DeleteSchedule.Model> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<DeleteSchedule.Model>

    const query = deleteScheduleMutation(params)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: params.data
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as DeleteSchedule.Model
    }
  }

  async listSchedules(
    params: ListSchedules.Params,
    patient_id?: number,
    interval?: Interval
  ): Promise<SchedulesList> {
    const apiSchedulingRepository = this
      .apiSchedulingRepository as IApiSchedulingRepository<{
      data: SchedulesList
    }>

    const query = getPatientSchedulesQuery(params!)

    const httpResponse = await apiSchedulingRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(patient_id, 'patient_id'),
          ...makeGraphQLVariable(interval, 'interval')
        }
      }
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body?.data as ListSchedules.Model
    }
  }
}
