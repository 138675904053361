// import { AxiosResponse } from 'axios'
import {
  ApiSchedulingClient,
  ApiRequest,
  ApiResponse,
  ApiMethod
} from 'service/protocols/api-scheduling/api-scheduling-client'
import {
  IApiSchedulingRepository,
  RepositoryRequest
} from 'service/protocols/api-scheduling/api-scheduling-repository'

export class ApiSchedulingRepository<Model = any>
  implements IApiSchedulingRepository<Model>
{
  constructor(
    private readonly apiSchedulingClient: ApiSchedulingClient<Model>
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiSchedulingClient.request({
      ...data,
      method: ApiMethod.GET
    } as ApiRequest)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    const request = await this.apiSchedulingClient.request({
      ...data,
      method: ApiMethod.POST
    } as ApiRequest)

    if (request.body && data.query) {
      request.body = (request.body as any).data
        ? (request.body as any).data[data.query]
        : request.body
    }

    return request
  }

  async put(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiSchedulingClient.request({
      ...data,
      method: ApiMethod.PUT
    } as ApiRequest)
  }

  delete(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return this.apiSchedulingClient.request({
      ...data,
      method: ApiMethod.DELETE
    } as ApiRequest)
  }
}
