import React from 'react'
import { Signup } from 'domain/usecases/authentication/signup'
import PatientSignupLayout from 'presentation/layouts/Signup'
import { useLocation } from 'react-router-dom'

type PatientSignupProps = {
  useCase: Signup
}

export default function PatientSignup({ useCase }: PatientSignupProps) {
  const { state } = useLocation<AddPatientFormValues>()
  return (
    <PatientSignupLayout
      useCase={useCase}
      initialValues={{ ...state } as AddPatientFormValues}
      update={!!state}
    />
  )
}

export type AddPatientFormValues = {
  name: string
  phone: string
  cpf: string
  gender: string
  maritalStatus: string
  email: string
  password: string
  passwordConfirmation: string
  terms: boolean
}
