import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'

export enum SurgicalScheduleStatus {
  AUTHORIZED = 'AUTHORIZED',
  CANCELLED = 'CANCELLED',
  CONCLUDED = 'CONCLUDED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_REGISTERED = 'ORDER_REGISTERED',
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION'
}

export interface LoadPatientSurgicalOrders {
  load(
    params?: LoadPatientSurgicalOrders.Params
  ): Promise<LoadPatientSurgicalOrders.Model>
}

export namespace LoadPatientSurgicalOrders {
  export type Params = {
    statusStartDate?: string
    statusEndDate?: string
    status?: SurgicalScheduleStatus
    activeSearch?: boolean
  }

  export type Model = Array<SurgicalOrderModel>
}
