import { CancelExamSchedule } from 'domain/usecases/exam/cancel-exam-schedule'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteCancelExamSchedule implements CancelExamSchedule {
  constructor(private readonly examRepository: ExamRepository) {}
  async delete(
    params: CancelExamSchedule.Params
  ): Promise<CancelExamSchedule.Model> {
    return this.examRepository.cancelExamSchedule(params)
  }
}

export type CancelExamScheduleModel = CancelExamSchedule.Model
