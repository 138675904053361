import styled from 'styled-components'

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 -24px;
`

export const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  padding: 16px 24px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  &:not(:last-child):after {
    content: '';
    height: 100%;
    width: 2px;
    background-color: ${({ theme }) => theme.ds.colors.primaryLight100};
    position: absolute;
    left: 51px;
    bottom: calc(-50% - 24px);
    z-index: 2;
  }
  &:hover {
    background-color: ${({ theme, onClick }) =>
      onClick ? theme.ds.colors.primary25 : 'transparent'};
  }
`

export const ItemInfo = styled.div`
  padding-right: 24px;
  flex: 1;
  gap: 4px;
  display: flex;
  flex-direction: column;
`

export const Dot = styled.div`
  background-color: ${({ theme }) => theme.ds.colors.primary200};
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 2px 4px;
  display: inline-block;
`
