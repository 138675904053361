import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { toast } from 'react-toastify'
import SchedulingCancelForm from 'presentation/components/Forms/SchedulingCancelForm'
import { FormattedSchedule } from 'domain/entities/schedule-model'
import { useServices } from 'presentation/hooks/use-services'
import { SchedulingReasonCancelFormatted } from 'domain/entities/scheduling-reason-cancel'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import HeaderNew from 'presentation/components/HeaderNew'

type LocationType = {
  state: {
    schedule: FormattedSchedule
  }
}

const SchedulingCancelLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const location: LocationType = useLocation()
    const schedulingService = useServices().scheduling
    const scheduleItem = location.state && location.state.schedule
    const [scheduleReasons, setScheduleReasons] =
      useState<SchedulingReasonCancelFormatted[]>()

    const loadScheduleReasons = async () => {
      try {
        setIsLoading(true)
        const response = await schedulingService.getReasonCancel()

        // Capitalize the first letter of a string
        const formattedItems = response?.data?.getReasonCancel?.map((item) => {
          const str = item.name?.toLocaleLowerCase()
          const formatStr = str.charAt(0).toUpperCase() + str.slice(1)
          return {
            reason_cancel_id: item.reason_cancel_id,
            name: formatStr
          }
        })

        // Get reason 'outro'
        const formattedOtherReason = formattedItems.filter(
          (item) => item.name?.toLocaleUpperCase() === 'OUTRO'
        )

        // Move reason 'outro' to end of list
        formattedItems.push(
          formattedItems.splice(
            formattedItems.indexOf(formattedOtherReason[0]),
            1
          )[0]
        )

        setScheduleReasons(formattedItems)
      } catch (error) {
        toast.error(
          'Não foi possível cancelar o agendamento, tente novamente mais tarde.'
        )

        setTimeout(() => {
          history.push('/consultas')
        }, 1500)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      if (scheduleItem) {
        loadScheduleReasons()
      } else {
        history.push('/consultas')
      }
    }, [])

    return (
      <>
        <HeaderNew actualPageTitle="Cancelar consulta" />
        {scheduleItem && scheduleReasons && (
          <SchedulingCancelForm
            schedule={scheduleItem}
            scheduleReasons={scheduleReasons}
          />
        )}
      </>
    )
  }
)

export default SchedulingCancelLayout
