import React from 'react'
import * as S from './styles'
import AvatarNew from 'presentation/components/AvatarNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import CardNew from 'presentation/shared/components/CardNew'
import moment from 'moment-timezone'
import {
  getNameInitials,
  getShortName
} from 'presentation/utils/get-short-name'
import { Profile, ProfileLocated } from 'common/enum/profile'

type Props = {
  message: string
  createdAt: Date
  responsible: string
  profile?: string
  description?: string
}

const MessageCard = ({ message, createdAt, responsible, profile }: Props) => {
  return (
    <CardNew shadow="none" gap="8px" padding="8px">
      <S.MessageHeader>
        <AvatarNew
          text={getNameInitials(responsible)}
          color="primary600"
          active
          size="large"
        />
        <div>
          <div className="header-text">
            {profile === Profile.DOCTOR.toUpperCase() && (
              <SupportTextNew as="span" weight="bold" color="neutral900">
                Dr(a)
              </SupportTextNew>
            )}{' '}
            <SupportTextNew
              as="span"
              weight="bold"
              color="neutral900"
              capitalize
            >
              {getShortName(responsible).toLowerCase()}
            </SupportTextNew>
            <SupportTextNew as="span" weight="bold" color="neutral900">
              {profile
                ? ` - ${
                    ProfileLocated[
                      profile.toLocaleLowerCase() as keyof typeof ProfileLocated
                    ]
                  }`
                : ''}
            </SupportTextNew>
          </div>
          <SupportTextNew
            weight="semiBold"
            size="xsmall"
            color="neutral600"
            className="header-text"
          >
            {moment
              .utc(createdAt)
              .format('DD [de] MMM[. de] YYYY [às] HH[h]mm')}
          </SupportTextNew>
        </div>
      </S.MessageHeader>

      <S.MessageContent>
        <SupportTextNew color="neutral900">{message}</SupportTextNew>
      </S.MessageContent>
    </CardNew>
  )
}

export default MessageCard
