import React, { useEffect } from 'react'
import SchedulingNpsForm from 'presentation/components/Forms/SchedulingNpsForm'
import HeaderNew from 'presentation/components/HeaderNew'
import { SchedulingSchedule } from 'domain/entities/scheduling-schedule-model'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  sendRate: (params: Params) => void
  campaignFeature?: string
}

const SchedulingNpsLayout = ({ sendRate, campaignFeature }: Props) => {
  const history = useHistory()
  const location: LocationType = useLocation()
  const scheduleState =
    location.state && location.state?.state?.state.selectedSchedule
  const scheduleHourState =
    location?.state && location?.state?.state?.state.selectedHour
  const patientState = location?.state && location?.state?.state?.state.patient
  const healthInsuranceState =
    location?.state && location?.state?.state?.state.healthInsurance
  const hasParamTokenState = location?.state && location?.state?.hasParamToken

  useEffect(() => {
    if (campaignFeature === 'exam-schedule') {
      return
    } else if (
      !patientState ||
      !scheduleState ||
      !scheduleHourState ||
      !healthInsuranceState
    ) {
      history.push('/consultas')
    }
  }, [])
  return (
    <>
      {!hasParamTokenState && (
        <HeaderNew actualPageTitle="Avalie o agendamento" />
      )}
      <SchedulingNpsForm
        sendRate={sendRate}
        hasParamTokenState={hasParamTokenState}
      />
    </>
  )
}

export default SchedulingNpsLayout

type Params = {
  rate: number
  reasons: {
    name: string
    value: boolean
  }[]
  suggestion: string
}

type LocationType = {
  pathname: string
  state: {
    hasParamToken?: boolean
    state: {
      state: {
        filteredSearchResult: {
          data: {
            getSchedules: SchedulingSchedule[]
          }
        }
        selectedSchedule: SchedulingSchedule
        selectedHour: SchedulingHours
        patient: Patient
        healthInsurance: PatientHealthInsurance
      }
    }
  }
}
