import React, { useEffect, useRef, useState } from 'react'

import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import Button from 'presentation/components/Button'
import TextField from 'presentation/components/TextField'
import SheetModal from 'presentation/components/SheetModal'
import * as S from './styles'
import { ReactComponent as EmailIcon } from 'presentation/assets/icons/email.svg'
import { ReactComponent as PhoneIcon } from 'presentation/assets/icons/phone.svg'
import InterrogationIcon from 'presentation/assets/icons/interrogation.png'
import Modal from 'presentation/shared/components/Modal'
import SelectField from 'presentation/shared/components/SelectField'
import { Container } from 'presentation/components/Container'
import { cpfMask, dateMask } from 'presentation/utils/masks'
import { useServices } from 'presentation/hooks/use-services'
import normalizeText from 'common/utils/getNormalizedText'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import Countdown, {
  CountdownRefProps
} from 'presentation/shared/components/Countdown'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

const RegisterValidateForm = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const [mode, setShowMode] = useState<'cpf' | 'other'>('cpf')
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showSmsCountdown, setShowSmsCountdown] = useState<boolean>(false)
    const [showEmailCountdown, setShowEmailCountdown] = useState<boolean>(false)
    const [possibleMotherNames, setPossibleMotherNames] = useState<string[]>([])
    const [showRegisteredModal, setShowRegisteredModal] =
      useState<boolean>(false)
    const emailCountdown = useRef<CountdownRefProps>(null)
    const smsCountdown = useRef<CountdownRefProps>(null)
    const [hiddenData, setHiddenData] = useState<{
      email: string
      phone: string
    }>({ email: '', phone: '' })

    const patientService = useServices().patient
    const history = useHistory()

    const cpfForm = useFormik({
      initialValues: {
        cpf: ''
      },
      validationSchema: cpfValidationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        const cpf = values.cpf.replace(/\D+/g, '')

        try {
          setIsLoading(true)
          await patientService
            .loadPatientPossibleMotherName({
              cpf: cpfForm.values.cpf.replace(/\D+/g, '')
            })
            .then((res) => setPossibleMotherNames(res))

          const contact = await patientService
            .loadPatientHideContact({
              cpf,
              params: ['email', 'phone']
            })
            .then((res) => {
              if (!res.email && !res.phone) {
                history.push('/invalid-register')
              }
              return res
            })

          setHiddenData({
            email: contact.email ?? '',
            phone: contact.phone ?? ''
          })
          return openSheetModal()
        } catch (error) {
          history.push('/cadastro')
        } finally {
          setIsLoading(false)
        }
      }
    })

    const otherDataForm = useFormik({
      initialValues: {
        motherName: '',
        birthday: ''
      },
      validationSchema: otherValidationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          const cpf = cpfForm.values.cpf.replace(/\D+/g, '')
          const isCorrect =
            await patientService.validatePatientMotherAndBirthday({
              motherName: normalizeText(values.motherName).replaceAll(
                '\\s',
                ''
              ),
              birthday: moment(values.birthday, 'DD/MM/YYYY').toISOString(),
              patientCpf: cpf
            })
          if (isCorrect) {
            history.push('/cadastro', isCorrect)
          } else {
            history.push('/invalid-register')
          }
        } catch (error) {
          toast.error('Ocorreu um erro ao validar seu cadastro')
        }
      }
    })

    const closeSheetModal = () => {
      setShowModal(false)
    }

    const openSheetModal = () => {
      setShowModal(true)
    }

    const invitePatient = async (type: 'email' | 'sms') => {
      const cpf = cpfForm.values.cpf.replace(/\D+/g, '')
      try {
        if (showEmailCountdown && showSmsCountdown) {
          return
        }
        if (type === 'email' && !showEmailCountdown) {
          await patientService.invitePatientFromMv({ cpf, send: type })
          emailCountdown.current?.reset?.()
          setShowEmailCountdown(true)
        } else if (type === 'sms' && !showSmsCountdown) {
          await patientService.invitePatientFromMv({ cpf, send: type })
          smsCountdown.current?.reset?.()
          setShowSmsCountdown(true)
        }
      } catch (error: any) {
        closeSheetModal()
        toast.error(error.message)
      }
    }

    useEffect(() => {
      if (mode === 'other') {
        ;(async () => {
          await patientService
            .loadPatientPossibleMotherName({
              cpf: cpfForm.values.cpf.replace(/\D+/g, '')
            })
            .then((res) => setPossibleMotherNames(res))
        })()
      }
    }, [mode])

    return (
      <>
        <Container
          actualPageText="Voltar"
          actualPageOnClick={() => history.push('/login')}
          title="Validação de cadastro"
          subtitle="Informe os dados abaixo para realizar seu cadastro no Meu Mater Dei"
          justifyContent="center"
          primaryButton={
            <Button
              type="submit"
              fullWidth
              disabled={
                (mode === 'cpf' ? !cpfForm.isValid : !otherDataForm.isValid) ||
                cpfForm.isSubmitting
              }
            >
              Prosseguir
            </Button>
          }
          form
          onSubmit={
            mode === 'cpf' ? cpfForm.handleSubmit : otherDataForm.handleSubmit
          }
        >
          {mode === 'cpf' ? (
            <TextField
              label="CPF"
              name="cpf"
              onChange={cpfForm.handleChange}
              onBlur={cpfForm.handleBlur}
              error={cpfForm.touched.cpf && cpfForm.errors.cpf}
              value={cpfForm.values.cpf}
              mask={cpfMask}
            />
          ) : (
            <>
              <SelectField
                label="Nome da mãe"
                name="motherName"
                onChange={otherDataForm.handleChange('motherName')}
                onBlur={otherDataForm.handleBlur('motherName')}
                value={otherDataForm.values.motherName || ''}
                error={
                  otherDataForm.touched.motherName &&
                  otherDataForm.errors.motherName
                }
                items={
                  possibleMotherNames.map((name) => ({
                    label: name,
                    value: name
                  })) || []
                }
              />
              <TextField
                label="Data de nascimento"
                name="birthday"
                onChange={otherDataForm.handleChange}
                onBlur={otherDataForm.handleBlur}
                mask={dateMask}
                error={
                  otherDataForm.touched.birthday &&
                  otherDataForm.errors.birthday &&
                  'Data inválida'
                }
                style={{ marginTop: '8px' }}
              />
            </>
          )}
        </Container>
        <SheetModal
          isOpen={showModal}
          onClose={closeSheetModal}
          size={500}
          isDraggable
        >
          <S.ModalContentWrapper>
            <header>
              <h2>Cadastro Rede Mater Dei</h2>
              <span>
                Você já possui cadastro na Rede Mater Dei, escolha uma forma
                para prosseguir
              </span>
            </header>
            <S.RecoveryModeWrapper>
              {hiddenData.email && (
                <S.RecoveryModeItem
                  isActive={showEmailCountdown}
                  onClick={() => invitePatient('email')}
                >
                  <EmailIcon />
                  <div>
                    <span>E-mail</span>
                    <p>{hiddenData.email}</p>
                  </div>
                  <S.CountdownWrapper
                    style={{ display: showEmailCountdown ? 'flex' : 'none' }}
                  >
                    <Countdown
                      time={60}
                      onIsDone={() => setShowEmailCountdown(false)}
                      autoReset
                      ref={emailCountdown}
                    />
                  </S.CountdownWrapper>
                </S.RecoveryModeItem>
              )}

              {hiddenData.phone && (
                <S.RecoveryModeItem
                  isActive={showSmsCountdown}
                  onClick={() => invitePatient('sms')}
                >
                  <PhoneIcon />
                  <div>
                    <span>Celular</span>
                    <p>{'+' + hiddenData.phone}</p>
                  </div>
                  <S.CountdownWrapper
                    style={{ display: showSmsCountdown ? 'flex' : 'none' }}
                  >
                    <Countdown
                      time={60}
                      onIsDone={() => setShowSmsCountdown(false)}
                      autoReset
                      ref={smsCountdown}
                    />
                  </S.CountdownWrapper>
                </S.RecoveryModeItem>
              )}

              {possibleMotherNames.length > 0 && (
                <S.RecoveryModeItem
                  onClick={() => {
                    setShowMode('other')
                    closeSheetModal()
                  }}
                >
                  <img src={InterrogationIcon} />
                  <div style={{ justifyContent: 'center' }}>
                    <p></p>
                    <span>Tentar de outra forma</span>
                  </div>
                </S.RecoveryModeItem>
              )}
            </S.RecoveryModeWrapper>
            <Button type="button" onClick={closeSheetModal} fullWidth>
              Confirmar
            </Button>
          </S.ModalContentWrapper>
        </SheetModal>
        <Modal
          type="alert"
          title="Paciente já cadastrado na aplicação"
          show={showRegisteredModal}
          close={() => setShowRegisteredModal(false)}
        />
      </>
    )
  }
)

export default RegisterValidateForm

const cpfValidationSchema = yup.object().shape({
  cpf: yup.string().cpf().required()
})

const otherValidationSchema = yup.object().shape({
  motherName: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required()
})
