export enum DoctorRegistrationSteps {
  TERMS = 'TERMS',
  PERSONAL_DATA = 'PERSONAL_DATA',
  CONTACTS = 'CONTACTS',
  ADDRESS = 'ADDRESS',
  PROFESSIONAL_DATA = 'PROFESSIONAL_DATA',
  ACADEMIC_EDUCATION = 'ACADEMIC_EDUCATION',
  SPECIALTIES = 'SPECIALTIES',
  SECTORS_OF_ACTIVITY = 'SECTORS_OF_ACTIVITY',
  MEDICAL_PRIVILEGES = 'MEDICAL_PRIVILEGES',
  ATTENDANCES = 'ATTENDANCES',
  IMUNNIZATION = 'IMUNNIZATION',
  DOCUMENTS_AND_ATTACHMENTS = 'DOCUMENTS_AND_ATTACHMENTS',
  SOURCE_VALIDATION = 'SOURCE_VALIDATION',
  CNES = 'CNES',
  TEAMS = 'TEAMS'
}
