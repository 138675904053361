const emergencyReasons = [
  {
    value: 'missingScale',
    label:
      'Desfalque na escala de plantão, sem tempo hábil para realizar trocas entre a equipe ou realização do cadastro no tempo adequado.'
  },
  {
    value: 'scheduledSurgery',
    label: 'Cirurgia agendada para médico com pendência de cadastro.'
  },
  {
    value: 'invitedProfessional',
    label: 'Cadastro para Profissional Convidado.'
  },
  {
    value: 'directorsRequest',
    label: 'Pedido da Diretoria.'
  }
]

export function emergencyReasonTranslator(value: string): string {
  const option = emergencyReasons.find((option) => {
    return option.value === value
  })

  return option ? option.label : ''
}
