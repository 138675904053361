import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import { PainSlider } from 'presentation/shared/components/PainSlider'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import React, { useState, useEffect } from 'react'
import * as S from './styles'

type PainIntensityModalProps = {
  onConfirm: (value: number) => void
  onClose: () => void
  open: boolean
  intensity: number
}

export function PainIntensityModal({
  onClose,
  open,
  onConfirm,
  intensity
}: PainIntensityModalProps) {
  const [painIntensity, setPainIntensity] = useState(intensity || 4)
  useEffect(() => {
    if (intensity) {
      setPainIntensity(intensity)
    }
  }, [intensity])

  return (
    <SheetModal size={420} isOpen={open} onClose={onClose} backdrop="true">
      <div style={{ marginBottom: '20px', width: '250px' }}>
        <Heading size="large">Qual a intensidade da sua dor?</Heading>
      </div>
      <Container
        noPadding
        primaryButton={
          <Button fullWidth onClick={() => onConfirm(painIntensity)}>
            Confirmar
          </Button>
        }
        secondaryButton={
          <SupportText onClick={() => onConfirm(0)} color="red">
            Excluir
          </SupportText>
        }
      >
        <S.IntensityWrapper>
          <PainSlider
            value={painIntensity}
            onChange={(value) => setPainIntensity(value)}
          />
        </S.IntensityWrapper>
      </Container>
    </SheetModal>
  )
}
