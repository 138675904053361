import { RegisterPendencyRepository as IRegisterPendencyRepository } from 'repository/interfaces/register-pendency-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { createRegisterPendencyMutation } from 'repository/graphql/mutations'
import { CreateRegisterPendency } from 'domain/usecases/register-pendency/create-register-pendency'
import { LoadCurrentRegisterPendency } from 'domain/usecases/register-pendency/load-current-register-pendency'
import { loadDoctorCurrentPendencyQuery } from 'repository/graphql/queries'

export class RegisterPendencyRepository implements IRegisterPendencyRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async createRegisterPendency(
    data: CreateRegisterPendency.Params
  ): Promise<CreateRegisterPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateRegisterPendency.Model>

    const query = createRegisterPendencyMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(data, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateRegisterPendency.Model
    }
  }

  async loadCurrentRegisterPendency(
    params: LoadCurrentRegisterPendency.Params
  ): Promise<LoadCurrentRegisterPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadCurrentRegisterPendency.Model>

    const query = loadDoctorCurrentPendencyQuery(params)
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.doctor_id, 'doctor_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadCurrentRegisterPendency.Model
    }
  }
}
