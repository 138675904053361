import React from 'react'

import PDFViwer from 'presentation/layouts/PdfViwer'
import { RemoteLoadExamPDF } from 'service/usecases/load-exam-pdf-new/remote-load-exam-pdf-new'
import { makePatientRepository } from 'main/factories/pages/make-patient-repository-factory'

const ViwerExamResultPage = () => {
  const loadExamPdf = new RemoteLoadExamPDF(makePatientRepository())

  return <PDFViwer loadPDF={loadExamPdf} />
}

export default ViwerExamResultPage
