import { Doctor } from './doctor-model'
import { User } from './user-model'
import { ClassProps } from 'common/utils/getClassProps'
import { Type } from 'class-transformer'
import { Specialty } from 'domain/usecases/specialties/load-specialties'
import { emergencyReasonTranslator } from 'presentation/utils/translators/emergency-reason'

export class DoctorRecommendation {
  doctor_recommendation_id!: number
  name?: string
  email!: string
  crm!: string
  crmUf!: string
  specialty!: Specialty
  units!: string
  inviteToken!: string
  emergency!: boolean
  reasonEmergency?: string
  emergencyJustification?: string

  @Type(() => Date)
  createdAt!: Date

  @Type(() => Date)
  updatedAt!: Date

  recommended_doctor_id?: number
  recommended_by_id?: number
  recommendedBy!: User
  recommendedDoctor?: Doctor
  recommendedByDoctor?: Doctor

  constructor(props: DoctorRecommendationProps) {
    Object.assign(this, props)
  }

  get translatedEmergencyReason(): string {
    return emergencyReasonTranslator(this.reasonEmergency || '')
  }
}

type DoctorRecommendationProps = ClassProps<
  typeof DoctorRecommendation.prototype
>
