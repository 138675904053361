import React, { useState, useEffect, useContext } from 'react'
import { Patient, PatientHealthInsurance } from 'domain/entities/patient-model'
import * as yup from 'yup'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import ButtonNew from 'presentation/components/ButtonNew'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import { useStores } from 'presentation/hooks/use-stores'
import { useFormik } from 'formik'
import moment from 'moment'
import {
  formatTelephoneWithParenthesis,
  phoneMaskParenthesis
} from 'presentation/utils/masks'
import { ContainerNew } from 'presentation/components/ContainerNew'
import * as S from './styles'
import TextFieldNew from 'presentation/components/TextFieldNew'
import { CPFformatter } from 'common/utils/CPFformatter'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import { NavigationProps } from '../utils'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'
import 'yup-phone'

type Props = {
  setIsLoading: (value: boolean) => void
  hasParamToken?: boolean
}

const UpdatePersonalInfo = ({
  setIsLoading,
  next,
  prev,
  activeIndex,
  hasParamToken
}: WithLoadingProps & NavigationProps & Props) => {
  const patientService = useServices().patient
  const [patient, setPatient] = useState<Patient>()

  const currentAccount = useStores().currentAccount
  const user = currentAccount.getCurrentAccount().user
  const dependents = getFullDependentsList(user)
  // const [personalData, setPersonalData] = useState<SchedulingPatient>()

  const { dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      ...patient,
      birthday: patient?.birthday
        ? moment(patient?.birthday).utc().format('DD/MM/YYYY')
        : ''
    } as PatientInfoFormValues,
    onSubmit: async (values) => {
      const updatePatientData = {
        ...values,
        phone: values.phone?.replace(/\D+/g, ''),
        birthday: moment(values.birthday, 'DD/MM/YYYY').utc().toISOString()
      } as PatientInfoFormValues

      const patientData = {
        patient_id: patient?.patient_id,
        user_id: patient?.user_id,
        email: patient?.email,
        name: patient?.name,
        age:
          Number(
            getAgeByBirthdayDate(moment(patient?.birthday).format('DD/MM/YYYY'))
          ) || undefined,
        gender: patient?.gender
      }

      if (
        patient?.email !== updatePatientData.email ||
        patient?.phone !== updatePatientData.phone
      ) {
        try {
          setIsLoading(true)
          delete updatePatientData.cpf
          delete updatePatientData.healthInsurances
          delete updatePatientData.patient_id
          delete updatePatientData.user_id
          await patientService.updatePatientInfo(updatePatientData)

          if (patient) {
            setPatient({
              ...patient,
              phone: updatePatientData.phone,
              email: updatePatientData.email
            })
          }

          toast.success('Dados atualizados')
          next()
        } catch (error: any) {
          toast.error(error.message)
          setIsLoading(false)
          return
        }
      }

      dispatch({
        type: 'UPDATE_PATIENT_DATA',
        payload: patientData
      })
      setIsLoading(false)
      next()
    }
  })

  const hasDependents = () => {
    return dependents.length > 0
  }

  const handleChangeDependent = (user_id: number) => {
    if (user_id !== user.user_id) {
      setIsLoading(true)
      currentAccount
        .setCurrentDependent(user_id)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao mudar de dependente')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const loadPatientData = async () => {
    try {
      setIsLoading(true)
      const response = await patientService.loadPatientInfo([
        'patient_id',
        'user_id',
        'name',
        'birthday',
        'gender',
        'phone',
        'email',
        'cpf',
        `
          healthInsurances {
            health_insurance_id
            healthCard
            healthInsuranceCode
            healthInsuranceName
            healthPlanCode
            healthPlanName
            healthSubPlanCode
            healthSubPlanName
            ansRegister
            validThru
          }
          `
      ])
      setPatient(response)
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar os dados')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (activeIndex === 1) {
      loadPatientData()

      formik.setFieldValue('phone', patient?.phone)
      formik.setFieldValue('email', patient?.email)
    }
  }, [activeIndex])

  return (
    <ContainerNew noHeader>
      {patient && (
        <S.FormWrapper onSubmit={formik.handleSubmit}>
          {hasDependents() && !hasParamToken && (
            <S.Row cols={1} style={{ marginTop: '8px' }}>
              <SelectFieldNew
                round
                label="Para quem é esse exame"
                name="current_user"
                items={dependents?.map(({ name, user_id }) => ({
                  label: name,
                  value: user_id
                }))}
                value={user.user_id}
                onInputChange={(e) => handleChangeDependent(Number(e))}
              />
            </S.Row>
          )}

          <ExamCard
            patientName={patient.name}
            patientCpf={patient.cpf}
            patientBirthday={patient.birthday}
            patientGender={patient.gender}
          />

          <S.PurpleStrong>Informações de contato</S.PurpleStrong>

          <S.Description>
            Confirme seu contato para receber atualizações sobre seu
            agendamento.
          </S.Description>

          <S.Row cols={1}>
            <TextFieldNew
              label="Telefone"
              name="phone"
              mask={phoneMaskParenthesis}
              value={formatTelephoneWithParenthesis(formik?.values?.phone)}
              onInputChange={formik.handleChange('phone')}
              error={formik.touched.phone && formik.errors.phone}
              required
              requiredColor="neutral800"
            />
          </S.Row>
          <S.Row cols={1}>
            <TextFieldNew
              label="E-mail"
              name="email"
              value={formik.values.email}
              onInputChange={formik.handleChange('email')}
              error={formik.touched.email && formik.errors.email}
              required
              requiredColor="neutral800"
            />
          </S.Row>
          <S.ButtonPanel>
            <ButtonNew
              color="primary"
              type="submit"
              fullWidth
              size="large"
              disabled={formik.isSubmitting}
              onClick={() => formik.handleSubmit()}
            >
              Próximo
            </ButtonNew>
            <ButtonNew
              type="button"
              outlined
              onClick={prev}
              fullWidth
              size="large"
            >
              Anterior
            </ButtonNew>
          </S.ButtonPanel>
        </S.FormWrapper>
      )}
    </ContainerNew>
  )
}

type PatientInfoFormValues = {
  patient_id?: number | undefined
  user_id?: number | undefined
  name: string
  birthday?: string
  age: number | undefined
  gender: string
  email: string
  cpf?: string
  phone: string
  healthInsurances?: PatientHealthInsurance[]
}

export default UpdatePersonalInfo

const ExamCard = ({
  patientName,
  patientCpf,
  patientBirthday,
  patientGender
}: any) => {
  return (
    <>
      <S.CardContainer>
        <S.CardLabel>Nome</S.CardLabel>
        <S.CardTextValue>{patientName}</S.CardTextValue>

        <S.CardLabel>CPF</S.CardLabel>
        <S.CardTextValue>{CPFformatter(String(patientCpf))}</S.CardTextValue>

        <S.CardLabel>Data de nascimento</S.CardLabel>
        <S.CardTextValue>
          {moment(patientBirthday).utc().format('DD/MM/YYYY')}
        </S.CardTextValue>

        <S.CardLabel>Sexo biológico</S.CardLabel>
        <S.CardTextValue>{patientGender}</S.CardTextValue>
      </S.CardContainer>
    </>
  )
}

const countryCode = 'BR'
const strict = true
const errorMessage = 'Telefone inválido'

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup.string().phone(countryCode, strict, errorMessage).required()
})
