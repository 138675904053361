import { RenewEmergencyToken } from 'domain/usecases/renew-emergency-token/renew-emergency-token'
import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'

export class RemoteRenewEmergencyToken implements RenewEmergencyToken {
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  renew(
    params: RenewEmergencyToken.Params,
    emergencyTokenId: number
  ): Promise<RenewEmergencyToken.Model> {
    return this.emergencyRoomRepository.renewEmergencyToken(
      params,
      emergencyTokenId
    )
  }
}

export type RenewEmergencyTokenModel = RenewEmergencyToken.Model
