import React, { useState, useEffect } from 'react'

import * as S from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/download-square.svg'
import { ReactComponent as BackArrowIcon } from 'presentation/assets/icons/backArrow.svg'

import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import HeaderNew from 'presentation/components/HeaderNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ILoadExamResultPDF } from 'domain/usecases/exam/load-exam-result-pdf'
import { ExamType } from 'common/enum/exam-type'

type LocationType = {
  title: string
  attendance_id: number
  exam_order_id: number
  exam_order_item_id?: number
  type: ExamType
  // pdfURL: string
  // token?: string
}

type Props = {
  loadPDF: ILoadExamResultPDF
} & WithLoadingProps

const PDFViwer = WithLoading(({ loadPDF, setIsLoading }: Props) => {
  const history = useHistory()
  const { attendance_id, exam_order_id, exam_order_item_id, type } =
    useLocation<LocationType>().state
  const [documentRaw, setDocumentRaw] = useState<Blob>()
  const [document, setDocument] = useState<string>('')

  const getFile = async () => {
    try {
      setIsLoading(true)
      const response = await loadPDF.load({
        attendance_id,
        exam_order_id,
        exam_order_item_id,
        type
      })

      setDocumentRaw(response)
      const file = new Blob([response], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      setDocument(fileURL)
    } catch (error: any) {
      toast.error(error?.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getFile()
  }, [])

  const downloadFile = (documentRaw: any) => {
    downloadFileFromBlob(documentRaw, documentRaw.type, 'meu-exame-materdei')
  }

  // Need backend refact:
  // const shareExam = () => {
  //   history.push({
  //     pathname: '/exame/resultado/compartilhar',
  //     state: {
  //       title,
  //       attendance_id,
  //       exam_order_id,
  //       exam_order_item_id
  //     }
  //   })
  // }

  return (
    <>
      <HeaderNew />
      <S.Wrapper>
        {documentRaw && (
          <S.Options position="top">
            <button onClick={() => history.goBack()}>
              <BackArrowIcon width={'15px'} />
            </button>

            <div className="navigation">
              <div className="file">
                <button onClick={() => downloadFile(documentRaw)}>
                  <DownloadIcon width={'15px'} />
                </button>

                {/* Need backend refact: */}
                {/* <button onClick={() => shareExam()}>
                  <SharedIcon width={'18px'} />
                </button> */}
              </div>
            </div>
          </S.Options>
        )}

        <S.Content sHeight="85vh" sWidth="100%">
          {document && (
            <object data={document} type="application/pdf">
              <div className="mobile-visualization-option">
                <SupportTextNew color="neutral800" weight="semiBold">
                  Não consegue visualizar o documento?
                </SupportTextNew>
                <SupportTextNew size="xsmall" style={{ marginTop: '8px' }}>
                  Se você está tendo problemas para visualizar o documento
                  diretamente nesta página, clique no link abaixo para
                  acessá-lo:
                </SupportTextNew>
                <SupportTextNew size="small" style={{ marginTop: '16px' }}>
                  <a
                    href={document}
                    title="Visualizar exame"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#038E83' }}
                  >
                    Clique aqui para visualizar o exame
                  </a>
                </SupportTextNew>
              </div>
            </object>
          )}
        </S.Content>
      </S.Wrapper>
    </>
  )
})

export default PDFViwer
