import styled from 'styled-components'

export const SymptomsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px 20px 20px;
  height: 100%;

  gap: 20px;

  strong {
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.gray};
  }

  input[type='checkbox'] {
    border-width: 1px;
  }
`

export const ActualPage = styled.div`
  margin-top: 14px;
`

export const SelectUnitChips = styled.div`
  display: flex;
  gap: 20px;
  div div label {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.primary};
    background: transparent;
  }
`

export const SelectUnitChipsGrid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 16px;
  row-gap: 24px;
`

type PainChipProps = {
  selected: boolean
}

export const PainChip = styled.div<PainChipProps>`
  display: flex;
  padding: 11px 18px 11px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary : theme.colors.lightGray};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.semiBold};
  width: 100%;
  border: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.secondary}`
      : `1px solid ${theme.colors.lightGray}`};
  border-radius: ${({ theme }) => theme.border.radius.small};
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
  }
`

type PainIconProps = {
  selected?: boolean
}

export const PainChipIcon = styled.div<PainIconProps>`
  width: 28px;
  height: 30px;
  margin-right: 12px;
  ellipse {
    fill: ${({ theme, selected }) =>
      selected ? theme.colors.secondary : theme.colors.lightGray};
  }
  path {
    fill: ${({ theme, selected }) =>
      selected ? theme.colors.secondary : theme.colors.lightGray};
  }
  .stroke {
    fill: none;
    stroke: ${({ theme, selected }) =>
      selected ? theme.colors.secondary : theme.colors.lightGray};
  }
  .golden {
    fill: ${({ theme, selected }) =>
      selected ? theme.colors.golden : theme.colors.lightGray};
  }
`

export const PainChipIntensity = styled.div`
  width: auto;
  background-color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  padding: 1px 12px 1px 12px;
  border-radius: ${({ theme }) => theme.border.radius.small};
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.font.bold};
`
