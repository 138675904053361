import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import AmbulatoryService from 'presentation/contexts/services/ambulatory-service'
import { ApiRepository } from 'repository/api-repository'
import { AmbulatoryRepository } from 'repository/repositories/ambulatory/ambulatory-repository'
import { RemoteAmbulatoryContact } from 'service/usecases/ambulatory-contact/remote-ambulatory-contact'
import { RemoteGetAmbulatorySchedulePanelOrganized } from 'service/usecases/get-ambulatory-panel-organized/remote-get-ambulatory-panel-organized'
import { RemoteSyncAmbulatoryToken } from 'service/usecases/sync-ambulatory-token/remote-sync-ambulatory-token'

export default function makeAmbulatoryService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const ambulatoryRepository = new AmbulatoryRepository(apiRepository)

  const ambulatoryContact = new RemoteAmbulatoryContact(ambulatoryRepository)

  const syncAmbulatoryToken = new RemoteSyncAmbulatoryToken(
    ambulatoryRepository
  )

  const getAmbulatorySchedulePanelOrganized =
    new RemoteGetAmbulatorySchedulePanelOrganized(ambulatoryRepository)

  return new AmbulatoryService({
    ambulatoryContact,
    syncAmbulatoryToken,
    getAmbulatorySchedulePanelOrganized
  })
}
