import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import PatientHome from 'presentation/pages/Home'
import React from 'react'
import { ApiRepository } from 'repository/api-repository'
import { EmergencyRoomRepository } from 'repository/repositories/emergency-room-atendance/emergency-room-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemoteGetPatientNextAttendances } from 'service/usecases/get-patient-next-attendances/remote-get-patient-next-attendances'
import { RemoteLoadExams } from 'service/usecases/load-exams/remote-load-exams'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import { RemoteLoadPatientSurgery } from 'service/usecases/load-patient-surgery/remote-load-patient-surgery'
import { RemoteLoadPatientSurgicalOrders } from 'service/usecases/load-patient-surgical-orders/remote-load-patient-surgical-orders'
import { RemoteLoadSurgicalOrderStatus } from 'service/usecases/load-surgical-order-status/remote-load-surgical-order-status'
import { RemoteRenewEmergencyToken } from 'service/usecases/renew-emergency-token/remote-renew-emergency-token'

export default function makePatientHome() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const patientRepository = new PatientRepository(apiRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const emergencyRoomRepository = new EmergencyRoomRepository(apiRepository)

  const loadPatientSurgery = new RemoteLoadPatientSurgery(patientRepository)
  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const loadSurgicalOrderStatus = new RemoteLoadSurgicalOrderStatus(
    surgicalOrderRepository
  )
  const renewEmergencyToken = new RemoteRenewEmergencyToken(
    emergencyRoomRepository
  )

  const loadExams = new RemoteLoadExams(patientRepository)

  const getPatientNextAttendances = new RemoteGetPatientNextAttendances(
    patientRepository
  )

  const loadPatientSurgicalOrders = new RemoteLoadPatientSurgicalOrders(
    patientRepository
  )

  return (
    <PatientHome
      loadExams={loadExams}
      loadPatientSurgery={loadPatientSurgery}
      loadPatientInfo={loadPatientInfo}
      loadSurgicalOrderStatus={loadSurgicalOrderStatus}
      renewEmergencyToken={renewEmergencyToken}
      getPatientNextAttendances={getPatientNextAttendances}
      loadPatientSurgicalOrders={loadPatientSurgicalOrders}
    />
  )
}
