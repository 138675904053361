import { ILoadExamResultPDF } from 'domain/usecases/exam/load-exam-result-pdf'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadExamPDF implements ILoadExamResultPDF {
  constructor(private readonly patientRepository: PatientRepository) {}
  async load(
    params: ILoadExamResultPDF.Params
  ): Promise<ILoadExamResultPDF.Model> {
    return this.patientRepository.loadExamPDF(params)
  }
}
