import React from 'react'
import ServiceSolicitationOverviewLayout from 'presentation/layouts/ServiceSolicitationOverview'
import { CancelEmergencyRoomAttendance } from 'domain/usecases/emergency-room/cancel-emergency-room-attendance'

type ServiceSolicitationOverviewProps = {
  cancelEmergencyRoomAttendance: CancelEmergencyRoomAttendance
}

export default function ServiceSolicitationOverview({
  cancelEmergencyRoomAttendance
}: ServiceSolicitationOverviewProps) {
  return (
    <ServiceSolicitationOverviewLayout
      cancelEmergencyRoomAttendance={cancelEmergencyRoomAttendance}
    />
  )
}
