import { CreateEmergencyRoom } from 'domain/usecases/emergency-room/create-emergency-room'
import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'

export class RemoteCreateEmergencyRoom implements CreateEmergencyRoom {
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  add(params: CreateEmergencyRoom.Params): Promise<CreateEmergencyRoom.Model> {
    return this.emergencyRoomRepository.createEmergencyRoom(params)
  }
}

export type CreateEmergencyRoomModel = CreateEmergencyRoom.Model
