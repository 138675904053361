import { ApiSchedulingExamImplementation } from 'infra/api-scheduling-exams/api-scheduling-exams-client'
import { PrepareApiRequestSchedulingExamDecorator } from 'main/decorators/prepare-api-request-scheduling-exams-decorator/prepare-api-request-scheduling-exams-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { RemoteSearchExamDoctors } from 'service/usecases/search-exams copy/remote-search-exam-doctors'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'
import ExamService from 'presentation/contexts/services/exam-service'
import { RemoteGetPatientExamAppointments } from 'service/usecases/patient-get-exam-appointments/remote-patient-load-exams'
import { RemoteSearchExams } from 'service/usecases/search-exams/remote-search-exams'
import { RemoteGetExamScheduleOffersByMonth } from 'service/usecases/get-exam-schedule-offers-by-month/remote-get-exam-schedule-offers-by-month'
import { RemoteGetExamScheduleHours } from 'service/usecases/get-exam-schedule-hours/remote-get-exam-schedule-hours'
import { RemoteGetExamAvailableDates } from 'service/usecases/get-exam-available-dates/remote-get-exam-available-dates'
import { RemoteCreatePatientExamAppointments } from 'service/usecases/patient-create-exam-appointments/remote-patient-create-exams-appointments'
import { RemoteGetExamReasonCancel } from 'service/usecases/get-exam-reason-cancel/remote-get-exam-reason-cancel'
import { RemoteCancelExamSchedule } from 'service/usecases/cancel-exam-schedule/remote-cancel-exam-schedule'
import { RemoteCheckResourceSchedule } from 'service/usecases/check-resource-schedule/remote-check-resource-schedule'
import { RemoteGetExamPreparation } from 'service/usecases/get-exam-preparation/remote-get-exam-preparation'
import { RemoteGetExamByItem } from 'service/usecases/get-exam-by-item/remote-get-exam-by-item'
import { RemoteGetExamAvailableUnits } from 'service/usecases/get-exam-available-units/remote-get-exam-available-units'
import { RemoteGetExamAvailableHospitals } from 'service/usecases/get-exam-available-hospitals/remote-get-exam-available-hospitals'
import { RemoteGetExamPreparationWithParams } from 'service/usecases/get-exam-preparation-with-params/remote-get-exam-preparation-with-params'
import { RemoteGetExamGroupOrdered } from 'service/usecases/get-exam-group-ordered/remote-get-exam-group-ordered'

export default function makePatientService() {
  const apiSchedulingExamRepository =
    new PrepareApiRequestSchedulingExamDecorator(
      makeLocalStorageAdapter(),
      new ApiRepository(new ApiSchedulingExamImplementation())
    )

  const examRepository = new ExamRepository(apiSchedulingExamRepository)

  const getPatientExamAppointments = new RemoteGetPatientExamAppointments(
    examRepository
  )

  const searchExams = new RemoteSearchExams(examRepository)

  const getExamScheduleOffersByMonth = new RemoteGetExamScheduleOffersByMonth(
    examRepository
  )

  const getExamScheduleHours = new RemoteGetExamScheduleHours(examRepository)

  const getExamAvailableDates = new RemoteGetExamAvailableDates(examRepository)

  const createPatientExamAppointments = new RemoteCreatePatientExamAppointments(
    examRepository
  )

  const searchExamDoctors = new RemoteSearchExamDoctors(examRepository)

  const getExamReasonCancel = new RemoteGetExamReasonCancel(examRepository)

  const cancelExamSchedule = new RemoteCancelExamSchedule(examRepository)

  const checkResourceSchedule = new RemoteCheckResourceSchedule(examRepository)

  const getExamPreparation = new RemoteGetExamPreparation(examRepository)

  const getExamByItem = new RemoteGetExamByItem(examRepository)

  const getExamAvailableUnits = new RemoteGetExamAvailableUnits(examRepository)

  const getExamAvailableHospitals = new RemoteGetExamAvailableHospitals(
    examRepository
  )

  const getExamPreparationWithParams = new RemoteGetExamPreparationWithParams(
    examRepository
  )

  const getExamGroupOrdered = new RemoteGetExamGroupOrdered(examRepository)

  return new ExamService({
    searchExamDoctors,
    getPatientExamAppointments,
    searchExams,
    getExamScheduleOffersByMonth,
    getExamScheduleHours,
    getExamAvailableDates,
    createPatientExamAppointments,
    getExamReasonCancel,
    cancelExamSchedule,
    checkResourceSchedule,
    getExamPreparation,
    getExamByItem,
    getExamAvailableUnits,
    getExamAvailableHospitals,
    getExamPreparationWithParams,
    getExamGroupOrdered
  })
}
