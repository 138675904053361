export enum PatientDocument {
  HEALTH_CARD = 'Carteira do convênio',
  HEALTH_CARD_FRONT = 'Carteira do convênio - Frente',
  HEALTH_CARD_BACK = 'Carteira do convênio - Verso',
  MEDICAL_REPORT = 'Relatório Médico',
  IDENTITY_CARD = 'Carteira de identidade',
  IDENTITY_CARD_FRONT = 'Carteira de identidade - Frente',
  IDENTITY_CARD_BACK = 'Carteira de identidade - Verso',
  SERVICE_AGREEMENT = 'Acordo de serviço',
  AUTHORIZATION = 'Autorização',
  SURGICAL_PLANNING = 'Planejamento cirúrgico',
  SURGICAL_CONSENT = 'Consent. cirúrgico',
  ANESTHETIC_CONSENT = 'Consent. anestésico',
  PRE_ANESTHETIC_CONSENT = 'Consulta pré-anestésica',
  PRE_ANESTHETIC_EVALUATION = 'Avaliação pré-anestésica',
  HEMOTHERAPY_CONSENT = 'Consent. de hemoterapia',
  GUIDE = 'GUIA',
  SURGICAL_RISK = 'Risco cirúrgico',
  SURGICAL_ORDER = 'Pedido cirúrgico',
  MEDICAL_ORDER = 'Pedido médico',
  EXAM_MEDICAL_ORDER = 'EXAME - Pedido médico',
  EXAM_REPORT = 'Laudos dos exames',
  EXAM_DOCUMENT = 'Documento do Exame',
  IMAGE_REPORT = 'Laudo de Imagem',
  CONSENT_TERM = 'Termo de consentimento',
  ALL_GUIDANCES = 'Todas as orientações',
  PATIENT_DOCUMENT = 'Documento do paciente',
  AUTHORIZATION_GUIDE = 'Guia de autorização',
  BIOPSY = 'Biópsia',
  BUDGET = 'Orçamento',
  INFORMED_CONSENT = 'Consent. informado',
  GUIDE_SPSADT = 'GUIA SADT',
  GUIDE_OPME = 'GUIA DE OPME',
  GUIDE_HOSPITALIZATION_REQUEST = 'GUIA SOLICITAÇÃO DE INTERNAÇÃO',
  GUIDE_SURGICAL_PLANNING = 'PLANEJAMENTO CIRÚRGICO',
  GUIDE_SURGICAL_ORDER = 'GUIA DE PEDIDO CIRÚRGICO'
}
