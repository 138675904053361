import { CheckResourceSchedule } from 'domain/usecases/exam/check-resource-schedule'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteCheckResourceSchedule implements CheckResourceSchedule {
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: CheckResourceSchedule.Params
  ): Promise<CheckResourceSchedule.Model> {
    return this.examRepository.checkResourceSchedule(params)
  }
}

export type CheckResourceScheduleModel = CheckResourceSchedule.Model
