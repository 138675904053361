import React from 'react'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import EditIcon from 'presentation/assets/icons/edit.svg'
import * as S from './style'
import TextField from 'presentation/components/TextField'
import { useFormik } from 'formik'
import Button from 'presentation/components/Button'
import Checkbox from 'presentation/shared/components/Checkbox'
import * as Yup from 'yup'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { toast } from 'react-toastify'
import { useHistory, useLocation } from 'react-router'
import { UpdateExam } from 'domain/usecases/patient/update-exam'

type Props = {
  currentEmail: string
  updateUserEmail: UpdatePatientInfo
  updateExam: UpdateExam
}

type ILocation = {
  exam_id: number
}

export default function EmailConfirmationForm({
  updateExam,
  currentEmail,
  updateUserEmail
}: Props) {
  const [correctlyEmail, setCorrectlyEmail] = React.useState(false)
  const [enableEditOption, setEnableEditOpption] = React.useState(false)

  const { state } = useLocation<ILocation>()

  const history = useHistory()

  const checkExamVisualization = async () => {
    await updateExam.update({
      visualized: true,
      exam_id: state.exam_id
    })
  }

  const formik = useFormik({
    initialValues: {
      email: currentEmail
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      try {
        if (email !== currentEmail) {
          await updateUserEmail.update({ email })
        }
        await checkExamVisualization()
        history.push({
          pathname: '/home',
          state: {
            confirmated: true,
            ...state
          }
        })
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  return (
    <Container
      actualPageText="Voltar"
      actualPageOnClick={() => history.goBack()}
    >
      <S.Wrapper>
        <S.Content>
          <Heading as="span" size="medium" color="gray" role="heading">
            Este é o seu e-mail?
          </Heading>

          <p>
            Você receberá os as instruções de como se preparar para seu exame
            por e-mail.
          </p>

          <p className="p-strong">Confira e corrija, caso estiver incorreto</p>
        </S.Content>

        <S.Form onSubmit={formik.handleSubmit} role="form">
          <div>
            <TextField
              role="input-email-confirmation"
              type="email"
              disabled={!enableEditOption}
              name="email"
              onInputChange={formik.handleChange('email')}
              value={formik.values.email}
              onBlur={formik.handleBlur('email')}
              error={formik.errors.email}
            />

            <S.EditButton
              role="edit-button"
              type="button"
              onClick={() => setEnableEditOpption(!enableEditOption)}
            >
              <img src={EditIcon} alt="" />
              editar
            </S.EditButton>
          </div>

          <div className="options-form">
            <Checkbox
              role="checkbox-conclude"
              value={formik.values.email}
              label="Meu e-mail está correto"
              onCheck={() => setCorrectlyEmail(!correctlyEmail)}
              checked={correctlyEmail}
              labelColor="primary"
              isChecked={correctlyEmail}
              style={{ marginBottom: '18px' }}
              disabled={Boolean(formik.errors.email)}
            />
            <Button
              role="submit-button"
              fullWidth
              onClick={() => formik.submitForm}
              disabled={!correctlyEmail || !!formik.errors.email}
            >
              Concluir
            </Button>
          </div>
        </S.Form>
      </S.Wrapper>
    </Container>
  )
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Dado obrigatório')
    .email('Endereço de e-mail inválido')
})
