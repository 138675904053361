/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermUseHospitalEquipment() {
  return {
    name: TermsTypeEnum.TERM_USE_HOSPITAL_EQUIPMENT,
    title: 'Termo de Uso de Equipamento Hospitalar',
    content: (
      <>
        <div>
          <p>
            Declaro estar ciente e concordo com as regras aqui estabelecidas
            para o uso de equipamentos de minha propriedade na Rede Mater Dei de
            Saúde.
          </p>

          <p>
            O uso do equipamento médico próprio do profissional só é permitido
            após avaliação e autorização da Engenharia Clínica da Rede.
          </p>

          <p>
            Desta forma, para solicitação de uso destes equipamentos, é
            necessário enviar e-mail à engclinica.bc@materdei.com.br com a
            solicitação, indicando o nome e telefone do profissional, a fim de
            que possamos entrar em contato para validação da documentação e
            realizar o cadastro do equipamento a ser utilizado.
          </p>

          <p>
            O cadastro e a verificação da documentação são imprescindíveis para
            mantermos uma assistência segura aos clientes da Rede, cumprindo a
            missão de "Compromisso com a Qualidade pela Vida".
          </p>

          <p>
            Define-se por equipamentos Médicos Hospitalares: Os equipamentos
            médicos sob regime de Vigilância Sanitária compreendendo todos os
            equipamentos de uso em saúde com finalidade médica, odontológica,
            laboratorial ou fisioterápica, utilizados direta ou indiretamente
            para diagnóstico, terapia, reabilitação ou monitorização de seres
            humanos e, ainda, os com finalidade de embelezamento e estética.
          </p>
        </div>
      </>
    ),
    acceptMessage:
      'Declaro que li e aceito as condições do Termo de Uso de Equipamento Hospitalar'
  }
}
