import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemotePatientUpdateInfoById implements UpdatePatientInfoById {
  constructor(private readonly patientRepository: PatientRepository) {}

  update(
    params: UpdatePatientInfoById.Params
  ): Promise<UpdatePatientInfoById.Model> {
    return this.patientRepository.updatePatientInfoById(params)
  }
}

export type PatientUpdateInfoByIdModel = UpdatePatientInfoById.Model
