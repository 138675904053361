import { CreateEmergencyRoomInMv } from 'domain/usecases/emergency-room/create-emergency-room-in-mv'
import { GetEmergencyRoomByToken } from 'domain/usecases/emergency-room/get-emergency-room-by-token'
import { LoadEmergencyRoomByHospital } from 'domain/usecases/emergency-room/load-emergency-room-by-hospital'
import { makeAutoObservable } from 'mobx'

type Services = {
  loadEmergencyRoomByHospital: LoadEmergencyRoomByHospital
  getEmergencyRoomByToken: GetEmergencyRoomByToken
  createEmergencyRoomInMV: CreateEmergencyRoomInMv
}

export class EmergencyRoomService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadEmergencyRoomByHospital(
    params: LoadEmergencyRoomByHospital.Params
  ): Promise<LoadEmergencyRoomByHospital.Model> {
    return this.services.loadEmergencyRoomByHospital.load(params)
  }

  async getEmergencyRoomByToken(
    params: GetEmergencyRoomByToken.Params
  ): Promise<GetEmergencyRoomByToken.Model> {
    return this.services.getEmergencyRoomByToken.get(params)
  }

  async createEmergencyRoomInMV(
    params: CreateEmergencyRoomInMv.Params
  ): Promise<CreateEmergencyRoomInMv.Model> {
    return this.services.createEmergencyRoomInMV.create(params)
  }
}

export default EmergencyRoomService
