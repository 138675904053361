import React from 'react'

import { Header } from 'presentation/components/Header'
import { Container } from 'presentation/components/Container'
import BottomMenu from 'presentation/components/BottomMenu'
import { UpdateLifeHabits } from 'domain/usecases/patient/update-life-habits'
import LifeHabitsForm from 'presentation/components/Forms/LifeHabits'

type Props = {
  updateLifeHabits?: UpdateLifeHabits
}

export default function LifeHabitsLayout({ updateLifeHabits }: Props) {
  return (
    <>
      <Header />
      <Container hasHeader>
        <LifeHabitsForm updateLifeHabits={updateLifeHabits} />
      </Container>
      <BottomMenu />
    </>
  )
}
