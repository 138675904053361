export default function parseParams(params: any) {
  let options = ''

  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      for (const element of value) {
        options += `${key}=${element}&`
      }
    } else if (typeof value === 'object') {
      for (const [objKey, objValue] of Object.entries(value!)) {
        options += `${key}[${objKey}]=${objValue}&`
      }
    } else {
      options += `${key}=${value}&`
    }
  }

  return options ? options.slice(0, -1) : options
}
