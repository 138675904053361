import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 24px;
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const PurpleStrong = styled.strong`
  color: ${({ theme }) => theme.ds.colors.secondary700};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const CardsList = styled.div`
  /* height: 550px;
  overflow-y: scroll; */
`

export const HeaderContainer = styled.div`
  margin-bottom: 24px;
`

export const EmptyStateContainer = styled.div`
  display: flex;
  margin: auto;
  place-content: center;
`

export const AlertCard = styled.div`
  display: flex;
  gap: 10px;
  border-radius: 8px;
  padding: 18px;
  background-color: ${({ theme }) => theme.ds.colors.danger25};
  border: 1px solid ${({ theme }) => theme.ds.colors.danger300};

  span {
    margin-top: -3px;
  }
`
