import {
  SurgicalOrderStatus,
  TimelineStatus
} from 'presentation/shared/components/Timeline/status'

export function getStatusInfo(status?: string):
  | {
      label: string
      status: SurgicalOrderStatus[] | string[]
      color: string
    }
  | undefined {
  return TimelineStatus.find((timelineStatus) => {
    return timelineStatus.status.find((val) => val === status)
  })
}
