import { GetPatientExamAppointments } from 'domain/usecases/patient/get-exam-appointments'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetPatientExamAppointments
  implements GetPatientExamAppointments
{
  constructor(private readonly examRepository: ExamRepository) {}
  async load(
    params: GetPatientExamAppointments.Params
  ): Promise<GetPatientExamAppointments.Model> {
    return this.examRepository.getPatientExamAppointments(params)
  }
}

export type GetExamAppointmentsModel = GetPatientExamAppointments.Model
