import React, { useContext, useEffect, useState } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import * as S from './styles'
import ButtonNew from 'presentation/components/ButtonNew'
import DocumentAccordion from 'presentation/components/DocumentAccordeon'
import { renameFile } from 'common/utils/file/renameFile'
import { generateHash } from 'common/utils/generateHash'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import downloadFileFromBlob from 'common/utils/downloadFileFromBlob'
import { NavigationProps } from '../utils'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { PatientDocument } from 'common/enum/patient-document'
import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
import { getDocumentsLabelLocationByEnum } from 'presentation/utils/document-types-location'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'

export enum DocumentsList {
  'Carteira de identidade' = 'Carteira de identidade',
  'Carteira do convênio' = 'Carteira do convênio',
  'EXAME - Pedido médico' = 'EXAME - Pedido médico'
}

export enum ParticularDocumentsList {
  'Carteira de identidade' = 'Carteira de identidade',
  'EXAME - Pedido médico' = 'EXAME - Pedido médico'
}

type Props = {
  uploadDocument: UploadPatientDocument
  downloadDocument: DownloadPatientDocument
  setIsLoading: (value: boolean) => void
}

const ExamsAddDocuments = ({
  next,
  prev,
  activeIndex,
  setIsLoading,
  uploadDocument,
  downloadDocument
}: NavigationProps & Props) => {
  const patientService = useServices().patient
  const [deleteDocumentState, setDeleteDocumentState] = useState<boolean>()

  const { state, dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  async function loadPatient() {
    return await patientService.loadPatientInfo(['patient_id'])
  }

  const [documents, setDocuments] = useState() as any

  async function loadDocs() {
    setIsLoading(true)

    try {
      const { patient_id } = await loadPatient()

      const patientDocuments = await patientService.loadPatientExamDocuments(
        patient_id
      )

      setDocuments(patientDocuments)

      const arrMedDocs = patientDocuments.find((document) => {
        return document.type === PatientDocument.EXAM_MEDICAL_ORDER
      })?.documents

      const arrMedDocsIds = arrMedDocs?.map((doc) => {
        return doc.document_id
      })

      dispatch({
        type: 'UPDATE_MEDICAL_ORDER_DOCUMENT_ID',
        payload: arrMedDocsIds
      })

      if (state?.healthPlanName !== 'Particular') {
        const arrHealthCardDocs = patientDocuments.find((document) => {
          return document.type === PatientDocument.HEALTH_CARD
        })?.documents

        const arrHealthCardIds = arrHealthCardDocs?.map((doc) => {
          return doc.document_id
        })

        dispatch({
          type: 'UPDATE_HEALTH_CARD_DOCUMENT_ID',
          payload: arrHealthCardIds
        })
      }

      setDeleteDocumentState(true)
    } catch (error) {
      toast.error('Falha na busca dos documentos')
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }

  async function deleteMedicalOrderDocument() {
    try {
      const promises: Promise<void>[] = []

      if (
        state?.medical_order_document_ids &&
        state?.medical_order_document_ids.length
      ) {
        promises.push(
          ...state?.medical_order_document_ids.map(async (id: number) => {
            await deleteDocument(id)
          })
        )
      }

      if (state?.health_card_document_id) {
        promises.push(deleteDocument(state?.health_card_document_id))
      }

      await Promise.all(promises)
    } catch (error) {
      toast.error('Falha ao deletar os documentos')
    }
  }

  useEffect(() => {
    if (activeIndex === 3) {
      loadDocs()

      if (deleteDocumentState) {
        deleteMedicalOrderDocument()
      }
    }
  }, [activeIndex, deleteDocumentState])

  const uploadExamDocument = async (files: any, type: string) => {
    try {
      setIsLoading(true)
      const file = files[0]
      const form = new FormData()
      const newFile = renameFile(file, file.name)
      form.append('file', newFile)
      form.append('type', type)
      form.append('group_id', generateHash())

      if (type === PatientDocument.IDENTITY_CARD) {
        await uploadDocument.upload({
          type: PatientDocument.IDENTITY_CARD,
          form: form,
          file: newFile
        })
      }

      if (type === PatientDocument.HEALTH_CARD) {
        await uploadDocument.upload({
          type: PatientDocument.HEALTH_CARD,
          form: form,
          file: newFile
        })
      }

      if (type === PatientDocument.EXAM_MEDICAL_ORDER) {
        await uploadDocument.upload({
          type: PatientDocument.EXAM_MEDICAL_ORDER,
          form: form,
          file: newFile
        })
      }
      loadDocs()
    } catch {
      toast.error('Ocorreu um erro ao enviar o documento')
      setIsLoading(false)
    }
  }

  const downloadPatientDocument = async (document: any) => {
    setIsLoading(true)
    try {
      const file = await downloadDocument.load(
        document?.documents[0]?.document_id
      )

      downloadFileFromBlob(
        file?.data,
        file?.contentType,
        getDocumentsLabelLocationByEnum(document.documents[0].type)
      )
    } catch {
      toast.error('Ocorreu um erro ao baixar o documento')
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async (document_id: number) => {
    const { patient_id } = await loadPatient()
    try {
      await patientService.deletePatientExamDocuments({
        patient_id,
        document_id
      })

      loadDocs()
    } catch {
      toast.error('Ocorreu um erro ao excluir o documento')
    }
  }

  const [expandedDocument, setExpandedDocument] = useState('')

  const mappedDocumentsList = () => {
    if (state?.healthPlanName === 'Particular') {
      return Object.keys(ParticularDocumentsList).map((document) => ({
        label:
          ParticularDocumentsList[
            document as keyof typeof ParticularDocumentsList
          ],
        type: document,
        ...documents?.find((doc: any) => doc.type === document)
      }))
    } else
      return Object.keys(DocumentsList).map((document) => ({
        label: DocumentsList[document as keyof typeof DocumentsList],
        type: document,
        ...documents?.find((doc: any) => doc.type === document)
      }))
  }

  const startUpload = (files: any, type: string) => {
    uploadExamDocument(files, type)
  }

  const changeExpandedAccordeon = (
    accordeonLabel: string,
    isExpanded: boolean
  ) => {
    setExpandedDocument(isExpanded ? accordeonLabel : '')
  }

  function handleNext() {
    const uploadedExamMedicalOrder = documents.find((doc: any) => {
      return doc.type === 'EXAME - Pedido médico'
    })

    if (!uploadedExamMedicalOrder) {
      toast.error('Pedido médico obrigatório')
      return
    }

    next()
  }

  return (
    <>
      <ContainerNew
        title="Incluir documentos"
        titleColor="secondary700"
        titleSize="medium"
        titleWeight="bold"
        subtitle={
          'Inclua os documentos necessários para realizar o agendamento.'
        }
      >
        <DividerNew />

        {!!mappedDocumentsList().length &&
          documents &&
          mappedDocumentsList().map((document, index) => {
            return (
              <S.DocumentContainer key={index}>
                <DocumentAccordion
                  document={document}
                  isExamDocuments={[
                    PatientDocument.IDENTITY_CARD,
                    PatientDocument.HEALTH_CARD,
                    PatientDocument.EXAM_MEDICAL_ORDER
                  ].includes(document.type)}
                  expandedDocument={expandedDocument}
                  handleChange={changeExpandedAccordeon}
                  accept="image/jpeg,image/png,image/bmp,application/pdf"
                  shadow="xsatin"
                  background="neutral25"
                  padding="16px"
                  onDeleteFile={deleteDocument}
                  onDownloadFile={() => downloadPatientDocument(document)}
                  onUploadFile={(files: any) =>
                    startUpload(files, document.type)
                  }
                />
              </S.DocumentContainer>
            )
          })}

        <S.ButtonPanel>
          <ButtonNew onClick={handleNext}>Próximo</ButtonNew>
          <ButtonNew onClick={prev} outlined>
            Anterior
          </ButtonNew>
        </S.ButtonPanel>
      </ContainerNew>
    </>
  )
}

export default ExamsAddDocuments
