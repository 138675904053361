import styled from 'styled-components'
import theme from 'presentation/styles/theme'

type EmergencyMenuProps = {
  selected: boolean
}

export const MenuItem = styled.div<EmergencyMenuProps>`
  z-index: 2;
  position: relative;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  padding-bottom: 8px;
  font-weight: ${({ selected }) =>
    selected
      ? theme.ds.typography.weight.bold
      : theme.ds.typography.weight.normal};
  color: ${({ selected }) =>
    selected ? theme.ds.colors.primary600 : theme.ds.colors.neutral500};
  border-bottom: 2px solid
    ${({ selected }) => (selected ? theme.ds.colors.primary600 : 'none')};
`
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  overflow-x: auto;
  padding: 24px 24px 0;
  margin-bottom: 8px;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${theme.ds.colors.neutral100};
    z-index: 1;
  }
`
