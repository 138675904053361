import { User } from 'domain/entities/user-model'

const getFullDependentsList = (user: User) => {
  let mappedList = [] as User[]

  if (user.tutor) {
    mappedList = [user.tutor].concat(user.tutor.dependents || ([] as User[]))
  } else {
    mappedList = [user].concat(user.dependents || ([] as User[]))
  }

  return mappedList as User[]
}

export default getFullDependentsList
