import { makeAutoObservable } from 'mobx'
import { LoadNotifications } from 'domain/usecases/notification/load-notifications'
import { ReadNotifications } from 'domain/usecases/notification/read-notifications'
// import { DisableNotifications } from 'domain/usecases/notification/disable-notifications'
import { LoadNotificationsByPendency } from 'domain/usecases/notification/load-notifications-by-pendency'

type Services = {
  loadNotifications: LoadNotifications
  readNotifications: ReadNotifications
  // disableNotifications: DisableNotifications
  loadNotificationsByPendency: LoadNotificationsByPendency
}

export class NotificationService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadNotifications(
    params: LoadNotifications.Params
  ): Promise<LoadNotifications.Model> {
    return this.services.loadNotifications.load(params)
  }

  async loadNotificationsByPendency(
    params: LoadNotificationsByPendency.Params
  ): Promise<LoadNotificationsByPendency.Model> {
    return this.services.loadNotificationsByPendency.load(params)
  }

  async readNotifications(
    params: ReadNotifications.Params
  ): Promise<ReadNotifications.Model> {
    return this.services.readNotifications.load(params)
  }

  // async disableNotifications(
  //   params: DisableNotifications.Params
  // ): Promise<DisableNotifications.Model> {
  //   return this.services.disableNotifications.load(params)
  // }
}

export default NotificationService
