import { DoctorOverallRegistration } from 'domain/entities/doctor-overall-registration-model'
import { CreateDoctorRegistrationStatus } from 'domain/usecases/overall-registration-status/create-doctor-registration-status'
import { LoadDoctorOverallStatusForReview } from 'domain/usecases/overall-registration-status/load-doctor-overall-status-for-review'
import { ResolveDoctorRegisterPendencies } from 'domain/usecases/overall-registration-status/resolve-doctor-register-pendencies'
import { UpdateDoctorOverallStatus } from 'domain/usecases/overall-registration-status/update-doctor-registration-status'

import { makeAutoObservable } from 'mobx'

type Services = {
  createDoctorRegistrationStatus: CreateDoctorRegistrationStatus
  updateDoctorOverallStatus: UpdateDoctorOverallStatus
  resolvePendencies: ResolveDoctorRegisterPendencies
  loadForReview: LoadDoctorOverallStatusForReview
}

export class OverallRegistrationStatusService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async createDoctorRegistrationStatus(
    doctor_id: number
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    return this.services.createDoctorRegistrationStatus.createDoctorRegistrationStatus(
      doctor_id
    )
  }

  async updateDoctorOverallStatus(
    doctorOverall: DoctorOverallRegistration,
    overall_registration_status_id: number
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    return this.services.updateDoctorOverallStatus.updateDoctorOverallStatus(
      doctorOverall,
      overall_registration_status_id
    )
  }

  async resolvePendencies(
    overall_registration_status_id: number
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    return this.services.resolvePendencies.resolveDoctorRegisterPendencies(
      overall_registration_status_id
    )
  }

  async loadForReview(
    params: LoadDoctorOverallStatusForReview.Params
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    return this.services.loadForReview.loadDoctorOverallStatusForReview(params)
  }
}
