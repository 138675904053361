import styled from 'styled-components'

export const Wrapper = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const TextContainer = styled.div`
  margin: 10px 0;
`

export const ContentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`

export const Card = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
`
