import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import HeadingNew from 'presentation/components/HeadingNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import AutoCompleteNew from 'presentation/components/AutoCompleteNew'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { SchedulingDoctor } from 'domain/entities/scheduling-doctor-model'
import { SchedulingSpecialty } from 'domain/entities/scheduling-specialty-model'
import { SchedulingUnit } from 'domain/entities/scheduling-unit-model'
import { SchedulingSearchFilterType } from 'common/enum/scheduling-search-filter-type'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import * as S from './styles'
import ButtonNew from 'presentation/components/ButtonNew'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import { ReactComponent as RedAlertCircle } from 'presentation/assets/icons/red-alert-circle.svg'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { openPhoneApp } from 'presentation/utils/mobile-actions'

type Props = {
  patient: SchedulingPatient
  healthInsurance: PatientHealthInsurance
  specialtyFromExam?: SchedulingSpecialty
} & WithLoadingProps

const SchedulingSearchForm = WithLoading(
  ({ patient, healthInsurance, specialtyFromExam, setIsLoading }: Props) => {
    const history = useHistory()
    const schedulingService = useServices().scheduling
    const [searchType, setSearchType] = useState<SchedulingSearchFilterType>(
      SchedulingSearchFilterType.DOCTOR
    )
    const [doctorQuery, setDoctorQuery] = useState<string>()
    const [doctorsSearchResult, setDoctorsSearchResult] = useState<
      SchedulingDoctor[] | undefined
    >()
    const [selectedDoctor, setSelectedDoctor] = useState<
      SchedulingDoctor | undefined
    >()
    const [specialtyQuery, setSpecialtyQuery] = useState<string>()
    const [specialtiesSearchResult, setSpecialtiesSearchResult] =
      useState<SchedulingSpecialty[]>()
    const [selectedSpecialty, setSelectedSpecialty] = useState<
      SchedulingSpecialty | undefined
    >()
    const [chooseDoctor, setChooseDoctor] = useState<string | undefined>(
      undefined
    )
    const [errorName, setErrorName] = useState<string | undefined>()

    useEffect(() => {
      if (specialtyFromExam) {
        searchTypeHandler(SchedulingSearchFilterType.SPECIALTY)

        selectSpecialty(specialtyFromExam)
      }
    }, [])

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        patient: {
          name: patient?.name,
          email: patient?.email,
          age: patient?.age,
          gender: patient?.gender,
          patient_id: patient?.patient_id,
          user_id: patient?.user_id
        },
        healthInsurance: {
          name: healthInsurance.healthInsuranceName,
          plan_name: healthInsurance.healthPlanName,
          plan_id: healthInsurance.healthPlanCode,
          health_insurance_id: healthInsurance.healthInsuranceCode
        },
        doctor: {
          name: undefined,
          doctor_id: undefined
        },
        specialty: {
          name: undefined,
          specialty_id: undefined
        },
        units: []
      } as searchFiltersSchedulesFormValues,
      onSubmit: async (values) => {
        let payload = {} as searchFiltersSchedulesFormValues
        const payloadUnits: SchedulingUnit[] = []
        values.units.forEach((unit) => {
          const result = {
            unit_id: unit.unit_id,
            name: unit.name
          }
          return payloadUnits.push(result)
        })

        switch (searchType) {
          case SchedulingSearchFilterType.SPECIALTY:
            payload = {
              specialty: values.specialty,
              healthInsurance: values.healthInsurance,
              units: payloadUnits,
              patient: values.patient
            }
            break

          case SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY:
            payload = {
              specialty: values.specialty,
              doctor: values.doctor,
              healthInsurance: values.healthInsurance,
              units: payloadUnits,
              patient: values.patient
            }
            break

          default:
            payload = {
              doctor: values.doctor,
              healthInsurance: values.healthInsurance,
              units: payloadUnits,
              patient: values.patient
            }
            break
        }

        try {
          setIsLoading(true)
          const res = await schedulingService.getSchedules({
            data: payload,
            fields: [
              `
            doctor {
              name
              doctor_id
            }

            `,
              `
            specialty {
              name
              specialty_id
            }
            `,
              `
            hours {
              date_begin
              hour_initial_id
              hour_finish_id
            }
            `,
              'unit_name',
              'unit_id',
              'unit_description',
              'date_initial',
              'schedule_id',
              'cd_it_agenda_central'
            ]
          })
          setIsLoading(false)
          setTimeout(() => {
            history.push('/agendamento/consulta/datas', {
              filteredSearchResult: res,
              patient: patient,
              healthInsurance
            })
          }, 700)
        } catch (error: any) {
          toast.error(
            'Ocorreu um erro ao buscar horários disponíveis, tente novamente mais tarde.'
          )
        } finally {
          setIsLoading(false)
        }
      }
    })

    const handleError = () =>
      errorName === RepositoryErrors.DOCTOR_NOT_EXIST.name ||
      errorName === RepositoryErrors.AGE_NOT_EXIST.name ||
      errorName === RepositoryErrors.SPECIALTY_NOT_EXIST.name

    const resetSelecteds = () => {
      resetSelectedSpecialty()
      resetSelectedDoctor()
      resetSelectedUnits()
      setChooseDoctor(undefined)
    }

    const searchTypeHandler = (value: SchedulingSearchFilterType) => {
      switch (value) {
        case SchedulingSearchFilterType.SPECIALTY:
          setSearchType(SchedulingSearchFilterType.SPECIALTY)
          resetSelectedDoctor()
          resetSelectedUnits()
          setChooseDoctor('no')
          setErrorName(undefined)
          break

        case SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY:
          setSearchType(SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY)
          resetSelectedDoctor()
          resetSelectedUnits()
          setChooseDoctor('yes')
          setErrorName(undefined)
          break

        default:
          setSearchType(SchedulingSearchFilterType.DOCTOR)
          resetSelecteds()
          setErrorName(undefined)
          break
      }
    }

    const searchDoctors = async (input: string) => {
      if (input) {
        setErrorName(undefined)
        const data = {
          query: input,
          type: searchType || SchedulingSearchFilterType.DOCTOR,
          term: selectedSpecialty ? selectedSpecialty?.specialty_id : undefined,
          patient: {
            name: patient?.name,
            email: patient?.email,
            age: patient?.age,
            gender: patient?.gender,
            patient_id: patient?.patient_id,
            user_id: patient?.user_id
          },
          healthInsurance: {
            name: healthInsurance.healthInsuranceName,
            plan_name: healthInsurance.healthPlanName,
            plan_id: healthInsurance.healthPlanCode,
            health_insurance_id: healthInsurance.healthInsuranceCode
          }
        }

        try {
          const res = await schedulingService.searchFiltersSchedules({
            data: data,
            fields: [
              `
              doctors {
                doctor_id,
                name,
                units {
                  unit_id,
                  name,
                  description
                }
              }

            `
            ]
          })
          setDoctorsSearchResult(res.doctors)
        } catch (error: any) {
          setErrorName(error.name)
          setDoctorsSearchResult([])
        } finally {
          setIsLoading(false)
        }
      }
    }

    const selectDoctor = async (doctor: SchedulingDoctor) => {
      if (doctor) {
        setDoctorQuery(doctor.name)
        setSelectedDoctor(doctor)
        setChooseDoctor(undefined)
        const doctorWithoutUnits = Object.assign({}, doctor)
        delete doctorWithoutUnits.units
        await formik.setFieldValue('doctor', doctorWithoutUnits)
      } else {
        resetSelectedDoctor()
      }
    }

    const resetSelectedDoctor = async () => {
      await formik.setFieldValue('doctor', undefined)
      setSelectedDoctor(undefined)
      setDoctorQuery(undefined)
      setDoctorsSearchResult([])
    }

    const searchSpecialties = async (input: string) => {
      if (input) {
        setErrorName(undefined)
        try {
          const res = await schedulingService.searchFiltersSchedules({
            data: {
              query: input,
              type: SchedulingSearchFilterType.SPECIALTY,
              patient: {
                name: patient?.name,
                email: patient?.email,
                age: patient?.age,
                gender: patient?.gender,
                patient_id: patient?.patient_id || undefined,
                user_id: patient?.user_id
              },
              healthInsurance: {
                name: healthInsurance.healthInsuranceName,
                plan_name: healthInsurance.healthPlanName,
                plan_id: healthInsurance.healthPlanCode,
                health_insurance_id: healthInsurance.healthInsuranceCode
              }
            },
            fields: [
              `
              specialties {
                name,
                specialty_id,
                units {
                  unit_id,
                  name,
                  description
                },
              }

            `
            ]
          })
          setSpecialtiesSearchResult(res.specialties)
        } catch (error: any) {
          setErrorName(error.name)
          setSpecialtiesSearchResult([])
        } finally {
          setIsLoading(false)
        }
      }
    }

    const selectSpecialty = async (specialty: SchedulingSpecialty) => {
      if (specialty) {
        setSpecialtyQuery(specialty.name)
        setSelectedSpecialty(specialty)
        setChooseDoctor('no')
        const specialtyWithoutUnits = Object.assign({}, specialty)
        delete specialtyWithoutUnits.units
        await formik.setFieldValue('specialty', specialtyWithoutUnits)
      } else {
        resetSelectedSpecialty()
      }
    }

    const resetSelectedSpecialty = async () => {
      await formik.setFieldValue('specialty', undefined)
      setSelectedSpecialty(undefined)
      setSpecialtyQuery(undefined)
      setSpecialtiesSearchResult([])
    }

    const onCheckUnitsOptions = (unit: SchedulingUnit) => {
      const alreadyIn = formik.values?.units?.some((op) => op === unit)

      if (alreadyIn) {
        formik.setFieldValue(
          'units',
          formik.values?.units?.filter((op) => op !== unit)
        )
      } else {
        formik.setFieldValue('units', [...(formik.values?.units || {}), unit])
      }
    }

    const isCheckedUnitsOptions = (unit: SchedulingUnit) =>
      formik.values.units?.some((unitOption) => unitOption === unit) || false

    const resetSelectedUnits = async () =>
      await formik.setFieldValue('units', [])

    const ShowUnitsList = () => {
      if (
        (searchType === SchedulingSearchFilterType.DOCTOR ||
          searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY) &&
        !!selectedDoctor
      ) {
        return (
          <S.Row cols={1}>
            <HeadingNew
              size="small"
              weight="semiBold"
              style={{ marginBottom: '8px' }}
              required
            >
              Qual unidade de sua preferência?
            </HeadingNew>

            {selectedDoctor.units ? (
              selectedDoctor.units.map((unit: SchedulingUnit, index) => (
                <S.CheckWrapper key={index}>
                  <CheckboxNew
                    name="units"
                    labelFor={`unidade-${index}`}
                    label={unit.name.toLocaleLowerCase()}
                    value={unit.unit_id}
                    onCheck={() => onCheckUnitsOptions(unit)}
                    checked={isCheckedUnitsOptions(unit)}
                  />
                  <S.CheckInfo>
                    {unit.description?.toLocaleLowerCase()}
                  </S.CheckInfo>
                </S.CheckWrapper>
              ))
            ) : (
              <p>Sem unidades disponíveis...</p>
            )}
          </S.Row>
        )
      }

      if (
        searchType === SchedulingSearchFilterType.SPECIALTY &&
        !!selectedSpecialty &&
        chooseDoctor === 'no'
      ) {
        return (
          <S.Row cols={1}>
            <HeadingNew
              size="small"
              weight="semiBold"
              style={{ marginBottom: '8px' }}
              required
            >
              Qual unidade de sua preferência?
            </HeadingNew>
            {selectedSpecialty.units ? (
              selectedSpecialty.units.map((unit: SchedulingUnit, index) =>
                unit.unit_id === 403 ? (
                  <>
                    <S.TelephoneContactContainer>
                      <S.UnitName>{unit.name}</S.UnitName>
                      <S.Description>
                        Agendamento exclusivo pelo tel{' '}
                        <S.Fone
                          onClick={() => openPhoneApp('phone', '55753616-8094')}
                        >
                          (75) 3616-8094
                        </S.Fone>{' '}
                        ou whatsapp{' '}
                        <S.Fone
                          onClick={() =>
                            openPhoneApp(
                              'whatsApp',
                              '5575997150766',
                              `Olá, gostaria de agendar uma consulta de ${selectedSpecialty.name}`
                            )
                          }
                        >
                          (75) 99715-0766
                        </S.Fone>
                      </S.Description>
                    </S.TelephoneContactContainer>
                  </>
                ) : (
                  <>
                    <S.CheckWrapper key={index}>
                      <CheckboxNew
                        name="units"
                        labelFor={`unidade-${index}`}
                        label={unit.name.toLocaleLowerCase()}
                        value={unit.unit_id}
                        onCheck={() => onCheckUnitsOptions(unit)}
                        checked={isCheckedUnitsOptions(unit)}
                      />
                      <S.CheckInfo>
                        {unit.description?.toLocaleLowerCase()}
                      </S.CheckInfo>
                    </S.CheckWrapper>
                  </>
                )
              )
            ) : (
              <p>Sem unidades disponíveis...</p>
            )}
          </S.Row>
        )
      }
    }

    return (
      <ContainerNew
        form
        onSubmit={formik.handleSubmit}
        primaryButton={
          <ButtonNew
            color="primary"
            fullWidth
            type="submit"
            size="large"
            disabled={
              formik.isSubmitting ||
              !searchType ||
              !formik.values.patient.patient_id ||
              !formik.values.patient.gender ||
              !formik.values.healthInsurance.health_insurance_id ||
              (searchType === SchedulingSearchFilterType.DOCTOR &&
                (!formik.values.doctor?.doctor_id ||
                  !formik.values.units.length)) ||
              (searchType === SchedulingSearchFilterType.SPECIALTY &&
                (!formik.values.specialty?.specialty_id ||
                  !formik.values.units.length)) ||
              (searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY &&
                (!formik.values.specialty?.specialty_id ||
                  !formik.values.doctor?.doctor_id ||
                  !formik.values.units.length))
            }
          >
            Próximo
          </ButtonNew>
        }
      >
        <S.Row cols={1}>
          <HeadingNew
            size="small"
            weight="semiBold"
            style={{ marginBottom: '8px' }}
            required
          >
            Qual forma prefere iniciar sua busca?
          </HeadingNew>
          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor="search_type_doctor"
            value={SchedulingSearchFilterType.DOCTOR}
            label="Por nome do médico"
            onCheck={(value) => searchTypeHandler(value)}
            checked={searchType === SchedulingSearchFilterType.DOCTOR}
          />
          <RadioButtonNew
            showBackground={false}
            name="type"
            labelFor="search_type_specialty"
            value={SchedulingSearchFilterType.SPECIALTY}
            label="Por especialidade"
            onCheck={(value) => searchTypeHandler(value)}
            checked={
              searchType === SchedulingSearchFilterType.SPECIALTY ||
              searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY
            }
          />
        </S.Row>

        {/* SPECIALTY NAME INPUT */}
        {(searchType === SchedulingSearchFilterType.SPECIALTY ||
          searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY) && (
          <>
            <S.Row cols={1}>
              <AutoCompleteNew
                name="specialty"
                placeholder="Digite a especialidade"
                label="Especialidade"
                labelWeight="semiBold"
                labelSize="small"
                value={specialtyQuery || selectedSpecialty?.name || ''}
                onType={(input) => searchSpecialties(input)}
                onInputChange={setSpecialtyQuery}
                suggestions={specialtiesSearchResult?.map((specialty) => ({
                  label: specialty.name?.toLocaleLowerCase(),
                  value: specialty
                }))}
                onSuggestionClick={(e) => selectSpecialty(e)}
                clear={resetSelecteds}
                disabled={!!selectedSpecialty}
                required
                data-testid="select-specialty-autocomplete"
                debounceDelay={1200}
                autoFocus={searchType === SchedulingSearchFilterType.SPECIALTY}
                closeSuggestionsOnNotFound
                minCharactersSearch={3}
              />
            </S.Row>

            {!!selectedSpecialty && (
              <S.Row cols={1} className="-inline">
                <HeadingNew
                  size="small"
                  weight="semiBold"
                  style={{ marginBottom: '8px' }}
                  required
                >
                  Deseja escolher o médico?
                </HeadingNew>

                <div className="row-box -inline">
                  <RadioButtonNew
                    showBackground={false}
                    name="search_specialty_doctor"
                    labelFor="search_doctor_yes"
                    value={SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY}
                    label="Sim"
                    onCheck={(value) => searchTypeHandler(value)}
                    // checked={}
                  />

                  <RadioButtonNew
                    showBackground={false}
                    name="search_specialty_doctor"
                    labelFor="search_doctor_no"
                    value={SchedulingSearchFilterType.SPECIALTY}
                    label="Não"
                    onCheck={(value) => searchTypeHandler(value)}
                    checked={!!selectedSpecialty && chooseDoctor === 'no'}
                  />
                </div>
              </S.Row>
            )}
          </>
        )}

        {/* DOCTOR NAME INPUT */}
        {(searchType === SchedulingSearchFilterType.DOCTOR ||
          (searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY &&
            !!selectedSpecialty)) && (
          <S.Row cols={1}>
            <AutoCompleteNew
              name="doctor"
              placeholder="Digite o nome do médico"
              label="Nome do médico"
              labelWeight="semiBold"
              labelSize="small"
              value={doctorQuery || selectedDoctor?.name || ''}
              onType={(input) => searchDoctors(input)}
              onInputChange={setDoctorQuery}
              suggestions={doctorsSearchResult?.map((doctor) => ({
                label: doctor.name?.toLocaleLowerCase(),
                value: doctor
              }))}
              onSuggestionClick={(value) => selectDoctor(value)}
              clear={() =>
                searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY
                  ? searchTypeHandler(
                      SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY
                    )
                  : resetSelecteds()
              }
              disabled={!!selectedDoctor}
              required
              data-testid="select-doctor-autocomplete"
              debounceDelay={1200}
              autoFocus={
                searchType === SchedulingSearchFilterType.DOCTOR ||
                searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY
              }
              closeSuggestionsOnNotFound
              minCharactersSearch={3}
            />
          </S.Row>
        )}

        {ShowUnitsList()}

        {/* <S.BoxAction>
          <ButtonNew
            color="primary"
            fullWidth
            type="submit"
            style={{ marginTop: '32px' }}
            size="large"
            disabled={
              formik.isSubmitting ||
              !searchType ||
              !formik.values.patient.patient_id ||
              !formik.values.patient.gender ||
              !formik.values.patient.age ||
              !formik.values.healthInsurance.health_insurance_id ||
              (searchType === SchedulingSearchFilterType.DOCTOR &&
                (!formik.values.doctor?.doctor_id ||
                  !formik.values.units.length)) ||
              (searchType === SchedulingSearchFilterType.SPECIALTY &&
                (!formik.values.specialty?.specialty_id ||
                  !formik.values.units.length)) ||
              (searchType === SchedulingSearchFilterType.DOCTOR_BY_SPECIALTY &&
                (!formik.values.specialty?.specialty_id ||
                  !formik.values.doctor?.doctor_id ||
                  !formik.values.units.length))
            }
          >
            Próximo
          </ButtonNew>
        </S.BoxAction> */}
        {/* </S.Wrapper> */}
        {handleError() && (
          <S.AlertCard>
            <div>
              <RedAlertCircle />
            </div>
            {errorName === 'DoctorNotExistsError' && (
              <span>Nenhum registro encontrado para esse médico.</span>
            )}
            {errorName === 'SpecialtyNotExistsError' && (
              <span>Nenhum registro encontrado para essa especialidade.</span>
            )}
            {errorName === 'NoResultsForThisAgeError' &&
              searchType === SchedulingSearchFilterType.DOCTOR && (
                <span>
                  Este médico não atende a faixa etária do paciente selecionado.
                </span>
              )}
            {errorName === 'NoResultsForThisAgeError' &&
              searchType === SchedulingSearchFilterType.SPECIALTY && (
                <span>
                  Esta especialidade não atende a faixa etária do paciente
                  selecionado.
                </span>
              )}
          </S.AlertCard>
        )}
      </ContainerNew>
    )
  }
)

type searchFiltersSchedulesFormValues = {
  patient: SchedulingPatient
  healthInsurance: {
    name: string
    plan_name: string
    plan_id: number
    health_insurance_id: number
  }
  doctor?: {
    name: string | undefined
    doctor_id: number | undefined
    units?: SchedulingUnit[]
  }
  specialty?: {
    name: string | undefined
    specialty_id: number | undefined
    units?: SchedulingUnit[]
  }
  units: SchedulingUnit[]
}

export default SchedulingSearchForm
