import addSurgicalOrderIcon from 'presentation/assets/icons/add-surgical-order.svg'
import doctorIcon from 'presentation/assets/icons/doctor-icon.svg'

type OptionsType = {
  link: string
  label: string
  icon: string
}

type ProfilesType = {
  doctor: OptionsType[]
  patient: OptionsType[]
  admin: OptionsType[]
  secretary: OptionsType[]
  crmo: OptionsType[]
  hospitalization: OptionsType[]
  ambulatory: OptionsType[]
  oncology: OptionsType[]
  doctor_register: OptionsType[]
  director_doctor_register: OptionsType[]
  admin_sectorial_crmo: OptionsType[]
  emergency_room: OptionsType[]
  setor_de_marcacao: OptionsType[]
}

const profiles: ProfilesType = {
  doctor: [
    {
      link: '/pedido/novo',
      label: 'Novo pedido cirúrgico',
      icon: doctorIcon
    },
    // TO-DO: uncomment when therapeutic plan is live
    // {
    //   link: '/novo-plano-terapeutico/selecionar-paciente',
    //   label: 'Novo plano terapêutico',
    //   icon: doctorIcon
    // },
    // TO-DO: uncomment when doctor channel is live
    // {
    //   link: '/home',
    //   label: 'Canal médico',
    //   icon: doctorIcon
    // },
    {
      link: '/vincular-profissional',
      label: 'Vincular profissional',
      icon: doctorIcon
    }
  ],
  patient: [
    {
      link: '/pedido-cirurgico',
      label: 'Solicitar pedido cirúrgico',
      icon: addSurgicalOrderIcon
    }
  ],
  admin: [],
  secretary: [],
  crmo: [],
  hospitalization: [],
  ambulatory: [],
  oncology: [],
  doctor_register: [],
  director_doctor_register: [],
  admin_sectorial_crmo: [],
  emergency_room: [],
  setor_de_marcacao: []
}

export default profiles
