import TermConfidentialityAndDataProtection from './list/TermConfidentialityAndDataProtection'
import TermUseHospitalEquipment from './list/TermUseHospitalEquipment'
import TermAcceptanceCompliance from './list/TermAcceptanceCompliance'
import TermRegistrationAndConfidentialityOfTheRecordsAndPortalLaboratoryTestsAndDiagnoses from './list/TermRegistrationAndConfidentialityOfTheRecordsAndPortalLaboratoryTestsAndDiagnoses'

export default function DoctorTerms() {
  const terms = [
    TermConfidentialityAndDataProtection(),
    TermUseHospitalEquipment(),
    TermAcceptanceCompliance(),
    TermRegistrationAndConfidentialityOfTheRecordsAndPortalLaboratoryTestsAndDiagnoses()
  ]

  return terms
}
