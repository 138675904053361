import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

import { PainIntensity as Intensity } from 'common/enum/pain-intensity'
import { AddPainAlert } from 'domain/usecases/patient/add-pain-alert'
import ActualPage from 'presentation/components/ActualPage'
import Heading from 'presentation/components/Heading'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import PainMapper from 'presentation/shared/components/PainMapper'
import Button from 'presentation/components/Button'
import PainIntensity from 'presentation/shared/components/PainIntensity'
import TextArea from 'presentation/shared/components/TextArea'
import Modal from 'presentation/shared/components/Modal'
import * as S from './styles'

type Props = {
  addPainAlert?: AddPainAlert
  surgicalOrderId: number
}

export default function AddPainAlertForm({
  addPainAlert,
  surgicalOrderId
}: Props) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [successModal, setSuccessModal] = useState(false)
  const history = useHistory()

  const formik = useFormik({
    initialValues: {} as AddPainAlertFormValues,
    onSubmit: (values) => {
      try {
        addPainAlert?.add({ ...values, surgical_order_id: surgicalOrderId })
        setSuccessModal(true)
        setTimeout(() => {
          history.push('/internacao')
        }, 2500)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  useEffect(() => {
    if (surgicalOrderId === 0) {
      history.push('/home')
    }
  }, [])

  return (
    <S.Wrapper onSubmit={formik.handleSubmit}>
      <ActualPage
        text="Como estou me sentindo"
        onClick={() => {
          carousel.activeIndex === 0 ? history.goBack() : carousel.slidePrev()
        }}
      />

      <Carousel
        state={carousel}
        setState={setCarousel}
        touch={false}
        slidesPerView={1}
        spaceBetween={3}
        freeMode={false}
        dynamicHeight
      >
        <S.Container>
          <S.Header>
            <Heading as="h2" color="primary">
              Selecione o local da sua dor
            </Heading>
          </S.Header>
          <S.Content>
            <p>Frontal</p>
            <PainMapper
              side="front"
              selectedValues={(values) =>
                formik.setFieldValue('frontLocals', values)
              }
            />
          </S.Content>
          <Button type="button" onClick={carousel.slideNext} fullWidth>
            Confirmar
          </Button>
        </S.Container>
        <S.Container>
          <S.Header>
            <Heading as="h2" color="primary">
              Selecione o local da sua dor
            </Heading>
          </S.Header>
          <S.Content>
            <p>Costas</p>
            <PainMapper
              side="back"
              selectedValues={(values) =>
                formik.setFieldValue('backLocals', values)
              }
            />
          </S.Content>
          <Button type="button" onClick={carousel.slideNext} fullWidth>
            Confirmar
          </Button>
        </S.Container>
        <S.DetailsContainer>
          {carousel.activeIndex === 2 && (
            <>
              <S.Content>
                <Heading
                  as="h2"
                  color="primary"
                  style={{ marginTop: '2%', marginBottom: '2%' }}
                >
                  Intensidade da dor
                </Heading>
                <PainIntensity
                  value={(intensity) =>
                    formik.setFieldValue('intensity', intensity)
                  }
                />
                <Heading as="h2" color="primary">
                  O que você está sentindo?
                </Heading>
                <TextArea
                  bgColor="lightestGray"
                  rows={10}
                  placeholder="Descrição do que você está sentindo"
                  onChange={formik.handleChange('description')}
                />
              </S.Content>
              <Button
                type="submit"
                fullWidth
                disabled={
                  !(formik.values.description && formik.values.intensity)
                }
              >
                Enviar
              </Button>
            </>
          )}
        </S.DetailsContainer>
      </Carousel>
      <Modal
        title="Seu alerta foi registrado!"
        description="A enfermagem já está a caminho"
        show={successModal}
      />
    </S.Wrapper>
  )
}

type AddPainAlertFormValues = {
  description: string
  intensity: Intensity
  frontLocals: string[]
  backLocals: string[]
}
