import { GenderNew } from 'common/enum/gender'
import { SurgeryType } from 'common/enum/surgery-type'
import { ExamGroup, ExamModelNew } from 'domain/entities/exam-model-new'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import React, { createContext, useReducer } from 'react'

export type SchedulingExamModelContext = {
  dispatch: any
  state: SchedulingExamState
}

type Exam = {
  examName: string
  examDescription: string
}

type SelectedSurgicalProcedure = {
  exam_item_title: string
  health_insurance: {
    health_insurance_id: number
    health_insurance_name: string
    health_insurance_code: string
    health_card: string
    health_plan_code: number
    health_plan_name: string
  }
  doctor: {
    doctor_id: number
    doctor_name: string
    hospital_ids?: number[]
  }
  hospital: {
    hospital_id: number
    name: string
  }
  surgery_type?: SurgeryType
  surgicalProcedureIndex?: string
  documents?: number[]
}

export const SchedulingExamContext = createContext({})

export type SchedulingExamState = {
  //health insurance
  selectedHealthInsurance: any
  health_insurance_id: number
  healthCard: string
  healthInsuranceCode: number
  healthInsuranceName: string
  healthPlanCode: number
  healthPlanName: string
  healthSubPlanCode: number
  healthSubPlanName: string
  ansRegister: string
  company: string
  validThru: string
  createdAt: string
  updatedAt: string
  // docs
  currentPendency: any

  //exams
  exams: ExamModelNew[]
  unavailableExams: ExamModelNew[]
  examsOrdered: ExamGroup[]
  examsScheduled: ExamGroup[]
  consultPreExams: ExamModelNew[]

  // surgical procedures
  surgicalProcedures: ExamModelNew[]
  selectedSurgicalProcedure: SelectedSurgicalProcedure

  //patient data
  patient_id: number
  user_id: number
  email: string
  name: string
  age: number
  gender: GenderNew | string

  // sedation
  use_sedation: boolean

  //documents
  health_card_document_id: any
  medical_order_document_ids: any
  identitiy_card_document_ids: number[]
  // selectedExamOffer
  selectedExamOffer: {
    exam_item_id: number
    exam_item_title: string
    exam_id: number
    date_initial: string
    hospital_id: number

    doctor?: {
      doctor_id: number
      name: string
    }
    unit: {
      name: string
      unit_id: number
      address: string
    }
    selected_hour: {
      cd_it_agenda_central: number
      cd_agenda_central: number
      date_begin: string
      date_finish: string
      cd_it_agenda_ini: number
      cd_it_agenda_fim: number

      available_appointments: {
        cd_agenda_central: number
        cd_it_agenda_ini: number
        cd_it_agenda_fim: number
        item_id: number
        date_begin: string
        sn_encaixe: false
      }[]
    }
    currentSchedulingGroup?: number
  }

  is_reschedule: boolean
  has_next_schedule?: boolean
  recommended_doctor?: any
  show_pre_consult?: boolean

  selectedExamToCancel?: any
}

export type Actions = {
  payload: PatientHealthInsurance | SchedulingPatient | Exam[]
  type:
    | 'SELECT_HEALTH_INSURANCE'
    | 'UPDATE_SELECTED_EXAMS'
    | 'UPDATE_PATIENT_DATA'
    | 'UPDATE_HEALTH_CARD_DOCUMENT_ID'
    | 'SELECT_EXAM_OFFER'
    | 'SET_USE_SEDATION'
    | 'SET_RECOMMENDED_DOCTOR'
    | 'SELECTED_EXAM_TO_CANCEL'
    | 'UPDATE_MEDICAL_ORDER_DOCUMENT_ID'
    | 'UPDATE_IDENTITY_CARD_DOCUMENT_ID'
    | 'UPDATE_SELECTED_UNAVAILABLE_EXAMS'
    | 'SET_IS_RESCHEDULE'
    | 'SET_HAS_NEXT_SCHEDULE'
    | 'UPDATE_SELECTED_EXAMS_ORDERED'
    | 'UPDATE_EXAMS_SCHEDULED'
    | 'UPDATE_SELECTED_CONSULT_PRE_EXAMS'
    | 'SET_SHOW_PRE_CONSULT'
    | 'UPDATE_SELECTED_SURGICAL_PROCEDURES'
    | 'SELECT_SURGICAL_PROCEDURE'
}

const reducer = (state: any, action: Actions) => {
  switch (action.type) {
    case 'SELECT_HEALTH_INSURANCE':
      return { ...state, ...action.payload }

    case 'UPDATE_SELECTED_EXAMS':
      return { ...state, exams: action.payload }

    case 'UPDATE_SELECTED_UNAVAILABLE_EXAMS':
      return { ...state, unavailableExams: action.payload }

    case 'UPDATE_PATIENT_DATA':
      return { ...state, ...action.payload }

    case 'UPDATE_HEALTH_CARD_DOCUMENT_ID':
      return { ...state, health_card_document_id: action.payload }

    case 'UPDATE_MEDICAL_ORDER_DOCUMENT_ID':
      return { ...state, medical_order_document_ids: action.payload }

    case 'SELECT_EXAM_OFFER':
      return { ...state, selectedExamOffer: action.payload }

    case 'SET_USE_SEDATION':
      return { ...state, use_sedation: action.payload }

    case 'SET_RECOMMENDED_DOCTOR':
      return { ...state, recommended_doctor: action.payload }

    case 'SELECTED_EXAM_TO_CANCEL':
      return { ...state, selectedExamToCancel: action.payload }

    case 'SET_IS_RESCHEDULE':
      return { ...state, is_reschedule: action.payload }

    case 'SET_HAS_NEXT_SCHEDULE':
      return { ...state, has_next_schedule: action.payload }

    case 'UPDATE_SELECTED_EXAMS_ORDERED':
      return { ...state, examsOrdered: action.payload }

    case 'UPDATE_EXAMS_SCHEDULED':
      return { ...state, examsScheduled: action.payload }

    case 'UPDATE_SELECTED_CONSULT_PRE_EXAMS':
      return { ...state, consultPreExams: action.payload }

    case 'SET_SHOW_PRE_CONSULT':
      return { ...state, show_pre_consult: action.payload }

    case 'UPDATE_SELECTED_SURGICAL_PROCEDURES':
      return { ...state, surgicalProcedures: action.payload }

    case 'SELECT_SURGICAL_PROCEDURE':
      return { ...state, selectedSurgicalProcedure: action.payload }

    case 'UPDATE_IDENTITY_CARD_DOCUMENT_ID':
      return { ...state, identitiy_card_document_ids: action.payload }

    default:
      return state
  }
}

export default function SchedulingExamContextWrapper(props: any) {
  const [state, dispatch] = useReducer(reducer, {} as SchedulingExamState)

  return (
    <SchedulingExamContext.Provider value={{ state, dispatch }}>
      {props.children}
    </SchedulingExamContext.Provider>
  )
}
