import styled, { css } from 'styled-components'
import { SupportTextProps } from '.'

export const Wrapper = styled.p<SupportTextProps>`
  ${({ theme, color, weight }) => css`
    line-height: 24px;
    letter-spacing: -0.4px;
    color: ${theme.colors?.[color!]};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.ds.typography.weight?.[weight!]};
  `}
`

// const wrapperModifiers = {
//   primary: (theme: DefaultTheme) => css`
//     color: ${theme.colors.primary};
//   `,
//   gray: (theme: DefaultTheme) => css`
//     color: ${theme.colors.gray};
//   `,
//   lightGray: (theme: DefaultTheme) => css`
//     color: ${theme.colors.lightGray};
//   `,
//   red: (theme: DefaultTheme) => css`
//     color: ${theme.colors.red};
//   `,
//   primaryDarker: (theme: DefaultTheme) => css`
//     color: ${theme.colors.primaryDarker};
//   `
// }
