import { LoadSurgicalSchedules } from 'domain/usecases/surgical-schedules/load-surgical-schedules'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadSchedules implements LoadSurgicalSchedules {
  constructor(
    private readonly scheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(
    params: LoadSurgicalSchedules.Params
  ): Promise<LoadSurgicalSchedules.Model> {
    return this.scheduleRepository.loadSurgicalSchedules(params)
  }
}

export type LoadSurgicalSchedulesModel = LoadSurgicalSchedules.Model
