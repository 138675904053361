import styled, { css } from 'styled-components'

import { HealthInsuranceCardWrapper } from 'presentation/shared/components/HealthInsuranceCard/styles'

export const Wrapper = styled.div`
  padding-bottom: 24px;
`
export const Content = styled.div`
  margin-top: 24px;

  ${HealthInsuranceCardWrapper} {
    ${({ theme }) => css`
      cursor: pointer;
      border: 1px solid transparent;
      transition: border ease 0.2s;
      &:hover,
      &:active,
      &:focus {
        border-color: ${theme.ds.colors.primary600};
      }
    `}
  }
`

export const Divider = styled.span`
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  width: 100%;
  margin: 24px 0;
`

export const IpsemgInfo = styled.p`
  ${({ theme }) => css`
    margin-top: -16px;
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 20px;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors.danger300};

    a {
      text-decoration: underline;
      color: ${theme.ds.colors.info400};
      transition: color ease 0.1s;

      &:hover {
        color: ${theme.ds.colors.info300};
      }
    }
  `}
`

export const ButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .loader {
    border: 4px solid ${({ theme }) => theme.ds.colors.white};
    border-top: 4px solid ${({ theme }) => theme.ds.colors.neutral400};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1.3s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const DocumentContainer = styled.div`
  margin-bottom: 12px;
`
