import styled, { css, DefaultTheme } from 'styled-components'
import chroma from 'chroma-js'
import { transparentize } from 'polished'

import { ButtonProps } from '.'

export type WrapperProps = Pick<
  ButtonProps,
  'fullWidth' | 'variant' | 'outlined' | 'backgroundColor'
>

const wrapperModifiers = {
  fullWidth: () => css`
    width: 100%;
  `,
  disabled: (theme: DefaultTheme, color?: string) => css`
    &:disabled {
      cursor: not-allowed;
      background: ${color && color !== 'transparent'
        ? chroma(color).brighten(1).hex()
        : transparentize(0.5, theme.colors?.primary)};
    }
  `,
  outlined: (theme: DefaultTheme, color?: string) => css`
    background-color: #fff;
    color: ${color ?? theme.colors?.primary};
    border: 2px solid ${color ?? theme.colors?.primary};
  `,
  white: (theme: DefaultTheme) => css`
    background-color: white;
    color: ${theme.colors?.primary};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  `,
  text: (theme: DefaultTheme) => css`
    background: transparent;
    color: ${theme.colors?.lightGray};
    box-shadow: none;
  `
}

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    fullWidth,
    disabled,
    variant,
    outlined,
    color,
    backgroundColor
  }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: ${backgroundColor
      ? theme.colors?.[backgroundColor]
      : theme.colors?.primary};
    color: ${theme.colors?.white};
    font-family: ${theme.font?.family};
    border: ${!outlined ? '0' : '1px solid' + theme.colors?.lightGray};
    cursor: pointer;
    border-radius: ${theme.border?.radius.small};
    font-weight: 600;
    font-size: ${theme.font?.sizes.medium};
    height: 48px;
    outline: none;
    width: min(320px, 100%);
    text-decoration: none;
    flex-shrink: 0;
    ${!!fullWidth && wrapperModifiers.fullWidth()};
    ${variant === 'outlined' && wrapperModifiers[variant](theme, color)}
    ${variant === 'white' && wrapperModifiers[variant](theme)}
    ${variant === 'text' && wrapperModifiers[variant](theme)}
    ${disabled && wrapperModifiers.disabled(theme, color)};

    span {
      display: flex;
      align-items: center;
    }
  `}
`
