import { CreateDoctorRegisterStepPendency } from 'domain/usecases/doctor-register-step-pendency/create-doctor-register-step-pendency'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteCreateDoctorRegisterStepPendency
  implements CreateDoctorRegisterStepPendency
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  add(
    params: CreateDoctorRegisterStepPendency.Params
  ): Promise<CreateDoctorRegisterStepPendency.Model> {
    return this.doctorRepository.createDoctorRegisterStepPendency(params)
  }
}
