import React from 'react'

import { useHistory } from 'react-router-dom'
import List, { ListItem } from 'presentation/shared/components/List'

import * as S from './styles'
import HeaderNew from 'presentation/components/HeaderNew'
import ButtonNew from 'presentation/components/ButtonNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ContainerNew } from 'presentation/components/ContainerNew'

type SaveSurgicalOrderLayoutProps = {
  handleSubmit: (e: React.FormEvent) => Promise<void>
  items: ListItem[]
  disabled: boolean
}

export default function SaveSurgicalOrderLayout({
  disabled,
  handleSubmit,
  items
}: SaveSurgicalOrderLayoutProps) {
  const history = useHistory()
  return (
    <>
      <HeaderNew actualPageTitle="Pedido de cirurgia" />
      <ContainerNew
        primaryButton={
          <ButtonNew
            data-testid="btn-send"
            size="large"
            disabled={disabled}
            fullWidth
            onClick={handleSubmit}
          >
            Enviar
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew
            data-testid="btn-cancel"
            outlined
            size="large"
            onClick={() => history.push('/home')}
            variant="text"
          >
            Cancelar
          </ButtonNew>
        }
      >
        <S.Wrapper onSubmit={handleSubmit}>
          <S.Content>
            <SupportTextNew>
              Anexe, abaixo, todos os dados e documentos solicitados:
            </SupportTextNew>
            <S.List data-testid="list-items">
              <List items={items} size={'small'} />
            </S.List>
          </S.Content>
        </S.Wrapper>
      </ContainerNew>
    </>
  )
}
