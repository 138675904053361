import styled, { css } from 'styled-components'

export type DividerProps = {
  marginTop?: string
  marginBottom?: string
  height?: string
  leak?: string
}

const Divider = styled.div<DividerProps>`
  ${({
    marginTop = '16px',
    marginBottom = '16px',
    height = '1px',
    leak = '0px'
  }) => css`
    background-color: #d1d3d7;
    width: calc(100% + (${leak} * 2));
    margin-left: -${leak};
    height: ${height};
    margin-top: ${marginTop};
    margin-bottom: ${marginBottom};
  `}
`

export default Divider
