// import CheckiInIcon from 'presentation/assets/icons/checkin.svg'
import OrientationIcon from 'presentation/assets/icons/orientation.svg'
// import DocumentsIcon from 'presentation/assets/icons/documents.svg'

type ButtonJourneyProps = {
  icon: string
  alt: string
  label: string
  path: string
  needSurgery: boolean
}

export const journeyButtons: ButtonJourneyProps[] = [
  {
    path: '/orientacoes/cirurgicas/pre-cirurgica',
    icon: OrientationIcon,
    alt: 'Coração envolto de duas mãos',
    label: 'Orientações Cirúrgicas',
    needSurgery: false
  }
  // {
  //   path: '/cirurgia/documentos',
  //   icon: DocumentsIcon,
  //   alt: 'Arquivo de documento',
  //   label: 'Documentos',
  //   needSurgery: true
  // },
  // {
  //   path: '/checkin',
  //   icon: CheckiInIcon,
  //   alt: 'Pin de localização',
  //   label: 'Check-in',
  //   needSurgery: true
  // }
]
