import { PatientDocument } from 'common/enum/patient-document'
import { DocumentsType, DocumentType } from 'domain/entities/document-types'
import { PendencyType } from 'domain/entities/surgical-pendency'
import AlertIcon from 'presentation/assets/icons/alert.svg'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import { useUploadSection } from 'presentation/hooks/use-upload-section'
import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import List from 'presentation/shared/components/List'
import Modal from 'presentation/shared/components/Modal'
import SupportText from 'presentation/components/SupportText'
import TextButton from 'presentation/shared/components/TextButton'
import { UploadSection } from 'presentation/shared/components/UploadSection'
import doctorPendencyStore from 'presentation/contexts/doctor-pendency/doctor-pendency-context'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import React, { useEffect, useState } from 'react'
import { ListFilePendencyType } from '../AnswerPendency'
import * as S from './styles'

type Props = {
  saveDocuments: (documents: ListFile<ListFilePendencyType>[]) => void
  defaultDocuments: ListFile<ListFilePendencyType>[]
  goBack: () => void
  pendencyType: PendencyType
}

export const AppendPendencyDocument = ({
  saveDocuments,
  defaultDocuments,
  goBack,
  pendencyType
}: Props) => {
  const [documents, setDocuments] = useState<DocumentType[]>([])
  const [files, setFiles] = useState<ListFile<ListFilePendencyType>[]>([])
  const [selectedFiles, setSelectedFiles] = useState<
    ListFile<ListFilePendencyType>[]
  >([])
  const [selectedActiveDocument, setSelectedActiveDocument] = useState<string>()
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false)
  const [goBackModal, setGoBackModal] = useState<boolean>(false)

  const getDocuments = () => {
    const labels = defaultDocuments
      .filter(
        (document) =>
          document.identifier?.label?.startsWith?.('Laudo') &&
          document.identifier?.label !== 'Laudos 1'
      )
      .map((document) => document.identifier?.label)
    const examLabels = [...new Set(labels)]
    const newDocuments = [
      ...DocumentsType.getPendenciesDocuments()[pendencyType],
      ...examLabels.map((label) => ({
        ...DocumentsType.examReportDocumentType,
        label: label || ''
      }))
    ]
    setDocuments(newDocuments)
  }

  useEffect(() => {
    getDocuments()
    setFiles(defaultDocuments)
  }, [defaultDocuments])

  useEffect(() => {
    resetInfo(selectedFiles)
  }, [selectedActiveDocument])

  const back = () => {
    if (selectedActiveDocument) {
      doctorPendencyStore.clearDoctorPendency()
      setSelectedActiveDocument(undefined)
      setSelectedFiles([])
      resetInfo()
    } else {
      setGoBackModal(true)
    }
  }

  const verifyAddReport = () => {
    const documentsType =
      DocumentsType.getPendenciesDocuments()[pendencyType as PendencyType]
    const hasReportType = documentsType.some((documentType) =>
      documentType.type.includes(PatientDocument.EXAM_REPORT)
    )
    if (!hasReportType) return false
    const filesUploaded: Record<string, number> = {}
    files.forEach(({ identifier }) => {
      filesUploaded[identifier?.label || ''] = 1
    })

    return !documents.some(
      (document) =>
        document.label.startsWith('Laudo') && !filesUploaded[document.label]
    )
  }

  const addReport = () => {
    if (!verifyAddReport()) return
    const lastExam =
      documents?.filter((doc) => doc.label.startsWith('Laudo'))?.length ?? 0
    const label = 'Laudos ' + (lastExam + 1)
    setDocuments([
      ...(documents ?? []),
      {
        ...DocumentsType.examReportDocumentType,
        label: label
      }
    ])
  }

  const getDocumentType = (): DocumentType => {
    const documentType = DocumentsType.getPendenciesDocuments()[
      pendencyType as PendencyType
    ].find((document) => document.label === selectedActiveDocument)
    if (documentType) return documentType
    return {
      ...DocumentsType.examReportDocumentType,
      label: selectedActiveDocument || ''
    }
  }

  const documentType = getDocumentType()

  const { filesInfo, onAdd, onDelete, onDownload, onUpdate, resetInfo } =
    useUploadSection({
      maxFileSizeInMB: documentType.maxFileSizeInMB,
      maxFiles: documentType.maxFiles,
      initialFiles: selectedFiles,
      extensionsAllowed: ['pdf', 'jpeg', 'jpg'],
      stateFiles: selectedFiles,
      setFilesFunction: (listFiles) => {
        setSelectedFiles(
          listFiles.map((listFile) => ({
            ...listFile,
            identifier: {
              label: documentType.label,
              type: documentType.type[0]
            }
          }))
        )
      }
    })

  const canUpload = {
    canAdd: filesInfo.canAdd,
    canDelete: true,
    canDownload: true,
    canUpdate: true
  }

  const saveListDocuments = () => {
    saveDocuments(files)
    setShowSaveModal(true)
  }

  const canAddReport = verifyAddReport()

  return (
    <Container
      title="Pendências cirúrgicas"
      actualPageText="Voltar"
      actualPageOnClick={back}
      primaryButton={
        <Button
          style={{ marginBottom: '10px' }}
          type="button"
          onClick={
            selectedActiveDocument
              ? () => setShowSuccessModal(true)
              : saveListDocuments
          }
          fullWidth
          data-testid="btn-save-documents"
        >
          Salvar
        </Button>
      }
      secondaryButton={
        <TextButton
          onClick={back}
          style={{ padding: '10px 0' }}
          data-testid="btn-cancel-upload"
        >
          Cancelar
        </TextButton>
      }
    >
      <SupportText color="primary">
        Documentos, Exames, Laudos e Relatórios Anexos
      </SupportText>
      {selectedActiveDocument ? (
        <>
          <UploadSection
            can={canUpload}
            files={selectedFiles}
            onAdd={onAdd}
            onDelete={onDelete}
            onDownload={onDownload}
            onUpdate={onUpdate}
            errors={filesInfo.errorMessage}
            acceptFiles={'application/pdf, image/jpeg'}
          />
        </>
      ) : (
        <>
          <S.List>
            <List
              items={documents.map(({ label }) => {
                const documentFiles = files.filter(
                  (file) => file.identifier?.label === label
                )
                return {
                  title: label,
                  icon: documentFiles.length > 0 ? CheckIcon : AlertIcon,
                  click: () => {
                    setSelectedActiveDocument(label)
                    setSelectedFiles(documentFiles)
                  }
                }
              })}
              size="medium"
            />
          </S.List>
          {canAddReport && (
            <Button
              variant="white"
              type="button"
              onClick={addReport}
              fullWidth
              data-testid="btn-add-more-exams-reports"
            >
              Adicionar outros laudos
            </Button>
          )}
        </>
      )}
      <Modal
        title="Arquivo anexado com sucesso"
        show={showSuccessModal}
        close={() => {
          setShowSuccessModal(false)
          setFiles([
            ...files.filter(
              (file) => file.identifier?.label !== selectedActiveDocument
            ),
            ...selectedFiles
          ])
          setSelectedActiveDocument(undefined)
          setSelectedFiles([])
        }}
        data-testid="modal-file-succesfully-attached"
      />
      <Modal
        title="Arquivos anexados com sucesso"
        show={showSaveModal}
        close={goBack}
        data-testid="modal-files-succesfully-attached"
      />
      <Modal show={goBackModal}>
        <S.BackModal>
          Deseja realmente voltar? Todos os anexos adicionados serão perdidos
          <div>
            <Button
              type="button"
              onClick={() => setGoBackModal(false)}
              data-testid="btn-go-back-modal-no"
            >
              Não
            </Button>
            <Button
              type="button"
              onClick={goBack}
              data-testid="btn-go-back-modal-yes"
            >
              Sim
            </Button>
          </div>
        </S.BackModal>
      </Modal>
    </Container>
  )
}
