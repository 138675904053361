import { ApiSurgicalScheduleRepository } from 'repository/rest/api-surgical-schedule/api-surgical-schedule-repository'
import { ApiResponse } from 'service/protocols/api-surgical-schedule/api-surgical-schedule-client'
import {
  IApiSurgicalScheduleRepository,
  RepositoryRequest
} from 'service/protocols/api-surgical-schedule/api-surgical-schedule-repository'
import { GetStorage } from 'service/protocols/cache/get-storage'

export class PrepareApiRequestsurgicalScheduleDecorator
  implements IApiSurgicalScheduleRepository
{
  constructor(
    private readonly getStorage: GetStorage,
    private readonly apiSurgicalScheduleRepository: ApiSurgicalScheduleRepository
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return await this.apiSurgicalScheduleRepository.get(data)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`
    return await this.apiSurgicalScheduleRepository.post(data)
  }

  async put(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-surgical-schedule${data.url}`
        : `${
            process.env.REACT_APP_API_MATERDEI_SURGICAL_SCHEDULING_URL +
            data.url
          }`

    return await this.apiSurgicalScheduleRepository.put(data)
  }
}
