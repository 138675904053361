import { HealthInsuranceRepository } from 'repository/interfaces/health-insurance-repository'
import { SearchHealthInsurances } from 'domain/usecases/health-insurance/search-health-insurances'

export class RemoteSearchHealthInsurances implements SearchHealthInsurances {
  constructor(
    private readonly healthInsuranceRepository: HealthInsuranceRepository
  ) {}

  load(
    params: SearchHealthInsurances.Params
  ): Promise<SearchHealthInsurances.Model> {
    return this.healthInsuranceRepository.searchHealthInsurances(params)
  }
}
