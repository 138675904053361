import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import ServiceSolicitation from 'presentation/pages/ServiceSolicitation'
import { HospitalizationRepository } from 'repository/repositories/hospitalization/hospitalization-repository'
import { RemoteAddHospitalizationService } from 'service/usecases/add-hospitalization-service/remote-add-hospitalization-service'

export default function makePatientSolicitation() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const hospitalizationService = new HospitalizationRepository(apiRepository)
  const addHospitalizationService = new RemoteAddHospitalizationService(
    hospitalizationService
  )
  return (
    <ServiceSolicitation
      addHospitalizationService={addHospitalizationService}
    />
  )
}
