import React from 'react'

import * as S from './styles'
import theme from 'presentation/styles/theme'
import SupportTextNew from '../SupportTextNew'

export type Props = {
  titleSmaller?: string
  title: string
  titleSmallerColor?: keyof typeof theme.ds.colors
  titleColor?: keyof typeof theme.ds.colors
  bgColor?: keyof typeof theme.ds.colors
  image?: React.ReactElement
  onClick: () => void
}

export const BannerCard = ({
  titleSmaller,
  title,
  titleSmallerColor = 'neutral700',
  titleColor = 'primary600',
  bgColor = 'primary50',
  image,
  onClick
}: Props) => {
  return (
    <S.Wrapper onClick={onClick} bgColor={bgColor}>
      <S.CardHeader>
        <SupportTextNew as="h1" color={titleColor} weight="bold" size="large">
          {title}
        </SupportTextNew>
        {titleSmaller && (
          <S.Small titleSmallerColor={titleSmallerColor}>
            {titleSmaller}
          </S.Small>
        )}
      </S.CardHeader>
      {image && <S.ImageWrapper>{image}</S.ImageWrapper>}
    </S.Wrapper>
  )
}
