import { TimerModel } from 'domain/entities/timer-model'
import { IntervalType } from 'presentation/utils/interval-type'
import { useEffect, useState } from 'react'

type UseTimerProps = {
  timer?: TimerModel
  timerIntervalInMs?: number
  action: () => void
}

const ONE_MINUTE_IN_MS = 60000 // 60 seconds

export default function useTimer({
  action,
  timer,
  timerIntervalInMs = ONE_MINUTE_IN_MS
}: UseTimerProps) {
  const [currentInterval, setCurrentInterval] = useState<IntervalType>()

  useEffect(() => {
    if (currentInterval) clearInterval(currentInterval)
    if (!timer) return
    action()
    const timeToNextUpdateInMs = timer.getSecondsToNextMinute() * 1000
    setTimeout(() => {
      action()
      const newInterval = setInterval(action, timerIntervalInMs)
      setCurrentInterval(newInterval)
    }, timeToNextUpdateInMs)
  }, [timer])
}
