export enum SurgeryType {
  CAT = 'CAT',
  BIO = 'BIO',
  EMB = 'EMB'
}

export enum SurgeryTypeLocated {
  CAT = 'Cateterismo Cardíaco',
  BIO = 'Biópsia Guiada',
  EMB = 'Embolização'
}
