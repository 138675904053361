import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { SchedulingSchedule } from 'domain/entities/scheduling-schedule-model'
import { useServices } from 'presentation/hooks/use-services'
import HeaderNew from 'presentation/components/HeaderNew'
import ProfilePic from 'presentation/components/ProfilePic'
import ButtonNew from 'presentation/components/ButtonNew'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { SchedulingUnit } from 'domain/entities/scheduling-unit-model'
import { ReactComponent as CreditCardIcon } from 'presentation/assets/icons/credit-card.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as PinIcon } from 'presentation/assets/icons/new_pin.svg'
import { ReactComponent as UserIcon } from 'presentation/assets/icons/user.svg'
import { ReactComponent as EmailIcon } from 'presentation/assets/icons/mail-new.svg'
import { ReactComponent as PhoneIcon } from 'presentation/assets/icons/phone-new.svg'
import * as S from './styles'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'

type LocationType = {
  state: {
    filteredSearchResult: {
      data: {
        getSchedules: SchedulingSchedule[]
      }
    }
    selectedSchedule: SchedulingSchedule
    selectedHour: SchedulingHours
    patient: SchedulingPatient
    healthInsurance: PatientHealthInsurance
    units: SchedulingUnit[]
  }
}

const SchedulingConfirmLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const location: LocationType = useLocation()
    const schedulingService = useServices().scheduling
    const scheduleState = location.state && location.state.selectedSchedule
    const scheduleHourState = location.state && location.state.selectedHour
    const patientState = location.state && location.state.patient
    const healthInsuranceState =
      location.state && location.state.healthInsurance
    const filteredSearchResultState =
      location.state && location.state.filteredSearchResult.data.getSchedules

    const makePayloadObject = () => ({
      patient: {
        user_id: patientState?.user_id,
        patient_id: patientState?.patient_id,
        name: patientState?.name,
        email: patientState?.email,
        age: patientState?.age,
        gender: patientState?.gender
      },
      specialty: {
        specialty_id: scheduleState?.specialty?.specialty_id,
        name: scheduleState?.specialty?.name
      },
      healthInsurance: {
        name: healthInsuranceState?.healthInsuranceName,
        plan_name: healthInsuranceState?.healthPlanName,
        plan_id: healthInsuranceState?.healthPlanCode,
        health_insurance_id: healthInsuranceState?.healthInsuranceCode
      },
      doctor: {
        doctor_id: scheduleState?.doctor.doctor_id,
        name: scheduleState?.doctor?.name
      },
      unit: {
        unit_id: scheduleState?.unit_id,
        name: scheduleState?.unit_name,
        description: scheduleState?.unit_description
      },
      schedule: {
        schedule_id: scheduleState?.schedule_id,
        hour_initial_id: scheduleHourState?.hour_initial_id,
        hour_finish_id: scheduleHourState?.hour_finish_id,
        cd_it_agenda_central: scheduleState.cd_it_agenda_central,
        date: `${moment(scheduleHourState?.date_begin)
          .locale('pt-br')
          .format('dddd - DD [de] MMMM')} às ${moment(
          scheduleHourState?.date_begin
        )
          .locale('pt-br')
          .format('LT')}`
      }
    })

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {},
      onSubmit: async () => {
        const payload = makePayloadObject()

        try {
          setIsLoading(true)
          const res = await schedulingService.createSchedule({
            data: payload
          })
          setIsLoading(false)
          if (res.data.createSchedule) {
            setTimeout(() => {
              history.push('/agendamento/consulta/confirmado', {
                state: location.state
              })
            }, 700)
          }
        } catch (error: any) {
          toast.error('Ocorreu um erro ao agendar a consulta')
        } finally {
          setIsLoading(false)
        }
      }
    })

    const whereToGo = () => {
      if (patientState && healthInsuranceState && filteredSearchResultState) {
        history.push('/agendamento/consulta/datas', {
          filteredSearchResult: {
            data: {
              getSchedules: filteredSearchResultState
            }
          },
          patient: patientState,
          healthInsurance: healthInsuranceState
        })
      } else {
        history.push('/consultas')
      }
    }

    const goToCalendar = (selectedSchedule: SchedulingSchedule) => {
      history.push('/agendamento/consulta/calendario', {
        filteredSearchResult: {
          data: {
            getSchedules: filteredSearchResultState
          }
        },
        patient: patientState,
        healthInsurance: {
          name: healthInsuranceState.healthInsuranceName,
          plan_name: healthInsuranceState.healthPlanName,
          plan_id: healthInsuranceState.healthPlanCode,
          health_insurance_id: healthInsuranceState.healthInsuranceCode
        },
        doctor: {
          name: selectedSchedule.doctor.name,
          doctor_id: selectedSchedule.doctor.doctor_id
        },
        specialty: {
          name: selectedSchedule.specialty.name,
          specialty_id: selectedSchedule.specialty.specialty_id
        },
        units: [
          {
            name: selectedSchedule.unit_name,
            unit_id: selectedSchedule.unit_id,
            description: selectedSchedule.unit_description
          }
        ],
        schedule_id: selectedSchedule.schedule_id,
        date_initial: selectedSchedule.date_initial,
        cd_it_agenda_central: selectedSchedule.cd_it_agenda_central
      })
    }

    useEffect(() => {
      if (
        !patientState ||
        !scheduleState ||
        !scheduleHourState ||
        !healthInsuranceState
      )
        whereToGo()
    }, [])

    return (
      <>
        <HeaderNew
          actualPageTitle="Agendar consulta"
          actualPageOnClick={() => whereToGo()}
        />
        <ContainerNew
          noPadding
          noHeader
          form
          onSubmit={formik.handleSubmit}
          primaryButton={
            <div style={{ width: '100%', padding: '0 24px' }}>
              <ButtonNew color="primary" fullWidth type="submit" size="large">
                Agendar consulta
              </ButtonNew>
            </div>
          }
        >
          <S.SchedulingWrapper>
            <S.CardSchedulingInfos>
              <div className="header">
                <div className="img">
                  <ProfilePic
                    data-testid="profile-pic"
                    outlined
                    size="xmedium"
                    name={scheduleState?.doctor?.name}
                  />
                </div>
                <div className="header-infos">
                  <h2 className="title">
                    <span className="prefix">Dr(a)</span>{' '}
                    <span className="text">
                      {scheduleState?.doctor?.name?.toLocaleLowerCase()}
                    </span>
                  </h2>
                  <h4 className="subtitle">{scheduleState?.specialty?.name}</h4>
                </div>
              </div>
              <div className="content">
                <div className="box">
                  <CalendarIcon className="icon" />
                  <div className="infos">
                    <h4 className="title -no-capitalize">
                      {moment(scheduleHourState?.date_begin)
                        .locale('pt-br')
                        .format('dddd - DD [de] MMMM')}{' '}
                      às{' '}
                      {moment(scheduleHourState?.date_begin)
                        .locale('pt-br')
                        .format('LT')}
                    </h4>
                    <ButtonNew
                      size="small"
                      outlined
                      style={{ marginTop: '8px' }}
                      onClick={() => goToCalendar(scheduleState)}
                    >
                      mudar a data
                    </ButtonNew>
                  </div>
                </div>

                <div className="box">
                  <CreditCardIcon className="icon" />
                  <div className="infos">
                    <h6 className="title">
                      {healthInsuranceState?.healthInsuranceName?.toLocaleLowerCase()}
                    </h6>
                    <p className="description -plan-name">
                      {healthInsuranceState?.healthPlanName?.toLocaleLowerCase()}
                    </p>
                  </div>
                </div>

                <div className="box">
                  <PinIcon className="icon" />
                  <div className="infos">
                    <h6 className="title">
                      {scheduleState?.unit_name?.toLocaleLowerCase()}
                    </h6>
                    <p className="description -localization">
                      {scheduleState?.unit_description?.toLocaleLowerCase()}
                    </p>
                  </div>
                </div>
              </div>
            </S.CardSchedulingInfos>
            <S.CardPatientInfos>
              <div className="header">
                <h2 className="title">Dados do paciente</h2>
              </div>
              <div className="content">
                <div className="box">
                  <UserIcon className="icon" />
                  <div className="infos">
                    <h6 className="title">Paciente</h6>
                    <p className="description">
                      {patientState?.name?.toLocaleLowerCase() || '-'}
                    </p>
                  </div>
                </div>

                <div className="box">
                  <EmailIcon className="icon" />
                  <div className="infos">
                    <h6 className="title">E-mail</h6>
                    <p className="description -no-transform">
                      {patientState?.email?.toLocaleLowerCase() || '-'}
                    </p>
                  </div>
                </div>

                <div className="box">
                  <PhoneIcon className="icon" />
                  <div className="infos">
                    <h6 className="title">Contato</h6>
                    <p className="description -no-transform">
                      {patientState?.phone || '-'}
                    </p>
                  </div>
                </div>
              </div>
            </S.CardPatientInfos>
            {healthInsuranceState?.healthPlanName?.toLocaleLowerCase() !==
              'particular' && (
              <S.CardInfo>
                <h6 className="title">Lembre-se</h6>
                <p className="paragraph">
                  Não se esqueça de levar o documento de identidade e
                  carteirinha do convênio.
                </p>
              </S.CardInfo>
            )}
          </S.SchedulingWrapper>
        </ContainerNew>
      </>
    )
  }
)

export default SchedulingConfirmLayout
