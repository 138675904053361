import React from 'react'
import { useLocation } from 'react-router-dom'
import * as S from './styles'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import HeaderNew from 'presentation/components/HeaderNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { ReasonsNoSurgeryForm } from 'presentation/components/Forms/ReasonsNoSurgeryForm'
import { CardInfoSurgery } from 'presentation/components/CardInfoSurgery'

type LocationType = {
  state: {
    newOpenScheduledSurgeries: any
    openScheduledSurgeries: any
  }
}

const ReasonNotSurgeryLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const location: LocationType = useLocation()

    const { openScheduledSurgeries, newOpenScheduledSurgeries } = location.state

    const currentOpenScheduledSurgeries =
      openScheduledSurgeries || newOpenScheduledSurgeries
    return (
      <>
        <HeaderNew />
        <ContainerNew>
          <S.Wrapper>
            <CardInfoSurgery surgicalOrder={currentOpenScheduledSurgeries[0]} />
            <SupportTextNew
              weight="semiBold"
              color="secondary700"
              size="medium"
            >
              Por qual motivo você não realizou a sua cirurgia? *
            </SupportTextNew>
            <ReasonsNoSurgeryForm
              openScheduledSurgeries={currentOpenScheduledSurgeries}
              surgicalOrder={currentOpenScheduledSurgeries[0]}
              setIsLoading={setIsLoading}
            />
          </S.Wrapper>
        </ContainerNew>
      </>
    )
  }
)

export default ReasonNotSurgeryLayout
