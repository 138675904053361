import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermConfidentialityAndDataProtection() {
  return {
    name: TermsTypeEnum.TERM_CONFIDENTIALITY_AND_DATA_PROTECTION,
    title: 'Termo de confidencialidade e proteção de dados',
    content: (
      <>
        {/* <iframe
          src="https://production-exp-paciente-public-assets.s3.amazonaws.com/terms/Termo+de+confidencialidade.pdf"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            paddingBottom: '0'
          }}
        /> */}
        <div>
          <p>
            Ao preencher este formulário, você está ciente e autoriza a Rede
            Mater Dei de Saúde a utilizar os seus dados pessoais para a criação
            de cadastro de potenciais médicos a integrarem o corpo clínico da
            Rede Mater Dei de Saúde. Em caso de futuro cadastro, informamos que
            esses dados serão incorporados ao Cadastro Oficial do Corpo Clínico.
            Lembramos, ainda, que é possível revogar a qualquer tempo o seu
            consentimento, solicitando a exclusão de seus dados pessoais de
            nosso cadastro, ou solicitar a revisão dos mesmos, através do nosso
            SAC ou Canal de Denúncia. Finalmente, informamos que seus dados
            pessoais não serão compartilhados com terceiros ou utilizados para
            qualquer outra finalidade que não a de formação de cadastro. Nós, da
            Rede Mater Dei de Saúde, nos preocupamos com a proteção dos seus
            dados pessoais, e adotamos as medidas de segurança necessárias para
            garantir a sua integridade e o seu sigilo.
          </p>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de confidencialidade e proteção de dados`
  }
}
