import { GetHospitalsByHealthInsurance } from 'domain/usecases/hospital/get-hospitals-by-health-insurance'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'

export class RemoteGetHospitalsByHealthInsurance
  implements GetHospitalsByHealthInsurance
{
  constructor(private readonly hospitalRepository: HospitalRepository) {}
  async get(
    params: GetHospitalsByHealthInsurance.Params
  ): Promise<GetHospitalsByHealthInsurance.Model> {
    return this.hospitalRepository.getHospitalsByHealthInsurance(params)
  }
}

export type RemoteGetHospitalsByHealthInsuranceModel =
  GetHospitalsByHealthInsurance.Model
