import { GetExamAvailableHospitals } from 'domain/usecases/exam/get-exam-available-hospitals'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamAvailableHospitals
  implements GetExamAvailableHospitals
{
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: GetExamAvailableHospitals.Params
  ): Promise<GetExamAvailableHospitals.Model> {
    return this.examRepository.getExamAvailableHospitals(params)
  }
}

export type GetExamAvailableHospitalsModel = GetExamAvailableHospitals.Model
