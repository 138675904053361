import React from 'react'

import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import StatusAnalyzingImage from 'presentation/assets/banners/surgical-journey/status-analyzing.svg'
import * as S from './styled'

export const SlideHospitalAnalysis = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Em análise no hospital
        </HeadingNew>
        <S.Image
          src={StatusAnalyzingImage}
          alt="Pessoas analisando informações"
        />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            Toda a documentação enviada por você e pelo seu médico é revisada
            antes de ser encaminhada ao convênio para autorização do
            procedimento.
          </SupportTextNew>
          <SupportTextNew color="neutral700">
            Esse processo é importante para minimizar as chances de negativa por
            parte do convênio. Acompanhe o andamento do seu pedido e caso haja
            alguma pendência vamos te sinalizar.
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
