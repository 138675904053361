import styled from 'styled-components'
import SupportText from 'presentation/components/SupportText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

export const List = styled.div`
  img {
    border-radius: 0 !important;
  }
`

export const UserName = styled(SupportText)`
  display: inline-block !important;
  margin: 0 auto 70px;
  margin-bottom: 70px;
  font-weight: 600;
  text-transform: capitalize;

  &.is-clickable {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const DependentsMenu = styled(Menu)`
  .MuiMenu-paper.MuiPopover-paper {
    margin-top: 16px;
    border-radius: 8px !important;
  }

  .MuiList-padding.MuiMenu-list {
    width: 312px !important;
    padding: 0;
    @media screen and (max-width: 580px) {
      width: calc(100vw - 48px) !important;
    }
  }
`

export const DependentsMenuItem = styled(MenuItem)`
  padding: 16px;
  border-bottom: 1px solid #c6c6c6;
  text-transform: capitalize;

  &:last-child {
    border-bottom: none;
  }

  &.is-active {
    font-weight: 600;
    color: #036e63;
    background-color: #e1f3f2;
    &:hover {
      background-color: #e1f3f2;
    }
  }
`
