// import { AxiosResponse } from 'axios'
import {
  ApiNotificationClient,
  ApiRequest,
  ApiResponse,
  ApiMethod
} from 'service/protocols/api-notification/api-notification-client'
import {
  IApiNotificationRepository,
  RepositoryRequest
} from 'service/protocols/api-notification/api-notification-repository'

export class ApiNotificationRepository<Model = any>
  implements IApiNotificationRepository<Model>
{
  constructor(
    private readonly apiNotificationClient: ApiNotificationClient<Model>
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiNotificationClient.request({
      ...data,
      method: ApiMethod.GET
    } as ApiRequest)
  }

  async post(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    const request = await this.apiNotificationClient.request({
      ...data,
      method: ApiMethod.POST
    } as ApiRequest)

    // if (request.body) {
    //   request.body = (request.body as any).data
    //     ? (request.body as any).data[data.query]
    //     : request.body
    // }

    return request
  }

  async put(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return await this.apiNotificationClient.request({
      ...data,
      method: ApiMethod.PUT
    } as ApiRequest)
  }

  delete(data: RepositoryRequest): Promise<ApiResponse<Model>> {
    return this.apiNotificationClient.request({
      ...data,
      method: ApiMethod.DELETE
    } as ApiRequest)
  }
}
