import React from 'react'
import * as S from './styles'

export default function PreSurgeryInfo() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.PreSurgery>
          <S.Title>Orientações Pré-Cirúrgicas</S.Title>

          <S.Description>
            - Preencha e envie toda a documentação obrigatória para que sua
            internação ocorra de forma rápida e segura;
            <br />
            <br />
            - Lembre-se de chegar com uma hora de antecedência ao Hospital;
            <br />
            <br />
            - Tome um banho na noite anterior e na manhã da cirurgia com um
            sabonete antisséptico à base de clorexidina (caso não seja
            alérgico);
            <br />
            <br />
            - Lave e seque os cabelos, evitando contato do sabonete com os
            olhos. Se necessário, peça ao seu médico orientações mais
            detalhadas;
            <br />
            <br />
            - Não remova os pelos da área do corpo onde será realizada a
            cirurgia. O uso de lâmina ou cera depilatória pode irritar a pele e
            torná-la mais fácil de desenvolver uma infecção;
            <br />
            <br />- Informe ao seu cirurgião se você apresentar sinais ou
            sintomas de uma infecção acometendo a pele, as vias respiratórias ou
            em outra localização no dia da cirurgia.
          </S.Description>
        </S.PreSurgery>
      </S.Container>
    </S.Wrapper>
  )
}
