import { DoctorRepository } from 'repository/interfaces/doctor-repository'
import { FinishDoctorRecommendation } from '../../../domain/usecases/doctor/finish-doctor-recommendation'

export class RemoteFinishDoctorRecommendation
  implements FinishDoctorRecommendation
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  finishDoctorRecommendation(
    params: FinishDoctorRecommendation.Params
  ): Promise<FinishDoctorRecommendation.Model> {
    return this.doctorRepository.finishDoctorRecommendation(params)
  }
}

export type FinishDoctorRecommendationModel = FinishDoctorRecommendation.Model
