import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import 'main/config/yup'

import { useFormik } from 'formik'
import { Authentication } from 'domain/usecases/authentication/authentication'
import { cpfMask } from 'presentation/utils/masks'
import { LoginFormValues } from 'presentation/pages/Login'
import { useStores } from 'presentation/hooks/use-stores'
import { useHistory, useLocation } from 'react-router'
import { ReactComponent as EmailIcon } from 'presentation/assets/icons/email.svg'
import { ReactComponent as PhoneIcon } from 'presentation/assets/icons/phone.svg'

import Button from '../../../../components/Button'
import Checkbox from '../../Checkbox'
import Heading from '../../../../components/Heading'
import PasswordField from '../../PasswordField'
import SupportText from '../../../../components/SupportText'
import TextButton from '../../TextButton'
import TextField from '../../../../components/TextField'
import SheetModal from '../../../../components/SheetModal'
import { profileBind } from 'common/utils/bindRolesToEnum'

import * as S from './styles'
import { toast } from 'react-toastify'
import { VerifyLoginClient } from 'domain/usecases/authentication/verify-client'
import { Profile } from 'common/enum/profile'
import makePermisions from 'main/factories/stores/permissions/permissions-factory'
import { Hospital } from 'domain/entities/hospital-model'
// import { UserRoles } from 'domain/entities/user-roles-model'
import { WithLoading, WithLoadingProps } from '../../HOCs/WithLoading'
import { UnauthorizedError } from 'common/errors'
import { useSearchParams } from 'presentation/hooks/use-search-params'
import isBase64 from 'presentation/utils/is-base-64'

type LoginFormProps = {
  useCase?: Authentication
  verifyClient?: VerifyLoginClient
  initialValues?: LoginFormValues
  client?: Profile
} & WithLoadingProps

function LoginForm({
  initialValues = { remember: false } as LoginFormValues,
  useCase,
  client,
  setIsLoading
}: LoginFormProps) {
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const stores = useStores()
  const currentAccount = useStores().currentAccount
  const currentHospital = useStores().currentHospital
  const deniedRegisterRoles = [Profile.CRMO, Profile.DOCTOR]
  const searchParams = useSearchParams()
  const queryHospital = searchParams.get('hospitalId')

  const nextPathname = searchParams.get('pathname')
  searchParams.delete('pathname')
  const encodedParams = isBase64(searchParams.toString())
    ? searchParams.toString()
    : btoa(searchParams.toString())
  const location = useLocation() as Location

  type Location = {
    state?: {
      pathname?: string
    }
  }

  useEffect(() => {
    localStorage.removeItem('pendencies_notification')
  }, [])

  const handleRedirect = (originPath: string, queryParams: string) => {
    switch (client) {
      case Profile.PATIENT:
        return history.push('/onboarding')
      case Profile.DOCTOR:
        return history.push('/doctor-onboarding')
      default:
        return history.push(originPath + queryParams)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: LoginSchema,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const account = await useCase?.auth({
          cpf: values.cpf.replace(/\D+/g, ''),
          password: values.password,
          role: client ? profileBind[client] : undefined
        })

        currentAccount.setCurrentAccount(account!)

        const hospitals: Hospital[] =
          account?.user.hospitals?.map(
            ({ hospital_id, name, nickname, friendly_name, uf }) =>
              new Hospital(hospital_id, name, nickname, friendly_name, uf)
          ) ?? []

        if (queryHospital) {
          currentHospital.setActualHospital(Number(queryHospital))
        } else if (account?.user?.selectedHospital) {
          currentHospital.setActualHospital(account?.user?.selectedHospital)
        }
        if (account?.user?.role !== Profile.PATIENT)
          currentHospital.setHospitalList(hospitals)

        stores.permissions = makePermisions()
        try {
          await stores.permissions.load()
        } catch (error) {
          throw new Error(
            'Ocorreu um erro ao carregar suas permissões, tente novamente'
          )
        }

        const queryParams = encodedParams ? `?${encodedParams}` : ''
        const originPath =
          account?.user.isMainRole === Profile.SETOR_DE_MARCACAO
            ? '/setor-de-marcacao/solicitacoes'
            : nextPathname || location.state?.pathname || '/home'

        !account?.user?.finishedAppOnboarding
          ? handleRedirect(originPath, queryParams)
          : history.push(originPath + queryParams)
      } catch (err: any) {
        if (err instanceof UnauthorizedError) {
          toast.error('Credenciais inválidas')
          setIsLoading(false)
          return
        }
        toast.error(err.message)
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <>
      <S.Form role="form" onSubmit={formik.handleSubmit}>
        <S.Content>
          <TextField
            label="CPF"
            name="cpf"
            mask={cpfMask}
            placeholder="000.000.000-00"
            initialValue={formik.values.cpf}
            onInputChange={formik.handleChange('cpf')}
            onBlur={formik.handleBlur('cpf')}
            error={formik.touched.cpf ? formik.errors.cpf : undefined}
          />
          <PasswordField
            style={{ marginTop: '24px' }}
            label="Senha"
            name="password"
            placeholder="••••••"
            initialValue={formik.values.password}
            onInputChange={formik.handleChange('password')}
            onBlur={formik.handleBlur('password')}
            error={formik.touched.password ? formik.errors.password : undefined}
          />
          <S.Options>
            <Checkbox
              name="remember"
              label="Lembrar senha"
              labelFor="lembrar senha"
              isChecked={formik.values.remember}
              onCheck={() =>
                formik.setFieldValue('remember', !formik.values.remember)
              }
            />
            <TextButton
              size="small"
              underline
              type="button"
              onClick={() => setOpenModal(true)}
            >
              Esqueceu sua senha?
            </TextButton>
          </S.Options>
        </S.Content>
        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          fullWidth
          style={{
            marginBottom: '10px'
          }}
          type="submit"
        >
          Acessar conta
        </Button>
        {client && !deniedRegisterRoles.includes(client) && (
          <TextButton
            size="medium"
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px'
            }}
            onClick={() => history.push('/cadastro-validacao')}
          >
            Cadastre-se
          </TextButton>
        )}
      </S.Form>
      <SheetModal
        isDraggable
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        size={500}
      >
        <S.PasswordRecoveryModal>
          <Heading>
            Escolha uma maneira de <strong>alterar sua senha</strong>
          </Heading>
          <SupportText>
            Enviaremos um código para seu celular ou e-mail para auxiliarmos no
            processo
          </SupportText>
          <S.PasswordRecoveryItem
            onClick={() => history.push('/recuperar-senha/email')}
          >
            <EmailIcon />
            <span>Enviar por E-mail</span>
          </S.PasswordRecoveryItem>
          <S.PasswordRecoveryItem
            onClick={() => history.push('/recuperar-senha/phone')}
          >
            <PhoneIcon />
            <span>Enviar por SMS</span>
          </S.PasswordRecoveryItem>
        </S.PasswordRecoveryModal>
      </SheetModal>
    </>
  )
}

export default WithLoading(LoginForm)

const LoginSchema = yup.object().shape({
  cpf: yup.string().cpf().required(),
  password: yup.string().required(),
  remember: yup.boolean()
})
