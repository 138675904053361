import React from 'react'

import ProfilePic from 'presentation/components/ProfilePic'
import * as S from './styles'

type Props = {
  name: string
}

export default function AccompanyingCard({ name }: Props) {
  return (
    <S.Card data-testid={'accompanying-card'} style={{ paddingLeft: 20 }}>
      <S.ImageContainer>
        <ProfilePic size="large" />
      </S.ImageContainer>
      <S.TextContainer>
        <p style={{ marginLeft: 35 }}>Acompanhante</p>
        <p style={{ marginLeft: 35 }}>{name}</p>
      </S.TextContainer>
    </S.Card>
  )
}
