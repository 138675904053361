import React from 'react'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import EmailConfirmationLayout from 'presentation/layouts/EmailConfirmation'
import { UpdateExam } from 'domain/usecases/patient/update-exam'

type Props = {
  updateUserEmail: UpdatePatientInfo
  updateExam: UpdateExam
}

export default function EmailConfirmation({
  updateUserEmail,
  updateExam
}: Props) {
  return (
    <EmailConfirmationLayout
      updateExam={updateExam}
      updateUserEmail={updateUserEmail}
    />
  )
}
