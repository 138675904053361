import { Patient } from 'domain/entities/patient-model'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { FormikProps, useFormik } from 'formik'
import Button from 'presentation/components/Button'
import Checkbox from 'presentation/shared/components/Checkbox'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import SupportText from 'presentation/components/SupportText'
import TextField from 'presentation/components/TextField'
import { phoneMask } from 'presentation/utils/masks'
import React from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import * as S from './styles'

type EditPatientPersonalDataProps = {
  patient: Patient
  onClose: () => void
  open: boolean
  addAccompanying?: AddAccompanying
  setAccompanists: (ac: any[]) => void
  accompanists: any[]
  updateAccompanies: () => void
}

export function AddAccompanyingModal({
  onClose,
  open,
  addAccompanying,
  setAccompanists,
  accompanists,
  updateAccompanies
}: EditPatientPersonalDataProps) {
  const formik: FormikProps<AccompanyingFormValues> =
    useFormik<AccompanyingFormValues>({
      initialValues: { sendEmail: false, sendSMS: false },
      validationSchema: validationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          const requestObject = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            sendSMS: !!values.sendSMS,
            sendEmail: !!values.sendEmail,
            isMain: !!values.isMain
          }
          const response = await addAccompanying?.add(requestObject)
          setAccompanists([...accompanists, response])
          onClose()
          updateAccompanies()
          formik.resetForm()
        } catch (error: any) {
          toast.error(error.message)
        }
      }
    })

  return (
    <SheetModal size={700} isOpen={open} onClose={onClose} backdrop="true">
      <div style={{ width: '50%', marginBottom: '20px' }}>
        <Heading size="large">Adicionar acompanhante</Heading>
      </div>
      <Container
        noPadding
        primaryButton={
          <Button
            fullWidth
            style={{ marginTop: '20px' }}
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
          >
            Confirmar
          </Button>
        }
      >
        <S.AddAccompanyingWrapper>
          <TextField
            label="Nome completo"
            placeholder="Seu nome"
            name="name"
            onInputChange={formik.handleChange('name')}
            onBlur={formik.handleBlur('name')}
            error={formik.touched.name && formik.errors.name}
            value={formik.values?.name}
            required
            maxLength={100}
          />
          <TextField
            label="E-mail"
            placeholder="seuemail@gmail.com"
            style={{ marginTop: '20px' }}
            name="email"
            onInputChange={formik.handleChange('email')}
            onBlur={formik.handleBlur('email')}
            value={formik.values?.email}
            error={formik.touched.email && formik.errors.email}
            required
            maxLength={100}
          />
          <TextField
            label="Celular"
            placeholder="+55 (xx) xxxxxxxxx"
            style={{ marginTop: '20px' }}
            mask={phoneMask}
            name="phone"
            onInputChange={formik.handleChange('phone')}
            onBlur={formik.handleBlur('phone')}
            error={formik.touched.phone && formik.errors.phone}
            initialValue={formik.values?.phone}
            defaultValue={'+55'}
            required
          />
          <br />
          <SupportText>
            Deseja que o seu acompanhante receba notificações via:
          </SupportText>
          <br />
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              <Checkbox
                onCheck={() =>
                  formik.setFieldValue('sendEmail', !formik.values.sendEmail)
                }
                label="E-mail"
                id="Email"
                labelFor="Email"
                checked={formik.values?.sendEmail}
              />
            </div>
            <Checkbox
              onCheck={() =>
                formik.setFieldValue('sendSMS', !formik.values.sendSMS)
              }
              label="SMS"
              id="sms"
              labelFor="sms"
              checked={formik.values?.sendSMS}
            />
          </div>
          <br />
          <Checkbox
            onCheck={() =>
              formik.setFieldValue('isMain', !formik.values.isMain)
            }
            label="Acompanhante Principal"
            id="accompanying"
            labelFor="accompanying"
            checked={formik.values?.isMain}
          />
        </S.AddAccompanyingWrapper>
      </Container>
    </SheetModal>
  )
}

export type AccompanyingFormValues = {
  name?: string
  lastName?: string
  relationship?: string
  email?: string
  phone?: string
  sendEmail?: boolean
  sendSMS?: boolean
  isMain?: boolean
}

const validationSchema = yup.object().shape(
  {
    name: yup.string().required(),
    lastName: yup.string().optional(),
    relationship: yup.string().optional(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .test('masked-phone-validation', 'Telefone inválido', function (val) {
        return !!(
          val?.match(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/) ||
          val?.match(/^[0-9]{13}$/)
        )
      })
      .required(),
    sendEmail: yup.boolean().when('sendSMS', {
      is: (sendSMSOption: boolean) => !sendSMSOption,
      then: yup.bool().oneOf([true], 'Deve selecionar pelo menos uma opção')
    }),
    sendSMS: yup.boolean().when('sendEmail', {
      is: (sendEmailOption: boolean) => !sendEmailOption,
      then: yup.bool().oneOf([true], 'Deve selecionar pelo menos uma opção')
    })
  },
  [
    ['sendEmail', 'sendSMS'],
    ['sendSMS', 'sendEmail']
  ]
)
