import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ isOpen: boolean }>`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    .input {
      border-radius: ${theme.ds.typography.border.radius.small};
    }

    .clear-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 48px;
      height: 48px;
      border-radius: ${`0 ${theme.ds.typography.border.radius.small} ${theme.ds.typography.border.radius.small} 0`};
      background-color: ${theme.ds.colors.neutral600};

      &:hover,
      &:active {
        background-color: ${theme.ds.colors.neutral500};
      }

      .icon {
        width: 16px;
        height: 16px;

        path {
          fill: ${theme.ds.colors.white};
        }
      }
    }
  `}
`

export const Suggestions = styled.ul`
  position: absolute;
  max-height: 144px;
  overflow-y: auto;
  top: calc(100% + 8px);
  width: 100%;
  font-variant-numeric: tabular-nums;
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  z-index: 2;
  background-color: ${({ theme }) => theme.ds.colors.white};
  outline: none;
  border: none;
  border: 1px solid ${({ theme }) => theme.ds.colors.primary300};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;

  li:not(.empty-state) {
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
    color: ${({ theme }) => theme.ds.colors.neutral900};
    display: inline-block; /* Force the width to be respected. See also: inline-block */
    /* float: left; So it won't be 1-per-line. Not needed for inline-block */
    text-align: left;
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    position: relative;
    transition: background-color 80ms;
    text-transform: capitalize;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.ds.colors.primary800};
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      background-color: ${({ theme }) => theme.ds.colors.primary50};
    }

    img {
      height: 32px;
      display: block;
      margin: auto;
    }
  }
`
