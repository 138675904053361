import React, { ReactElement } from 'react'

import * as S from './style'
import SheetModal from 'presentation/components/SheetModal'
import Button from 'presentation/components/Button'

type ServiceSolicitationOverviewModalProps = {
  isOpen: boolean
  onClose: () => void
  titleSlot: ReactElement
  firstButtonLabel: string
  firstButtonOnClick: () => void
  secondButtonLabel?: string
  secondButtonOnClick?: () => void
}

export default function ServiceSolicitationOverviewModal({
  titleSlot,
  firstButtonLabel,
  firstButtonOnClick,
  secondButtonLabel,
  secondButtonOnClick,
  isOpen,
  onClose
}: ServiceSolicitationOverviewModalProps) {
  return (
    <SheetModal
      isOpen={isOpen}
      onClose={onClose}
      isDraggable={false}
      size={250}
    >
      <S.Wrapper>
        {titleSlot}

        <S.ButtonContainer>
          <Button
            fullWidth={true}
            style={{ marginBottom: 10 }}
            onClick={firstButtonOnClick}
          >
            {firstButtonLabel}
          </Button>

          {secondButtonOnClick && secondButtonLabel && (
            <Button
              fullWidth={true}
              type="button"
              variant="outlined"
              style={{ backgroundColor: 'transparent', border: 0 }}
              onClick={secondButtonOnClick}
            >
              {secondButtonLabel}
            </Button>
          )}
        </S.ButtonContainer>
      </S.Wrapper>
    </SheetModal>
  )
}
