import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermRegistrationAndConfidentialityOfTheRecordsAndPortalLaboratoryTestsAndDiagnoses() {
  return {
    name: TermsTypeEnum.TERM_REGISTRATION_AND_CONFIDENTIALITY__OF_THE_RECORDS_AND_PORTAL_LABORATORY_TESTS_AND_DIAGNOSES,
    title:
      'Termo de Cadastro e Confidencialidade do Prontuário e Portal de Exames Laboratoriais e Diagnósticos',
    content: (
      <>
        <div>
          <p>
            Pela presente, venho solicitar-lhes colocar a minha disposição o
            acesso remoto aos Exames Laboratoriais e Diagnósticos dos Pacientes
            da Rede Mater Dei de Saúde, dos quais pretendo valer-me para
            admitir, atender e tratar meus pacientes de acordo com as minhas
            qualiﬁcações na(s) especialidade(s) de que exerço como proﬁssional
            autônomo.
          </p>
          <p>
            Esclareço que não há vínculo trabalhista de qualquer espécie entre
            minha pessoa a este hospital, uma vez que ambos nos consideraremos
            contratados diretamente pelos pacientes e/ou convênios ou
            seguros-saúde de que façam parte.
          </p>
          <p>
            Comprometo-me a manter a documentação para este cadastro e outras
            exigidas por lei atualizadas e informar, de forma oportuna, qualquer
            alteração cadastral e titulações.
          </p>
          <p>
            Estou ciente que a partir desta data terei acesso ao sistema de
            Prontuário Clínico do Paciente e Acesso ao Portal de Exames
            Laboratoriais e Diagnósticos desta instituição através de uma senha
            ao qual me permitirá consultar e registrar as informações de saúde
            dos pacientes.
          </p>
          <p>
            É de meu conhecimento que as informações constantes no prontuário
            médico e Portal de Exames pertencem ao paciente, são de caráter
            sigiloso e estão sob a guarda do Hospital Mater Dei. Comprometo-me a
            não copiar, não acessar e não divulgar indevidamente os dados
            médicos.
          </p>
          <p>
            Estou ciente que meu acesso ao Prontuário Clínico e Portal de Exames
            Laboratoriais e Diagnósticos se dará de acordo com a vigência de
            minha senha e que meus acessos ao sistema serão registrados a ﬁm de
            possibilitar auditorias, quando necessárias.
          </p>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de Cadastro e Confidencialidade do Prontuário e Portal de Exames Laboratoriais e Diagnósticos`
  }
}
