import React from 'react'
import Modal, { ModalProps } from 'presentation/shared/components/Modal'

import * as S from './style'
import Heading from 'presentation/components/Heading'
import Button from 'presentation/components/Button'
import StatusBranch from 'presentation/assets/icons/branch-icon.svg'
import { useHistory } from 'react-router-dom'

type IInfoNewExamModal = {
  exam_id?: number
  confirmated: boolean
} & ModalProps

const DEFAULT_SUBTITLE_CONTET = {
  confirmated:
    'Tudo certo! Agora você receberá as instruções de preparo em seu e-mail.',
  not_confirmated: `Confirme seu endereço de e-mail para que possamos enviar as
  instruções de preparo.`
}

export default function ConfirmNewExamModal({
  show,
  close,
  exam_id,
  confirmated
}: IInfoNewExamModal) {
  const history = useHistory()

  return (
    <Modal show={show} close={close} preventPadding preventAutomateClose>
      <S.Wrapper confirmated={confirmated}>
        <S.Content>
          <div className="text-info">
            <Heading as="h1" size="large">
              Seu atendimento foi agendado!
            </Heading>

            <S.Text>
              {confirmated
                ? DEFAULT_SUBTITLE_CONTET.confirmated
                : DEFAULT_SUBTITLE_CONTET.not_confirmated}
            </S.Text>
          </div>
        </S.Content>

        <div className="options-div">
          {!confirmated && (
            <Button
              onClick={() =>
                history.push({
                  pathname: '/confirmar-email',
                  state: { exam_id }
                })
              }
            >
              Confirmar e-mail
            </Button>
          )}

          <Button
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            variant="white"
            onClick={() =>
              history.push({
                pathname: `/exame`,
                state: {
                  id: exam_id
                }
              })
            }
            outlined
          >
            <img
              style={{ marginRight: '10px' }}
              src={StatusBranch}
              alt="Icone de raificação para sinalizar status"
            />
            Visualizar status
          </Button>
        </div>
      </S.Wrapper>
    </Modal>
  )
}
