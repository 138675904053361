import React, { useState } from 'react'

import * as S from './style'
import ModalNew, { ModalNewProps } from 'presentation/components/ModalNew'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import SlideItem from './SlideItem'
import ButtonNew from 'presentation/components/ButtonNew'
import SlideImg0 from 'presentation/assets/onboarding-images/documents-onboarding/0.svg'
import SlideImg1 from 'presentation/assets/onboarding-images/documents-onboarding/1.svg'
import SlideImg2 from 'presentation/assets/onboarding-images/documents-onboarding/2.svg'
import SlideImg3 from 'presentation/assets/onboarding-images/documents-onboarding/3.svg'
import SlideImg4 from 'presentation/assets/onboarding-images/documents-onboarding/4.svg'
import SlideImg5 from 'presentation/assets/onboarding-images/documents-onboarding/5.png'
import { ReactComponent as LeftArrowIcon } from 'presentation/assets/icons/left-arrow-new.svg'
import { ReactComponent as RightArrowIcon } from 'presentation/assets/icons/right-arrow-new.svg'
import StepDotsNew from 'presentation/shared/components/StepDotsNew'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'
import { UserFlagType, UserFlagTypeLocated } from 'common/enum/user-flags-types'
import { useServices } from 'presentation/hooks/use-services'

export default function MyDocumentsOnboardingModal({
  show,
  close,
  preventAutomateClose,
  preventOutsideClickClose
}: ModalNewProps) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [startedOnboarding, setStartedOnboarding] = useState<boolean>(false)
  const [finishedOnboarding, setFinishedOnboarding] = useState<boolean>(false)
  const localStorageAdapter = new LocalStorageAdapter()
  const userService = useServices().user

  const handleClickStepDots = (stepNumber: number) =>
    carousel.slideTo(stepNumber)

  const handleClickNextButton = () => {
    if (carousel.isLast) {
      setFinishedOnboarding(true)
    } else {
      carousel.slideNext()
    }
  }

  const handleClickSeeAgain = () => {
    setFinishedOnboarding(false)
    setStartedOnboarding(false)
  }

  const handleClickSeeLater = () => {
    const currentUserFlags = localStorageAdapter.get('user-flags')
    localStorageAdapter.set('user-flags', {
      ...currentUserFlags,
      [UserFlagTypeLocated[UserFlagType.INTEGRATION_MEMED]]: true
    })
    !!close && close()
  }

  const handleFinishOnboarding = async () => {
    try {
      const currentUserFlags = localStorageAdapter.get('user-flags')
      localStorageAdapter.set('user-flags', {
        ...currentUserFlags,
        [UserFlagTypeLocated[UserFlagType.INTEGRATION_MEMED]]: true
      })

      !!close && close()

      await userService.updateUserFlags({
        type: UserFlagType.INTEGRATION_MEMED
      })
    } catch (error: any) {
      console.error(error.message)
    }
  }

  return (
    <ModalNew
      show={show}
      close={close}
      preventAutomateClose={preventAutomateClose}
      preventOutsideClickClose={preventOutsideClickClose}
    >
      <S.Wrapper>
        <S.Content>
          {!startedOnboarding && !finishedOnboarding && (
            <SlideItem
              key={0}
              image={SlideImg0}
              title="Conheça as novidades!"
              description="Você sabia que agora pode acessar seus atestados, receitas, e pedido de exames no Meu Mater Dei?"
            />
          )}
          {startedOnboarding && !finishedOnboarding && (
            <Carousel
              state={carousel}
              setState={setCarousel}
              slidesPerView={1}
              dynamicHeight={false}
              fade={{ crossFade: true }}
              effect="fade"
              speed={800}
              slideFullHeight
              touch={false}
            >
              <SlideItem
                key={1}
                image={SlideImg1}
                title="Passo 1"
                description="Na página inicial, procure por “Consultas”"
              />
              <SlideItem
                key={2}
                image={SlideImg2}
                title="Passo 2"
                description="Acesse o item “Meus documentos”"
              />
              <SlideItem
                key={3}
                image={SlideImg3}
                title="Passo 3"
                description="Você visualizará as datas e profissionais das suas consultas. Clique em “Ver documentos”"
              />
              <SlideItem
                key={4}
                image={SlideImg4}
                title="Passo 4"
                description="Você receberá um código de validação e será redirecionado para o site da Memed"
              />
            </Carousel>
          )}
          {finishedOnboarding && (
            <SlideItem
              key={5}
              image={SlideImg5}
              title="Você conseguiu!"
              description="Pronto! Você irá visualizar todos os seus documentos e se precisar baixar é só digitar o código."
            />
          )}
        </S.Content>
        <S.Footer>
          {!startedOnboarding && !finishedOnboarding && (
            <div className="action-btns">
              <ButtonNew
                type="button"
                onClick={() => setStartedOnboarding(true)}
              >
                Iniciar tutorial
              </ButtonNew>
              <ButtonNew
                type="button"
                size="small"
                variant="text"
                onClick={handleClickSeeLater}
              >
                Ver mais tarde
              </ButtonNew>
            </div>
          )}

          {startedOnboarding && !finishedOnboarding && (
            <>
              <S.Button type="button" onClick={carousel.slidePrev}>
                <LeftArrowIcon />
              </S.Button>
              <StepDotsNew
                total={carousel.itemsCount}
                active={carousel.activeIndex || 0}
                clickEvent={(index: number) => handleClickStepDots(index)}
              />
              <S.Button type="button" onClick={handleClickNextButton}>
                <RightArrowIcon />
              </S.Button>
            </>
          )}

          {finishedOnboarding && (
            <div className="action-btns">
              <ButtonNew type="button" onClick={handleFinishOnboarding}>
                Fechar
              </ButtonNew>
              <ButtonNew
                type="button"
                size="small"
                variant="text"
                onClick={handleClickSeeAgain}
              >
                Ver tutorial novamente
              </ButtonNew>
            </div>
          )}
        </S.Footer>
      </S.Wrapper>
    </ModalNew>
  )
}
