import { DoctorRegisterRepository as IDoctorRegisterRepository } from 'repository/interfaces/doctor-register-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { CreateDoctorRegisterPendency } from 'domain/usecases/doctor-register/enable-doctor-register-pendency'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  CreateDoctorRegisterReviewMutation,
  enableDoctorRegisterPendencyMutation,
  sendDoctorRegisterToDirectorMutation
} from 'repository/graphql/mutations'
import { SendDoctorRegisterToDirector } from 'domain/usecases/doctor-register/send-doctor-register-to-director'
import { CreateDoctorRegisterReview } from 'domain/usecases/doctor-register-review/create-doctor-register-review'
import { plainToClass } from 'class-transformer'
import { DoctorRegisterReview } from 'domain/entities/doctor-register-review-modal'

export class DoctorRegisterRepository implements IDoctorRegisterRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async createDoctorRegisterPendency(
    params: CreateDoctorRegisterPendency.Params
  ): Promise<CreateDoctorRegisterPendency.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateDoctorRegisterPendency.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: enableDoctorRegisterPendencyMutation.query,
        variables: makeGraphQLVariable(
          params.doctor_register_id,
          'doctor_register_id'
        )
      },
      query: enableDoctorRegisterPendencyMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateDoctorRegisterPendency.Model
    }
  }

  async sendDoctorRegisterToDirector(
    params: SendDoctorRegisterToDirector.Params
  ): Promise<SendDoctorRegisterToDirector.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SendDoctorRegisterToDirector.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: sendDoctorRegisterToDirectorMutation.query,
        variables: makeGraphQLVariable(
          params.doctor_register_id,
          'doctor_register_id'
        )
      },
      query: sendDoctorRegisterToDirectorMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SendDoctorRegisterToDirector.Model
    }
  }

  async createDoctorRegisterReview(
    params: CreateDoctorRegisterReview.Params
  ): Promise<CreateDoctorRegisterReview.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateDoctorRegisterReview.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: CreateDoctorRegisterReviewMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: CreateDoctorRegisterReviewMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return plainToClass(DoctorRegisterReview, httpResponse.body)
    }
  }
}
