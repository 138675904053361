import { Hospital } from 'domain/entities/hospital-model'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { HospitalRepository } from 'repository/interfaces/hospital-repository'

export class RemoteLoadHospitals implements LoadHospitals {
  constructor(private readonly hospitalRepository: HospitalRepository) {}

  async load(params?: LoadHospitals.Params): Promise<LoadHospitals.Model> {
    const hospitalList = await this.hospitalRepository.loadHospitals(params)
    return hospitalList.map((hospital) => {
      return new Hospital(
        hospital.hospital_id,
        hospital.nickname,
        hospital.name,
        hospital?.friendly_name,
        hospital?.uf,
        hospital?.specialityHospital,
        hospital?.hasPartnerCode
      )
    })
  }
}

export type LoadHospitalsModel = LoadHospitals.Model
