import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { ApiRepository } from 'repository/api-repository'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import SaveSurgicalOrder from 'presentation/pages/CreateSurgicalOrder/Save'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'
import { RemotePatientAddSurgicalOrder } from 'service/usecases/patient-add-surgical-order/remote-patient-add-surgical-order'

export default function makePatientSaveSurgicalOrder() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)
  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const addSurgicalOrder = new RemotePatientAddSurgicalOrder(
    surgicalOrderRepository
  )

  return (
    <SaveSurgicalOrder
      createSurgicalOrder={addSurgicalOrder}
      loadPatientInfo={loadPatientInfo}
    />
  )
}
