import { DeleteSchedule } from 'domain/usecases/scheduling/delete-schedule'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteDeleteSchedule implements DeleteSchedule {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: DeleteSchedule.Params): Promise<DeleteSchedule.Model> {
    return this.schedulingRepository.deleteSchedule(params)
  }
}

export type DeleteScheduleModel = DeleteSchedule.Model
