import React, { useContext, useEffect, useState } from 'react'
import * as S from './styles'
// import SupportTextNew from 'presentation/shared/components/SupportTextNew'
import { ReactComponent as Arrow } from 'presentation/assets/icons/v-arrow.svg'
import { ReactComponent as PendingIcon } from 'presentation/assets/icons/pending-timeline.svg'
import { ReactComponent as DocumentAddedIcon } from 'presentation/assets/icons/document-timeline.svg'
import { ReactComponent as SystemIcon } from 'presentation/assets/icons/system-timeline.svg'
// import { ReactComponent as DivergenceIcon } from 'presentation/assets/icons/divergence-timeline.svg'
// import { ReactComponent as CommentIcon } from 'presentation/assets/icons/comment-timeline.svg'
// import { ReactComponent as RequestCancellationIcon } from 'presentation/assets/icons/request-cancellation.svg'
// import { ReactComponent as RequestedDeniedIcon } from 'presentation/assets/icons/request-denied.svg'
import { ReactComponent as CancelledIcon } from 'presentation/assets/icons/cancelled-timeline.svg'
import { ReactComponent as DocumentInvalidatedIcon } from 'presentation/assets/icons/document-invalidated.svg'
import { ReactComponent as DocumentValidatedIcon } from 'presentation/assets/icons/document-validated.svg'
import { ReactComponent as EventAvailableIcon } from 'presentation/assets/icons/event-available.svg'
import { ReactComponent as RequestedEventIcon } from 'presentation/assets/icons/request-event.svg'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderContext } from 'main/factories/pages/surgical-order/surgical-order-factory'
import { toast } from 'react-toastify'
import {
  TimelineModel,
  SurgicalTimeLineActions
} from 'domain/usecases/surgical-order/get-surgical-order-timeline'
import theme from 'presentation/styles/theme'
import moment from 'moment-timezone'
import { useHistory } from 'react-router'
import SupportTextNew from 'presentation/components/SupportTextNew'
import AvatarNew from 'presentation/components/AvatarNew'
import ButtonNew from 'presentation/components/ButtonNew'
import { ProfileLocated } from 'common/enum/profile'

type TimelineTabProps = {
  timelineItemsActions?: any
} & WithLoadingProps

const TimelineTab = ({
  setIsLoading,
  timelineItemsActions
}: TimelineTabProps) => {
  const { state, dispatch } = useContext<any>(SurgicalOrderContext)
  const surgicalOrderService = useServices().surgicalOrder
  const [isTimelineExpanded, setIsTimelineExpanded] = useState(false)
  const history = useHistory()

  const getSurgicalOrderTimeline = async () => {
    setIsLoading(true)
    try {
      const response = await surgicalOrderService.getSurgicalOrderTimeline({
        surgical_order_id: state.card.surgical_order_id
      })

      dispatch({
        type: 'TIMELINE_TAB',
        payload: response.data.reverse()
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar os documentos')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getSurgicalOrderTimeline()
  }, [])

  const goToItem = (item: TimelineModel) => {
    history.push('/pedido-cirurgico/pendencia', {
      surgical_pendency_id: item.surgical_pendency_id
    })
  }

  const canRedirect = (item: TimelineModel) => {
    return Boolean(item.surgical_pendency_id)
  }

  const canClick = (item: TimelineModel) => {
    return item.action_type && timelineItemsActions[item.action_type]
  }

  const onItemClick = (item: TimelineModel) => {
    if (canClick(item)) {
      timelineItemsActions[item.action_type]()
    } else if (canRedirect(item)) {
      goToItem(item)
    }
  }

  const itemIcon = (action_type: SurgicalTimeLineActions) => {
    switch (action_type) {
      case 'CREATED_SURGICAL_ORDER_PENDENCY':
      case 'ANSWERED_SURGICAL_ORDER_PENDENCY':
      case 'APPROVED_SURGICAL_ORDER_PENDENCY':
        return <PendingIcon />
      case 'INCLUDED_SURGICAL_ORDER_DOCUMENT':
        return <DocumentAddedIcon />
      case 'UPDATED_STATUS_DOCUMENT_PENDENCY_VALIDATED':
        return <DocumentValidatedIcon />
      case 'UPDATED_STATUS_DOCUMENT_PENDENCY_INVALIDATED':
        return <DocumentInvalidatedIcon />
      case 'SOLICITATION_SURGICAL_ORDER_SCHEDULE':
        return <RequestedEventIcon />
      case 'SCHEDULED_SURGICAL_ORDER':
        return <EventAvailableIcon />
      case 'UPDATED_SURGICAL_ORDER_CANCELLED_STATUS':
        return <CancelledIcon />
      case 'CREATED_SURGICAL_ORDER':
      case 'SOLICITATION_SURGICAL_ORDER':
      case 'UPDATED_SURGICAL_ORDER_STATUS':
      case 'UPDATED_SURGICAL_ORDER_AUTHORIZATION_STATUS':
      case 'REQUESTED_CANCELLATION_SURGICAL_ORDER':
      default:
        return <SystemIcon />
    }
  }

  const historyToShow = () => {
    return isTimelineExpanded ? state.timeline : state?.timeline?.slice(0, 5)
  }

  return (
    <S.TabWrapper>
      <SupportTextNew color="neutral700" weight="bold">
        Atualizações sobre o pedido
      </SupportTextNew>
      <S.TimelineContainer>
        {historyToShow()?.map((item: TimelineModel) => (
          <S.TimelineItem
            key={item.surgical_time_line_id}
            onClick={() => onItemClick(item)}
          >
            <AvatarNew
              color="primary400"
              active
              size="xlarge"
              style={{
                zIndex: 3,
                border: `2px solid ${theme.ds.colors.primaryLight100}`
              }}
              icon={itemIcon(item.action_type)}
            />
            <S.ItemInfo>
              <SupportTextNew color="neutral900">{item.action}</SupportTextNew>
              <SupportTextNew
                weight="semiBold"
                color="neutral900"
                size="xsmall"
                capitalize
              >
                {ProfileLocated[item.persona as keyof typeof ProfileLocated]}{' '}
                <S.Dot />{' '}
                {moment
                  .utc(item.created_at)
                  .format('DD [de] MMM[. de] YYYY [às] HH[h]mm')}
              </SupportTextNew>
            </S.ItemInfo>
            {canRedirect(item) ||
              (canClick(item) && (
                <Arrow style={{ width: '16px', height: '16px' }} />
              ))}
          </S.TimelineItem>
        ))}
      </S.TimelineContainer>
      {state.timeline?.length > 5 && (
        <ButtonNew
          variant="text"
          onClick={() => setIsTimelineExpanded(!isTimelineExpanded)}
        >
          {isTimelineExpanded ? 'Ver menos' : 'Ver histórico'}
        </ButtonNew>
      )}
    </S.TabWrapper>
  )
}

export default WithLoading(TimelineTab)
