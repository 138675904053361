import { Type } from 'class-transformer'
import { ClassProps } from 'common/utils/getClassProps'

export class DoctorRegisterReview {
  doctor_register_review_id!: number
  text!: string
  isSolved!: boolean
  created_by_id!: number
  doctor_register_step_id!: number

  @Type(() => Date)
  createdAt!: Date

  @Type(() => Date)
  updatedAt!: Date

  constructor(props?: Partial<DoctorRegisterReviewProps>) {
    if (props) {
      Object.assign(this, props)
    }
  }
}

type DoctorRegisterReviewProps = ClassProps<
  typeof DoctorRegisterReview.prototype
>
