import SurgeryStatusDetailLayout from 'presentation/layouts/SurgeryStatusDetail'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

export default function SurgeryStatusDetail() {
  const status = useLocation<{ status?: string }>().state?.status
  const history = useHistory()

  useEffect(() => {
    if (!status) {
      history.goBack()
    }
  }, [])

  if (!status) return <></>

  return <SurgeryStatusDetailLayout status={status} />
}
