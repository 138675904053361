import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

import * as S from './styles'
import HeaderNew from '../HeaderNew'
import { ContainerNew } from '../ContainerNew'
import { PendencyCard } from 'presentation/shared/components/PendencyCard'
import SupportTextNew from '../SupportTextNew'
import ButtonNew from '../ButtonNew'

export const SurgicalPendencyList = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const patientService = useServices().patient
    const [showMore, setShowMore] = useState(false)
    const [pendenciesData, setPendenciesData] = useState<any>([])

    const loadPendencies = async () => {
      try {
        setIsLoading(true)
        const response = await patientService.getPatientPendency({
          pageNumber: 1,
          pageSize: 2
        })
        setPendenciesData(response.surgical_orders)
      } catch (error: any) {
        toast.error('Falha na busca das pendências.')
      } finally {
        setIsLoading(false)
      }
    }

    const goToSurgicalOrder = (surgical_order_id: number) => {
      history.push('/pedido-cirurgico/detalhes', {
        surgical_order_id
      })
    }

    useEffect(() => {
      loadPendencies()
    }, [])

    return (
      <>
        <HeaderNew
          actualPageTitle="Pendências"
          actualPageOnClick={() => history.push('/home')}
        />
        <ContainerNew noHeader>
          <SupportTextNew weight="bold" size="large" color="neutral600">
            Pendências
          </SupportTextNew>
          <SupportTextNew
            size="small"
            color="neutral800"
            style={{ marginTop: '16px' }}
          >
            Verifique as pendências decorrentes do seu processo cirúrgico
          </SupportTextNew>
          {pendenciesData.map((pendency: any, index: number) =>
            !showMore ? (
              index < 2 && (
                <PendencyCard
                  pendency={pendency}
                  key={index}
                  onClick={goToSurgicalOrder}
                />
              )
            ) : (
              <PendencyCard
                pendency={pendency}
                key={index}
                onClick={goToSurgicalOrder}
              />
            )
          )}
          <S.LoadMoreButton data-testid="btn-load-more">
            <ButtonNew
              onClick={() => setShowMore(!showMore)}
              size="large"
              fullWidth
            >
              {showMore ? 'Ver menos' : 'Ver mais'}
            </ButtonNew>

            {pendenciesData.length === 0 && (
              <S.NoPendenciesLabel>
                Sua pesquisa não encontrou nenhum resultado correspondente
              </S.NoPendenciesLabel>
            )}
          </S.LoadMoreButton>
        </ContainerNew>
      </>
    )
  }
)
