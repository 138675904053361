import { DoctorRepository } from 'repository/interfaces/doctor-repository'
import { LoadDoctorRegisterPendenciesGroupedDate } from '../../../domain/usecases/doctor/load-doctor-register-pendencies-grouped-date'

export class RemoteLoadDoctorRegisterPendenciesGroupedDate
  implements LoadDoctorRegisterPendenciesGroupedDate
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  execute(
    params: LoadDoctorRegisterPendenciesGroupedDate.Params
  ): Promise<LoadDoctorRegisterPendenciesGroupedDate.Model> {
    return this.doctorRepository.loadDoctorRegisterPendenciesGroupedDate(params)
  }
}
