import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import * as S from './styles'

type TextButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLSpanElement>

export type TextButtonProps = {
  as?: 'button' | 'a'
  size?: 'small' | 'medium'
  underline?: boolean
} & TextButtonTypes

const TextButton = ({
  size = 'medium',
  underline = false,
  ...props
}: TextButtonProps) => {
  return (
    <S.Wrapper size={size} underline={underline} {...props}>
      {!!props.children && props.children}
    </S.Wrapper>
  )
}

export default TextButton
