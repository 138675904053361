import React, { createContext, useReducer } from 'react'
import SurgicalOrderDetails from 'presentation/pages/SurgicalOrderDetails'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { SurgicalOrderStatus } from 'common/enum/surgical-orders-status'

export const SurgicalOrderContext = createContext({})

type Types =
  | 'SURGICAL_ORDER'
  | 'SURGICAL_PENDENCIES'
  | 'CURRENT_SURGICAL_PENDENCY'
  | 'CLEAR_CURRENT_PENDENCY'
  | 'TIMELINE_TAB'

type Payload = any

export type Actions = {
  payload: Payload
  type: Types
}

export type ContextType = {
  dispatch: (params: Actions) => void
  state: {
    card: SurgicalOrderModel
    footer: {
      status: SurgicalOrderStatus
      created_at: Date
    }
    pendencies: {
      in_progress: any[]
      pendent: any[]
      resolved: any[]
    }
    currentPendency: any
  }
}

const reducer = (state: any, action: Actions) => {
  switch (action.type) {
    case 'SURGICAL_ORDER':
      return {
        ...state,
        card: action.payload.card,
        footer: action.payload.footer
      }
    case 'SURGICAL_PENDENCIES':
      return { ...state, pendencies: action.payload }
    case 'CURRENT_SURGICAL_PENDENCY':
      return { ...state, currentPendency: action.payload }
    case 'CLEAR_CURRENT_PENDENCY':
      return { ...state, currentPendency: {} }
    case 'TIMELINE_TAB':
      return { ...state, timeline: action.payload }
    default:
      return state
  }
}

export default function makeSurgicalOrderPage() {
  const [state, dispatch] = useReducer(reducer, {
    card: {
      surgical_order_id: 0,
      scheduling_status: '',
      patient: {
        patient_id: 1,
        name: ''
      },
      health_insurance: {
        health_insurance_id: 0,
        name: '',
        health_insurance_status: []
      },
      hospital: {
        hospital_id: 1,
        name: ''
      },
      procedures: [],
      scheduling_date: [],
      pendencies: [],
      status: [],
      active_status: '',
      isSolicitation: false,
      finalize_surgical_order: {
        password_max_date_validation: '',
        password_max_date_validation_status: ''
      }
    },
    footer: {
      status: '',
      created_at: ''
    },
    pendencies: {
      in_progress: [],
      pendent: [],
      resolved: []
    },
    currentPendency: {
      historic: [],
      documents: []
    }
  })

  return (
    <SurgicalOrderContext.Provider value={{ state, dispatch }}>
      <SurgicalOrderDetails />
    </SurgicalOrderContext.Provider>
  )
}
