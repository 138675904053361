import React, { useState } from 'react'
import LoadingModal from 'presentation/shared/components/LoadingModal'

export type WithLoadingProps = {
  setIsLoading: (state: boolean) => void
  isLoading?: boolean
}

export function WithLoading<T extends WithLoadingProps = WithLoadingProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithLoading = (props: Omit<T, keyof WithLoadingProps>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleLoading = (state: boolean) => {
      if (!state) {
        setTimeout(() => {
          setIsLoading(state)
        }, 500)
      } else {
        setIsLoading(state)
      }
    }

    return (
      <>
        <LoadingModal show={isLoading} />
        <WrappedComponent
          {...(props as T)}
          setIsLoading={handleLoading}
          isLoading={isLoading}
        />
      </>
    )
  }

  ComponentWithLoading.displayName = `withLoading(${displayName})`

  return ComponentWithLoading
}
