import styled, { css } from 'styled-components'

export const Container = styled.div`
  /* background-color: ${({ theme }) => theme.ds.colors.white}; */
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  font-size: 16px;
  justify-content: flex-start;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  /* Loading */
  img {
    display: flex;
    margin: auto;
    justify-self: center;
    align-items: center;
  }
`

export const CardsWrapper = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ShowMoreContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 8px 16px;

  button {
    width: 100%;
  }
`

export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

export const CardScheduling = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.ds.typography.fontFamily};
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${theme.ds.colors.white};
    padding: 24px 16px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    > .top-header {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${theme.ds.colors.neutral200};

      .icon {
        width: 20px;
        height: 20px;

        svg,
        path {
          fill: ${theme.ds.colors.primary600};
        }
      }

      .date-box {
        font-size: ${theme.ds.typography.size.small};
        color: ${theme.ds.colors.primary600};
        font-weight: ${theme.ds.typography.weight.bold};

        .date {
          text-transform: capitalize;
        }
      }
    }

    > .header {
      display: flex;

      .img {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .header-infos {
        display: flex;
        flex-direction: column;
        /* gap: 4px; */

        > .title {
          font-size: ${theme.ds.typography.size.small};
          color: ${theme.ds.colors.neutral900};
          line-height: ${theme.ds.typography.lineHeight.medium};

          .prefix {
            font-weight: ${theme.ds.typography.weight.semiBold};
            font-size: ${theme.ds.typography.size.xsmall};
          }

          .text {
            text-transform: capitalize;
            font-weight: ${theme.ds.typography.weight.bold};
            font-size: ${theme.ds.typography.size.small};
          }
        }

        > .subtitle {
          display: flex;
          align-items: center;
          font-size: ${theme.ds.typography.size.xsmall};
          color: ${theme.ds.colors.neutral600};
          font-weight: ${theme.ds.typography.weight.normal};
          line-height: ${theme.ds.typography.lineHeight.medium};
          text-transform: capitalize;

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
    }

    > .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > .localization-box {
        display: flex;
        align-items: flex-start;
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        > .infos {
          > .title {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
            line-height: ${theme.ds.typography.lineHeight.medium};
            text-transform: capitalize;
          }

          > .description {
            text-transform: capitalize;
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral600};
            font-weight: ${theme.ds.typography.weight.normal};
            line-height: ${theme.ds.typography.lineHeight.medium};
          }
        }
      }

      > .action-box {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 8px;
      }
    }
  `}
`

export const ResultMessage = styled.div`
  padding: 22px 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: ${({ theme }) => theme.ds.colors.neutral800};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
    margin-bottom: 20px;
  }
`

export const CancelOrRescheduleModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral200};

    .btn {
      transition: opacity 0.2s;
      width: 12px;
      height: 12px;
      svg,
      path {
        fill: ${({ theme }) => theme.ds.colors.neutral500};
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .content {
    text-align: center;
    margin-bottom: 48px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: auto;
    margin-bottom: 0;
  }
`

export const PurpleStrong = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: ${({ theme }) => theme.ds.colors.secondary600};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const BlackStrong = styled.strong`
  display: flex;
  gap: 5px;
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
`

export const TextDescription = styled.span`
  color: ${({ theme }) => theme.ds.colors.neutral600};
  margin-bottom: 18px;
  margin-left: 21px;
`

export const HeaderCard = styled.div`
  display: flex;

  svg {
    path {
      fill: ${({ theme }) => theme.ds.colors.secondary600};
    }
  }
`

export const Title = styled.strong`
  display: flex;
  gap: 5px;
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-size: ${({ theme }) => theme.ds.typography.size.large};
  font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
`

export const TextSheet = styled.p`
  color: ${({ theme }) => theme.ds.colors.neutral900};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  font-size: ${({ theme }) => theme.ds.typography.size.medium};
  margin-bottom: 10px;
  text-align: left;
`

export const SheetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;

  svg {
    cursor: pointer;
  }
`

export const BigText = styled.div`
  display: flex;
  color: ${({ theme }) => theme.ds.colors.black};
  font-size: ${({ theme }) => theme.ds.typography.size.xxlarge};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  text-align: center;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral300};
  border-radius: 8px;
  /* padding: 10px 0px 10px 34px; */
  /* letter-spacing: 30px; */

  &:first-of-type {
    padding: 10px 23.7px;
  }

  padding: 10px 20px;
`

export const SplitedCodeContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 340px;
  justify-content: space-between;
  /* gap: 29px; */
  align-self: center;
  margin: 20px;
  /* margin: auto; */
`

export const SheetWrapper = styled.div`
  display: contents;
  overflow: hidden;

  button {
    margin-top: auto;
  }
`

export const Loading = styled.img`
  width: 50px;
`

export const ButtonContainer = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
  display: grid;
`
