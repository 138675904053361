import React from 'react'

import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import MySurgeryImage from 'presentation/assets/banners/surgical-journey/surgical-order.svg'
import * as S from './styled'

export const SlideSurgicalOrder = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Pedido cirúrgico
        </HeadingNew>
        <S.Image src={MySurgeryImage} alt="Mulher preenchendo cadastro" />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            O pedido cirúrgico é essencial na <b>Rede Mater Dei de Saúde</b>,
            garantindo o sucesso do processo de autorização e agendamento de
            cirurgias.
          </SupportTextNew>
          <SupportTextNew color="neutral700">
            É obrigatório o envio do cadastro e documentação aqui pela
            plataforma, proporcionando uma forma ágil e descomplicada de
            solicitar a cirurgia.
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
