import { GetExamByItem } from 'domain/usecases/exam/get-exam-by-item'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamByItem implements GetExamByItem {
  constructor(private readonly examRepository: ExamRepository) {}
  async get(params: GetExamByItem.Params): Promise<GetExamByItem.Model> {
    return this.examRepository.getExamByItem(params)
  }
}

export type GetExamByItemModel = GetExamByItem.Model
