import React, { AnchorHTMLAttributes } from 'react'

import theme from 'presentation/styles/theme'
import * as S from './styles'

type HeadingTypes = AnchorHTMLAttributes<HTMLAnchorElement>

export type HeadingProps = {
  children: React.ReactNode
  color?: keyof typeof theme.ds.colors
  size?: keyof typeof theme.ds.typography.size
  weight?: keyof typeof theme.ds.typography.weight
  lineHeight?: keyof typeof theme.ds.typography.lineHeight
  flexStart?: boolean
  as?: React.ElementType
  required?: boolean
} & HeadingTypes

const HeadingNew = ({
  children,
  required,
  color = 'neutral900',
  size = 'medium',
  weight = 'bold',
  lineHeight = 'medium',
  ...props
}: HeadingProps) => (
  <S.Wrapper
    {...props}
    color={color}
    size={size}
    weight={weight}
    lineHeight={lineHeight}
    required={required}
  >
    {children}
    {required && <span>*</span>}
  </S.Wrapper>
)

export default HeadingNew
