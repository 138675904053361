import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/pt-br'
import { toast } from 'react-toastify'

import { useServices } from 'presentation/hooks/use-services'
import BottomMenu from 'presentation/components/BottomMenu'
import ProfilePic from 'presentation/components/ProfilePic'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as PinIcon } from 'presentation/assets/icons/new_pin.svg'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/new_close.svg'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import * as S from './styles'
import { FormattedSchedule } from 'domain/entities/schedule-model'
import HeadingNew from 'presentation/components/HeadingNew'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import HeaderNew from 'presentation/components/HeaderNew'

const AppointmentsLayout = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const history = useHistory()
  const schedulingService = useServices().scheduling
  const patientService = useServices().patient
  const [schedules, setSchedules] = useState<FormattedSchedule[]>([])
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [cancelOrRescheduleItem, setCancelOrRescheduleItem] = useState<
    FormattedSchedule | undefined
  >(undefined)

  const orderByDateDESC = (a: any, b: any) => {
    const aDate: any = new Date(a.date)
    const bDate: any = new Date(b.date)
    return aDate - bDate
  }

  // const orderByDateASC = (a: Schedule, b: Schedule) => {
  //   const aDate: any = new Date(a.scheduleTime)
  //   const bDate: any = new Date(b.scheduleTime)
  //   return bDate - aDate
  // }

  // const showCancelOrRescheduleModal = (schedule: Schedule) => {
  //   setCancelOrRescheduleItem(schedule)
  //   setOpenModal(true)
  // }

  const hideCancelOrRescheduleModal = () => {
    setCancelOrRescheduleItem(undefined)
    setOpenModal(false)
  }

  const handleCancelSchedule = () => {
    if (!cancelOrRescheduleItem) {
      hideCancelOrRescheduleModal()
      return toast.warning(
        'Não foi possível cancelar o agendamento, tente mais tarde.'
      )
    }
    history.push('/consulta/cancelamento', {
      schedule: cancelOrRescheduleItem
    })
  }

  async function loadPatient() {
    return await patientService.loadPatientInfo(['patient_id'])
  }

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const patient = await loadPatient()

        const patientSchedules = await schedulingService.listSchedules(
          [
            'schedule_id',
            'cd_it_agenda_central',
            'created_at',
            'date',
            `specialty {
              name
              specialty_id
            }`,
            `patient {
              user_id
              patient_id
              name
              email
              age
              gender
            }`,
            `doctor {
              doctor_id
              name
            }`,
            `unit {
              unit_id
              name
              description
            }`,
            `healthInsurance {
              health_insurance_id
              plan_id
              name
              plan_name
            }`
          ],
          patient.patient_id,
          {
            startDate: new Date().toISOString(),
            endDate: moment(new Date())
              .add({
                years: 5
              })
              .toISOString()
          }
        )

        const formatSchedules = patientSchedules.listSchedules.map(
          (schedule) => ({
            ...schedule
          })
        )
        formatSchedules.sort(orderByDateDESC)
        setSchedules(formatSchedules)
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar consultas')
      } finally {
        setIsLoading(false)
        setFinishLoading(true)
      }
    })()
  }, [])

  return (
    <S.Wrapper>
      <HeaderNew
        actualPageTitle="Minhas consultas"
        actualPageOnClick={() =>
          history.push('/servicos', {
            anchor: 'appointments'
          })
        }
      />
      <ContainerNew noHeader>
        <S.CardsWrapper>
          {finishLoading &&
            (schedules.length ? (
              schedules.map((schedule, index) => (
                <S.CardScheduling key={index}>
                  <div className="top-header">
                    <CalendarIcon className="icon" />
                    <div className="date-box">
                      <span className="date">
                        {moment(schedule.date).format('dddd - DD/MM/YYYY')}
                      </span>{' '}
                      às {moment(schedule.date).format('LT')}
                    </div>
                  </div>
                  <div className="header">
                    <div className="img">
                      <ProfilePic
                        data-testid="profile-pic"
                        outlined
                        size="medium"
                        name={schedule.doctor.name}
                      />
                    </div>
                    <div className="header-infos">
                      <h2 className="title">
                        <span className="prefix">Dr(a)</span>{' '}
                        <span className="text">
                          {schedule.doctor.name?.toLocaleLowerCase()}
                        </span>
                      </h2>
                      <h4 className="subtitle">
                        {schedule.specialty.name?.toLocaleLowerCase()}
                      </h4>
                    </div>
                  </div>
                  <div className="content">
                    <div className="localization-box">
                      <PinIcon className="icon" />
                      <div className="infos">
                        <h6 className="title">
                          {schedule.unit?.name?.toLocaleLowerCase()}
                        </h6>
                        <p className="description">
                          {schedule.unit?.description?.toLocaleLowerCase()}
                        </p>
                      </div>
                    </div>
                    <div className="action-box">
                      <ButtonNew
                        type="button"
                        size="small"
                        variant="text"
                        // onClick={() => showCancelOrRescheduleModal(schedule)}
                        onClick={() =>
                          history.push('/consulta/cancelamento', {
                            schedule
                          })
                        }
                      >
                        Não posso ir
                      </ButtonNew>
                      <ButtonNew
                        type="button"
                        size="small"
                        onClick={() =>
                          history.push('/consulta/detalhes', {
                            schedule
                          })
                        }
                      >
                        Mais detalhes
                      </ButtonNew>
                    </div>
                  </div>
                </S.CardScheduling>
              ))
            ) : (
              <S.ResultMessage>
                <p>Você não possui nenhuma consulta agendada.</p>

                <ButtonNew
                  type="button"
                  onClick={() =>
                    history.push('/agendamento/consulta/confirmar-dados')
                  }
                >
                  Agendar consulta
                </ButtonNew>
              </S.ResultMessage>
            ))}
        </S.CardsWrapper>
      </ContainerNew>
      <SheetModalNew
        isDraggable
        onClose={hideCancelOrRescheduleModal}
        isOpen={openModal}
        size={325}
      >
        <S.CancelOrRescheduleModal>
          <div className="header">
            <HeadingNew as="h6" weight="semiBold">
              Não posso ir
            </HeadingNew>
            <ButtonNew
              className="btn"
              type="button"
              variant="text"
              noPadding
              onClick={hideCancelOrRescheduleModal}
            >
              <CloseIcon />
            </ButtonNew>
          </div>
          <div className="content">
            <SupportTextNew weight="semiBold" color="neutral600">
              Gostaria de reagendar sua consulta?
            </SupportTextNew>
          </div>
          <div className="footer">
            <ButtonNew
              type="button"
              fullWidth
              size="medium"
              // onClick={}
              disabled
            >
              Sim, reagendar
            </ButtonNew>

            <ButtonNew
              type="button"
              fullWidth
              variant="text"
              size="medium"
              onClick={handleCancelSchedule}
            >
              Não, cancelar
            </ButtonNew>
          </div>
        </S.CancelOrRescheduleModal>
      </SheetModalNew>
      <BottomMenu />
    </S.Wrapper>
  )
})

export default AppointmentsLayout
