import React, { useState } from 'react'
import PendencyCard, {
  PendencyTypes
} from 'presentation/components/PendencyCard'
import AvatarNew from 'presentation/components/AvatarNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import * as S from './styles'
import theme from 'presentation/styles/theme'
import { PendencyStatusPlural } from 'common/enum/pendency-status'
import { useScrollContainer } from 'react-indiana-drag-scroll'
import PendencyModal from '../../Modals/PendencyModal'

type Props = {
  pendencies: any
  getPendencyHistory: (
    surgical_pendency_id: number,
    pendency_type?: PendencyTypes
  ) => void
  dispatch: ({ action }: { action: string }) => void
}

const PendencieTab = ({ pendencies, getPendencyHistory, dispatch }: Props) => {
  const [pendencyModalIsOpen, setPendencyModalIsOpen] = useState(false)
  const [pendencyOrderId, setPendencyOrderId] = useState<any>(null)

  const containerScroll = useScrollContainer()
  const sections = [
    {
      key: 'pendent',
      color: 'terciary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência a ser resolvida no momento.'
    },
    {
      key: 'in_progress',
      color: 'secondary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência em andamento no momento.'
    },
    {
      key: 'resolved',
      color: 'primary400' as keyof typeof theme.ds.colors,
      emptyState: 'Não há nenhuma pendência resolvida  no momento.'
    }
  ]

  const showPendency = (
    surgical_pendency_id: number,
    pendency_type?: PendencyTypes,
    pendency_order_id?: number
  ) => {
    setPendencyModalIsOpen(true)
    getPendencyHistory(surgical_pendency_id, pendency_type)
    setPendencyOrderId(pendency_order_id)
  }

  const hidePendency = () => {
    dispatch({
      action: 'CLEAR_CURRENT_PENDENCY'
    })
    setPendencyModalIsOpen(false)
  }

  return (
    <>
      <S.TabWrapper>
        {sections.map((section) => (
          <S.PendencyStatusSection key={section.key}>
            <S.PendencyStatusHeader>
              <AvatarNew
                color={section.color}
                text={pendencies[section.key].length}
                active
              />
              <SupportTextNew
                color="neutral700"
                weight="bold"
                className="capitalize-fl"
              >
                {
                  PendencyStatusPlural[
                    section.key.toUpperCase() as keyof typeof PendencyStatusPlural
                  ]
                }
              </SupportTextNew>
            </S.PendencyStatusHeader>

            {pendencies[section.key].length ? (
              <S.PendenciesListContainer ref={containerScroll.ref}>
                {pendencies[section.key].map((pendency: any) => (
                  <PendencyCard
                    key={pendency.surgical_pendency_id}
                    pendency={pendency}
                    onClick={() =>
                      showPendency(
                        pendency.surgical_pendency_id,
                        pendency.type,
                        pendency.surgical_pendency_order_id
                      )
                    }
                  />
                ))}
              </S.PendenciesListContainer>
            ) : (
              <SupportTextNew>{section.emptyState}</SupportTextNew>
            )}
          </S.PendencyStatusSection>
        ))}
      </S.TabWrapper>

      <PendencyModal
        isOpen={pendencyModalIsOpen}
        onClose={hidePendency}
        getPendencyHistory={getPendencyHistory}
        hidePendency={hidePendency}
        pendencyOrderId={pendencyOrderId}
      />
    </>
  )
}

export default PendencieTab
