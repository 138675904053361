import { Interval } from 'service/protocols/api/api-client'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'
import { ListSchedules } from 'domain/usecases/scheduling/list-schedules'

export class RemoteListSchedules implements ListSchedules {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(
    params?: ListSchedules.Params,
    patient_id?: number,
    interval?: Interval
  ): Promise<any> {
    return this.schedulingRepository.listSchedules(params, patient_id, interval)
  }
}

export type ListSchedulesModel = ListSchedules.Model
