import { CreateRecommendationWithoutDoctor } from 'domain/usecases/doctor/create-recommendation-without-doctor'
import { RecommendationRepository } from 'repository/interfaces/recommendation-repository'

export class RemoteCreateRecommendationWithoutDoctor
  implements CreateRecommendationWithoutDoctor
{
  constructor(
    private readonly recommendationRepository: RecommendationRepository
  ) {}

  add(
    params: CreateRecommendationWithoutDoctor.Params
  ): Promise<CreateRecommendationWithoutDoctor.Model> {
    return this.recommendationRepository.createRecommendationWithoutDoctor(
      params
    )
  }
}

export type CreateRecommendationWithoutDoctorModel =
  CreateRecommendationWithoutDoctor.Model
