import { GetExamAvailableUnits } from 'domain/usecases/exam/get-exam-available-units'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamAvailableUnits implements GetExamAvailableUnits {
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: GetExamAvailableUnits.Params
  ): Promise<GetExamAvailableUnits.Model> {
    return this.examRepository.getExamAvailableUnits(params)
  }
}

export type GetExamAvailableUnitsModel = GetExamAvailableUnits.Model
