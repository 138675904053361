import React from 'react'

import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import StatusAnalyzingHealthInsuranceImage from 'presentation/assets/banners/surgical-journey/status-analyzing-health-insurance.svg'
import * as S from './styled'

export const SlideHealthInsuranceAnalysis = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Em análise no convênio
        </HeadingNew>
        <S.Image
          src={StatusAnalyzingHealthInsuranceImage}
          alt="Pessoas analisando informações do convênio"
        />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            Nesta fase o pedido do seu procedimento cirúrgico está sendo
            analisado pelo convênio, responsável pela autorização.
          </SupportTextNew>
          <SupportTextNew color="neutral700">
            De acordo com a Agência Nacional de Saúde Suplementar (ANS), esse
            período pode durar até 21 dias úteis para cirurgias eletivas.
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
