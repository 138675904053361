import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.border.radius.small};
`

export const ButtonContainer = styled.div`
  margin: 20px 0 0 0;
`
