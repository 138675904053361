import React from 'react'

export default function PatientTerms() {
  return (
    <>
      <div>
        A presente política contém informações sobre coleta, uso, armazenamento,
        tratamento e proteção dos dados pessoais dos usuários, e visitantes do
        aplicativo e/ou site, com a finalidade de demonstrar a absoluta
        transparência quanto ao assunto e esclarecer todos os interessados sobre
        os tipos de dados que serão coletados, para o que usamos, onde
        armazenamos e com quem compartilhamos.
      </div>
      <div>
        <br />
      </div>
      <div>
        Esta Política de Privacidade e Proteção de Dados Pessoais expressa nosso
        compromisso com o tratamento de seus dados pessoais de modo responsável,
        ético, em linha com nossos princípios e valores e, especialmente, de
        acordo com as regras da Lei nº 13.709/2018 (Lei Geral de Proteção de
        Dados Pessoais - “LGPD”) e demais legislações vigentes aplicáveis.
        Reconhecemos que esta é uma responsabilidade constante e, portanto,
        atualizaremos periodicamente esta Política à medida que se fizerem
        necessários novos procedimentos de privacidade e proteção de seus dados
        pessoais.
      </div>
      <div>
        <br />
      </div>
      <div>
        Ao utilizar nossos serviços, bem como nosso site ou aplicativo, você
        concorda com o tratamento de seus dados pessoais como está descrito
        nesta Política de Privacidade e Proteção de Dados Pessoais.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Como coletamos os seus dados pessoais:</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Coletamos seus dados pessoais e dados pessoais sensíveis automaticamente
        quando você visita nosso site, utiliza nossos aplicativos, e-mails ou
        anúncios. Também coletamos seus dados pessoais durante a prestação de
        nossos serviços. Assim, a coleta de dados pode advir das seguintes
        fontes:
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>
          • Diretamente do titular: podemos coletar dados pessoais que você nos
          forneceu
        </strong>
        ;
      </div>
      <div>
        <br />
      </div>
      <div>
        •&emsp;Via interação com o nosso site e/ou aplicativo: através de sua
        interação em nosso site, nosso aplicativo, podemos coletar dados
        pessoais sobre você para a prestação de serviços . Além disso, podemos
        coletar ou obter dados pessoais sobre você pela maneira como você
        interage com nosso site e/ou aplicativo para fornecer informações que
        acreditamos ser de seu interesse;
      </div>
      <div>
        •&emsp;A partir de terceiros: também podemos coletar seus dados pessoais
        por meio de parceiros, médicos ou fornecedores. Neste caso, adotamos
        medidas para garantir que foram cumpridas as regras de privacidade e
        proteção de dados pessoais, dispostas na LGPD, incluindo a coleta de
        termo de consentimento, se assim for necessário.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Tipos de dados pessoais que coletamos:</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Clientes e usuários do nosso site/aplicativo: durante a prestação de
        nossos serviços ou mesmo durante a navegação em nosso site e aplicativo,
        podemos coletar alguns dados pessoais sobre você, tais como:
      </div>
      <div>
        <br />
      </div>
      <div>
        •&emsp;Dados de identificação: nome, RG, CPF, idade, sexo, endereço de
        e-mail, endereço comercial/residencial, telefone, data de nascimento,
        estado civil, carteirinha do plano, filiação, convênio médico,
        documentos para fins de identidade (tais como documentos de conselho de
        classe, carteira nacional de habilitação, número do passaporte e outros
        documentos oficiais) e imagens de circuito interno de câmeras;
      </div>
      <div>
        •&emsp;Dados sensíveis: dados relacionados a sua saúde, relatórios
        médicos, solicitações médicas, resultados de exames, medicamentos que
        você utiliza, dados genéticos, dados biométricos, etnia, orientação
        sexual e religião;
      </div>
      <div>
        •&emsp;Dados financeiros: informações sobre pagamento, conta bancária e
        dados de cartões de crédito;
      </div>
      <div>
        •&emsp;Dados de navegação: login e senha de acesso, postagens em nossas
        mídias sociais, endereço IP, tipo de navegador e idioma, horários de
        acesso, detalhes de solicitações e de como você usa nossos serviços e de
        sua interação conosco. Além disso e prezando pela transparência na
        relação com nossos clientes sinalizamos que também coletamos cookies
        quando você acessa nosso site e/ou aplicativo, para melhor experiência
        do usuário;
      </div>
      <div>
        •&emsp;Acompanhantes de clientes: durante a prestação de nossos serviços
        podemos coletar informações sobre seus acompanhantes presentes durante a
        realização de algum exame ou procedimento;
      </div>
      <div>
        •&emsp;Dados de identificação: nome, RG, CPF e imagens de circuito
        interno de câmeras;
      </div>
      <div>
        •&emsp;Médicos: durante a prestação de nossos serviços podemos coletar
        informações sobre os médicos responsáveis pelas solicitações dos exames;
      </div>
      <div>
        •&emsp;Dados de identificação: nome, especialidade profissional e CRM.
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Como utilizamos seus dados pessoais</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Coletamos, armazenamos e tratamos seus dados pessoais para diversas
        finalidades ligadas ao nosso negócio, tais como:
      </div>
      <div>Clientes e usuários do nosso site/aplicativo</div>
      <div>
        •&emsp;Dados identificação: para identificar e confirmar sua identidade
        para atendimentos, solicitar autorização junto às operadoras de planos
        de saúde, realizar agendamentos, divulgar produtos e serviços, assegurar
        o acesso às unidades do Hospital Mater Dei e atender às determinações
        legais e regulatórias;
      </div>
      <div>• Dados sensíveis: entender histórico;</div>
      <div>
        •&emsp;Dados financeiros: identificar, agendar e faturar pagamentos e
        atender questões fiscais, legais e regulatórias;
      </div>
      <div>
        •&emsp;Dados de navegação: agendar procedimentos e realizar atendimentos
        on-line, cumprir determinações legais de coleta de dados pessoais
        dispostas no Marco Civil da Internet (Lei nº 12.965/2014), promover
        melhorias na experiência de navegação e realizar análises estatísticas;
      </div>
      <div>•&emsp;Acompanhantes de clientes;</div>
      <div>
        •&emsp;Dados de identificação: para fins de identificação e controle de
        acesso.
      </div>
      <div>Médicos:</div>
      <div>
        •&emsp;Dados de identificação: para fins de identificação, confirmação
        da solicitação médica e registro.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Fundamentos legais para tratar os seus dados pessoais</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        A LGPD dispõe que o tratamento de dados pessoais apenas deve ocorrer
        mediante fundamento legal.
      </div>
      <div>
        Assim, destacamos abaixo as hipóteses legais em que os seus dados
        pessoais poderão ser tratados, quando estamos na posição de Controlador,
        aplicando-se de acordo com a categoria dos dados (dados pessoais ou
        dados pessoais sensíveis):
      </div>
      <div>
        a.&emsp;mediante o fornecimento do seu consentimento para tratamento de
        seus dados pessoais, como por exemplo, para lhe conceder acesso ao site,
        aplicativo ou outras plataformas mantidas pelo Hospital ou receber
        informações via e-mail sobre seus interesses;
      </div>
      <div>
        b.&emsp;quando existentes legítimos interesses para tratamento de seus
        dados pessoais como no oferecimento e entrega de nossos serviços para
        você, bem como para o funcionamento eficaz e lícito de nossa prestação
        de serviços, desde que tais interesses não sejam superados pelos seus
        interesses, direitos e liberdades fundamentais;
      </div>
      <div>
        c.&emsp;para o cumprimento de obrigações legais e regulatórias que podem
        exigir a coleta, armazenamento e compartilhamento de seus dados pessoais
        e dados pessoais sensíveis;
      </div>
      <div>
        d.&emsp;para executar eventual contrato, bem como para fornecer nossos
        serviços a você;
      </div>
      <div>
        e.&emsp;para exercer regularmente nossos direitos em contratos,
        processos judiciais, administrativos ou arbitrais;
      </div>
      <div>f.&emsp;para proteção da vida ou da sua incolumidade física;</div>
      <div>g.&emsp;para tutelar sua saúde;</div>
      <div>h.&emsp;para proteção de nosso crédito;</div>
      <div>
        i.&emsp;para garantir a prevenção à fraude e à sua segurança, nos
        processos de identificação e autenticação de cadastro em sistemas
        eletrônicos;
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Compartilhamento de seus dados pessoais</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        No decorrer da prestação de nossos serviços, poderemos compartilhar seus
        dados pessoais com:
      </div>
      <div>
        •&emsp;Hospitais, profissionais médicos e unidades de atendimento do
        Hospital para, por exemplo, exercer regularmente algum direito ou
        executar devidamente os serviços contratados;
      </div>
      <div>
        •&emsp;Terceiros que nos prestam serviços nas condições de operadores de
        tratamento de dados pessoais;
      </div>
      <div>
        •&emsp;Autoridades competentes (incluindo tribunais e autoridades que
        nos regulam);
      </div>
      <div>
        •&emsp;Empresas de tecnologia que fazem a gestão dos nossos sistemas
        integrados ou responsáveis pelo armazenamento e garantia de segurança no
        tratamento de seus dos dados pessoais; internamente para áreas que
        necessitam ter acesso aos dados pessoais.
      </div>
      <div>
        <br />
      </div>
      <div>
        Ao realizar interação com nosso site ou aplicativo, que eventualmente
        permitam compartilhar conteúdo com outros usuários, os dados pessoais e
        informações que você publicar podem ser lidas, coletadas e usadas por
        outros usuários do site ou aplicativo.
      </div>
      <div>
        <br />
      </div>
      <div>
        Temos pouco ou nenhum controle sobre esses outros usuários e, portanto,
        não podemos garantir que qualquer informação ou dado pessoal que você
        forneça nesse contexto será tratado de acordo com esta Política de
        Privacidade e Proteção de Dados Pessoais.
      </div>
      <div>
        <br />
      </div>
      <div>
        Além disso, ao realizar solicitações de atendimento, agendamentos,
        pré-atendimentos e fale conosco pelo nosso site, seus dados pessoais
        serão compartilhados com nossos atendentes e prestadores de serviços de
        infraestrutura dos sistemas geridos pelo Hospital, os quais são
        treinados e capacitados a tratar seus dados pessoais de forma ética e em
        linha com esta Política.
      </div>
      <div>
        <br />
      </div>
      <div>
        Outros compartilhamentos podem ser realizados com a finalidade de
        atender as suas solicitações e prestar devidamente os serviços
        contratados. Para informações detalhadas sobre os nomes dos terceiros
        com os quais compartilhamos seus dados pessoais, entre em contato com
        o(a) nosso(a) DPO/Encarregado.
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Dados de Crianças e Adolescentes</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Durante a prestação de serviços, o Hospital poderá coletar dados
        pessoais de crianças e adolescentes e garantirá que o tratamento sempre
        ocorra no melhor interesse da criança e do adolescente.
      </div>
      <div>
        Crianças: O tratamento de dados pessoais de crianças será realizado
        mediante consentimento específico e em destaque de pelo menos um de seus
        pais ou responsável legal. Em caso de urgência/emergência, a coleta e
        tratamento dos dados pessoais da criança será realizado imediatamente
        para proteção de sua vida e, posteriormente, será comunicado a um de
        seus pais ou responsável legal. As mesmas disposições serão aplicáveis
        aos dados pessoais de interditados e curatelados.
      </div>
      <div>
        Adolescentes: Em relação ao adolescente, o tratamento de seus dados
        pessoais poderá ocorrer independentemente do consentimento de um dos
        pais ou responsável legal, desde que presente outro fundamento legal que
        autorize o tratamento, nos termos da LGPD.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Retenção de seus dados pessoais</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Armazenamos e mantemos seus dados pessoais de forma segura em data
        centers localizados no Brasil, em conformidade com a legislação
        aplicável e pelo período necessário ou permitido em vista das
        finalidades para as quais os dados pessoais foram coletados, conforme
        exposto nesta Política de Privacidade e Proteção de Dados Pessoais.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Segurança de seus dados pessoais</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Estamos comprometidos em proteger a sua privacidade e seus dados. Para
        isso, adotamos medidas de segurança, técnicas e administrativas aptas a
        proteger os seus dados pessoais de acessos não autorizados e de
        situações acidentais ou ilícitas de destruição, perda, alteração,
        comunicação ou qualquer forma de tratamento inadequado ou ilícito, o que
        inclui, mas não se limita à:
      </div>
      <div>
        •&emsp;Limitação do acesso a dados pessoais por parte dos colaboradores,
        prestadores de serviços e visitantes, restringindo-o apenas nos limites
        da necessidade e finalidade de tratamento dos dados pessoais;
      </div>
      <div>
        •&emsp;Garantia de que os nossos colaboradores, prestadores de serviços
        e visitantes cumpram esta Política e todos os procedimentos adequados
        para o correto tratamento de dados pessoais;
      </div>
      <div>
        •&emsp;Utilização de tecnologias concebidas para proteger os dados
        pessoais durante a sua transmissão, como encriptação SSL dos dados que
        você fornece em determinadas partes do nosso site e utilização de
        segurança adequada para proteger os dados pessoais recebidos.
      </div>
      <div>
        <br />
      </div>
      <div>
        Também aplicamos processos e medidas, padrão da indústria para a
        detecção e resposta a tentativas de violação dos nossos sistemas.
        Entretanto, não existe um método de transmissão pela Internet ou um
        método de armazenamento eletrônico que seja 100% seguro. Por
        conseguinte, não podemos garantir a segurança absoluta das suas
        informações, apesar de serem tomadas todas as precauções necessárias e
        exigidas pelos órgãos competentes.
      </div>
      <div>
        <br />
      </div>
      <div>
        A Internet, dada a sua natureza, é um fórum público e por isso
        recomendamos que você tenha cautela ao divulgar informações online.
        Frequentemente, você se encontra na melhor posição para proteger a si
        mesmo online. Você é responsável pela proteção do seu nome de usuário e
        senha contra o acesso de terceiros, assim como pela escolha de senhas
        seguras.
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Seus direitos</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Você possui vários direitos em relação aos seus dados pessoais, nos
        termos da LGPD. Para tanto, implementamos controles adicionais de
        transparência e acesso em nossa área de Privacidade para disponibilizar
        aos usuários o acesso livre e gratuito a esses direitos. Neste contexto,
        você tem o direito a:
      </div>
      <div>•&emsp;Confirmação de que estamos tratando seus dados pessoais;</div>
      <div>•&emsp;Acessar os dados pessoais que tratamos sobre você;</div>
      <div>
        •&emsp;Solicitar a alteração ou atualização de seus dados pessoais
        quando estiverem incorretos, incompletos ou inexatos;
      </div>
      <div>
        •&emsp;Se opor ao tratamento de dados pessoais, quando não tivermos mais
        necessidade legítima ou legal de trata-los;
      </div>
      <div>
        •&emsp;Solicitar a transmissão dos dados pessoais que tratamos sobre
        você para outro fornecedor;
      </div>
      <div>
        •&emsp;Solicitar informações das entidades públicas e privadas com as
        quais compartilhamos seus dados pessoais;
      </div>
      <div>
        •&emsp;Revogar o consentimento concedido, solicitar a eliminação dos
        dados pessoais tratados com base em consentimento, bem como de ter
        acesso a informações sobre a possibilidade de você não fornecer o
        consentimento e as respectivas consequências da negativa; e
      </div>
      <div>
        •&emsp;Solicitar a revisão do tratamento de dados pessoais com base em
        decisões automatizadas.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>
          • Alterações nesta Política de Privacidade e Proteção de Dados
          Pessoais
        </strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        A presente Política de Privacidade e Proteção de Dados Pessoais poderá
        ser alterada a qualquer tempo. Portanto, recomendamos que você reveja
        esta Política de tempos em tempos para ser informado sobre como estamos
        protegendo suas informações.
      </div>
      <div>
        Todas as alterações serão comunicadas por meio de um aviso em destaque
        na tela inicial do nosso site/aplicativo ou por meio de qualquer outra
        forma de comunicação com você.
      </div>
      <div>
        <br />
      </div>
      <div>
        <strong>• Reclamações, dúvidas e/ou solicitações</strong>
      </div>
      <div>
        <br />
      </div>
      <div>
        Se você não estiver satisfeito com a maneira como tratamos os seus dados
        pessoais ou em caso de qualquer dúvida, reclamação, preocupação ou
        solicitações relacionadas a sua privacidade e proteção de seus dados
        pessoais, você pode entrar em contato com o(a) nosso(a)
        DPO/Encarregado(a) pelo envio de e-mail para o endereço eletrônico:{' '}
        <a href="mailto:meumaterdei@materdei.com.br">
          meumaterdei@materdei.com.br
        </a>
      </div>
    </>
  )
}
