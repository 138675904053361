import React, { useState } from 'react'

import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'
import * as S from './styles'
import Button from 'presentation/components/Button'
import ActualPage from 'presentation/components/ActualPage'
import { toast } from 'react-toastify'
import Modal from 'presentation/shared/components/Modal'
import { useHistory } from 'react-router'
import { Container } from 'presentation/components/Container'
import Header from 'presentation/components/Header'

type Props = {
  disabled?: boolean
  handleSubmit?: () => Promise<void>
}

export default function CheckinLayout({
  disabled = true,
  handleSubmit
}: Props) {
  const [successModal, setSuccessModal] = useState(false)
  const history = useHistory()

  const check = async () => {
    try {
      await handleSubmit?.()
      setSuccessModal(true)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <Header />
      <Container
        hasHeader
        primaryButton={
          <Button type="button" onClick={check} fullWidth disabled={disabled}>
            Já estou no hospital
          </Button>
        }
      >
        <S.Wrapper>
          <S.Header>
            <ActualPage text="Check-in" />
          </S.Header>
          <S.Content>
            <Heading as="h1">
              Faça seu <span>Check-in</span>!
            </Heading>
            <SupportText>
              Ao fazer o check-in, você <span>confirmará</span> para a nossa
              equipe que já chegou no hospital.
            </SupportText>
          </S.Content>

          <Modal
            show={successModal}
            title="Seu check-in foi feito com sucesso!"
            description="Nossa equipe acaba de ser notificada sobre sua chegada no hospital. Nos falamos em breve!"
            close={() => history.push('/home')}
          />
        </S.Wrapper>
      </Container>
    </>
  )
}
