import { DoctorOverallRegistration } from 'domain/entities/doctor-overall-registration-model'
import { UpdateDoctorOverallStatus } from 'domain/usecases/overall-registration-status/update-doctor-registration-status'
import { OverallRegistrationStatusRepository } from 'repository/repositories/overall-registration-status/overall-registration-status-repository'

export class RemoteUpdateDoctorOverallStatus
  implements UpdateDoctorOverallStatus
{
  constructor(
    private readonly registrationStatusRepository: OverallRegistrationStatusRepository
  ) {}

  updateDoctorOverallStatus(
    doctorOverall: DoctorOverallRegistration,
    overall_registration_status_id: number
  ): Promise<UpdateDoctorOverallStatus.Model> {
    return this.registrationStatusRepository.updateDoctorOverallStatus(
      doctorOverall,
      overall_registration_status_id
    )
  }
}
