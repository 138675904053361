import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'

import { RegisterPendencyService } from 'presentation/contexts/services/register-pendency-service'
import { RegisterPendencyRepository } from 'repository/repositories/register-pendency/register-pendency-repository'
import { RemoteCreateRegisterPendency } from 'service/usecases/create-register-pendency/remote-create-register-pendency'
import { RemoteLoadCurrentRegisterPendency } from 'service/usecases/load-current-register-pendency/remote-load-current-register-pendency'

export default function makeRegisterPendencyService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const registerPendencyRepository = new RegisterPendencyRepository(
    apiRepository
  )

  const create = new RemoteCreateRegisterPendency(registerPendencyRepository)
  const loadCurrent = new RemoteLoadCurrentRegisterPendency(
    registerPendencyRepository
  )

  return new RegisterPendencyService({
    create,
    loadCurrent
  })
}
