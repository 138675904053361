import { CreateEmergencyRoom } from 'domain/usecases/emergency-room/create-emergency-room'
import { CreateEmergencyRoomInMv } from 'domain/usecases/emergency-room/create-emergency-room-in-mv'
import { GetEmergencyRoomByToken } from 'domain/usecases/emergency-room/get-emergency-room-by-token'
import { LoadEmergencyRoomByHospital } from 'domain/usecases/emergency-room/load-emergency-room-by-hospital'
import { RenewEmergencyToken } from 'domain/usecases/renew-emergency-token/renew-emergency-token'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  cancelEmergencyRoomAttendanceMutation,
  createEmergencyRoomInMvMutation,
  createEmergencyRoomMutation
} from 'repository/graphql/mutations'
import {
  getEmergencyRoomByTokenQuery,
  loadEmergencyRoomByHospitalQuery,
  renewEmergencyTokenQuery
} from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { EmergencyRoomRepository as IEmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { CancelEmergencyRoomAttendance } from '../../../domain/usecases/emergency-room/cancel-emergency-room-attendance'

export class EmergencyRoomRepository implements IEmergencyRoomRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async createEmergencyRoom(
    params: CreateEmergencyRoom.Params
  ): Promise<CreateEmergencyRoom.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateEmergencyRoom.Model>

    const query = createEmergencyRoomMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params, 'data')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateEmergencyRoom.Model
    }
  }

  async cancelEmergencyRoomAttendance(
    params: CancelEmergencyRoomAttendance.Params
  ): Promise<CancelEmergencyRoomAttendance.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CancelEmergencyRoomAttendance.Model>

    const query = cancelEmergencyRoomAttendanceMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          params.emergency_room_attendance_id,
          'emergency_room_attendance_id'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CancelEmergencyRoomAttendance.Model
    }
  }

  async renewEmergencyToken(
    params: RenewEmergencyToken.Params,
    emergencyTokenId: number
  ): Promise<RenewEmergencyToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RenewEmergencyToken.Model>

    const query = renewEmergencyTokenQuery(params!)

    const body: { query: string; variables?: any } = {
      query: query.query
    }
    body.variables = makeGraphQLVariable(
      emergencyTokenId,
      'emergency_room_token_id'
    )

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: body,
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RenewEmergencyToken.Model
    }
  }

  async loadEmergencyRoomByHospital(
    fields: LoadEmergencyRoomByHospital.Params
  ): Promise<LoadEmergencyRoomByHospital.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadEmergencyRoomByHospital.Model>

    const query = loadEmergencyRoomByHospitalQuery(fields)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadEmergencyRoomByHospital.Model
    }
  }

  async getEmergencyRoomByToken({
    fields,
    token
  }: GetEmergencyRoomByToken.Params): Promise<GetEmergencyRoomByToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetEmergencyRoomByToken.Model>

    const query = getEmergencyRoomByTokenQuery(fields)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(token, 'token')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetEmergencyRoomByToken.Model
    }
  }

  async createEmergencyRoomInMv({
    patient_id,
    doctor_id
  }: CreateEmergencyRoomInMv.Params): Promise<CreateEmergencyRoomInMv.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateEmergencyRoomInMv.Model>

    const query = createEmergencyRoomInMvMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(patient_id, 'patient_id'),
          ...makeGraphQLVariable(doctor_id, 'doctor_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateEmergencyRoomInMv.Model
    }
  }
}
