import React from 'react'

import HospitalizationLayout from 'presentation/layouts/Hospitalization'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'

type Props = {
  loadSurgicalOrder: LoadSurgicalOrder
}

export default function Hospitalization({ loadSurgicalOrder }: Props) {
  return <HospitalizationLayout loadSurgicalOrder={loadSurgicalOrder} />
}
