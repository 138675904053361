export class NotificationConfig {
  private _sendEmail: boolean
  private _sendSMS: boolean

  constructor(sendEmail: boolean, sendSMS: boolean) {
    this._sendEmail = sendEmail
    this._sendSMS = sendSMS
  }

  public get sendEmail(): boolean {
    return this._sendEmail
  }

  public set sendEmail(sendEmail: boolean) {
    this._sendEmail = sendEmail
  }

  public get sendSMS(): boolean {
    return this._sendSMS
  }

  public set sendSMS(sendSMS: boolean) {
    this._sendSMS = sendSMS
  }
}
