import React from 'react'
import * as S from './styles'

export default function PostSurgeryInfo() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.PreSurgery>
          <S.Title>Orientações Pós-Cirúrgicas</S.Title>

          <S.Description>
            - Converse com o seu médico sobre o planejamento de alta;
            <br />
            <br />
            - Esteja seguro sobre os cuidados pós-cirúrgicos, incluindo trocas
            de curativo e uso da medicação prescrita, se for o caso;
            <br />
            <br />
            - Fique atento à evolução da ferida operatória e ao surgimento de
            sintomas que possam indicar uma complicação. Entre em contato com a
            equipe médica, se necessário;
            <br />
            <br />- Em caso de dúvidas, utilize nosso Chat para orientação e
            acolhimento.
          </S.Description>
        </S.PreSurgery>
      </S.Container>
    </S.Wrapper>
  )
}
