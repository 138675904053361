import styled, { css } from 'styled-components'

export const Marker = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    hr {
      background-color: ${theme.colors.gray};
      margin: 20px 0px;
      width: 99%;
    }
  `}
`

export const Arrows = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 99%;
  top: 13px;

  svg {
    position: absolute;
  }

  svg:first-of-type {
    left: -1.2px;
  }

  svg + svg {
    right: -1.2px;
  }
`

export const Dot = styled.div`
  ${({ theme, color }) => css`
    position: absolute;
    left: calc(50% - 7px);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${color ? color : theme.colors.primary};
    top: 21px;
    border: 2px solid ${theme.colors.mainBg};
    box-shadow: 0 0 0 1px ${color ? color : theme.colors.primary};
  `}
`
