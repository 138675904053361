import { IApiRepository } from 'service/protocols/api/api-repository'
import { AuthenticationRepository as IAuthenticationRepository } from 'repository/interfaces/authentication-repository'
import { Authentication } from 'domain/usecases/authentication/authentication'
import { Signup } from 'domain/usecases/authentication/signup'
import {
  authMutation,
  signupMutation,
  getPasswordRecoveryCodeMutation,
  changePasswordMutation,
  logoutMutation
} from 'repository/graphql/mutations'
import {
  loadUserRoleQuery,
  loadUserGroupsQuery,
  verifyLoginClientQuery,
  getUserByInviteToken
} from 'repository/graphql/queries/index'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { AccountModel } from 'domain/entities/account-model'
import { GetPasswordRecoveryCode } from 'domain/usecases/authentication/get-password-recovery-code'
import { ChangePassword } from 'domain/usecases/authentication/change-password'
import { Logout } from 'domain/usecases/authentication/logout'
import { LogoutModel } from 'service/usecases/logout/remote-logout'
import { LoadUserRole } from 'domain/usecases/authentication/load-user-role'
import { LoadUserGroups } from 'domain/usecases/authentication/load-user-groups'
import { LoadUserRoleModel } from 'service/usecases/load-user-role/remote-load-user-role'
import { LoadUserGroupsModel } from 'service/usecases/load-user-groups/remote-load-user-groups'
import { VerifyLoginClient } from 'domain/usecases/authentication/verify-client'
import { LoadUserByInviteToken } from 'domain/usecases/authentication/get-user-by-invite-token'
import { LoadUserByInviteTokenModel } from 'service/usecases/load-user-by-invite-token/remote-load-user-by-invite-token'

export class AuthenticationRepository implements IAuthenticationRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async auth(params: Authentication.Params): Promise<Authentication.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<Authentication.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: authMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: authMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AccountModel
    }
  }

  async signup(params: Signup.Params): Promise<Signup.Model> {
    const apiRepository = this.apiRepository as IApiRepository<Signup.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: signupMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: signupMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AccountModel
    }
  }

  async getPasswordRecoveryCode(
    params: GetPasswordRecoveryCode.Params
  ): Promise<GetPasswordRecoveryCode.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetPasswordRecoveryCode.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: getPasswordRecoveryCodeMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: getPasswordRecoveryCodeMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    }
  }

  async changePassword(
    params: ChangePassword.Params
  ): Promise<ChangePassword.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ChangePassword.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: changePasswordMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: changePasswordMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    }
  }

  async logout(params: Logout.Params): Promise<Logout.Model> {
    const apiRepository = this.apiRepository as IApiRepository<Logout.Model>
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: logoutMutation.query,
        variables: makeGraphQLVariable(params.accessToken)
      },
      query: logoutMutation.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LogoutModel
    }
  }

  async loadUserRole(params: LoadUserRole.Params): Promise<LoadUserRole.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadUserRole.Model>

    const query = loadUserRoleQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadUserRoleModel
    }
  }

  async loadUserGroups(
    params: LoadUserGroups.Params
  ): Promise<LoadUserGroups.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadUserGroups.Model>

    const query = loadUserGroupsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadUserGroupsModel
    }
  }

  async verifyLoginClient(params: VerifyLoginClient.Params): Promise<boolean> {
    const apiRepository = this
      .apiRepository as IApiRepository<VerifyLoginClient.Model>

    const query = verifyLoginClientQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params)
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as VerifyLoginClient.Model
    }
  }

  async loadUserByInviteToken(
    token: string,
    params?: LoadUserByInviteToken.Params
  ): Promise<LoadUserByInviteToken.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadUserByInviteToken.Model>

    const query = getUserByInviteToken(token, params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(token, 'inviteToken')
      },
      query: query.name
    })

    if (httpResponse.statusCode !== ApiStatusCode.ok) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadUserByInviteTokenModel
    }
  }
}
