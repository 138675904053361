import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import theme from 'presentation/styles/theme'
import React from 'react'
import * as S from '../styles'

type SuccessRenewModalProps = {
  show: boolean
  onClose: () => void
  timeLeft: number
}

export default function ExpireRenewModal({
  onClose,
  show,
  timeLeft
}: SuccessRenewModalProps) {
  const labelMin = ['instantes', '1 minuto']
  return (
    <SheetModal size={220} isOpen={show} onClose={onClose}>
      <S.Container>
        <S.HeaderContainer>
          <Heading size="large">
            Seu Token irá expirar em{' '}
            <strong style={{ color: theme.colors.primaryDarker }}>
              {timeLeft > 1 ? `${timeLeft} minutos` : labelMin[timeLeft]}
            </strong>
          </Heading>
        </S.HeaderContainer>
        <Button
          fullWidth
          style={{ marginTop: '20px' }}
          onClick={onClose}
          backgroundColor={'primaryDarker'}
        >
          Voltar a tela inicial
        </Button>
      </S.Container>
    </SheetModal>
  )
}
