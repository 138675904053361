import { RequestSurgicalOrder } from 'domain/usecases/patient/request-surgical-order'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteRequestSurgicalOrder implements RequestSurgicalOrder {
  constructor(private readonly patientRepository: PatientRepository) {}
  async create(
    params: RequestSurgicalOrder.Params
  ): Promise<RequestSurgicalOrder.Model> {
    return this.patientRepository.requestSurgicalOrder(params)
  }
}

export type RemoteRequestSurgicalOrderModel = RequestSurgicalOrder.Model
