import { GetExamScheduleHours } from 'domain/usecases/exam/get-exam-schedule-hours'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamScheduleHours implements GetExamScheduleHours {
  constructor(private readonly examRepository: ExamRepository) {}
  async load(
    params: GetExamScheduleHours.Params
  ): Promise<GetExamScheduleHours.Model> {
    return this.examRepository.getExamScheduleHours(params)
  }
}

export type GetExamScheduleHoursModel = GetExamScheduleHours.Model
