import styled from 'styled-components'
import { css } from 'styled-components'

type CardProps = {
  unavailable?: boolean
}

export const CardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral200};
  background: ${({ theme }) => theme.ds.colors.neutral25};
  border-radius: 8px;
  padding: 18px;
  margin-bottom: 20px;
`
export const CardTitle = styled.strong<CardProps>`
  ${({ theme, unavailable }) => css`
    color: ${unavailable
      ? theme.ds.colors.neutral400
      : theme.ds.colors.neutral800};
    font-size: ${({ theme }) => theme.ds.typography.size.medium};
    font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
  `}
`

export const CardDescription = styled.p<CardProps>`
  ${({ theme, unavailable }) => css`
    color: ${unavailable
      ? theme.ds.colors.neutral400
      : theme.ds.colors.neutral900};
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
    margin-top: 8px;
  `}
`

export const WarningContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: baseline;

  svg {
    margin-right: 4px;
    align-self: flex-start;
    min-width: 16px;
    path {
      fill: ${({ theme }) => theme.ds.colors.danger900};
    }
  }
`

export const WarningDescription = styled.p`
  color: ${({ theme }) => theme.ds.colors.danger900};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
`
export const NoSchedule = styled.div`
  border: 1px solid ${({ theme }) => theme.ds.colors.danger300};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
  background: ${({ theme }) => theme.ds.colors.danger25};
  border-radius: 8px;
  padding: 18px;
  margin-top: 20px;

  strong {
    color: ${({ theme }) => theme.ds.colors.danger700};
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  }
`

export const MedicalAppointmentText = styled.p`
  color: ${({ theme }) => theme.ds.colors.info700};
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
`

export const HorizontalButtonPanel = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;
  justify-content: right;
`

export const MedicalAppointmentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: baseline;
  line-height: 17px;

  svg {
    margin-right: 4px;
    align-self: flex-start;
    min-width: 16px;
    path {
      fill: ${({ theme }) => theme.ds.colors.info700};
    }
  }
`
