export enum SurgicalOrderStatus {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'UNDER_REVIEW_AT_THE_HOSPITAL',
  UNDER_REVIEW_AT_THE_INSURANCE = 'UNDER_REVIEW_AT_THE_INSURANCE',
  AUTHORIZED = 'AUTHORIZED',
  CANCELLED = 'CANCELLED',
  CONCLUDED = 'CONCLUDED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_REGISTERED = 'ORDER_REGISTERED',
  REQUESTED_CANCELLATION = 'REQUESTED_CANCELLATION'
}

export enum SurgicalOrdersStatusTranslatedPlural {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'em análise no hospital',
  UNDER_REVIEW_AT_THE_INSURANCE = 'em análise no convênio',
  AUTHORIZED = 'autorizados',
  CANCELLED = 'cancelados',
  CONCLUDED = 'realizados',
  ORDER_CREATED = 'Pedidos criados',
  ORDER_REGISTERED = 'Pedidos solicitados',
  REQUESTED_CANCELLATION = 'cancelamentos solicitados'
}

export enum SurgicalOrdersStatusTranslatedSingular {
  UNDER_REVIEW_AT_THE_HOSPITAL = 'em análise no hospital',
  UNDER_REVIEW_AT_THE_INSURANCE = 'em análise no convênio',
  AUTHORIZED = 'autorizado',
  CANCELLED = 'cancelado',
  CONCLUDED = 'realizado',
  ORDER_CREATED = 'Pedido criado',
  ORDER_REGISTERED = 'Pedido solicitado',
  REQUESTED_CANCELLATION = 'cancelamento solicitado'
}
