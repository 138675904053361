import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import { Patient } from 'domain/entities/patient-model'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'
import moment from 'moment'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import TextButton from 'presentation/shared/components/TextButton'
import TextField from 'presentation/components/TextField'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import { cpfMask, phoneMask } from 'presentation/utils/masks'
import React, { useState, useEffect } from 'react'
import * as S from '../styles'
import { EditPatientPersonalData } from './EditPatienPersonalData'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

type EmergencyRoomPatientInfoProps = {
  patient: Patient
  setPatient: (patient: Patient) => void
  patientCarousel: CarouselState
  updatePatientPersonalData: UpdatePatientInfoById
  editPatient: (patient: Patient) => void
  patientBackStep?: number
}

type Religion = {
  religion_id: number
  name: string
}

export function PatientPersonalData({
  patient,
  patientCarousel,
  updatePatientPersonalData,
  setPatient,
  editPatient,
  patientBackStep
}: EmergencyRoomPatientInfoProps) {
  const [open, setOpen] = useState<boolean>(false)
  const religionService = useServices().religion
  const [religions, setReligions] = useState<Religion[]>([])
  const loadReligions = async () => {
    try {
      const response = await religionService.loadReligions()
      setReligions(response)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    loadReligions()
  }, [])

  if (
    Object.keys(patientCarousel).length > 0 &&
    patientBackStep &&
    patientBackStep > 0
  ) {
    patientCarousel.slideTo(patientBackStep)
  }

  return (
    <>
      <S.PatientInfoContainer>
        <S.PersonalDataHeader>
          <strong>Dados de cadastro</strong>
          <TextButton size="small" underline onClick={() => setOpen(true)}>
            Editar
          </TextButton>
        </S.PersonalDataHeader>
        <TextField
          label="Nome completo"
          value={patient.name}
          disabled
          role="patient-data-input"
          required
        />
        <TextField
          label="Nome social"
          value={patient.socialName}
          disabled
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="Sexo"
          value={patient.gender}
          disabled
          required
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="Data de nascimento"
          value={
            patient.birthday
              ? moment(patient.birthday).format('DD/MM/YYYY')
              : ''
          }
          disabled
          required
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="Idade"
          disabled
          value={getAgeByBirthdayDate(patient.birthday)}
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="RG"
          disabled
          value={patient.rg}
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="CPF"
          value={patient.cpf}
          mask={cpfMask}
          disabled
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="E-mail"
          value={patient.email}
          disabled
          role="patient-data-input"
          style={{ marginTop: 15 }}
          required
        />
        <TextField
          label="Telefone"
          value={patient.phone}
          mask={phoneMask}
          disabled
          role="patient-data-input"
          style={{ marginTop: 15 }}
          required
        />
        <TextField
          label="Estado civil"
          required
          disabled
          value={
            maritalStatus.find(
              (status) => status.value === patient.maritalStatus
            )?.label
          }
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="Empresa"
          disabled
          value={patient.company}
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <TextField
          label="Religião"
          disabled
          value={patient.religion}
          role="patient-data-input"
          style={{ marginTop: 15 }}
        />
        <Button
          fullWidth
          style={{ marginTop: '20px' }}
          onClick={() => patientCarousel.slideNext()}
          role="continue-button"
        >
          Continuar
        </Button>
      </S.PatientInfoContainer>
      <EditPatientPersonalData
        patient={patient}
        setPatient={setPatient}
        onClose={() => setOpen(false)}
        open={open}
        updatePatientPersonalData={updatePatientPersonalData}
        religions={religions}
        editPatient={(patient) => editPatient(patient)}
      />
    </>
  )
}
