import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'

import { useStores } from 'presentation/hooks/use-stores'
import HamburguerIcon from 'presentation/components/Icons/HamburguerIcon'
import { profiles } from './profiles'
import { ReactComponent as LogoutIcon } from 'presentation/assets/icons/exit.svg'
import * as S from './styles'
import { LocalStorageAdapter } from 'infra/storage-adapter/storage-adapter'

const SideMenu = observer(() => {
  const ref = useRef(null)

  const menuStore = useStores().menu
  const accountStore = useStores().currentAccount
  const permissions = useStores().permissions
  const history = useHistory()

  const logout = async () => {
    const localStorage = new LocalStorageAdapter()
    await accountStore.removeCurrentAccount()
    permissions.clean()
    localStorage.set('user-flags')
    localStorage.set('pendent-surgeries-banner')
    history.push('/login')
    menuStore.close()
  }

  const redirect = (url: string) => {
    history.push(url)
    menuStore.close()
  }

  return (
    <>
      <S.Wrapper isOpen={menuStore.isOpen}>
        <S.MenuFull
          ref={ref}
          aria-hidden={!menuStore.isOpen}
          isOpen={menuStore.isOpen}
          aria-label="Side menu"
        >
          <S.HamburguerIconWrapper
            aria-label="Close menu icon"
            onClick={() => menuStore.close()}
          >
            <HamburguerIcon />
          </S.HamburguerIconWrapper>
          <S.MenuNav>
            {profiles[accountStore.getCurrentAccount()?.user?.role]?.map(
              (link) => (
                <S.MenuItem
                  onClick={() => redirect(link.link)}
                  key={link.label}
                >
                  {link.icon}
                  <S.MenuLink>{link.label}</S.MenuLink>
                </S.MenuItem>
              )
            )}
            <S.MenuItem onClick={logout}>
              <div>
                <LogoutIcon />
              </div>
              <S.MenuLink>Sair</S.MenuLink>
            </S.MenuItem>
          </S.MenuNav>
        </S.MenuFull>
      </S.Wrapper>
      {menuStore.isOpen && (
        <S.ClickInterceptor
          data-testid="Side menu interceptor"
          onClick={() => menuStore.isOpen && menuStore.close()}
        ></S.ClickInterceptor>
      )}
    </>
  )
})

export default SideMenu
