import React from 'react'
import {
  ExamOrderItem,
  ExamResultModel
} from 'domain/entities/exam-result-model'
import { useStores } from 'presentation/hooks/use-stores'
import DividerNew from 'presentation/shared/components/DividerNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { ReactComponent as PdfFileIcon } from 'presentation/assets/icons/pdf-file-icon.svg'
import { ReactComponent as ImageFileIcon } from 'presentation/assets/icons/image-file-icon.svg'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import moment from 'moment-timezone'
import CardNew from 'presentation/shared/components/CardNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { useHistory } from 'react-router'
import { ExamStatusEnum, ExamStatusEnumLocated } from 'common/enum/exam-status'
import HeaderNew from 'presentation/components/HeaderNew'
import { ExamOrderType } from 'common/enum/exam-order-type'

type Props = {
  exam: ExamResultModel
  examOrderItems: ExamOrderItem[]
  procedures: string
} & WithLoadingProps

const getVueMotionUrl = (exam_order_item_id: number): string => {
  return process.env.NODE_ENV === 'production'
    ? `/fetch/redirect-vuemotion/?accession_number=${exam_order_item_id}`
    : `${process.env.REACT_APP_API_URL}/redirect-vuemotion/?accession_number=${exam_order_item_id}`
}

const ExamResultListLayout = WithLoading(
  ({ exam, examOrderItems, procedures }: Props) => {
    const { currentAccount } = useStores()
    const history = useHistory()

    const getCompleteExams = () => {
      return examOrderItems.filter((item) => {
        return item.available_report || item.available_image
      })
    }

    const getIncompleteExams = () => {
      return examOrderItems.filter((item) => {
        return !item.available_report && !item.available_image
      })
    }

    const showExam = (examOrderItem: ExamOrderItem) => {
      if (examOrderItem.available_report || examOrderItem.available_image) {
        examOrderItem.exam_type === ExamOrderType.IMAGE
          ? showImage(examOrderItem.exam_order_item_id)
          : showPdf(examOrderItem)
      }
    }

    const showGeneralResultPdf = () => {
      history.push('/exame/resultado/pdf', {
        title: 'Resultado Geral',
        attendance_id: exam.attendance_id,
        exam_order_id: exam.exam_order_id,
        type: exam.type
      })
    }

    const showPdf = (examOrderItem: ExamOrderItem) => {
      history.push('/exame/resultado/pdf', {
        title: examOrderItem.exam_description,
        attendance_id: exam.attendance_id,
        exam_order_id: examOrderItem.exam_order_id,
        exam_order_item_id: examOrderItem.exam_order_item_id,
        type: examOrderItem.type
      })
    }

    const showImage = (orderItemId: number) => {
      window.open(
        getVueMotionUrl(orderItemId!),
        '_blank',
        'noopener,noreferrer'
      )
    }

    const examsCardList = (examOrderItems: ExamOrderItem[]) => {
      return examOrderItems?.map((examOrderItem: ExamOrderItem, index) => (
        <CardNew
          data-testid="exam-document-card"
          key={index}
          style={{
            flexDirection: 'row',
            marginTop: '16px',
            alignItems: 'center',
            textTransform: 'capitalize'
          }}
          gap="10px"
          border="primary300"
          shadow="none"
          disabled={
            !examOrderItem.available_report && !examOrderItem.available_image
          }
          onClick={() => showExam(examOrderItem)}
        >
          {examOrderItem.exam_type === ExamOrderType.IMAGE ? (
            <>
              <ImageFileIcon /> {'Imagem - '}
            </>
          ) : (
            <PdfFileIcon />
          )}
          {examOrderItem.exam_description.toLowerCase()}
        </CardNew>
      ))
    }

    return (
      <>
        <HeaderNew />
        <ContainerNew
          title={`Número do atendimento: ${exam?.attendance_id}`}
          titleColor="primary600"
          titleSize="large"
          titleWeight="bold"
          subtitle={
            <>
              <SupportTextNew
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical'
                }}
                capitalize
              >
                <b>Exame:</b> {procedures}
              </SupportTextNew>
              <SupportTextNew capitalize>
                <b>Paciente:</b>{' '}
                {currentAccount.getCurrentAccount().user.name.toLowerCase()}
              </SupportTextNew>
              <SupportTextNew>
                <b>Data de exame:</b>{' '}
                {moment(exam?.requested_at).format('DD/MM/YYYY')}
              </SupportTextNew>
              <SupportTextNew capitalize>
                <b>Situação:</b>{' '}
                {
                  ExamStatusEnumLocated[
                    exam.status as keyof typeof ExamStatusEnum
                  ]
                }
              </SupportTextNew>
            </>
          }
          gap="16px"
        >
          {/* General Results */}
          {exam && exam.order_type === ExamOrderType.LABORATORY && (
            <div>
              <SupportTextNew weight="bold">Resultado geral</SupportTextNew>
              <SupportTextNew>
                Visualize todos os exames já disponíveis em um único arquivo
              </SupportTextNew>

              <CardNew
                data-testid="exam-document-card"
                style={{
                  flexDirection: 'row',
                  marginTop: '16px',
                  alignItems: 'center',
                  textTransform: 'capitalize'
                }}
                gap="10px"
                border="primary300"
                shadow="none"
                onClick={() => showGeneralResultPdf()}
                disabled={!exam.has_general_result}
              >
                <PdfFileIcon />
                Resultado Geral
              </CardNew>

              <DividerNew marginBottom="0px" />
            </div>
          )}

          {/* Complete Exams */}
          {getCompleteExams().length > 0 && (
            <div>
              <SupportTextNew weight="bold">
                {exam.status === ExamStatusEnum.FULL
                  ? 'Resultados individuais'
                  : 'Resultados já disponíveis'}
              </SupportTextNew>
              <SupportTextNew>
                Visualize cada exame de forma individual
              </SupportTextNew>

              {examsCardList(getCompleteExams())}
            </div>
          )}

          {/* Incomplete Exams */}
          {getIncompleteExams().length > 0 && (
            <div>
              <DividerNew marginTop="0px" />
              <SupportTextNew weight="bold">
                Resultados em análise
              </SupportTextNew>
              <SupportTextNew>
                Aguarde a disponibilidade dos exames
              </SupportTextNew>

              {examsCardList(getIncompleteExams())}
            </div>
          )}
        </ContainerNew>
      </>
    )
  }
)

export default ExamResultListLayout
