import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'
import moment from 'moment-timezone'

import { ReactComponent as ArrowIcon } from 'presentation/assets/icons/green-arrow.svg'
import { TimelineStatus } from './status'
import theme from 'presentation/styles/theme'
import * as S from './styles'
import StatusChip from '../StatusChip'

export type TimelineStatusItem = {
  status: string
  color?: string
  isConcluded: boolean
  isActive?: boolean
  createdAt?: string
  updatedAt?: string
}

export type TimelineProps = {
  minified?: boolean
  items?: TimelineStatusItem[]
  timelineStatusProp?: {
    label: string
    status: string[]
    color: string
  }[]

  supportButtonLabel?: string
  supportButtonClick?: () => void
}

export default function Timeline({
  minified = false,
  items,
  timelineStatusProp,
  supportButtonLabel,
  supportButtonClick
}: TimelineProps) {
  const [actualStatus, setActualStatus] = useState<number | undefined>()
  const [timelineItems, setTimelineItems] = useState<any[]>([])

  const history = useHistory()
  useEffect(() => {
    const lastItem = timelineItems
      .filter((el: any) => {
        return el.isConcluded
      })
      .slice(-1)
    items?.length
      ? setActualStatus(timelineItems.indexOf(lastItem[0]))
      : setActualStatus(0)
  }, [items, timelineItems])

  useEffect(() => {
    const newItems: any[] =
      (timelineStatusProp ?? TimelineStatus)?.map((item, idx) => {
        const itemProps = items?.filter((val) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return item.status.includes(val.status)
        })

        return {
          color: item.color,
          isConcluded:
            itemProps &&
            itemProps.length > 0 &&
            itemProps?.every((val) => val.isConcluded),
          isActive: itemProps?.[idx]?.isActive,
          status: item.label
        }
      }) ?? []
    const filteredNewItems = newItems.filter((el) => {
      if (el.status !== newItems[0].status) return el
    })
    const validateStatus = filteredNewItems.every(
      (el) => el.isConcluded === false
    )
    if (validateStatus) {
      filteredNewItems.forEach((e) => {
        if (e.status === filteredNewItems[0]?.status) {
          e.isConcluded = true
        }
      })
    }
    setTimelineItems(filteredNewItems)
  }, [items])

  return (
    <S.Wrapper minified={minified}>
      {timelineItems?.map((status, index) => (
        <S.TimelineItem
          done={status.isConcluded || (actualStatus && index < actualStatus)}
          minified={minified}
          actual={index === actualStatus}
          key={status.status}
        >
          {minified && index !== 0 && (
            <S.Bar
              done={
                status.isConcluded || (actualStatus && index < actualStatus)
              }
            />
          )}
          <S.Dot
            done={
              status.isConcluded ||
              status.isActive ||
              (actualStatus && index < actualStatus)
            }
            minified={minified}
            color={theme.colors.primary}
          />
          {!minified && (
            <S.ItemContainer
              onClick={() =>
                history.push('/detalhes-status', {
                  status: status.status
                })
              }
            >
              <S.DescriptionContainer>
                <S.StatusLabel>{status.status}</S.StatusLabel>
                {supportButtonLabel ? (
                  index === actualStatus ? (
                    <StatusChip
                      color={'pink'}
                      label={supportButtonLabel}
                      onClick={(e) => {
                        if (supportButtonClick) {
                          supportButtonClick()
                        }
                        e.stopPropagation()
                      }}
                    />
                  ) : null
                ) : null}
                {status.isConcluded && (
                  <S.DoneDate>
                    {status.updatedAt &&
                      moment(status.updatedAt).format('DD/MM/YYYY - H:mm')}
                  </S.DoneDate>
                )}
              </S.DescriptionContainer>
              <ArrowIcon />
            </S.ItemContainer>
          )}
        </S.TimelineItem>
      ))}
    </S.Wrapper>
  )
}
