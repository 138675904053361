import React from 'react'
import { RemoteCancelEmergencyRoomAttendance } from 'service/usecases/cancel-emergency-room-attendance/remote-cancel-emergency-room-attendance'
import ServiceSolicitationOverview from 'presentation/pages/ServiceSolicitationOverview'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { EmergencyRoomRepository } from 'repository/repositories/emergency-room-atendance/emergency-room-repository'

export default function makeEmergencyRoomAttendance() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )

  const emergencyRoomRepository = new EmergencyRoomRepository(apiRepository)
  const cancelEmergencyRoomAttendance = new RemoteCancelEmergencyRoomAttendance(
    emergencyRoomRepository
  )

  return (
    <ServiceSolicitationOverview
      cancelEmergencyRoomAttendance={cancelEmergencyRoomAttendance}
    />
  )
}
