import { makeAutoObservable } from 'mobx'
import type { DeleteDoctorDocument } from 'domain/usecases/document/delete-doctor-document'
import { GetScheduleDocuments } from 'domain/usecases/document/get-schedule-documents'

type Services = {
  deleteDoctorDocument: DeleteDoctorDocument
  getScheduleDocuments: GetScheduleDocuments
}

export class DocumentService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async deleteDoctorDocument(
    params: DeleteDoctorDocument.Params
  ): Promise<DeleteDoctorDocument.Model> {
    await this.services.deleteDoctorDocument.delete(params)
  }

  async getScheduleDocuments(): Promise<GetScheduleDocuments.Model> {
    return this.services.getScheduleDocuments.get()
  }
}

export default DocumentService
