import styled, { css } from 'styled-components'

import { HeadingProps } from '.'

export const Wrapper = styled.h2<HeadingProps>`
  ${({ theme, color, size, weight, lineHeight }) => css`
    color: ${theme.ds.colors?.[color!]};
    font-weight: ${theme.ds.typography.weight?.[weight!]};
    font-size: ${theme.ds.typography.size?.[size!]};
    line-height: ${theme.ds.typography.lineHeight?.[lineHeight!]};

    span {
      color: ${theme.ds.colors.danger300};
    }
  `}

  ${({ flexStart }) =>
    flexStart &&
    css`
      display: flex;
      align-self: flex-start;
    `}
`
