import { GetExamReasonCancel } from 'domain/usecases/exam/get-exam-reason-cancel'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamReasonCancel implements GetExamReasonCancel {
  constructor(private readonly examRepository: ExamRepository) {}
  async load(): Promise<GetExamReasonCancel.Model> {
    return this.examRepository.getExamReasonCancel()
  }
}

export type GetExamReasonCancelModel = GetExamReasonCancel.Model
