import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import HeaderNew from 'presentation/components/HeaderNew'
import SchedulingUpdatePersonalInfoForm from 'presentation/components/Forms/SchedulingUpdatePersonalInfo'
import { Patient } from 'domain/entities/patient-model'

import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'

const SchedulingUpdatePersonalInfoLayout = WithLoading(
  ({ setIsLoading }: WithLoadingProps) => {
    const history = useHistory()
    const patientService = useServices().patient
    const [patient, setPatient] = useState<Patient>()

    const loadPatientData = async () => {
      try {
        setIsLoading(true)
        const response = await patientService.loadPatientInfo([
          'patient_id',
          'user_id',
          'name',
          'birthday',
          'gender',
          'phone',
          'email',
          'cpf',
          `
          healthInsurances {
            health_insurance_id
            healthCard
            healthInsuranceCode
            healthInsuranceName
            healthPlanCode
            healthPlanName
            healthSubPlanCode
            healthSubPlanName
            ansRegister
            validThru
          }
          `
        ])
        setPatient(response)
      } catch (error) {
        toast.error('Ocorreu um erro ao carregar os dados')
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      loadPatientData()
    }, [])

    return (
      <>
        <HeaderNew
          actualPageTitle="Agendar consulta"
          actualPageOnClick={() =>
            history.push('/servicos', {
              anchor: 'appointments'
            })
          }
        />
        {patient && <SchedulingUpdatePersonalInfoForm patient={patient} />}
      </>
    )
  }
)

export default SchedulingUpdatePersonalInfoLayout
