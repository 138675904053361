export default function translateRole(role?: string) {
  switch (role?.toLocaleLowerCase()) {
    case 'crmo':
      return 'CRMO'
    case 'setor_de_marcacao':
      return 'Setor de marcação'
    case 'hospitalization':
      return 'Hospitalização'
    case 'secretary':
      return 'Secretária'
    case 'patient':
      return 'Paciente'
    case 'doctor':
      return 'Médico'
    case 'admin':
      return 'Administrador'
    case 'admin_sectorial_crmo':
      return 'Administrador CRMO'
    case 'director_doctor_register':
      return 'Diretor médico'
    case 'doctor_register':
      return 'Aux Administrativo'
    default:
      return ''
  }
}
