import { RemoveDocumentFromSurgicalPendency } from 'domain/usecases/surgical-pendency/remove-document-from-surgical-pendency'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteRemoveDocumentFromSurgicalPendency
  implements RemoveDocumentFromSurgicalPendency
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  async remove(
    params: RemoveDocumentFromSurgicalPendency.Params
  ): Promise<RemoveDocumentFromSurgicalPendency.Model> {
    return this.surgicalPendencyRepository.removeDocumentFromSurgicalPendency(
      params
    )
  }
}
