import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermAcceptanceCompliance() {
  return {
    name: TermsTypeEnum.TERM_ACCEPTANCE_COMPLIANCE,
    title: 'Termo de Aceite Compliance',
    content: (
      <>
        <div>
          <p>
            Ao ingressar voluntariamente no Corpo Clínico da Rede Mater Dei de
            Saúde, o profissional se compromete a observar o Regimento Interno
            do Corpo Clínico e o Código de Integridade, bem como o Código de
            Ética aplicável à sua respectiva categoria.
          </p>

          <p>
            O Regimento Interno do Corpo Clínico e o Código de Integridade
            encontram-se disponíveis em:
          </p>

          <ul>
            <li>
              <a
                href="https://www.materdei.com.br/regimentointerno"
                target="_blank"
                rel="noreferrer"
                title="https://www.materdei.com.br/regimentointerno"
              >
                https://www.materdei.com.br/regimentointerno
              </a>
            </li>

            <li>
              <a
                href="https://materdei.com.br/sobre-o-mater-dei/institucional/manual-de-compliance"
                target="_blank"
                rel="noreferrer"
                title="https://materdei.com.br/sobre-o-mater-dei/institucional/manual-de-compliance"
              >
                https://materdei.com.br/sobre-o-mater-dei/institucional/manual-de-compliance
              </a>
            </li>
          </ul>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de Aceite Compliance`
  }
}
