import React from 'react'

import theme from 'presentation/styles/theme'
import {
  SurgicalOrderStatus,
  SurgicalOrdersStatusTranslatedSingular
} from 'common/enum/surgical-orders-status'
import { PendencyStatusSingular } from 'common/enum/pendency-status'
import { PendencieTypes } from 'domain/usecases/surgical-order/load-surgical-orders-notice-card'
import * as S from './styles'

type Props = {
  status: SurgicalOrderStatus | PendencieTypes
  type?: 'scheduling' | 'pendency'
}

const SchediligStatusCard = ({ status, type = 'scheduling' }: Props) => {
  const getTranslatedLabel = () => {
    switch (type) {
      case 'pendency':
        return PendencyStatusSingular[status as PendencieTypes]
      case 'scheduling':
      default:
        return SurgicalOrdersStatusTranslatedSingular[
          status as SurgicalOrderStatus
        ]
    }
  }

  const getTag = () => {
    switch (status) {
      case 'AUTHORIZED':
      case 'CONCLUDED':
      case 'RESOLVED':
        return {
          textColor: 'success900',
          bgColor: 'success100'
        }
      case 'UNDER_REVIEW_AT_THE_HOSPITAL':
      case 'UNDER_REVIEW_AT_THE_INSURANCE':
      case 'IN_PROGRESS':
        return {
          textColor: 'secondary900',
          bgColor: 'secondary100'
        }
      case 'PENDENT':
        return {
          textColor: 'terciary900',
          bgColor: 'terciary100'
        }
      case 'CANCELLED':
      default:
        return {
          textColor: 'neutral900',
          bgColor: 'neutral200'
        }
    }
  }

  return (
    <S.StatusTag
      bgColor={getTag().bgColor as keyof typeof theme.ds.colors}
      color={getTag().textColor as keyof typeof theme.ds.colors}
      as="span"
      className="capitalize-fl"
    >
      {getTranslatedLabel()}
    </S.StatusTag>
  )
}

export default SchediligStatusCard
