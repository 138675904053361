import {
  updatePatientHealthInsurance,
  updatePatientInfoMutation,
  invitePatientMutation,
  addPainAlertMutation,
  updatePatientMedicineAndDiseasesMutation,
  updateLifeHabitsMutation,
  createSuggestionOrCriticismMutation,
  updatePatientHealthInsuranceByCpfMutation,
  addPatientMutation,
  completePatientSignup,
  validatePatientMotherAndBirthdayMutation,
  invitePatientFromMVMutation,
  updateExamMutation,
  inviteNewBornPatientMutation,
  updatePatientByIdMutation,
  updatePatientInfoByIdMutation,
  updateHealthInsuranceInMv,
  addHealthInsuranceInMv
} from 'repository/graphql/mutations/index'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import {
  RepositoryErrors,
  RepositoryErrorsKey
} from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { PatientRepository as IPatientRepository } from 'repository/interfaces/patient-repository'
import {
  ApiStatusCode,
  Interval,
  Pagination
} from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import {
  getPatientByCpf,
  getPatientNextAttendancesQuery,
  getPatientPendenciesQuery,
  getPatientSchedules,
  LoadExamQuery,
  loadExamsQuery,
  loadPatientDocumentsQuery,
  loadPatientHideContactQuery,
  loadPatientInfoQuery,
  loadPatientPossibleMotherNameQuery,
  loadPatientsQuery,
  loadPatientSurgeryQuery,
  searchPatientsQuery,
  shareExamResultQuery,
  validateRegisteredPatientInDbQuery,
  checkPatientByEmailQuery,
  checkPatientByPhoneQuery,
  checkPatientByCpfQuery
} from 'repository/graphql/queries'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadHealthInsuraceCard } from 'domain/usecases/patient/upload-health-insurance-card'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { LoadPatientDocuments } from 'domain/usecases/patient/load-patient-documents'
import { LoadPatientExamDocuments } from 'domain/usecases/patient/load-patient-exam-documents'
import { DeletePatientExamDocument } from 'domain/usecases/patient/delete-patient-exam-document'
import { LoadPatientSurgery } from 'domain/usecases/patient/load-patient-surgery'
import { LoadPatientByCpf } from 'domain/usecases/patient/load-patient-by-cpf'
import { InvitePatient } from 'domain/usecases/patient/invite-patient'
import { AddPainAlert } from 'domain/usecases/patient/add-pain-alert'
import { UpdateMedicineAndDiseases } from 'domain/usecases/patient/update-medicine-and-diseases'
import { UpdateLifeHabits } from 'domain/usecases/patient/update-life-habits'
import { CreateSuggestionOrCriticism } from 'domain/usecases/patient/create-suggestion-or-criticism'
import { UpdatePatientHealthInsuranceByCpf } from 'domain/usecases/patient/update-patient-health-insurance-by-cpf'
import { UploadHealthInsuraceCardByCpf } from 'domain/usecases/patient/upload-health-insurance-card-by-cpf'
import { LoadPatients } from 'domain/usecases/patient/load-patients'
import { LoadPatientSchedules } from 'domain/usecases/patient/load-patient-schedules'
import { AddPatient } from 'domain/usecases/patient/add-patient'
import { SearchPatient } from 'domain/usecases/patient/search-patient'
import { CompletePatientSignup } from 'domain/usecases/patient/complete-patient-signup'
import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
import { LoadPatientHideContact } from 'domain/usecases/patient/load-patient-hide-contact'
import { LoadPatientPossibleMotherName } from 'domain/usecases/patient/load-patient-possible-mother-name'
import { ValidatePatientMotherAndBirthday } from 'domain/usecases/patient/validate-patient-mother-and-birthday'
import { ValidateRegisteredPatientInDb } from 'domain/usecases/patient/validate-registered-patient-in-db'
import { InvitePatientFromMv } from 'domain/usecases/patient/invite-patient-from-mv'
import { LoadExams } from 'domain/usecases/patient/load-exams'
import { UpdateExam } from 'domain/usecases/patient/update-exam'
import { LoadPatientPendencies } from 'domain/usecases/patient/load-patient-pendencies'
import { LoadExamById } from 'domain/usecases/patient/loadExamById'
import { InviteNewBornPatient } from 'domain/usecases/patient/invite-newborn-patient'
import { ListExamResult } from 'domain/usecases/exam/list-exam-result'
import { IShareExamResult } from 'domain/usecases/exam/share-exam-result'
import { ILoadExamResultPdf } from 'domain/usecases/exam/get-exam-result-pdf'
import { GetPatientNextAttendances } from 'domain/usecases/patient/get-patient-next-attendances'
import { UpdatePatientById } from 'domain/usecases/patient/update-patient-by-id'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'
import { CheckPatientByEmail } from 'domain/usecases/patient/check-patient-by-email'
import { CheckPatientByPhone } from 'domain/usecases/patient/check-patient-by-phone'
import { CheckPatientByCpf } from 'domain/usecases/patient/check-patient-by-cpf'
import { LoadPatientSurgicalOrders } from 'domain/usecases/patient/load-patient-surgical-orders'
import { GetPatientExamAppointments } from 'domain/usecases/patient/get-exam-appointments'
import { SearchExams } from 'domain/usecases/exam/search-exams'
import { GetExamAvailableDates } from 'domain/usecases/exam/get-exam-available-dates'
import { CreatePatientExamAppointments } from 'domain/usecases/patient/create-patient-exam-appointment'
import { GetExamScheduleOffersByMonth } from 'domain/usecases/exam/get-exam-schedule-offers-by-month'
import { GetExamScheduleHours } from 'domain/usecases/exam/get-exam-schedule-hours'
import { GetExamResult } from 'domain/usecases/exam/get-exam-result'
import parseParams from 'common/utils/parseParams'
import { GetPatientPendency } from 'domain/usecases/surgical-pendency/get-patient-pendency'
import { CustomError } from 'common/errors'
import { ILoadExamResultPDF } from 'domain/usecases/exam/load-exam-result-pdf'
import { RequestSurgicalOrder } from 'domain/usecases/patient/request-surgical-order'

export class PatientRepository implements IPatientRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  // Deprecated:
  async loadExamPdf({
    pdf_url,
    token,
    attendanceId,
    orderId,
    orderItemId
  }: ILoadExamResultPdf.Params): Promise<ILoadExamResultPdf.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ILoadExamResultPdf.Model>
    const httpResponse: any = await apiRepository.get(
      {
        url: `/exam/load-exam-pdf?pdf_url=${encodeURIComponent(
          pdf_url
        )}&token=${token}&orderId=${orderId}&attendanceId=${attendanceId}&orderItemId=${orderItemId}`
      },
      true
    )

    return httpResponse
  }

  async loadExamPDF(
    params: ILoadExamResultPDF.Params
  ): Promise<ILoadExamResultPDF.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ILoadExamResultPDF.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/exams/download?attendance_id=${
          params.attendance_id
        }&exam_order_id=${params.exam_order_id}&type=${
          params.type
        }&exam_order_item_id=${params.exam_order_item_id || ''}`
      },
      true
    )

    if (httpResponse.status && httpResponse.status !== ApiStatusCode.ok) {
      throw new CustomError('Não foi possível carregar o documento.')
    } else {
      return httpResponse.data
    }
  }

  async shareProcedureResult(
    params: IShareExamResult.Params
  ): Promise<IShareExamResult.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<IShareExamResult.Model>

    const query = shareExamResultQuery()

    const body: { query: string; variables?: any } = {
      query: query.query,
      variables: {
        ...makeGraphQLVariable(params, 'data')
      }
    }

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: body,
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as IShareExamResult.Model
    }
  }

  async listExamResult(): Promise<ListExamResult.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ListExamResult.Model>
    const httpResponse: any = await apiRepository.get({
      url: `/exams`
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error! as RepositoryErrorsKey]
      )
    } else {
      return httpResponse.body.data as ListExamResult.Model
    }
  }

  // Deprecated:
  async loadExamResult(
    data: GetExamResult.Params
  ): Promise<GetExamResult.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetExamResult.Model>
    const httpResponse = await apiRepository.post({
      url: `/exam/results/`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetExamResult.Model
    }
  }

  async loadExamById(params: LoadExamById.Params): Promise<LoadExamById.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadExamById.Model>

    const query = LoadExamQuery(params!)

    const body: { query: string; variables?: any } = {
      query: query.query,
      variables: {
        ...makeGraphQLVariable(params.data, 'data')
      }
    }

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: body,
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadExamById.Model
    }
  }

  async loadExams(params?: LoadExams.Params): Promise<LoadExams.Model> {
    const apiRepository = this.apiRepository as IApiRepository<LoadExams.Model>

    const query = loadExamsQuery(params!)

    const body: { query: string; variables?: any } = {
      query: query.query
    }

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: body,
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadExams.Model
    }
  }

  async updateExam(params: UpdateExam.Params): Promise<UpdateExam.Model> {
    const apiRepository = this.apiRepository as IApiRepository<UpdateExam.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateExamMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateExamMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateExam.Model
    }
  }

  async createSuggestionOrCriticism(
    params: CreateSuggestionOrCriticism.Params
  ): Promise<void> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateSuggestionOrCriticism.Model>

    const mutation = createSuggestionOrCriticismMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: mutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: mutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateSuggestionOrCriticism.Model
    }
  }

  async updatePatientInfo(
    params: UpdatePatientInfo.Params
  ): Promise<UpdatePatientInfo.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientInfo.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updatePatientInfoMutation.query,
        variables: {
          ...makeGraphQLVariable(params),
          ...makeGraphQLVariable(true, 'forceUpdateInMV')
        }
      },
      query: updatePatientInfoMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientInfo.Model
    }
  }

  async updatePatientInfoById(
    params: UpdatePatientInfoById.Params
  ): Promise<UpdatePatientInfoById.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientInfoById.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updatePatientInfoByIdMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updatePatientInfoByIdMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientInfoById.Model
    }
  }

  async loadPatientInfo(
    params?: LoadPatientInfo.Params,
    patient_id?: number
  ): Promise<LoadPatientInfo.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientInfo.Model>

    const query = loadPatientInfoQuery(params!)

    const body: { query: string; variables?: any } = {
      query: query.query
    }

    if (patient_id) {
      body.variables = makeGraphQLVariable(patient_id, 'patient_id')
    }

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: body,
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientInfo.Model
    }
  }

  async loadPatients(params: LoadPatients.Params): Promise<LoadPatients.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatients.Model>

    const query = loadPatientsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.name, 'name'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatients.Model
    }
  }

  async loadPatientByCpf(
    cpf: string,
    params?: LoadPatientByCpf.Params
  ): Promise<LoadPatientByCpf.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientByCpf.Model>

    const query = getPatientByCpf(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(cpf, 'cpf')
      },
      query: query.name
    })

    const { body, statusCode } = httpResponse

    if (statusCode && statusCode !== ApiStatusCode.ok && !body) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientByCpf.Model
    }
  }

  async updateHealthInsurance(
    params: UpdatePatientHealthInsurance.Params
  ): Promise<UpdatePatientHealthInsurance.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientHealthInsurance.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updatePatientHealthInsurance.query,
        variables: makeGraphQLVariable(params)
      },
      query: updatePatientHealthInsurance.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientHealthInsurance.Model
    }
  }

  async updateHealthInsuranceInMv(
    params: UpdatePatientHealthInsurance.Params
  ): Promise<UpdatePatientHealthInsurance.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientHealthInsurance.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateHealthInsuranceInMv.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateHealthInsuranceInMv.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientHealthInsurance.Model
    }
  }

  async addHealthInsuranceInMv(
    params: UpdatePatientHealthInsurance.Params
  ): Promise<UpdatePatientHealthInsurance.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientHealthInsurance.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: addHealthInsuranceInMv.query,
        variables: makeGraphQLVariable(params)
      },
      query: addHealthInsuranceInMv.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientHealthInsurance.Model
    }
  }

  async updateHealthInsuranceByCpf(
    params: UpdatePatientHealthInsuranceByCpf.Params
  ): Promise<UpdatePatientHealthInsuranceByCpf.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientHealthInsuranceByCpf.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updatePatientHealthInsuranceByCpfMutation.query,
        variables: {
          cpf: params.cpf,
          ...makeGraphQLVariable(params.params)
        }
      },
      query: updatePatientHealthInsuranceByCpfMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientHealthInsuranceByCpf.Model
    }
  }

  async updateMedicineAndDiseases(
    params: UpdateMedicineAndDiseases.Params
  ): Promise<UpdateMedicineAndDiseases.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateMedicineAndDiseases.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updatePatientMedicineAndDiseasesMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updatePatientMedicineAndDiseasesMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateMedicineAndDiseases.Model
    }
  }

  async updateLifeHabits(
    params: UpdateLifeHabits.Params
  ): Promise<UpdateLifeHabits.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateLifeHabits.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: updateLifeHabitsMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: updateLifeHabitsMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateLifeHabits.Model
    }
  }

  async uploadHealthInsuranceCardFront(
    params: UploadHealthInsuraceCard.Params
  ): Promise<UploadHealthInsuraceCard.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadHealthInsuraceCard.Model>
    const httpResponse = await apiRepository.post({
      url: '/patient/upload-front-card',
      body: params.file
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadHealthInsuraceCard.Model
    }
  }

  async uploadHealthInsuranceCardBack(
    params: UploadHealthInsuraceCard.Params
  ): Promise<UploadHealthInsuraceCard.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadHealthInsuraceCard.Model>

    const httpResponse = await apiRepository.post({
      url: '/patient/upload-back-card',
      body: params.file
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadHealthInsuraceCard.Model
    }
  }

  async uploadHealthInsuranceCardFrontByCpf(
    params: UploadHealthInsuraceCardByCpf.Params
  ): Promise<void> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadHealthInsuraceCard.Model>
    const httpResponse = await apiRepository.post({
      url: '/patient/upload-front-card-by-cpf',
      body: params.params.file
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadHealthInsuraceCard.Model
    }
  }

  async uploadHealthInsuranceCardBackByCpf(
    params: UploadHealthInsuraceCardByCpf.Params
  ): Promise<void> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadHealthInsuraceCard.Model>

    const httpResponse = await apiRepository.post({
      url: '/patient/upload-back-card-by-cpf',
      body: params.params.file
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadHealthInsuraceCard.Model
    }
  }

  async uploadPatientDocument(
    params: UploadPatientDocument.Params
  ): Promise<UploadPatientDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UploadPatientDocument.Model>

    const httpResponse = await apiRepository.post({
      url: '/patient/upload-patient-document',
      body: params.form
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UploadPatientDocument.Model
    }
  }

  async loadPatientDocuments(
    params?: LoadPatientDocuments.Params
  ): Promise<LoadPatientDocuments.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientDocuments.Model>

    const query = loadPatientDocumentsQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientDocuments.Model
    }
  }

  async loadPatientSurgery(
    params?: LoadPatientSurgery.Params
  ): Promise<LoadPatientSurgery.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientSurgery.Model>

    const query = loadPatientSurgeryQuery(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientSurgery.Model
    }
  }

  async invitePatient(
    params: InvitePatient.Params
  ): Promise<InvitePatient.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<InvitePatient.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: invitePatientMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: invitePatientMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as InvitePatient.Model
    }
  }

  async addPainAlert(params: AddPainAlert.Params): Promise<AddPainAlert.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<AddPainAlert.Model>

    const mutation = addPainAlertMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: mutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: mutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddPainAlert.Model
    }
  }

  async loadPatientSchedules(
    params: LoadPatientSchedules.Params,
    pagination?: Pagination,
    interval?: Interval
  ): Promise<LoadPatientSchedules.Model[]> {
    const apiRepository = this.apiRepository as IApiRepository<{
      data: LoadPatientSchedules.Model[]
    }>

    const query = getPatientSchedules(params!)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(pagination, 'pagination'),
          ...makeGraphQLVariable(interval, 'interval')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body?.data as LoadPatientSchedules.Model[]
    }
  }

  async addPatient(params: AddPatient.Params): Promise<AddPatient.Model> {
    const apiRepository = this.apiRepository as IApiRepository<AddPatient.Model>

    const mutation = addPatientMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: mutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: mutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AddPatient.Model
    }
  }

  async searchPatients(
    params: SearchPatient.Params
  ): Promise<SearchPatient.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<SearchPatient.Model>

    const query = searchPatientsQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.name, 'name'),
          ...makeGraphQLVariable(params.bday, 'bday'),
          ...makeGraphQLVariable(params.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as SearchPatient.Model
    }
  }

  async completePatientSignup(
    params: CompletePatientSignup.Params
  ): Promise<CompletePatientSignup.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CompletePatientSignup.Model>

    const mutation = completePatientSignup

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: mutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: mutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CompletePatientSignup.Model
    }
  }

  async downloadPatientDocument(
    document_id: number
  ): Promise<DownloadPatientDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<DownloadPatientDocument.Model>

    const httpResponse: any = await apiRepository.get(
      {
        url: `/patient/document/${document_id}`
      },
      true
    )

    const contentType = httpResponse.headers['content-type']
    const data = httpResponse.data

    return {
      contentType,
      data
    }
  }

  async loadPatientHideContact(
    params: LoadPatientHideContact.Params
  ): Promise<LoadPatientHideContact.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientHideContact.Model>

    const query = loadPatientHideContactQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.cpf, 'cpf')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientHideContact.Model
    }
  }

  async loadPatientPossibleMotherName(
    params: LoadPatientPossibleMotherName.Params
  ): Promise<LoadPatientPossibleMotherName.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientPossibleMotherName.Model>

    const query = loadPatientPossibleMotherNameQuery()

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.cpf, 'cpf')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientPossibleMotherName.Model
    }
  }

  async validatePatientMotherAndBirthday(
    params: ValidatePatientMotherAndBirthday.Params
  ): Promise<ValidatePatientMotherAndBirthday.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ValidatePatientMotherAndBirthday.Model>

    const query = validatePatientMotherAndBirthdayMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.motherName, 'motherName'),
          ...makeGraphQLVariable(params.birthday, 'birthday'),
          ...makeGraphQLVariable(params.patientCpf, 'cpf')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as ValidatePatientMotherAndBirthday.Model
    }
  }

  async validateRegisteredPatientInDb(
    params: ValidateRegisteredPatientInDb.Params
  ): Promise<boolean> {
    const apiRepository = this.apiRepository as IApiRepository<boolean>

    const query = validateRegisteredPatientInDbQuery

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.cpf, 'cpf')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as boolean
    }
  }

  async checkPatientByEmail(email: string): Promise<CheckPatientByEmail.Model> {
    const apiRepository = this.apiRepository as IApiRepository<boolean>

    const query = checkPatientByEmailQuery

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(email, 'email')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CheckPatientByEmail.Model
    }
  }

  async checkPatientByPhone(phone: string): Promise<CheckPatientByPhone.Model> {
    const apiRepository = this.apiRepository as IApiRepository<boolean>

    const query = checkPatientByPhoneQuery

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(phone, 'phone')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CheckPatientByPhone.Model
    }
  }

  async checkPatientByCpf(cpf: string): Promise<CheckPatientByCpf.Model> {
    const apiRepository = this.apiRepository as IApiRepository<boolean>

    const query = checkPatientByCpfQuery

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(cpf, 'cpf')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CheckPatientByCpf.Model
    }
  }

  async invitePatientFromMV(
    params: InvitePatientFromMv.Params
  ): Promise<InvitePatientFromMv.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<InvitePatientFromMv.Model>

    const query = invitePatientFromMVMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params.cpf, 'cpf'),
          ...makeGraphQLVariable(params.send, 'send')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as InvitePatientFromMv.Model
    }
  }

  async loadPatientPendencies(
    params: LoadPatientPendencies.Params
  ): Promise<LoadPatientPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientPendencies.Model>
    const query = getPatientPendenciesQuery(params.params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.query, 'query'),
          ...makeGraphQLVariable(params?.pagination, 'pagination')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadPatientPendencies.Model
    }
  }

  async inviteNewBornPatient(
    params: InviteNewBornPatient.Params
  ): Promise<InviteNewBornPatient.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<InviteNewBornPatient.Model>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: inviteNewBornPatientMutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: inviteNewBornPatientMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as InviteNewBornPatient.Model
    }
  }

  async getPatientNextAttendances(
    params: GetPatientNextAttendances.Params
  ): Promise<GetPatientNextAttendances.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetPatientNextAttendances.Model>

    const query = getPatientNextAttendancesQuery(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(params.limit, 'limit')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetPatientNextAttendances.Model
    }
  }

  async updatePatientById(
    params: UpdatePatientById.Params
  ): Promise<UpdatePatientById.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdatePatientById.Model>

    const mutation = updatePatientByIdMutation

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: mutation.query,
        variables: makeGraphQLVariable(params)
      },
      query: mutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdatePatientById.Model
    }
  }

  async loadPatientSurgicalOrders(
    params?: LoadPatientSurgicalOrders.Params
  ): Promise<LoadPatientSurgicalOrders.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientSurgicalOrders>

    const httpResponse: any = await apiRepository.get({
      url: '/surgical-order/by-patient',
      params: params,
      paramsSerializer: (params: any) => parseParams(params)
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as LoadPatientSurgicalOrders.Model
    }
  }

  async loadPatientExamDocuments(
    patient_id: number
  ): Promise<LoadPatientExamDocuments.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientSurgicalOrders>

    const httpResponse: any = await apiRepository.get({
      url: `/patient/documents/?patient_id=${patient_id}`
    })

    return httpResponse.body.data as LoadPatientExamDocuments.Model
  }

  async deletePatientExamDocument(
    params: DeletePatientExamDocument.Params
  ): Promise<DeletePatientExamDocument.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadPatientSurgicalOrders>

    const httpResponse: any = await apiRepository.delete({
      url: `/patient/documents/?patient_id=${params.patient_id}&document_id=${params.document_id}`
    })

    return httpResponse.body.data as DeletePatientExamDocument.Model
  }

  async getPatientExamAppointments(
    params: GetPatientExamAppointments.Params
  ): Promise<GetPatientExamAppointments.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.get({
      url: `/api/v1/appointments?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&patient_id=${params.patient_id}`
    })

    return httpResponse.body.data as GetPatientExamAppointments.Model
  }

  async createPatientExamAppointment(
    params: CreatePatientExamAppointments.Params
  ): Promise<CreatePatientExamAppointments.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/appointments`,
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as CreatePatientExamAppointments.Model
    }
  }

  async searchExams(data: SearchExams.Params): Promise<SearchExams.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/exams/search`,
      body: data
    })

    return httpResponse.body.data as SearchExams.Model
  }

  async getExamAvailableDates(
    data: GetExamAvailableDates.Params
  ): Promise<GetExamAvailableDates.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-recent-schedules`,
      body: data
    })

    return httpResponse.body.data as GetExamAvailableDates.Model
  }

  async getExamScheduleOffersByMonth(
    data: GetExamScheduleOffersByMonth.Params
  ): Promise<GetExamScheduleOffersByMonth.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-schedules-by-month`,
      body: data
    })

    return httpResponse.body.data as GetExamScheduleOffersByMonth.Model
  }

  async getExamScheduleHours(
    data: GetExamScheduleHours.Params
  ): Promise<GetExamScheduleHours.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.post({
      url: `/api/v1/offers/get-schedule-hours`,
      body: data
    })

    return httpResponse.body.data as GetExamScheduleHours.Model
  }

  async getPatientPendency(
    data: GetPatientPendency.Params
  ): Promise<GetPatientPendency.Model> {
    const apiRepository = this.apiRepository as IApiRepository<IApiRepository>

    const httpResponse: any = await apiRepository.get({
      url: `/surgical-pendency/patients`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(
        RepositoryErrors[httpResponse.error as keyof typeof RepositoryErrors]
      )
    } else {
      return httpResponse.body.data as GetPatientPendency.Model
    }
  }

  async requestSurgicalOrder(
    data: RequestSurgicalOrder.Params
  ): Promise<RequestSurgicalOrder.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<RequestSurgicalOrder.Model>
    const httpResponse = await apiRepository.post({
      url: `/patient/request-surgical-order`,
      body: data
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.created
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as RequestSurgicalOrder.Model
    }
  }
}
