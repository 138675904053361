import styled, { css } from 'styled-components'
import { Tab, Tabs } from '@material-ui/core'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

type StyledTabProps = {
  primary?: string
}

export const StyledTabs = styled(Tabs)<StyledTabProps>`
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.ds.colors.primary600};
  }

  ${({ primary }) =>
    primary &&
    css`
      & .MuiTabs-indicator {
        background-color: ${({ theme }) => theme.colors.primary};
      }
    `}
`

export const StyledTab = styled(Tab)<StyledTabProps>`
  span {
    text-transform: capitalize;
    color: #9b9b9b;
    font-weight: 500;
    font-size: ${({ theme }) => theme.font.sizes.medium};
  }

  &.Mui-selected {
    &.MuiTab-root {
      background-color: transparent;
      border-radius: 6px 6px 0 0;
    }

    & .MuiTab-wrapper {
      font-weight: 600;
      color: ${({ theme }) => theme.ds.colors.primary600};
    }
  }

  ${({ primary }) =>
    primary &&
    css`
      &.MuiButtonBase-root {
        background: ${({ theme }) => theme.ds.colors.primary600};

        & + &.MuiButtonBase-root {
          margin-left: 4px;
        }
      }
    `}
`
