import { User } from './user-model'

export class AccountModel {
  private _accessToken: string
  private _user: User

  constructor(accessToken: string, user: User) {
    this._accessToken = accessToken
    this._user = user
  }

  public get accessToken(): string {
    return this._accessToken
  }

  public set accessToken(accessToken: string) {
    this._accessToken = accessToken
  }

  public get user(): User {
    return this._user
  }

  public set user(user: User) {
    this._user = user
  }

  toJSON() {
    return {
      accessToken: this._accessToken,
      user: this._user
    }
  }
}
