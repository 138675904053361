import { SyncAmbulatoryToken } from 'domain/usecases/ambulatory/sync-ambulatory-token'
import { AmbulatoryRepository } from 'repository/interfaces/ambulatory-repository'

export class RemoteSyncAmbulatoryToken implements SyncAmbulatoryToken {
  constructor(private readonly ambulatoryRepository: AmbulatoryRepository) {}

  async sync(
    params: SyncAmbulatoryToken.Params
  ): Promise<SyncAmbulatoryToken.Model> {
    return await this.ambulatoryRepository.syncAmbulatoryToken(params)
  }
}
