import styled, { css } from 'styled-components'
import { CardWrapper } from 'presentation/shared/components/Card'

export const CardHeader = styled.div`
  .health-insurance-name {
    color: ${({ theme }) => theme.ds.colors.neutral600};
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
  }

  .health-insurance-title {
    margin: 4px 0;
    color: ${({ theme }) => theme.ds.colors.neutral700};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    text-transform: capitalize;
  }
`

export const CardBody = styled.div`
  color: #707683;
  strong {
    color: ${({ theme }) => theme.ds.colors.neutral600};
    font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
    font-weight: ${({ theme }) => theme.ds.typography.weight.semiBold};
  }

  p {
    color: ${({ theme }) => theme.ds.colors.neutral900};
    font-size: ${({ theme }) => theme.ds.typography.size.small};
    font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  }
`

export const CardFooter = styled.footer`
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.ds.colors.primary50};

  padding: 7px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  top: 16px;
  right: 16px;
  width: calc(100% + 32px);

  strong {
    color: ${({ theme }) => theme.ds.colors.primary600};
  }
`

export const HealthInsuranceCardWrapper = styled(CardWrapper)`
  position: relative;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 1px 1px 2px 1px #0000003d;
  box-shadow: 1px 1px 3px 1px #0000001f;

  background: linear-gradient(0deg, #fbfbfb, #fbfbfb),
    linear-gradient(0deg, #52dbcc, #52dbcc);

  &:hover {
    border: 1px solid #52dbcc;
    box-shadow: 0px 4px 4px 0px #0000003d;
    box-shadow: 0px 3px 3px 3px #0000001f;
  }

  &.-disabled {
    pointer-events: none;
    opacity: 0.8;

    ${CardHeader} {
      .health-insurance-type,
      .health-insurance-title,
      .health-insurance-name {
        color: ${({ theme }) => theme.ds.colors.neutral400};
      }
    }

    ${CardBody} {
      color: ${({ theme }) => theme.ds.colors.neutral400};
    }
  }
`

export const PlanservInfo = styled.p`
  ${({ theme }) => css`
    margin-top: 16px;
    font-size: ${theme.ds.typography.size.xsmall};
    margin-bottom: 8px;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors.danger700};

    a {
      text-decoration: underline;
      color: ${theme.ds.colors.info500};
      transition: color ease 0.1s;
      font-weight: ${theme.ds.typography.weight.bold};

      &:hover {
        color: ${theme.ds.colors.info300};
      }
    }
  `}
`
