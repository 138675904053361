import styled from 'styled-components'

export const Wrapper = styled.form`
  flex: 1;
  height: 100%;
  position: relative;

  .button-submit {
    padding: 14px;
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 70px;
  }

  .switch-contact {
    display: flex;
    align-items: center;
    margin-top: 14px;
    span {
      margin-left: 5px;
      font-weight: 600;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.lightGray};
    }
  }
`

export const ContactsDiv = styled.div`
  margin-top: 24px;

  hr {
    margin-bottom: 8px;
  }
`

export const List = styled.ul`
  list-style: none;
  max-height: 470px;
  overflow-y: hidden;
  overflow-y: scroll;

  @media (max-height: 1180px) {
    max-height: 700px;
  }

  @media (max-height: 1025px) {
    max-height: 560px;
  }

  @media (max-height: 950px) {
    max-height: 370px;
  }

  @media (max-height: 852px) {
    max-height: 400px;
  }

  @media (max-height: 750px) {
    max-height: 200px;
  }

  @media (max-height: 670px) {
    max-height: 210px;
  }

  li:nth-child(odd) {
    background: rgba(244, 237, 239, 0.85);
  }
  li {
    width: 100%;
    height: 64px;
    color: ${({ theme }) => theme.colors.lightGray};
    padding: 9px;
    letter-spacing: 0.15px;

    strong {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray};
      display: block;
    }
  }
`
