import { CheckPatientByCpf } from 'domain/usecases/patient/check-patient-by-cpf'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteCheckPatientByCpf implements CheckPatientByCpf {
  constructor(private readonly patientRepository: PatientRepository) {}

  check(cpf: string): Promise<CheckPatientByCpf.Model> {
    return this.patientRepository.checkPatientByCpf(cpf)
  }
}
