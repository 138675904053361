import React, { useEffect, useState } from 'react'
import CalendarLayout from 'presentation/layouts/Calendar'
import { useServices } from 'presentation/hooks/use-services'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { SchedulingHours } from 'domain/entities/scheduling-hours-model'
import { SchedulingSchedulesByMonth } from 'domain/entities/scheduling-schedule-by-month-model'
import { SchedulingDoctor } from 'domain/entities/scheduling-doctor-model'
import { SchedulingUnit } from 'domain/entities/scheduling-unit-model'
import { SchedulingSpecialty } from 'domain/entities/scheduling-specialty-model'
import { SchedulingHealthInsurance } from 'domain/entities/scheduling-health-insurance'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import { SchedulingSchedule } from 'domain/entities/scheduling-schedule-model'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

type State = {
  filteredSearchResult: {
    data: {
      getSchedules: SchedulingSchedule[]
    }
  }
  healthInsurance: SchedulingHealthInsurance
  doctor: SchedulingDoctor
  specialty: SchedulingSpecialty
  units: SchedulingUnit[]
  patient: SchedulingPatient
  schedule_id: number
  date_initial: string
  cd_it_agenda_central: number
}

const Calendar = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const history = useHistory()
  const location = useLocation<State>()
  const schedulingService = useServices().scheduling
  const [availableHours, setAvailableHours] = useState<SchedulingHours[]>(
    [] as SchedulingHours[]
  )
  const [availableDates, setAvailableDates] = useState<
    SchedulingSchedulesByMonth[]
  >([] as SchedulingSchedulesByMonth[])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentDate, setCurrentDate] = useState<SchedulingSchedulesByMonth>()

  const {
    filteredSearchResult,
    healthInsurance,
    specialty,
    doctor,
    units,
    schedule_id,
    patient,
    date_initial,
    cd_it_agenda_central
  } = location.state

  const getSchedulesByMonth = async (date: string) => {
    setIsLoading(true)
    const response = await schedulingService.getSchedulesByMonth({
      data: {
        healthInsurance,
        specialty,
        doctor,
        patient: {
          name: patient?.name,
          email: patient?.email,
          age: patient?.age,
          gender: patient?.gender,
          patient_id: patient?.patient_id,
          user_id: patient?.user_id
        },
        units,
        schedule_id,
        month: date
      },
      fields: [
        `
          unit_name
          date_initial
          schedule_id
          specialty {
            name
            specialty_id
          }
          hours {
            date_begin
            hour_initial_id
            hour_finish_id
          }
          doctor {
            name
            doctor_id
          }
        `
      ]
    })
    setAvailableDates(response as SchedulingSchedulesByMonth[])
    setAvailableHours([] as SchedulingHours[])
    setIsLoading(false)
  }

  useEffect(() => {
    getSchedulesByMonth(moment(date_initial).format('YYYY-MM-DD'))
  }, [])

  const selectedDate = (date: string) => {
    return availableDates.find((items) => {
      const currentDate = moment(items.date_initial).format('YYYY-MM-DD')
      return currentDate === moment(date).format('YYYY-MM-DD')
    })
  }

  const getHoursByDay = async (date: string) => {
    setIsLoading(true)
    const selectedDateItem = selectedDate(date)
    setCurrentDate(selectedDateItem)

    const response = await schedulingService.getScheduleHours({
      data: {
        healthInsurance,
        specialty,
        doctor,
        patient: {
          name: patient?.name,
          email: patient?.email,
          age: patient?.age,
          gender: patient?.gender,
          patient_id: patient?.patient_id,
          user_id: patient?.user_id
        },
        units,
        schedule_id: selectedDateItem?.schedule_id || 0,
        date: date
      },
      fields: [
        'date_begin',
        'date_finish',
        'hour_initial_id',
        'hour_finish_id',
        'schedule_id'
      ]
    })

    setAvailableHours(response as SchedulingHours[])
    // setAvailableHours(selectedDate?.hours as SchedulingHours[])
    setIsLoading(false)
  }

  const submitSchedule = (time: number) => {
    const selectedHour = availableHours.find((item) => {
      return time === item.hour_finish_id
    })

    const obj = {
      filteredSearchResult,
      healthInsurance: {
        healthInsuranceName: healthInsurance.name,
        healthPlanName: healthInsurance.plan_name,
        healthPlanCode: healthInsurance.plan_id,
        healthInsuranceCode: healthInsurance.health_insurance_id
      },
      units,
      selectedSchedule: {
        specialty,
        doctor,
        date_initial,
        schedule_id: selectedHour?.schedule_id || 0,
        unit_name: units[0].name,
        unit_id: units[0].unit_id,
        unit_description: units[0].description,
        cd_it_agenda_central
        // hours
      },
      patient,
      selectedHour
    }

    history.push('/agendamento/consulta/resumo', obj)
  }

  return (
    <CalendarLayout
      availableDates={availableDates}
      availableHours={availableHours}
      dateInitial={date_initial}
      getSchedulesByMonth={getSchedulesByMonth}
      getHoursByDay={getHoursByDay}
      submitSchedule={submitSchedule}
    />
  )
})

export default Calendar
