import { PatientDocument } from 'common/enum/patient-document'
import { PendencyType } from 'domain/entities/surgical-pendency'

export type DocumentType = {
  type: PatientDocument[]
  label: string
  title: string
  maxFileSizeInMB: number
  maxFiles: number
  minFiles: number
  extensionsAllowed: string[]
  acceptFiles: string
}

export class DocumentsType {
  static preAnestheticEvaluationDocumentType: DocumentType = {
    type: [PatientDocument.PRE_ANESTHETIC_EVALUATION],
    label: 'Consulta pré-anestésica',
    title: 'Consulta pré-anestésica',
    maxFileSizeInMB: 10,
    maxFiles: 10,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static surgicalConsentDocumentType: DocumentType = {
    type: [PatientDocument.SURGICAL_CONSENT],
    label: 'Consentimento Cirúrgico',
    title: 'Consentimento Cirúrgico',
    maxFileSizeInMB: 10,
    maxFiles: 10,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static anestheticConsentDocumentType: DocumentType = {
    type: [PatientDocument.ANESTHETIC_CONSENT],
    label: 'Consentimento anestésico',
    title: 'Consentimento anestésico',
    maxFileSizeInMB: 10,
    maxFiles: 10,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static hemotherapyConsentDocumentType: DocumentType = {
    type: [PatientDocument.HEMOTHERAPY_CONSENT],
    label: 'Consentimento de hemoterapia',
    title: 'Consentimento de hemoterapia',
    maxFileSizeInMB: 10,
    maxFiles: 10,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static identityCardDocumentType: DocumentType = {
    type: [PatientDocument.IDENTITY_CARD],
    label: 'Carteira de Identidade',
    title: 'Carteira de Identidade',
    maxFileSizeInMB: 6,
    maxFiles: 2,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static healthCardDocumentType: DocumentType = {
    type: [PatientDocument.HEALTH_CARD],
    label: 'Carteira do convênio',
    title: 'Carteira do convênio',
    maxFileSizeInMB: 6,
    maxFiles: 2,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static medicalOrderDocumentType: DocumentType = {
    type: [PatientDocument.MEDICAL_ORDER],
    label: 'Pedido médico',
    title: 'Pedido médico',
    maxFileSizeInMB: 5,
    maxFiles: 5,
    minFiles: 1,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static medicalReportDocumentType: DocumentType = {
    type: [PatientDocument.MEDICAL_REPORT],
    label: 'Relatório médico',
    title: 'Relatório médico',
    maxFileSizeInMB: 10,
    maxFiles: 10,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static examReportDocumentType: DocumentType = {
    type: [PatientDocument.EXAM_REPORT],
    label: 'Laudos 1',
    title: 'Laudos dos exames',
    maxFileSizeInMB: 19,
    maxFiles: 60,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static surgicalOrderDocumentType: DocumentType = {
    type: [PatientDocument.SURGICAL_ORDER],
    label: 'Pedido Cirúrgico',
    title: 'Pedido Cirúrgico',
    maxFileSizeInMB: 5,
    maxFiles: 5,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }
  static authorizationGuideDocumentType: DocumentType = {
    type: [PatientDocument.AUTHORIZATION_GUIDE],
    label: 'Guia de autorização',
    title: 'Guia de autorização',
    maxFileSizeInMB: 5,
    maxFiles: 5,
    minFiles: 0,
    extensionsAllowed: ['jpg', 'jpeg', 'pdf'],
    acceptFiles: 'image/jpeg, application/pdf'
  }

  static getAllDocuments(): DocumentType[] {
    return [
      this.preAnestheticEvaluationDocumentType,
      this.surgicalConsentDocumentType,
      this.anestheticConsentDocumentType,
      this.hemotherapyConsentDocumentType,
      this.identityCardDocumentType,
      this.healthCardDocumentType,
      this.medicalOrderDocumentType,
      this.medicalReportDocumentType,
      this.examReportDocumentType,
      this.surgicalOrderDocumentType,
      this.authorizationGuideDocumentType
    ]
  }

  static getDocumentTypeByType(type: PatientDocument) {
    return this.getAllDocuments().find((document) =>
      document.type.includes(type)
    )
  }

  static getSurgicalDocuments() {
    return [
      this.identityCardDocumentType,
      this.healthCardDocumentType,
      this.medicalOrderDocumentType,
      this.medicalReportDocumentType,
      this.surgicalConsentDocumentType,
      this.anestheticConsentDocumentType,
      this.preAnestheticEvaluationDocumentType,
      this.examReportDocumentType,
      this.authorizationGuideDocumentType
    ]
  }

  static getPendenciesDocuments() {
    return {
      [PendencyType.ASSISTANCE]: [
        this.preAnestheticEvaluationDocumentType,
        this.surgicalConsentDocumentType,
        this.anestheticConsentDocumentType,
        this.hemotherapyConsentDocumentType
      ],
      [PendencyType.AUTHORIZATION]: [
        this.identityCardDocumentType,
        this.healthCardDocumentType,
        this.medicalOrderDocumentType,
        this.medicalReportDocumentType,
        this.examReportDocumentType
      ]
    }
  }
}
