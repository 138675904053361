import React from 'react'

import HeadingNew from 'presentation/components/HeadingNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import StatusAnalyzingHealthInsuranceImage from 'presentation/assets/banners/surgical-journey/status-authorized.svg'
import * as S from './styled'

export const SlideSurgicalAuthorization = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <HeadingNew as="h1" size="large" color="primary600">
          Autorização da cirurgia
        </HeadingNew>
        <S.Image
          src={StatusAnalyzingHealthInsuranceImage}
          alt="Pessoas analisando informações do convênio"
        />
        <S.TextBox>
          <SupportTextNew color="neutral700">
            Nesta etapa, a Rede Mater Dei de Saúde faz a verificação de cada
            retorno e as mensagens referentes a autorização ou negativa da sua
            cirurgia, bem como os relatórios complementares são enviados através
            do Meu Mater Dei e por email para você e seu médico.
          </SupportTextNew>
        </S.TextBox>
      </S.Content>
    </S.Wrapper>
  )
}
