import React, { Fragment } from 'react'
import SupportTextNew from 'presentation/components/SupportTextNew'
import AccordionCardNew from 'presentation/components/AccordionCardNew'
import UploadButtonNew from 'presentation/components/UploadButtonNew'
import CardNew, { CardProps } from 'presentation/shared/components/CardNew'
import { ReactComponent as DownloadIcon } from 'presentation/assets/icons/DonwloadIcon.svg'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trash-icon.svg'
import { ReactComponent as ClockCircle } from 'presentation/assets/icons/clock-circle.svg'
import { ReactComponent as CheckCircle } from 'presentation/assets/icons/check-circle.svg'
import { ReactComponent as AlertCircle } from 'presentation/assets/icons/alert-circle.svg'
import * as S from './styles'
import moment from 'moment-timezone'
import { DocumentsList } from 'presentation/layouts/SurgicalOrderDetails/Modals/DocumentsModal'
import { PatientDocument } from 'common/enum/patient-document'

type Props = {
  document: any
  onUploadFile?: (files: any) => void
  onDeleteFile?: (document_id: number, group_id: string) => void
  onDownloadFile?: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList | any
  ) => void
  hideHeaderDivider?: boolean
  expandedDocument?: string
  isExamDocuments?: boolean
  handleChange?: (accordeonLabel: string, isExpanded: boolean) => void
  accept?: string
} & CardProps

const DocumentAccordeon = ({
  isExamDocuments = false,
  document,
  shadow = 'none',
  background = 'transparent',
  border = 'none',
  padding = '8px',
  gap = '8px',
  hideHeaderDivider = false,
  expandedDocument,
  onUploadFile,
  handleChange,
  onDownloadFile,
  onDeleteFile,
  accept
}: Props) => {
  const StatusIcon = () => {
    if (isExamDocuments && !!document?.documents?.length) {
      return <CheckCircle />
    } else
      switch (document.status) {
        case 'APPROVED':
        case 'approved':
          return <CheckCircle />
        case 'ANALYZING':
        case 'analyzing':
          return <ClockCircle />
        case 'DISAPPROVED':
        case 'disapproved':
        case 'NOT_UPLOADED':
        case 'not uploaded':
        default:
          return <AlertCircle />
      }
  }

  const getStatusText = (status: string, name: string) => {
    switch (status) {
      case 'APPROVED':
      case 'approved':
        return 'Validado por ' + name
      case 'ANALYZING':
      case 'analyzing':
        return 'Pendente de validação'
      case 'DISAPPROVED':
      case 'disapproved':
        return 'Invalidado por ' + name
      case 'NOT_UPLOADED':
      case 'not uploaded':
      default:
        return 'Pendente de envio'
    }
  }

  return (
    <AccordionCardNew
      accordionLabel={document.type}
      expanded={expandedDocument === document.type}
      header={DocumentsList[document.type as keyof typeof DocumentsList]}
      headerIcon={<StatusIcon />}
      shadow={shadow}
      background={background}
      border={border}
      padding={padding}
      gap={gap}
      hideHeaderDivider={hideHeaderDivider}
      handleChange={handleChange}
    >
      {document?.documents?.map((file: any, index: any) => (
        <Fragment key={index}>
          <CardNew
            shadow="none"
            background="neutral25"
            border="neutral100"
            padding="12px 16px"
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <SupportTextNew color="neutral800">{file.name}</SupportTextNew>
            <S.ActionIcons>
              {onDownloadFile && (
                <DownloadIcon
                  className="download-icon"
                  onClick={() =>
                    onDownloadFile(
                      file.document_id,
                      file.group_id,
                      document.type
                    )
                  }
                />
              )}
              {['not uploaded', 'analyzing'].includes(file.status) &&
                onDeleteFile && (
                  <TrashIcon
                    className="thresh-icon"
                    onClick={() =>
                      onDeleteFile(file.document_id, file.group_id)
                    }
                  />
                )}
            </S.ActionIcons>
          </CardNew>

          {!isExamDocuments && (
            <S.DocumentDetails>
              <div>
                <SupportTextNew color="neutral600" size="xxsmall">
                  Inclusão
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {['not uploaded', 'analyzing'].includes(file.status)
                    ? 'Rascunho salvo em '
                    : 'Incluido em '}
                  {moment.utc(file.sended_at).format('DD [de] MMM[. de] YYYY ')}
                  {file.created_by?.name ? `por ${file.created_by?.name}` : ''}
                </SupportTextNew>
              </div>
              <div>
                <SupportTextNew color="neutral600" size="xxsmall">
                  Validação
                </SupportTextNew>
                <SupportTextNew color="neutral900" size="xsmall">
                  {getStatusText(file.status, file.status_updated_by?.name)}
                </SupportTextNew>
              </div>
            </S.DocumentDetails>
          )}
        </Fragment>
      ))}

      {((!isExamDocuments && !document?.documents?.length) ||
        (isExamDocuments && !document?.documents?.length) ||
        (isExamDocuments &&
          !!document?.documents?.length &&
          [PatientDocument.EXAM_MEDICAL_ORDER].includes(document.type))) && (
        <>
          <UploadButtonNew
            onInput={onUploadFile}
            name={document.type}
            outlined
            multiple
            accept={accept}
          >
            Incluir documento
          </UploadButtonNew>
        </>
      )}
    </AccordionCardNew>
  )
}

export default DocumentAccordeon
