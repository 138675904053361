import React, { useEffect, useState } from 'react'

import BottomMenu from 'presentation/components/BottomMenu'
import { Container } from 'presentation/components/Container'
import SelectField from 'presentation/shared/components/SelectField'
import ServiceOverview, {
  ServiceOverviewProps
} from 'presentation/shared/components/ServiceOverview'
import { ScheduledCardProps } from 'presentation/shared/components/ScheduledCard'
import { handleScheduleStatus } from 'presentation/utils/translators/scheduleStatus'
import moment from 'moment-timezone'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderNew from 'presentation/components/HeaderNew'
import {
  SurgicalOrderStatus,
  TimelineStatus
} from 'presentation/shared/components/Timeline/status'

type Props = {
  surgicalOrders: ServiceOverviewProps[]
  schedules: ScheduledCardProps[]
}

export default function AttendancesLayout({
  surgicalOrders,
  schedules
}: Props) {
  const history = useHistory()
  const [type, setType] = useState<string>('surgery')
  const { state } = useLocation<{ type: string }>()

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value)
  }

  useEffect(() => {
    if (state) {
      setType(state.type)
    }
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Meus atendimentos"
        actualPageOnClick={() => history.push('/home')}
      />
      <Container>
        <SelectField
          label="Filtrar atendimentos"
          items={selectItems}
          onChange={handleChange}
          value={type}
        />
        {type === 'surgery' &&
          surgicalOrders.map((surgicalOrder) => {
            return (
              <ServiceOverview
                {...surgicalOrder}
                date={
                  surgicalOrder.date
                    ? moment.utc(surgicalOrder.date).format('DD/MM/YYYY')
                    : '-'
                }
                key={surgicalOrder.surgical_order_id}
                title={
                  surgicalOrder?.title + ` ${surgicalOrder.surgical_order_id} `
                }
                type="Cirurgia"
                style={{ marginTop: '24px' }}
                onClick={() =>
                  history.push('/pedido-cirurgico/detalhes', {
                    surgical_order_id: surgicalOrder.surgical_order_id
                  })
                }
              />
            )
          })}

        {type === 'appointments' &&
          schedules.map((schedule) => {
            return (
              <ServiceOverview
                title={schedule.title}
                date={schedule.date}
                unit={schedule.unit}
                color={schedule.color}
                type={schedule.type}
                status={
                  schedule.status && handleScheduleStatus[schedule.status]
                }
                key={schedule.date}
                style={{ marginTop: '24px' }}
              />
            )
          })}

        {type === 'surgery-cancellation-requested' &&
          surgicalOrders
            .filter((surgicalOrder) => {
              if (
                TimelineStatus.find((timelineStatus) =>
                  timelineStatus.status.includes(
                    SurgicalOrderStatus.REQUESTED_CANCELLATION
                  )
                )?.label === surgicalOrder.status
              ) {
                return surgicalOrder
              }
            })
            .map((surgicalOrder) => {
              return (
                <ServiceOverview
                  {...surgicalOrder}
                  date={
                    surgicalOrder.date
                      ? moment.utc(surgicalOrder.date).format('DD/MM/YYYY')
                      : '-'
                  }
                  key={surgicalOrder.surgical_order_id}
                  title={surgicalOrder?.title}
                  type="Cirurgia"
                  style={{ marginTop: '24px' }}
                  onClick={() =>
                    history.push('/pedido-cirurgico/detalhes', {
                      surgical_order_id: surgicalOrder.surgical_order_id
                    })
                  }
                />
              )
            })}
      </Container>
      <BottomMenu />
    </>
  )
}

const selectItems = [
  { label: 'Cirurgia', value: 'surgery' },
  { label: 'Ambulatório', value: 'appointments' },
  {
    label: 'Cirurgia - Cancelamento solicitado',
    value: 'surgery-cancellation-requested'
  }
]
