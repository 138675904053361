import React from 'react'
import * as S from './styles'

export type StepDotsProps = {
  active: number
  total: number
  clickEvent?: (number: number) => void
}

export default function StepDotsNew({
  active,
  total,
  clickEvent
}: StepDotsProps) {
  const onClick = (number: number) => {
    !!clickEvent && clickEvent(number)
  }

  return (
    <S.Wrapper role="navigation">
      {[...Array(total)].map((number, idx) => (
        <React.Fragment key={idx}>
          <S.Dot
            margin={idx > active}
            onClick={() => onClick(idx)}
            active={idx === active}
          />
          {idx < active && <S.HorizontalLine />}
        </React.Fragment>
      ))}
    </S.Wrapper>
  )
}
