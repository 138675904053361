import { AmbulatoryContact } from 'domain/usecases/ambulatory/ambulatory-contact'
import { GetAmbulatorySchedulePanelOrganized } from 'domain/usecases/ambulatory/get-ambulatory-panel-organized'
import { SyncAmbulatoryToken } from 'domain/usecases/ambulatory/sync-ambulatory-token'
import { RepositoryErrors } from 'repository/errors/repository-errors'
import {
  ambulatoryContactMutation,
  SyncAmbulatoryTokenMutation
} from 'repository/graphql/mutations'
import { getAmbulatorySchedulePanelOrganizedQuery } from 'repository/graphql/queries'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'
import { AmbulatoryRepository as IAmbulatoryRepository } from 'repository/interfaces/ambulatory-repository'
import { ApiStatusCode } from 'service/protocols/api/api-client'
import { IApiRepository } from 'service/protocols/api/api-repository'

export class AmbulatoryRepository implements IAmbulatoryRepository {
  constructor(private readonly apiRepository: IApiRepository) {}

  async ambulatoryContact(
    params: AmbulatoryContact.Params,
    patient_id: number,
    ambulatory_schedule_id: number
  ): Promise<AmbulatoryContact.Model> {
    const apiRepository = this.apiRepository as IApiRepository<{
      data: AmbulatoryContact.Model
    }>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: ambulatoryContactMutation.query,
        variables: {
          ...makeGraphQLVariable(params),
          ...makeGraphQLVariable(patient_id, 'patient_id'),
          ...makeGraphQLVariable(
            ambulatory_schedule_id,
            'ambulatory_schedule_id'
          )
        }
      },
      query: ambulatoryContactMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body?.data as AmbulatoryContact.Model
    }
  }

  async syncAmbulatoryToken({
    ambulatory_schedule_id,
    token
  }: SyncAmbulatoryToken.Params): Promise<SyncAmbulatoryToken.Model> {
    const apiRepository = this.apiRepository as IApiRepository<{
      data: SyncAmbulatoryToken.Model
    }>

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: SyncAmbulatoryTokenMutation.query,
        variables: {
          ...makeGraphQLVariable(token, 'token'),
          ...makeGraphQLVariable(
            ambulatory_schedule_id,
            'ambulatory_schedule_id'
          )
        }
      },
      query: SyncAmbulatoryTokenMutation.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body?.data as SyncAmbulatoryToken.Model
    }
  }

  async getAmbulatorySchedulePanelOrganized(
    fields: GetAmbulatorySchedulePanelOrganized.Params
  ): Promise<GetAmbulatorySchedulePanelOrganized.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<GetAmbulatorySchedulePanelOrganized.Model>

    const query = getAmbulatorySchedulePanelOrganizedQuery(fields)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as GetAmbulatorySchedulePanelOrganized.Model
    }
  }
}
