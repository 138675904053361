import { NotificationConfig } from './notification-config-model'
import { Patient } from './patient-model'

export class Accompanying {
  private _accompanying_id: number
  private _name: string
  private _socialName?: string
  private _lastName: string
  private _relationship?: string
  private _email: string
  private _phone: string
  private _sendEmail: boolean
  private _sendSMS: boolean
  private _patient: Patient
  private _createdAt?: Date
  private _isMain?: boolean

  constructor(
    accompanying_id: number,
    name: string,
    lastName: string,
    email: string,
    phone: string,
    notificationConfig: NotificationConfig,
    patient: Patient,
    isMain?: boolean,
    socialName?: string
  ) {
    this._accompanying_id = accompanying_id
    this._name = name
    this._socialName = socialName
    this._lastName = lastName
    this._email = email
    this._phone = phone
    this._sendEmail = notificationConfig.sendEmail
    this._sendSMS = notificationConfig.sendSMS
    this._patient = patient
    this._isMain = isMain
  }

  public get accompanying_id(): number {
    return this._accompanying_id
  }

  public set accompanying_id(accompanying_id: number) {
    this._accompanying_id = accompanying_id
  }

  public get name(): string {
    return this._name
  }

  public set name(name: string) {
    this._name = name
  }

  public get socialName(): string | undefined {
    return this._socialName
  }

  public set socialName(socialName: string | undefined) {
    this._socialName = socialName
  }

  public get lastName(): string {
    return this._lastName
  }

  public set lastName(lastName: string) {
    this._lastName = lastName
  }

  public get relationship(): string | undefined {
    return this._relationship
  }

  public set relationship(relationship: string | undefined) {
    this._relationship = relationship
  }

  public get email(): string {
    return this._email
  }

  public set email(email: string) {
    this._email = email
  }

  public get phone(): string {
    return this._phone
  }

  public set phone(phone: string) {
    this._phone = phone
  }

  public get sendEmail(): boolean {
    return this._sendEmail
  }

  public set sendEmail(sendEmail: boolean) {
    this._sendEmail = sendEmail
  }

  public get sendSMS(): boolean {
    return this._sendSMS
  }

  public set sendSMS(sendSMS: boolean) {
    this._sendSMS = sendSMS
  }

  public get patient(): Patient {
    return this._patient
  }

  public set patient(patient: Patient) {
    this._patient = patient
  }

  public get createdAt(): Date | undefined {
    return this._createdAt
  }

  public set createdAt(createdAt: Date | undefined) {
    this._createdAt = createdAt
  }

  public get isMain(): boolean | undefined {
    return this._isMain
  }

  public set isMain(isMain: boolean | undefined) {
    this._isMain = isMain
  }

  public toJSON() {
    return {
      accompanying_id: this.accompanying_id,
      name: this.name,
      socialName: this.socialName,
      lastName: this.lastName,
      relationship: this.relationship,
      email: this.email,
      phone: this.phone,
      sendEmail: this.sendEmail,
      sendSMS: this.sendSMS,
      patient: this.patient,
      createdAt: this.createdAt,
      isMain: this.isMain
    }
  }
}
