import { AmbulatoryContact } from 'domain/usecases/ambulatory/ambulatory-contact'
import { GetAmbulatorySchedulePanelOrganized } from 'domain/usecases/ambulatory/get-ambulatory-panel-organized'
import { SyncAmbulatoryToken } from 'domain/usecases/ambulatory/sync-ambulatory-token'
import { makeAutoObservable } from 'mobx'

type Services = {
  ambulatoryContact: AmbulatoryContact
  syncAmbulatoryToken: SyncAmbulatoryToken
  getAmbulatorySchedulePanelOrganized: GetAmbulatorySchedulePanelOrganized
}

export class AmbulatoryService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async ambulatoryContact(
    params: AmbulatoryContact.Params,
    patient_id: number,
    ambulatory_schedule_id: number
  ): Promise<AmbulatoryContact.Model> {
    return this.services.ambulatoryContact.contact(
      params,
      patient_id,
      ambulatory_schedule_id
    )
  }

  async syncAmbulatoryToken(
    params: SyncAmbulatoryToken.Params
  ): Promise<SyncAmbulatoryToken.Model> {
    return this.services.syncAmbulatoryToken.sync(params)
  }

  async getAmbulatorySchedulePanelOrganized(
    params: GetAmbulatorySchedulePanelOrganized.Params
  ): Promise<GetAmbulatorySchedulePanelOrganized.Model> {
    return this.services.getAmbulatorySchedulePanelOrganized.get(params)
  }
}

export default AmbulatoryService
