import { FinishSurgicalSchedule } from 'domain/usecases/finish-surgical-schedule/finish-surgical-schedule'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteFinishSurgicalSchedule implements FinishSurgicalSchedule {
  constructor(
    private readonly surgicalScheduleRepository: SurgicalScheduleRepository
  ) {}

  add(
    params: FinishSurgicalSchedule.Params
  ): Promise<FinishSurgicalSchedule.Model> {
    return this.surgicalScheduleRepository.finishSurgicalSchedule(params)
  }
}
