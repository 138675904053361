import { EmergencyRoomAttendanceModel } from 'domain/entities/emergency-room-attendance.model'
import React, { useEffect, useState } from 'react'
import RenewEmergencyTokenModal from '../../Forms/RenewEmergencyToken'
import ExpireRenewModal from '../../Forms/RenewEmergencyToken/ExpireRenewModal'
import SuccessRenewModal from '../../Forms/RenewEmergencyToken/SuccessRenewModal'

export type EmergencyRenewModalsProps = {
  renewToken: () => void
  showRenewToken: boolean
  canRenew: boolean
  emergencyRoomAttendance?: EmergencyRoomAttendanceModel
}

export function EmergencyRenewModals({
  canRenew,
  renewToken,
  showRenewToken,
  emergencyRoomAttendance
}: EmergencyRenewModalsProps) {
  const [renew, setRenew] = useState<boolean>(false)
  const [successRenew, setSuccessRenew] = useState<boolean>(false)
  const [expireRenew, setExpireRenew] = useState<boolean>(false)

  useEffect(() => {
    if (showRenewToken && canRenew) {
      setRenew(true)
    }
    if (!showRenewToken && renew) {
      setRenew(false)
    }
  }, [showRenewToken])

  const onRenewToken = () => {
    renewToken()
    setRenew(false)
    setSuccessRenew(true)
  }

  const onCancelRenew = () => {
    setRenew(false)
    setExpireRenew(true)
  }

  const arrivalForecastTime = new Date(
    emergencyRoomAttendance?.emergencyRoomToken?.arrivalForecast || ''
  ).getTime()

  const diff = arrivalForecastTime - new Date().getTime()

  const timeLeftInMinutes = Math.trunc(diff / 60000 || 0)

  return (
    <>
      <RenewEmergencyTokenModal
        onClose={() => setRenew(false)}
        renewToken={onRenewToken}
        onCancel={onCancelRenew}
        showRenew={renew}
      />
      <SuccessRenewModal
        show={successRenew}
        onClose={() => setSuccessRenew(false)}
      />
      <ExpireRenewModal
        show={expireRenew}
        onClose={() => setExpireRenew(false)}
        timeLeft={timeLeftInMinutes}
      />
    </>
  )
}
