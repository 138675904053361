import { CreatePatientExamAppointments } from 'domain/usecases/patient/create-patient-exam-appointment'
import { ExamRepository } from 'repository/interfaces/exam-repository'

export class RemoteCreatePatientExamAppointments
  implements CreatePatientExamAppointments
{
  constructor(private readonly examRepository: ExamRepository) {}
  async create(
    params: CreatePatientExamAppointments.Params
  ): Promise<CreatePatientExamAppointments.Model> {
    return this.examRepository.createPatientExamAppointment(params)
  }
}

export type GetExamAppointmentsModel = CreatePatientExamAppointments.Model
