import React from 'react'
import * as S from './styles'
import moment from 'moment-timezone'
import { PatientHealthInsurance } from 'domain/entities/patient-model'

export type Props = {
  healthInsurance?: PatientHealthInsurance
  particular?: boolean
  showOnly?: boolean
  title?: string
  description?: string
  openConfirmationDeleteMenu?: (healthInsuranceId: number) => void
  style?: any
  onClick?: () => void
  disabled?: boolean
}

export default function HealthInsuranceCardNew({
  style,
  healthInsurance,
  particular = false,
  title,
  description,
  disabled = false,
  onClick
}: Props) {
  const planservHealhInsuranceCode = 448

  return (
    <S.HealthInsuranceCardWrapper
      style={style}
      onClick={onClick}
      className={disabled ? '-disabled' : ''}
    >
      <S.CardHeader>
        {particular ? (
          <>
            <span className="health-insurance-name">Particular</span>
            {title && <p className="health-insurance-title">{title}</p>}
          </>
        ) : (
          <>
            <h3 className="health-insurance-name">
              {healthInsurance?.healthInsuranceCode === 396
                ? healthInsurance?.healthInsuranceName.toUpperCase()
                : healthInsurance?.healthInsuranceName.toLowerCase()}
            </h3>
          </>
        )}
      </S.CardHeader>
      <S.CardBody>
        {particular ? (
          description && <p className="description">{description}</p>
        ) : (
          <>
            <div>
              <strong>Plano:</strong>
              <p>{healthInsurance?.healthPlanName || '-'}</p>
            </div>
            <div>
              <strong>Carteirinha:</strong>
              <p>{healthInsurance?.healthCard || '-'}</p>
            </div>
            <div>
              <strong>Validade:</strong>{' '}
              <p>
                {' '}
                {healthInsurance?.validThru
                  ? moment(healthInsurance?.validThru).format('DD/MM/YYYY')
                  : '-'}
              </p>
            </div>
          </>
        )}
      </S.CardBody>
      {healthInsurance?.healthInsuranceCode === planservHealhInsuranceCode && (
        <S.PlanservInfo>
          Agendamentos pelo PLANSERV são exclusivos por telefone:{' '}
          <a
            href="tel:3135122500"
            target="_blank"
            rel="noreferrer"
            title="Ligue para (75) 2102-8050  / (75)3616-8050"
          >
            Ligue para (75) 2102-8050 / (75)3616-8050
          </a>
        </S.PlanservInfo>
      )}
      {healthInsurance?.healthInsuranceCode !== planservHealhInsuranceCode && (
        <S.CardFooter>
          {!particular && (
            <>
              <strong>Agendar por este convênio {'>'} </strong>
            </>
          )}
          {particular && (
            <>
              <strong>Agendar particular {'>'} </strong>
            </>
          )}
        </S.CardFooter>
      )}
    </S.HealthInsuranceCardWrapper>
  )
}
