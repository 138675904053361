import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteUpdatePatientHealthInsuranceInMv
  implements UpdatePatientHealthInsurance
{
  constructor(private readonly patientRepository: PatientRepository) {}
  update(
    params: UpdatePatientHealthInsurance.Params
  ): Promise<UpdatePatientHealthInsurance.Model> {
    return this.patientRepository.updateHealthInsuranceInMv(params)
  }
}

export type UpdatePatientHealthInsuranceModel =
  UpdatePatientHealthInsurance.Model
