import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { DoctorRegisterStepRepository } from 'repository/repositories/doctor-register-step/doctor-register-step-repository'
import { RemoteUpdateDoctorRegisterStep } from 'service/usecases/update-doctor-register-step/remote-update-doctor-register-step'
import { DoctorRegisterStepService } from 'presentation/contexts/services/doctor-register-step-service'

export default function makeDoctorRegisterStepService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const doctorRegisterStepRepository = new DoctorRegisterStepRepository(
    apiRepository
  )

  const updateDoctorRegisterStep = new RemoteUpdateDoctorRegisterStep(
    doctorRegisterStepRepository
  )

  return new DoctorRegisterStepService({ updateDoctorRegisterStep })
}
