import * as yup from 'yup'

export const schedulingCancelValidationSchema = yup.object().shape({
  validate_reason_other: yup.boolean().nullable(),
  cd_mot_cancel: yup.number().required(),
  text: yup.string().when('validate_reason_other', {
    is: true,
    then: yup.string().required('Campo obrigatório')
  })
})
