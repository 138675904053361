import { VueMotionCheckForExams } from 'domain/usecases/vue-motion/vue-motion-check-for-exams'
import { VueMotionRepository } from 'repository/interfaces/vue-motion-repository'

export class RemoteVueMotionCheckForExams implements VueMotionCheckForExams {
  constructor(private readonly vueMotionRepository: VueMotionRepository) {}

  load(
    params: VueMotionCheckForExams.Params
  ): Promise<VueMotionCheckForExams.Model> {
    return this.vueMotionRepository.vueMotionCheckForExams(params)
  }
}
