import styled from 'styled-components'
import theme from 'presentation/styles/theme'

export const Wrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  height: 100%;
`
export const EmergencyRoomOption = styled.div`
  padding: 10px 5px 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`
export const Icon = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  margin-right: 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.moreLightGreen};
`
export const Title = styled.div`
  margin-left: 10px;
`
export const DividingLine = styled.hr`
  width: 100%;
  border: 0;
  height: 1px;
  background: ${theme.colors.lightestGray};
`
export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubTitle = styled.span`
  font-size: 12px;
  color: ${theme.ds.colors.neutral800};
  margin-left: 10px;
`
