import { LoadSurgeryCenterCalendar } from 'domain/usecases/load-surgery-center-calendar/load-surgery-center-calendar'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadSurgeryCenterCalendar
  implements LoadSurgeryCenterCalendar
{
  constructor(
    private readonly surgicalScheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(
    params: LoadSurgeryCenterCalendar.Params
  ): Promise<LoadSurgeryCenterCalendar.Model> {
    return this.surgicalScheduleRepository.loadSurgeryCenterCalendar(params)
  }
}

export type LoadSurgeryCenterCalendarModel = LoadSurgeryCenterCalendar.Model
