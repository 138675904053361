import styled from 'styled-components'

import { ServiceOverviewProps } from '.'

export const Wrapper = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius.large};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
  box-shadow: ${({ theme }) => theme.ds.shadows.satin};
  margin-bottom: 8px;

  h5 {
    font-weight: ${({ theme }) => theme.font.black};
    font-size: ${({ theme }) => theme.font.sizes.small};
    line-height: ${({ theme }) => theme.font.sizes.small};
    color: #6f6f6f;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const Header = styled.header<Pick<ServiceOverviewProps, 'color'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: ${({ color }) => (color ? color : '#A0A49F')};
  letter-spacing: -0.28px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: ${({ theme }) => theme.font.semiBold};
  border-top-left-radius: ${({ theme }) => theme.border.radius.large};
  border-top-right-radius: ${({ theme }) => theme.border.radius.large};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  height: 7rem;
`

export const AdditionalInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ServiceType = styled.h6`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ theme }) => theme.font.normal};
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
`
export const ServiceTitle = styled.span`
  color: #4a4a4a;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  div {
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 400;
    font-size: 14px;

    span {
      text-align: left !important;
    }

    svg {
      margin-right: 5.5px;
    }
  }
`

export const LocationContainer = styled.section``

export const Location = styled.div`
  display: flex;
  align-items: stretch;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  text-align: left;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  span {
    gap: 5px;
    color: ${({ theme }) => theme.colors.gray};
    margin-left: 8px;
    text-align: left !important;
    font-weight: 500 !important;
  }

  & + & {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`

export const ParticipantsContainer = styled.section``

export const ParticipantTitle = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
`

export const Patient = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    line-height: 21px;
    letter-spacing: -0.28px;

    strong {
      color: ${({ theme }) => theme.colors.primary};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      font-weight: ${({ theme }) => theme.font.normal};
    }

    span {
      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`

export const Doctor = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    line-height: 21px;
    letter-spacing: -0.28px;

    img {
      margin-right: 8px;
    }

    strong {
      color: ${({ theme }) => theme.colors.primary};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      font-weight: ${({ theme }) => theme.font.normal};
    }

    span {
      font-weight: ${({ theme }) => theme.font.semiBold};
      font-size: ${({ theme }) => theme.font.sizes.xsmall};
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`
export const Order = styled.div`
  margin-top: 30px;
`

export const OrderId = styled.label`
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
`

export const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
`

export const TimeLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: ${({ theme }) => theme.font.sizes.xxsmall};
  text-align: left;

  span {
    strong {
      color: ${({ theme }) => theme.colors.primary};
      margin-right: 4px;
    }

    color: ${({ theme }) => theme.colors.gray};
    margin-left: 8px;
    text-align: left !important;
    font-weight: 500 !important;
  }

  & + & {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`
