import styled from 'styled-components'

export const PatientInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`

export const PatientInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px 20px 20px;
  div:first-child {
    margin-top: 14px;
    padding: 10px 0px 10px;
  }
`

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px 20px 20px;
  flex: 1;
  strong {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const PersonalDataHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;
`

export const PatientAccompanyingList = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1 1 0;

  min-height: 300px;

  margin-top: 20px;
  margin-bottom: 20px;

  overflow-y: scroll;
  flex-wrap: nowrap;
  gap: 20px;
`
