import { GetSurgicalOrderTimeline } from 'domain/usecases/surgical-order/get-surgical-order-timeline'
import { SurgicalOrderRepository } from 'repository/interfaces/surgical-order-repository'

export class RemoteGetSurgicalOrderTimeline
  implements GetSurgicalOrderTimeline
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  async load(
    params: GetSurgicalOrderTimeline.Params
  ): Promise<GetSurgicalOrderTimeline.Model> {
    return this.surgicalOrderRepository.getSurgicalOrderTimeline(params)
  }
}

export type RemoteGetSurgicalOrderTimelineModel = GetSurgicalOrderTimeline.Model
