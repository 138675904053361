import { SendReasonNotSurgery } from 'domain/usecases/surgical-order/send-reason-not-surgery'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteSendReasonNotSurgery implements SendReasonNotSurgery {
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  send(
    params: SendReasonNotSurgery.Params
  ): Promise<SendReasonNotSurgery.Model> {
    return this.surgicalOrderRepository.sendReasonNotSurgery(params)
  }
}
