import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import EmergencyRoomService from 'presentation/contexts/services/emergency-room-service'
import { ApiRepository } from 'repository/api-repository'
import { EmergencyRoomRepository } from 'repository/repositories/emergency-room-atendance/emergency-room-repository'
import { RemoteCreateEmergencyRoomInMv } from 'service/usecases/create-emergency-room-in-mv/remote-create-emergency-room-in-mv'
import { RemoteGetEmergencyRoomByToken } from 'service/usecases/get-emergency-room-by-token/remote-get-emergency-room-by-token'
import { RemoteLoadEmergencyRoomByHospital } from 'service/usecases/load-emergency-room-by-hospital/remote-load-emergency-room-by-hospital'

export default function makeEmergencyRoomService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const emergencyRoomRepository = new EmergencyRoomRepository(apiRepository)

  const loadEmergencyRoomByHospital = new RemoteLoadEmergencyRoomByHospital(
    emergencyRoomRepository
  )

  const getEmergencyRoomByToken = new RemoteGetEmergencyRoomByToken(
    emergencyRoomRepository
  )

  const createEmergencyRoomInMV = new RemoteCreateEmergencyRoomInMv(
    emergencyRoomRepository
  )

  return new EmergencyRoomService({
    loadEmergencyRoomByHospital,
    getEmergencyRoomByToken,
    createEmergencyRoomInMV
  })
}
