import { ChangeDependent } from 'domain/usecases/dependents/change-denpendent'
import { DependentsRepository } from 'repository/interfaces/dependents-repositoriy'

export class RemoteChangeDependent implements ChangeDependent {
  constructor(private readonly dependentsRepository: DependentsRepository) {}

  update(params: ChangeDependent.Params): Promise<ChangeDependent.Model> {
    return this.dependentsRepository.changeDependent(params)
  }
}

export type ChangePasswordModel = ChangeDependent.Model
