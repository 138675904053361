import styled, { css, DefaultTheme } from 'styled-components'
import { lighten } from 'polished'

import { TextAreaProps } from '.'

type PropsTextArea = Pick<
  TextAreaProps,
  | 'bgColor'
  | 'outline'
  | 'outlineColor'
  | 'labelColor'
  | 'textColor'
  | 'fontSize'
>

type WrapperProps = Pick<TextAreaProps, 'disabled'> & { error?: boolean }

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const Input = styled.textarea<PropsTextArea>`
  ${({ theme, bgColor, outline, outlineColor, textColor, fontSize }) => css`
    color: ${theme.ds.colors[textColor!]};
    font-family: ${theme.ds.typography.fontFamily};
    font-size: ${theme.ds.typography.size[fontSize!]};
    line-height: ${theme.ds.typography.lineHeight.medium};
    background-color: ${theme.ds.colors[bgColor!]};
    padding: 8px 16px;
    border: 1px solid
      ${outline ? theme.ds.colors[outlineColor!] : theme.ds.colors.neutral300};
    border-radius: ${theme.ds.typography.border.radius.small};
    outline: none;
    resize: none;
    width: 100%;

    &::placeholder {
      color: ${theme.ds.colors.neutral500};
      font-weight: ${theme.ds.typography.weight.normal};
      opacity: 1;
    }

    &:focus {
      border-color: ${theme.ds.colors.primary300};
    }
  `}
`

export const Label = styled.label<PropsTextArea>`
  display: block;
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  color: ${({ theme, labelColor }) => theme.ds.colors[labelColor!]};
  cursor: pointer;
  margin-bottom: 8px;
`

export const Icon = styled.div<PropsTextArea>`
  display: flex;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 8px;
  cursor: pointer;
  & > svg {
    height: 54px;
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`

const wrapperModifiers = {
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.colors.lightRed};
      border-width: 1px;
      border-style: solid;
    }
    ${Icon} {
      color: ${theme.colors.lightRed};
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Label},
    ${Input},
    ${Icon} {
      &::placeholder {
        color: currentColor;
      }
    }
    ${Input} {
      background-color: ${lighten(0.3, theme.colors.lightGray)};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  color: ${({ theme }) => theme.ds.colors.danger300};
  cursor: pointer;
`
