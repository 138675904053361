import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`

export const ImageContainer = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-weight: 500;

    :first-child {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
