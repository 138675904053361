import React, { useContext, useEffect, useState } from 'react'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'
import ButtonNew from 'presentation/components/ButtonNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import * as S from './styles'
import {
  AppointmentExamItemType,
  AppointmentExamType,
  CancelAppointmentExamType
} from '../utils'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar-icon.svg'
import { ReactComponent as GrayPinIcon } from 'presentation/assets/icons/gray-pin-icon.svg'
import { ReactComponent as StethoscopeIconOutline } from 'presentation/assets/icons/stethoscope-outline.svg'
import { ReactComponent as SyringeIcon } from 'presentation/assets/icons/syringe-icon.svg'
import { ReactComponent as CanceledExam } from 'presentation/assets/banners/canceled-exam.svg'
import RadioButtonNew from 'presentation/shared/components/RadioButtonNew'
import CheckboxNew from 'presentation/shared/components/CheckboxNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useServices } from 'presentation/hooks/use-services'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'

type ScheduledExamProps = {
  setShowCancelExam: (open: boolean) => void
  showCancelExam: boolean
  selectedExamToCancel: AppointmentExamType
  setIsLoading: (value: boolean) => void
  slideToReschedule: () => void
}

type CancelExamCardProps = {
  selectedExamToCancel: AppointmentExamType
}

type ReasonCancelType = {
  reason_cancel_id: number
  description: string
}

type AppointmentsIds = {
  appointments_ids: {
    appointment_id: number
    is_main: boolean
  }[]
}

const CancelExamSchedule = ({
  setShowCancelExam,
  showCancelExam,
  selectedExamToCancel,
  slideToReschedule,
  setIsLoading
}: WithLoadingProps & ScheduledExamProps) => {
  const [cancelConfirmationScreen, setShowCancelConfirmationScreen] =
    useState(false)

  const examService = useServices().exam

  const [reasonsCancel, setReasonsCancel] = useState() as any

  const { dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  async function getReasonCancel() {
    try {
      setIsLoading(true)
      const response = await examService.getExamReasonCancel()
      setReasonsCancel(response)
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar lista de motivos de cancelamento')
    } finally {
      setIsLoading(false)
    }
  }

  async function cancelExamSchedule(cancelExamScheduleObj: any) {
    try {
      setIsLoading(true)
      const response = await examService.cancelExamSchedule(
        cancelExamScheduleObj
      )

      if (response.statusCode !== 200) {
        return toast.error(
          'Ocorreu um erro ao cancelar o agendamento de exame.'
        )
      }

      toast.success('Agendamento cancelado com sucesso')
      setShowCancelConfirmationScreen(true)
    } catch (error) {
      toast.error('Ocorreu um erro ao cancelar o agendamento de exame')
    } finally {
      setIsLoading(false)
    }
  }

  const [patientInfo, setPatientInfo] = useState({}) as any

  useEffect(() => {
    if (showCancelExam) {
      getReasonCancel()

      loadPatientData()
    }
  }, [showCancelExam])

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      cancelReasonId: '',
      cancelReasonDescription: '',
      textReason: '',
      examsToCancel: []
    },
    onSubmit: async () => {
      if (!formik.values.cancelReasonId) {
        toast.error('Selecione um motivo de cancelamento')
        return
      }

      const appointments_ids: AppointmentsIds = {
        appointments_ids: formik.values.examsToCancel.map(
          (appointment: AppointmentExamItemType) => ({
            appointment_id: appointment.id,
            is_main: false
          })
        )
      }

      const payload: CancelAppointmentExamType = {
        ...appointments_ids,
        exams: [],
        patient: {
          patient_id: selectedExamToCancel.patient_id,
          email: patientInfo!.email,
          name: patientInfo!.name
        },
        cancel_reason_id: Number(formik.values.cancelReasonId),
        description:
          formik.values.cancelReasonDescription !== 'OUTRO'
            ? ''
            : formik.values.textReason
      }

      const appointments: AppointmentExamItemType[] =
        formik.values.examsToCancel
      const mainId = selectedExamToCancel.id
      for (const appointment of appointments) {
        payload.exams.push({
          name: appointment.exam.name,
          date: appointment.appointment_date,
          is_sedation: appointment.use_sedation || false,
          doctor_name: appointment.doctor.name,
          unit: {
            address: appointment.unit.address,
            name: appointment.unit.name
          }
        })
        if (appointments.length === 1 && mainId === appointment.id) {
          const [{ id }] = selectedExamToCancel.items.filter(
            (item) => item.id !== appointment.id
          )

          payload.appointments_ids[0].is_main = true
          payload.appointments_ids.push({
            appointment_id: id,
            is_main: false
          })
        }
      }

      if (!payload.exams.length) {
        payload.exams[0] = {
          name: selectedExamToCancel.items[0].exam.name,
          date: selectedExamToCancel.items[0].appointment_date,
          is_sedation: selectedExamToCancel.use_sedation || false,
          doctor_name: selectedExamToCancel.items[0]?.doctor?.name,
          unit: {
            address: selectedExamToCancel.items[0].unit.address,
            name: selectedExamToCancel.items[0].unit.name
          }
        }
        payload.appointments_ids[0] = {
          appointment_id: selectedExamToCancel.id,
          is_main: false
        }
      }

      const checkedExamToCancel = selectedExamToCancel.items.filter((item) => {
        return appointments.find((item2) => item2.id === item.id)
      })

      const examToCancelResult = await loadExamData(
        checkedExamToCancel.map((item) => item.exam.item_id)
      )

      dispatch({
        type: 'UPDATE_SELECTED_EXAMS',
        payload: examToCancelResult!.data
      })

      cancelExamSchedule(payload)
    }
  })

  function handleRedirect() {
    setShowCancelExam(false)
    setShowCancelConfirmationScreen(false)
    window.location.reload()
  }

  function handleSelectReason(reason: ReasonCancelType) {
    formik.setFieldValue('cancelReasonDescription', reason.description)
    formik.setFieldValue('cancelReasonId', reason.reason_cancel_id)
  }

  ///////////// Preencher o estado de Paciente:
  const patientService = useServices().patient

  const loadPatientData = async () => {
    try {
      const response = await patientService.loadPatientInfo([
        'patient_id',
        'name',
        'email',
        'user_id',
        'birthday',
        'gender'
      ])

      setPatientInfo(response)
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar os dados')
    }
  }

  async function updatePatient() {
    await loadPatientData()

    const patientData = {
      patient_id: patientInfo?.patient_id,
      user_id: patientInfo?.user_id,
      email: patientInfo?.email,
      name: patientInfo?.name,
      age:
        Number(
          getAgeByBirthdayDate(
            moment(patientInfo?.birthday).format('DD/MM/YYYY')
          )
        ) || undefined,
      gender: patientInfo?.gender
    }

    dispatch({
      type: 'UPDATE_PATIENT_DATA',
      payload: patientData
    })

    return patientData
  }

  ///////////// Preencher o estado de Exame:

  const loadExamData = async (item_id: number[] = []) => {
    try {
      setIsLoading(true)
      const response = await examService.getExamByItem({
        item_ids:
          item_id.length > 0
            ? item_id
            : [selectedExamToCancel.items[0].exam.item_id]
      })

      return response
    } catch (error) {
      toast.error('Ocorreu um erro ao carregar os dados')
    } finally {
      setIsLoading(false)
    }
  }

  async function updateExam() {
    dispatch({
      type: 'SELECT_HEALTH_INSURANCE',
      payload: {
        healthInsuranceCode:
          selectedExamToCancel.health_insurance.health_insurance_id,
        healthInsuranceName: selectedExamToCancel.health_insurance.name,
        healthPlanCode: selectedExamToCancel.health_insurance.plan_code,
        healthPlanName: selectedExamToCancel.health_insurance.plan
      }
    })
  }

  /////////////////////////////////

  async function handleReschedule() {
    setIsLoading(true)

    await updatePatient()

    dispatch({
      type: 'UPDATE_MEDICAL_ORDER_DOCUMENT_ID',
      payload: [1]
    })

    await updateExam()
    dispatch({
      type: 'SET_IS_RESCHEDULE',
      payload: true
    })

    slideToReschedule()

    setIsLoading(false)
  }

  const onCheckExamsToCancelOptions = (examItem: AppointmentExamItemType) => {
    const alreadyIn = formik.values?.examsToCancel?.some(
      (op: AppointmentExamItemType) => op.id === examItem.id
    )

    if (alreadyIn) {
      formik.setFieldValue(
        'examsToCancel',
        formik.values?.examsToCancel?.filter(
          (op: AppointmentExamItemType) => op.id !== examItem.id
        )
      )
    } else {
      formik.setFieldValue('examsToCancel', [
        ...formik.values?.examsToCancel,
        examItem
      ])
    }
  }

  const isCheckedExamItemsOptions = (exam_item_id: number) => {
    return (
      formik.values?.examsToCancel?.some(
        (op: AppointmentExamItemType) => op.id === exam_item_id
      ) || false
    )
  }

  return (
    <>
      {cancelConfirmationScreen ? (
        <S.CancelWrapper>
          <div>
            <S.ImageContainer>
              <CanceledExam />
            </S.ImageContainer>
            <S.PurpleStrong>Agendamento Cancelado</S.PurpleStrong>
            <S.TitleDescription>
              Você pode agendar novamente clicando no botão abaixo.
            </S.TitleDescription>
          </div>

          <S.ButtonPanel>
            <ButtonNew onClick={handleReschedule}>
              Agendar uma nova data
            </ButtonNew>
            <ButtonNew onClick={handleRedirect} outlined>
              Voltar para Meus Agendamentos
            </ButtonNew>
          </S.ButtonPanel>
        </S.CancelWrapper>
      ) : (
        <>
          <S.PurpleStrong>Cancelar agendamento</S.PurpleStrong>
          <ContainerNew
            subtitle={
              'Você tem certeza que deseja cancelar este agendamento? Você também pode reagendar uma nova data'
            }
            noPadding
          >
            <DividerNew />

            <S.ExamTitle>
              {selectedExamToCancel.items.length > 1
                ? selectedExamToCancel.items[0].exam.name +
                  ' + ' +
                  selectedExamToCancel.items[1].exam.name
                : selectedExamToCancel.items[0].exam.name}
            </S.ExamTitle>
            <CancelExamCard selectedExamToCancel={selectedExamToCancel} />

            {selectedExamToCancel.items.length > 1 && (
              <>
                <S.CancelReasonTitle>
                  Selecione os exames que deseja cancelar:
                </S.CancelReasonTitle>
                <>
                  {selectedExamToCancel.items.map((item, index) => (
                    <CheckboxNew
                      name="exam"
                      key={item.id}
                      label={item.exam.name}
                      value={item.id}
                      labelFor={`exame-${index}`}
                      onCheck={() => onCheckExamsToCancelOptions(item)}
                      checked={isCheckedExamItemsOptions(item.id)}
                    />
                  ))}
                </>
              </>
            )}

            <DividerNew />

            <S.CancelReasonTitle>
              Por qual motivo você deseja cancelar o seu agendamento?
            </S.CancelReasonTitle>

            <S.RadioContainer>
              {reasonsCancel?.length &&
                reasonsCancel.map((reason: ReasonCancelType) => {
                  return (
                    <RadioButtonNew
                      key={reason.reason_cancel_id}
                      showBackground={false}
                      name="cancelReasonDescription"
                      labelFor={
                        reason.description.charAt(0) +
                        reason.description.toLocaleLowerCase().slice(1)
                      }
                      value={reason.reason_cancel_id}
                      label={
                        reason.description.charAt(0) +
                        reason.description.toLocaleLowerCase().slice(1)
                      }
                      onCheck={() => handleSelectReason(reason)}
                    />
                  )
                })}
            </S.RadioContainer>

            {formik.values.cancelReasonDescription === 'OUTRO' && (
              <TextAreaNew
                rows={3}
                dataTestId="pendency-text-area"
                placeholder="Escreva o motivo aqui"
                label="Motivo:"
                name="textReason"
                onInputChange={formik.handleChange('textReason')}
                error={formik.errors.textReason}
                value={formik.values.textReason}
                // onBlur={formik.handleBlur('textReason')}
              />
            )}

            <S.ButtonPanel>
              <ButtonNew type="submit" onClick={() => formik.handleSubmit()}>
                Cancelar agendamento
              </ButtonNew>
            </S.ButtonPanel>
          </ContainerNew>
        </>
      )}
    </>
  )
}

const validationSchema = yup.object().shape({
  // cancelReason: yup.string().required(),
  textReason: yup.string().when('reasonDescription', {
    is: 'OUTRO',
    then: yup.string().required()
  })
})

export default CancelExamSchedule

const CancelExamCard = ({ selectedExamToCancel }: CancelExamCardProps) => {
  return (
    <>
      <S.CardContainer>
        <S.ContainerInfo>
          <S.Label>
            <CalendarIcon />
            Data agendada
          </S.Label>
          <S.PurpleDescription>
            {moment
              .utc(selectedExamToCancel.items[0].appointment_date)
              .format('DD/MM/YYYY [às] HH[h]mm')}
          </S.PurpleDescription>
        </S.ContainerInfo>

        {selectedExamToCancel.items[0]?.doctor?.name && (
          <S.ContainerInfo>
            <S.Label>
              <StethoscopeIconOutline width={15} />
              Médico
            </S.Label>
            <S.SubDescription>
              Dr(a) {selectedExamToCancel.items[0]?.doctor?.name}{' '}
            </S.SubDescription>
          </S.ContainerInfo>
        )}

        <S.ContainerInfo>
          <S.Label>
            <GrayPinIcon />
            Unidade de atendimento
          </S.Label>
          <S.PurpleDescription>
            {' '}
            {selectedExamToCancel.items[0].unit.name}{' '}
          </S.PurpleDescription>
          <S.AddressDescription>
            {' '}
            {selectedExamToCancel.items[0].unit.address}
          </S.AddressDescription>
        </S.ContainerInfo>

        <S.ContainerInfo>
          <S.Label>
            <SyringeIcon />
            Vai realizar sedação?
          </S.Label>
          <S.SubDescription>
            {selectedExamToCancel.use_sedation ? 'Sim' : 'Não'}{' '}
          </S.SubDescription>
        </S.ContainerInfo>
      </S.CardContainer>
    </>
  )
}
