import React from 'react'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { ApiRepository } from 'repository/api-repository'
import { HealthInsuranceRepository } from 'repository/repositories/health-insurance/health-insurance-repository'
import ChangeSurgicalOrderInsuranceInfo from 'presentation/pages/UpdateInsuranceInfo'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import { RemoteUpdatePatientHealthInsurance } from 'service/usecases/patient-update-health-insurance/remote-patient-update-health-insurance'
import { RemoteUploadPatientDocument } from 'service/usecases/upload-patient-document/remote-upload-patient-document'
import { RemotePatientUpdateInfo } from 'service/usecases/patient-update-info/remote-patient-update-info'
import { RemoteLoadHealthInsurancePlans } from 'service/usecases/load-health-insurance-plans/remote-load-health-insurance-plans'
import { RemoteLoadSurgicalOrder } from 'service/usecases/load-surgical-order/remote-load-surgical-order'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export default function makePatientSaveSurgicalOrderInsuranceInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const healthInsuranceRepository = new HealthInsuranceRepository(apiRepository)
  const patientRepository = new PatientRepository(apiRepository)

  const loadPatientInfo = new RemoteLoadPatientInfo(patientRepository)
  const updateInsuranceInfo = new RemoteUpdatePatientHealthInsurance(
    patientRepository
  )
  const uploadInsuranceCard = new RemoteUploadPatientDocument(patientRepository)
  const updatePatientPersonalInfo = new RemotePatientUpdateInfo(
    patientRepository
  )
  const loadHealthInsurance = new RemoteLoadHealthInsurancePlans(
    healthInsuranceRepository
  )

  const surgicalOrderRepository = new SurgicalOrderRepository(apiRepository)
  const loadSurgicalOrder = new RemoteLoadSurgicalOrder(surgicalOrderRepository)

  return (
    <ChangeSurgicalOrderInsuranceInfo
      loadHealthInsurance={loadHealthInsurance}
      loadSurgicalOrder={loadSurgicalOrder}
      loadPatientInfo={loadPatientInfo}
      uploadInsuranceCard={uploadInsuranceCard}
      updateInsuranceInfo={updateInsuranceInfo}
      updatePatientPersonalInfo={updatePatientPersonalInfo}
    />
  )
}
