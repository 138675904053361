import { LoadUserFlags } from 'domain/usecases/user/load-user-flags'
import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'

export class RemoteLoadUserFlags implements LoadUserFlags {
  constructor(private readonly userRepository: IUserRepositoryRepository) {}

  loadUserFlags(params: LoadUserFlags.Params): Promise<LoadUserFlags.Model> {
    return this.userRepository.loadUserFlags(params)
  }
}

export type LoadUserFlagsModel = LoadUserFlags.Model
