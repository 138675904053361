import React from 'react'
import MuiTooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from 'styled-components'
import { Fade } from '@material-ui/core'

const DEFAULT_STYLE = {
  tooltip: {
    maxWidth: '210px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '8px 16px'
  }
}

const StandardTooltipStyle = makeStyles(() => {
  const scTheme = useTheme()
  return {
    tooltip: {
      ...DEFAULT_STYLE.tooltip,
      fontFamily: scTheme.ds.typography.fontFamily,
      color: scTheme.ds.colors.white,
      backgroundColor: scTheme.ds.colors.neutral700,
      textAlign: 'left'
    },
    arrow: {
      color: scTheme.ds.colors.neutral700
    }
  }
})

const AlertTooltipStyle = makeStyles(() => {
  const scTheme = useTheme()
  return {
    tooltip: {
      ...DEFAULT_STYLE.tooltip,
      fontFamily: scTheme.ds.typography.fontFamily,
      color: scTheme.ds.colors.danger400,
      backgroundColor: scTheme.ds.colors.danger100,
      textAlign: 'left'
    },
    arrow: {
      color: scTheme.ds.colors.danger100
    }
  }
})

type Props = {
  children: React.ReactElement
  text: string
  className?: string
  type?: 'standard' | 'alert'
}

export function TooltipNew({
  children,
  text,
  type = 'standard',
  className
}: Props) {
  const classes =
    type === 'standard' ? StandardTooltipStyle() : AlertTooltipStyle()

  return (
    <MuiTooltip
      title={text}
      style={{ cursor: 'pointer' }}
      arrow
      placement="top-start"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      leaveDelay={200}
      enterDelay={100}
      className={className}
      classes={classes}
    >
      {children}
    </MuiTooltip>
  )
}
