import React from 'react'
import { useHistory, useLocation, Redirect } from 'react-router-dom'

import SchedulingSearchForm from 'presentation/components/Forms/SchedulingSearch'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { SchedulingPatient } from 'domain/entities/scheduling-patient-model'
import HeaderNew from 'presentation/components/HeaderNew'
import { SchedulingSpecialty } from 'domain/entities/scheduling-specialty-model'

type LocationType = {
  state: {
    patient: SchedulingPatient
    selectedHealthInsurance: PatientHealthInsurance
    specialtyFromExam?: SchedulingSpecialty
    medicalOrderDocumentIds: number[]
  }
}

const SchedulingSearchLayout = () => {
  const history = useHistory()
  const location: LocationType = useLocation()
  const selectedPatient = location.state && location.state.patient
  const selectedHealthInsurance =
    location.state && location.state.selectedHealthInsurance

  const specialtyFromExam = location.state && location.state.specialtyFromExam

  const medicalOrderDocumentIds =
    location.state && location.state.medicalOrderDocumentIds

  if (!selectedPatient || !selectedHealthInsurance) {
    return <Redirect to="/agendamento/consulta/convenios" />
  }

  function handlePrev() {
    if (!!selectedPatient && !!selectedHealthInsurance && !!specialtyFromExam) {
      history.push('/agendamento/exames', {
        currentSlide: 4,
        selectedHealthInsurance,
        selectedPatient,
        medicalOrderDocumentIds
      })
    } else {
      history.push('/agendamento/consulta/convenios')
    }
  }
  return (
    <>
      <HeaderNew
        actualPageTitle="Agendar consulta"
        actualPageOnClick={handlePrev}
      />
      <SchedulingSearchForm
        patient={selectedPatient}
        healthInsurance={selectedHealthInsurance}
        specialtyFromExam={specialtyFromExam}
      />
    </>
  )
}

export default SchedulingSearchLayout
