import { SurgicalStatusModel } from 'domain/entities/surgical-status-model'
import { SurgicalOrderStatus } from 'presentation/shared/components/Timeline/status'

export function getCrmoTableStatus(surgeryStatusEnum: SurgicalStatusModel[]) {
  let actualStatus = surgeryStatusEnum.slice(-1)[0].status
  if (actualStatus === SurgicalOrderStatus.SCHEDULED) {
    actualStatus = surgeryStatusEnum.slice(-2)[0].status
  }
  const crmoStatus = {
    [SurgicalOrderStatus.ORDER_REGISTERED]: 'Pedidos solicitados por pacientes',
    [SurgicalOrderStatus.REVIEW]: 'Revisão',
    [SurgicalOrderStatus.OPME_QUOTATION]: 'Cotação de OPME',
    [SurgicalOrderStatus.PRE_SCHEDULE]: 'Pré agendamento',
    [SurgicalOrderStatus.CRMO]: 'Na CRMO',
    [SurgicalOrderStatus.HEALTH_INSURANCE_APPROVE]: 'No Convênio',
    [SurgicalOrderStatus.AUTHORIZED]: 'Autorizado',
    [SurgicalOrderStatus.FASTPASS]: 'Autorizado',
    [SurgicalOrderStatus.CHECK_IN]: 'Autorizado',
    [SurgicalOrderStatus.PATIENT_IN_SURGERY]: 'Autorizado',
    [SurgicalOrderStatus.PATIENT_RECOVERY]: 'Autorizado',
    [SurgicalOrderStatus.PATIENT_DISCHARGE]: 'Autorizado',
    [SurgicalOrderStatus.CONCLUDED]: 'Realizada',
    [SurgicalOrderStatus.CANCELLATION_REQUEST]: 'Solicitação de cancelamento',
    [SurgicalOrderStatus.SERVICE_EVALUATION]: 'Realizada',
    [SurgicalOrderStatus.CANCELLED]: 'Cancelada',
    [SurgicalOrderStatus.PENDENCY]: 'Pendências',
    [SurgicalOrderStatus.SCHEDULED]: 'Pedido agendado',
    [SurgicalOrderStatus.UNDER_REVIEW_AT_THE_HOSPITAL]:
      'Em análise do hospital',
    [SurgicalOrderStatus.UNDER_REVIEW_AT_THE_INSURANCE]:
      'Em análise no convênio',
    [SurgicalOrderStatus.REQUESTED_CANCELLATION]: 'Solicitação de cancelamento'
  }
  return crmoStatus[actualStatus]
}
