import { CreateRegisterPendency } from 'domain/usecases/register-pendency/create-register-pendency'
import { RegisterPendencyRepository } from 'repository/interfaces/register-pendency-repository'

export class RemoteCreateRegisterPendency implements CreateRegisterPendency {
  constructor(
    private readonly registrationStatusRepository: RegisterPendencyRepository
  ) {}

  createRegisterPendency(
    data: CreateRegisterPendency.Params
  ): Promise<CreateRegisterPendency.Model> {
    return this.registrationStatusRepository.createRegisterPendency(data)
  }
}
