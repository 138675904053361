import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import * as S from './styles'

import BottomMenu from 'presentation/components/BottomMenu'
// import Modal from 'presentation/shared/components/Modal'
// import { openPhoneApp } from 'presentation/utils/mobile-actions'
// import SupportText from 'presentation/shared/components/SupportText'
// import Button from 'presentation/shared/components/Button'
// import requestHelpcon from 'presentation/assets/icons/filled-request-help.svg'
import CalendarTimerIcon from 'presentation/assets/icons/schedule-appointment-or-exam.svg'
import myAppointments from 'presentation/assets/icons/my-appointments.svg'
import examResults from 'presentation/assets/icons/exam-results.svg'
import examPreparation from 'presentation/assets/icons/exam-preparation.svg'
import newSurgicalOrder from 'presentation/assets/icons/filled-doctor.svg'
import newbornPackages from 'presentation/assets/icons/baby.svg'
import documentLink from 'presentation/assets/icons/document-link.svg'
import TelemedicineIcon from 'presentation/assets/icons/telemedicine.svg'
import { EmergencyMenu } from 'presentation/components/Forms/EmergencyRoom/EmergencyMenu'
import { ContainerNew } from 'presentation/components/ContainerNew'
import HeaderNew from 'presentation/components/HeaderNew'

const TELEMEDICINE_LINK = 'https://materdei.drtis.com.br/'

export default function ServicesLayout() {
  const history = useHistory()
  // const [showWhatsappModal, setShowWhatsappModal] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<string>('appointments')

  const { state } = useLocation<{ anchor: string }>()

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSelectedItem(state?.anchor ? state.anchor : 'appointments')
  }, [])

  return (
    <>
      <HeaderNew
        actualPageTitle="Serviços"
        actualPageOnClick={() => history.push('/home')}
      />
      <div style={{ backgroundColor: 'white' }} ref={scrollRef}>
        <EmergencyMenu
          onSelectItem={(itemMenu: string) => setSelectedItem(itemMenu)}
          selectedItemMenu={selectedItem}
        />
      </div>
      <ContainerNew
        style={{ backgroundColor: 'white', paddingTop: '0' }}
        noHeader
      >
        {selectedItem === 'appointments' && (
          <>
            {/* {process.env.REACT_APP_FEATURE_FLIP_ENABLE_SCHEDULE === 'true' && ( */}
            <>
              <S.EmergencyRoomOption
                onClick={() =>
                  history.push('/agendamento/consulta/confirmar-dados')
                }
              >
                <S.Icon>
                  <img src={CalendarTimerIcon} alt="Ícone calendário" />
                </S.Icon>
                <S.Title>Agendar consulta</S.Title>
              </S.EmergencyRoomOption>
              <S.DividingLine />
              <S.EmergencyRoomOption onClick={() => history.push('/consultas')}>
                <S.Icon>
                  <img src={myAppointments} alt="myAppointments" />
                </S.Icon>
                <S.Title>Minhas consultas</S.Title>
              </S.EmergencyRoomOption>
              <S.DividingLine />

              <S.EmergencyRoomOption
                onClick={() => history.push('/meus-documentos')}
              >
                <S.Icon>
                  <img src={documentLink} alt="myDocuments" />
                </S.Icon>
                <S.TitleSection>
                  <S.Title>Meus documentos</S.Title>
                  <S.SubTitle>
                    Atestados, receitas e pedidos de exames
                  </S.SubTitle>
                </S.TitleSection>
              </S.EmergencyRoomOption>
              <S.DividingLine />
            </>
            {/* )} */}
            <S.EmergencyRoomOption
              onClick={() => window.location.replace(TELEMEDICINE_LINK)}
            >
              <S.Icon>
                <img src={TelemedicineIcon} alt="myAppointments" />
              </S.Icon>
              <S.Title>Atendimentos Telemedicina</S.Title>
            </S.EmergencyRoomOption>
          </>
        )}
        {selectedItem === 'exams' && (
          <>
            <S.EmergencyRoomOption
              onClick={() => history.push('/agendamento/exames')}
            >
              <S.Icon>
                <img src={CalendarTimerIcon} alt="Ícone calendário" />
              </S.Icon>
              <S.Title>Agendar exames</S.Title>
            </S.EmergencyRoomOption>

            <S.EmergencyRoomOption onClick={() => history.push('/exames')}>
              <S.Icon>
                <img src={examResults} alt="examResults" />
              </S.Icon>
              <S.Title>Resultado de exames</S.Title>
            </S.EmergencyRoomOption>

            <S.EmergencyRoomOption
              onClick={() => history.push('/exames/preparos')}
            >
              <S.Icon>
                <img src={examPreparation} alt="examsPreparations" />
              </S.Icon>
              <S.Title>Preparos de exames</S.Title>
            </S.EmergencyRoomOption>
          </>
        )}
        {selectedItem === 'surgery' && (
          <S.EmergencyRoomOption
            onClick={() => history.push('/pedido-cirurgico')}
          >
            <S.Icon>
              <img src={newSurgicalOrder} alt="newSurgicalOrder" />
            </S.Icon>
            <S.Title>Novo pedido cirúrgico</S.Title>
          </S.EmergencyRoomOption>
        )}
        {/* {selectedItem === 'emergency_room' && (
          <>
            <S.EmergencyRoomOption
              onClick={() => history.push('solicitar-atendimento')}
            >
              <S.Icon>
                <img src={requestHelpcon} />
              </S.Icon>
              <S.Title>Solicitar Pronto-Socorro</S.Title>
            </S.EmergencyRoomOption>
            <S.DividingLine />
            <S.EmergencyRoomOption>
              <S.Icon>
                <img src={myAppointments} alt="myAppointments" />
              </S.Icon>
              <S.Title>Atendimentos anteriores</S.Title>
            </S.EmergencyRoomOption>
          </>
        )} */}
        {selectedItem === 'vaccines' && (
          <>
            <S.EmergencyRoomOption
              onClick={() => history.push('/pacotes-recem-nascido')}
            >
              <S.Icon>
                <img src={newbornPackages} />
              </S.Icon>
              <S.Title>Pacotes Recém-Nascido</S.Title>
            </S.EmergencyRoomOption>
          </>
        )}
      </ContainerNew>
      {/* <Modal
        show={showWhatsappModal}
        style={{ justifyContent: 'space-between', padding: '34px 27px' }}
        close={() => setShowWhatsappModal(false)}
      >
        <Heading>Quase lá</Heading>
        <SupportText>
          Você está sendo direcionado para o nosso atendimento virtual no
          WhatsApp!
        </SupportText>
        <Button
          variant="outlined"
          onClick={() => {
            openPhoneApp('whatsApp', '553133399800')
            setShowWhatsappModal(false)
          }}
        >
          <WhatsappIcon />
          Ir para o atendimento
        </Button>
      </Modal> */}
      <BottomMenu />
    </>
  )
}
