import { Profile } from 'common/enum/profile'
import { Hospital } from 'domain/entities/hospital-model'
import { UserStatusEnum } from 'common/enum/user-status'
import { Address } from './address-model'
import { ClassProps } from 'common/utils/getClassProps'
import { UserBirthInfo } from './user-birth-info-model'
import { Term } from 'domain/entities/term'
import { UserActiveFeatures } from './user-active-features'

export class User {
  user_id!: number
  name!: string
  lastName?: string
  cpf!: string
  phone?: string
  landlinePhone?: string
  email!: string
  password?: string
  isMain?: string
  isMainRole?: string
  role!: Profile
  groups!: string[]
  permissions?: string[]
  status!: UserStatusEnum
  profile_pic?: string
  photoKey?: string
  selectedHospital?: number
  hospitals?: Hospital[]
  address?: Address
  birthInfo?: UserBirthInfo
  finishedAppOnboarding?: boolean
  finishTutorialTutor?: boolean
  dependents?: User[]
  tutor?: User
  terms?: Term[]
  features?: UserActiveFeatures
  constructor(props?: UserProps) {
    Object.assign(this, props)
  }
}

type UserProps = ClassProps<typeof User.prototype>
