import { GetReasonCancel } from 'domain/usecases/scheduling/get-reason-cancel'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetReasonCancel implements GetReasonCancel {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(): Promise<GetReasonCancel.Model> {
    return this.schedulingRepository.getReasonCancel()
  }
}

export type GetReasonCancelModel = GetReasonCancel.Model
