import { Accompanying } from './accompanying-model'
import {
  EmergencyRoomSymptoms,
  SymptomsValues
} from './emergency-room-symptoms.model'
import { EmergencyRoomTokenModel } from './emergency-room-token.model'
import { Hospital } from './hospital-model'
import { NotificationConfig } from './notification-config-model'
import { Patient } from './patient-model'

export enum EmergencyRoomStatus {
  REQUEST_SENT = 'REQUEST_SENT',
  CANCELED = 'CANCELED',
  SCREENING = 'SCREENING',
  REGISTRATION = 'REGISTRATION',
  ATTENDANCE = 'ATTENDANCE',
  ACCOUNT_CLOSING = 'ACCOUNT_CLOSING'
}

export interface EmergencyRoomAttendanceProps {
  therapeutic_plan_id?: number
  hospital_id?: number
  patientName?: string
  specialty?: string
  arrivalForecast?: string
  symptoms?: SymptomsValues
  health_insurance_id?: number
  accompanies_id?: number[]
  createdAt?: Date
  updatedAt?: Date
  emergencyRoomToken?: EmergencyRoomTokenModel
  hospital?: Hospital
  patient?: Patient
  status?: EmergencyRoomStatus
  emergency_room_attendance_id?: number
  accompanies?: Accompanying[]
}

export class EmergencyRoomAttendanceModel
  implements EmergencyRoomAttendanceProps
{
  hospital_id?: number
  specialty?: string
  patientName?: string
  symptoms?: EmergencyRoomSymptoms
  health_insurance_id?: number
  accompanies_id?: number[]
  createdAt?: Date
  updatedAt?: Date
  emergencyRoomToken?: EmergencyRoomTokenModel
  hospital?: Hospital
  patient?: Patient
  status?: EmergencyRoomStatus
  emergency_room_attendance_id?: number
  accompanies?: Accompanying[]

  constructor(props: EmergencyRoomAttendanceProps) {
    if (!props) return
    Object.assign(this, props)
    if (props.emergencyRoomToken) {
      this.emergencyRoomToken = new EmergencyRoomTokenModel(
        props.emergencyRoomToken
      )
    }
    if (props.createdAt) {
      this.createdAt = new Date(props.createdAt)
    }
    if (props.updatedAt) {
      this.updatedAt = new Date(props.updatedAt)
    }
    if (props.hospital) {
      const anyHospital = this.hospital as any
      this.hospital = new Hospital(
        this.hospital?.hospital_id || anyHospital?._hospital_id || 0,
        this.hospital?.nickname || anyHospital?._nickname || '',
        this.hospital?.name || anyHospital?._name || '',
        this.hospital?.friendly_name || anyHospital?.friendly_name || '',
        this.hospital?.specialityHospital || anyHospital?._specialityHospital,
        this.hospital?.hasPartnerCode || anyHospital?._hasPartnerCode
      )
    }
    if (props.patient) {
      this.patient = new Patient(props.patient)
    }
    if (props.accompanies) {
      this.accompanies = props.accompanies.map((accompanying) => {
        const anyAccompanying = accompanying as any

        return new Accompanying(
          accompanying.accompanying_id || anyAccompanying._accompanying_id,
          accompanying.name || anyAccompanying._name,
          accompanying.lastName || anyAccompanying._lastName,
          accompanying.email || anyAccompanying._email,
          accompanying.phone || anyAccompanying._phone,
          new NotificationConfig(
            accompanying.sendEmail || anyAccompanying._sendEmail,
            accompanying.sendSMS || anyAccompanying._sendSMS
          ),
          accompanying.patient || anyAccompanying._patient,
          accompanying.isMain || anyAccompanying._isMain,
          accompanying.socialName || anyAccompanying._socialName
        )
      })
    }
    if (props.symptoms) {
      this.symptoms = new EmergencyRoomSymptoms(props.symptoms)
    }
  }

  public get firstAccompanying(): Accompanying | undefined {
    return this?.accompanies?.[0]
  }
}
