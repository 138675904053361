import React from 'react'
import ButtonNew from '../../../components/ButtonNew'
import { ContainerNew } from '../../../components/ContainerNew'
import { useHistory } from 'react-router'
import * as S from './styles'
import Logo from '../../../components/Logo'

export default function Tutorials({
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  children
}: any) {
  const history = useHistory()

  const primaryButton = () => {
    return (
      <ButtonNew
        onClick={primaryButtonAction}
        size="large"
        fullWidth
        style={{ marginBottom: '8px' }}
      >
        {primaryButtonText}
      </ButtonNew>
    )
  }
  const secondaryButton = () => {
    return (
      <ButtonNew
        onClick={secondaryButtonAction}
        variant="text"
        size="large"
        fullWidth
        style={{ marginBottom: '8px' }}
      >
        {secondaryButtonText}
      </ButtonNew>
    )
  }

  return (
    <>
      <S.Header>
        <Logo onClick={() => history.push('/home')} />
      </S.Header>
      <ContainerNew
        noHeader
        primaryButton={primaryButton()}
        secondaryButton={secondaryButton()}
        justifyContent="center"
        alignItems="center"
        gap="60px"
        style={{ background: 'white', paddingTop: '0' }}
      >
        {children}
      </ContainerNew>
    </>
  )
}
