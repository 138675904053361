import { GetSurgicalOrderCancellationNotifications } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteGetSurgicalOrderCancellationNotifications
  implements GetSurgicalOrderCancellationNotifications
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  get(): Promise<GetSurgicalOrderCancellationNotifications.Model> {
    return this.surgicalOrderRepository.getSurgicalOrderCancellationNotifications()
  }
}
