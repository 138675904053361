export default function verifyValidMaxFileSizeInMb(
  size: number,
  maxFileSizeMb: number
) {
  if (size === 0) {
    return false
  }
  const sizeInMb = size / (1024 * 1024)
  return sizeInMb < maxFileSizeMb
}
