import { UpdateUserFlags } from 'domain/usecases/user/update-user-flags'
import { IUserRepositoryRepository } from 'repository/interfaces/user-repository'

export class RemoteUpdateUserFlags implements UpdateUserFlags {
  constructor(private readonly userRepository: IUserRepositoryRepository) {}

  updateUserFlags(
    params: UpdateUserFlags.Params
  ): Promise<UpdateUserFlags.Model> {
    return this.userRepository.updateUserFlags(params)
  }
}

export type UpdateUserFlagsModel = UpdateUserFlags.Model
