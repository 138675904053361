import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import SheetModal from 'presentation/components/SheetModal'
import React from 'react'

type SuccessModalProps = {
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export function SuccessModal({ onConfirm, onClose, open }: SuccessModalProps) {
  return (
    <SheetModal size={250} isOpen={open} onClose={onClose} backdrop="true">
      <div style={{ marginBottom: '20px' }}>
        <Heading size="large">
          Convênio selecionado com <strong>sucesso</strong>
        </Heading>
      </div>
      <Button
        fullWidth
        style={{ marginTop: '20px' }}
        onClick={() => {
          onConfirm()
        }}
      >
        Confirmar
      </Button>
    </SheetModal>
  )
}
