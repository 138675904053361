export type Attendance = {
  identifier: number
  type: AttendanceType
  attendanceDate?: Date
  description?: string
  status: string
  hospitalName: string
}

export enum AttendanceType {
  SURGICAL = 'SURGICAL',
  AMBULATORY = 'AMBULATORY'
}

export enum AttendanceTypeTranslation {
  SURGICAL = 'Cirurgia',
  AMBULATORY = 'Ambulatório'
}
