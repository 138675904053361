import styled from 'styled-components'

export const Wrapper = styled.main`
  h2 {
    margin-top: 20px;
    font-weight: ${({ theme }) => theme.font.black};
  }
  p {
    margin-bottom: 20px;
    line-height: 24px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  height: auto;
  width: 100%;
  position: relative;
  padding: 0.7em;

  background-color: white;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  min-height: 80px;

  h3 {
    font-size: 1em;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.bold};
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  p {
    line-height: 17px;
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    font-weight: ${({ theme }) => theme.font.light};
    color: ${({ theme }) => theme.colors.gray};
  }

  .password-description {
    font-weight: ${({ theme }) => theme.font.light};
    margin-bottom: 6px;
  }

  .procedure-title {
    svg {
      rect {
        fill: transparent;
      }
    }
  }

  .procedure-observation-title {
    font-size: 0.96em;
  }

  .procedure-container {
    display: flex;
    gap: 20px;

    p {
      margin-bottom: 9px;
    }
  }

  .procedure-description {
    margin-bottom: 9px;
  }

  strong {
    line-height: 11px;
    font-size: ${({ theme }) => theme.font.sizes.small};
    font-weight: ${({ theme }) => theme.font.bold};
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const Divider = styled.div`
  height: 1px;
  background: #82cec9;
  margin: 0px 0 10px 0;

  &:nth-last-child(2) {
    display: none;
  }
`

export const GreenCard = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.mainBg};
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 10px;
  min-height: 81px;
`
