import { Gender } from 'common/enum/gender'
import { Secretary } from './secretary-model'
import { User } from './user-model'
import { SurgicalPendency } from 'domain/entities/surgical-pendency'
import { StudyModality } from 'common/enum/study-modality'
import { DoctorCouncilRegister } from './council-register-model'
import { DoctorSpecialty } from './doctor-specialty-model'
import { Immunization } from './immunization-model'
import { Document } from './document'
import { DoctorStatus } from 'common/enum/doctor-status'
import { DoctorRecommendation } from './doctor-recommendation-model'
import { OverallRegistrationStatusWithoutDoctor } from './overall-registration-status-without-doctor-model'
import { MedicalTeam } from 'domain/entities/MedicalTeam'
import { ClassProps } from 'common/utils/getClassProps'
import { Type } from 'class-transformer'
import { ActingSectors } from 'common/enum/acting-sectors'
import { Specialty } from 'domain/usecases/specialties/load-specialties'
import { DoctorRegister } from 'domain/entities/doctor-register'

export class Doctor extends User {
  doctor_id!: number
  crm!: string
  crmUf!: string
  keySignature?: string
  surgicalOrderCount?: SurgicalOrderCount
  gender!: Gender
  secretary!: Secretary[]

  @Type(() => Date)
  createdInMvAt?: Date

  @Type(() => Date)
  createdAt?: Date

  updatedAt?: Date
  pendencies?: SurgicalPendency[]
  specialty?: Specialty
  coordinator?: string | undefined
  validated?: boolean
  badgeName?: string
  ethnicity?: string
  birthDate?: Date
  rg?: string
  fatherName?: string
  motherName?: string
  emergencyPhone?: string
  emergencyContact?: string
  materDeiStudent?: boolean
  modality?: StudyModality
  startCollegeDate?: Date
  endCollegePrediction?: Date
  academicFormation?: string
  academicInstitution?: string
  lattes?: string
  linkedin?: string
  sectors?: string[]
  privileges?: string[]
  otherClinics?: boolean
  privateOffice?: boolean
  medicalInsurance?: string[]
  otherClinicsName?: string
  immunization?: Immunization[]
  specialties?: DoctorSpecialty[]
  councilRegister?: DoctorCouncilRegister[]
  doctorDocuments?: Document[]
  doctorStatus?: DoctorStatus
  overallRegistrationStatus?: OverallRegistrationStatusWithoutDoctor

  @Type(() => DoctorRegister)
  doctorRegister?: DoctorRegister
  medicalTeam?: MedicalTeam[]

  @Type(() => DoctorRecommendation)
  getDoctorRecommendation?: DoctorRecommendation

  get translatedSectors(): string[] {
    return translateSectors(this.sectors || [])
  }

  get mainSpecialty(): Specialty | undefined {
    return this.specialties?.find((specialty) => specialty.isMain)
  }

  constructor(props?: DoctorProps) {
    super(props)
    Object.assign(this, props)
  }
}

type SurgicalOrderCount = {
  total?: number
}

type DoctorProps = ClassProps<typeof Doctor.prototype>

function translateSectors(sectors: string[]): string[] {
  return sectors.map((sector) => {
    switch (sector) {
      case ActingSectors.EMERGENCY_ROOM:
        return 'Pronto Socorro (PSO)'
      case ActingSectors.INPATIENT_UNIT:
        return 'Unidade de Internação (UIN)'
      case ActingSectors.INTENSIVE_TREATMENT_CENTER:
        return 'Centro de Tratamento Intensivo (CTI-Geral)'
      case ActingSectors.INTENSIVE_TREATMENT_CENTER_CORONARY:
        return 'Centro de Tratamento Intensivo - Unidade Coronariana (CTI-UCO)'
      case ActingSectors.NEONATAL_INTENSIVE_CARE_UNIT:
        return 'Unidade de Terapia Intensiva Neonatal (UTIN)'
      case ActingSectors.PEDIATRIC_INTENSIVE_CARE_UNIT:
        return 'Unidade de Terapia Intensiva Pediátrica (UTIP)'
      case ActingSectors.AMBULATORY:
        return 'Ambulatório (AMB)'
      case ActingSectors.SURGICAL_CENTER:
        return 'Centro Cirúrgico (CCI)'
      case ActingSectors.WOUND_CENTER:
        return 'Centro de Feridas (CFE)'
      case ActingSectors.ONCOLOGY:
        return 'Oncologia (ONC)'
      case ActingSectors.DIAGNOSTIC_MEDICINE:
        return 'Medicina Diagnóstica (MDG)'
      case ActingSectors.HEMODYNAMICS:
        return 'Hemodinâmica (HEM)'
      case ActingSectors.ENDOSCOPY:
        return 'Endoscopia (END)'
      default:
        return sector
    }
  })
}
