import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  padding: 0px 24px;
  margin-top: min(50px, 10%);
  display: flex;
  flex-direction: column;
  flex: 1;
  & > span {
    align-self: flex-end;
    justify-self: flex-end;
    margin-bottom: 20px;
  }
`

export const Header = styled.div`
  ${({ theme }) => css`
    h1 {
      margin-top: 12px;
      margin-bottom: 12px;
      strong {
        color: ${theme.colors.primary};
      }
    }
  `}
`
