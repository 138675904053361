import styled from 'styled-components'

export const SubtitleWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  div {
    display: flex;
    align-items: center;
  }

  h6,
  span {
    font-size: 10px;
    display: inline-block;
  }

  h6 {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
    font-weight: 500;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 700 !important;
  }
`

export const AnswerInfoWrapper = styled.div`
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  h6,
  span {
    font-size: 10px;
    display: inline-block;
    font-weight: 500 !important;
  }

  h6 {
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 4px;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`
