import { ApiNotificationRepository } from 'repository/rest/api-notification/api-notification-repository'
import { ApiResponse } from 'service/protocols/api-notification/api-notification-client'
import {
  IApiNotificationRepository,
  RepositoryRequest
} from 'service/protocols/api-notification/api-notification-repository'
import { GetStorage } from 'service/protocols/cache/get-storage'

export class PrepareApiRequestNotificationDecorator
  implements IApiNotificationRepository
{
  constructor(
    private readonly getStorage: GetStorage,
    private readonly apiNotificationRepository: ApiNotificationRepository
  ) {}

  async get(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-notifications${data.url}`
        : `${process.env.REACT_APP_API_MATERDEI_NOTIFICATIONS_URL + data.url}`

    return await this.apiNotificationRepository.get(data)
  }

  async put(data: RepositoryRequest): Promise<ApiResponse> {
    const account = this.getStorage.get('account')
    if (account?.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          authorization: 'Bearer ' + account.accessToken
        })
      })
    }
    data.url =
      process.env.NODE_ENV === 'production'
        ? `/proxy-notifications${data.url}`
        : `${process.env.REACT_APP_API_MATERDEI_NOTIFICATIONS_URL + data.url}`

    return await this.apiNotificationRepository.put(data)
  }
}
