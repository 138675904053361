import React, { useState } from 'react'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import DocumentAccordion from 'presentation/components/DocumentAccordeon'

// export enum DocumentsList {
//   IDENTITY_CARD = 'Carteira de identidade',
//   HEALTH_CARD = 'Carteira do convênio',
//   EXAM_REPORT = 'Laudos de exames',
//   MEDICAL_REPORT = 'Relatório médico',
//   SURGICAL_CONSENT = 'Consentimento cirúrgico',
//   ANESTHETIC_CONSENT = 'Consentimento anestésico',
//   PRE_ANESTHETIC_EVALUATION = 'Avaliação pré-anestésica'
// }

export enum DocumentsList {
  'Carteira de identidade' = 'Carteira de identidade',
  'Carteira do convênio' = 'Carteira do convênio',
  'Laudos dos exames' = 'Laudos de exames',
  'Relatório Médico' = 'Relatório médico',
  'Consent. cirúrgico' = 'Consentimento cirúrgico',
  'Consent. anestésico' = 'Consentimento anestésico',
  'Avaliação pré-anestésica' = 'Avaliação pré-anestésica',
  'Autorização' = 'Autorização',
  'Pedido médico' = 'Pedido médico',
  'EXAME - Pedido médico' = 'Pedido médico'
}

type Props = {
  onClose: (value?: boolean) => void
  onUploadFile?: (files: any, type: string) => void
  onDeleteFile?: (document_id: number, group_id: string) => void
  onDownloadFile?: (
    document_id: number,
    group_id: string,
    type: keyof typeof DocumentsList
  ) => void
  isOpen: boolean
  documents: any[]
}

const DocumentsModal = ({
  isOpen,
  onClose,
  documents,
  onUploadFile,
  onDeleteFile,
  onDownloadFile
}: Props) => {
  const [expandedDocument, setExpandedDocument] = useState('')

  const mappedDocumentsList = () => {
    return Object.keys(DocumentsList).map((document) => ({
      label: DocumentsList[document as keyof typeof DocumentsList],
      type: document,
      ...documents?.find((doc) => doc.type === document)
    }))
  }

  const startUpload = (files: any, type: string) => {
    onUploadFile && onUploadFile(files, type)
  }

  const changeExpandedAccordeon = (
    accordeonLabel: string,
    isExpanded: boolean
  ) => {
    setExpandedDocument(isExpanded ? accordeonLabel : '')
  }

  return (
    <SheetModalNew
      isDraggable
      isOpen={isOpen}
      onClose={() => onClose()}
      title="Adicionar documento"
      gap="16px"
    >
      <SupportTextNew color="neutral600">Documentos</SupportTextNew>
      <SupportTextNew color="neutral900">
        Selecione o tipo de documento para adicionar.
      </SupportTextNew>

      {mappedDocumentsList().map((document) => {
        return (
          <DocumentAccordion
            key={document.type}
            document={document}
            expandedDocument={expandedDocument}
            handleChange={changeExpandedAccordeon}
            shadow="xsatin"
            background="neutral25"
            padding="16px"
            onDeleteFile={onDeleteFile}
            onDownloadFile={onDownloadFile}
            onUploadFile={(files: any) => startUpload(files, document.type)}
          />
        )
      })}
    </SheetModalNew>
  )
}

export default DocumentsModal
