import { GetSchedules } from 'domain/usecases/scheduling/get-schedules'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetSchedules implements GetSchedules {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: GetSchedules.Params): Promise<GetSchedules.Model> {
    return this.schedulingRepository.getSchedules(params)
  }
}

export type SearchDoctorsModel = GetSchedules.Model
