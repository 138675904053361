import React, { useEffect, useState } from 'react'
import { useStores } from 'presentation/hooks/use-stores'
import { User } from 'domain/entities/user-model'
import { toast } from 'react-toastify'
import { WithLoading, WithLoadingProps } from '../HOCs/WithLoading'
import ProfilePic from 'presentation/components/ProfilePic'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import { ReactComponent as CheckIcon } from 'presentation/assets/icons/checked-icon.svg'
import { ReactComponent as ArrowDown } from 'presentation/assets/icons/open-arrow.svg'
import { useHistory } from 'react-router'

import * as S from './styles'

type Props = {
  user: User
} & WithLoadingProps

const DependentDrawer = WithLoading(({ user, setIsLoading }: Props) => {
  const history = useHistory()
  const [dependentsDrawer, setDependentsDrawer] = useState(false)
  const currentAccount = useStores().currentAccount

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    if (hasDependents()) setDependentsDrawer(open)
  }

  const handleChangeDependent = (user_id: number) => {
    if (user_id !== user.user_id) {
      setIsLoading(true)
      currentAccount
        .setCurrentDependent(user_id)
        .then(() => {
          window.location.reload()
          setDependentsDrawer(false)
        })
        .catch((e: any) => {
          toast.error(e.message)
        })
        .finally(() => {
          WithLoading
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (
      !currentAccount.getCurrentAccount().user.finishTutorialTutor &&
      hasDependents()
    ) {
      history.push('/tutorial/tutor')
    }
  }, [])

  const isSelectedDependent = (dependent_id: number) => {
    if (dependent_id === user.user_id) return true
    return false
  }

  const dependentCard = (dependent: User, index: number) => {
    return (
      <S.Card
        className={isSelectedDependent(dependent?.user_id) ? 'isActive' : ''}
        onClick={() => handleChangeDependent(dependent?.user_id)}
        key={index}
      >
        <S.Profile>
          <ProfilePic
            data-testid="profile pic"
            outlined
            size="small"
            name={dependent.name}
          />
          {dependent.name.toLocaleLowerCase()}
        </S.Profile>
        <span className="button">
          {isSelectedDependent(dependent.user_id) ? <CheckIcon /> : 'Entrar'}
        </span>
      </S.Card>
    )
  }

  const hasDependents = () => {
    const depsList = getFullDependentsList(user)
    return depsList.length > 1
  }

  return (
    <>
      <S.DrawerWrapper
        className="drawer"
        anchor={'top'}
        open={dependentsDrawer}
        onClose={toggleDrawer(false)}
      >
        <span>Selecione em qual perfil deseja entrar:</span>
        <S.DependentsList>
          {getFullDependentsList(user)?.map((dependent, index) => {
            return dependentCard(dependent, index)
          })}
        </S.DependentsList>
      </S.DrawerWrapper>
      <S.UserName
        className={hasDependents() ? 'is-clickable' : ''}
        color="primary"
        onClick={toggleDrawer(true)}
      >
        Olá, {user.name.split(' ')[0].toLocaleLowerCase()}
        {hasDependents() && (
          <ArrowDown style={{ marginLeft: '8px', verticalAlign: 'middle' }} />
        )}
      </S.UserName>
    </>
  )
})

export default DependentDrawer
