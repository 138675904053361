import { GetExamScheduleOffersByMonth } from 'domain/usecases/exam/get-exam-schedule-offers-by-month'
import { ExamRepository } from 'repository/interfaces/exam-repository'
export class RemoteGetExamScheduleOffersByMonth
  implements GetExamScheduleOffersByMonth
{
  constructor(private readonly examRepository: ExamRepository) {}
  async load(
    params: GetExamScheduleOffersByMonth.Params
  ): Promise<GetExamScheduleOffersByMonth.Model> {
    return this.examRepository.getExamScheduleOffersByMonth(params)
  }
}

export type GetExamScheduleOffersByMonthModel =
  GetExamScheduleOffersByMonth.Model
