import styled, { css, DefaultTheme } from 'styled-components'
// import { lighten } from 'polished'

import { TextFieldProps } from '.'

type IconPositionProps = Pick<
  TextFieldProps,
  'iconPosition' | 'bgColor' | 'dsBgColor' | 'iconLocale' | 'iconMargin'
>

type WrapperProps = Pick<TextFieldProps, 'disabled'> & { error?: boolean }

export const LabelWrapper = styled.div`
  display: flex;
`

export const Label = styled.label<
  Pick<TextFieldProps, 'labelColor' | 'dsLabelColor'>
>`
  ${({ theme, labelColor, dsLabelColor }) => css`
    color: ${labelColor
      ? theme.colors[labelColor!]
      : theme.ds.colors[dsLabelColor!]};
    font-size: ${theme.ds.typography.size.small};
    font-weight: ${theme.ds.typography.weight.normal};
    line-height: ${theme.ds.typography.lineHeight.medium};
    cursor: pointer;
    margin-bottom: 8px;
  `}
`

export const LabelIcon = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

export const Asterisk = styled.span`
  font-size: ${({ theme }) => theme.ds.typography.size.small};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  color: ${({ theme }) => theme.ds.colors.danger200};
  cursor: pointer;
`

export const InputWrapper = styled.div<IconPositionProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  ${({ theme }) => wrapperModifiers['normal'](theme)}
`

export const Input = styled.input<IconPositionProps>`
  ${({ theme, iconPosition, bgColor, dsBgColor, iconLocale }) => css`
    color: ${theme.ds.colors.neutral900};
    font-family: ${theme.ds.typography.fontFamily};
    font-size: ${theme.ds.typography.size.small};
    line-height: ${theme.ds.typography.lineHeight.medium};
    height: 48px;
    padding-left: ${iconPosition === 'left' ? '50px' : '18px'};
    padding-right: 18px;
    background-color: ${dsBgColor
      ? theme.ds.colors[dsBgColor!]
      : theme.colors[bgColor!]};
    border: 1px solid ${theme.ds.colors.neutral300};
    outline: none;
    border-radius: ${theme.ds.typography.border.radius.small};
    width: ${iconPosition === 'right' && iconLocale === 'outside'
      ? 'calc(100% - 2.2px)'
      : '100%'};

    &::placeholder {
      color: ${theme.ds.colors.neutral500};
      font-weight: ${theme.ds.typography.weight.normal};
      opacity: 1;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      opacity: 1;
    }
  `}
`

export const Icon = styled.button<IconPositionProps>`
  display: flex;
  color: ${({ theme }) => theme.colors.gray};
  background: none;
  border: none;
  order: ${({ iconPosition }) => (iconPosition === 'right' ? 1 : 0)};
  position: ${({ iconLocale }) =>
    iconLocale === 'inside' ? 'absolute' : 'relative'} !important;
  top: 50%;
  margin-top: ${({ iconLocale }) => (iconLocale !== 'inside' ? '-12px' : '0')};
  transform: translateY(-50%);
  left: ${({ iconLocale, iconPosition, iconMargin }) =>
    iconLocale === 'inside' && iconPosition === 'right'
      ? 'calc(100% - ' + iconMargin + ')'
      : 0};
  padding: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 54px;
  outline: none;

  :focus {
    svg {
      outline: 2px solid ${({ theme }) => theme.colors.primary} !important;
      outline-offset: 5px;
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;
    }
  }

  svg {
    position: relative;
    //margin-top: 12px;
    max-width: 22px;
    max-height: 22px;
    outline-offset: -5px;
    transition-property: outline, outline-offset;
    border-radius: 3px;
    transition: 0.2s;
  }
`

export const Error = styled.p`
  color: ${({ theme }) => theme.ds.colors.danger300};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
`

const wrapperModifiers = {
  normal: (theme: DefaultTheme) => css`
    ${Input} {
      &:focus {
        border-color: ${theme.ds.colors.primary300};
      }
    }
  `,
  error: (theme: DefaultTheme) => css`
    ${Input} {
      border-color: ${theme.ds.colors.danger200} !important;
    }
    ${Icon} {
      color: ${theme.ds.colors.danger200} !important;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Input} {
      color: ${theme.ds.colors.neutral300};
      border-color: ${theme.ds.colors.neutral300};
      background-color: ${theme.ds.colors.neutral100};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`
