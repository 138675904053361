import { LoadProtocols } from 'domain/usecases/therapeutic-plan/load-protocols'
import { TherapeuticPlanRepository } from 'repository/interfaces/therapeutic-plan-repository'

export class RemoteLoadProtocols implements LoadProtocols {
  constructor(
    private readonly therapeuticPlanRepository: TherapeuticPlanRepository
  ) {}

  load(params?: LoadProtocols.Params): Promise<LoadProtocols.Model> {
    return this.therapeuticPlanRepository.loadProtocols(params)
  }
}

export type LoadProtocolsModel = LoadProtocols.Model
