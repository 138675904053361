import styled from 'styled-components'
import { ThemeColorsOptions } from '../../shared/components/MUI'

type WrapperProps = {
  backgroundColor?: ThemeColorsOptions
  bottom?: number | string
  slideFullHeight?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;

  .swiper {
    height: ${({ slideFullHeight }) => (slideFullHeight ? '100%' : 'auto')};
  }

  .swiper-pagination {
    bottom: ${({ bottom }) => bottom};
  }

  .swiper-pagination-bullet-active {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ? backgroundColor : theme.colors.primary};
  }
`
