import { PasswordMaxDateValidationStatus } from 'common/enum/password-max-date-validation-status'
import theme from 'presentation/styles/theme'
import styled, { css } from 'styled-components'

type SituationStatus = {
  status: PasswordMaxDateValidationStatus
}

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CardFooter = styled.div<{
  color?: keyof typeof theme.ds.colors
}>`
  /* border-top: 1px solid ${({ theme }) => theme.ds.colors.neutral200}; */
  background-color: ${({ theme }) => theme.ds.colors.white};
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px -16px -16px -16px;
  padding: 10px 16px;
  svg {
    path {
      fill: ${({ theme, color = 'terciary400' }) => theme.ds.colors[color]};
    }
  }
`
export const BadgeCounter = styled.div<{
  color?: keyof typeof theme.ds.colors
}>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme, color = 'terciary400' }) =>
    theme.ds.colors[color]};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AlertIconWrapper = styled.span<SituationStatus>`
  ${({ theme, status }) => css`
    display: flex;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      path {
        fill: ${status === PasswordMaxDateValidationStatus.EXPIRED
          ? theme.ds.colors.danger500
          : theme.ds.colors.attention500};
      }
    }
  `}
`
