import React, { useRef } from 'react'

import BottomMenu from 'presentation/components/BottomMenu'
// import Heading from 'presentation/shared/components/Heading'

import * as S from './styles'
import { Container } from 'presentation/components/Container'
import Header from 'presentation/components/Header'
// import SupportText from 'presentation/shared/components/SupportText'
import List from 'presentation/shared/components/List'

export default function HelpLayout() {
  const phoneNumberRef = useRef<HTMLAnchorElement>(null)

  return (
    <>
      <Header />
      <Container
        title="Ajuda Mater Dei"
        subtitle="Estamos disponíveis, entre em contato:"
      >
        <S.Wrapper>
          <List
            size="medium"
            padding={false}
            items={[
              {
                title: (
                  <>
                    <meta name="format-detection" content="telephone=yes" />
                    <a
                      ref={phoneNumberRef}
                      href="tel:313339-9000"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ligar para a Mater Dei
                    </a>
                  </>
                ),
                click: () => {
                  phoneNumberRef.current?.click()
                },
                icon: (
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: '9px' }}
                  >
                    <circle cx="18" cy="18" r="18" fill="white" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.3871 12.8347C11.6368 12.4297 13.0128 10.9565 13.9946 11.001C14.2882 11.0248 14.5476 11.1987 14.7584 11.4005C15.2425 11.8635 16.6281 13.6146 16.7068 13.9831C16.8981 14.8869 15.7987 15.4079 16.1352 16.3188C16.993 18.375 18.4711 19.8227 20.5711 20.6622C21.5002 20.9918 22.0321 19.9149 22.9548 20.1031C23.3302 20.1801 25.1189 21.5374 25.5916 22.0115C25.7967 22.2172 25.9751 22.4721 25.9994 22.7596C26.0359 23.7722 24.4386 25.139 24.1272 25.3137C23.3927 25.8283 22.4343 25.8196 21.2659 25.2875C18.0057 23.9588 12.7955 18.9515 11.4139 15.6374C10.8852 14.4994 10.8487 13.5543 11.3871 12.8347Z"
                      stroke="#008E82"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )
              }
            ]}
          />
        </S.Wrapper>
      </Container>
      <BottomMenu />
    </>
  )
}
