import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'

import { RecommendationRepository } from 'repository/repositories/recommendation/recommendation-repository'
import { RemoteAddRecommendation } from 'service/usecases/add-recommendation/remote-add-recommendation'
import RecommendationService from 'presentation/contexts/services/recommendation-service'
import { RemoteLoadDoctorRecommendations } from 'service/usecases/load-doctor-recommendations/remote-load-doctor-recommendations'
import { RemoteLoadRecommendationByInviteToken } from 'service/usecases/load-recommendation-by-invite-token/remote-load-recommendation-by-invite-token'
import { RemoteUpdateRecommendation } from 'service/usecases/update-recommendation/remote-update-recommendation'
import { RemoteCreateRecommendationWithoutDoctor } from 'service/usecases/create-recommendation-without-doctor/remote-create-recommendation-without-doctor'

export default function makeRecommendationService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const recommendationRepository = new RecommendationRepository(apiRepository)

  const addRecommendation = new RemoteAddRecommendation(
    recommendationRepository
  )

  const createRecommendationWithoutDoctor =
    new RemoteCreateRecommendationWithoutDoctor(recommendationRepository)

  const loadDoctorRecommendations = new RemoteLoadDoctorRecommendations(
    recommendationRepository
  )
  const loadRecommendationByInviteToken =
    new RemoteLoadRecommendationByInviteToken(recommendationRepository)

  const updateRecommendation = new RemoteUpdateRecommendation(
    recommendationRepository
  )

  return new RecommendationService({
    addRecommendation,
    createRecommendationWithoutDoctor,
    loadDoctorRecommendations,
    loadRecommendationByInviteToken,
    updateRecommendation
  })
}
