import theme from 'presentation/styles/theme'
import styled from 'styled-components'

export const StepsContent = styled.div`
  display: flex;
  padding: 24px;
  flex-wrap: wrap;
  gap: 24px;
`

interface StepsButtonProps {
  status?: 'completed' | 'actual' | 'disabled'
}

const statusContent = {
  completed: {
    border: `2px solid ${theme.colors.secondary}`,
    color: theme.colors.secondary,
    statusColor: theme.colors.secondary,
    borderIcon: `2px solid ${theme.colors.secondary}`
  },
  actual: {
    border: `2px solid ${theme.colors.darkBlue}`,
    color: theme.colors.gray,
    statusColor: theme.colors.darkBlue,
    borderIcon: `2px solid ${theme.colors.darkBlue}`
  },
  disabled: {
    border: `0px`,
    color: theme.colors.gray,
    statusColor: theme.colors.lightGray,
    borderIcon: `2px solid ${theme.colors.lightestGray}`
  }
}

export const StepsButton = styled.div<StepsButtonProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ status }) => status && statusContent[status].border};
  color: ${({ status }) => status && statusContent[status].color};
  border-radius: ${({ theme }) => theme.border.radius.small};
  height: auto;
  width: 100%;
  padding: 24px;
  padding-left: 29px;
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
  flex-wrap: wrap;
  gap: 5px;
  margin-left: 16px;

  div div {
    font-size: ${({ theme }) => theme.font.sizes.xxsmall};
    color: ${({ status }) => status && statusContent[status].statusColor};
  }
`

export const StepsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StepsIcon = styled.div<StepsButtonProps>`
  height: 32px;
  width: 32px;
  background-color: ${({ status, theme }) =>
    status === 'completed' ? theme.colors.secondary : theme.colors.white};
  border: ${({ status }) => status && statusContent[status].borderIcon};
  color: ${({ status }) => status && statusContent[status].statusColor};
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: -16px;
  right: 0;
  bottom: 0;
  margin: auto 0;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.sizes.medium};
  font-weight: ${({ theme }) => theme.font.semiBold};
`
