export function handleUnityPhone(id?: number) {
  switch (id) {
    case 1:
      return '(31) 3339-1902'
    case 6:
      return '(31) 3401-7891'
    case 7:
      return '(31) 3512-2393'
    default:
      return '(31) 3339-1902'
  }
}
