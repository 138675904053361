import React, { useEffect, useState } from 'react'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import ExamsResultsLayout from 'presentation/layouts/ExamsResults'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { ExamResultModel } from 'domain/entities/exam-result-model'

const ExamsResults = WithLoading(({ setIsLoading }: WithLoadingProps) => {
  const patientService = useServices().patient
  const [exams, setExams] = useState<ExamResultModel[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const examResult = await patientService.listExamResult()

        if (!examResult) {
          throw new Error()
        }

        setExams(examResult)
      } catch (error) {
        toast.error('O Paciente não tem registros de exames.')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])

  return <ExamsResultsLayout exams={exams} />
})

export default ExamsResults
