import styled from 'styled-components'

export const Wrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;

  .swiper {
    height: 100%;
  }
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Content = styled.div`
  flex: 1;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
`

export const Buttons = styled.div`
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding-bottom: 24px;
`
