export default {
  border: {
    radius: {
      xsmall: '6px',
      small: '10px',
      medium: '15px',
      large: '20px',
      rounded: '50%'
    }
  },
  font: {
    family:
      "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    black: 700,
    sizes: {
      xxxsmall: '0.625em',
      xxsmall: '0.75em',
      xsmall: '0.875em',
      small: '1em',
      medium: '1.125em',
      large: '1.5em',
      xlarge: '2em',
      xxlarge: '3em',
      xxxlarge: '3.5em'
    }
  },
  colors: {
    primary: '#038E83',
    lightPrimary: '#E8F7E9',
    primaryDarker: '#026960',
    secondary: '#4ABFA1',
    lightSecondary: '#5ACA65',
    lightestPink: '#EDF1ED',
    alert: '#F48989',
    mainBg: '#F1F9F9',
    white: '#FFFFFF',
    success: '#1BD15D',
    neutralWhite: '#FBFBFB',
    neutralGray: '#a8a8a8',
    neutralBlack: '#282828',
    black: '#000000',
    gray: '#4A4A4A',
    lightGreen: '#81BB81',
    softGray: '#B0B4BB',
    lightGray: '#9B9B9B',
    lightestGray: '#E5E5E5',
    green: '#125F1C',
    darkGreen: '#03601C',
    moreLightGreen: '#E3EEE2',
    softGreen: '#EBF4F4',
    lightestGreen: '#A5D8AC',
    blueishGreen: '#00A499',
    lightBlueishGreen: '#4db6b1',
    lightestBlueishGreen: '#eff8f8',
    blue: '#3BA3F4',
    bluishGreen: '#06D7CB',
    darkBlue: '#3F3F9E',
    pink: '#F27C9B',
    lightPink: '#FEEFEF',
    red: '#E31A0B',
    lightRed: '#FA7676',
    moreLightRed: '#FFDADA',
    lightOrange: '#F5AA77',
    salmon: '#FF6341',
    orange: '#F1904E',
    purple: '#BA509D',
    darkPurple: '#6A2297',
    greyishBlue: '#3F6F9E',
    golden: '#D1B41B',
    yellow: '#F6BC26',
    violet: '#9E3F9E',
    brightOrange: '#FF8F39',
    mediumGray: '#888888',
    medGray: '#c6c6c6',
    emeraldGreen: '#00CFAA',
    info: '#2F67BA'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 9999
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  },

  // DESIGN SYSTEM VARIABLES
  ds: {
    typography: {
      fontFamily:
        "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      size: {
        xxsmall: '0.75em',
        xsmall: '0.875em',
        small: '1em',
        medium: '1.125em',
        large: '1.25em',
        xlarge: '1.5em',
        xxlarge: '2em',
        xxxlarge: '3em'
      },
      weight: {
        thin: 100,
        light: 300,
        normal: 400,
        semiBold: 500,
        bold: 600,
        black: 700
      },
      lineHeight: {
        xxsmall: '0.75em',
        xsmall: '0.875em',
        small: '1em',
        medium: '1.5em',
        large: '1.75em',
        xlarge: '2em',
        xxlarge: '3em',
        xxxlarge: '4em'
      },
      border: {
        radius: {
          xsmall: '6px',
          small: '8px',
          medium: '16px',
          large: '24px',
          rounded: '50%'
        }
      }
    },
    colors: {
      transparent: 'transparent',
      white: '#FFFFFF',
      black: '#000000',
      primaryLight25: '#F2FFFC',
      primaryLight50: '#B3FFF4',
      primaryLight100: '#72F8E8',
      primaryLight200: '#52DBCC',
      primary25: '#F0F9F8',
      primary50: '#E1F3F2',
      primary100: '#B3E1DE',
      primary200: '#82CEC9',
      primary300: '#4FBAB3',
      primary400: '#28ABA1',
      primary500: '#049B90',
      primary600: '#038E83',
      primary700: '#037E72',
      primary800: '#036E63',
      primary900: '#2D2F34',
      secondary25: '#FCF5FF',
      secondary50: '#FAECFF',
      secondary100: '#F1DAFF',
      secondary200: '#DFB7FF',
      secondary300: '#CC91FF',
      secondary400: '#B177E3',
      secondary500: '#955DC7',
      secondary600: '#7B43AC',
      secondary700: '#622992',
      secondary800: '#49077A',
      secondary900: '#2D004F',
      terciary25: '#FFF8EF',
      terciary50: '#FFF2DF',
      terciary100: '#FFDDB0',
      terciary200: '#FFC77D',
      terciary300: '#FFAF49',
      terciary400: '#FF9E22',
      terciary500: '#FF8D00',
      terciary600: '#FA8100',
      terciary700: '#F47100',
      terciary800: '#EE6002',
      terciary900: '#E54304',
      neutral25: '#FBFBFB',
      neutral50: '#F6F6F7',
      neutral100: '#EDEDEF',
      neutral200: '#D1D3D7',
      neutral300: '#B0B4BB',
      neutral400: '#90959F',
      neutral500: '#707683',
      neutral600: '#5F646F',
      neutral700: '#4E535C',
      neutral800: '#3E4148',
      neutral900: '#2D2F34',
      success25: '#F3FAF4',
      success50: '#E8F5E9',
      success100: '#C8E6C9',
      success200: '#A5D6A7',
      success300: '#81C784',
      success400: '#66BB6A',
      success500: '#4CAF50',
      success600: '#43A047',
      success700: '#388E3C',
      success800: '#2E7D32',
      success900: '#1B5E20',
      info25: '#F1F8FE',
      info50: '#E3F2FD',
      info100: '#BBDEFB',
      info200: '#90CAF9',
      info300: '#64B5F6',
      info400: '#42A5F5',
      info500: '#2196F3',
      info600: '#1E88E5',
      info700: '#1976D2',
      info800: '#1565C0',
      info900: '#0D47A1',
      warning100: '#FAEAAF',
      warning200: '#F3D044',
      warning300: '#F1C40F',
      warning400: '#F0BA00',
      attention25: '#FFFBF0',
      attention50: '#FFF8E1',
      attention100: '#FFECB3',
      attention200: '#FFE082',
      attention300: '#FFD54F',
      attention400: '#FFCA28',
      attention500: '#FFC107',
      attention600: '#FFB300',
      attention700: '#FFA000',
      attention800: '#FF8F00',
      attention900: '#FF6F00',
      danger25: '#FFF5F7',
      danger50: '#FFEBEE',
      danger100: '#FFCDD2',
      danger200: '#EF9A9A',
      danger300: '#E57373',
      danger400: '#EF5350',
      danger500: '#F44336',
      danger600: '#E53935',
      danger700: '#D32F2F',
      danger800: '#C62828',
      danger900: '#B71C1C'
    },
    shadows: {
      none: 'none',
      xsatin:
        '0px 1px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',
      satin:
        '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 2px 6px 2px rgba(0, 0, 0, 0.12)',
      normal:
        '0px 1px 3px rgba(0, 0, 0, 0.24), 0px 4px 8px 3px rgba(0, 0, 0, 0.12)',
      deep: '0px 6px 10px 4px rgba(0, 0, 0, 0.12), 0px 2px 3px rgba(0, 0, 0, 0.24)',
      xdeep:
        '0px 8px 12px 6px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24)'
    }
  }
} as const
