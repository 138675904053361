import React, { useEffect, useState } from 'react'
import ButtonNew from 'presentation/components/ButtonNew'
import getFullDependentsList from 'presentation/utils/fetch-data/get-full-dependents-list'
import { useStores } from 'presentation/hooks/use-stores'
import * as S from './styles'
import { ReactComponent as NoExamSchedule } from 'presentation/assets/empty-states/no-exam-schedule.svg'
import SelectFieldNew from 'presentation/shared/components/SelectFieldNew'
import { useHistory } from 'react-router-dom'
import {
  NavigationProps,
  AppointmentExamType,
  AppointmentExamItemType
} from '../utils'
import CancelExamSchedule from '../CancelExamSchedule'
import moment from 'moment'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { NumberLocale } from 'yup/lib/locale'
import { ReactComponent as RedAlertIcon } from 'presentation/assets/icons/small-red-alert.svg'
import { ContainerNew } from 'presentation/components/ContainerNew'
import { ExamSearchType } from 'domain/entities/exam-model-new'
import { SurgeryType, SurgeryTypeLocated } from 'common/enum/surgery-type'

type ModalCancelProps = {
  showCancelExam: boolean
  setShowCancelExam: (open: boolean) => void
  setIsLoading: (value: boolean) => void
  isLoading?: boolean
  slideToReschedule: () => void
  activeIndex: NumberLocale
  hasParamToken?: boolean
}

const ScheduleExams = ({
  setIsLoading,
  isLoading,
  next,
  activeIndex,
  showCancelExam,
  setShowCancelExam,
  slideToReschedule,
  hasParamToken
}: NavigationProps & ModalCancelProps) => {
  const currentAccount = useStores().currentAccount
  const user = currentAccount.getCurrentAccount().user
  const dependents = getFullDependentsList(user)
  const [selectedExamToCancel, setSelectedExamToCancel] = useState() as any

  const [examAppointments, setExamAppointments] = useState([]) as any
  const patientService = useServices().patient
  const examService = useServices().exam

  async function loadPatient() {
    return await patientService.loadPatientInfo(['patient_id'])
  }

  const [pageNumber, setPageNumber] = useState(0)
  const [pageLimit, setPageLimit] = useState(null) as any
  const [totalItems, setTotalItems] = useState(null) as any
  const [itemsPerPage, setItemsPerPage] = useState(null) as any

  async function loadExamAppointments() {
    try {
      setIsLoading(true)
      const { patient_id } = await loadPatient()
      const patientExamAppointments =
        await examService.getPatientExamAppointments({
          pageNumber: pageNumber + 1,
          pageSize: 2,
          patient_id: patient_id
        })

      if (examAppointments.length === 0) {
        setExamAppointments(patientExamAppointments?.appointments)
      } else {
        if (pageNumber === 0) {
          setExamAppointments([...patientExamAppointments?.appointments])
        } else
          setExamAppointments([
            ...examAppointments,
            ...patientExamAppointments?.appointments
          ])
      }
      setPageNumber(patientExamAppointments.page_info.current_page)
      setPageLimit(patientExamAppointments.page_info.total_pages)
      setTotalItems(patientExamAppointments.page_info.total_items)
      setItemsPerPage(patientExamAppointments.page_info.items_per_page)
    } catch (error) {
      toast.error('Falha na busca dos agendamentos')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if ((!activeIndex || activeIndex === 0) && !showCancelExam) {
      loadExamAppointments()
    }
  }, [showCancelExam, activeIndex])

  const handleChangeDependent = (user_id: number) => {
    if (user_id !== user.user_id) {
      setIsLoading(true)
      currentAccount
        .setCurrentDependent(user_id)
        .then(() => {
          window.location.reload()
        })
        .catch((e: any) => {
          console.log(e.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  function handleNext() {
    next()
  }

  return (
    <ContainerNew noHeader>
      {showCancelExam ? (
        <>
          <div style={{ height: '90vh' }}>
            <CancelExamSchedule
              setShowCancelExam={setShowCancelExam}
              selectedExamToCancel={selectedExamToCancel}
              showCancelExam={showCancelExam}
              slideToReschedule={slideToReschedule}
              setIsLoading={setIsLoading}
            />
          </div>
        </>
      ) : (
        <>
          {!hasParamToken && (
            <SelectFieldNew
              round
              name="current_user"
              items={dependents?.map(({ name, user_id }) => ({
                label: name,
                value: user_id
              }))}
              value={user.user_id}
              onInputChange={(e) => handleChangeDependent(Number(e))}
            />
          )}

          <S.TitleContainer>
            <S.TitleInfo>
              <S.TitleStrong>Agendamento de Exames</S.TitleStrong>
              <S.SubTitle>Acompanhe seus exames agendados</S.SubTitle>
            </S.TitleInfo>
            <ButtonNew
              size="large"
              fullWidth
              onClick={handleNext}
              style={{ marginTop: '24px' }}
            >
              Agendar exames
            </ButtonNew>
          </S.TitleContainer>
          {!isLoading && examAppointments?.length === 0 && (
            <NoExamSchedule style={{ display: 'flex', margin: 'auto' }} />
          )}

          {examAppointments?.length > 0 &&
            examAppointments.map((appointmentExam: AppointmentExamType) => {
              return (
                <ExamCard
                  key={appointmentExam.id}
                  exam={appointmentExam}
                  setSelectedExamToCancel={setSelectedExamToCancel}
                  setShowCancelExam={setShowCancelExam}
                  setIsLoading={setIsLoading}
                  setPageNumber={setPageNumber}
                  hasParamToken={hasParamToken}
                />
              )
            })}

          {/* {examAppointments?.length > 0 && pageNumber >= pageLimit && (
            <S.ButtonContainer>
              <ButtonNew disabled size="large" fullWidth>
                Fim
              </ButtonNew>
            </S.ButtonContainer>
          )} */}

          {((examAppointments?.length > 0 && pageNumber < pageLimit) ||
            (pageNumber === 1 && totalItems > itemsPerPage)) && (
            <S.ButtonContainer>
              <ButtonNew onClick={loadExamAppointments} size="large" fullWidth>
                Ver mais
              </ButtonNew>
            </S.ButtonContainer>
          )}
        </>
      )}
    </ContainerNew>
  )
}

export default ScheduleExams

type ScheduledExamCardProps = {
  exam: AppointmentExamType
  setShowCancelExam: (show: boolean) => void
  setIsLoading: (value: boolean) => void
  setSelectedExamToCancel: (examToCancel: AppointmentExamType) => void
  setPageNumber: (value: number) => void
  hasParamToken?: boolean
}

const ExamCard = ({
  exam,
  setShowCancelExam,
  setSelectedExamToCancel,
  setIsLoading,
  setPageNumber,
  hasParamToken
}: ScheduledExamCardProps) => {
  const history = useHistory()
  const examService = useServices().exam

  async function handleRedirect(appointmentExams: any) {
    const filteredExams = appointmentExams.items?.map(
      (item: AppointmentExamItemType) => {
        return {
          exam_id: item.exam.exam_id,
          name: item.exam.name
        }
      }
    )

    setIsLoading(true)
    const response = await examService.getExamPreparation({
      exams: filteredExams,
      hospital_id: +appointmentExams.items[0].unit.hospital_id
    })

    if (response?.data?.length > 0) {
      history.push('/exames/preparos/instrucoes', {
        prevPage: location.pathname,
        selectedExam: response.data,
        hasParamToken: hasParamToken
      })
    } else {
      toast.error('Instruções de preparo do exame não encontradas')
    }
    setIsLoading(false)
  }

  async function handleRedirectSurgicalProcedure(exam: AppointmentExamType) {
    history.push('/pedido-cirurgico/detalhes', {
      surgical_order_id: exam.id
    })
  }

  function handleCancelExam() {
    setPageNumber(0)
    setShowCancelExam(true)
    setSelectedExamToCancel(exam)
  }

  const examName = () => {
    if (exam.type === ExamSearchType.SURGERY) {
      return SurgeryTypeLocated[exam?.items[0]?.exam?.name as SurgeryType]
    }

    if (exam?.items?.length > 1) {
      const examNames = exam?.items.map((item) => {
        return item?.exam?.name
      })

      return examNames.join(',').split(',').join(' + ')
    }

    return exam?.items[0]?.exam?.name
  }

  return (
    <S.CardContainer>
      <S.CardTitle>{examName() || '-'}</S.CardTitle>

      {exam?.items[0]?.doctor && (
        <>
          <S.CardLabel>Médico</S.CardLabel>
          <S.CardTextValue>{exam?.items[0]?.doctor?.name}</S.CardTextValue>
        </>
      )}
      <S.CardLabel>Unidade de atendimento</S.CardLabel>
      <S.CardTextValue>{exam?.items[0].unit.name}</S.CardTextValue>

      {exam.type === ExamSearchType.EXAM && (
        <>
          <S.CardLabel>Próxima data </S.CardLabel>
          <S.CardTextValue>
            {moment
              .utc(exam?.items[0].appointment_date)
              .format(' dddd | DD/MM/YYYY [às] HH[h]mm')}
          </S.CardTextValue>
        </>
      )}

      {!!exam?.use_sedation && (
        <>
          <S.AlertContainer>
            <RedAlertIcon />
            <S.SubTitleRed>
              Antes de realizar o exame é necessário agendar uma consulta
              pré-anestésica.
            </S.SubTitleRed>
          </S.AlertContainer>
        </>
      )}

      {exam.type === ExamSearchType.EXAM && (
        <>
          <ButtonNew onClick={() => handleRedirect(exam)}>
            Ver instruções de preparo
          </ButtonNew>
          <ButtonNew outlined onClick={handleCancelExam}>
            Não posso ir
          </ButtonNew>
        </>
      )}

      {exam.type === ExamSearchType.SURGERY && (
        <ButtonNew onClick={() => handleRedirectSurgicalProcedure(exam)}>
          Ver solicitação de procedimento
        </ButtonNew>
      )}
    </S.CardContainer>
  )
}
