import styled from 'styled-components'
import CardNew from 'presentation/shared/components/CardNew'
import theme from 'presentation/styles/theme'

export const StatusCard = styled(CardNew)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    height: 14px;
    width: 14px;
    path {
      fill: ${({ theme }) => theme.ds.colors.secondary700};
    }
  }
`

export const Timeline = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const TimelineStep = styled.div<{ color: keyof typeof theme.ds.colors }>`
  position: relative;
  margin-bottom: 24px;
  width: 200px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${({ theme, color = 'secondary400' }) =>
      theme.ds.colors[color]};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -23px;
    top: calc(50% + 12px);
    width: 2px;
    height: calc(100%);
    /* transform: translateY(-50%); */
    background-color: ${({ theme, color = 'secondary400' }) =>
      theme.ds.colors[color]};
  }
  &:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
`
