import styled from 'styled-components'

export const SelectUnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px 20px 20px;
  justify-content: space-between;
  height: 100%;

  strong {
    margin-bottom: 15px;
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.gray};
  }
`

export const ActualPage = styled.div`
  margin-top: 14px;
  padding: 10px 0px 20px;
`

export const SelectUnitWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div > select {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

export const SelectUnitChips = styled.div`
  display: flex;
  flex-direction: column;

  div div label {
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${({ theme }) => theme.font.semiBold};
    font-size: ${({ theme }) => theme.font.sizes.xsmall};
    color: ${({ theme }) => theme.colors.primary};
    background: transparent;
  }
`

export const SelectUnitChipsGrid = styled.div`
  display: grid;
  grid: auto / 1fr 1fr;
  column-gap: 16px;
  row-gap: 24px;
`
