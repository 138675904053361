import { ClassProps } from 'common/utils/getClassProps'
import { Type } from 'class-transformer'

export class DoctorRegisterReview {
  doctor_register_review_id!: number
  text!: string
  isSolved!: boolean
  created_by_id?: number
  doctor_register_step_id?: number
  @Type(() => Date)
  createdAt?: Date
  @Type(() => Date)
  updatedAt?: Date

  constructor(props?: ClassProps<DoctorRegisterReview>) {
    Object.assign(this, props)
  }
}
