import React from 'react'
import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'

import * as S from './styles'
import { useHistory } from 'react-router-dom'

export default function InvalidRegisterLayout() {
  const history = useHistory()
  return (
    <Container>
      <S.Wrapper>
        <Heading as="h1" size="large">
          Seus dados não foram validados
        </Heading>
        <S.Content>
          <SupportText>
            Para liberarmos seu acesso envie para{' '}
            <strong>gestaodeleitos@materdei.com.br</strong> os seguintes
            documentos:
          </SupportText>
          <SupportText>
            <strong>
              um documento oficial com foto email que deseja atualizar.
            </strong>
          </SupportText>
          <SupportText>
            Em até 2 dias úteis retornaremos no email indicado e você poderá
            prosseguir com o cadastro na solução.
          </SupportText>
        </S.Content>
        <Button type="button" fullWidth onClick={() => history.push('/login')}>
          Fechar
        </Button>
      </S.Wrapper>
    </Container>
  )
}
