import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'

export const MenuDrawer = styled(Drawer)`
  /* z-index: ${({ theme }) => theme.layers.base - 1} !important; */
  .MuiDrawer-paper {
    border-radius: 16px 16px 0 0;
    padding: 24px;
    font-family: ${({ theme }) => theme.font.family};
  }
`

export const DrawerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  vertical-align: middle;
`

export const DrawerBody = styled.div`
  padding: 24px 0;
  font-size: 16px;
`

export const DrawerFooter = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Divider = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: #dadee2;
`

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 12px;
`
