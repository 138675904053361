import { GetEmergencyRoomByToken } from 'domain/usecases/emergency-room/get-emergency-room-by-token'
import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'

export class RemoteGetEmergencyRoomByToken implements GetEmergencyRoomByToken {
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  get(
    params: GetEmergencyRoomByToken.Params
  ): Promise<GetEmergencyRoomByToken.Model> {
    return this.emergencyRoomRepository.getEmergencyRoomByToken(params)
  }
}
