import { GetSchedulesByMonth } from 'domain/usecases/scheduling/get-schedules-by-month'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteGetSchedulesByMonth implements GetSchedulesByMonth {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(params: GetSchedulesByMonth.Params): Promise<GetSchedulesByMonth.Model> {
    return this.schedulingRepository.getSchedulesByMonth(params)
  }
}

export type SearchDoctorsModel = GetSchedulesByMonth.Model
