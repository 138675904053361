import { GetExamAvailableDates } from 'domain/usecases/exam/get-exam-available-dates'
import { ExamRepository } from 'repository/interfaces/exam-repository'

export class RemoteGetExamAvailableDates implements GetExamAvailableDates {
  constructor(private readonly examRepository: ExamRepository) {}
  async load(
    params: GetExamAvailableDates.Params
  ): Promise<GetExamAvailableDates.Model> {
    return this.examRepository.getExamAvailableDates(params)
  }
}

export type GetExamAvailableDatesModel = GetExamAvailableDates.Model
