import React from 'react'

import Modal from 'presentation/shared/components/Modal'
import Button from 'presentation/components/Button'

import * as S from './styles'

export type FileToView = {
  contentType: string
  fileUrl: string
}

type FileViewerProps = {
  file: FileToView
  show: boolean
  onClose(): void
}

const FileViewer = ({ file, show, onClose }: FileViewerProps) => {
  const getViewerByType = () => {
    if (isImageFile()) {
      return <S.ImageView data-testid="image-view" src={file.fileUrl} />
    } else if (isPDFfile()) {
      return <S.PdfView data-testid="pdf-view" src={file.fileUrl} />
    }
  }

  const isImageFile = (): boolean => {
    return !!file.contentType.match(/(jpg|jpeg|png|gif)$/i)
  }

  const isPDFfile = (): boolean => {
    return !!file.contentType.match(/(pdf)$/i)
  }

  return (
    <S.Wrapper>
      <Modal
        data-testid="modal-file-viewer"
        close={() => onClose()}
        show={show}
        preventAutomateClose
        style={{
          height: '80vh',
          width: '70vw',
          padding: '12px',
          position: 'relative'
        }}
      >
        <>
          <S.CloseButtonContainer>
            <Button type="button" onClick={() => onClose()} variant="outlined">
              Fechar
            </Button>
          </S.CloseButtonContainer>
          {getViewerByType()}
        </>
      </Modal>
    </S.Wrapper>
  )
}

export default FileViewer
