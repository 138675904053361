import React from 'react'

import SchedulingNpsLayout from 'presentation/layouts/SchedulingNpsLayout'
import { useServices } from 'presentation/hooks/use-services'
import { useStores } from 'presentation/hooks/use-stores'
import { toast } from 'react-toastify'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

type SchedulingNpsProps = {
  campaignFeature?: string
}

const SchedulingNps = WithLoading(
  ({
    setIsLoading,
    campaignFeature
  }: WithLoadingProps & SchedulingNpsProps) => {
    const tracksale = useServices().tracksale
    const currentAccount = useStores().currentAccount
    const { email, name, user_id } = currentAccount.getCurrentAccount().user

    const sendRate = async ({ rate, reasons, suggestion = '' }: Params) => {
      try {
        setIsLoading(true)
        // const campaign_code =
        //   process.env.REACT_APP_TRACKSALE_CAMPAING_CODE_SCHEDULING_NPS || ''
        let campaign_code

        if (campaignFeature === 'exam-schedule') {
          campaign_code =
            process.env.NODE_ENV === 'production'
              ? process.env
                  .REACT_APP_TRACKSALE_CAMPAING_CODE_SCHEDULING_EXAM_NPS_PRODUCAO ||
                ''
              : process.env
                  .REACT_APP_TRACKSALE_CAMPAING_CODE_SCHEDULING_EXAM_NPS_DEVELOP ||
                ''
        } else {
          campaign_code =
            process.env.NODE_ENV === 'production'
              ? process.env
                  .REACT_APP_TRACKSALE_CAMPAING_CODE_SCHEDULING_NPS_PRODUCAO ||
                ''
              : process.env
                  .REACT_APP_TRACKSALE_CAMPAING_CODE_SCHEDULING_NPS_DEVELOP ||
                ''
        }

        await tracksale.answerNps({
          campaign_code,
          answers: [
            {
              name: name,
              email: email,
              identification: String(user_id),
              score: rate,
              justification: suggestion,
              tags: reasons
            }
          ]
        })

        toast.success('Avaliação enviada com sucesso!')
      } catch (error: any) {
        toast.error('Houve um erro ao enviar sua avaliação')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <SchedulingNpsLayout
        sendRate={sendRate}
        campaignFeature={campaignFeature}
      />
    )
  }
)

export default SchedulingNps

type Params = {
  rate: number
  reasons: {
    name: string
    value: boolean
  }[]
  suggestion: string
}
