import { GetHospitalsByHealthInsurance } from 'domain/usecases/hospital/get-hospitals-by-health-insurance'
import { makeAutoObservable } from 'mobx'

type Services = {
  getHospitalsByHealthInsurance: GetHospitalsByHealthInsurance
}

export class HospitalService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async getHospitalsByHealthInsurance(
    params: GetHospitalsByHealthInsurance.Params
  ): Promise<GetHospitalsByHealthInsurance.Model> {
    return this.services.getHospitalsByHealthInsurance.get(params)
  }
}

export default HospitalService
