import { ResolveDoctorRegisterPendencies } from 'domain/usecases/overall-registration-status/resolve-doctor-register-pendencies'
import { OverallRegistrationStatusRepository } from 'repository/repositories/overall-registration-status/overall-registration-status-repository'

export class RemoteResolveDoctorRegisterPendencies
  implements ResolveDoctorRegisterPendencies
{
  constructor(
    private readonly registrationStatusRepository: OverallRegistrationStatusRepository
  ) {}

  resolveDoctorRegisterPendencies(
    overall_registration_status_id: number
  ): Promise<ResolveDoctorRegisterPendencies.Model> {
    return this.registrationStatusRepository.resolveDoctorRegisterPendencies(
      overall_registration_status_id
    )
  }
}
