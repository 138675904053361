import styled from 'styled-components'

export const EditPatientInfoWrapper = styled.div`
  text-align: start !important;

  input {
    background: #f0f6ef;
  }
`
export const SupportText = styled.p`
  margin-bottom: 20px;
`
