import { makeObservable, action } from 'mobx'

type Notification = {
  notification_id: number
  user_id: number
  content: string
  attributes: {
    patient_name: string
    surgical_order_id?: number
    pendency_id?: number
    current: string
  }
  type: string
  active: boolean
  created_at: string
  updated_at: string
}

class DoctorPendencyStore {
  doctorPendency = [] as any
  doctorNotification = {} as Notification

  constructor() {
    makeObservable<any>(this, {
      setDoctorPendency: action
    })
  }

  setDoctorPendency(doctorPendency: any) {
    this.doctorPendency = doctorPendency
  }

  setDoctorNotification(doctorNotification: any) {
    this.doctorNotification = doctorNotification
  }

  clearDoctorPendency() {
    this.doctorPendency = []
  }
}

export default new DoctorPendencyStore()
