import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  font-size: ${({ theme }) => theme.font.sizes.small};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 12px;
  cursor: pointer;
`

export const Input = styled.input`
  display: none;
`

type InputLabelProps = {
  checked?: boolean
}

export const InputLabel = styled.label<InputLabelProps>`
  cursor: pointer;
  padding: 8px;
  background-color: white;
  border-radius: ${({ theme }) => theme.border.radius.large};
  font-size: ${({ theme }) => theme.font.sizes.medium};
  color: ${({ theme, checked }) =>
    !checked ? theme.colors.gray : theme.colors.secondary};
  border: ${({ theme, checked }) =>
    '1px solid ' + (checked ? theme.colors.secondary : theme.colors.primary)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`

export const InputsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.lightRed};
  font-size: ${({ theme }) => theme.font.sizes.xsmall};
`
