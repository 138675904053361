import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import HospitalService from 'presentation/contexts/services/hospital-service'
import { ApiRepository } from 'repository/api-repository'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteGetHospitalsByHealthInsurance } from 'service/usecases/get-hospitals-by-health-insurance/remote-get-hospitals-by-health-insurance'

export default function makeHospitalService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const hospitalRepository = new HospitalRepository(apiRepository)

  const getHospitalsByHealthInsurance = new RemoteGetHospitalsByHealthInsurance(
    hospitalRepository
  )

  return new HospitalService({
    getHospitalsByHealthInsurance
  })
}
