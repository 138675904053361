import React, { useEffect, useState } from 'react'
import HealthInsurancesLayout from 'presentation/layouts/HealthInsurancesList'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'

type Props = {
  loadHealthInsurances: LoadPatientInfo
} & WithLoadingProps

const HealthInsurancesList = WithLoading(
  ({ loadHealthInsurances, setIsLoading }: Props) => {
    const [healthInsurances, setHealthInsurances] =
      useState<PatientHealthInsurance[]>()

    useEffect(() => {
      async function loadData() {
        setIsLoading(true)
        try {
          const response = await loadHealthInsurances.load([
            `
              healthInsurances {
                health_insurance_id
                healthCard
                healthInsuranceCode
                healthInsuranceName
                healthPlanCode
                healthPlanName
                healthSubPlanCode
                healthSubPlanName
                ansRegister
                validThru
              }
            `
          ])

          setHealthInsurances(response.healthInsurances)
        } catch (err: any) {
          toast.error('Nao foi possível carregar os planos de saúde')
        } finally {
          setIsLoading(false)
        }
      }

      loadData()
    }, [])

    return <HealthInsurancesLayout healthInsurances={healthInsurances} />
  }
)

export default HealthInsurancesList
