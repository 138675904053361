import styled, { css, DefaultTheme } from 'styled-components'
import { SelectFieldProps } from '.'

type WrapperProps = Pick<SelectFieldProps, 'disabled'> & {
  error?: boolean
}
type SelectProps = Pick<SelectFieldProps, 'bgColor' | 'round'>
type LabelProps = Pick<SelectFieldProps, 'labelColor'>

type AsteriskProps = Pick<SelectFieldProps, 'requiredColor'>

export const LabelWrapper = styled.div`
  display: flex;
`

export const Label = styled.label<LabelProps>`
  ${({ theme, labelColor }) => css`
    color: ${theme.ds.colors[labelColor!]};
    font-size: ${theme.ds.typography.size.small};
    font-weight: ${theme.ds.typography.weight.normal};
    line-height: ${theme.ds.typography.lineHeight.medium};
    cursor: pointer;
    margin-bottom: 8px;
  `}
`

export const LabelIcon = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
`

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
  ${({ theme }) => wrapperModifiers['normal'](theme)}
`

export const Select = styled.select<SelectProps>`
  ${({ theme, bgColor, round }) => css`
    height: 48px;
    width: 100%;
    min-width: fit-content;
    padding: 0 16px;
    font-family: ${theme.ds.typography.fontFamily};
    font-size: ${theme.ds.typography.size.small};
    line-height: ${theme.ds.typography.lineHeight.medium};
    color: ${theme.ds.colors.neutral900};
    background-color: ${theme.ds.colors[bgColor!]};
    background-image: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 1em) center !important;
    border-radius: ${theme.ds.typography.border.radius.small};
    border: 1px solid ${theme.ds.colors.neutral300};
    -moz-appearance: none !important;
    -webkit-appearance: none !important;

    &::placeholder {
      color: ${theme.ds.colors.neutral500};
      font-weight: ${theme.ds.typography.weight.normal};
      opacity: 1;
    }

    ${!round &&
    css`
      outline: none;
    `}

    ${round &&
    css`
      color: ${theme.ds.colors.primary600};
      font-weight: ${theme.ds.typography.weight.bold};
      background-color: ${theme.ds.colors.primary50};

      border-radius: ${theme.ds.typography.border.radius.large};
      border: 1px solid ${theme.ds.colors.neutral300};
      border: 1px solid ${theme.ds.colors.primary50};

      &:focus {
        border: 1px solid ${theme.ds.colors.primary50};
        outline-color: ${theme.ds.colors.primary50};
      }
    `}
  `}
`

export const Option = styled.option<SelectProps>`
  ${({ round }) => css`
    border-radius: ${({ theme }) => theme.ds.typography.border.radius.small};
    width: 100%;

    &:focus {
      border-color: ${({ theme }) => theme.ds.colors.primary300};
    }
    background-color: ${({ theme }) => theme.ds.colors.white};

    ${round &&
    css`
      color: ${({ theme }) => theme.ds.colors.primary600};
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      &:focus {
        &:focus {
          border-color: ${({ theme }) => theme.ds.colors.primary300};
        }
        &:hover {
          border-color: ${({ theme }) => theme.ds.colors.primary300};
          border-color: ${({ theme }) => theme.ds.colors.primary300};
        }
      }
    `}
  `}
`

export const Error = styled.p`
  margin-top: 4px;
  color: ${({ theme }) => theme.ds.colors.danger300};
  font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
  line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
  font-size: ${({ theme }) => theme.ds.typography.size.xsmall};
`

const wrapperModifiers = {
  normal: (theme: DefaultTheme) => css`
    ${Select} {
      &:focus {
        border-color: ${theme.ds.colors.primary300};
      }
    }
  `,
  error: (theme: DefaultTheme) => css`
    ${Select} {
      border-color: ${theme.ds.colors.danger200} !important;
    }
  `,
  disabled: (theme: DefaultTheme) => css`
    ${Select} {
      cursor: not-allowed;
      color: ${theme.ds.colors.neutral300};
      border-color: ${theme.ds.colors.neutral300};
      background-color: ${theme.ds.colors.neutral100};
    }
  `
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, error }) => error && wrapperModifiers.error(theme)}
  ${({ theme, disabled }) => disabled && wrapperModifiers.disabled(theme)}
`

export const Asterisk = styled.span<AsteriskProps>`
  ${({ theme, requiredColor }) => css`
    font-size: ${theme.ds.typography.size.small};
    font-weight: ${theme.ds.typography.weight.normal};
    color: ${theme.ds.colors[requiredColor!]};
    cursor: pointer;
  `}
`
