import { makeAutoObservable } from 'mobx'
import { AnswerNps } from 'domain/usecases/tracksale/answer-nps'

type Services = {
  answerNps: AnswerNps
}

export class TracksaleService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async answerNps(params: AnswerNps.Params): Promise<AnswerNps.Model> {
    return this.services.answerNps.post(params)
  }
}

export default TracksaleService
