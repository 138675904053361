import theme from 'presentation/styles/theme'
import styled from 'styled-components'
import SupportTextNew from '../../../components/SupportTextNew'

export const ScaleContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > .scale-item {
    padding: 4px;
    text-align: center;
    transition: all 0.1s ease-out;
    cursor: pointer;

    & > div {
      vertical-align: middle;
      line-height: 32px;
      font-weight: 500;
      border-radius: 4px;
      width: 32px;
      height: 32px;
      transition: all 0.1s ease-out, background-color 0.2s ease-out;
    }

    &:hover {
      padding: 2px;
      & > div {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 1.15em;
      }
    }

    &.active {
      & ~ .scale-item > div {
        background-color: ${theme.ds.colors.neutral300} !important;
      }
    }
  }
`

export const RateLabel = styled(SupportTextNew)`
  margin-top: 8px;
  min-height: 1.5em;
  line-height: 1.5em;
  text-transform: capitalize;
`
