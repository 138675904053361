import { GetPatientPendency } from 'domain/usecases/surgical-pendency/get-patient-pendency'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'

export class RemoteGetPatientPendency implements GetPatientPendency {
  constructor(private readonly patientRepository: PatientRepository) {}
  async get(
    params: GetPatientPendency.Params
  ): Promise<GetPatientPendency.Model> {
    return this.patientRepository.getPatientPendency(params)
  }
}

export type RemoteGetPatientPendencyModel = GetPatientPendency.Model
