import { Patient } from 'domain/entities/patient-model'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'
import { FormikProps, useFormik } from 'formik'
import moment from 'moment'
import Button from 'presentation/components/Button'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import Modal from 'presentation/shared/components/Modal'
import SheetModal from 'presentation/components/SheetModal'
import TextField from 'presentation/components/TextField'
import theme from 'presentation/styles/theme'
import { cpfMask, dateMask, phoneMask } from 'presentation/utils/masks'
import React, { useState } from 'react'
import * as yup from 'yup'
import 'main/config/yup'
import * as S from './styles'
import SelectField from 'presentation/shared/components/SelectField'
import { Gender } from 'common/enum/gender'
import { maritalStatus } from 'presentation/utils/default-marital-status'
import getAgeByBirthdayDate from 'common/utils/getAgeByBirthdayDate'
import { blockInvalidChar } from 'common/utils/blockInvalidChar'

type EditPatientPersonalDataProps = {
  patient: Patient
  setPatient: (patient: Patient) => void
  onClose: () => void
  open: boolean
  updatePatientPersonalData: UpdatePatientInfoById
  religions: Religion[]
  editPatient: (patient: Patient) => void
}

type Religion = {
  religion_id: number
  name: string
}

export function EditPatientPersonalData({
  patient,
  onClose,
  open,
  updatePatientPersonalData,
  setPatient,
  religions,
  editPatient
}: EditPatientPersonalDataProps) {
  const [showError, setShowError] = useState<{
    show: boolean
    message?: string
  }>({
    show: false
  })
  const [handleSuccess, setHandleSuccess] = useState<boolean>(false)
  const formik: FormikProps<UpdatePatientValues> =
    useFormik<UpdatePatientValues>({
      enableReinitialize: true,
      initialValues: {
        ...patient,
        socialName: patient.socialName,
        rg: patient.rg,
        religion_id:
          patient.religion_id ??
          religions.find((religion) => religion.name === patient.religion)
            ?.religion_id,
        religion: patient.religion,
        company: patient.company,
        birthday: patient.birthday
          ? moment(new Date(patient.birthday)).utc().format('DD/MM/YYYY')
          : null
      } as UpdatePatientValues,
      onSubmit: async (patient) => {
        try {
          const data = {
            patient_id: patient.patient_id,
            name: patient.name,
            birthday: moment(patient.birthday, 'DD/MM/YYYY').toISOString(),
            email: patient.email,
            phone: handlePhone(patient.phone),
            gender: patient.gender,
            religion_id: +patient.religion_id,
            religion: patient.religion,
            rg: patient.rg,
            socialName: patient.socialName,
            maritalStatus: patient.maritalStatus,
            company: patient.company
          }
          const newPatient = await updatePatientPersonalData.update(data)
          setPatient(newPatient)
          editPatient(newPatient)
          setHandleSuccess(true)
        } catch (error: any) {
          setShowError({
            show: true,
            message: error.message
          })
        }
      },
      validationSchema: validationSchema,
      validateOnMount: true
    })

  const handlePhone = (data: string) => {
    const phone = data.substring(1).split(' ').join('')
    return phone
  }

  return (
    <SheetModal size={700} isOpen={open} onClose={onClose} backdrop="true">
      <div style={{ width: '50%', marginBottom: '20px' }}>
        <Heading size="large">Editar dados de cadastro</Heading>
      </div>
      <Container
        noPadding
        primaryButton={
          <Button
            role="submit-button"
            color={theme.colors.primaryDarker}
            fullWidth
            style={{ marginTop: '20px' }}
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
          >
            Confirmar
          </Button>
        }
      >
        <form role="form" onSubmit={formik.handleSubmit}>
          <S.EditPatientInfoWrapper>
            <TextField
              label="Nome completo"
              onInputChange={formik.handleChange('name')}
              placeholder="Nome do Paciente"
              error={formik.touched.name && formik.errors.name}
              value={formik.values.name}
              required
            />
            <TextField
              label="Nome social"
              onInputChange={formik.handleChange('socialName')}
              placeholder="Nome social do Paciente"
              error={formik.touched.socialName && formik.errors.socialName}
              value={formik.values.socialName}
              style={{ marginTop: 15 }}
            />
            <SelectField
              label="Sexo"
              onInputChange={formik.handleChange('gender')}
              error={formik.touched.gender && formik.errors.gender}
              value={formik.values.gender || ''}
              items={[
                {
                  label: 'Masculino',
                  value: Gender.MALE
                },
                {
                  label: 'Feminino',
                  value: Gender.FEMALE
                }
              ]}
              style={{ marginTop: 15 }}
              required
              bgColor="mainBg"
            />
            <TextField
              label="Data de nascimento"
              onInputChange={formik.handleChange('birthday')}
              placeholder="__/__/__"
              mask={dateMask}
              error={formik.touched.birthday && formik.errors.birthday}
              value={formik.values.birthday}
              style={{ marginTop: 15 }}
              required
            />
            <TextField
              label="Idade"
              disabled
              value={getAgeByBirthdayDate(formik.values.birthday)}
              style={{ marginTop: 15 }}
            />
            <TextField
              label="RG"
              onInputChange={formik.handleChange('rg')}
              error={formik.touched.rg && formik.errors.rg}
              value={formik.values.rg}
              style={{ marginTop: 15 }}
              type="number"
              onKeyDown={blockInvalidChar}
            />
            <TextField
              label="CPF"
              onInputChange={formik.handleChange('cpf')}
              placeholder="CPF"
              error={formik.touched.cpf && formik.errors.cpf}
              value={formik.values.cpf}
              mask={cpfMask}
              disabled
              style={{ marginTop: 15 }}
            />
            <TextField
              label="E-mail"
              onInputChange={formik.handleChange('email')}
              placeholder="E-mail"
              error={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              style={{ marginTop: 15 }}
              required
            />
            <TextField
              label="Telefone"
              onInputChange={formik.handleChange('phone')}
              placeholder="Telefone"
              error={formik.touched.phone && formik.errors.phone}
              value={formik.values.phone}
              mask={phoneMask}
              style={{ marginTop: 15 }}
              required
            />
            <SelectField
              label="Estado civil"
              onInputChange={formik.handleChange('maritalStatus')}
              error={
                formik.touched.maritalStatus && formik.errors.maritalStatus
              }
              value={formik.values.maritalStatus || ''}
              items={maritalStatus.map((status) => ({
                label: status.label,
                value: status.value
              }))}
              bgColor="mainBg"
              style={{ marginTop: 15 }}
              required
            />
            <TextField
              label="Empresa"
              onInputChange={formik.handleChange('company')}
              value={formik.values.company}
              error={formik.touched.company && formik.errors.company}
              style={{ marginTop: 15 }}
              placeholder="-"
            />
            <SelectField
              label="Religião"
              onInputChange={formik.handleChange('religion_id')}
              error={formik.touched.religion_id && formik.errors.religion_id}
              value={formik.values.religion_id}
              items={religions.map((religion) => ({
                label: religion.name,
                value: religion.religion_id
              }))}
              bgColor="mainBg"
              style={{ marginTop: 15, marginBottom: 10 }}
              placeholder="-"
            />
          </S.EditPatientInfoWrapper>
        </form>
      </Container>
      <Modal
        type="alert"
        show={showError.show}
        title={showError.message}
        close={() => {
          setShowError({
            show: false
          })
        }}
      />
      <SheetModal
        size={300}
        isOpen={handleSuccess}
        onClose={onClose}
        backdrop="true"
      >
        <div style={{ marginBottom: '20px' }}>
          <Heading size="large">
            Cadastro editado com{' '}
            <strong style={{ color: theme.colors.primaryDarker }}>
              sucesso
            </strong>
          </Heading>
        </div>
        <S.SupportText>
          Seus dados cadastrais foram alterados com sucesso
        </S.SupportText>
        <Button
          color={theme.colors.primaryDarker}
          type="submit"
          onClick={() => setHandleSuccess(false)}
        >
          Confirmar
        </Button>
      </SheetModal>
    </SheetModal>
  )
}

type UpdatePatientValues = {
  patient_id: number
  name: string
  socialName: string
  birthday: string
  cpf: string
  rg: string
  phone: string
  email: string
  gender: string
  maritalStatus: string
  company: string
  religion_id: number
  religion: string
}

const validationSchema = yup.object().shape({
  cpf: yup.string().required(),
  name: yup.string().required(),
  birthday: yup.date().format('DD/MM/YYYY', true).required(),
  phone: yup.string().required(),
  email: yup.string().email().required()
})
