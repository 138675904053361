import { LoadEquipaments } from 'domain/usecases/equipaments/load-equipaments'
import { SurgicalScheduleRepository } from 'repository/interfaces/surgical-schedule-repository'

export class RemoteLoadEquipaments implements LoadEquipaments {
  constructor(
    private readonly surgicalScheduleRepository: SurgicalScheduleRepository
  ) {}

  async load(params: LoadEquipaments.Params): Promise<LoadEquipaments.Model> {
    return this.surgicalScheduleRepository.loadEquipaments(params)
  }
}

export type LoadEquipamentsModel = LoadEquipaments.Model
