import styled, { css, DefaultTheme } from 'styled-components'

type DotProps = {
  active: boolean
  margin: boolean
}

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    transition: ${theme.transition.default};
  `}
`

export const Dot = styled.div<DotProps>`
  ${({ theme, active, margin }) => css`
    position: relative;
    width: 12px;
    height: 12px;
    background-color: ${theme.ds.colors.neutral200};
    border-radius: ${theme.border.radius.rounded};
    cursor: pointer;
    ${!!active && wrapperModifiers.active(theme)}
    ${!margin && wrapperModifiers.done(theme)}
    ${!!margin && wrapperModifiers.margin()}
  `}
`

export const HorizontalLine = styled.hr`
  ${({ theme }) => css`
    width: 8px;
    /* height: 1px; */
    color: ${theme.ds.colors.primary600};
    border: 1px solid ${theme.ds.colors.primary600};
    background-color: ${theme.ds.colors.primary600};
  `}
`

const wrapperModifiers = {
  active: (theme: DefaultTheme) => css`
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 16px;
      height: 16px;
      border: 1px solid ${theme.ds.colors.primary600};
      border-radius: ${theme.border.radius.rounded};
      display: block;
    }
  `,
  margin: () => css`
    margin-left: 8px;
  `,
  done: (theme: DefaultTheme) => css`
    background-color: ${theme.ds.colors.primary600};
  `
}
