import React from 'react'
import CarouselInsuranceInfoForm, {
  PatientInsuranceInfoForm
} from 'presentation/shared/components/Forms/CarouselInsuranceInfo'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { HealthInsurancePlans } from 'domain/usecases/health-insurance/load-health-insurance-plans'

type ChangeSurgicalOrderPersonalInfoProps = {
  healthInsurances: HealthInsurancePlans[]
  patientInfo: PatientInsuranceInfoForm
  uploadInsuranceCard: UploadPatientDocument
  updateInsuranceInfo: UpdatePatientHealthInsurance
  updatePatientPersonalInfo?: UpdatePatientInfo
  setIsLoading: (isLoading: boolean) => void
}

export default function UpdateInsuranceInfoLayout({
  patientInfo,
  healthInsurances,
  updateInsuranceInfo,
  uploadInsuranceCard,
  updatePatientPersonalInfo,
  setIsLoading
}: ChangeSurgicalOrderPersonalInfoProps) {
  return (
    <CarouselInsuranceInfoForm
      uploadInsuranceCard={uploadInsuranceCard}
      healthInsurances={healthInsurances}
      updatePatientInsurance={updateInsuranceInfo}
      initialValues={patientInfo}
      updatePatientPersonalInfo={updatePatientPersonalInfo}
      setIsLoading={setIsLoading}
    />
  )
}
