import { OverallRegistrationStatusRepository as IOverallRegistrationStatusRepository } from 'repository/interfaces/overall-registration-status-repository'
import { IApiRepository } from 'service/protocols/api/api-repository'

import { makeGraphQLVariable } from 'repository/graphql/utils/make-variables'

import { ApiStatusCode } from 'service/protocols/api/api-client'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { RepositoryErrors } from 'repository/errors/repository-errors'

import {
  createDoctorRegistrationStatusMutation,
  resolveDoctorRegisterPendenciesMutation,
  updateDoctorRegistrationStatusMutation
} from 'repository/graphql/mutations'
import { CreateDoctorRegistrationStatus } from 'domain/usecases/overall-registration-status/create-doctor-registration-status'

import { DoctorOverallRegistration } from 'domain/entities/doctor-overall-registration-model'
import { UpdateDoctorOverallStatus } from 'domain/usecases/overall-registration-status/update-doctor-registration-status'
import { ResolveDoctorRegisterPendencies } from 'domain/usecases/overall-registration-status/resolve-doctor-register-pendencies'
import { LoadDoctorOverallStatusForReview } from 'domain/usecases/overall-registration-status/load-doctor-overall-status-for-review'
import { loadDoctorOverallStatusForReview } from 'repository/graphql/queries'

export class OverallRegistrationStatusRepository
  implements IOverallRegistrationStatusRepository
{
  constructor(private readonly apiRepository: IApiRepository) {}

  async createDoctorRegistrationStatus(
    doctor_id: number
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<CreateDoctorRegistrationStatus.Model>

    const query = createDoctorRegistrationStatusMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(doctor_id, 'doctorId')
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateDoctorRegistrationStatus.Model
    }
  }

  async updateDoctorOverallStatus(
    doctorOverall: DoctorOverallRegistration,
    overall_registration_status_id: number
  ): Promise<UpdateDoctorOverallStatus.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<UpdateDoctorOverallStatus.Model>

    const query = updateDoctorRegistrationStatusMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          data: {
            ...makeGraphQLVariable(doctorOverall, 'doctorOverall'),
            ...makeGraphQLVariable(
              overall_registration_status_id,
              'overall_registration_status_id'
            )
          }
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as UpdateDoctorOverallStatus.Model
    }
  }

  async resolveDoctorRegisterPendencies(
    overall_registration_status_id: number
  ): Promise<ResolveDoctorRegisterPendencies.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<ResolveDoctorRegisterPendencies.Model>

    const query = resolveDoctorRegisterPendenciesMutation
    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: makeGraphQLVariable(
          overall_registration_status_id,
          'overallRegistrationStatusId'
        )
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as CreateDoctorRegistrationStatus.Model
    }
  }

  async loadDoctorOverallStatusForReview(
    params: LoadDoctorOverallStatusForReview.Params
  ): Promise<LoadDoctorOverallStatusForReview.Model> {
    const apiRepository = this
      .apiRepository as IApiRepository<LoadDoctorOverallStatusForReview.Model>

    const query = loadDoctorOverallStatusForReview(params)

    const httpResponse = await apiRepository.post({
      url: '/graphql',
      body: {
        query: query.query,
        variables: {
          ...makeGraphQLVariable(params?.doctor_id, 'doctor_id')
        }
      },
      query: query.name
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as LoadDoctorOverallStatusForReview.Model
    }
  }
}
