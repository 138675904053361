import React from 'react'
import * as S from './styles'
import { SurgeryDetailsLayoutProps } from 'presentation/layouts/OldSurgeryDetails'
import { ReactComponent as PersonIcon } from 'presentation/assets/icons/person-outline.svg'
import { ReactComponent as ClipboardIcon } from 'presentation/assets/icons/clipboard.svg'
import { Procedure } from 'domain/entities/surgical-schedule-model'
import Heading from '../../../components/Heading'

export default function OrderDetails({
  statusList,
  passwordToFinish,
  passwordMaxDateValidation,
  procedureDetails,
  procedures,
  observation,
  opmeDetails,
  opme
}: SurgeryDetailsLayoutProps) {
  const isOrderAuthorized = statusList?.some(
    (item) => item.status === 'AUTHORIZED'
  )

  return (
    <>
      {isOrderAuthorized && (
        <S.Wrapper>
          {passwordToFinish && (
            <PasswordCard
              passwordToFinish={passwordToFinish}
              passwordMaxDateValidation={passwordMaxDateValidation}
            />
          )}
          {procedures && (
            <ProceduresCard
              procedures={procedures}
              procedureDetails={procedureDetails}
            />
          )}
          {opmeDetails && <OpmeCard opme={opme} opmeDetails={opmeDetails} />}

          {observation && <GeneralObservation observation={observation} />}
        </S.Wrapper>
      )}
      {isOrderAuthorized === false && (
        <S.Wrapper>
          <Heading>
            Acompanhe o status do seu procedimento na aba Status
          </Heading>
        </S.Wrapper>
      )}
    </>
  )
}

const PasswordCard = ({ passwordToFinish }: any) => {
  return (
    <S.CardWrapper>
      <h3>
        <PersonIcon />
        &nbsp;Senha
      </h3>
      <p>
        {' '}
        <strong>Senha: </strong> {passwordToFinish}{' '}
      </p>
      <p>
        {' '}
        <strong>Validade da senha: </strong>{' '}
      </p>
      <p className="password-description">
        A senha terá validade de 30 dias a partir da data de autorização do
        pedido
      </p>
    </S.CardWrapper>
  )
}

const ProceduresCard = ({ procedures, procedureDetails }: any) => {
  return (
    <S.CardWrapper>
      <h3 className="procedure-title">
        <ClipboardIcon />
        &nbsp;Procedimentos autorizados
      </h3>
      {procedures?.map((procedure: Procedure, index: any) => {
        return (
          <>
            <div key={index} className="procedure-container">
              <p>
                {' '}
                <strong>Cód TUSS: </strong> {procedure.code}{' '}
              </p>
              <p>
                {' '}
                <strong>Quant.:</strong> {procedure.quantity}{' '}
              </p>
            </div>
            <p className="procedure-description">{procedure.description}</p>
            <S.Divider />
          </>
        )
      })}

      {procedureDetails && (
        <ObservationCard
          observation={procedureDetails}
          title={'Observação sobre procedimentos'}
        />
      )}
    </S.CardWrapper>
  )
}

const ObservationCard = ({ observation, title }: any) => {
  return (
    <S.GreenCard>
      <h3 className="procedure-observation-title">{title}</h3>
      <p>{observation}</p>
    </S.GreenCard>
  )
}

const OpmeCard = ({ opme, opmeDetails }: any) => {
  return (
    <>
      <S.CardWrapper>
        <h3 className="procedure-title">
          <ClipboardIcon />
          &nbsp;OPME
        </h3>
        <p>
          {' '}
          <strong>OPME autorizado: </strong> {opmeDetails?.split(',')[0]}{' '}
        </p>
        <p>
          {' '}
          <strong>Fornecedor: </strong>{' '}
        </p>
        {opme?.providers?.map((opme: any, index: any) => {
          return <p key={index}>{opme}</p>
        })}

        {opmeDetails?.split(',')[1] && (
          <ObservationCard
            observation={opmeDetails?.split(',')[1]}
            title={'Observação sobre OPME'}
          />
        )}
      </S.CardWrapper>
    </>
  )
}

const GeneralObservation = ({ observation }: any) => {
  return (
    <S.CardWrapper>
      <h3>Observações Gerais</h3>
      <p> {observation} </p>
    </S.CardWrapper>
  )
}
