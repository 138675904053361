import { LoadExams } from 'domain/usecases/patient/load-exams'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteLoadExams implements LoadExams {
  constructor(private readonly patientRepository: PatientRepository) {}

  load(params?: LoadExams.Params): Promise<LoadExams.Model> {
    return this.patientRepository.loadExams(params)
  }
}

export type LoadRemoteLoadExamsModel = LoadExams.Model
