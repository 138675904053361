import React, { HTMLAttributes } from 'react'
import theme from 'presentation/styles/theme'
import * as S from './style'

export type CardProps = {
  children?: React.ReactNode
  style?: React.CSSProperties
  shadow?: keyof typeof theme.ds.shadows
  border?: 'none' | keyof typeof theme.ds.colors
  background?: keyof typeof theme.ds.colors
  color?: keyof typeof theme.ds.colors
  padding?: string
  gap?: string
  disabled?: boolean
  onClick?: () => void
  // hover?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function CardNew({
  children,
  style,
  shadow = 'normal',
  border = 'none',
  background = 'white',
  color = 'neutral900',
  padding = '16px',
  gap,
  disabled,
  onClick,
  ...props
}: CardProps) {
  return (
    <S.CardWrapper
      style={style}
      shadow={shadow}
      gap={gap}
      border={border}
      background={background}
      color={color}
      padding={padding}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {children}
    </S.CardWrapper>
  )
}
