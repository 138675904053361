import { SendDoctorRegisterToDirector } from 'domain/usecases/doctor-register/send-doctor-register-to-director'
import { DoctorRegisterRepository } from 'repository/interfaces/doctor-register-repository'

export class RemoteSendDoctorRegisterToDirector
  implements SendDoctorRegisterToDirector
{
  constructor(
    private readonly doctorRegisterRepository: DoctorRegisterRepository
  ) {}

  execute(
    params: SendDoctorRegisterToDirector.Params
  ): Promise<SendDoctorRegisterToDirector.Model> {
    return this.doctorRegisterRepository.sendDoctorRegisterToDirector(params)
  }
}
