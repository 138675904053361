import { Profile } from 'common/enum/profile'
import UserCard from 'presentation/assets/icons/user-card.svg'
import Clipboard from 'presentation/assets/icons/clipboard.svg'
import Apple from 'presentation/assets/icons/apple.svg'
import Pill from 'presentation/assets/icons/pill.svg'
import SignatureIcon from 'presentation/assets/icons/signature-icon.svg'
import Card from 'presentation/assets/icons/health-insurance.svg'
import { useHistory } from 'react-router-dom'

export const getProfileList = (profile: Profile) => {
  const history = useHistory()
  switch (profile) {
    case Profile.PATIENT:
      return [
        {
          title: 'Dados pessoais',
          icon: Clipboard,
          click: () => history.push('/informacoes-pessoais')
        },
        {
          title: 'Hábitos de vida e nutrição',
          icon: Apple,
          click: () => history.push('/habitos')
        },
        {
          title: 'Medicamentos e doença',
          icon: Pill,
          click: () => history.push('/medicamentos-doencas')
        },
        {
          title: 'Convênio médico',
          icon: Card,
          click: () => history.push('/convenios')
        }
      ]
    case Profile.DOCTOR:
      return [
        {
          title: 'Dados pessoais',
          icon: UserCard,
          click: () => history.push('/informacoes-pessoais')
        },
        {
          title: 'Assinatura',
          icon: SignatureIcon,
          click: () => history.push('/assinatura')
        },
        {
          title: 'Indicações',
          icon: SignatureIcon,
          click: () => history.push('/indicacoes')
        }
      ]
    default:
      return []
  }
}
