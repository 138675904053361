import { ConfirmReadCancellationRequests } from 'domain/usecases/surgical-order/read-cancellation-requests'
import { SurgicalOrderRepository } from 'repository/repositories/surgical-order/surgical-order-repository'

export class RemoteConfirmReadCancellationRequests
  implements ConfirmReadCancellationRequests
{
  constructor(
    private readonly surgicalOrderRepository: SurgicalOrderRepository
  ) {}

  put(): Promise<ConfirmReadCancellationRequests.Model> {
    return this.surgicalOrderRepository.confirmReadCancellationRequests()
  }
}
