// import { Notification } from 'domain/entities/notification-model'
import { ReadNotifications } from 'domain/usecases/notification/read-notifications'
import { NotificationRepository } from 'repository/interfaces/notification-repository'

export class RemoteReadNotifications implements ReadNotifications {
  constructor(
    private readonly notificationRepository: NotificationRepository
  ) {}

  async load(
    params: ReadNotifications.Params
  ): Promise<ReadNotifications.Model> {
    return this.notificationRepository.readNotifications(params)
  }
}

export type ReadNotificationsModel = ReadNotifications.Model
