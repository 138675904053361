import React, { useState, useCallback, useRef, useEffect } from 'react'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'

import TextFieldNew, {
  TextFieldProps
} from 'presentation/components/TextFieldNew'
import ButtonNew from 'presentation/components/ButtonNew'
import LoadingGif from 'presentation/assets/icons/loading.gif'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/close.svg'
import * as S from './styles'

export type AutoCompleteProps = {
  suggestions?: { label: string; value: any }[]
  onSuggestionClick?: (value: any) => void
  onType?: (value: any) => void
  // onIconClick?: (value: any) => void
  onInputChange: (value: any) => void
  clear?: () => void
  debounceDelay?: number
  minCharactersSearch?: number
  closeSuggestionsOnNotFound?: boolean
} & TextFieldProps

const AutoCompleteNew = ({
  suggestions,
  onSuggestionClick,
  onType,
  // onIconClick,
  onInputChange,
  clear,
  debounceDelay = 500,
  minCharactersSearch = 0,
  closeSuggestionsOnNotFound = false,
  ...inputProps
}: AutoCompleteProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [loading, setLoading] = useState(true)

  const wrapperRef = useRef(null) as any
  const [SuggestionContainerVisible, setSuggestionContainerVisible] =
    useState(false)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSuggestionContainerVisible(false)
        setShowSuggestions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const changeTrigger = async (userInput: string) => {
    if (userInput.length >= minCharactersSearch) {
      try {
        setLoading(true)
        setShowSuggestions(true)
        setSuggestionContainerVisible(true)
        onType && (await onType(userInput))
      } catch (err: any) {
        toast.error(err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  const debounced = useCallback(
    debounce((func, e) => func(e), debounceDelay),
    []
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value
    setShowSuggestions(false)
    onInputChange(userInput)
    debounced(changeTrigger, userInput)

    if (!userInput) {
      setShowSuggestions(false)
    }
  }

  const onItemClick = (event: React.MouseEvent<HTMLLIElement>, value: any) => {
    setShowSuggestions(false)
    try {
      onSuggestionClick?.(value)
    } catch {
      return
    }
  }

  // const onIconClickHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const userInput = e.currentTarget.value
  //   setShowSuggestions(false)
  //   onIconClick(userInput)
  //   debounced(changeTrigger, userInput)

  //   if (!userInput) {
  //     setShowSuggestions(false)
  //   }
  // }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setShowSuggestions(false)
    }
  }

  const SuggestionsListComponent = () => {
    if (loading) {
      return (
        <S.Suggestions className="suggestions">
          <li style={{ textAlign: 'center' }}>
            <p>Buscando...</p>
            <img src={LoadingGif} alt="loading" />
          </li>
        </S.Suggestions>
      )
    }

    return (
      <>
        {SuggestionContainerVisible && suggestions && (
          <>
            {/* eslint-disable-next-line react/prop-types */}
            {suggestions.length === 0 && !loading ? (
              <>
                {!closeSuggestionsOnNotFound && (
                  <S.Suggestions className="suggestions">
                    <li ref={wrapperRef} style={{ textAlign: 'center' }}>
                      Nenhum registro encontrado
                    </li>
                  </S.Suggestions>
                )}
              </>
            ) : (
              <S.Suggestions className="suggestions">
                <div ref={wrapperRef}>
                  {/* eslint-disable-next-line react/prop-types */}
                  {suggestions?.map((suggestion, index) => {
                    return (
                      <li
                        key={index}
                        data-testid={`doctor-autocomplete-doctor-${index}`}
                        onClick={(e) => onItemClick(e, suggestion.value)}
                      >
                        {suggestion.label}
                      </li>
                    )
                  })}
                </div>
              </S.Suggestions>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <S.Wrapper isOpen={showSuggestions}>
      <TextFieldNew
        className="input"
        type="text"
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoComplete="off"
        {...inputProps}
        data-testid="autocomplete-tuss-field"
      />
      {showSuggestions && <SuggestionsListComponent />}
      {clear && inputProps.disabled && (
        <ButtonNew onClick={clear} className="clear-btn" type="button">
          <CloseIcon className="icon" />
        </ButtonNew>
      )}
    </S.Wrapper>
  )
}

export default AutoCompleteNew
