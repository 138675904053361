import React from 'react'
import * as S from './styles'

export type Props = {
  showOnly?: boolean
  title?: string
  description?: string
  openConfirmationDeleteMenu?: (healthInsuranceId: number) => void
  style?: any
  onClick?: () => void
  disabled?: boolean
}

export default function ExamPreparationCard({
  style,
  title,
  description,
  disabled = false,
  onClick
}: Props) {
  return (
    <S.ExamPreparationCardWrapper
      style={style}
      onClick={onClick}
      className={disabled ? '-disabled' : ''}
    >
      <S.CardHeader>
        <>
          <h3 className="exam-preparation-name">{title}</h3>
        </>
      </S.CardHeader>
      <S.CardBody>
        <>
          <div>
            <strong>{description}</strong>
          </div>
        </>
      </S.CardBody>
      <S.CardFooter>
        <strong>Ver preparo {'>'} </strong>
      </S.CardFooter>
    </S.ExamPreparationCardWrapper>
  )
}
