import { Hospital } from 'domain/entities/hospital-model'
import { Patient } from 'domain/entities/patient-model'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import ActualPage from 'presentation/components/ActualPage'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import Chip from 'presentation/shared/components/Chip'
import Heading from 'presentation/components/Heading'
import SelectField from 'presentation/shared/components/SelectField'
import SupportText from 'presentation/components/SupportText'
import React, { useState } from 'react'
import * as S from './styles'

export type ArrivalTimes = 'MIN_30' | 'HR_1' | 'HR_1_30' | 'HR_2'

type EmergencyRoomSelectUnitProps = {
  patient: Patient
  carousel: CarouselState
  setStep: (number: number) => void
  hospitals: Hospital[]
  setForm: (data: SubmitEmergencyAttendance) => void
  form?: SubmitEmergencyAttendance
  backPatientCarousel: (number: number) => void
}
export function EmergencyRoomSelectUnit({
  carousel,
  setStep,
  hospitals,
  form,
  setForm,
  backPatientCarousel
}: EmergencyRoomSelectUnitProps) {
  const [time, setTime] = useState<ArrivalTimes>()
  const [selectedSpeciality, setSelectedSpeciality] = useState<string>()

  return (
    <>
      <S.SelectUnitContainer>
        <S.ActualPage>
          <ActualPage
            onClick={() => {
              carousel.slidePrev()
              backPatientCarousel(2)
            }}
            text="Voltar"
          />
        </S.ActualPage>
        <Heading as="h5" color="primary">
          Especialidade e unidade
        </Heading>
        <SupportText color="lightGray">
          Preencha as informações do convênio.
        </SupportText>
        <S.SelectUnitWrapper>
          <strong>Dados para o atendimento</strong>
          <SelectField
            label="Especialidade"
            name="speciality"
            items={hospitals
              .find((hospital) => hospital.hospital_id === form?.hospital_id)
              ?.specialityHospital?.map((speciality) => ({
                value: speciality?.name || '',
                label: speciality.name || ''
              }))}
            onChange={({ target }) => setSelectedSpeciality(target.value)}
            value={selectedSpeciality ?? ''}
          />
        </S.SelectUnitWrapper>
      </S.SelectUnitContainer>
      <hr />
      <S.SelectUnitContainer>
        <S.SelectUnitChips>
          <strong>
            Indique uma previsão de horário para a sua chegada ao hospital:
          </strong>
          <S.SelectUnitChipsGrid>
            <Chip
              label="30 minutos"
              checked={time === 'MIN_30'}
              value={'MIN_30'}
              onCheck={() => setTime('MIN_30')}
            />
            <Chip
              label="1 hora"
              checked={time === 'HR_1'}
              value={'HR_1'}
              onCheck={() => setTime('HR_1')}
            />
            <Chip
              label="1h e 30 minutos"
              checked={time === 'HR_1_30'}
              value={'HR_1_30'}
              onCheck={() => setTime('HR_1_30')}
            />
            <Chip
              label="2 horas"
              checked={time === 'HR_2'}
              value={'HR_2'}
              onCheck={() => setTime('HR_2')}
            />
          </S.SelectUnitChipsGrid>
        </S.SelectUnitChips>
        <Button
          fullWidth
          onClick={() => {
            setStep(2)
            carousel.slideNext()
            setForm({
              ...form,
              specialty: selectedSpeciality,
              arrivalForecast: time
            })
          }}
          disabled={!selectedSpeciality || !time}
        >
          Concluir
        </Button>
      </S.SelectUnitContainer>
    </>
  )
}
