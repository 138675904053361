import { GetExamGroupOrdered } from 'domain/usecases/exam/get-exam-group-ordered'
import { ExamRepository } from 'repository/repositories/exam/exam-repository'

export class RemoteGetExamGroupOrdered implements GetExamGroupOrdered {
  constructor(private readonly examRepository: ExamRepository) {}
  async get(
    params: GetExamGroupOrdered.Params
  ): Promise<GetExamGroupOrdered.Model> {
    return this.examRepository.getExamGroupOrdered(params)
  }
}

export type RemoteGetExamGroupOrderedModel = GetExamGroupOrdered.Model
