import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import HeaderNew from 'presentation/components/HeaderNew'
import * as S from './styles'
import UpdatePersonalInfo from './UpdatePersonalInfo'
import ScheduleExams from './ScheduleExams'
import { useLocation } from 'react-router-dom'
import ExamsAddDocuments from './ExamsAddDocuments'
import ExamsHealthInsurance from './ExamsHealthInsurance'
import SchedulingExamContextWrapper from './context'
import SearchExams from './SearchExams'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { DownloadPatientDocument } from 'domain/usecases/patient/download-patient-document'
import SearchDoctorAndUnity from './SearchDoctorAndUnity'
import ConfirmationExamSchedule from './ConfirmationExamSchedule'
import SuccessExamSchedule from './SuccessExamSchedule'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { useSearchParams } from 'presentation/hooks/use-search-params'

type Props = {
  uploadDocument: UploadPatientDocument
  downloadDocument: DownloadPatientDocument
} & WithLoadingProps

type LocationType = {
  state: {
    hasParamTokenState?: boolean
  }
}

const SchedulingExamsLayout = WithLoading(
  ({ uploadDocument, downloadDocument, setIsLoading, isLoading }: Props) => {
    const location: LocationType = useLocation()
    const history = useHistory()
    const searchParams = useSearchParams()
    const searchParamToken =
      searchParams.get('token') || location?.state?.hasParamTokenState
    const [carousel, setCarousel] = useState({} as CarouselState)
    const { state } = useLocation() as any
    const [showCancelExam, setShowCancelExam] = useState(false)
    const [showCalendarScreen, setShowCalendarScreen] = useState(false)
    const [showAddHealthInsurance, setShowAddHealthInsurance] = useState(false)
    const [showPreparation, setShowPreparation] = useState(false)
    const hasParamToken = searchParamToken ? true : false

    const [
      showMedicalAppointmentRedirectScreen,
      setShowMedicalAppointmentRedirectScreen
    ] = useState(false)

    useEffect(() => {
      if (carousel && carousel?.activeIndex !== 5) {
        if (state?.currentSlide === 4 && carousel?.activeIndex >= 0) {
          carousel?.slideTo(4)
        }
      }
    }, [state, carousel])

    return (
      <>
        {!hasParamToken && (
          <HeaderNew
            actualPageTitle="Agendamento de exames"
            actualPageOnClick={() => {
              if (showAddHealthInsurance) {
                return setShowAddHealthInsurance(false)
              }
              if (showCancelExam) {
                return setShowCancelExam(false)
              }
              if (showCalendarScreen) {
                return setShowCalendarScreen(false)
              }
              if (carousel.activeIndex === 0) {
                history.push('/servicos', {
                  anchor: 'exams'
                })
              }
              if (showMedicalAppointmentRedirectScreen) {
                return setShowMedicalAppointmentRedirectScreen(false)
              }
              if (carousel.activeIndex === 7) {
                setShowPreparation(false)
                carousel.slideTo(0)
                window.location.reload()
              } else {
                carousel.slidePrev()
                setShowMedicalAppointmentRedirectScreen(false)
              }
            }}
          />
        )}
        <SchedulingExamContextWrapper id="timeline">
          <S.BackgroundWrapper>
            <Carousel
              state={carousel}
              initialSlide={state?.activeIndex || 0}
              setState={setCarousel}
              touch={false}
              slidesPerView={1}
              freeMode={false}
              dynamicHeight
            >
              <S.Wrapper>
                <ScheduleExams
                  next={carousel.slideNext}
                  prev={carousel.slidePrev}
                  activeIndex={carousel.activeIndex}
                  showCancelExam={showCancelExam}
                  setShowCancelExam={setShowCancelExam}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  slideToReschedule={() => carousel.slideTo(5)}
                  hasParamToken={hasParamToken}
                />
              </S.Wrapper>

              <S.Wrapper>
                <UpdatePersonalInfo
                  prev={carousel.slidePrev}
                  next={() => carousel.slideTo(2)}
                  activeIndex={carousel.activeIndex}
                  setIsLoading={setIsLoading}
                  hasParamToken={hasParamToken}
                />
              </S.Wrapper>

              <S.Wrapper>
                <ExamsHealthInsurance
                  next={carousel.slideNext}
                  showAddHealthInsurance={showAddHealthInsurance}
                  setShowAddHealthInsurance={setShowAddHealthInsurance}
                  setIsLoading={setIsLoading}
                />
              </S.Wrapper>

              <S.Wrapper>
                <ExamsAddDocuments
                  next={carousel.slideNext}
                  prev={carousel.slidePrev}
                  uploadDocument={uploadDocument}
                  downloadDocument={downloadDocument}
                  activeIndex={carousel.activeIndex}
                  setIsLoading={setIsLoading}
                />
              </S.Wrapper>

              <S.Wrapper>
                <SearchExams
                  next={carousel.slideNext}
                  prev={carousel.slidePrev}
                  setIsLoading={setIsLoading}
                  activeIndex={carousel.activeIndex}
                  slideToHealthInsurance={() => carousel.slideTo(2)}
                  slideTo={carousel.slideTo}
                  showMedicalAppointmentRedirectScreen={
                    showMedicalAppointmentRedirectScreen
                  }
                  setShowMedicalAppointmentRedirectScreen={
                    setShowMedicalAppointmentRedirectScreen
                  }
                />
              </S.Wrapper>

              <S.Wrapper>
                <SearchDoctorAndUnity
                  next={carousel.slideNext}
                  prev={carousel.slidePrev}
                  activeIndex={carousel.activeIndex}
                  showCalendarScreen={showCalendarScreen}
                  setShowCalendarScreen={setShowCalendarScreen}
                  setIsLoading={setIsLoading}
                  slideTo={carousel.slideTo}
                  isLoading={isLoading}
                />
              </S.Wrapper>

              <S.Wrapper>
                <ConfirmationExamSchedule
                  next={carousel.slideNext}
                  prev={carousel.slidePrev}
                  slideTo={carousel.slideTo}
                  activeIndex={carousel.activeIndex}
                  setShowCalendarScreen={setShowCalendarScreen}
                  setIsLoading={setIsLoading}
                />
              </S.Wrapper>

              <S.Wrapper>
                <SuccessExamSchedule
                  setIsLoading={setIsLoading}
                  setShowPreparation={setShowPreparation}
                  showPreparation={showPreparation}
                  slideTo={carousel.slideTo}
                  hasParamToken={hasParamToken}
                />
              </S.Wrapper>
            </Carousel>
          </S.BackgroundWrapper>
        </SchedulingExamContextWrapper>
      </>
    )
  }
)

export default SchedulingExamsLayout
