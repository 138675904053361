import { Patient } from 'domain/entities/patient-model'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { CreateEmergencyRoom } from 'domain/usecases/emergency-room/create-emergency-room'
import { LoadHospitals } from 'domain/usecases/hospital/load-hospitals'
import { LoadPatientInfo } from 'domain/usecases/patient/load-patient-info'
import EmergencyRoomServiceLayout from 'presentation/layouts/EmergencyRoom'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'

type Props = {
  loadPatient: LoadPatientInfo
  loadHospitals: LoadHospitals
  updatePatientPersonalData: UpdatePatientInfoById
  addAccompanying: AddAccompanying
  createEmergencyRoom: CreateEmergencyRoom
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  uploadDocument?: UploadPatientDocument
}

export default function EmergencyRoomService({
  loadPatient,
  updatePatientPersonalData,
  loadHospitals,
  addAccompanying,
  createEmergencyRoom,
  updateHealthInsuranceData,
  uploadDocument
}: Props) {
  const [patient, setPatient] = useState<Patient>()
  const [editedPatient, setEditedPatient] = useState<Patient>()
  const [hospitals, setHospitals] = useState<LoadHospitals.Model>()
  const [editedHealthInsurance, setEditedHealthInsurance] =
    useState<boolean>(false)
  const [updatedAccompanies, setUpdatedAccompanies] = useState<boolean>(false)

  useEffect(() => {
    async function loadData() {
      try {
        const patientLoaded = await loadPatient.load([
          'patient_id',
          'name',
          'socialName',
          'lastName',
          'birthday',
          'cpf',
          'rg',
          'phone',
          'email',
          'gender',
          'maritalStatus',
          'company',
          'religion',
          'religion_id',
          'accompanists {name, accompanying_id}',
          'healthInsurance {',
          '  health_insurance_id',
          '  healthInsuranceName',
          '  healthPlanName',
          '  healthCard',
          '  healthInsuranceCode',
          '  healthPlanCode',
          '  company',
          '  validThru',
          '  ansRegister',
          '}',
          'lastHealthInsuranceUsed {',
          '  healthInsuranceName',
          '  healthPlanName',
          '  card',
          '  healthInsuranceCode',
          '  healthPlanCode',
          '  expirationDate',
          '  subPlanCode',
          '  subPlanName',
          '}'
        ])
        const hospitalLoaded = await loadHospitals.load([
          'name',
          'hospital_id',
          'specialityHospital {name}',
          'hasPartnerCode',
          'friendly_name'
        ])
        setPatient(patientLoaded)
        setHospitals(hospitalLoaded)
      } catch (error) {
        toast.error('Ocorreu um erro.')
      }
    }

    loadData()
  }, [editedPatient, editedHealthInsurance, updatedAccompanies])

  return (
    <EmergencyRoomServiceLayout
      patient={patient ?? ({} as Patient)}
      updatePatientPersonalData={updatePatientPersonalData}
      hospitals={hospitals || []}
      addAccompanying={addAccompanying}
      createEmergencyRoom={createEmergencyRoom}
      setPatient={setPatient}
      updateHealthInsuranceData={updateHealthInsuranceData}
      uploadDocument={uploadDocument}
      editPatient={(patient) => setEditedPatient(patient)}
      editHealthInsurance={() =>
        setEditedHealthInsurance(!editedHealthInsurance)
      }
      updatedHealthInsurance={editedHealthInsurance}
      updateAccompanies={() => setUpdatedAccompanies(!updatedAccompanies)}
    />
  )
}
