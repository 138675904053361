import React from 'react'

import AddPainAlertLayout from 'presentation/layouts/AddPainAlert'
import { AddPainAlert as IAddPainAlert } from 'domain/usecases/patient/add-pain-alert'
import { useStores } from 'presentation/hooks/use-stores'

type Props = {
  addPainAlert: IAddPainAlert
}

export default function AddPainAlert({ addPainAlert }: Props) {
  const currentSurgery = useStores().currentSurgery

  return (
    <AddPainAlertLayout
      surgicalOrderId={currentSurgery.getSurgeryIdSelected()}
      addPainAlert={addPainAlert}
    />
  )
}
