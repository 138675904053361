import React from 'react'
import { ReactComponent as CollaboratorIcon } from 'presentation/assets/icons/collaborators.svg'
import { ReactComponent as FavoriteIcon } from 'presentation/assets/icons/star_fav_maior.svg'
import { ReactComponent as TermsIcon } from 'presentation/assets/icons/terms.svg'
// import { ReactComponent as ExamsAndSurgicalsIcon } from 'presentation/assets/icons/exams-and-surgicals-light.svg'

type OptionsType = {
  link: string
  label: string
  icon: React.ReactNode | string
}

type ProfilesType = {
  doctor: OptionsType[]
  patient: OptionsType[]
  admin: OptionsType[]
  secretary: OptionsType[]
  crmo: OptionsType[]
  hospitalization: OptionsType[]
  ambulatory: OptionsType[]
  oncology: OptionsType[]
  doctor_register: OptionsType[]
  director_doctor_register: OptionsType[]
  admin_sectorial_crmo: OptionsType[]
  emergency_room: OptionsType[]
  setor_de_marcacao: OptionsType[]
}

export const profiles: ProfilesType = {
  doctor: [
    {
      link: '/secretaria',
      label: 'Secretárias',
      icon: <CollaboratorIcon />
    },
    // {
    //   link: '',
    //   label: 'Exames e consultas',
    //   icon: <ExamsIcon />
    // },
    {
      link: '/favoritos',
      label: 'Meus Favoritos',
      icon: <FavoriteIcon />
    },
    {
      link: '/pendencias',
      label: 'Pendências',
      icon: <TermsIcon />
    }
    // {
    //   link: '',
    //   label: 'Fale conosco',
    //   icon: <ContactUsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Termos e politicas',
    //   icon: <TermsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Ajuda',
    //   icon: <HelpIcon />
    // }
  ],
  patient: [
    // {
    //   link: '/acompanhantes',
    //   label: 'Acompanhantes',
    //   icon: <CollaboratorIcon />
    // },
    // {
    //   link: '/exames/meus',
    //   label: 'Exames e Consultas',
    //   icon: <ExamsAndSurgicalsIcon />
    // }
    // {
    //   link: '',
    //   label: 'Exames e consultas',
    //   icon: <ExamsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Orçamentos',
    //   icon: <BudgetsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Fale conosco',
    //   icon: <ContactUsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Termos e politicas',
    //   icon: <TermsIcon />
    // },
    // {
    //   link: '',
    //   label: 'Ajuda',
    //   icon: <HelpIcon />
    // }
  ],
  admin: [],
  secretary: [],
  crmo: [],
  hospitalization: [],
  ambulatory: [],
  oncology: [],
  doctor_register: [],
  director_doctor_register: [],
  admin_sectorial_crmo: [],
  emergency_room: [],
  setor_de_marcacao: []
}
