import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { SchedulingRepository } from 'repository/interfaces/scheduling-repository'

export class RemoteSearchScheduling implements SearchFiltersSchedules {
  constructor(private readonly schedulingRepository: SchedulingRepository) {}

  load(
    params: SearchFiltersSchedules.Params
  ): Promise<SearchFiltersSchedules.Model> {
    return this.schedulingRepository.searchFiltersSchedules(params)
  }
}

export type SearchDoctorsModel = SearchFiltersSchedules.Model
