import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 100%;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
`

export const BackgroundWrapper = styled.div`
  background: ${({ theme }) => theme.ds.colors.white};
`
