import React, { useState } from 'react'

import Heading from '../../../components/Heading'
import { PainIntensity as Intensity } from 'common/enum/pain-intensity'
import {
  ModerateIcon,
  IntenseIcon,
  StrongIcon,
  UnbearableIcon,
  NoPainIcon
} from 'presentation/assets/pain-icons'
import * as S from './styles'
import { useEffect } from 'react'
import { PainSlider } from '../PainSlider'

type Props = {
  value?: (value?: Intensity) => void
}

const PainIntensity = ({ value }: Props) => {
  const [painIntensity, setPainIntensity] = useState(5)

  useEffect(() => {
    const intensityValue = intensityList.find((i) => i.number === painIntensity)
    if (intensityValue) {
      !!value && value(intensityValue.value)
    }
  }, [painIntensity])

  return (
    <S.Wrapper>
      <Heading color="lightGray" size="medium" flexStart>
        Qual intensidade de sua dor?
      </Heading>
      <S.SliderContainer>
        <PainSlider
          value={painIntensity}
          startLabel="Sem dor"
          onChange={(value) => setPainIntensity(value)}
        />
      </S.SliderContainer>
    </S.Wrapper>
  )
}

export default PainIntensity

const intensityList = [
  {
    label: 'Sem dor',
    value: Intensity.NO_PAIN,
    number: 1,
    icon: NoPainIcon,
    color: '#46BF66'
  },
  {
    label: 'Sem dor',
    value: Intensity.NO_PAIN,
    number: 2,
    icon: NoPainIcon,
    color: '#46BF66'
  },
  {
    label: 'Dor moderada',
    value: Intensity.MODERATE,
    number: 3,
    icon: ModerateIcon,
    color: '#DCD14E'
  },
  {
    label: 'Dor moderada',
    value: Intensity.MODERATE,
    number: 4,
    icon: ModerateIcon,
    color: '#DCD14E'
  },
  {
    label: 'Dor intensa',
    value: Intensity.INTENSE,
    number: 5,
    icon: IntenseIcon,
    color: '#FDA248'
  },
  {
    label: 'Dor intensa',
    value: Intensity.INTENSE,
    number: 6,
    icon: IntenseIcon,
    color: '#FDA248'
  },
  {
    label: 'Dor forte',
    value: Intensity.STRONG,
    number: 7,
    icon: StrongIcon,
    color: '#FE6645'
  },
  {
    label: 'Dor forte',
    value: Intensity.STRONG,
    number: 8,
    icon: StrongIcon,
    color: '#FE6645'
  },
  {
    label: 'Dor insuportável',
    value: Intensity.UNBEARABLE,
    number: 9,
    icon: UnbearableIcon,
    color: '#D81D1D'
  },
  {
    label: 'Dor insuportável',
    value: Intensity.UNBEARABLE,
    number: 10,
    icon: UnbearableIcon,
    color: '#D81D1D'
  }
]
