import React, { useContext, useState, useEffect } from 'react'
import * as S from './styles'
import { toast } from 'react-toastify'
import { useServices } from 'presentation/hooks/use-services'
import { SurgicalOrderContext } from 'main/factories/pages/surgical-order/surgical-order-factory'
import moment from 'moment-timezone'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'

export type SurgicalOrderFinalizedProps = {
  finalize_surgical_order_id: number | undefined
  opme_details: string | undefined
  opme_observation: string | undefined
  procedure_observation: string | undefined
  password_to_finish: string | undefined
  password_max_date_validation: string | undefined
  password_max_string_validation: string | undefined
  finalized_by_user_id: number | undefined
  general_observation: string | undefined
  last_update_user_id: number | undefined
  surgical_order_id: number | undefined
  created_at: Date | undefined | string
  updated_at: Date | undefined | string
}

const PlanningTab = ({ setIsLoading }: WithLoadingProps) => {
  const { state } = useContext<any>(SurgicalOrderContext)
  const surgicalOrderService = useServices().surgicalOrder

  const [surgicalOrder, setSurgicalOrder] =
    useState<SurgicalOrderFinalizedProps>()

  const getSurgicalOrderInfoFinalized = async () => {
    setIsLoading(true)
    try {
      const response = await surgicalOrderService.loadSurgicalOrder(
        state.card.surgical_order_id,
        [
          'finalized{finalize_surgical_order_id',
          'opme_details',
          'opme_observation',
          'procedure_observation',
          'password_to_finish',
          'password_max_date_validation',
          'password_max_string_validation',
          'finalized_by_user_id',
          'general_observation',
          'last_update_user_id',
          'surgical_order_id',
          'created_at',
          'updated_at }'
        ]
      )
      const finalized =
        response?.finalized || ({} as SurgicalOrderFinalizedProps)

      setSurgicalOrder({
        finalize_surgical_order_id: finalized?.finalize_surgical_order_id,
        opme_details: finalized?.opme_details,
        opme_observation: finalized?.opme_observation,
        procedure_observation: finalized?.procedure_observation,
        password_to_finish: finalized?.password_to_finish,
        password_max_date_validation: finalized?.password_max_date_validation,
        password_max_string_validation:
          finalized?.password_max_string_validation,
        finalized_by_user_id: finalized?.finalized_by_user_id,
        general_observation: finalized?.general_observation,
        last_update_user_id: finalized?.last_update_user_id,
        surgical_order_id: finalized?.surgical_order_id,
        created_at: finalized?.created_at,
        updated_at: finalized?.updated_at
      })
    } catch {
      toast.error('Ocorreu um erro ao carregar aba de informações')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getSurgicalOrderInfoFinalized()
  }, [])

  const renderPasswordSection = () => (
    <S.Content>
      <S.Label>Senha</S.Label>
      <S.Description>{surgicalOrder?.password_to_finish}</S.Description>
    </S.Content>
  )

  const renderPasswordValidationSection = () => (
    <S.Content>
      <S.Label>Validade da senha</S.Label>
      <S.Description>
        {moment
          .utc(surgicalOrder?.password_max_date_validation)
          .format('DD/MM/YYYY')}
      </S.Description>
      {surgicalOrder?.password_max_string_validation && (
        <S.Description>
          {surgicalOrder.password_max_string_validation}
        </S.Description>
      )}
    </S.Content>
  )

  const renderGeneralObservationSection = () => (
    <S.Content>
      <S.TextArea>
        <S.Label>Observações gerais</S.Label>
        <S.Description>{surgicalOrder?.general_observation}</S.Description>
      </S.TextArea>
    </S.Content>
  )

  return (
    <S.TabWrapper>
      {/* <SupportTextNew color="neutral700" weight="bold">
        Finalização
      </SupportTextNew> */}
      {surgicalOrder?.password_to_finish && renderPasswordSection()}
      {surgicalOrder?.password_max_date_validation &&
        renderPasswordValidationSection()}
      {surgicalOrder?.general_observation && renderGeneralObservationSection()}
    </S.TabWrapper>
  )
}

export default WithLoading(PlanningTab)
