import { SendDoctorRegisterChangesToAnalysis } from 'domain/usecases/doctor/send-doctor-register-changes-to-analysis'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteSendDoctorRegisterChangesToAnalysis
  implements SendDoctorRegisterChangesToAnalysis
{
  constructor(private readonly doctorRepository: DoctorRepository) {}

  execute(
    params: SendDoctorRegisterChangesToAnalysis.Params
  ): Promise<SendDoctorRegisterChangesToAnalysis.Model> {
    return this.doctorRepository.sendDoctorRegisterChangesToAnalysis(params)
  }
}
