export enum PendencyStatusSingular {
  PENDENT = 'à resolver',
  RESOLVED = 'resolvida',
  IN_PROGRESS = 'em andamento'
}

export enum PendencyStatusPlural {
  PENDENT = 'à resolver',
  RESOLVED = 'resolvidas',
  IN_PROGRESS = 'em andamento'
}
