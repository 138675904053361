import React from 'react'
import * as S from './styles'
import ButtonNew from '../../../components/ButtonNew'
import moment from 'moment-timezone'
import { PatientHealthInsurance } from 'domain/entities/patient-model'
import { useHistory } from 'react-router'

export type Props = {
  healthInsurance?: PatientHealthInsurance
  particular?: boolean
  showOnly?: boolean
  title?: string
  description?: string
  openConfirmationDeleteMenu?: (healthInsuranceId: number) => void
  style?: any
  onClick?: () => void
  disabled?: boolean
}

export default function HealthInsuranceCard({
  style,
  healthInsurance,
  particular = false,
  showOnly = false,
  title,
  description,
  disabled = false,
  onClick,
  openConfirmationDeleteMenu
}: Props) {
  const history = useHistory()
  const startEdition = (healthInsurance: PatientHealthInsurance) => {
    history.push('/convenio/editar', {
      healthInsurance
    })
  }

  return (
    <S.HealthInsuranceCardWrapper
      style={style}
      onClick={onClick}
      className={disabled ? '-disabled' : ''}
    >
      <S.CardHeader>
        {particular ? (
          <>
            <span className="health-insurance-name">Particular</span>
            {title && <p className="health-insurance-title">{title}</p>}
          </>
        ) : (
          <>
            <h3 className="health-insurance-name">
              {healthInsurance?.healthInsuranceCode === 396
                ? healthInsurance?.healthInsuranceName.toUpperCase()
                : healthInsurance?.healthInsuranceName.toLowerCase()}
            </h3>
          </>
        )}
      </S.CardHeader>
      <S.CardBody>
        {particular ? (
          description && <p className="description">{description}</p>
        ) : (
          <>
            <div>
              <strong>Plano:</strong> {healthInsurance?.healthPlanName || '-'}
            </div>
            <div>
              <strong>Carteirinha:</strong> {healthInsurance?.healthCard || '-'}
            </div>
            <div>
              <strong>Validade:</strong>{' '}
              {healthInsurance?.validThru
                ? moment(healthInsurance?.validThru).format('DD/MM/YYYY')
                : '-'}
            </div>
          </>
        )}
      </S.CardBody>
      <S.CardFooter>
        {!particular && !showOnly && (
          <>
            {!!openConfirmationDeleteMenu && (
              <ButtonNew
                style={{ display: 'none' }}
                variant="text"
                color="danger400"
                onClick={() =>
                  openConfirmationDeleteMenu(
                    healthInsurance!.health_insurance_id
                  )
                }
              >
                Excluir
              </ButtonNew>
            )}
            <ButtonNew
              size="small"
              onClick={() =>
                startEdition(healthInsurance as PatientHealthInsurance)
              }
            >
              Editar
            </ButtonNew>
          </>
        )}
      </S.CardFooter>
    </S.HealthInsuranceCardWrapper>
  )
}
