import styled from 'styled-components'
import SupportText from 'presentation/components/SupportText'
import Drawer from '@material-ui/core/Drawer'

export const DrawerWrapper = styled(Drawer)`
  z-index: ${({ theme }) => theme.layers.base - 1} !important;
  .MuiDrawer-paper {
    background-color: #${({ theme }) => theme.ds.colors.primary25};
    padding: 24px 24px 38px;
    font-family: ${({ theme }) => theme.font.family};
  }

  .MuiDrawer-paperAnchorTop {
    top: 72px;
  }
`

export const UserName = styled(SupportText)`
  font-weight: 600;
  display: inline;
  width: fit-content;
  text-transform: capitalize;

  &.is-clickable {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const DependentsList = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
`

export const Card = styled.div`
  background-color: #fff;
  border-color: #a8a8a8;
  border-width: 1px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border-style: solid;
  padding: 8px 16px;
  cursor: pointer;

  &.isActive {
    border-color: ${({ theme }) => theme.ds.colors.primary600} !important;
    border-width: 2px;
    font-weight: 600;
  }

  & > .button {
    font-weight: bold;
  }
`

export const Profile = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  text-overflow: ellipsis;
  text-transform: capitalize;
`
