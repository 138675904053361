import ButtonNew from 'presentation/components/ButtonNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import HeaderNew from 'presentation/components/HeaderNew'
import * as S from './styles'
import { ReactComponent as PreAnesthesicImage } from 'presentation/assets/banners/pre-anesthesic.svg'

const PreAnesthesicScreen = () => {
  const history = useHistory()

  function redirectToSchedule() {
    // history.push('/consultas')

    history.push('/agendamento/consulta/busca', {
      patient: state.patient,
      selectedHealthInsurance: state.selectedHealthInsurance,
      specialtyFromExam: state.specialtyFromExam,
      medicalOrderDocumentIds: state.medicalOrderDocumentIds
    })
  }

  const { state } = useLocation() as any

  function handleNext() {
    history.push('/agendamento/exames')
  }

  return (
    <>
      <HeaderNew
        actualPageOnClick={() => {
          history.push('/agendamento/exames')
        }}
      />

      <ContainerNew>
        <PreAnesthesicImage
          style={{ alignSelf: 'center', marginBottom: '30px' }}
        />
        <S.SpacedWrapper>
          <div>
            <S.PurpleStrong>Agendar consulta pré-anestésica</S.PurpleStrong>

            <S.SubDescription>
              Antes da realização do exame você precisa fazer uma consulta
              pré-anestésica.
            </S.SubDescription>

            <S.SubDescription>Deseja agendar agora?</S.SubDescription>
          </div>

          <S.ButtonPanel>
            <ButtonNew
              color="primary"
              type="button"
              fullWidth
              size="large"
              onClick={redirectToSchedule}
            >
              Agendar pré-anestésica
            </ButtonNew>

            <ButtonNew
              color="primary"
              outlined
              type="button"
              fullWidth
              size="large"
              onClick={handleNext}
            >
              Ir para meus agendamentos
            </ButtonNew>
          </S.ButtonPanel>
        </S.SpacedWrapper>
      </ContainerNew>
    </>
  )
}

export default PreAnesthesicScreen
