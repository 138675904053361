import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Content = styled.div`
  p {
    color: ${({ theme }) => theme.colors.lightGray};
  }
  .p-strong {
    margin-top: 14px;
    color: ${({ theme }) => theme.colors.gray};
    font-weight: 500;
  }
`

export const Form = styled.form`
  position: relative;

  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: space-between;
  height: 40%;
`

export const EditButton = styled.button`
  display: flex;
  background: none;
  border: none;
  align-items: flex-end;
  text-decoration: underline;
  position: absolute;
  right: 0;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.primary};

  img {
    margin-right: 5px;
  }
`
