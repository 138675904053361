import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import BottomMenu from 'presentation/components/BottomMenu'
import { Container } from 'presentation/components/Container'
import { Header } from 'presentation/components/Header'
import List, { ListItem } from 'presentation/shared/components/List'
import PlusIcon from 'presentation/assets/icons/plus.svg'
import CheckIcon from 'presentation/assets/icons/big-check.svg'
import { useServices } from 'presentation/hooks/use-services'
import * as S from './styles'
import { PatientDocument } from 'common/enum/patient-document'
import Button from 'presentation/components/Button'
import {
  WithLoading,
  WithLoadingProps
} from 'presentation/shared/components/HOCs/WithLoading'
import { Document } from 'domain/entities/document'
import { sortReports } from 'presentation/utils/reports/sort-reports'
import { MoreExamReportButton } from './styles'
import { getNextReportIndex } from 'presentation/utils/reports/get-next-index'
import { useStores } from 'presentation/hooks/use-stores'
import { Profile } from 'common/enum/profile'

type Props = {
  surgicalOrderId: number
} & WithLoadingProps

type Reports = {
  documentUri: string
} & Document

export const MedicalReportsLayout = WithLoading(
  ({ surgicalOrderId, setIsLoading }: Props) => {
    const history = useHistory()
    const service = useServices().surgicalOrder
    const [reportList, setReportList] = useState<ListItem[]>([])
    const [isSolicitation, setIsSolicitation] = useState<boolean>(false)
    const [reports, setReports] = useState<any[]>([])
    const currentUser = useStores().currentAccount
    const isPatient =
      currentUser.getCurrentAccount()?.user?.role === Profile.PATIENT

    useEffect(() => {
      ;(async () => {
        setIsLoading(true)
        setReports(
          await service
            .loadSurgicalOrder(surgicalOrderId, [
              'documents {document_id, type, name, documentUri, isMerged, group_id}, isSolicitation'
            ])
            .then((res) => {
              setIsLoading(false)
              setIsSolicitation(!!res.isSolicitation)
              return (
                res.documents?.filter(
                  (doc) => doc.type === PatientDocument.EXAM_REPORT
                ) ?? []
              )
            })
        )
      })()
    }, [])

    function generateReportList(reportsList?: Reports[]) {
      if (reportsList) {
        if (reportsList.length > 0) {
          const concludedReports = reportsList.map((report, index) => {
            const isValidName =
              report.name.startsWith('Laudos') &&
              Number(report.name.split(' ')[1]) > 0
            const title = isValidName ? report.name : `Laudos ${index + 1}`
            return {
              title,
              icon: CheckIcon,
              click: () =>
                history.push('/upload/documentos/laudos-exames', {
                  name: title,
                  surgicalOrderId,
                  isSolicitation,
                  initialValues: [report],
                  title
                })
            }
          })
          return setReportList(sortReports(concludedReports))
        }
      }
      const title = 'Laudos 1'
      return setReportList([
        {
          title,
          icon: PlusIcon,
          click: () =>
            history.push('/upload/documentos/laudos-exames', {
              name: title,
              surgicalOrderId,
              isSolicitation,
              initialValues: [],
              title
            })
        }
      ])
    }

    useEffect(() => {
      generateReportList(reports)
    }, [reports])

    const canAddReport = () => {
      if (isPatient && isSolicitation) return false
      return !reportList.some((reportList) => reportList.icon === PlusIcon)
    }

    return (
      <>
        <Header />
        <Container
          title="Laudos dos exames"
          titleColor="primary"
          subtitle="Você possui laudos que comprovem a necessidade do procedimento cirúrgico? Se sim, gentileza anexar abaixo."
          actualPageText="Documentos"
          actualPageOnClick={history.goBack}
          primaryButton={
            <Button fullWidth type="button" onClick={history.goBack}>
              Voltar
            </Button>
          }
        >
          <S.List>
            <List items={reportList} size="medium" />
          </S.List>
          {canAddReport() && (
            <MoreExamReportButton
              onClick={() => {
                const newReportNumber = getNextReportIndex(reportList)
                const title = `Laudos ${newReportNumber}`
                const unsortedReportList = [
                  ...reportList,
                  {
                    title,
                    icon: PlusIcon,
                    click: () =>
                      history.push('/upload/documentos/laudos-exames', {
                        name: title,
                        surgicalOrderId,
                        isSolicitation,
                        initialValues: [],
                        title
                      })
                  }
                ]
                setReportList(sortReports(unsortedReportList))
              }}
            >
              Adicionar mais laudos
            </MoreExamReportButton>
          )}
        </Container>
        <BottomMenu />
      </>
    )
  }
)
