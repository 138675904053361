import { ListExamResult } from 'domain/usecases/exam/list-exam-result'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteListExamResult implements ListExamResult {
  constructor(private readonly patientRepository: PatientRepository) {}

  async load(): Promise<ListExamResult.Model> {
    return this.patientRepository.listExamResult()
  }
}
