import React, { useState, useContext } from 'react'
import moment from 'moment'

import { ContainerNew } from 'presentation/components/ContainerNew'
import { ReactComponent as RightArrow } from 'presentation/assets/icons/v-arrow.svg'
import SupportTextNew from 'presentation/components/SupportTextNew'
import TabsNew from 'presentation/components/TabsNew'
import { SurgicalOrderContext } from 'main/factories/pages/surgical-order/surgical-order-factory'
import SurgicalOrderCard from 'presentation/components/SurgicalOrderCard'
import PendenciesTab from './Tabs/PendenciesTab'
// import PlanningTab from './Tabs/PlanningTab'
import TimelineTab from './Tabs/TimelineTab'
import {
  SurgicalOrderStatus,
  SurgicalOrdersStatusTranslatedSingular
} from 'common/enum/surgical-orders-status'
import TimelineStatusModal from './Modals/TimelineStatusModal'
import theme from 'presentation/styles/theme'
import * as S from './styles'
import HeadingNew from 'presentation/components/HeadingNew'
import PlanningTab from './Tabs/PlanningTab'
import ButtonNew from 'presentation/components/ButtonNew'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { WithLoadingProps } from 'presentation/shared/components/HOCs/WithLoading'
import { toast } from 'react-toastify'
import SheetModalNew from 'presentation/shared/components/SheetModalNew'
import Divider from 'presentation/shared/components/DividerNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import { capitalizeEachWordText } from 'common/utils/capitalizeEachWordText'
import { useServices } from 'presentation/hooks/use-services'
import { PendencyTypes } from 'presentation/components/PendencyCard'
import { PendencyStatusSingular } from 'common/enum/pendency-status'

type Props = {
  // surgical_order_id: number
  getPendencyHistory: (
    surgical_pendency_id: number,
    pendency_type?: PendencyTypes,
    status?: PendencyStatusSingular
  ) => void
  getSurgicalOrder: () => Promise<void>
  getSurgicalOrderTimeline: () => Promise<void>
} & WithLoadingProps

export const getDateTextFromNow = (date: Date | string) => {
  const dateUtc = moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
  return moment(dateUtc).fromNow()
}

export const getColor = (status: keyof typeof SurgicalOrderStatus) => {
  switch (status) {
    case SurgicalOrderStatus.AUTHORIZED:
    case SurgicalOrderStatus.CONCLUDED:
      return {
        main: 'success700',
        lighten: 'success200'
      }
    case SurgicalOrderStatus.UNDER_REVIEW_AT_THE_HOSPITAL:
    case SurgicalOrderStatus.UNDER_REVIEW_AT_THE_INSURANCE:
      return {
        main: 'secondary700',
        lighten: 'secondary200'
      }
    case SurgicalOrderStatus.CANCELLED:
    case SurgicalOrderStatus.REQUESTED_CANCELLATION:
    default:
      return {
        main: 'neutral700',
        lighten: 'neutral200'
      }
  }
}

export const statusIsSolicitation = (status: SurgicalOrderStatus) =>
  status === SurgicalOrderStatus.ORDER_REGISTERED

export default function SurgicalSchedulingInformationsLayout({
  // surgical_order_id,
  getPendencyHistory,
  setIsLoading,
  getSurgicalOrder,
  getSurgicalOrderTimeline
}: Props) {
  const [isOpenTimelineStatus, setIsOpenTimelineStatus] = useState(false)
  const { state, dispatch } = useContext<any>(SurgicalOrderContext)
  const [isCancellationRequestOpen, setIsCancellationRequestOpen] =
    useState(false)
  const surgicalOrderService = useServices().surgicalOrder

  const orderCreatedAt = moment(state.card?.created_at)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')

  const timelineItemsActions = {
    REQUESTED_CANCELLATION_SURGICAL_ORDER: () => setIsOpenTimelineStatus(true)
  }

  const Tabs = [
    {
      label: 'Pendências',
      componentChildren: (
        <PendenciesTab
          pendencies={state.pendencies}
          getPendencyHistory={getPendencyHistory}
          dispatch={dispatch}
        />
      )
    },
    {
      label: 'Linha do tempo',
      componentChildren: (
        <TimelineTab timelineItemsActions={timelineItemsActions} />
      )
    },
    {
      label: 'Informações',
      componentChildren: <PlanningTab />
    }
  ]

  const openRequestCancellationModal = () => {
    setIsCancellationRequestOpen(true)
  }

  const refreshSurgicalOrder = async () => {
    await getSurgicalOrder()
    await getSurgicalOrderTimeline()
  }

  const formik = useFormik({
    initialValues: {
      cancellation_reason: ''
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        await surgicalOrderService.requestSurgicalOrderCancellation({
          surgical_order_id: state.card.surgical_order_id,
          reason: values.cancellation_reason
        })
        await refreshSurgicalOrder()
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao solicitar cancelamento do pedido cirúrgico'
        )
      } finally {
        setIsLoading(false)
        setIsCancellationRequestOpen(false)
      }
    }
  })

  return (
    <ContainerNew noHeader>
      <HeadingNew
        color="primary600"
        size="large"
        weight="bold"
        style={{ marginBottom: '16px' }}
        as="h1"
      >
        Detalhes do pedido cirúrgico
      </HeadingNew>
      <SurgicalOrderCard surgicalOrder={state.card} hideFooter hideStatusTag />

      <S.StatusCard
        role="button"
        background="neutral25"
        shadow="satin"
        onClick={() => setIsOpenTimelineStatus(true)}
      >
        <div>
          <SupportTextNew
            color={
              getColor(state.card.active_status)
                .main as keyof typeof theme.ds.colors
            }
            weight="bold"
            size="large"
            className="capitalize-fl"
          >
            {
              SurgicalOrdersStatusTranslatedSingular[
                state.footer.status as keyof typeof SurgicalOrderStatus
              ]
            }
          </SupportTextNew>
          {!statusIsSolicitation(state.card.active_status) && (
            <>
              {state.card.active_status ===
              SurgicalOrderStatus.REQUESTED_CANCELLATION ? (
                <SupportTextNew size="small">
                  Solicitação de cancelamento enviada{' '}
                  {getDateTextFromNow(
                    state.card.requested_cancellation?.props?.created_at
                  )}{' '}
                  por{' '}
                  {capitalizeEachWordText(
                    state.card.requested_cancellation?.props?.requested_by.name
                  )}
                  .
                </SupportTextNew>
              ) : (
                <>
                  {orderCreatedAt && (
                    <SupportTextNew size="medium">
                      Pedido criado {moment(orderCreatedAt).fromNow()}
                    </SupportTextNew>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <SupportTextNew color="secondary700" weight="bold" size="large">
          <RightArrow style={{ marginLeft: '16px' }} />
        </SupportTextNew>
      </S.StatusCard>

      {state.card.active_status !== SurgicalOrderStatus.CANCELLED &&
        state.card.active_status !== SurgicalOrderStatus.CONCLUDED &&
        state.card.active_status !==
          SurgicalOrderStatus.REQUESTED_CANCELLATION && (
          <ButtonNew
            fullWidth
            size="large"
            outlined
            onClick={openRequestCancellationModal}
          >
            Cancelar pedido cirúrgico
          </ButtonNew>
        )}

      <TabsNew tabItems={Tabs} />

      <TimelineStatusModal
        setIsLoading={setIsLoading}
        isOpen={isOpenTimelineStatus}
        onClose={() => setIsOpenTimelineStatus(false)}
        status={state.footer.status}
        created_at={state.card.created_at}
        timeline={state.card.status}
        cancellation_request={state.card.requested_cancellation?.props}
        refreshSurgicalOrder={refreshSurgicalOrder}
      />

      <SheetModalNew
        isOpen={isCancellationRequestOpen}
        onClose={() => setIsCancellationRequestOpen(false)}
        title="Cancelar pedido cirúrgico"
      >
        <SupportTextNew
          weight="semiBold"
          size="small"
          color="neutral900"
          style={{ marginBottom: '24px' }}
        >
          Tem certeza que deseja cancelar este pedido cirúrgico?
        </SupportTextNew>
        <SupportTextNew size="small" color="neutral900">
          Esta <b>solicitação</b> será recebida pela CRMO que cuidará do
          processo de cancelamento do seu pedido.
        </SupportTextNew>
        <Divider />
        <TextAreaNew
          label="Qual o motivo do cancelamento?"
          required
          placeholder="Descreva o motivo aqui."
          style={{ marginBottom: '24px' }}
          id="cancellation_reason"
          name="cancellation_reason"
          value={formik.values.cancellation_reason}
          onChange={formik.handleChange}
          error={formik.errors.cancellation_reason}
        />
        <ButtonNew
          fullWidth
          size="large"
          disabled={!!formik.errors.cancellation_reason}
          onClick={formik.submitForm}
        >
          Cancelar pedido cirúrgico
        </ButtonNew>
      </SheetModalNew>
    </ContainerNew>
  )
}

const validationSchema = yup.object().shape({
  cancellation_reason: yup
    .string()
    .required()
    .max(200, 'Máximo 200 caracteres.')
})
