import React, { useContext, useState } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import * as S from './styles'

import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/calendar-icon.svg'
import { ReactComponent as GrayPinIcon } from 'presentation/assets/icons/gray-pin-icon.svg'
import { ReactComponent as SyringeIcon } from 'presentation/assets/icons/syringe-icon.svg'
import { ReactComponent as StethoscopeIconOutline } from 'presentation/assets/icons/stethoscope-outline.svg'
import { ReactComponent as AccordionArrow } from 'presentation/assets/icons/accordion-arrow.svg'

import {
  SchedulingExamContext,
  SchedulingExamModelContext,
  SchedulingExamState
} from '../context'
import moment from 'moment'
import ButtonNew from 'presentation/components/ButtonNew'
import { useHistory } from 'react-router-dom'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import DividerNew from 'presentation/shared/components/DividerNew'
import { ExamGroup, ExamModelNew } from 'domain/entities/exam-model-new'

type ExamOfferProps = Pick<
  SchedulingExamState,
  'selectedExamOffer' | 'use_sedation'
>

type Props = {
  setIsLoading: (value: boolean) => void
  setShowPreparation: (value: boolean) => void
  showPreparation: boolean
  slideTo?: (number: number) => void
  hasParamToken?: boolean
}

type ConfirmCardProps = {
  setIsLoading: (value: boolean) => void
  setShowPreparation: (value: boolean) => void
  setSelectedPreparation: (value: any) => void
  exams: ExamModelNew[]
}

const SuccessExamSchedule = ({
  setIsLoading,
  setShowPreparation,
  showPreparation,
  slideTo,
  hasParamToken
}: Props) => {
  const { state, dispatch } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  const history = useHistory()

  const healthInsuranceInfo = {
    healthInsuranceCode: state.healthInsuranceCode,
    healthInsuranceName: state.healthInsuranceName,
    healthPlanCode: state.healthPlanCode,
    healthPlanName: state.healthPlanName,
    health_card_document_id: state.health_card_document_id,
    health_insurance_id: state.health_insurance_id
  }

  const patientInfo = {
    age: state.age ?? 0,
    gender: state.gender,
    email: state.email,
    name: state.name,
    patient_id: state.patient_id,
    user_id: state.user_id
  }

  function handleNext() {
    if (state.consultPreExams?.length) {
      dispatch({
        type: 'SET_SHOW_PRE_CONSULT',
        payload: true
      })

      slideTo && slideTo(4)
    } else {
      history.push({
        pathname: '/agendamento/exames/avalie',
        state: {
          patient: patientInfo,
          selectedHealthInsurance: healthInsuranceInfo,
          useSedation: state.use_sedation,
          medicalOrderDocumentIds: state.medical_order_document_ids,
          hasParamToken: hasParamToken
        }
      })
    }
  }

  const [selectedPreparation, setSelectedPreparation] = useState({}) as any

  return showPreparation ? (
    <ContainerNew
      title="Instruções de preparo"
      titleColor="neutral700"
      titleWeight="bold"
      titleSize="medium"
    >
      <PreparationInstructions
        selectedPreparation={selectedPreparation}
        setShowPreparation={setShowPreparation}
        setSelectedPreparation={setSelectedPreparation}
      />
    </ContainerNew>
  ) : (
    <ContainerNew
      title="Agendamento realizado com sucesso!"
      titleColor="secondary700"
      titleSize="xlarge"
      titleWeight="bold"
    >
      {state.examsScheduled &&
        state.examsScheduled?.map((examGroup: ExamGroup, index) => (
          <ConfirmExamCard
            key={index}
            setIsLoading={setIsLoading}
            selectedExamOffer={examGroup.selectedExamOffer}
            exams={examGroup.exams}
            use_sedation={examGroup.groupExamsScheduled.use_sedation}
            setShowPreparation={setShowPreparation}
            setSelectedPreparation={setSelectedPreparation}
          />
        ))}

      <S.BlueNote>
        <strong>Lembre-se</strong>
        <p>
          Chegue com 30 minutos de antecedência na recepção e não se esqueça de
          levar o documento de identidade e carteirinha do convênio.
        </p>
      </S.BlueNote>

      <S.ButtonPanel>
        <ButtonNew
          color="primary"
          type="button"
          fullWidth
          size="large"
          onClick={handleNext}
        >
          Próximo
        </ButtonNew>
      </S.ButtonPanel>
    </ContainerNew>
  )
}

export default SuccessExamSchedule

const ConfirmExamCard = ({
  selectedExamOffer,
  use_sedation,
  setIsLoading,
  setShowPreparation,
  setSelectedPreparation,
  exams
}: ExamOfferProps & ConfirmCardProps) => {
  const examService = useServices().exam

  async function handleRedirect(exams: ExamModelNew[]) {
    const examsList = exams.map((exam: ExamModelNew) => {
      return {
        exam_id: exam.exam_ids?.[0],
        name: exam.name
      }
    })

    setIsLoading(true)
    const response = await examService.getExamPreparation({
      exams: examsList,
      hospital_id: +selectedExamOffer.hospital_id
    })

    if (response?.data?.length > 0) {
      setSelectedPreparation(response.data)
      setShowPreparation(true)
    } else {
      toast.error('Instruções de preparo do exame não encontradas')
    }
    setIsLoading(false)
  }

  return (
    <>
      <S.CardContainer>
        <div>
          <S.TitleStrong>{selectedExamOffer.exam_item_title}</S.TitleStrong>

          <S.HorizontalLine />
        </div>

        <S.ContainerInfo>
          <S.Label>
            <CalendarIcon />
            Data agendada
          </S.Label>
          <S.PurpleDescription>
            {moment(selectedExamOffer.selected_hour.date_begin).format(
              `dddd [|] DD/MM/YYYY [às] HH[h]mm`
            )}
          </S.PurpleDescription>
        </S.ContainerInfo>

        {selectedExamOffer?.doctor?.name && (
          <S.ContainerInfo>
            <S.Label>
              <StethoscopeIconOutline width={15} />
              Médico
            </S.Label>
            <S.SubDescription>
              Dr(a) {selectedExamOffer?.doctor?.name}{' '}
            </S.SubDescription>
          </S.ContainerInfo>
        )}

        <S.ContainerInfo>
          <S.Label>
            <GrayPinIcon />
            Unidade de atendimento
          </S.Label>
          <S.SubDescription> {selectedExamOffer.unit.name} </S.SubDescription>
          <S.AddressDescription>
            {' '}
            {selectedExamOffer.unit.address}
          </S.AddressDescription>
        </S.ContainerInfo>

        <S.ContainerInfo>
          <S.Label>
            <SyringeIcon />
            Vai realizar sedação?
          </S.Label>
          <S.SubDescription>{use_sedation ? 'Sim' : 'Não'} </S.SubDescription>
        </S.ContainerInfo>

        <S.ContainerInfo>
          <ButtonNew
            fullWidth
            size="large"
            onClick={() => handleRedirect(exams)}
          >
            Ver instruções de preparo
          </ButtonNew>
        </S.ContainerInfo>
      </S.CardContainer>
    </>
  )
}

const PreparationInstructions = ({
  selectedPreparation,
  setShowPreparation
}: any) => {
  return (
    <S.Wrapper>
      {selectedPreparation?.length > 0 &&
        selectedPreparation.map((examPreparation: any) => {
          return (
            <PreparationAccordion
              key={examPreparation.exam_id}
              examPreparation={examPreparation}
            />
          )
        })}
      <S.ButtonPreparation>
        <ButtonNew onClick={() => setShowPreparation(false)} outlined fullWidth>
          Voltar
        </ButtonNew>
      </S.ButtonPreparation>
    </S.Wrapper>
  )
}

const PreparationAccordion = ({ examPreparation }: any) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <S.AccordionContainer
        isVisible={isVisible}
        onClick={() => setIsVisible(!isVisible)}
      >
        <S.PurpleStrong className="capitalize-fl">
          {examPreparation.title}
        </S.PurpleStrong>

        <div>
          <AccordionArrow />
        </div>
      </S.AccordionContainer>
      {!isVisible && (
        <div onClick={() => setIsVisible(!isVisible)}>
          <S.SubTitleHeader>{examPreparation.description}</S.SubTitleHeader>
        </div>
      )}
      <DividerNew />
    </>
  )
}
