export const phoneMask = [
  '+',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const phoneMaskParenthesis = [
  '+',
  /\d/,
  /\d/,
  ' ',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const landlinePhoneMask = [
  '+',
  /\d/,
  /\d/,
  ' ',
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
]

export const rgMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/
]

export const dateMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const dateTimeMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/
]

export const temperatureMask = [/\d/, /\d/, '.', /\d/, ' °C']

export const ufMask: (string | RegExp)[] = [/^[A-Z]*$/, /^[A-Z]*$/]

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

export const nameMask = (name: string): (string | RegExp)[] => {
  return name.split('').map(() => /^[a-zA-Z\xC0-\uFFFF\s]+$/gi)
}

export const RgRegex = /^\d{0,10}(\d|x|X)$/i

export function formatMask(mask: (string | RegExp)[], value: string) {
  if (!value) return ''
  const chars = value.split('')

  let masked = ''

  for (let i = 0, k = 0; i < mask.length; i++) {
    const maskElement = mask[i]
    if (maskElement instanceof RegExp) {
      masked += maskElement.test(chars[k]) ? chars[k] : ''
      k++
    } else {
      masked += maskElement
    }
  }

  return masked
}

export function formatPhone(phone?: string) {
  return formatMask(phoneMask, phone ?? '')
}

export function formatTelephoneWithParenthesis(phoneNumber?: string) {
  const cleanNumber = phoneNumber?.replace(/\D/g, '')

  const formatedNumber = cleanNumber?.replace(
    /^(\d{2})(\d{2})(\d{9})$/,
    '+$1 ($2) $3'
  )

  return formatedNumber
}
