import styled, { css } from 'styled-components'
import theme from 'presentation/styles/theme'

export const SchedulingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  background-color: ${theme.ds.colors.white};
  overflow: auto;
  height: 100%;
`

export const CardSchedulingInfos = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.ds.typography.fontFamily};
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: ${theme.ds.colors.white};
    padding: 0 24px 32px;
    /* box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.06); */

    > .header {
      display: flex;
      align-items: center;

      .img {
        position: relative;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .header-infos {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > .title {
          font-size: ${theme.ds.typography.size.xsmall};
          color: ${theme.ds.colors.neutral900};
          font-weight: ${theme.ds.typography.weight.semiBold};
          line-height: ${theme.ds.typography.lineHeight.medium};

          span {
            text-transform: capitalize;
            font-weight: ${theme.ds.typography.weight.bold};
            font-size: ${theme.ds.typography.size.medium};
          }
        }

        > .subtitle {
          display: flex;
          align-items: center;
          font-size: ${theme.ds.typography.size.xsmall};
          color: ${theme.ds.colors.neutral600};
          font-weight: ${theme.ds.typography.weight.normal};
          line-height: ${theme.ds.typography.lineHeight.medium};
          text-transform: capitalize;

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
    }

    > .content {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      > .box {
        display: flex;
        align-items: flex-start;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          flex-shrink: 0;
        }

        > .infos {
          > .title,
          > .date-box {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral700};
            font-weight: ${theme.ds.typography.weight.bold};
            line-height: ${theme.ds.typography.lineHeight.medium};

            .date,
            &.-capitalize {
              text-transform: capitalize;
            }
          }

          > .description {
            font-size: ${theme.ds.typography.size.xsmall};
            color: ${theme.ds.colors.neutral600};
            font-weight: ${theme.ds.typography.weight.normal};
            line-height: ${theme.ds.typography.lineHeight.medium};

            &.-capitalize {
              text-transform: capitalize;
            }
          }
        }
      }
    }

    > .footer {
      .text {
        font-size: ${theme.ds.typography.size.xsmall};
        color: ${theme.ds.colors.neutral700};
        font-weight: ${theme.ds.typography.weight.normal};
        line-height: ${theme.ds.typography.lineHeight.medium};

        b {
          font-weight: ${theme.ds.typography.weight.bold};
        }

        a {
          color: ${theme.ds.colors.primary600};
          text-decoration: none;
          font-weight: ${theme.ds.typography.weight.bold};
          word-wrap: break-word;

          &:hover {
            color: ${theme.ds.colors.primary800};
          }
        }
      }
    }
  `}
`

export const CardInfo = styled.div`
  ${({ theme }) => css`
    margin-top: 4px;
    padding: 16px;
    border-radius: 8px;
    background-color: ${theme.ds.colors.primary25};
    .title {
      font-size: ${theme.ds.typography.size.small};
      font-weight: ${theme.ds.typography.weight.semiBold};
      color: ${theme.ds.colors.primary800};
      line-height: ${theme.ds.typography.lineHeight.medium};
    }

    .paragraph {
      font-size: ${theme.ds.typography.size.xsmall};
      color: ${theme.ds.colors.neutral700};
      font-weight: ${theme.ds.typography.weight.normal};
      line-height: ${theme.ds.typography.lineHeight.medium};
    }
  `}
`
