import { GetScheduleDocuments } from 'domain/usecases/document/get-schedule-documents'
import { DocumentRepository } from 'repository/interfaces/document-repository'

export class RemoteGetScheduleDocuments implements GetScheduleDocuments {
  constructor(private readonly documentsRepository: DocumentRepository) {}

  async get(): Promise<GetScheduleDocuments.Model> {
    return await this.documentsRepository.getScheduleDocuments()
  }
}

export type GetScheduleDocumentsModel = GetScheduleDocuments.Model
