import React, { HTMLAttributes } from 'react'

import { ReactComponent as AttachmentIcon } from 'presentation/assets/icons/primary-attachment-icon.svg'
import * as S from './styles'

export type Props = {
  showAttachmentIcon?: boolean
  label?: string
} & HTMLAttributes<HTMLDivElement>

const AdvancedButton = ({
  label = 'Documentos, exames, Laudos e Relatórios Anexos',
  showAttachmentIcon = false,
  ...props
}: Props) => {
  return (
    <S.Button
      showAttachmentIcon={showAttachmentIcon}
      {...props}
      data-testid="documents-exams-reports-button"
    >
      {showAttachmentIcon ? <AttachmentIcon /> : null}
      <p>{label}</p>
      <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.485917 15.2416L6.94217 8.99161L0.485917 2.74164C0.331863 2.59251 0.209661 2.41546 0.126287 2.22061C0.042913 2.02576 3.24646e-09 1.81692 0 1.60602C-3.24646e-09 1.39511 0.0429131 1.18627 0.126287 0.99142C0.209661 0.796569 0.331863 0.619523 0.485917 0.470391C0.639972 0.321258 0.822861 0.20296 1.02414 0.12225C1.22543 0.0415404 1.44116 -3.14273e-09 1.65902 0C1.87689 3.14273e-09 2.09262 0.0415404 2.2939 0.12225C2.49519 0.20296 2.67808 0.321258 2.83213 0.470391L10.4698 7.86404C11.1188 8.49225 11.1188 9.50707 10.4698 10.1353L2.83213 17.5289C2.67819 17.6783 2.49534 17.7967 2.29404 17.8776C2.09274 17.9584 1.87695 18 1.65902 18C1.44109 18 1.22531 17.9584 1.02401 17.8776C0.822711 17.7967 0.639858 17.6783 0.485917 17.5289C-0.146396 16.9007 -0.163036 15.8698 0.485917 15.2416Z"
          fill="#D9D9D9"
          className="arrow-svg"
        />
      </svg>
    </S.Button>
  )
}

export default AdvancedButton
