import React from 'react'
import { TermsTypeEnum } from 'common/enum/terms-type'

export default function TermConfidentialityConsent() {
  return {
    name: TermsTypeEnum.TERM_CONFIDENTIALITY_CONSENT,
    title: 'Termo de confidencialidade e consentimento',
    content: (
      <>
        {/* <iframe
          src="https://production-exp-paciente-public-assets.s3.amazonaws.com/terms/Termo+de+confidencialidade.pdf"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            paddingBottom: '0'
          }}
        /> */}
        <div>
          <p>
            A presente política contém informações sobre coleta, uso,
            armazenamento, tratamento e proteção dos dados pessoais dos
            usuários, e visitantes do aplicativo e/ou site, com a finalidade de
            demonstrar a absoluta transparência quanto ao assunto e esclarecer
            todos os interessados sobre os tipos de dados que serão coletados,
            para o que usamos, onde armazenamos e com quem compartilhamos.
          </p>
          <p>
            Esta Política de Privacidade e Proteção de Dados Pessoais expressa
            nosso compromisso com o tratamento de seus dados pessoais de modo
            responsável, ético, em linha com nossos princípios e valores e,
            especialmente, de acordo com as regras da Lei nº 13.709/2018 (Lei
            Geral de Proteção de Dados Pessoais - “LGPD”) e demais legislações
            vigentes aplicáveis. Reconhecemos que esta é uma responsabilidade
            constante e, portanto, atualizaremos periodicamente esta Política à
            medida que se fizerem necessários novos procedimentos de privacidade
            e proteção de seus dados pessoais.
          </p>
          <p>
            Ao utilizar nossos serviços, bem como nosso site ou aplicativo, você
            concorda com o tratamento de seus dados pessoais como está descrito
            nesta Política de Privacidade e Proteção de Dados Pessoais.
          </p>
        </div>
        <div>
          <h2>• Como coletamos os seus dados pessoais:</h2>
          <p>
            Coletamos seus dados pessoais e dados pessoais sensíveis
            automaticamente quando você visita nosso site, utiliza nossos
            aplicativos, e-mails ou anúncios. Também coletamos seus dados
            pessoais durante a prestação de nossos serviços. Assim, a coleta de
            dados pode advir das seguintes fontes:
          </p>
          <ul>
            <li>
              <strong>
                •&emsp;Diretamente do titular: podemos coletar dados pessoais
                que você nos forneceu;
              </strong>
            </li>
            <li>
              •&emsp;Via interação com o nosso site e/ou aplicativo: através de
              sua interação em nosso site, nosso aplicativo, podemos coletar
              dados pessoais sobre você para a prestação de serviços . Além
              disso, podemos coletar ou obter dados pessoais sobre você pela
              maneira como você interage com nosso site e/ou aplicativo para
              fornecer informações que acreditamos ser de seu interesse;
            </li>
            <li>
              •&emsp;A partir de terceiros: também podemos coletar seus dados
              pessoais por meio de parceiros, médicos ou fornecedores. Neste
              caso, adotamos medidas para garantir que foram cumpridas as regras
              de privacidade e proteção de dados pessoais, dispostas na LGPD,
              incluindo a coleta de termo de consentimento, se assim for
              necessário.
            </li>
          </ul>
        </div>
        <div>
          <h2>• Tipos de dados pessoais que coletamos:</h2>
          <p>
            Clientes e usuários do nosso site/aplicativo: durante a prestação de
            nossos serviços ou mesmo durante a navegação em nosso site e
            aplicativo, podemos coletar alguns dados pessoais sobre você, tais
            como:
          </p>
          <ul>
            <li>
              •&emsp;Dados de identificação: nome, RG, CPF, idade, sexo,
              endereço de e-mail, endereço comercial/residencial, telefone, data
              de nascimento, estado civil, carteirinha do plano, filiação,
              convênio médico, documentos para fins de identidade (tais como
              documentos de conselho de classe, carteira nacional de
              habilitação, número do passaporte e outros documentos oficiais) e
              imagens de circuito interno de câmeras;
            </li>
            <li>
              •&emsp;Dados sensíveis: dados relacionados a sua saúde, relatórios
              médicos, solicitações médicas, resultados de exames, medicamentos
              que você utiliza, dados genéticos, dados biométricos, etnia,
              orientação sexual e religião;
            </li>
            <li>
              •&emsp;Dados financeiros: informações sobre pagamento, conta
              bancária e dados de cartões de crédito;
            </li>
            <li>
              •&emsp;Dados de navegação: login e senha de acesso, postagens em
              nossas mídias sociais, endereço IP, tipo de navegador e idioma,
              horários de acesso, detalhes de solicitações e de como você usa
              nossos serviços e de sua interação conosco. Além disso e prezando
              pela transparência na relação com nossos clientes sinalizamos que
              também coletamos cookies quando você acessa nosso site e/ou
              aplicativo, para melhor experiência do usuário;
            </li>
            <li>
              •&emsp;Acompanhantes de clientes: durante a prestação de nossos
              serviços podemos coletar informações sobre seus acompanhantes
              presentes durante a realização de algum exame ou procedimento;
            </li>
            <li>
              •&emsp;Dados de identificação: nome, RG, CPF e imagens de circuito
              interno de câmeras;
            </li>
            <li>
              •&emsp;Médicos: durante a prestação de nossos serviços podemos
              coletar informações sobre os médicos responsáveis pelas
              solicitações dos exames;
            </li>
            <li>
              •&emsp;Dados de identificação: nome, especialidade profissional e
              CRM.
            </li>
          </ul>
        </div>
        <div>
          <h2>• Como utilizamos seus dados pessoais</h2>
          <p>
            Coletamos, armazenamos e tratamos seus dados pessoais para diversas
            finalidades ligadas ao nosso negócio, tais como:
          </p>
          <h3>Clientes e usuários do nosso site/aplicativo:</h3>
          <ul>
            <li>
              •&emsp;Dados identificação: para identificar e confirmar sua
              identidade para atendimentos, solicitar autorização junto às
              operadoras de planos de saúde, realizar agendamentos, divulgar
              produtos e serviços, assegurar o acesso às unidades do Hospital
              Mater Dei e atender às determinações legais e regulatórias;
            </li>
            <li>• Dados sensíveis: entender histórico;</li>
            <li>
              •&emsp;Dados financeiros: identificar, agendar e faturar
              pagamentos e atender questões fiscais, legais e regulatórias;
            </li>
            <li>
              •&emsp;Dados de navegação: agendar procedimentos e realizar
              atendimentos on-line, cumprir determinações legais de coleta de
              dados pessoais dispostas no Marco Civil da Internet (Lei nº
              12.965/2014), promover melhorias na experiência de navegação e
              realizar análises estatísticas;
            </li>
            <li>•&emsp;Acompanhantes de clientes;</li>
            <li>
              •&emsp;Dados de identificação: para fins de identificação e
              controle de acesso.
            </li>
          </ul>
          <h3>Médicos:</h3>
          <ul>
            <li>
              •&emsp;Dados de identificação: para fins de identificação,
              confirmação da solicitação médica e registro.
            </li>
          </ul>
        </div>
        <div>
          <h2>• Fundamentos legais para tratar os seus dados pessoais</h2>
          <p>
            A LGPD dispõe que o tratamento de dados pessoais apenas deve ocorrer
            mediante fundamento legal.
          </p>
          <p>
            Assim, destacamos abaixo as hipóteses legais em que os seus dados
            pessoais poderão ser tratados, quando estamos na posição de
            Controlador, aplicando-se de acordo com a categoria dos dados (dados
            pessoais ou dados pessoais sensíveis):
          </p>
          <ul>
            <li>
              a.&emsp;mediante o fornecimento do seu consentimento para
              tratamento de seus dados pessoais, como por exemplo, para lhe
              conceder acesso ao site, aplicativo ou outras plataformas mantidas
              pelo Hospital ou receber informações via e-mail sobre seus
              interesses;
            </li>
            <li>
              b.&emsp;quando existentes legítimos interesses para tratamento de
              seus dados pessoais como no oferecimento e entrega de nossos
              serviços para você, bem como para o funcionamento eficaz e lícito
              de nossa prestação de serviços, desde que tais interesses não
              sejam superados pelos seus interesses, direitos e liberdades
              fundamentais;
            </li>
            <li>
              c.&emsp;para o cumprimento de obrigações legais e regulatórias que
              podem exigir a coleta, armazenamento e compartilhamento de seus
              dados pessoais e dados pessoais sensíveis;
            </li>
            <li>
              d.&emsp;para executar eventual contrato, bem como para fornecer
              nossos serviços a você;
            </li>
            <li>
              e.&emsp;para exercer regularmente nossos direitos em contratos,
              processos judiciais, administrativos ou arbitrais;
            </li>
            <li>
              f.&emsp;para proteção da vida ou da sua incolumidade física;
            </li>
            <li>g.&emsp;para tutelar sua saúde;</li>
            <li>h.&emsp;para proteção de nosso crédito;</li>
            <li>
              i.&emsp;para garantir a prevenção à fraude e à sua segurança, nos
              processos de identificação e autenticação de cadastro em sistemas
              eletrônicos;
            </li>
          </ul>
        </div>
        <div>
          <h2>• Compartilhamento de seus dados pessoais</h2>
          <p>
            No decorrer da prestação de nossos serviços, poderemos compartilhar
            seus dados pessoais com:
          </p>
          <ul>
            <li>
              •&emsp;Hospitais, profissionais médicos e unidades de atendimento
              do Hospital para, por exemplo, exercer regularmente algum direito
              ou executar devidamente os serviços contratados;
            </li>
            <li>
              •&emsp;Terceiros que nos prestam serviços nas condições de
              operadores de tratamento de dados pessoais;
            </li>
            <li>
              •&emsp;Autoridades competentes (incluindo tribunais e autoridades
              que nos regulam);
            </li>
            <li>
              •&emsp;Empresas de tecnologia que fazem a gestão dos nossos
              sistemas integrados ou responsáveis pelo armazenamento e garantia
              de segurança no tratamento de seus dos dados pessoais;
              internamente para áreas que necessitam ter acesso aos dados
              pessoais.
            </li>
          </ul>
          <p>
            Ao realizar interação com nosso site ou aplicativo, que
            eventualmente permitam compartilhar conteúdo com outros usuários, os
            dados pessoais e informações que você publicar podem ser lidas,
            coletadas e usadas por outros usuários do site ou aplicativo.
          </p>
          <p>
            Temos pouco ou nenhum controle sobre esses outros usuários e,
            portanto, não podemos garantir que qualquer informação ou dado
            pessoal que você forneça nesse contexto será tratado de acordo com
            esta Política de Privacidade e Proteção de Dados Pessoais.
          </p>
          <p>
            Além disso, ao realizar solicitações de atendimento, agendamentos,
            pré-atendimentos e fale conosco pelo nosso site, seus dados pessoais
            serão compartilhados com nossos atendentes e prestadores de serviços
            de infraestrutura dos sistemas geridos pelo Hospital, os quais são
            treinados e capacitados a tratar seus dados pessoais de forma ética
            e em linha com esta Política.
          </p>
          <p>
            Outros compartilhamentos podem ser realizados com a finalidade de
            atender as suas solicitações e prestar devidamente os serviços
            contratados. Para informações detalhadas sobre os nomes dos
            terceiros com os quais compartilhamos seus dados pessoais, entre em
            contato com o(a) nosso(a) DPO/Encarregado.
          </p>
        </div>
        <div>
          <h2>• Dados de Crianças e Adolescentes</h2>
          <p>
            Durante a prestação de serviços, o Hospital poderá coletar dados
            pessoais de crianças e adolescentes e garantirá que o tratamento
            sempre ocorra no melhor interesse da criança e do adolescente.
          </p>
          <p>
            Crianças: O tratamento de dados pessoais de crianças será realizado
            mediante consentimento específico e em destaque de pelo menos um de
            seus pais ou responsável legal. Em caso de urgência/emergência, a
            coleta e tratamento dos dados pessoais da criança será realizado
            imediatamente para proteção de sua vida e, posteriormente, será
            comunicado a um de seus pais ou responsável legal. As mesmas
            disposições serão aplicáveis aos dados pessoais de interditados e
            curatelados.
          </p>
          <p>
            Adolescentes: Em relação ao adolescente, o tratamento de seus dados
            pessoais poderá ocorrer independentemente do consentimento de um dos
            pais ou responsável legal, desde que presente outro fundamento legal
            que autorize o tratamento, nos termos da LGPD.
          </p>
        </div>
        <div>
          <h2>• Retenção de seus dados pessoais</h2>
          <p>
            Armazenamos e mantemos seus dados pessoais de forma segura em data
            centers localizados no Brasil, em conformidade com a legislação
            aplicável e pelo período necessário ou permitido em vista das
            finalidades para as quais os dados pessoais foram coletados,
            conforme exposto nesta Política de Privacidade e Proteção de Dados
            Pessoais.
          </p>
        </div>
        <div>
          <h2>• Segurança de seus dados pessoais</h2>
          <p>
            Estamos comprometidos em proteger a sua privacidade e seus dados.
            Para isso, adotamos medidas de segurança, técnicas e administrativas
            aptas a proteger os seus dados pessoais de acessos não autorizados e
            de situações acidentais ou ilícitas de destruição, perda, alteração,
            comunicação ou qualquer forma de tratamento inadequado ou ilícito, o
            que inclui, mas não se limita à:
          </p>
          <ul>
            <li>
              •&emsp;Limitação do acesso a dados pessoais por parte dos
              colaboradores, prestadores de serviços e visitantes,
              restringindo-o apenas nos limites da necessidade e finalidade de
              tratamento dos dados pessoais;
            </li>
            <li>
              •&emsp;Garantia de que os nossos colaboradores, prestadores de
              serviços e visitantes cumpram esta Política e todos os
              procedimentos adequados para o correto tratamento de dados
              pessoais;
            </li>
            <li>
              •&emsp;Utilização de tecnologias concebidas para proteger os dados
              pessoais durante a sua transmissão, como encriptação SSL dos dados
              que você fornece em determinadas partes do nosso site e utilização
              de segurança adequada para proteger os dados pessoais recebidos.
            </li>
          </ul>
          <p>
            Também aplicamos processos e medidas, padrão da indústria para a
            detecção e resposta a tentativas de violação dos nossos sistemas.
            Entretanto, não existe um método de transmissão pela Internet ou um
            método de armazenamento eletrônico que seja 100% seguro. Por
            conseguinte, não podemos garantir a segurança absoluta das suas
            informações, apesar de serem tomadas todas as precauções necessárias
            e exigidas pelos órgãos competentes.
          </p>
          <p>
            A Internet, dada a sua natureza, é um fórum público e por isso
            recomendamos que você tenha cautela ao divulgar informações online.
            Frequentemente, você se encontra na melhor posição para proteger a
            si mesmo online. Você é responsável pela proteção do seu nome de
            usuário e senha contra o acesso de terceiros, assim como pela
            escolha de senhas seguras.
          </p>
        </div>
        <div>
          <h2>• Seus direitos</h2>
          <p>
            Você possui vários direitos em relação aos seus dados pessoais, nos
            termos da LGPD. Para tanto, implementamos controles adicionais de
            transparência e acesso em nossa área de Privacidade para
            disponibilizar aos usuários o acesso livre e gratuito a esses
            direitos. Neste contexto, você tem o direito a:
          </p>
          <ul>
            <li>
              •&emsp;Confirmação de que estamos tratando seus dados pessoais;
            </li>
            <li>•&emsp;Acessar os dados pessoais que tratamos sobre você;</li>
            <li>
              •&emsp;Solicitar a alteração ou atualização de seus dados pessoais
              quando estiverem incorretos, incompletos ou inexatos;
            </li>
            <li>
              •&emsp;Se opor ao tratamento de dados pessoais, quando não
              tivermos mais necessidade legítima ou legal de trata-los;
            </li>
            <li>
              •&emsp;Solicitar a transmissão dos dados pessoais que tratamos
              sobre você para outro fornecedor;
            </li>
            <li>
              •&emsp;Solicitar informações das entidades públicas e privadas com
              as quais compartilhamos seus dados pessoais;
            </li>
            <li>
              •&emsp;Revogar o consentimento concedido, solicitar a eliminação
              dos dados pessoais tratados com base em consentimento, bem como de
              ter acesso a informações sobre a possibilidade de você não
              fornecer o consentimento e as respectivas consequências da
              negativa; e
            </li>
            <li>
              •&emsp;Solicitar a revisão do tratamento de dados pessoais com
              base em decisões automatizadas.
            </li>
          </ul>
        </div>
        <div>
          <h2>
            • Alterações nesta Política de Privacidade e Proteção de Dados
            Pessoais
          </h2>
          <p>
            A presente Política de Privacidade e Proteção de Dados Pessoais
            poderá ser alterada a qualquer tempo. Portanto, recomendamos que
            você reveja esta Política de tempos em tempos para ser informado
            sobre como estamos protegendo suas informações.
          </p>
          <p>
            Todas as alterações serão comunicadas por meio de um aviso em
            destaque na tela inicial do nosso site/aplicativo ou por meio de
            qualquer outra forma de comunicação com você.
          </p>
        </div>
        <div>
          <h2>• Reclamações, dúvidas e/ou solicitações</h2>
          <p>
            Se você não estiver satisfeito com a maneira como tratamos os seus
            dados pessoais ou em caso de qualquer dúvida, reclamação,
            preocupação ou solicitações relacionadas a sua privacidade e
            proteção de seus dados pessoais, você pode entrar em contato com
            o(a) nosso(a) DPO/Encarregado(a) pelo envio de e-mail para o
            endereço eletrônico:{' '}
            <a
              href="mailto:meumaterdei@materdei.com.br"
              target="_blank"
              rel="noreferrer"
              title="meumaterdei@materdei.com.br"
            >
              meumaterdei@materdei.com.br
            </a>
          </p>
        </div>
      </>
    ),
    acceptMessage: `Declaro que li e aceito as condições do Termo de confidencialidade e consentimento`
  }
}
