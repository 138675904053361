import styled from 'styled-components'

export const MessageHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  .header-text {
    line-height: 1.4em;
  }
`

export const MessageContent = styled.div``
