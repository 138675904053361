import React, { useContext, useEffect, useState } from 'react'
import ButtonNew from 'presentation/components/ButtonNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import DividerNew from 'presentation/shared/components/DividerNew'
import * as S from './styles'
import { SchedulingExamContext, SchedulingExamModelContext } from '../context'
import { NavigationProps } from '../utils'
import { ReactComponent as SearchIcon } from 'presentation/assets/icons/search-icon.svg'
import AutoCompleteNew from 'presentation/components/AutoCompleteNew'
import { useServices } from 'presentation/hooks/use-services'
import { toast } from 'react-toastify'
import { ExamCardNew } from 'presentation/components/ExamCardNew'
import { useHistory, useLocation } from 'react-router-dom'
import { SchedulingSearchFilterType } from 'common/enum/scheduling-search-filter-type'
import { ReactComponent as MedicalAppointmentImage } from 'presentation/assets/banners/medical-appointment.svg'
import { ExamModelNew, ExamSearchType } from 'domain/entities/exam-model-new'
import { generateHash } from 'common/utils/generateHash'
import { SurgeryType } from 'common/enum/surgery-type'

type Props = {
  setIsLoading: (value: boolean) => void
  slideToHealthInsurance: () => void
  showMedicalAppointmentRedirectScreen: boolean
  setShowMedicalAppointmentRedirectScreen: (value: boolean) => void
}

const SearchExams = ({
  prev,
  next,
  activeIndex,
  setIsLoading,
  slideToHealthInsurance,
  slideTo,
  showMedicalAppointmentRedirectScreen,
  setShowMedicalAppointmentRedirectScreen
}: NavigationProps & Props) => {
  const { dispatch, state } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext
  const [messageExamsError, setMessageExamsError] = useState('')

  useEffect(() => {
    if (activeIndex === 4) {
      resetSelectedExam()
      setIsLoading(false)
    }
  }, [activeIndex])

  function handleDelete(exam: ExamModelNew) {
    let filteredExams

    if (exam?.type === ExamSearchType.SURGERY) {
      if (!exam.locatedSurgeryId) return
      filteredExams = state.exams.filter((item: ExamModelNew) => {
        return exam.locatedSurgeryId !== item.locatedSurgeryId && item
      })
    } else {
      const currentExamId = exam?.item_ids?.[0]
      if (!currentExamId) return
      filteredExams = state.exams.filter((item: ExamModelNew) => {
        return currentExamId !== item.item_ids?.[0] && item
      })
    }

    dispatch({
      type: 'UPDATE_SELECTED_EXAMS',
      payload: filteredExams
    })
  }

  function handleDeleteUnavailableExam(item_id?: number) {
    if (!item_id) return
    const filteredUnavailableExams = state.unavailableExams.filter(
      (exam: ExamModelNew) => {
        return item_id !== exam.item_ids?.[0] && exam
      }
    )

    dispatch({
      type: 'UPDATE_SELECTED_UNAVAILABLE_EXAMS',
      payload: filteredUnavailableExams
    })
  }

  function handleAddUnavailableExam(exam: ExamModelNew) {
    const foundExam = state?.unavailableExams?.some((ex) => {
      return exam.item_ids?.[0] === ex.item_ids?.[0]
    })

    if (foundExam) {
      return toast.error('Exame já adicionado')
    }

    let unavailableExamsList = [] as any
    if (state?.unavailableExams?.length) {
      unavailableExamsList = [...state.unavailableExams]
      unavailableExamsList.push(exam)
    } else {
      unavailableExamsList = [exam]
    }

    dispatch({
      type: 'UPDATE_SELECTED_UNAVAILABLE_EXAMS',
      payload: unavailableExamsList
    })
  }

  function handleAddExam(exam: ExamModelNew) {
    let examItem
    if (exam.type === ExamSearchType.SURGERY) {
      examItem = {
        ...exam,
        locatedSurgeryId: generateHash()
      }
    } else {
      examItem = exam
    }

    let examsList = [] as any
    if (state?.exams?.length) {
      examsList = [...state.exams]
      examsList.push(examItem)
    } else {
      examsList = [examItem]
    }
    dispatch({
      type: 'UPDATE_SELECTED_EXAMS',
      payload: examsList
    })
  }

  const examService = useServices().exam
  const [examQuery, setExamQuery] = useState<string>()
  const [examSearchResult, setExamsSearchResult] = useState() as any

  const resetSelectedExam = async () => {
    setExamQuery(undefined)
    setExamsSearchResult([])
  }

  const searchExams = async (input: string) => {
    setExamsSearchResult(undefined)
    const response = await examService.searchExams({
      search: input,
      patient: {
        // user_id: state.user_id,
        // patient_id: state.patient_id,
        // age: state.age ?? 0,
        gender: state.gender === 'Feminino' ? 'F' : 'M'
      }
    })

    setExamsSearchResult(response)
  }

  const locationState = useLocation().state as any

  const selectExam = async (exam: ExamModelNew) => {
    const foundExam = state?.exams?.some((ex) => {
      return exam.item_ids?.[0] === ex.item_ids?.[0]
    })

    if (foundExam) {
      return toast.error('Exame já adicionado')
    }

    try {
      if (exam) {
        setExamQuery(exam.name)
      }

      if (locationState?.selectedHealthInsurance) {
        dispatch({
          type: 'SELECT_HEALTH_INSURANCE',
          payload: locationState?.selectedHealthInsurance
        })

        dispatch({
          type: 'UPDATE_HEALTH_CARD_DOCUMENT_ID',
          payload:
            locationState?.selectedHealthInsurance?.health_card_document_id
        })

        dispatch({
          type: 'UPDATE_MEDICAL_ORDER_DOCUMENT_ID',
          payload: locationState?.medicalOrderDocumentIds
        })
      }

      if (locationState?.selectedPatient) {
        dispatch({
          type: 'UPDATE_PATIENT_DATA',
          payload: locationState?.selectedPatient
        })
      }

      history.replace({ ...history.location, state: undefined })

      setIsLoading(true)
      if (exam.type === ExamSearchType.EXAM) {
        const response = await examService.getExamAvailableUnits({
          health_insurance_code:
            state.healthInsuranceCode ||
            locationState.selectedHealthInsurance?.healthInsuranceCode,
          plan_code:
            state.healthPlanCode ||
            locationState.selectedHealthInsurance?.healthPlanCode,
          exam_ids: exam.exam_ids
        })
        if (response) {
          if (!response?.data?.length) {
            handleAddUnavailableExam(exam)
            return toast.error(
              'Nenhuma unidade foi encontrada para o exame e convênio selecionado'
            )
          } else {
            handleAddExam(exam)
            resetSelectedExam()
          }
        }
      }

      if (exam.type === ExamSearchType.SURGERY) {
        handleAddExam(exam)
        resetSelectedExam()
      }
    } catch (error: any) {
      if (error.name === 'AgreementDoesNotCoverExam') {
        setMessageExamsError(error.message)
        handleAddUnavailableExam(exam)
      } else {
        toast.error(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  function handleNext() {
    if (!state.exams?.length) {
      return toast.error('Selecione pelo menos um exame')
    } else {
      next()
    }
  }

  const history = useHistory()
  const schedulingService = useServices().scheduling

  const searchSpecialties = async (input: string) => {
    if (input) {
      const res = await schedulingService.searchFiltersSchedules({
        data: {
          query: input,
          type: SchedulingSearchFilterType.CONSULT_BY_ID,
          patient: {
            name: state?.name,
            email: state?.email,
            age: state.age ?? 0,
            gender: state?.gender,
            patient_id: state?.patient_id || undefined,
            user_id: state?.user_id
          },
          healthInsurance: {
            name: state.healthInsuranceName,
            plan_name: state.healthPlanName,
            plan_id: state.healthPlanCode,
            health_insurance_id: state.healthInsuranceCode
          }
        },
        fields: [
          `
          specialties {
            name,
            specialty_id,
            units {
              unit_id,
              name,
              description
            },
          }

        `
        ]
      })

      return res.specialties[0]
    }
  }

  // Tratar caso de Biópsia aqui (caso necessário):
  async function prepareRedirectMedicalAppointment() {
    try {
      setIsLoading(true)

      let preExamId
      // verifica se o exame com consulta previa está no primeiro indice da lista de exames ou nao,
      // para poder ser direcionado da forma correta em caso de o paciente colocar na lista um exame
      // nao elegivel para agendamento via meu mmd.
      if (state.exams[0]?.is_mandatory_pre_exam) {
        preExamId =
          state.exams?.[0]?.pre_exam_id ||
          state.consultPreExams?.[0].pre_exam_id
      } else {
        preExamId =
          state.exams.find((item) => item?.is_mandatory_pre_exam)
            ?.pre_exam_id || state.consultPreExams?.[0].pre_exam_id
      }

      // Alterar para specialidade que vem do exame que necessita de agendamento de consulta
      const responseSpecialties = await searchSpecialties(String(preExamId))

      // setSpecialtyFromExam(responseSpecialties)
      setShowMedicalAppointmentRedirectScreen(true)

      return responseSpecialties
    } catch (error: any) {
      toast.error(error?.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function handleRedirectMedicalAppointment() {
    const responseSpecialties = await prepareRedirectMedicalAppointment()

    const healthInsuranceInfo = {
      healthInsuranceCode: state.healthInsuranceCode,
      healthInsuranceName: state.healthInsuranceName,
      healthPlanCode: state.healthPlanCode,
      healthPlanName: state.healthPlanName,
      health_card_document_id: state.health_card_document_id,
      health_insurance_id: state.health_insurance_id
    }

    const patientInfo = {
      age: state.age ?? 0,
      gender: state.gender,
      email: state.email,
      name: state.name,
      patient_id: state.patient_id,
      user_id: state.user_id
    }

    history.push('/agendamento/consulta/busca', {
      patient: patientInfo,
      selectedHealthInsurance: healthInsuranceInfo,
      specialtyFromExam: responseSpecialties,
      medicalOrderDocumentIds: state.medical_order_document_ids
    })

    setShowMedicalAppointmentRedirectScreen(false)
  }

  function handleRedirectExamAppointments() {
    dispatch({
      type: 'SET_SHOW_PRE_CONSULT',
      payload: false
    })
    setShowMedicalAppointmentRedirectScreen(false)
    slideTo && slideTo(0)
    window.location.reload()
  }

  function goToHealthInsuranceSelect() {
    dispatch({
      type: 'UPDATE_SELECTED_EXAMS',
      payload: []
    })

    slideToHealthInsurance()
  }

  const isAvailableScheduleExam = (exam: ExamModelNew[]) => {
    for (const item of exam) {
      if (item.type === ExamSearchType.SURGERY || item.allow_schedule) {
        return false
      }
    }
    return true
  }

  return (
    <>
      {showMedicalAppointmentRedirectScreen || state.show_pre_consult ? (
        <MedicalAppointmentRedirectScreen
          handleRedirectMedicalAppointment={handleRedirectMedicalAppointment}
          handleRedirectExamAppointments={handleRedirectExamAppointments}
        />
      ) : (
        <ContainerNew
          title="Buscar exames"
          titleColor="secondary700"
          titleSize="large"
          titleWeight="bold"
        >
          <AutoCompleteNew
            label="Buscar exames"
            placeholder="Adicione um novo exame"
            icon={<SearchIcon />}
            value={examQuery || ''}
            onType={(input) => searchExams(input)}
            onInputChange={setExamQuery}
            suggestions={examSearchResult?.map((exam: ExamModelNew) => ({
              label: exam.name?.toLocaleLowerCase(),
              value: exam
            }))}
            onSuggestionClick={(e) => selectExam(e)}
            clear={() => resetSelectedExam()}
            required
            requiredColor="neutral800"
            data-testid="select-exam-autocomplete"
            debounceDelay={1000}
            minCharactersSearch={2}
            autoFocus={activeIndex === 4}
          />

          <DividerNew />

          {(state?.exams?.length > 0 ||
            state?.unavailableExams?.length > 0) && (
            <>
              <S.TitleStrong>Exames adicionados</S.TitleStrong>

              {state.exams?.map((exam: ExamModelNew, index) => {
                return (
                  <ExamCardNew
                    key={index}
                    allowSchedule={exam?.allow_schedule}
                    needMedicalAppointment={
                      !!exam?.is_mandatory_pre_exam &&
                      exam.surgery_type !== SurgeryType.BIO
                    }
                    examName={exam?.name}
                    examDescription={exam?.aliases}
                    examReasonNotSchedule={exam?.reason_not_schedule}
                    handleDelete={() => handleDelete(exam)}
                    examType={exam?.type}
                  />
                )
              })}

              {state?.unavailableExams?.map((exam: ExamModelNew, index) => {
                return (
                  <ExamCardNew
                    key={index}
                    unavailable
                    allowSchedule={exam?.allow_schedule}
                    examName={exam?.name}
                    examReasonNotSchedule={exam?.reason_not_schedule}
                    examDescription={exam?.aliases}
                    handleDelete={() =>
                      handleDeleteUnavailableExam(exam?.item_ids?.[0])
                    }
                    warning={messageExamsError}
                    examType={exam?.type}
                  />
                )
              })}
            </>
          )}

          <S.ButtonPanel>
            {state.exams?.length === 0 &&
            state.unavailableExams?.length >= 1 ? (
              <ButtonNew onClick={goToHealthInsuranceSelect}>
                Voltar para seleção de convênio
              </ButtonNew>
            ) : (
              <>
                {state.exams?.length >= 1 &&
                // VERIFICA SE NO UNICO EXAME DO ARRAY POSSUI A NECESSIDADE DE AGENDAR CONSULTA (exceto Biopsia guiada)
                !!state.exams.find(
                  (item) =>
                    item.is_mandatory_pre_exam &&
                    item.surgery_type !== SurgeryType.BIO
                ) &&
                !state.exams.find((item) => item.allow_schedule) ? (
                  <ButtonNew
                    onClick={() => prepareRedirectMedicalAppointment()}
                  >
                    Agendar consulta
                  </ButtonNew>
                ) : (
                  <ButtonNew
                    disabled={
                      // Desabilita o botao caso os exames nao seja elegivel para agendamento (allow_schedule === false)
                      state.exams?.length === 0 ||
                      !state.exams?.length ||
                      isAvailableScheduleExam(state.exams)
                    }
                    onClick={handleNext}
                  >
                    Próximo
                  </ButtonNew>
                )}
                <ButtonNew onClick={prev} outlined>
                  Anterior
                </ButtonNew>
              </>
            )}
          </S.ButtonPanel>
        </ContainerNew>
      )}
    </>
  )
}

export default SearchExams

type MedicalAppointmentRedirectScreenProps = {
  handleRedirectMedicalAppointment: () => void
  handleRedirectExamAppointments: () => void
}

const MedicalAppointmentRedirectScreen = ({
  handleRedirectMedicalAppointment,
  handleRedirectExamAppointments
}: MedicalAppointmentRedirectScreenProps) => {
  const { state } = useContext<any>(
    SchedulingExamContext
  ) as SchedulingExamModelContext

  return (
    <ContainerNew>
      <MedicalAppointmentImage
        style={{ alignSelf: 'center', marginBottom: '30px' }}
      />
      <S.SpacedWrapper>
        {state.examsScheduled?.length > 0 ? (
          <>
            <div>
              <S.PurpleStrong>Agendar consulta pré-exame</S.PurpleStrong>

              <S.SubDescription>
                {`Antes da realização do exame de ${
                  state.exams?.[0]?.name || state.consultPreExams?.[0]?.name
                } você precisa agendar
                  uma consulta.`}
              </S.SubDescription>

              <S.SubDescription>Deseja agendar agora?</S.SubDescription>
            </div>

            <S.ButtonPanel>
              <ButtonNew onClick={handleRedirectMedicalAppointment}>
                Agendar consulta
              </ButtonNew>

              <ButtonNew
                color="primary"
                outlined
                type="button"
                fullWidth
                size="large"
                onClick={handleRedirectExamAppointments}
              >
                Ir para meus agendamentos
              </ButtonNew>
            </S.ButtonPanel>
          </>
        ) : (
          <>
            <div>
              <S.PurpleStrong>Agendar consulta pré-exame</S.PurpleStrong>

              <S.SubDescription>
                Para realizar este exame é necessário agendar uma consulta pré
                exame.
              </S.SubDescription>

              <S.SubDescription>
                Você está saindo do fluxo de agendamento de exames e será
                direcionado ao agendamento de consultas.
              </S.SubDescription>
            </div>
            <S.ButtonPanel>
              <ButtonNew onClick={handleRedirectMedicalAppointment}>
                Agendar consulta
              </ButtonNew>
            </S.ButtonPanel>
          </>
        )}
      </S.SpacedWrapper>
    </ContainerNew>
  )
}
