export class TimerModel {
  date: Date

  constructor(date: Date | string) {
    this.date = new Date(date)
  }

  getSecondsToNextMinute(): number {
    const tokenSeconds = this.date.getSeconds()
    const nowSeconds = new Date().getSeconds()
    if (tokenSeconds > nowSeconds) {
      return tokenSeconds - nowSeconds
    } else {
      return 60 - nowSeconds + tokenSeconds
    }
  }

  getDifferenceByNow(): number {
    if (!this.date) return -1
    const now = new Date()

    const difference = this.date.getTime() - now.getTime()
    return difference
  }

  getHoursAndMinutesDiff(): {
    hours: number
    minutes: number
  } {
    const difference = this.getDifferenceByNow()
    let minutes = Math.trunc(difference / 60000)
    if (difference < 0 || minutes === 0)
      return {
        hours: 0,
        minutes: 0
      }
    if (minutes === 1)
      return {
        hours: 0,
        minutes: 1
      }
    let hours = 0
    if (minutes > 59) {
      hours = Math.trunc(minutes / 60)
      minutes = minutes - 60 * hours
    }
    return {
      hours,
      minutes
    }
  }

  toStringTimer(): string {
    const { hours, minutes } = this.getHoursAndMinutesDiff()
    const addZeros = (number: number): string =>
      `${String(number).padStart(2, '0')}`
    return `${addZeros(hours)}:${addZeros(minutes)}`
  }

  toStringDifference(): string {
    const { hours, minutes } = this.getHoursAndMinutesDiff()
    if (minutes === 0 && hours === 0) return 'Em instantes'
    if (minutes === 1 && hours === 0) return 'Falta 1 min'
    let hourString = ''
    if (hours) {
      if (hours === 1) hourString = '1 hora e '
      else hourString = `${hours} horas e `
    }
    return `Faltam ${hourString} ${minutes} min`
  }

  toStringDifferenceText(): string {
    const { hours, minutes } = this.getHoursAndMinutesDiff()
    if (minutes === 0 && hours === 0) return 'em instantes'
    if (minutes === 1 && hours === 0) return 'em 1 minuto'
    let hourString = ''
    const minutesString = `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`
    if (hours) {
      if (hours === 1) hourString = '1 hora e '
      else hourString = `${hours} horas e `
    }
    return `em até ${hourString} ${minutesString}`
  }
}
