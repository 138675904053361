import { makeObservable, observable, action } from 'mobx'
import { Notification } from 'domain/entities/notification-model'

class NotificationStore {
  count = 0

  pendencyModalOpen = false

  surgicalOrder = {}

  hospitalNotification = {} as Notification

  currentSurgicalPendencyId = 0

  isLoadingPendency = false

  constructor() {
    makeObservable<any>(this, {
      count: observable,
      pendencyModalOpen: observable,
      // surgicalOrder: observable,
      isLoadingPendency: observable,
      currentSurgicalPendencyId: observable,
      setSurgicalOrder: action,
      setCurrentSurgicalPendencyId: action,
      openPendencyModal: action,
      closePendencyModal: action
    })
  }

  setSurgicalOrder(surgicalOrder: any) {
    this.surgicalOrder = surgicalOrder
  }

  setCurrentSurgicalPendencyId(currentSurgicalPendencyId: number) {
    this.currentSurgicalPendencyId = currentSurgicalPendencyId
  }

  openPendencyModal() {
    this.pendencyModalOpen = true
  }

  closePendencyModal() {
    this.pendencyModalOpen = false
  }

  setShowLoadingPendency(isLoadingPendency: boolean) {
    this.isLoadingPendency = isLoadingPendency
  }

  setHospitalNotification(hospitalNotification: any) {
    this.hospitalNotification = hospitalNotification
  }
}

export default new NotificationStore()
