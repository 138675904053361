import React, { useState } from 'react'
import { SymptomsValues } from 'domain/entities/emergency-room-symptoms.model'
import { Hospital } from 'domain/entities/hospital-model'
import { Patient } from 'domain/entities/patient-model'
import { AddAccompanying } from 'domain/usecases/accompanying/add-accompanying'
import { CreateEmergencyRoom } from 'domain/usecases/emergency-room/create-emergency-room'
import { EmergencyRoomSymptoms } from 'presentation/components/Forms/EmergencyRoom/EmergencySymptoms'
import { EmergencyRoomPatientInfo } from 'presentation/components/Forms/EmergencyRoom/PatientInfo'
import { EmergencyRoomSelectUnit } from 'presentation/components/Forms/EmergencyRoom/SelectUnit'
import { EmergencyRoomSteps } from 'presentation/components/Forms/EmergencyRoom/Steps'
import { CarouselState } from 'presentation/components/Carousel'
import ConditionalCarousel from 'presentation/components/Carousel/ConditionalCarousel'
import { Container } from 'presentation/components/Container'
import Header from 'presentation/components/Header'
import { UpdatePatientHealthInsurance } from 'domain/usecases/patient/update-patient-health-insurance'
import { UploadPatientDocument } from 'domain/usecases/patient/upload-patient-document'
import { UpdatePatientInfoById } from 'domain/usecases/patient/update-patient-info-by-id.'

type Props = {
  patient: Patient
  setPatient: (patient: Patient) => void
  updatePatientPersonalData: UpdatePatientInfoById
  hospitals: Hospital[]
  addAccompanying: AddAccompanying
  createEmergencyRoom: CreateEmergencyRoom
  updateHealthInsuranceData: UpdatePatientHealthInsurance
  uploadDocument?: UploadPatientDocument
  editPatient: (patient: Patient) => void
  editHealthInsurance: () => void
  updatedHealthInsurance: boolean
  updateAccompanies: () => void
}

export default function EmergencyRoomServiceLayout({
  patient,
  updatePatientPersonalData,
  hospitals,
  addAccompanying,
  createEmergencyRoom,
  setPatient,
  updateHealthInsuranceData,
  uploadDocument,
  editPatient,
  editHealthInsurance,
  updatedHealthInsurance,
  updateAccompanies
}: Props) {
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [backPatientCarousel, setBackPatientCarousel] = useState<number>(0)
  const [step, setStep] = useState<number>(0)
  const [form, setForm] = useState<SubmitEmergencyAttendance>({
    useHealthInsurance: true
  })

  return (
    <>
      <Header />
      <Container hasHeader noPadding>
        <ConditionalCarousel setState={setCarousel}>
          <EmergencyRoomSteps
            carousel={carousel}
            step={step}
            form={form}
            createEmergencyRoom={createEmergencyRoom}
            hospitals={hospitals}
          />
          <EmergencyRoomPatientInfo
            carousel={carousel}
            patient={patient}
            setStep={setStep}
            updatePatientPersonalData={updatePatientPersonalData}
            addAccompanying={addAccompanying}
            hospitals={hospitals}
            setForm={setForm}
            form={form}
            setPatient={setPatient}
            updateHealthInsuranceData={updateHealthInsuranceData}
            uploadDocument={uploadDocument}
            editPatient={(patient) => editPatient(patient)}
            editHealthInsurance={() => editHealthInsurance()}
            updatedHealthInsurance={updatedHealthInsurance}
            updateAccompanies={updateAccompanies}
            patientBackStep={backPatientCarousel}
            backPatientCarousel={setBackPatientCarousel}
          />
          <EmergencyRoomSelectUnit
            carousel={carousel}
            patient={patient}
            setStep={setStep}
            hospitals={hospitals}
            setForm={setForm}
            form={form}
            backPatientCarousel={(number) => setBackPatientCarousel(number)}
          />
          <EmergencyRoomSymptoms
            carousel={carousel}
            patient={patient}
            setStep={setStep}
            setForm={setForm}
            form={form}
          />
        </ConditionalCarousel>
      </Container>
    </>
  )
}

export interface SubmitEmergencyAttendance {
  hospital_id?: number
  specialty?: string
  arrivalForecast?: string
  symptoms?: SymptomsValues
  accompanies_id?: number[]
  authorization?: CreateEmergencyRoomAuthorization

  health_insurance_id?: number
  useHealthInsurance?: boolean
  card?: string
  healthPlanCode?: string
}

type CreateEmergencyRoomAuthorization = {
  guideNumber: string
  password: string
  expirationDate: Date
}
