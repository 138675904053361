import { makeAutoObservable } from 'mobx'
import { LoadSurgicalOrderDocument } from 'domain/usecases/surgical-order/load-surgical-order-document'
import { UploadSurgicalOrderDocument } from 'domain/usecases/surgical-order/upload-surgical-order-document'
import { LoadSurgicalOrder } from 'domain/usecases/surgical-order/load-surgical-order'
import { LoadCid } from 'domain/usecases/surgical-order/load-cid'
import { ConcludeSurgicalOrder } from 'domain/usecases/surgical-order/conclude-surgical-order'
import { DeleteSurgicalOrderSolicitation } from 'domain/usecases/surgical-order/delete-surgical-order-solicitation'
import { UploadSurgicalOrderDocumentsSameType } from 'domain/usecases/surgical-order/upload-surgical-order-documents-same-type'
import { LoadFavoriteSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-surgical-orders'
import { DeleteFavoritedSurgicalOrder } from 'domain/usecases/surgical-order/delete-favorited-surgical-order'
import { SurgicalOrderCheckin } from 'domain/usecases/surgical-order/checkin'
import { LoadFavoriteDoctorSurgicalOrders } from 'domain/usecases/surgical-order/load-favorite-doctor-order'
import { UploadToMergeDocument } from 'domain/usecases/surgical-order/upload-to-merge-document'
import { ConfirmMergeDocuments } from 'domain/usecases/surgical-order/confirm-merge-documents'
import { DeleteUploadedDocuments } from 'domain/usecases/surgical-order/delete-uploaded-documents'
import { LoadSurgicalOrderNoticeCard } from 'domain/usecases/surgical-order/load-surgical-orders-notice-card'
import { GetSurgicalOrderTimeline } from 'domain/usecases/surgical-order/get-surgical-order-timeline'
import { RequestSurgicalOrderCancellation } from 'domain/usecases/surgical-order/request-surgical-order-cancellation'
import { AddSurgicalOrderCancellationComment } from 'domain/usecases/surgical-order/add-surgical-order-cancellation-comment'
import { GetSurgicalOrderCancellationNotifications } from 'domain/usecases/surgical-order/get-surgical-order-cancellation-notifications'
import { ConfirmReadCancellationRequests } from 'domain/usecases/surgical-order/read-cancellation-requests'
import { SendReasonNotSurgery } from 'domain/usecases/surgical-order/send-reason-not-surgery'

type Services = {
  loadSurgicalOrder: LoadSurgicalOrder
  loadSurgicalOrderDocument: LoadSurgicalOrderDocument
  uploadSurgicalOrderDocument: UploadSurgicalOrderDocument
  loadCids: LoadCid
  concludeSurgicalOrder: ConcludeSurgicalOrder
  deleteSurgicalOrderSolicitation: DeleteSurgicalOrderSolicitation
  uploadSurgicalOrderDocumentsSameType: UploadSurgicalOrderDocumentsSameType
  loadFavoriteSurgicalOrders: LoadFavoriteSurgicalOrders
  loadFavoriteDoctorSurgicalOrders: LoadFavoriteDoctorSurgicalOrders
  deleteFavoritedSurgicalOrder: DeleteFavoritedSurgicalOrder
  checkin: SurgicalOrderCheckin
  uploadToMergeDocument: UploadToMergeDocument
  confirmMergeDocuments: ConfirmMergeDocuments
  deleteUploadedDocuments: DeleteUploadedDocuments
  loadSurgicalOrderNoticeCard: LoadSurgicalOrderNoticeCard
  getSurgicalOrderTimeline: GetSurgicalOrderTimeline
  requestSurgicalOrderCancellation: RequestSurgicalOrderCancellation
  addSurgicalOrderCancellationComment: AddSurgicalOrderCancellationComment
  getSurgicalOrderCancellationNotifications: GetSurgicalOrderCancellationNotifications
  confirmReadCancellationRequests: ConfirmReadCancellationRequests
  sendReasonNotSurgery: SendReasonNotSurgery
}

export class SurgicalOrderService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async loadSurgicalOrderDocument(
    params: LoadSurgicalOrderDocument.Params
  ): Promise<LoadSurgicalOrderDocument.Model> {
    return this.services.loadSurgicalOrderDocument.load(
      params.surgical_order_id,
      params.document_id
    )
  }

  async uploadSurgicalOrderDocument(
    params: UploadSurgicalOrderDocument.Params
  ): Promise<UploadSurgicalOrderDocument.Model> {
    return this.services.uploadSurgicalOrderDocument.upload(params)
  }

  async loadSurgicalOrder(
    id: number,
    params: LoadSurgicalOrder.Params
  ): Promise<LoadSurgicalOrder.Model> {
    return this.services.loadSurgicalOrder.load(id, params)
  }

  async loadCids(params?: LoadCid.Params): Promise<LoadCid.Model> {
    return this.services.loadCids.load(params)
  }

  async concludeSurgicalOrder(
    params: ConcludeSurgicalOrder.Params
  ): Promise<ConcludeSurgicalOrder.Model> {
    return this.services.concludeSurgicalOrder.conclude(params)
  }

  async deleteSurgicalOrderSolicitation(
    params: DeleteSurgicalOrderSolicitation.Params
  ): Promise<DeleteSurgicalOrderSolicitation.Model> {
    return this.services.deleteSurgicalOrderSolicitation.delete(params)
  }

  async uploadSurgicalOrderDocumentsSameType(
    params: UploadSurgicalOrderDocumentsSameType.Params
  ): Promise<UploadSurgicalOrderDocumentsSameType.Model> {
    return this.services.uploadSurgicalOrderDocumentsSameType.upload(params)
  }

  async uploadToMergeDocument(
    params: UploadToMergeDocument.Params
  ): Promise<UploadToMergeDocument.Model> {
    return this.services.uploadToMergeDocument.upload(params)
  }

  async loadFavoriteSurgicalOrders(
    params: LoadFavoriteSurgicalOrders.Params
  ): Promise<LoadFavoriteSurgicalOrders.Model> {
    return this.services.loadFavoriteSurgicalOrders.load(params)
  }

  async loadFavoriteDoctorSurgicalOrders(
    params: LoadFavoriteDoctorSurgicalOrders.Params
  ): Promise<LoadFavoriteDoctorSurgicalOrders.Model> {
    return this.services.loadFavoriteDoctorSurgicalOrders.load(params)
  }

  async deleteFavoritedSurgicalOrder(
    params: DeleteFavoritedSurgicalOrder.Params
  ): Promise<DeleteFavoritedSurgicalOrder.Model> {
    return this.services.deleteFavoritedSurgicalOrder.delete(params)
  }

  async checkin(
    params: SurgicalOrderCheckin.Params
  ): Promise<SurgicalOrderCheckin.Model> {
    return this.services.checkin.checkin(params)
  }

  async confirmMergeDocuments(
    params: ConfirmMergeDocuments.Params
  ): Promise<ConfirmMergeDocuments.Model> {
    return this.services.confirmMergeDocuments.confirm(params)
  }

  async deleteUploadedDocuments(
    params: DeleteUploadedDocuments.Params
  ): Promise<DeleteUploadedDocuments.Model> {
    return this.services.deleteUploadedDocuments.delete(params)
  }

  async loadSurgicalOrderNoticeCard(
    params: LoadSurgicalOrderNoticeCard.Params
  ): Promise<LoadSurgicalOrderNoticeCard.Model> {
    return this.services.loadSurgicalOrderNoticeCard.load(params)
  }

  async getSurgicalOrderTimeline(
    params: GetSurgicalOrderTimeline.Params
  ): Promise<GetSurgicalOrderTimeline.Model> {
    return this.services.getSurgicalOrderTimeline.load(params)
  }

  async requestSurgicalOrderCancellation(
    params: RequestSurgicalOrderCancellation.Params
  ): Promise<RequestSurgicalOrderCancellation.Model> {
    return this.services.requestSurgicalOrderCancellation.post(params)
  }

  async addSurgicalOrderCancellationComment(
    params: AddSurgicalOrderCancellationComment.Params
  ): Promise<AddSurgicalOrderCancellationComment.Model> {
    return this.services.addSurgicalOrderCancellationComment.post(params)
  }

  async getSurgicalOrderCancellationNotifications(): Promise<GetSurgicalOrderCancellationNotifications.Model> {
    return this.services.getSurgicalOrderCancellationNotifications.get()
  }

  async confirmReadCancellationRequests(): Promise<ConfirmReadCancellationRequests.Model> {
    return this.services.confirmReadCancellationRequests.put()
  }

  async sendReasonNotSurgery(
    params: SendReasonNotSurgery.Params
  ): Promise<SendReasonNotSurgery.Model> {
    return this.services.sendReasonNotSurgery.send(params)
  }
}

export default SurgicalOrderService
