import React from 'react'

import { useHistory } from 'react-router-dom'

import AlertIcon from 'presentation/assets/icons/alert.svg'
import CheckIcon from 'presentation/assets/icons/check-icon.svg'
import List, { ListItem } from 'presentation/shared/components/List'
import { ItemText } from 'presentation/shared/components/List/styles'
import { useStores } from 'presentation/hooks/use-stores'
import { PatientDocument } from 'common/enum/patient-document'

import * as S from './styles'
import theme from 'presentation/styles/theme'
import HeaderNew from 'presentation/components/HeaderNew'
import { ContainerNew } from 'presentation/components/ContainerNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import ButtonNew from 'presentation/components/ButtonNew'

export const AddDocumentsForm = () => {
  const history = useHistory()

  const filesContext = useStores().file

  const getStatusIconByType = (type: PatientDocument) => {
    const files = filesContext.listFiles?.filter(
      (file) => file.identifier === type
    )
    return files && files.length > 0 ? CheckIcon : AlertIcon
  }

  const documentItems: ListItem[] = [
    {
      title: (
        <ItemText>
          Pedido Cirúrgico
          <strong style={{ color: theme.colors.alert }}>*</strong>
        </ItemText>
      ),
      icon: getStatusIconByType(PatientDocument.SURGICAL_ORDER),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'order'
        })
    },
    {
      title: <ItemText>Relatório Médico</ItemText>,
      icon: getStatusIconByType(PatientDocument.MEDICAL_REPORT),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'medical_report'
        })
    },
    {
      title: <ItemText>Laudos</ItemText>,
      icon: getStatusIconByType(PatientDocument.EXAM_REPORT),
      click: () =>
        history.push('/pedido-cirurgico/save/documentos/anexo', {
          type: 'medical_exams'
        })
    }
  ]

  const completeAttachment = () =>
    filesContext.listFiles?.some(
      (file) => file.identifier === PatientDocument.SURGICAL_ORDER
    ) || false

  return (
    <>
      <HeaderNew actualPageTitle="Documentos" />
      <ContainerNew
        subtitle={
          <SupportTextNew color="neutral500">
            Você possui <strong>documentos</strong> que comprove necessidade da
            cirurgia?
          </SupportTextNew>
        }
        primaryButton={
          <ButtonNew
            size="large"
            fullWidth
            disabled={!completeAttachment()}
            onClick={() => history.goBack()}
            data-testid="btn-send"
          >
            Enviar
          </ButtonNew>
        }
        secondaryButton={
          <ButtonNew
            size="large"
            variant="text"
            data-testid="btn-cancel"
            onClick={() => history.goBack()}
          >
            Cancelar
          </ButtonNew>
        }
      >
        <S.Wrapper>
          <S.DocumentsList data-testid="list-documents">
            <List items={documentItems} size={'small'} />
          </S.DocumentsList>
        </S.Wrapper>
      </ContainerNew>
    </>
  )
}
