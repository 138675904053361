import { CreateDoctorRegistrationStatus } from 'domain/usecases/overall-registration-status/create-doctor-registration-status'
import { OverallRegistrationStatusRepository } from 'repository/repositories/overall-registration-status/overall-registration-status-repository'

export class RemoteCreateDoctorRegistrationStatus
  implements CreateDoctorRegistrationStatus
{
  constructor(
    private readonly registrationStatusRepository: OverallRegistrationStatusRepository
  ) {}

  createDoctorRegistrationStatus(
    doctor_id: number
  ): Promise<CreateDoctorRegistrationStatus.Model> {
    return this.registrationStatusRepository.createDoctorRegistrationStatus(
      doctor_id
    )
  }
}
