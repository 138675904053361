import { RepositoryErrors } from 'repository/errors/repository-errors'
import handleGraphQLError from 'repository/graphql/utils/handle-error'
import { TracksaleRepository as ITracksaleRepository } from 'repository/interfaces/tracksale-repository'
import { ApiStatusCode } from 'service/protocols/api-tracksale/api-tracksale-client'
import { IApiTracksaleRepository } from 'service/protocols/api-tracksale/api-tracksale-repository'
import { AnswerNps } from 'domain/usecases/tracksale/answer-nps'

export class TracksaleRepository implements ITracksaleRepository {
  constructor(
    private readonly apiTracksaleRepository: IApiTracksaleRepository
  ) {}

  async answerNps(params: AnswerNps.Params): Promise<AnswerNps.Model> {
    const apiTracksaleRepository = this
      .apiTracksaleRepository as IApiTracksaleRepository<AnswerNps.Model>

    const httpResponse = await apiTracksaleRepository.post({
      headers: {
        Authorization: 'bearer 0bc4eed1fd1819a7f0c63dde122e4c1a'
      },
      url: '/answer',
      body: params
    })

    if (
      httpResponse.statusCode &&
      httpResponse.statusCode !== ApiStatusCode.ok
    ) {
      throw handleGraphQLError(RepositoryErrors[httpResponse.error!])
    } else {
      return httpResponse.body as AnswerNps.Model
    }
  }
}
