import { makeAutoObservable } from 'mobx'
import { SearchFiltersSchedules } from 'domain/usecases/scheduling/search-filters-schedules'
import { GetSchedules } from 'domain/usecases/scheduling/get-schedules'
import { CreateSchedule } from 'domain/usecases/scheduling/create-schedule'
import { GetSchedulesByMonth } from 'domain/usecases/scheduling/get-schedules-by-month'
import { GetHoursByDay } from 'domain/usecases/scheduling/get-hours-by-day'
import { GetReasonCancel } from 'domain/usecases/scheduling/get-reason-cancel'
import { DeleteSchedule } from 'domain/usecases/scheduling/delete-schedule'
import { ListSchedules } from 'domain/usecases/scheduling/list-schedules'
import { Interval } from 'service/protocols/api/api-client'
import { SchedulesList } from 'domain/entities/schedule-model'

type Services = {
  searchFiltersSchedules: SearchFiltersSchedules
  getSchedules: GetSchedules
  getSchedulesByMonth: GetSchedulesByMonth
  createSchedule: CreateSchedule
  getHoursByDay: GetHoursByDay
  getReasonCancel: GetReasonCancel
  deleteSchedule: DeleteSchedule
  listSchedules: ListSchedules
}

export class SchedulingService {
  constructor(private readonly services: Services) {
    makeAutoObservable(this)
  }

  async searchFiltersSchedules(
    params: SearchFiltersSchedules.Params
  ): Promise<SearchFiltersSchedules.Model> {
    return this.services.searchFiltersSchedules.load(params)
  }

  async getSchedules(params: GetSchedules.Params): Promise<GetSchedules.Model> {
    return this.services.getSchedules.load(params)
  }

  async getSchedulesByMonth(
    params: GetSchedulesByMonth.Params
  ): Promise<GetSchedulesByMonth.Model> {
    return this.services.getSchedulesByMonth.load(params)
  }

  async getScheduleHours(
    params: GetHoursByDay.Params
  ): Promise<GetHoursByDay.Model> {
    return this.services.getHoursByDay.load(params)
  }

  async createSchedule(
    params: CreateSchedule.Params
  ): Promise<CreateSchedule.Model> {
    return this.services.createSchedule.load(params)
  }

  async listSchedules(
    params: ListSchedules.Params,
    patient_id: number,
    interval: Interval
  ): Promise<SchedulesList> {
    return this.services.listSchedules.load(params, patient_id, interval)
  }

  async getReasonCancel(): Promise<GetReasonCancel.Model> {
    return this.services.getReasonCancel.load()
  }

  async deleteSchedule(
    params: DeleteSchedule.Params
  ): Promise<DeleteSchedule.Model> {
    return this.services.deleteSchedule.load(params)
  }
}

export default SchedulingService
