import { TimerModel } from './timer-model'

export interface EmergencyRoomTokenProps {
  emergency_room_token_id?: number
  token?: string
  qtRenewed?: number
  noticeSent?: boolean
  arrivalForecast?: Date
  createdAt?: Date
  updatedAt?: Date
}

export class EmergencyRoomTokenModel implements EmergencyRoomTokenProps {
  emergency_room_token_id?: number
  token?: string
  qtRenewed?: number
  noticeSent?: boolean
  arrivalForecast: Date = new Date()
  arrivalForecastTimer?: TimerModel
  createdAt?: Date
  updatedAt?: Date

  constructor(props: EmergencyRoomTokenProps) {
    Object.assign(this, props)
    if (props.arrivalForecast) {
      this.arrivalForecast = new Date(props.arrivalForecast)
      this.arrivalForecastTimer = new TimerModel(this.arrivalForecast)
    }
    if (props.createdAt) {
      this.createdAt = new Date(props.createdAt)
    }
    if (props.updatedAt) {
      this.updatedAt = new Date(props.updatedAt)
    }
  }
}
