import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { object, string } from 'yup'

import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import MultipleSelect from 'presentation/shared/components/MultipleSelect'
import ProfilePic from 'presentation/components/ProfilePic'
import SelectField from 'presentation/shared/components/SelectField'
import TextArea from 'presentation/shared/components/TextArea'
import TextField from 'presentation/components/TextField'
import { diseases } from 'presentation/utils/default-diseases'
import * as S from './styles'
import { UpdateMedicineAndDiseases } from 'domain/usecases/patient/update-medicine-and-diseases'
import { toast } from 'react-toastify'
import { useStores } from 'presentation/hooks/use-stores'
import { useState } from 'react'
import Modal from 'presentation/shared/components/Modal'
import ActualPage from '../../ActualPage'

type Props = {
  updateMedicineAndDiseases?: UpdateMedicineAndDiseases
  initialValues?: MedicinesAndDiseasesFormValues
}

export default function MedicinesAndDiseasesForm({
  updateMedicineAndDiseases,
  initialValues
}: Props) {
  const [successModal, setSuccessModal] = useState(false)
  const history = useHistory()
  const formikInitialValues = {
    isAllergy: 'false',
    isMedicines: 'false',
    isInsulin: 'false',
    functionalCapacity: 'INDEPENDENT'
  } as MedicinesAndDiseasesFormValues
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await updateMedicineAndDiseases?.update({
          ...values,
          isAllergy: values.isAllergy === 'true' || values.isAllergy === true,
          isMedicines:
            values.isMedicines === 'true' || values.isMedicines === true,
          isInsulin: values.isInsulin === 'true' || values.isInsulin === true,
          illnesses: values.illnesses?.join(','),
          allergyRemarks: isNegativeAnswer('isAllergy')
            ? ''
            : values.allergyRemarks,
          insulinRemarks: isNegativeAnswer('isInsulin')
            ? ''
            : values.insulinRemarks,
          medicinesRemarks: isNegativeAnswer('isMedicines')
            ? ''
            : values.medicinesRemarks
        })
        setSuccessModal(true)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
  })

  useEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues)
    }
  }, [initialValues])

  const user = useStores().currentAccount.getCurrentAccount()

  const isNegativeAnswer = (field: string) => {
    //@ts-ignore
    return !formik.values[field] || formik.values[field] === 'false'
  }
  return (
    <S.Wrapper role="form" onSubmit={formik.handleSubmit}>
      <ActualPage text="Voltar" />
      <ProfilePic
        outlined
        size="large"
        style={{
          display: 'block',
          margin: '0 auto',
          marginTop: '36px',
          marginBottom: '4px'
        }}
      />
      <p>{user?.user?.name}</p>
      <Heading as="h2" color="primary" style={{ marginBottom: '20px' }}>
        Medicamentos e doenças
      </Heading>
      <SelectField
        label="Possui alguma alergia?"
        items={[
          {
            label: 'Não',
            value: 'false'
          },
          {
            label: 'Sim',
            value: 'true'
          }
        ]}
        value={String(formik.values.isAllergy)}
        onInputChange={formik.handleChange('isAllergy')}
      />
      <TextArea
        placeholder="Observações adicionais"
        rows={5}
        disabled={
          !formik.values.isAllergy || formik.values.isAllergy === 'false'
        }
        onInputChange={formik.handleChange('allergyRemarks')}
        aria-label="Observações sobre a alergia"
        value={
          isNegativeAnswer('isAllergy') ? '' : formik.values.allergyRemarks
        }
      />
      <SelectField
        label="Utiliza constantemente algum tipo de medicamento?"
        items={[
          {
            label: 'Não',
            value: 'false'
          },
          {
            label: 'Sim',
            value: 'true'
          }
        ]}
        value={String(formik.values.isMedicines)}
        onInputChange={formik.handleChange('isMedicines')}
      />
      <TextArea
        placeholder="Observações adicionais"
        rows={5}
        disabled={
          !formik.values.isMedicines || formik.values.isMedicines === 'false'
        }
        aria-label="Observações sobre os medicamentos"
        onInputChange={formik.handleChange('medicinesRemarks')}
        value={
          isNegativeAnswer('isMedicines') ? '' : formik.values.medicinesRemarks
        }
      />
      <SelectField
        label="Faz uso de insulina?"
        items={[
          {
            label: 'Não',
            value: 'false'
          },
          {
            label: 'Sim',
            value: 'true'
          }
        ]}
        value={String(formik.values.isInsulin)}
        onInputChange={formik.handleChange('isInsulin')}
      />
      <TextArea
        placeholder="Observações adicionais"
        rows={5}
        disabled={
          !formik.values.isInsulin || formik.values.isInsulin === 'false'
        }
        onInputChange={formik.handleChange('insulinRemarks')}
        aria-label="Observações sobre a insulina"
        value={
          isNegativeAnswer('isInsulin') ? '' : formik.values.insulinRemarks
        }
      />
      <SelectField
        label="Capacidade funcional"
        items={[
          {
            label: 'Independente',
            value: 'INDEPENDENT'
          },
          {
            label: 'Necessita de assistência',
            value: 'NEED_FOR_ASSISTANCE'
          },
          {
            label: 'Restrito/Acamado',
            value: 'RESTRICTED_BEDRIDDEN'
          }
        ]}
        value={String(formik.values.functionalCapacity)}
        onInputChange={formik.handleChange('functionalCapacity')}
      />
      <Heading as="h2" color="primary" style={{ marginTop: '44px' }}>
        Doenças
      </Heading>
      <S.Diseases>
        <MultipleSelect
          items={diseases.general.map((disease) => ({
            label: disease,
            value: disease
          }))}
          name="illnesses"
          initialValue={formik.values.illnesses}
          onCheck={formik.handleChange('illnesses')}
        />
      </S.Diseases>
      <Heading as="h3" color="primary">
        Doenças respiratórias
      </Heading>
      <S.Diseases>
        <MultipleSelect
          items={diseases.respiratory.map((disease) => ({
            label: disease,
            value: disease
          }))}
          name="illnesses"
          onCheck={formik.handleChange('illnesses')}
          initialValue={formik.values.illnesses}
        />
      </S.Diseases>
      <Heading as="h3" color="primary">
        Imunossupressão
      </Heading>
      <S.Diseases>
        <MultipleSelect
          items={diseases.immunosuppression.map((disease) => ({
            label: disease,
            value: disease
          }))}
          name="illnesses"
          onCheck={formik.handleChange('illnesses')}
          initialValue={formik.values.illnesses}
        />
      </S.Diseases>
      <Heading as="h3" color="primary">
        Cardiovascular
      </Heading>
      <S.Diseases>
        <MultipleSelect
          items={diseases.cardiovascular.map((disease) => ({
            label: disease,
            value: disease
          }))}
          name="illnesses"
          onCheck={formik.handleChange('illnesses')}
          initialValue={formik.values.illnesses}
        />
      </S.Diseases>
      <Heading as="h3" color="primary">
        Neurológicas e psiquiátricas
      </Heading>
      <S.Diseases>
        <MultipleSelect
          items={diseases.neurologicalAndPsychiatric.map((disease) => ({
            label: disease,
            value: disease
          }))}
          name="illnesses"
          onCheck={formik.handleChange('illnesses')}
          initialValue={formik.values.illnesses}
        />
      </S.Diseases>
      <TextField
        label="Além das citadas já teve/tem outros tipos de enfermidade?"
        style={{ marginTop: '24px' }}
        onInputChange={formik.handleChange('otherDiseases')}
        value={formik.values.otherDiseases || ''}
      />
      <Button
        type="submit"
        fullWidth
        style={{ marginTop: '37px' }}
        disabled={!formik.isValid}
      >
        Salvar
      </Button>
      <Modal
        title="Dados atualizados com sucesso!"
        show={successModal}
        close={history.goBack}
      />
    </S.Wrapper>
  )
}

type MedicinesAndDiseasesFormValues = {
  isAllergy: boolean | string
  allergyRemarks?: string
  isMedicines: boolean | string
  medicinesRemarks?: string
  isInsulin: boolean | string
  insulinRemarks?: string
  otherDiseases: string
  illnesses: string[]
  functionalCapacity: string
}

const validationSchema = object().shape({
  allergyRemarks: string().when('isAllergy', {
    is: 'true',
    then: string().required('Obrigatório')
  }),
  medicinesRemarks: string().when('isMedicines', {
    is: 'true',
    then: string().required('Obrigatório')
  }),
  insulinRemarks: string().when('isInsulin', {
    is: 'true',
    then: string().required('Obrigatório')
  }),
  functionalCapacity: string().required(),
  isAllergy: string().required(),
  isMedicines: string().required(),
  isInsulin: string().required()
})
