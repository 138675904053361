import styled from 'styled-components'

export const TabWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const PendencyStatusSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const PendencyStatusHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
`

export const PendenciesListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: none;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: auto;
  padding: 8px 24px;
  margin: -8px -24px;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    min-width: 460px;
    max-width: 460px !important;
    height: 100%;
    @media (max-width: 580px) {
      min-width: 100%;
      max-width: 100% !important;
    }
  }
`
