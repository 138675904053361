import { Type } from 'class-transformer'
import { Profile } from 'common/enum/profile'
import { ClassProps } from 'common/utils/getClassProps'

export class Document {
  document_id!: number
  type!: string
  name!: string
  documentUri?: string
  status?: string
  sendedAt?: string
  updatedAt?: string
  base64?: string
  extension?: string
  group_id?: string
  is_validated?: boolean
  updated_by?: {
    name: string
    cpf: string
    user_id: number | string
    role: Profile
  }

  @Type(() => Date)
  conclusionDate?: Date | string | undefined

  constructor(props?: Partial<DocumentProps>) {
    Object.assign(this, props)
  }
}

type DocumentProps = ClassProps<typeof Document.prototype>
