import React, { useState } from 'react'
import { useHistory } from 'react-router'

import Modal from 'presentation/shared/components/Modal'
import BottomMenu from 'presentation/components/BottomMenu'
import Carousel, { CarouselState } from 'presentation/components/Carousel'
import WhatsAppButton from 'presentation/components/Button/WhatsAppButton'
import { Container } from 'presentation/components/Container'
import Heading from 'presentation/components/Heading'
import ServiceOverview, {
  ServiceOverviewProps
} from 'presentation/shared/components/ServiceOverview'
import SupportText from 'presentation/components/SupportText'
import * as S from './styles'
import { journeyButtons } from './buttons-list'
import { openPhoneApp } from 'presentation/utils/mobile-actions/'
import LoadingGif from 'presentation/shared/components/LoadingGif'
import {
  EmergencyRenewModals,
  EmergencyRenewModalsProps
} from 'presentation/components/Home/EmergencyRenewModals'
import { ReactComponent as VaccineIcon } from 'presentation/assets/icons/vaccine.svg'
import { ReactComponent as AppointmentIcon } from 'presentation/assets/icons/appointments-icon.svg'
import { ReactComponent as SurgeryIcon } from 'presentation/assets/icons/surgery-icon.svg'
import { ReactComponent as ExamIcon } from 'presentation/assets/icons/exam-icon.svg'
import { ReactComponent as SurgeryJourneyBanner } from 'presentation/assets/banners/surgical-journey-full-banner.svg'
import { ReactComponent as VaccineBanner } from 'presentation/assets/banners/newborn-vaccine-packages-banner.svg'
import { ReactComponent as TelemedicineBanner } from 'presentation/assets/banners/telemedicine-banner.svg'
import { ReactComponent as OpenSurgeriesBanner } from 'presentation/assets/banners/open-surgeries.svg'
import { ReactComponent as ExamResultBanner } from 'presentation/assets/icons/exam-result.svg'
import { ReactComponent as ScheduleAppointmentBanner } from 'presentation/assets/icons/schedule-appointment.svg'
import { ReactComponent as ScheduleExamBanner } from 'presentation/assets/icons/schedule-exam.svg'
import useTimer from 'presentation/hooks/use-timer'
import { setNewTimeLeft } from 'presentation/utils/timer/set-new-time-left'
import { User } from 'domain/entities/user-model'
import DependentDrawer from 'presentation/shared/components/DependentsDrawer/index'
import HeaderNew from 'presentation/components/HeaderNew'
import InfoCard from 'presentation/components/InfoCard'
import { SurgicalOrderModel } from 'domain/entities/surgical-order-model'
import { useServices } from 'presentation/hooks/use-services'
import { capitalizeEachWordText } from 'common/utils/capitalizeEachWordText'
import { ReactComponent as CloseIcon } from 'presentation/assets/icons/white-close.svg'
import { BannerCard } from 'presentation/components/Banner'

type PatientHomeLayoutProps = {
  user: User
  attendances?: ServiceOverviewProps[]
  hasMoreAttendances: boolean
  surgeries?: ServiceOverviewProps[]
  redirect?: (url: string, state?: any) => void
  pendencies?: number
  isLoading?: boolean
  renewEmergencyTokenProps: EmergencyRenewModalsProps
  surgicalOrderCancellationNotification?: SurgicalOrderModel
  openSurgeriesModalVisible?: boolean
  setOpenSurgeriesModalVisible?: (value: boolean) => void
  openScheduledSurgeries?: SurgicalOrderModel
}

const TELEMEDICINE_LINK = 'https://materdei.drtis.com.br/'

export default function PatientHomeLayout({
  user,
  redirect,
  pendencies,
  surgeries,
  renewEmergencyTokenProps,
  attendances,
  hasMoreAttendances,
  isLoading,
  surgicalOrderCancellationNotification,
  openSurgeriesModalVisible,
  setOpenSurgeriesModalVisible,
  openScheduledSurgeries
}: PatientHomeLayoutProps) {
  const emergencyRoomAttendance =
    renewEmergencyTokenProps?.emergencyRoomAttendance
  const emergencyRoomToken = emergencyRoomAttendance?.emergencyRoomToken
  const [carousel, setCarousel] = useState({} as CarouselState)
  const [bannerCarousel, setBannerCarousel] = useState({} as CarouselState)
  const [timeLeft, setTimeLeft] = useState<string>()
  const history = useHistory()
  const push = (url: string, state?: any) => {
    !!redirect && redirect(url, state)
  }
  const [appointmentModal, setAppointmentModal] = useState<boolean>(false)

  const surgicalOrderService = useServices().surgicalOrder

  const CarouselChildren: JSX.Element[] = []

  const handleEmergencyRoomAttendanceClick = () => {
    history.push('/solicitacao-de-servico/status', {
      emergencyRoomAttendance: emergencyRoomAttendance
    })
  }

  useTimer({
    action: () =>
      setNewTimeLeft({
        emergencyRoomToken,
        setTimeLeft,
        timeLeft
      }),
    timer: emergencyRoomToken?.arrivalForecastTimer
  })

  if (emergencyRoomAttendance) {
    CarouselChildren.push(
      <ServiceOverview
        status="Solicitação enviada"
        title="PRONTO-SOCORRO"
        unit={`Unidade: ${emergencyRoomAttendance?.hospital?.name}`}
        type="CLÍNICA MÉDICA"
        timeLeft={timeLeft}
        token={emergencyRoomToken?.token}
        onClick={handleEmergencyRoomAttendanceClick}
        key={`${emergencyRoomAttendance?.hospital?.name}`}
      />
    )
  }

  if (attendances) {
    attendances.forEach((attendance) => {
      CarouselChildren.push(
        <ServiceOverview {...attendance} key={`${attendance.title}`} />
      )
    })
  }

  const getJourneyButtons = (): typeof journeyButtons => {
    if ((surgeries || []).length === 0) {
      return journeyButtons.filter((button) => !button.needSurgery)
    }
    return journeyButtons
  }

  return (
    <S.Wrapper>
      <OpenSurgeriesModal
        openSurgeriesModalVisible={openSurgeriesModalVisible}
        openScheduledSurgeries={openScheduledSurgeries}
        setOpenSurgeriesModalVisible={setOpenSurgeriesModalVisible}
      />
      <HeaderNew actualPageTitle="Home" showMenu />
      {pendencies ? (
        <S.PendencyAlert onClick={() => history.push('/pendencias')}>
          Você possui {pendencies} pendência
          {pendencies > 1 ? 's' : undefined}
        </S.PendencyAlert>
      ) : undefined}
      {renewEmergencyTokenProps.showRenewToken ? (
        <S.NotificationHome>
          Caso você não compareça no horário estimado, seu pré cadastro irá
          expirar
        </S.NotificationHome>
      ) : null}
      <Container>
        <DependentDrawer user={user} />
        <S.ShortCutsWrapper>
          <BannerCard
            title="Agende suas consultas"
            titleSmaller="Atendimento nas unidades do Mater Dei."
            titleColor="primary600"
            titleSmallerColor="neutral800"
            onClick={() =>
              history.push('/agendamento/consulta/confirmar-dados')
            }
            image={<ScheduleAppointmentBanner />}
          />
          <BannerCard
            title="Agende seus exames"
            titleSmaller="Praticidade e agilidade no seu atendimento."
            titleColor="secondary600"
            titleSmallerColor="neutral800"
            bgColor={'secondary50'}
            onClick={() =>
              history.push('/agendamento/exames', {
                activeIndex: 1
              })
            }
            image={<ScheduleExamBanner />}
          />
          <BannerCard
            title="Resultado de exames"
            titleSmaller="Visualize seus resultados online."
            titleColor="terciary600"
            titleSmallerColor="neutral800"
            bgColor="terciary50"
            onClick={() => history.push('/exames')}
            image={<ExamResultBanner />}
          />
        </S.ShortCutsWrapper>

        <Heading
          style={{ fontWeight: 500, marginTop: '24px', marginBottom: '16px' }}
          size="small"
          as="h4"
        >
          Meus serviços
        </Heading>
        <div>
          <S.ServicesWrapper>
            <div>
              <div
                onClick={() =>
                  history.push('/servicos', {
                    anchor: 'appointments'
                  })
                }
              >
                <AppointmentIcon />
              </div>
              <SupportText>Consultas</SupportText>
            </div>
            <div>
              <div
                onClick={() =>
                  history.push('/servicos', {
                    anchor: 'exams'
                  })
                }
              >
                <ExamIcon />
              </div>
              <SupportText>Exames</SupportText>
            </div>
            <div>
              <div
                onClick={() =>
                  history.push('/servicos', {
                    anchor: 'surgery'
                  })
                }
              >
                <SurgeryIcon />
              </div>
              <SupportText>Cirurgia</SupportText>
            </div>
            {/* <div>
              <div
                onClick={() =>
                  history.push('/servicos', {
                    anchor: 'emergency_room'
                  })
                }
              >
                <RequestHelpIcon />
              </div>
              <SupportText style={{ whiteSpace: 'nowrap' }}>
                Pronto-Socorro
              </SupportText>
            </div> */}
            <div>
              <div
                onClick={() =>
                  history.push('/servicos', {
                    anchor: 'vaccines'
                  })
                }
              >
                <VaccineIcon />
              </div>
              <SupportText style={{ whiteSpace: 'nowrap' }}>
                Vacinas
              </SupportText>
            </div>
          </S.ServicesWrapper>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginTop: '24px',
            marginBottom: '16px'
          }}
          data-testid="box-my-attendances"
        >
          <Heading style={{ fontWeight: 500 }} size="small" as="h4">
            Meus atendimentos
          </Heading>
          {hasMoreAttendances ? (
            <SupportText
              color="primary"
              onClick={() => history.push('/atendimentos')}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontSize: '14px'
              }}
            >
              Ver mais
            </SupportText>
          ) : null}
        </div>
        {isLoading ? (
          <LoadingGif />
        ) : (
          <div className="container-card">
            {CarouselChildren.length === 0 && !isLoading ? (
              <S.EmptyAttendances>
                Você não possui nenhum atendimento
              </S.EmptyAttendances>
            ) : (
              <Carousel
                state={carousel}
                setState={setCarousel}
                slidesPerView={1}
                spaceBetween={50}
                dynamicHeight={true}
                pagination={{ type: 'bullets' }}
                bulletBottomPosition={'-10%'}
              >
                {[...CarouselChildren.slice(0, 5)]}
              </Carousel>
            )}
          </div>
        )}
        <S.JourneySection>
          <S.CarouselContainer>
            <Carousel
              state={bannerCarousel}
              setState={setBannerCarousel}
              slidesPerView={1}
              spaceBetween={10}
              pagination={{ clickable: true }}
              containerStyle={{
                maxHeight: 150,
                marginBottom: 0,
                paddingBottom: 0
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2
                },

                960: {
                  slidesPerView: 3
                }
              }}
            >
              <S.JourneyDiv
                onClick={() =>
                  push('/servicos', {
                    anchor: 'vaccines'
                  })
                }
              >
                <VaccineBanner />
              </S.JourneyDiv>

              <S.JourneyDiv onClick={() => push('/minha-jornada')}>
                <SurgeryJourneyBanner />
              </S.JourneyDiv>

              <S.JourneyDiv
                onClick={() => {
                  window.location.assign(TELEMEDICINE_LINK)
                }}
              >
                <TelemedicineBanner />
              </S.JourneyDiv>
            </Carousel>
          </S.CarouselContainer>
          <S.SurgicalJourneyDiv>
            {getJourneyButtons().map(({ label, icon, alt, path }, index) => (
              <S.SurgicalJourneyButton
                key={index}
                onClick={() =>
                  push(path, {
                    id: surgeries?.[0]?.surgicalId
                  })
                }
              >
                <div>
                  <img src={icon} alt={alt} />
                </div>
                <span>{label}</span>
              </S.SurgicalJourneyButton>
            ))}
          </S.SurgicalJourneyDiv>
        </S.JourneySection>
      </Container>
      {surgicalOrderCancellationNotification &&
        surgicalOrderCancellationNotification.doctor?.name && (
          <InfoCard
            mainText={`Dr(a). ${capitalizeEachWordText(
              surgicalOrderCancellationNotification.doctor.name
            )} solicitou o cancelamento do seu pedido cirúrgico.`}
            linkText="Ver solicitações de cancelamento"
            onLinkClick={async () => {
              await surgicalOrderService.confirmReadCancellationRequests()
              history.push('/atendimentos', {
                type: 'surgery-cancellation-requested'
              })
            }}
          />
        )}
      <BottomMenu
        onAppointment={(label) => {
          label === 'Marcar consulta' && setAppointmentModal(!appointmentModal)
        }}
      />
      <Modal
        show={appointmentModal}
        style={{ paddingTop: 20 }}
        close={() => setAppointmentModal(false)}
        preventAutomateClose
      >
        <S.Title>Quase lá!</S.Title>
        <S.Subtitle>
          Você está sendo direcionado para o nosso atendimento virtual no
          WhatsApp!
        </S.Subtitle>
        <WhatsAppButton
          handleRedirect={() => openPhoneApp('whatsApp', '553133399800')}
        />
      </Modal>
      <EmergencyRenewModals {...renewEmergencyTokenProps} />
    </S.Wrapper>
  )
}

const OpenSurgeriesModal = ({
  openSurgeriesModalVisible,
  setOpenSurgeriesModalVisible,
  openScheduledSurgeries
}: any) => {
  const history = useHistory()
  return (
    <Modal
      show={openSurgeriesModalVisible}
      style={{
        padding: '20px',
        background: '#4FBAB3',
        width: '312px',
        height: '336px',
        cursor: 'pointer'
      }}
      preventAutomateClose
    >
      <S.HeaderModalSheetContainer>
        <CloseIcon onClick={() => setOpenSurgeriesModalVisible(false)} />
      </S.HeaderModalSheetContainer>
      <OpenSurgeriesBanner
        onClick={() =>
          history.push('/cirurgias-nao-relizadas/motivo', {
            openScheduledSurgeries
          })
        }
      />
    </Modal>
  )
}
