import React from 'react'
import { makeApiRepository } from 'main/factories/repositories/api-repository-factory'
import EmergencyRoomService from 'presentation/pages/EmergencyRoomService'
import { AccompanyingRepository } from 'repository/repositories/accompanying/accompanying-repository'
import { EmergencyRoomRepository } from 'repository/repositories/emergency-room-atendance/emergency-room-repository'
import { HospitalRepository } from 'repository/repositories/hospital/hospital-repository'
import { RemoteAddAccompanying } from 'service/usecases/add-accompanying/remote-add-accompanying'
import { RemoteCreateEmergencyRoom } from 'service/usecases/create-emergency-room/remote-create-emergency-room'
import { RemoteLoadHospitals } from 'service/usecases/load-hospitals/remote-load-hospitals'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
import { makePatientRepository } from '../make-patient-repository-factory'
import { RemoteUpdatePatientHealthInsurance } from 'service/usecases/patient-update-health-insurance/remote-patient-update-health-insurance'
import { RemoteUploadPatientDocument } from 'service/usecases/upload-patient-document/remote-upload-patient-document'
import { RemotePatientUpdateInfoById } from 'service/usecases/patient-update-info-by-id/remote-patient-update-info-by-id'

export default function makePatientEmergencyRoomService() {
  const apiRepository = makeApiRepository()
  const patientRepository = makePatientRepository()
  const accompanyingRepository = new AccompanyingRepository(apiRepository)
  const emergencyRoomRepository = new EmergencyRoomRepository(apiRepository)
  const addAccompanying = new RemoteAddAccompanying(accompanyingRepository)
  const hospitalRepository = new HospitalRepository(apiRepository)
  const loadHospitals = new RemoteLoadHospitals(hospitalRepository)
  const loadPatient = new RemoteLoadPatientInfo(patientRepository)
  const createEmergencyRoom = new RemoteCreateEmergencyRoom(
    emergencyRoomRepository
  )

  const updateHealthInsuranceData = new RemoteUpdatePatientHealthInsurance(
    patientRepository
  )

  const uploadDocument = new RemoteUploadPatientDocument(patientRepository)

  const updatePatientById = new RemotePatientUpdateInfoById(patientRepository)

  return (
    <EmergencyRoomService
      loadPatient={loadPatient}
      updatePatientPersonalData={updatePatientById}
      loadHospitals={loadHospitals}
      addAccompanying={addAccompanying}
      createEmergencyRoom={createEmergencyRoom}
      updateHealthInsuranceData={updateHealthInsuranceData}
      uploadDocument={uploadDocument}
    />
  )
}
