import { LoadMedicalTeams } from 'domain/usecases/medical-team/load-medical-teams'
import { DoctorRepository } from 'repository/interfaces/doctor-repository'

export class RemoteLoadMedicalTeams implements LoadMedicalTeams {
  constructor(private readonly doctorRepository: DoctorRepository) {}

  load(params: LoadMedicalTeams.Params): Promise<LoadMedicalTeams.Model> {
    return this.doctorRepository.loadMedicalTeams(params)
  }
}
