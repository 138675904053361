import React from 'react'
import SchedulingExamsLayout from 'presentation/layouts/SchedulingExamsLayout'
import { RemoteUploadPatientDocument } from 'service/usecases/upload-patient-document/remote-upload-patient-document'
import { makePatientRepository } from 'main/factories/pages/make-patient-repository-factory'
import { RemoteDownloadPatientDocument } from 'service/usecases/download-patient-document/remote-download-patient-document'

const SchedulingExams = () => {
  const patientRepository = makePatientRepository()
  const uploadDocument = new RemoteUploadPatientDocument(patientRepository)
  const downloadDocument = new RemoteDownloadPatientDocument(patientRepository)

  return (
    <SchedulingExamsLayout
      uploadDocument={uploadDocument}
      downloadDocument={downloadDocument}
    />
  )
}

export default SchedulingExams
