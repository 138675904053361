import React from 'react'
import { Menu } from './menu/menu-context'
import makeCurrentUser from 'main/factories/stores/current-user/current-user-factory'
import File from './file/file-context'
import makePermisions from 'main/factories/stores/permissions/permissions-factory'
import makeCurrentHospital from 'main/factories/stores/current-hospital/current-hospital-factory'
import makeCurrentSurgery from 'main/factories/stores/current-surgery/current-surgery.-factory'
import makeCurrentFilters from 'main/factories/stores/current-filters/current-filters-factory'
import makeExamStore from 'main/factories/stores/exam/exam-store-factory'

export const storesContext = React.createContext({
  currentAccount: makeCurrentUser(),
  permissions: makePermisions(),
  menu: new Menu(),
  file: new File(),
  currentHospital: makeCurrentHospital(),
  currentSurgery: makeCurrentSurgery(),
  currentFilters: makeCurrentFilters(),
  examsStore: makeExamStore()
})
