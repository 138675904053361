import styled from 'styled-components'

export const Wrapper = styled.form`
  height: auto;
  display: block;
`

export const Term = styled.div`
  .header {
    display: flex;
    align-items: center;
    height: 110px;
    padding: 0 16px;
    h1 {
      width: 100%;
      font-size: ${({ theme }) => theme.ds.typography.size.large};
      color: ${({ theme }) => theme.ds.colors.black};
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
    }

    @media (min-width: 600px) {
      text-align: center;
    }
  }

  .content {
    display: block;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 250px);
    padding: 16px 16px;
    font-family: ${({ theme }) => theme.ds.typography.fontFamily};
    border-top: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
    border-bottom: 1px solid ${({ theme }) => theme.ds.colors.neutral100};
    color: ${({ theme }) => theme.ds.colors.black};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: block;
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      color: ${({ theme }) => theme.ds.colors.black};
    }

    h1 {
      font-size: ${({ theme }) => theme.ds.typography.size.large};
    }
    h2 {
      font-size: ${({ theme }) => theme.ds.typography.size.medium};
    }
    h3 {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }
    h4 {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }
    h5 {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }
    h6 {
      font-size: ${({ theme }) => theme.ds.typography.size.small};
    }

    div {
      &:not(:last-of-type) {
        margin-bottom: 32px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 8px;
      }
    }

    span,
    p,
    a {
      font-weight: ${({ theme }) => theme.ds.typography.weight.normal};
      line-height: ${({ theme }) => theme.ds.typography.lineHeight.medium};
      margin-bottom: 24px;
    }

    p,
    li {
      text-align: justify;
    }

    b,
    strong {
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
    }

    i {
      font-style: italic;
    }

    ul {
      list-style: none;
      padding: 0 0 0 16px;

      li {
        position: relative;
        margin: 0 0 16px 0;

        /* &::before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: black;
        } */
      }
    }

    a {
      display: inline-block;
      color: ${({ theme }) => theme.ds.colors.primary600};
      border: none;
      text-decoration: none;
      outline: none;
      font-weight: ${({ theme }) => theme.ds.typography.weight.bold};
      transition: all ease 0.2s;
      margin: 0;
      word-break: break-all;

      &:hover {
        color: ${({ theme }) => theme.ds.colors.primary400};
      }
    }
  }
`

export const AcceptTerm = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  position: absolute;
  height: 140px;
  right: 0;
  bottom: 0;
  left: 0;
`
