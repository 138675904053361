import styled, { css } from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  scrollbar-width: none;
`

export const Row = styled.div<{ cols: number }>`
  ${({ cols }) => css`
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-rows: 1fr;
    grid-template-columns: ${`repeat(${cols}, 1fr)`};
    margin-top: 24px;
  `}
`

export const BodyInfoLabel = styled.small`
  color: ${({ theme }) => theme.colors.lightGray};
  height: 8px;
  width: 1px;
  display: block;
  margin-right: 10px;
`
