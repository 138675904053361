import { CheckPatientByEmail } from 'domain/usecases/patient/check-patient-by-email'
import { PatientRepository } from 'repository/interfaces/patient-repository'

export class RemoteCheckPatientByEmail implements CheckPatientByEmail {
  constructor(private readonly patientRepository: PatientRepository) {}

  check(email: string): Promise<CheckPatientByEmail.Model> {
    return this.patientRepository.checkPatientByEmail(email)
  }
}
