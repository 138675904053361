import { makeAutoObservable } from 'mobx'
import { Patient } from 'domain/entities/patient-model'
import { Hospital } from 'domain/entities/hospital-model'
import { SurgeryCenter } from 'domain/entities/surgery-center-model'
import { HealthInsurance } from 'domain/entities/health-insurance'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
// import { CreateSurgicalOrderFormValues } from 'presentation/doctor/pages/AddSurgicalOrder/CreateSurgicalOrder'

export type CreateSurgicalOrderFormValues = {
  patient: Patient
  hospital: Hospital
  surgeryCenter: SurgeryCenter
  healthInsurance: HealthInsurance
  healthCardFiles: ListFile[]
}

export class CurrentSurgery {
  constructor() {
    makeAutoObservable(this)
  }

  surgeryIdSelected = 0
  newSurgicalOrder = {} as CreateSurgicalOrderFormValues
  surgeryPatient = {} as Patient

  getSurgery() {
    return this.newSurgicalOrder
  }

  setSurgery(newSurgery: CreateSurgicalOrderFormValues) {
    this.newSurgicalOrder = newSurgery
  }

  getSurgeryIdSelected() {
    return this.surgeryIdSelected
  }

  getSurgeryPatient() {
    return this.surgeryPatient
  }

  setSurgeryPatient(surgeryPatientData: Patient) {
    this.surgeryPatient = surgeryPatientData
  }

  setSurgeryIdSelected(surgeryIDSelected: number) {
    this.surgeryIdSelected = surgeryIDSelected
  }
}

export default CurrentSurgery
