import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    .react-calendar {
      width: 100%;
      border: none;
      font-family: ${theme.ds.typography.fontFamily} !important;
      background-color: ${theme.ds.colors.neutral25};
      .react-calendar__navigation__arrow {
        color: ${theme.ds.colors.neutral500};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        svg {
          font-size: ${theme.ds.typography.size.large};
        }
        &:hover:not(:disabled) {
          color: ${theme.ds.colors.neutral700};
          background-color: ${theme.ds.colors.neutral25};
        }
        &:active,
        &:focus {
          background-color: ${theme.ds.colors.neutral25};
        }
        &:disabled {
          color: ${theme.ds.colors.neutral200};
        }
      }

      .react-calendar__navigation__label {
        color: ${theme.ds.colors.neutral800};
        font-size: ${theme.ds.typography.size.xlarge};
        font-weight: ${theme.ds.typography.weight.normal};

        height: 40px;
        &:hover {
          background-color: ${theme.ds.colors.neutral100} !important;
        }
      }

      .react-calendar__month-view__weekdays
        .react-calendar__month-view__weekdays__weekday {
        abbr[title] {
          text-decoration: none;
          text-transform: lowercase;
          color: ${theme.ds.colors.neutral300};
          font-size: ${theme.ds.typography.size.medium};
          font-weight: ${theme.ds.typography.weight.bold};
          background-color: ${theme.ds.colors.neutral25};
        }
        background-color: ${theme.ds.colors.neutral25};
      }

      button {
        position: relative;
        height: 60px;
        font-weight: ${theme.ds.typography.weight.bold};
        background-color: ${theme.ds.colors.neutral25};
        border-radius: 8px;

        /* MONTHS / YEARS */
        &.react-calendar__year-view__months__month,
        &.react-calendar__decade-view__years__year,
        &.react-calendar__century-view__decades__decade {
          color: ${theme.ds.colors.primary600};
          background-color: ${theme.ds.colors.neutral25};
          border: ${theme.ds.colors.neutral25};
          border-radius: 30px;

          &.react-calendar__tile--hasActive {
            color: ${theme.ds.colors.white};
            background-color: ${theme.ds.colors.primary600};
          }

          &:hover:not(:disabled, .react-calendar__tile--hasActive) {
            color: ${theme.ds.colors.primary800};
          }
        }

        /* DAYS */
        &.react-calendar__month-view__days__day {
          color: ${theme.ds.colors.primary600};
          font-weight: ${theme.ds.typography.weight.black};

          &.react-calendar__tile--active {
            color: ${theme.ds.colors.white};
            abbr {
              background-color: ${theme.ds.colors.primary600};
            }
          }

          &:hover:not(:disabled, .react-calendar__tile--active) {
            background-color: ${theme.ds.colors.neutral25};
            color: ${theme.ds.colors.primary800};
          }
        }

        /* GENERAL */
        &.react-calendar__month-view__days__day,
        &.react-calendar__year-view__months__month,
        &.react-calendar__decade-view__years__year,
        &.react-calendar__century-view__decades__decade {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          abbr {
            min-width: 32px;
            max-height: 32px;
            line-height: 32px;
            border-radius: 500%;
          }
        }

        &:hover,
        &:active,
        &:focus {
          background-color: ${theme.ds.colors.neutral25};
          abbr {
            background-color: ${theme.ds.colors.neutral25};
          }
        }

        &:disabled {
          color: ${theme.ds.colors.neutral300};
          font-weight: ${theme.ds.typography.weight.normal};
        }
      }

      /* ---------------------------*/

      & .react-calendar__tile--rangeStart abbr,
      .react-calendar__tile--rangeEnd abbr {
        background-color: ${theme.ds.colors.primary600} !important;
      }

      & .react-calendar__tile--range {
        position: relative;
      }

      & .react-calendar__tile--range:before {
        content: '';
        position: absolute;
        background: rgba(18, 95, 28, 0.3) !important;
        height: 68%;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(-50%);
      }

      & .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd {
        &:before {
          width: 50%;
        }
      }

      & .react-calendar__tile--rangeStart {
        &:before {
          left: 50%;
        }
      }

      & .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
        &:before {
          display: none;
        }
      }

      &__tile {
        padding: 0;

        &--now {
          background: ${theme.ds.colors.neutral25};
        }
      }
    }
  `}
`
