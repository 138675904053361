import React from 'react'
import { useHistory } from 'react-router'

import Button from 'presentation/components/Button'
import Heading from 'presentation/components/Heading'
import { Package } from 'presentation/utils/newborn-packages-informations'
import { openPhoneApp } from 'presentation/utils/mobile-actions'
import { ReactComponent as ShoppingCart } from 'presentation/assets/icons/outlined-shopping-cart.svg'
import * as S from './styles'

type PackagesCardProps = {
  packageInformation: Package
  image: string
}

export default function VaccinesPackageCard({
  packageInformation,
  image
}: PackagesCardProps) {
  const history = useHistory()

  const handleRedirect = () =>
    openPhoneApp(
      'whatsApp',
      '553133399800',
      'Olá! Gostaria de saber mais sobre os pacotes de vacinas da Rede Mater Dei.'
    )

  const handleClickOnMoreInformationsButton = () => {
    history.push('/detalhes-do-pacote', {
      packageInformation: packageInformation
    })
  }

  const { alias, vaccines, price } = packageInformation

  const vaccinesNames = vaccines.map((vaccine) => vaccine.vaccineName)

  return (
    <S.Card>
      <S.Image src={image} alt={'newbornPackagesImages'} />

      <Heading as="h5" role={'alias-field'} style={{ marginTop: 10 }}>
        {alias}
      </Heading>

      <S.TextContainer>
        {vaccinesNames.map((vaccineName, index) => (
          <p key={index} role={'vaccine-name-p'}>
            {vaccineName}
          </p>
        ))}
      </S.TextContainer>

      <Heading as="h5" color={'primary'} role={'package-price'}>
        Valor: {price}
      </Heading>

      <Button
        fullWidth={true}
        type="button"
        variant="outlined"
        style={{
          backgroundColor: 'transparent',
          marginTop: 30,
          marginBottom: 10
        }}
        onClick={handleClickOnMoreInformationsButton}
      >
        Saiba mais
      </Button>

      <Button fullWidth={true} onClick={handleRedirect} role={'get-packages'}>
        <ShoppingCart style={{ marginRight: 5 }} />
        Contratar pacote
      </Button>
    </S.Card>
  )
}
