// import { Notification } from 'domain/entities/notification-model'
import { LoadNotifications } from 'domain/usecases/notification/load-notifications'
import { NotificationRepository } from 'repository/interfaces/notification-repository'

export class RemoteLoadNotifications implements LoadNotifications {
  constructor(
    private readonly notificationRepository: NotificationRepository
  ) {}

  async load(
    params: LoadNotifications.Params
  ): Promise<LoadNotifications.Model> {
    return this.notificationRepository.loadNotifications(params)
  }
}

export type LoadNotificationsModel = LoadNotifications.Model
