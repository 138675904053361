import styled from 'styled-components'

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 22px;
`

export const TextAreaWrapper = styled.div`
  margin-top: 16px;
`

export const Wrapper = styled.div`
  margin-bottom: 16px;
`
