import React from 'react'
import SupportText from '../../../components/SupportText'
import * as S from './styles'

type PainSliderProps = {
  value: number
  onChange: (value: number) => void
  startLabel?: string
  endLabel?: string
}

export function PainSlider({
  value,
  onChange,
  startLabel = 'Dor baixa',
  endLabel = 'Dor insuportável'
}: PainSliderProps) {
  const marks = Array.from({ length: 10 }, (_, i) => i).map((i) => ({
    value: i + 1,
    label: `${i + 1}`
  }))
  return (
    <>
      <S.StyledMuiSlider
        step={null}
        min={1}
        max={10}
        valueLabelDisplay="off"
        marks={marks}
        value={value}
        onChange={(_, intensity) => onChange(intensity as number)}
      />
      <S.SupportTexts>
        <SupportText color="lightGray">{startLabel}</SupportText>
        <SupportText color="lightGray">{endLabel}</SupportText>
      </S.SupportTexts>
    </>
  )
}
