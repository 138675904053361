import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'

import { ContainerNew } from 'presentation/components/ContainerNew'
import { ReactComponent as CreditCardIcon } from 'presentation/assets/icons/credit-card.svg'
import { ReactComponent as CalendarIcon } from 'presentation/assets/icons/new_calendar.svg'
import { ReactComponent as PinIcon } from 'presentation/assets/icons/new_pin.svg'
import BottomMenu from 'presentation/components/BottomMenu'
import ProfilePic from 'presentation/components/ProfilePic'
import { FormattedSchedule } from 'domain/entities/schedule-model'
import * as S from './styles'
import HeaderNew from 'presentation/components/HeaderNew'

type LocationType = {
  state: {
    schedule: FormattedSchedule
  }
}

const SchedulingDetailsLayout = () => {
  const history = useHistory()
  const location: LocationType = useLocation()
  const schedule = location.state && location.state?.schedule

  if (!schedule) {
    return <Redirect to="/consultas" />
  }

  return (
    <>
      <HeaderNew
        actualPageTitle="Mais detalhes"
        actualPageOnClick={() => history.push('/consultas')}
      />
      <ContainerNew noPadding noHeader>
        <S.SchedulingWrapper>
          {schedule && (
            <>
              <S.CardSchedulingInfos>
                <div className="header">
                  <div className="img">
                    <ProfilePic
                      data-testid="profile-pic"
                      outlined
                      size="xmedium"
                      name={schedule.doctor?.name}
                    />
                  </div>
                  <div className="header-infos">
                    <h2 className="title">
                      Dr(a){' '}
                      <span>{schedule.doctor?.name?.toLocaleLowerCase()}</span>
                    </h2>
                    <h4 className="subtitle">
                      {schedule.specialty?.name?.toLocaleLowerCase()}
                    </h4>
                  </div>
                </div>
                <div className="content">
                  <div className="box">
                    <CalendarIcon className="icon" />
                    <div className="infos">
                      <h4 className="title">
                        <span className="date">
                          {moment(schedule?.date).format('dddd - DD/MM/YYYY')}
                        </span>{' '}
                        às {moment(schedule?.date).format('LT')}
                      </h4>
                    </div>
                  </div>

                  <div className="box">
                    <CreditCardIcon className="icon" />
                    <div className="infos">
                      {schedule.healthInsurance?.name?.toLocaleLowerCase() ===
                      'particular' ? (
                        <>
                          <h6 className="title -capitalize">
                            Consulta Particular
                          </h6>
                          <p className="description">
                            Pagamento será feito no local.
                          </p>
                          <p className="description">
                            Aceitamos cartões de débito e crédito, Pix e
                            dinheiro.
                          </p>
                        </>
                      ) : (
                        <>
                          <h6 className="title -capitalize">Convênio</h6>
                          <p className="description -capitalize">
                            {schedule.healthInsurance?.name?.toLocaleLowerCase()}{' '}
                            |{' '}
                            {schedule.healthInsurance?.plan_name?.toLocaleLowerCase()}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="box">
                    <PinIcon className="icon" />
                    <div className="infos">
                      <h6 className="title -capitalize">
                        {schedule.unit?.name?.toLocaleLowerCase()}
                      </h6>
                      <p className="description -capitalize">
                        {schedule.unit?.description?.toLocaleLowerCase()}
                      </p>
                    </div>
                  </div>

                  {schedule.healthInsurance?.name?.toLocaleLowerCase() !==
                    'particular' && (
                    <S.CardInfo>
                      <h6 className="title">Lembre-se</h6>
                      <p className="paragraph">
                        Não se esqueça de levar o documento de identidade e
                        carteirinha do convênio.
                      </p>
                    </S.CardInfo>
                  )}
                </div>

                <div className="footer">
                  <p className="text">
                    <b>Dúvidas?</b> Entre em contato com a Central Telefônica,
                    acessando o link:{' '}
                    <a
                      href="https://www.materdei.com.br/fale-conosco"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.materdei.com.br/fale-conosco
                    </a>
                  </p>
                </div>
              </S.CardSchedulingInfos>
            </>
          )}
        </S.SchedulingWrapper>
      </ContainerNew>
      <BottomMenu />
    </>
  )
}

export default SchedulingDetailsLayout
