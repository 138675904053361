import { SearchExams } from 'domain/usecases/exam/search-exams'
import { ExamRepository } from 'repository/interfaces/exam-repository'

export class RemoteSearchExams implements SearchExams {
  constructor(private readonly examRepository: ExamRepository) {}
  async load(params: SearchExams.Params): Promise<SearchExams.Model> {
    return this.examRepository.searchExams(params)
  }
}

export type SearchExamsModel = SearchExams.Model
