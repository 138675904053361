import React, { useEffect, useState } from 'react'
import { ContainerNew } from 'presentation/components/ContainerNew'
import ButtonNew from 'presentation/components/ButtonNew'
import TextAreaNew from 'presentation/shared/components/TextAreaNew'
import SupportTextNew from 'presentation/components/SupportTextNew'
import { positiveReasons, negativeReasons } from './options'
import NpsScale from 'presentation/shared/components/NpsScale'
import * as S from './styles'
import { useFormik } from 'formik'
import { useHistory, useLocation } from 'react-router'

type Props = {
  sendRate: (params: Params) => void
  hasParamTokenState?: boolean
}

type Params = {
  rate: number
  reasons: {
    name: string
    value: boolean
  }[]
  suggestion: string
}

type LocationType = {
  pathname: string
  state: {
    rate?: number
  }
}

const SchedulingNpsForm = ({ sendRate, hasParamTokenState }: Props) => {
  const history = useHistory()
  const location: LocationType = useLocation()

  const { state } = useLocation<Location>() as any

  const formik = useFormik({
    initialValues: {
      rate: -1 || location.state?.rate,
      reasons: [''],
      suggestion: ''
    },
    onSubmit: () => submitForm()
  })

  const submitForm = async () => {
    const items = formik.values.rate > 6 ? positiveReasons : negativeReasons

    const payload = {
      ...formik.values,
      reasons: items.map((i) => ({
        name: i,
        value: formik.values.reasons.includes(i)
      }))
    }

    await sendRate(payload)

    if (location.pathname === '/agendamento/exames/avalie') {
      if (state?.useSedation === true) {
        history.push('/agendamento/exames/pre-anestesica', {
          hasParamTokenState: hasParamTokenState
        })
        return
      }
      history.push('/agendamento/exames', {
        hasParamTokenState: hasParamTokenState
      })
      return
    }

    history.push('/consultas')
  }

  function handleSkipNps() {
    if (location.pathname === '/agendamento/exames/avalie') {
      if (state?.useSedation === true) {
        history.push('/agendamento/exames/pre-anestesica', state)
        return
      }
      history.push('/agendamento/exames', {
        hasParamTokenState: hasParamTokenState
      })
      return
    }
  }

  const [question, setQuestion] = useState('')

  useEffect(() => {
    if (location.pathname === '/agendamento/exames/avalie') {
      setQuestion(
        'De 0 a 10, como foi sua experiência com o  agendamento de exames ? '
      )
    }
  }, [])

  const changeRate = (value: number) => {
    if (
      (formik.values.rate <= 6 && value >= 7) ||
      (formik.values.rate >= 7 && value <= 6)
    ) {
      formik.setFieldValue('reasons', [])
    }
    formik.setFieldValue('rate', value)
  }

  // const checkItem = (option: string) => {
  //   const alreadyIn = formik.values?.reasons?.some((i) => i === option)

  //   if (alreadyIn) {
  //     formik.setFieldValue(
  //       'reasons',
  //       formik.values?.reasons?.filter((i) => i !== option)
  //     )
  //   } else {
  //     formik.setFieldValue('reasons', [
  //       ...(formik.values?.reasons || []),
  //       option
  //     ])
  //   }
  // }

  // const reasonsChips = () => {
  //   const items = formik.values.rate > 6 ? positiveReasons : negativeReasons

  //   return items.map((item: string) => (
  //     <S.ChipNew
  //       key={item}
  //       label={item}
  //       customValue={item}
  //       name="reasons"
  //       onCheck={checkItem}
  //       type="checkbox"
  //       checked={formik.values?.reasons?.some((i) => i === item) || false}
  //     />
  //   ))
  // }

  // const submitButton = () => {
  //   return (
  //     <ButtonNew
  //       type="submit"
  //       size="large"
  //       fullWidth
  //       disabled={formik.values.rate === -1 || formik.values.rate === undefined}
  //     >
  //       Enviar
  //     </ButtonNew>
  //   )
  // }

  return (
    <ContainerNew
      title=""
      titleWeight="bold"
      titleSize="large"
      form
      // primaryButton={submitButton()}
      onSubmit={formik.handleSubmit}
      style={{ background: 'white', textAlign: 'center' }}
      gap="16px"
    >
      <div>
        <SupportTextNew color="neutral700">
          {question ||
            'De 0 a 10, como foi sua experiência com o agendamento de consultas?'}
        </SupportTextNew>
        <NpsScale
          style={{ marginTop: '8px' }}
          value={formik.values.rate}
          onChange={(value) => changeRate(value)}
        />
      </div>

      {/* {formik.values.rate > -1 && ( */}
      <>
        <S.ChipsContainer>
          <SupportTextNew color="neutral700">
            Selecione um ou mais itens que representem sua nota.
          </SupportTextNew>
          {/* <div className="options">{reasonsChips()}</div> */}
        </S.ChipsContainer>

        <TextAreaNew
          style={{
            width: '100%',
            maxWidth: '580px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '8px',
            textAlign: 'center'
          }}
          rows={4}
          value={formik.values.suggestion}
          labelColor="neutral700"
          label="O que motivou sua nota? (opcional)"
          onChange={formik.handleChange('suggestion')}
          placeholder="Deixe aqui seu elogio, crítica ou sugestão de como podemos melhorar"
        />
      </>
      {/* )} */}

      <S.ButtonPanel>
        <ButtonNew
          type="submit"
          size="large"
          fullWidth
          disabled={
            formik.values.rate === -1 || formik.values.rate === undefined
          }
        >
          Enviar
        </ButtonNew>

        <ButtonNew
          type="button"
          outlined
          onClick={handleSkipNps}
          size="large"
          fullWidth
        >
          Pular avaliação
        </ButtonNew>
      </S.ButtonPanel>
    </ContainerNew>
  )
}

export default SchedulingNpsForm
