import React from 'react'

import { ApiClientImplementation } from 'infra/api-client/api-client'
import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { PatientRepository } from 'repository/repositories/patient/patient-repository'
import HealthInsurancesList from 'presentation/pages/HealthInsurancesList'
import { RemoteLoadPatientInfo } from 'service/usecases/load-patient-info/remote-load-patient-info'
// import { RemotePatientUpdateInfo } from 'service/usecases/patient-update-info/remote-patient-update-info'

export default function makePatientHealthInsurancesInfo() {
  const apiClient = new ApiClientImplementation()
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(apiClient)
  )
  const patientRepository = new PatientRepository(apiRepository)

  const loadHealthInsurances = new RemoteLoadPatientInfo(patientRepository)
  // const deleteHealthInsurance = new RemotePatientUpdateInfo(patientRepository)

  return (
    <HealthInsurancesList
      loadHealthInsurances={loadHealthInsurances}
      // deleteHealthInsurance={deleteHealthInsurance}
    />
  )
}
