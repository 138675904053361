import EmailConfirmation from 'presentation/pages/EmailConfirmation'
import React from 'react'
import { RemoteExamUpdate } from 'service/usecases/exam-update-info/remode-exam-update-info'
import { RemotePatientUpdateInfo } from 'service/usecases/patient-update-info/remote-patient-update-info'
import { makePatientRepository } from '../make-patient-repository-factory'

export default function makeEmailConfirmation() {
  const patientRepository = makePatientRepository()
  const updatePatientInfo = new RemotePatientUpdateInfo(patientRepository)
  const updateExam = new RemoteExamUpdate(patientRepository)

  return (
    <EmailConfirmation
      updateUserEmail={updatePatientInfo}
      updateExam={updateExam}
    />
  )
}
