import styled from 'styled-components'

export const Image = styled.div`
  img {
    width: 400px;
    max-width: 100%;
  }
`

export const TextContent = styled.div`
  text-align: center;
  max-width: 500px;
`
