import { CreateEmergencyRoomInMv } from 'domain/usecases/emergency-room/create-emergency-room-in-mv'
import { EmergencyRoomRepository } from 'repository/interfaces/emergency-room-repository'

export class RemoteCreateEmergencyRoomInMv implements CreateEmergencyRoomInMv {
  constructor(
    private readonly emergencyRoomRepository: EmergencyRoomRepository
  ) {}

  create(
    params: CreateEmergencyRoomInMv.Params
  ): Promise<CreateEmergencyRoomInMv.Model> {
    return this.emergencyRoomRepository.createEmergencyRoomInMv(params)
  }
}
