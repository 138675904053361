import {
  BreathingDifficultyOptions,
  Locations,
  SymptomsValues
} from 'domain/entities/emergency-room-symptoms.model'
import { Patient } from 'domain/entities/patient-model'
import { useFormik } from 'formik'
import { ReactComponent as ChestIcon } from 'presentation/assets/emergency-service-icons/chest.svg'
import { ReactComponent as HeadacheIcon } from 'presentation/assets/emergency-service-icons/headache.svg'
import { ReactComponent as StomachIcon } from 'presentation/assets/emergency-service-icons/stomach.svg'
import { PainIntensityModal } from 'presentation/components/Forms/EmergencyRoom/EmergencySymptoms/PainIntensityModal'
import { SubmitEmergencyAttendance } from 'presentation/layouts/EmergencyRoom'
import ActualPage from 'presentation/components/ActualPage'
import Button from 'presentation/components/Button'
import { CarouselState } from 'presentation/components/Carousel'
import Checkbox from 'presentation/shared/components/Checkbox'
import Chip from 'presentation/shared/components/Chip'
import Heading from 'presentation/components/Heading'
import SupportText from 'presentation/components/SupportText'
import TextArea from 'presentation/shared/components/TextArea'
import TextField from 'presentation/components/TextField'
import { temperatureMask } from 'presentation/utils/masks'
import React, { useState } from 'react'
import * as S from './styles'
import * as yup from 'yup'
import theme from 'presentation/styles/theme'

type EmergencyRoomSelectUnitProps = {
  patient: Patient
  carousel: CarouselState
  setStep: (number: number) => void
  setForm: (data: SubmitEmergencyAttendance) => void
  form?: SubmitEmergencyAttendance
}
export function EmergencyRoomSymptoms({
  carousel,
  setStep,
  setForm,
  form
}: EmergencyRoomSelectUnitProps) {
  const [showWarningFever, setShowWarningFever] = useState<boolean>(false)

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isValid,
    touched,
    errors
  } = useFormik<SymptomsValues>({
    initialValues: {},
    validationSchema,
    validateOnMount: true,
    onSubmit: (data) => {
      delete data.isFever
      setForm({
        ...form,
        symptoms: {
          ...data,
          temperatureFever: data?.temperatureFever
        }
      })
    }
  })

  const onCheckBreathingOptions = (option: BreathingDifficultyOptions) => {
    const alreadyIn = values?.breathingOptions?.some((op) => op === option)

    if (alreadyIn) {
      setFieldValue(
        'breathingOptions',
        values?.breathingOptions?.filter((op) => op !== option)
      )
    } else {
      setFieldValue('breathingOptions', [
        ...(values?.breathingOptions || []),
        option
      ])
    }
  }

  const [selectedPain, setSelectedPain] = useState<Locations | undefined>()

  const onSelectLocationPain = (location: Locations) => {
    setSelectedPain(location)
  }

  const onConfirmIntensity = (value: number) => {
    const filterLocation = values?.painLocation?.filter(
      (painLocation) => painLocation.location !== selectedPain
    )
    if (value === 0) {
      setFieldValue('painLocation', [...(filterLocation || [])])
    } else {
      setFieldValue('painLocation', [
        ...(filterLocation || []),
        {
          location: selectedPain,
          intensity: value
        }
      ])
    }
    setSelectedPain(undefined)
  }

  const findPain = (location: Locations) => {
    return values?.painLocation?.find(
      (painLocation) => painLocation?.location === location
    )
  }

  const isChecked = (option: BreathingDifficultyOptions) => {
    return (
      values.breathingOptions?.some(
        (breathOption) => breathOption === option
      ) || false
    )
  }

  const needHead = (findPain('HEAD')?.intensity || 0) > 5
  const needThorax = (findPain('THORAX')?.intensity || 0) > 0

  const handleFeverTemperature = (degrees?: string) => {
    degrees && parseFloat(degrees) < 37.5
      ? setShowWarningFever(true)
      : setShowWarningFever(false)
    setFieldValue('temperatureFever', degrees)

    if (degrees) {
      setFieldValue('isFever', parseFloat(degrees))
    }
  }

  return (
    <>
      <S.SymptomsContainer>
        <S.ActualPage>
          <ActualPage
            onClick={() => {
              carousel.slideTo(2)
            }}
            text="Voltar"
          />
        </S.ActualPage>
        <Heading as="h5" color="primary">
          Sintomas
        </Heading>
        <SupportText color="lightGray">
          Informe o que está sentindo para prepararmos o seu atendimento.
        </SupportText>
        <strong>Está com febre?*</strong>
        <S.SelectUnitChips>
          <Chip
            label="Sim"
            checked={values.fever === 'YES'}
            value={'YES'}
            onCheck={() => setFieldValue('fever', 'YES')}
            id={'fever-yes'}
          />
          <Chip
            label="Não"
            checked={values.fever === 'NO'}
            value={'NO'}
            onCheck={() => {
              setFieldValue('temperatureFever', undefined)
              setFieldValue('fever', 'NO')
              setFieldValue('isFever', undefined)
            }}
            id={'fever-no'}
          />
          <Chip
            label="Não sei dizer"
            value={'I_CANT_TELL'}
            checked={values.fever === 'I_CANT_TELL'}
            onCheck={() => {
              setFieldValue('temperatureFever', undefined)
              setFieldValue('fever', 'I_CANT_TELL')
              setFieldValue('isFever', undefined)
            }}
            id={'fever-i-cant-tell'}
          />
        </S.SelectUnitChips>
        {values.fever === 'YES' && (
          <TextField
            mask={temperatureMask}
            label="Qual a temperatura?"
            placeholder="Digite aqui..."
            name="temperatureFever"
            value={values.temperatureFever || ''}
            onChange={(degree) => {
              handleFeverTemperature(degree.currentTarget.value)
            }}
            spellCheck="false"
            error={touched.temperatureFever && errors.temperatureFever}
          />
        )}
        {showWarningFever && values.fever === 'YES' && (
          <strong style={{ color: theme.colors.alert }}>
            {errors.isFever}
          </strong>
        )}
      </S.SymptomsContainer>
      <hr />
      <S.SymptomsContainer>
        <strong>Dificuldade respiratória?*</strong>
        <S.SelectUnitChips>
          <Chip
            labelFor="Sim breathingDifficulty"
            label="Sim"
            checked={values.breathingDifficulty === true}
            value={'true'}
            onCheck={() => {
              setFieldValue('breathingDifficulty', true)
            }}
            id={'breathing-yes'}
          />
          <Chip
            labelFor="Não breathingDifficulty"
            label="Não"
            checked={values.breathingDifficulty === false}
            value={'false'}
            onCheck={() => {
              setFieldValue('breathingDifficulty', false)
              setFieldValue('breathingOptions', undefined)
            }}
            id={'breathing-no'}
          />
        </S.SelectUnitChips>
        {values.breathingDifficulty && values.breathingDifficulty === true && (
          <>
            <SupportText color="lightGray">Selecione abaixo:</SupportText>
            <Checkbox
              disabled={!values.breathingDifficulty}
              labelColor={values.breathingDifficulty ? 'gray' : 'lightGray'}
              label="Tosse"
              id="breathingDifficulty.cough"
              name="breathingDifficulty.cough"
              labelFor="breathingDifficulty.cough"
              onCheck={() => onCheckBreathingOptions('COUGH')}
              checked={isChecked('COUGH')}
            />
            <Checkbox
              disabled={!values.breathingDifficulty}
              labelColor={values.breathingDifficulty ? 'gray' : 'lightGray'}
              label="Coriza"
              id="breathingDifficulty.runny_nose"
              name="breathingDifficulty.runny_nose"
              labelFor="breathingDifficulty.runny_nose"
              onCheck={() => onCheckBreathingOptions('RUNNY_NOSE')}
              checked={isChecked('RUNNY_NOSE')}
            />
            <Checkbox
              disabled={!values.breathingDifficulty}
              labelColor={values.breathingDifficulty ? 'gray' : 'lightGray'}
              label="Dor de garganta"
              id="breathingDifficulty.sore_throat"
              name="breathingDifficulty.sore_throat"
              labelFor="breathingDifficulty.sore_throat"
              onCheck={() => onCheckBreathingOptions('SORE_THROAT')}
              checked={isChecked('SORE_THROAT')}
            />
            <Checkbox
              disabled={!values.breathingDifficulty}
              labelColor={values.breathingDifficulty ? 'gray' : 'lightGray'}
              label="Falta de ar"
              id="breathingDifficulty.shortness_of_breath"
              name="breathingDifficulty.shortness_of_breath"
              labelFor="breathingDifficulty.shortness_of_breath"
              onCheck={() => onCheckBreathingOptions('SHORTNESS_OF_BREATH')}
              checked={isChecked('SHORTNESS_OF_BREATH')}
            />
          </>
        )}
      </S.SymptomsContainer>
      <hr />
      <S.SymptomsContainer>
        <strong>Sente palpitação?*</strong>
        <S.SelectUnitChips>
          <Chip
            labelFor="Sim isHeartPalpitations"
            label="Sim"
            checked={values.isHeartPalpitations === true}
            value={'true'}
            onCheck={() => {
              setFieldValue('isHeartPalpitations', true)
            }}
            id={'palpitations-yes'}
          />
          <Chip
            labelFor="Não isHeartPalpitations"
            label="Não"
            checked={values.isHeartPalpitations === false}
            value={'false'}
            onCheck={() => {
              setFieldValue('isHeartPalpitations', false)
            }}
            id={'palpitations-no'}
          />
        </S.SelectUnitChips>
      </S.SymptomsContainer>
      <hr />
      <S.SymptomsContainer>
        <strong>Sente alguma dor?*</strong>
        <S.SelectUnitChips>
          <Chip
            labelFor="Sim isPain"
            label="Sim"
            checked={values.isPain === true}
            value={'true'}
            onCheck={() => {
              setFieldValue('isPain', true)
            }}
            id={'is-pain-yes'}
          />
          <Chip
            labelFor="Não isPain"
            label="Não"
            checked={values.isPain === false}
            value={'false'}
            onCheck={() => {
              setFieldValue('isPain', false)
            }}
            id={'is-pain-no'}
          />
        </S.SelectUnitChips>
      </S.SymptomsContainer>
      {values?.isPain === true ? (
        <>
          <hr />
          <S.SymptomsContainer>
            <strong>Você possui dor em algum desses locais?</strong>
            <PainChip
              icon={<HeadacheIcon />}
              label="Cabeça"
              selected={!!findPain('HEAD')}
              intensity={findPain('HEAD')?.intensity}
              onClick={() => onSelectLocationPain('HEAD')}
            />
            <PainChip
              icon={<ChestIcon />}
              label="Peito / tórax"
              selected={!!findPain('THORAX')}
              intensity={findPain('THORAX')?.intensity}
              onClick={() => onSelectLocationPain('THORAX')}
            />
            <PainChip
              icon={<StomachIcon />}
              label="Barriga / abdômen"
              selected={!!findPain('ABDOMEN')}
              intensity={findPain('ABDOMEN')?.intensity}
              onClick={() => onSelectLocationPain('ABDOMEN')}
            />
            <PainChip
              icon={<StomachIcon />}
              label="Lombar"
              selected={!!findPain('LOW_BACK')}
              intensity={findPain('LOW_BACK')?.intensity}
              onClick={() => onSelectLocationPain('LOW_BACK')}
            />
          </S.SymptomsContainer>
          {needHead || needThorax ? <hr /> : null}
          {needHead ? (
            <>
              <S.SymptomsContainer>
                <strong>Perda de força?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim lossStrength"
                    label="Sim"
                    checked={values.lossStrength === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('lossStrength', true)
                    }}
                    id={'loss-strength-yes'}
                  />
                  <Chip
                    labelFor="Não lossStrength"
                    label="Não"
                    checked={values.lossStrength === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('lossStrength', false)
                    }}
                    id={'loss-strength-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
              <S.SymptomsContainer>
                <strong>Dificuldade para falar ou andar?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim difficultiesSpeakWalk"
                    label="Sim"
                    checked={values.difficultiesSpeakWalk === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('difficultiesSpeakWalk', true)
                    }}
                    id={'difficulties-speak-walk-yes'}
                  />
                  <Chip
                    labelFor="Não difficultiesSpeakWalk"
                    label="Não"
                    checked={values.difficultiesSpeakWalk === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('difficultiesSpeakWalk', false)
                    }}
                    id={'difficulties-speak-walk-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
              <S.SymptomsContainer>
                <strong>Confusão or perda de memória?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim confusionMemoryLoss"
                    label="Sim"
                    checked={values.confusionMemoryLoss === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('confusionMemoryLoss', true)
                    }}
                    id={'confusion-memory-loss-yes'}
                  />
                  <Chip
                    labelFor="Não confusionMemoryLoss"
                    label="Não"
                    checked={values.confusionMemoryLoss === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('confusionMemoryLoss', false)
                    }}
                    id={'confusion-memory-loss-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
            </>
          ) : null}
          {needThorax ? (
            <>
              {needHead ? <hr /> : null}
              <S.SymptomsContainer>
                <strong>Dor no peito com irradiação pro braço?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim chestRadiatingArm"
                    label="Sim"
                    checked={values.chestRadiatingArm === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('chestRadiatingArm', true)
                    }}
                    id={'chest-arm-yes'}
                  />
                  <Chip
                    labelFor="Não chestRadiatingArm"
                    label="Não"
                    checked={values.chestRadiatingArm === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('chestRadiatingArm', false)
                    }}
                    id={'chest-arm-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
              <S.SymptomsContainer>
                <strong>Sente dor abdominal?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim abdominalPain"
                    label="Sim"
                    checked={values.abdominalPain === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('abdominalPain', true)
                    }}
                    id={'abdominal-pain-yes'}
                  />
                  <Chip
                    labelFor="Não abdominalPain"
                    label="Não"
                    checked={values.abdominalPain === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('abdominalPain', false)
                    }}
                    id={'abdominal-pain-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
              <S.SymptomsContainer>
                <strong>Tem hipertensão ou diabetes?*</strong>
                <S.SelectUnitChips>
                  <Chip
                    labelFor="Sim hypertensionDiabetes"
                    label="Sim"
                    checked={values.hypertensionDiabetes === true}
                    value={'true'}
                    onCheck={() => {
                      setFieldValue('hypertensionDiabetes', true)
                    }}
                    id={'hypertension-diabetes-yes'}
                  />
                  <Chip
                    labelFor="Não hypertensionDiabetes"
                    label="Não"
                    checked={values.hypertensionDiabetes === false}
                    value={'false'}
                    onCheck={() => {
                      setFieldValue('hypertensionDiabetes', false)
                    }}
                    id={'hypertension-diabetes-no'}
                  />
                </S.SelectUnitChips>
              </S.SymptomsContainer>
            </>
          ) : null}
          {needHead || needThorax ? <hr /> : null}
          <S.SymptomsContainer>
            <TextArea
              id="otherPainLocation"
              placeholder="Digite aqui..."
              label="Está sentido dor em outro local? Se sim, descreva abaixo."
              rows={4}
              name="otherPainLocation"
              value={values.otherPainLocation || ''}
              onChange={handleChange}
              style={{ marginTop: '20px' }}
              disabled={!values?.isPain}
            />
          </S.SymptomsContainer>
        </>
      ) : null}
      <hr />
      <S.SymptomsContainer>
        <TextArea
          id="details"
          placeholder="Detalhe o que está sentindo"
          label="Observações"
          rows={4}
          name="details"
          value={values.details || ''}
          onChange={handleChange}
          style={{ marginTop: '20px' }}
        />
      </S.SymptomsContainer>
      <S.SymptomsContainer>
        <Button
          fullWidth
          onClick={() => {
            handleSubmit()
            setStep(3)
            carousel.slideTo(0)
          }}
          disabled={!isValid && !(values.fever !== 'YES')}
        >
          Continuar
        </Button>
      </S.SymptomsContainer>
      <PainIntensityModal
        intensity={
          values?.painLocation?.find(
            (painLocation) => painLocation?.location === selectedPain
          )?.intensity || 5
        }
        onClose={() => setSelectedPain(undefined)}
        open={!!selectedPain}
        onConfirm={onConfirmIntensity}
      />
    </>
  )
}

type PainChipProps = {
  icon: React.ReactElement
  label: string
  selected: boolean
  intensity?: number
  onClick: () => void
}

function PainChip({
  label,
  intensity,
  selected = false,
  icon,
  onClick
}: PainChipProps) {
  return (
    <S.PainChip selected={selected} onClick={() => onClick()}>
      <div>
        <S.PainChipIcon selected={selected}>{icon}</S.PainChipIcon>
        {label}
      </div>
      {!!intensity && (
        <S.PainChipIntensity>
          {('00' + intensity).slice(-2)}
        </S.PainChipIntensity>
      )}
    </S.PainChip>
  )
}

const validationSchema = yup.object().shape({
  fever: yup.string().required(),
  temperatureFever: yup.string().nullable().when('fever', {
    is: 'YES',
    then: yup.string().required()
  }),
  isFever: yup
    .number()
    .nullable()
    .min(
      37.5,
      'Para ser considerado febre, a temperatura deve ser maior ou igual a 37.5 °C.'
    ),
  breathingDifficulty: yup.boolean().required(),
  details: yup.string().nullable(),
  breathingOptions: yup.array().nullable(),
  isHeartPalpitations: yup.boolean().required(),
  isPain: yup.boolean().required(),
  lossStrength: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('HEAD', val, form)
    }),
  difficultiesSpeakWalk: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('HEAD', val, form)
    }),
  confusionMemoryLoss: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('HEAD', val, form)
    }),
  chestRadiatingArm: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('THORAX', val, form)
    }),
  abdominalPain: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('THORAX', val, form)
    }),
  hypertensionDiabetes: yup
    .boolean()
    .nullable()
    .test('greaterThan', 'Obrigatório', (val?: boolean | null, form?: any) => {
      return validate('THORAX', val, form)
    }),
  painLocation: yup.array().nullable()
})

const validate = (location: string, val?: boolean | null, form?: any) => {
  if (val === null || val === undefined) {
    const headIntensity = form?.parent?.painLocation?.find(
      (pL: any) => pL?.location === location
    )
    if (headIntensity?.intensity > 5) return false
  }
  return true
}
