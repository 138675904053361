import { LoadSurgicalOrderPendenciesHistory } from 'domain/usecases/surgical-pendency/load-surgical-order-pendencies-history'
import { SurgicalPendencyRepository } from 'repository/interfaces/surgical-pendency-repository'

export class RemoteLoadSurgicalOrderPendenciesHistory
  implements LoadSurgicalOrderPendenciesHistory
{
  constructor(
    private readonly surgicalPendencyRepository: SurgicalPendencyRepository
  ) {}

  async load(
    surgical_order_id: LoadSurgicalOrderPendenciesHistory.Params
  ): Promise<LoadSurgicalOrderPendenciesHistory.Model> {
    return this.surgicalPendencyRepository.loadSurgicalOrderPendenciesHistory(
      surgical_order_id
    )
  }
}
