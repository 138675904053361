import styled from 'styled-components'

export const Wrapper = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const TitleContainer = styled.div`
  margin: 10px 0;
`

export const CardsContainer = styled.div`
  margin: 20px 0;
`

export const Card = styled.div`
  padding: 20px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`
