import styled, { css } from 'styled-components'
import { CheckboxProps } from '.'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Input = styled.input`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid
      ${disabled ? theme.ds.colors.neutral300 : theme.ds.colors.primary600};
    border-radius: 5px;
    transition: background border ${theme.transition.fast};
    position: relative;
    outline: none;

    &:after {
      content: '';
      width: 0;
      height: 0;
      transition: 200ms;
      transition-property: height, width;
    }

    &:before {
      content: '';
      width: 6px;
      height: 12px;
      border: 2px solid ${theme.ds.colors.white};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 0.5px;
      opacity: 0;
      transition: ${theme.transition.fast};
      z-index: 2;
    }

    &:checked {
      border-color: ${theme.ds.colors.primary600};
      background: ${theme.ds.colors.primary600};
      &:before {
        opacity: 1;
      }
    }
  `}
`

export const Label = styled.label<
  Pick<CheckboxProps, 'labelColor' | 'labelSize' | 'labelWeight' | 'disabled'>
>`
  ${({ theme, labelSize, labelColor, labelWeight, disabled }) => css`
    cursor: pointer;
    line-height: ${theme.ds.typography.lineHeight.medium};
    font-weight: ${theme.ds.typography.weight?.[labelWeight!]};
    margin-left: 8px;
    font-size: ${theme.ds.typography.size?.[labelSize!]};
    color: ${disabled
      ? theme.ds.colors.neutral300
      : theme.ds.colors?.[labelColor!]};
    text-transform: capitalize;
  `}
`
