import { UpdateRecommendation } from 'domain/usecases/doctor/update-recommendation'
import { RecommendationRepository } from 'repository/interfaces/recommendation-repository'

export class RemoteUpdateRecommendation implements UpdateRecommendation {
  constructor(
    private readonly recommendationRepository: RecommendationRepository
  ) {}

  update(
    params: UpdateRecommendation.Params
  ): Promise<UpdateRecommendation.Model> {
    return this.recommendationRepository.updateRecommendation(params)
  }
}

export type UpdateRecommendationModel = UpdateRecommendation.Model
