import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'
import { ApiClientImplementation } from 'infra/api-client/api-client'
import { RemoteCreateDoctorRegisterPendency } from 'service/usecases/create-doctor-register-pendency/remote-create-doctor-register-pendency'
import { DoctorRegisterRepository } from 'repository/repositories/doctor-register/doctor-register-repository'
import { DoctorRegisterService } from 'presentation/contexts/services/doctor-register-service'
import { RemoteSendDoctorRegisterToDirector } from 'service/usecases/send-doctor-register-to-director/remote-send-doctor-register-to-director'
import { RemoteCreateDoctorRegisterReview } from 'service/usecases/create-doctor-register-review/remote-create-doctor-register-review'

export default function makeDoctorRegisterService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const doctorRegisterRepository = new DoctorRegisterRepository(apiRepository)

  const createDoctorRegisterPendency = new RemoteCreateDoctorRegisterPendency(
    doctorRegisterRepository
  )

  const sendDoctorRegisterToDirector = new RemoteSendDoctorRegisterToDirector(
    doctorRegisterRepository
  )

  const createDoctorRegisterReview = new RemoteCreateDoctorRegisterReview(
    doctorRegisterRepository
  )

  return new DoctorRegisterService({
    createDoctorRegisterPendency,
    sendDoctorRegisterToDirector,
    createDoctorRegisterReview
  })
}
