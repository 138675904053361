import styled, { css } from 'styled-components'

import { LogoProps } from '.'

const wrapperModifiers = {
  normal: () => css`
    width: 120px;
  `,

  large: () => css`
    width: 170px;
  `,

  huge: () => css`
    width: 210px;
  `
}

export const Wrapper = styled.div<LogoProps>`
  ${({ size }) => css`
    .cls-1 {
      fill: ${({ theme }) => theme.colors.primary};
    }
    ${!!size && wrapperModifiers[size]}
  `}
`
