export enum ActiveSearchSurgeryType {
  I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE = 'I_DID_NOT_HAVE_SURGERY_BUT_I_WANT_TO_CONTINUE',
  SURGERY_ALREADY_DONE = 'SURGERY_ALREADY_DONE',
  SURGERY_ALREADY_DONE_IN_OTHER_HOSPITAL = 'SURGERY_ALREADY_DONE_IN_OTHER_HOSPITAL',
  I_NO_LONGER_WISH_TO_CONTINUE = 'I_NO_LONGER_WISH_TO_CONTINUE',
  OPERATOR_AUTHORIZED = 'OPERATOR_AUTHORIZED',
  HOSPITAL_RETURN = 'HOSPITAL_RETURN',
  CHANGE_OF_DOCTOR = 'CHANGE_OF_DOCTOR',
  HEALTH_PLAN_ANOTHER_HOSPITAL = 'HEALTH_PLAN_ANOTHER_HOSPITAL',
  PERSONAL_PROBLEM = 'PERSONAL_PROBLEM',
  OTHER = 'OTHER'
}
