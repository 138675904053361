import { CreateSchedule } from 'domain/usecases/scheduling/create-schedule'
import { DeleteSchedule } from 'domain/usecases/scheduling/delete-schedule'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const createScheduleMutation = (params: CreateSchedule.Params) => ({
  query: `
  mutation CreateSchedule(
    $healthInsurance: HealthInsuranceData!,
    $specialty: SpecialtyData!,
    $patient: PatientData!,
    $schedule: ScheduleData!,
    $doctor: DoctorData!,
    $unit: UnitData!
    ) {
	  createSchedule(
      data: {
        healthInsurance: $healthInsurance,
        specialty: $specialty,
        patient: $patient,
        schedule: $schedule,
        doctor: $doctor,
        unit: $unit
      }
    )
  }
`
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteScheduleMutation = (params: DeleteSchedule.Params) => ({
  query: `
  mutation DeleteSchedule(
    $healthInsurance: HealthInsuranceData!,
    $specialty: SpecialtyData!,
    $patient: PatientData!,
    $schedule: ScheduleData!,
    $doctor: DoctorData!,
    $unit: UnitData!
  ) {
    deleteSchedule(data: {
      healthInsurance: $healthInsurance,
      specialty: $specialty,
      patient: $patient,
      schedule: $schedule,
      doctor: $doctor,
      unit: $unit,
    })
  }
  `,
  name: 'deleteScheduleMutation'
})
