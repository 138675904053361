import React from 'react'
import EmailConfirmationForm from 'presentation/components/Forms/ConfirmEmail'
import { useStores } from 'presentation/hooks/use-stores'
import { UpdatePatientInfo } from 'domain/usecases/patient/update-patient-info'
import { UpdateExam } from 'domain/usecases/patient/update-exam'

type Props = {
  updateUserEmail: UpdatePatientInfo
  updateExam: UpdateExam
}

export default function EmailConfirmationLayout({
  updateUserEmail,
  updateExam
}: Props) {
  const { email } = useStores().currentAccount.getCurrentAccount().user

  return (
    <EmailConfirmationForm
      updateExam={updateExam}
      currentEmail={email}
      updateUserEmail={updateUserEmail}
    />
  )
}
