import styled, { css, DefaultTheme } from 'styled-components'

import { TextButtonProps } from '.'

export type WrapperProps = Pick<TextButtonProps, 'size' | 'underline'>

const wrapperModifiers = {
  small: (theme: DefaultTheme) => css`
    font-size: ${theme.font.sizes.xsmall};
  `,
  medium: (theme: DefaultTheme) => css`
    font-size: ${theme.font.sizes.medium};
  `,
  underlined: () => css`
    text-decoration: underline;
  `
}

export const Wrapper = styled.button<WrapperProps>`
  ${({ theme, size, underline }) => css`
    cursor: pointer;
    line-height: 21px;
    color: ${theme.colors.primary};
    font-weight: ${theme.font.bold};
    ${!!size && wrapperModifiers[size](theme)};
    ${!!underline && wrapperModifiers.underlined()};
    background: none;
    border: none;
    border-radius: 4px;
    outline-offset: 5px;
    outline-color: transparent;
    transition: 0.2s;

    :focus {
      outline-color: ${theme.colors.primary};
    }
  `}
`
