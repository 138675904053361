import { ApiClientImplementation } from 'infra/api-client/api-client'

import { PrepareApiRequestDecorator } from 'main/decorators/prepare-api-request-decorator/prepare-api-request-decorator'
import { makeLocalStorageAdapter } from 'main/factories/cache/local-storage-adapter-factory'
import { ApiRepository } from 'repository/api-repository'

import { OverallRegistrationStatusService } from 'presentation/contexts/services/overall-registration-status'
import { OverallRegistrationStatusRepository } from 'repository/repositories/overall-registration-status/overall-registration-status-repository'
import { RemoteCreateDoctorRegistrationStatus } from 'service/usecases/create-doctor-overall-registration-status/remote-create-doctor-registration-status'
import { RemoteUpdateDoctorOverallStatus } from 'service/usecases/update-doctor-overall-registration-status/remote-update-doctor-registration-status'
import { RemoteResolveDoctorRegisterPendencies } from 'service/usecases/resolve-doctor-register-pendencies/remote-resolve-doctor-register-pendencies'
import { RemoteLoadDoctorOverallStatusForReview } from 'service/usecases/load-doctor-overall-status-for-review/remote-load-doctor-overall-status-for-review'

export default function makeOverallRegistrationStatusService() {
  const apiRepository = new PrepareApiRequestDecorator(
    makeLocalStorageAdapter(),
    new ApiRepository(new ApiClientImplementation())
  )

  const registrationStatusRepository = new OverallRegistrationStatusRepository(
    apiRepository
  )

  const createDoctorRegistrationStatus =
    new RemoteCreateDoctorRegistrationStatus(registrationStatusRepository)

  const updateDoctorOverallStatus = new RemoteUpdateDoctorOverallStatus(
    registrationStatusRepository
  )
  const resolvePendencies = new RemoteResolveDoctorRegisterPendencies(
    registrationStatusRepository
  )
  const loadForReview = new RemoteLoadDoctorOverallStatusForReview(
    registrationStatusRepository
  )

  return new OverallRegistrationStatusService({
    createDoctorRegistrationStatus,
    updateDoctorOverallStatus,
    resolvePendencies,
    loadForReview
  })
}
