import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  PendencyType,
  SurgicalPendency
} from 'domain/entities/surgical-pendency'
import { AnswerSurgicalPendencyLayout } from 'presentation/layouts/AnswerSurgicalPendency'
import { AppendPendencyDocument } from 'presentation/pages/AppendPendencyDocument'
import { AnswerSurgicalPendency } from 'domain/usecases/surgical-pendency/answer-surgical-pendency'
import { FormikProvider, useFormik } from 'formik'
import { toast } from 'react-toastify'
import { PatientDocument } from 'common/enum/patient-document'
import { UploadSurgicalPendencyDocument } from 'domain/usecases/surgical-pendency/upload-surgical-pendency-document'
import Modal from 'presentation/shared/components/Modal'
import { ListFile } from 'presentation/shared/components/UploadSection/UploadListFiles/UploadListFile'
import { generateHash } from 'common/utils/generateHash'
import { renameFile } from 'common/utils/file/renameFile'
import doctorPendencyStore from 'presentation/contexts/doctor-pendency/doctor-pendency-context'
import LoadingModal from 'presentation/shared/components/LoadingModal'
// import { useServices } from 'presentation/hooks/use-services'

type Props = {
  answerSurgicalPendency: AnswerSurgicalPendency
  uploadSurgicalPendencyDocument: UploadSurgicalPendencyDocument
}

export type ListFilePendencyType = {
  label: string
  type: PatientDocument
}

export const AnswerPendency = ({
  answerSurgicalPendency,
  uploadSurgicalPendencyDocument
}: Props) => {
  // const notificationService = useServices().notification

  // const disableNotifications = async ({
  //   notification_id,
  //   pendency_id
  // }: any) => {
  //   await notificationService.disableNotifications({
  //     notification_id: notification_id,
  //     pendency_id: pendency_id
  //   })
  // }

  // const loadNotificationsByPendency = async (pendency_id: number) => {
  //   return await notificationService.loadNotificationsByPendency({
  //     pendency_id: pendency_id
  //   })
  // }

  const location = useLocation<{ pendency: SurgicalPendency }>()
  const [mode, setMode] = useState<'answer' | 'append'>('answer')
  const [documents, setDocuments] = useState<ListFile<ListFilePendencyType>[]>(
    []
  )

  const [isLoading, setIsLoading] = useState(false)

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const history = useHistory()
  const currentPendency =
    location.state.pendency || doctorPendencyStore.doctorPendency[0]

  const formik = useFormik({
    initialValues: {
      answer: currentPendency.answer ?? ''
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const group_id = generateHash()
        await Promise.all(
          documents.map(async ({ file, identifier }) => {
            const form = new FormData()
            const type = identifier?.type || ''
            const newFile = renameFile(file, identifier?.label)
            form.append('file', newFile)
            form.append(
              'surgical_pendency_id',
              currentPendency.surgical_pendency_id.toString()
            )
            form.append('type', type)
            form.append('group_id', group_id)
            const uploadedFile = uploadSurgicalPendencyDocument.upload({
              type,
              file,
              form
            })
            return await toast.promise(uploadedFile, {
              pending: `Fazendo o envio do arquivo ${file.name}`,
              success: `Arquivo ${file.name} enviado com sucesso`,
              error: `Falha ao enviar o arquivo ${file.name}`
            })
          })
        )
        await answerSurgicalPendency.answer({
          surgical_pendency_id: currentPendency.surgical_pendency_id,
          answer: values.answer,
          group_id
        })

        // await disableNotifications({
        //   notification_id:
        //     relatedNotifications.notification_id ||
        //     doctorPendencyStore.doctorNotification?.notification_id,
        //   pendency_id:
        //     currentPendency?.surgical_pendency_id ||
        //     doctorPendencyStore.doctorNotification?.attributes?.pendency_id
        // })

        setIsLoading(false)
        setShowSuccessModal(true)
      } catch (e: any) {
        setIsLoading(false)
        toast.error(e.message)
      }
    }
  })

  if (mode === 'append') {
    return (
      <AppendPendencyDocument
        saveDocuments={setDocuments}
        defaultDocuments={documents}
        goBack={() => setMode('answer')}
        pendencyType={currentPendency.type as PendencyType}
      />
    )
  }
  return (
    <>
      <FormikProvider value={formik}>
        <AnswerSurgicalPendencyLayout
          hasDocuments={documents?.length > 0}
          pendency={currentPendency}
          appendMode={() => setMode('append')}
        />
        <Modal
          show={showSuccessModal}
          close={() => history.push('/pendencias')}
          title="Pendência respondida com sucesso!"
        />
      </FormikProvider>
      <LoadingModal show={isLoading} />
    </>
  )
}
