import React from 'react'
import CardNew from 'presentation/shared/components/CardNew'
import DividerNew from 'presentation/shared/components/DividerNew'

import BadgeStatusNew from '../BadgeStatusNew'
import SupportTextNew from '../SupportTextNew'
import * as S from './styles'
import { OpenScheduleSurgeryType } from 'common/enum/open-schedule-surgery-type'

type SurgicalOrderProps = {
  surgicalOrder: OpenScheduleSurgeryType
}

export const CardInfoSurgery = ({ surgicalOrder }: SurgicalOrderProps) => {
  const mainProcedure = () => {
    const procedureName = surgicalOrder.procedures?.find(
      (procedure: any) => procedure.isMain
    )?.description

    if (procedureName) {
      return (
        procedureName?.charAt(0).toUpperCase() +
        procedureName?.slice(1).toLowerCase()
      )
    }
  }

  return (
    <CardNew
      background={'white'}
      shadow={'none'}
      border={'primary300'}
      style={{ marginBottom: '20px' }}
    >
      <S.CardHeader>
        <SupportTextNew weight="semiBold" color="neutral500">
          N° {surgicalOrder.surgical_order_id}
        </SupportTextNew>

        {surgicalOrder.scheduling_status && (
          <BadgeStatusNew status={surgicalOrder.scheduling_status} />
        )}
      </S.CardHeader>

      <DividerNew />

      <S.CardBody>
        <div>
          <SupportTextNew weight="semiBold" color="neutral500" size="xxsmall">
            Procedimento
          </SupportTextNew>

          <SupportTextNew>{mainProcedure()}</SupportTextNew>
        </div>

        <div>
          <SupportTextNew weight="semiBold" color="neutral500" size="xsmall">
            Médico
          </SupportTextNew>

          <SupportTextNew weight="semiBold" color="neutral800" size="xsmall">
            {surgicalOrder.doctor.name}
          </SupportTextNew>
        </div>
      </S.CardBody>
    </CardNew>
  )
}
