import React from 'react'
import { ReactComponent as TrashIcon } from 'presentation/assets/icons/trash-icon.svg'
import { ReactComponent as AlertCircle } from 'presentation/assets/icons/alert-circle.svg'
import * as S from './styles'
import ButtonNew from '../ButtonNew'
import { ExamSearchType } from 'domain/entities/exam-model-new'

type ExamCardProps = {
  examName: string
  examDescription?: string
  handleDelete: () => void
  allowSchedule: boolean
  unavailable?: boolean
  needMedicalAppointment?: boolean
  warning?: string
  examReasonNotSchedule?: string
  examType?: ExamSearchType
}

export const ExamCardNew = ({
  examName,
  examDescription,
  handleDelete,
  allowSchedule,
  unavailable,
  warning,
  examReasonNotSchedule,
  needMedicalAppointment,
  examType = ExamSearchType.EXAM
}: ExamCardProps) => {
  return (
    <>
      <S.CardContainer>
        <S.CardTitle unavailable={unavailable}>{examName}</S.CardTitle>

        <S.CardDescription unavailable={unavailable}>
          {examDescription}
        </S.CardDescription>

        {warning && (
          <S.WarningContainer>
            <AlertCircle width={20} />
            <S.WarningDescription>{warning}</S.WarningDescription>
          </S.WarningContainer>
        )}

        {examType === ExamSearchType.EXAM &&
          !allowSchedule &&
          !needMedicalAppointment && (
            <S.NoSchedule>
              <strong>Atenção: </strong>
              {examReasonNotSchedule
                ? examReasonNotSchedule
                : 'Para realizar esse exame não é necessário agendamento. O atendimento é 24h e realizado todos os dias da semana. '}
            </S.NoSchedule>
          )}

        {examType === ExamSearchType.SURGERY && (
          <S.NoSchedule>
            <strong>Atenção: </strong>
            Este item é um procedimento cirúrgico, portanto passará pelo
            processo de autorização de cirurgias.
          </S.NoSchedule>
        )}

        {needMedicalAppointment && (
          <S.MedicalAppointmentContainer>
            <AlertCircle width={20} />
            <S.MedicalAppointmentText>
              Para realizar este exame é necessário agendar uma consulta
              pré-exame. Após a consulta, seu exame será agendado
              presencialmente.
            </S.MedicalAppointmentText>
          </S.MedicalAppointmentContainer>
        )}

        <S.HorizontalButtonPanel>
          <ButtonNew onClick={handleDelete} icon={<TrashIcon />}>
            Excluir
          </ButtonNew>
        </S.HorizontalButtonPanel>
      </S.CardContainer>
    </>
  )
}
